import { Component, ViewEncapsulation } from '@angular/core';

import { BaseCheckboxRadioWithTextComponent } from '../../helpers';

@Component({
  selector: 'lib-checkbox-with-text-widget',
  template: `
    <mat-checkbox class="mbe-10" color="primary" [aria-label]="option?.title" [checked]="data.selected" (change)="onChange($event.checked)">
      <span [innerHTML]="option?.title"></span>
    </mat-checkbox>

    <mat-form-field appearance="outline" class="text-input">
      <input type="text" autocomplete="off" matInput [formControl]="formControl" />
    </mat-form-field>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./checkbox-with-text-widget.component.scss']
})
export class CheckboxWithTextWidgetComponent extends BaseCheckboxRadioWithTextComponent {
  constructor() {
    super();
  }
}
