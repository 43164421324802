import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const somethingWentWrong = 'Oops! Something went wrong';

export const successSnackConfig = (duration = 2000): MatSnackBarConfig => ({
  duration, verticalPosition: 'top', panelClass: 'success-snackbar'
});

export const failSnackConfig = (duration = 5000): MatSnackBarConfig => ({
  duration, verticalPosition: 'top', panelClass: 'failed-snackbar'
});

export const failSnackBottomConstantConfig = (duration = 10000): MatSnackBarConfig => ({
  duration, horizontalPosition: 'center', verticalPosition: 'bottom', panelClass: 'failed-snackbar'
});
// TODO We need to migrate the platform from the functions above to the following ones
export const successSnackbarConfig = (message: string, duration = 1000): MatSnackBarConfig => ({
  panelClass: 'success-snackbar', verticalPosition: 'top', duration, data: {message, icon: 'notify-success'}
});

export const failSnackbarConfig = (message: string, duration = 10000): MatSnackBarConfig => ({
  panelClass: 'failed-snackbar', verticalPosition: 'top', duration, data: {message, icon: 'notify-failed'}
});
