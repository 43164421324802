import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { consentTypeSchema } from './consent';

export const signedConsentSchema = t.type({
  consent_type: consentTypeSchema,
  created_at: t.union([t.string, DateFromISOString]),
  subject_name: t.string,
  study_id: t.string,
  signed_consent_id: t.number,
  consent_id: t.number,
  consent_text: t.string,
  agreement_text: t.string
}, 'signedConsentSchema');
export type SignedConsent = t.TypeOf<typeof signedConsentSchema>;
