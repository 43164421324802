import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { FormGroupProtoType } from 'tr-lib';

export interface LoginData {
  email: string;
  password: string;
  recaptchaToken?: string;
}

export const emailFormValidators = (): {email: ValidatorFn; password: ValidatorFn} => ({
  email: Validators.compose([Validators.required, Validators.email]),
  password: Validators.required
});

export const getLoginForm = (withRecaptcha = false): FormGroup<FormGroupProtoType<LoginData>> => {
  const baseForm: FormGroupProtoType<LoginData> = {
    email: new FormControl<string>(null, emailFormValidators().email),
    password: new FormControl<string>(null, emailFormValidators().password),
  };

  return new FormGroup<FormGroupProtoType<LoginData>>(
    withRecaptcha ? {...baseForm, recaptchaToken: new FormControl<string>(null)} : baseForm
  );
}

export const getLoginFormValue = (form: FormGroup<FormGroupProtoType<LoginData>>): LoginData => {
  const {email, password, recaptchaToken} = form.value;

  return {email: email.toLowerCase(), password, recaptchaToken};
};
