import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { fullDateFormats } from '@tr-common';

import { SourceChange } from '../../../models';
import { BaseDateComponent } from '../../helpers';

@Component({
  selector: 'lib-date-widget',
  template: `
    <mat-form-field appearance="outline" [class.warning]="hasOnlyAsyncWarnings(dateControl.errors)">
      <mat-label>{{ format }}</mat-label>
      <input autocomplete="off" #dateInput matInput
        [matDatepicker]="myDatepicker" [formControl]="dateControl" [errorStateMatcher]="errorMatcher" (dateChange)="getDateValueAndEmit()"
      />

      <mat-datepicker-toggle matSuffix [for]="myDatepicker">
        <mat-icon svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #myDatepicker></mat-datepicker>

      <mat-error><lib-form-control-error [control]="dateControl" [errorHandlers]="errorHandlers"></lib-form-control-error></mat-error>
    </mat-form-field>
  `,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: fullDateFormats},
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./date-widget.component.scss'],
})
export class DateWidgetComponent extends BaseDateComponent {
  @ViewChild('myDatepicker') myDatepicker: MatDatepicker<unknown>;

  constructor() {
    super();
  }

  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);
  @HostListener('window:scroll', ['$event']) windowScroll() {
    this.myDatepicker.close();
  }
}
