import { FormControl } from '@angular/forms';
import { isMoment, Moment, MomentInput } from 'moment';

import { isNonEmptyString, isNullOrUndefined, isRealValue, serverDateFormat, ServerDateRange } from '@tr-common';

import { DateRangeType } from './study-option-types';

import { ValidateResults } from './validate-common';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export interface MatDateRange {
  start: Moment;
  end: Moment;
}

export interface DateFragment {
  day: number,
  month: number,
  year: number,
}

export interface DateRange {
  beginDate: DateFragment,
  endDate: DateFragment,
}

export const dateRange = (format: string, {value}: FormControl<Moment>, b: FormControl<Moment>): ValidateResults => {
  const start = moment(value, format);
  const end = moment(b.value, format);

  return start.diff(end) > 0 ? {dateRange: true} : null;
};

export const getDateRangeObject = (value: MatDateRange): DateRangeType => isRealValue(value?.start) && isRealValue(value?.end)
  ? {beginDate: moment(value.start), endDate: moment(value.end)}
  : null;

export const serverDateRangeToDateRange = (sdr: string): MatDateRange => {
  const parsedSdr: ServerDateRange = isNonEmptyString(sdr) ? JSON.parse(sdr) : null;

  return isNullOrUndefined(parsedSdr) ? null : {
    start: isNonEmptyString(parsedSdr.start) ? moment(parsedSdr.start, serverDateFormat) : null,
    end: isNonEmptyString(parsedSdr.end) ? moment(parsedSdr.end, serverDateFormat) : null
  };
};

export const isRangeFilled = (
  range: MatDateRange
): boolean => isMoment(range?.start) && isMoment(range?.end);

const dateFragmentToYYYYMMDD = (f: Moment): string => (isRealValue(f) && f.isValid()) ? f.format('YYYY-MM-DD') : null;

export const dateRangeToServerDateRange = (
  dr: MatDateRange
): ServerDateRange => ({start: dateFragmentToYYYYMMDD(dr.start), end: dateFragmentToYYYYMMDD(dr.end)});

export const getDateRangeValue = (formValue: Partial<MatDateRange>): string => {
  const {start, end}: Partial<MatDateRange> = formValue;
  const isBothEmpty = isNullOrUndefined(start) && isNullOrUndefined(end);

  return isBothEmpty ? null : JSON.stringify(dateRangeToServerDateRange({start, end}));
}
