import { ChangeDetectorRef, Component, HostListener, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { exchangeContactInfoT1D } from '@tr-common';

@Component({
  selector: 'lib-contact-info',
  encapsulation: ViewEncapsulation.None,
  template: `
    <a mat-flat-button color="secondary" class="is-with-icon need-help"
      [matMenuTriggerFor]="contactMenu" [class.flat]="flatBtn" [class.activated]="activated" #menuTrigger="matMenuTrigger"
      (click)="addListener()">
      <mat-icon svgIcon="help"></mat-icon>
      <span>Need Help?</span>
    </a>

    <mat-menu #contactMenu="matMenu" class="tr-contact-popup" xPosition="before" yPosition="above" [overlapTrigger]="false">
      <div class="tr-contact-popup-title">
        Our information
      </div>
      <div class="tr-contact-popup-content">
        <p>Reach us by phone or email:</p>
        <a href="tel:{{ contactInfo.phone }}" (click)="$event.stopPropagation()">{{ contactInfo.phone }}</a>
        <a target="_blank" rel="noopener" href="mailto:{{ contactInfo.email }}" (click)="$event.stopPropagation()">
          {{ contactInfo.email }}
        </a>
        <p>We are available Monday to Friday from 8AM to 5PM EST.</p>
      </div>
    </mat-menu>
  `,
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  @Input() flatBtn: boolean;
  activated: boolean;
  readonly contactInfo = exchangeContactInfoT1D;

  constructor(private cdRef: ChangeDetectorRef) {}

  @HostListener('window:scroll') windowScroll() {
    this.trigger.closeMenu();
  }

  addListener() {
    this.activated = true;
    document.querySelectorAll('.cdk-overlay-backdrop')[0]?.addEventListener('click', () => {
      this.activated = false;
      this.cdRef.detectChanges();
    });
  }
}
