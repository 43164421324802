import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-not-eligible-content',
  template: `
    <h4>Thank you for your time and interest in the T1D Exchange Registry</h4>
    <p class="text-normal">
      We are so sorry! Unfortunately, at this time you do not qualify to take part in the "{{ title }}".
      If you would like to talk to a study team member, our contact information is below.
    </p>
    <h4>What’s Next?</h4>
    <p class="text-normal">
      T1D Exchange will keep your email address and responses in our database. If the eligibility criteria for
      the Registry changes, we will reach out to you. We thank you for your enthusiasm in contributing to T1D research!
    </p>
  `,
  styleUrls: ['./not-eligible-content.component.scss']
})
export class NotEligibleContentComponent {
  @Input() title = 'T1D Exchange Registry';
}
