import { AnswerWidgetType, isRealValue } from '@tr-common';

import { FlatTreeNode } from './flat-tree-node';
import { StudyOption } from './study-option';

export const isStudyOption = (data: unknown): data is StudyOption => isRealValue((data as StudyOption)?.widget);

export const whenWidgetIs = (
  widget: AnswerWidgetType
) => (x: number, {data}: FlatTreeNode) => isStudyOption(data) && data.widget === widget;
