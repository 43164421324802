import * as t from 'io-ts';

export enum ProcedureTemplateTypes {
  intro = 'intro',
  screening = 'screening',
  consent = 'consent',
  questionnaire = 'questionnaire',
  research = 'research',
}

export const procedureWelcomeDialogSchema = t.union([
  t.literal(ProcedureTemplateTypes.screening),
  t.literal(ProcedureTemplateTypes.consent),
  t.literal(ProcedureTemplateTypes.questionnaire),
]);
export type ProcedureWelcomeDialogType = t.TypeOf<typeof procedureWelcomeDialogSchema>;

export const procedureTemplateTypesSchema = t.union([
  procedureWelcomeDialogSchema,
  t.literal(ProcedureTemplateTypes.intro),
  t.literal(ProcedureTemplateTypes.research),
], 'procedureTemplateTypesSchema');
export type ProcedureTemplateType = t.TypeOf<typeof procedureTemplateTypesSchema>;
