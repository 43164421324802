<ng-container *ngIf="optInSettings$ | async as settings">
  <div mat-dialog-title>
    <img src="/assets/dialog/sms-consent.svg" alt="sms-consent-image">
    <h3>Start Receiving Reminders Directly to Your Phone</h3>
    <h5>Opt-in to receive texts from the T1D Exchange Registry and never miss a study reminder.</h5>
  </div>

  <form mat-dialog-content class="phone-input-form" [formGroup]="formGroup" [class.submitted]="showError">
    <div>Please provide mobile phone number:</div>
    <mat-form-field appearance="outline" class="full-width phone-input-wrapper">
      <lib-phone-input class="full-width" formControlName="phone" [codes]="profile.countryCodes$ | async" [validity]="!!phone.errors">
      </lib-phone-input>
      <mat-error *ngIf="phone.errors">
        <span *ngIf="showError && phone.errors?.required">Phone number is required to continue</span>
        <span *ngIf="phone.errors?.server">{{ phone.errors.server }}</span>
        <span *ngIf="phone.errors?.countryError">{{ phone.errors?.countryError }}</span>
      </mat-error>
    </mat-form-field>

    <div class="opt-in-wrapper">
      <mat-checkbox formControlName="optIn">Opt-in to receive study reminders (optional)</mat-checkbox>
      <mat-error *ngIf="showError && optIn.errors">Checkbox required to continue.</mat-error>
    </div>

    <div class="sms-consent-footer mbs-20">
      {{ smsConsentFooter }}
      <span *ngIf="layout.likeDesktop" class="show-more" (click)="showMore()">Show {{ isCut ? 'more' : 'less' }}</span>
    </div>
  </form>

  <div mat-dialog-actions class="flex justify-center">
    <button type="button" class="dialog-button" mat-flat-button color="secondary" [mat-dialog-close]="null">Skip</button>
    <button type="button" class="dialog-button" mat-flat-button color="primary" [spinner]="settings.optInPending"
      (click)="confirm()">Confirm
    </button>
  </div>
</ng-container>
