import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Debounce, Memoize } from 'lodash-decorators';
import { Observable, Subscription } from 'rxjs';

import { isRealValue } from '@tr-common';

import { REGISTRY_DOCUMENT } from '../../../global/tokens';
import { LayoutService } from '../../../services';
import { SurveyService } from '../../services/survey.service';

@Component({
  selector: 'lib-question-wrapper',
  templateUrl: './question-wrapper.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./question-wrapper.component.scss']
})
export class QuestionWrapperComponent implements OnInit, OnDestroy {
  // TODO Was 'Save and leave'. We are expecting a new design for a modal which asks a participant about changes. Look at TR-4007.
  @Input() button = 'Save and Leave';
  @Input() preview = false;
  @Input() absentAnswers = 0;
  @Input() pending: Observable<boolean>;
  @HostBinding('class.loading') loading = false;
  @Output() back = new EventEmitter<void>();
  @Output() questions = new EventEmitter<void>()
  private subscription = new Subscription();

  constructor(
    private elRef: ElementRef,
    private surveyService: SurveyService,
    private layoutService: LayoutService,
    @Inject(REGISTRY_DOCUMENT) private _document: Document
  ) {
    this.subscription.add(this.layoutService.scrollIt$.subscribe(() => {
      this.elRef.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
    }));
  }

  @Memoize() mustShowInfoContainer(): boolean {
    return this.surveyService.isPublicApplication;
  }

  @HostListener('scroll', ['$event']) bodyScroll = (event: Event) => this.onScroll(event);

  ngOnInit(): void {
    if (isRealValue(this.pending)) {
      this.subscription.add(this.pending.subscribe(pending => this.loading = pending))
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Debounce(100) onScroll(event: Event): void {
    this.layoutService.scrollRise$.next(event);
  }
}
