<header class="flex align-center space-between navbar" *ngIf="user$ | async as user">
  <a class="navbar-logo"><img src="assets/icons/logo.svg" alt="#" routerLink="/"></a>
  <div class="navbar-procedures"></div>
  <div class="flex navbar-info">
    <p>{{ user?.first_name }} {{ user?.last_name }}</p>
    <button mat-flat-button class="is-with-icon is-squared"  [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
      <mat-icon svgIcon="profile"></mat-icon>
    </button>
    <lib-contact-info *ngIf="showContactInfo"></lib-contact-info>
  </div>
</header>

<mat-menu #menu="matMenu" class="tr-profile-popup" xPosition="before" yPosition="above" [overlapTrigger]="false">
  <a *ngIf="!hideUserProfile" mat-menu-item routerLink="/user-profile">My Profile</a>
  <a mat-menu-item (click)="onLogout()">Sign Out</a>
</mat-menu>
