import * as t from 'io-ts';

export enum StudySource {
  profile = 'profile',
  userAgeYears = 'user_age_years',
  storedAnswer = 'stored_answer'
}
export const studySourceSchema = t.union([
  t.literal(StudySource.profile),
  t.literal(StudySource.userAgeYears),
  t.literal(StudySource.storedAnswer)
], 'studySourceSchema');
export type StudySourceType = t.TypeOf<typeof studySourceSchema>;
