import { Component } from '@angular/core';

import { exchangeContactInfoT1D } from 'tr-lib';

@Component({
  selector: 'app-get-in-touch',
  template: `
    <section class="get-in-touch" id="contact-section">
      <div class="get-in-touch-block">
        <img class="l-desktop" src="assets/img/get_in_touch_bg_element.png" alt="#">
        <img class="l-medium" src="assets/img/bg_pattern_mobile.png" alt="#">
        <h1>Get in touch with us!</h1>
        <p>We are available Monday to Friday from 8AM to 5PM (EST).</p>
      </div>
      <div class="get-in-touch-block">
        <img class="l-desktop" src="assets/img/bg_pattern.png" alt="#">
        <img class="l-medium" src="assets/img/get_in_touch_bg_element.png" alt="#">
        <div class="get-in-touch-block-item">
          <mat-icon svgIcon="email"></mat-icon>
          <a href="mailto:{{contactInfo.email}}">{{ contactInfo.email }}</a>
        </div>
        <div class="get-in-touch-block-item">
          <mat-icon svgIcon="phone"></mat-icon>
          <a href="tel:{{contactInfo.phone}}">{{ contactInfo.phone }}</a>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent {
  readonly contactInfo = exchangeContactInfoT1D;
}
