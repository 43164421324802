import { isRealValue, UserStudyType } from 'tr-lib';

import { isStudyAQ } from './dashboard';

export const magicDate20220403 = '2022-04-03';
export const getUserStudyReward = (usersStudy: UserStudyType): boolean => {
  let study_reward_on = usersStudy.study_reward_on;

  if (study_reward_on && isStudyAQ(usersStudy.study_type) && isRealValue(usersStudy.completed_at)) {
    const completedDate = new Date(usersStudy.completed_at);

    study_reward_on = completedDate.getTime() - new Date(magicDate20220403).getTime() > 0;
  }

  return study_reward_on;
};
