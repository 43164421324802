import { isRealValue, isStudyCoreOrAnnual, SurveyType, surveyType, UserStudyType } from 'tr-lib';

import { UserState } from '@store/reducers';
import { DashboardExtendedStatuses, DashboardExtendedStatus } from './dashboard-extended-status';
import { extendUsersStudy } from './user-study-extend';
import { isStudyInactiveOrClose, isStudyInProgressForParticipant, isStudyNotClosedBySystem } from './user-study';

export const sortResearchesInExtendedStatus = (
  a: DashboardExtendedStatus, b: DashboardExtendedStatus
) => a.currentProgress < b.currentProgress
  ? -1
  : a.currentProgress > b.currentProgress
    ? 1
    : 0;
export const sortDashboardExtendedStatusV2 = (a: DashboardExtendedStatus, b: DashboardExtendedStatus) => a.status - b.status;

export interface DashboardComponentState extends DashboardExtendedStatuses {
  info: UserState;
  begunStudies: number[];
  showBanner: boolean;
  isEligible: boolean;
}

export const isStudyCompletedByDashboardStatus = (s: string) => isRealValue(s) && ['completed', 'closed_by_system'].some(st => st === s);
export const isStudyCoreStudy = (sT: SurveyType): boolean => sT === surveyType.core_study;
export const isStudyAQ = (sT: SurveyType): boolean => sT === surveyType.annual_questionnaire;

const isStudyActiveForParticipant = (
  {isActiveStudy, study_status, status}: DashboardExtendedStatus
) => isActiveStudy && isStudyNotClosedBySystem(status) || isStudyInactiveOrClose(study_status) && isStudyInProgressForParticipant(status);
/**
 * splitAllStudies() splits the incoming studies from BE part using the documentation on the url
 * https://unitio.atlassian.net/wiki/spaces/RS/pages/2060681221
 *
 * @param userStudies
 * @param userID
 * @return DashboardExtendedStatuses
 */
export const splitAllStudies = (userStudies: UserStudyType[], userID: string): DashboardExtendedStatuses => {
  const allStudies = userStudies.map(study => extendUsersStudy(study, userID));
  const activeStudies = allStudies.filter(study => isStudyActiveForParticipant(study));
  const closedStudies = allStudies.filter(study => study.isStudyClosed);
  const completedStudies = allStudies.filter(({dashboard_status, is_eligible}) => 
  dashboard_status === 'completed' || is_eligible === false);

  const isCoreOrAnnualStudyCompleted = completedStudies.some(study =>
    study.study_type === 'core_study' || study.study_type === 'annual_questionnaire'
  );
  const filterCoreAnnualStudies = completedStudies.filter(({study_type}) => (
    study_type === 'core_study' || study_type === 'annual_questionnaire'
    )
  );
  const questionHistory = isCoreOrAnnualStudyCompleted ? filterCoreAnnualStudies : [];
  const availableStudies = allStudies.filter( 
    ({dashboard_status, study_status,
      isResearchOpportunity}) => dashboard_status === 'not_started' && study_status !== 'inactive' && !isResearchOpportunity
  );

  return {
    activeOpportunities: activeStudies.filter(study => study.isResearchOpportunity),
    activeStudies: activeStudies.filter(study => !study.isResearchOpportunity),
    availableStudies,
    closedStudies,
    questionHistory,
    completedStudies,
    coreStudies: allStudies.filter(({study_type}) => isStudyCoreOrAnnual(study_type)),
  };
}
