import { ofType } from '@ngrx/effects';
import { RouterAction, RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { OperatorFunction, pipe } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';

import { numberRegExp, uuidRegExp } from './constants';
import { isNonEmptyArray } from './helpers';
import { narrowingFilter } from './operators';

/**
 * @description curried function; check if action match route and action type; consume three arguments:
 * @param type - type of action to check
 */
export const isRouterActionOfType = <T, TInAction extends RouterAction<T>, TOutAction extends TInAction>
(type: TOutAction['type']) =>
  (route: string | string[]) =>
    (action: Action): action is TOutAction => {
      let result = false;

      if (action.type === type) {
        const routePath = (action as TOutAction).payload.routerState.url;

        result = Array.isArray(route) ? route.some(r => cleanupPath(routePath) === r) : cleanupPath(routePath) === route;
      }

      return result;
    };

/**
 * ofRouterAction('/builder/edit/:uuid') // /builder/edit/bdafc783-5c1f-4d7e-9767-3ef049c02cc4
 * ofRouterAction('/core-study/consent')
 * ofRouterAction(['/core-study/consent/status', 'core-study/consent/review/'])
 *
 * @param route
 */
export const ofRouterAction = (
  route: string | string[]
): OperatorFunction<Action, Action> => narrowingFilter(isRouterActionOfType(ROUTER_NAVIGATION)(route));

export const onLeaveRoute = (route: string | RegExp): OperatorFunction<Action, Action> => pipe(
  ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
  pairwise(),
  filter((
    [prev, curr]
  ) => !isNonEmptyArray((curr.payload.event.url as string).match(route)) && isNonEmptyArray(prev.payload.event.url.match(route))),
  map(([, snd]) => snd),
);

export const cleanupPath = (
  value: string
): string => value.split('?')[0].replace(uuidRegExp, ':uuid').replace(numberRegExp, ':id');
