import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, OptInSettings } from '@app/model';
import { combineLatest, Observable } from 'rxjs';
import { delay, filter, map, withLatestFrom } from 'rxjs/operators';

import { dateTimeMediumFormat, filterRealValues, isNonEmptyArray,
   ParticipantSourceType } from 'tr-lib';

import { UserState } from '@store/reducers';
import { sourcesToComponent } from '@app/model/constants';
import {
  DashboardComponentState,
  DashboardExtendedStatuses,
  DashboardExtendedStatus,
  DashboardStatuses,
  DashboardStudyStatusType,
  StudyDownloadParams,
  UserProcedureParams,
  IProcedure,
  ReviewAnswerStatusDashboard,
} from '@app/dashboard/models';
import {
  selectActiveOpportunities,
  selectActiveStudies,
  selectBegunStudies,
  selectClosedStudies,
  selectPendingAchievements,
  selectPendingStudies,
  selectQuestionHistory,
  selectQuestionnaireProcedure,
  selectSubmittedAnswers,
  selectUserStudiesWithStatusV2,
} from '@app/dashboard/store/dashboard.selectors';
import { getUser, signSmsConsent } from '@store/actions';
import { selectOptInSettings, selectProfileSource, selectUser } from '@store/selectors';
import { getPDFOfSubmittedAnswers, getQuestionnaireProcedure, getSubmittedAnswers,
   markStudyAsViewed, reactivateStudyRequest, 
  refreshUserStudies } from '@app/dashboard/store/dashboard.actions';
import { isRealValue } from '@tr-common';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class DashboardFacade {
  optInSettings$: Observable<OptInSettings> = this.store.select(selectOptInSettings);
  source$: Observable<ParticipantSourceType> = this.store.select(selectProfileSource);
  pendingAchievements$: Observable<boolean> = this.store.select(selectPendingAchievements);
  pendingStudies$: Observable<boolean> = this.store.select(selectPendingStudies);
  dashboard$: Observable<DashboardComponentState> = this.store.select(selectUserStudiesWithStatusV2).pipe(
    filter(({coreStudies}) => isNonEmptyArray(coreStudies)),
    withLatestFrom(
      this.store.select(selectUser).pipe(filterRealValues(), delay(0)),
      this.store.select(selectBegunStudies)
    ),
    map(([studyStatuses, info, begunStudies]: [DashboardExtendedStatuses, UserState, number[]]) => {
      const nonSortedStudies = isNonEmptyArray(studyStatuses?.coreStudies) ? [...studyStatuses.coreStudies] : [];
      const {completedStudies, activeStudies, availableStudies, questionHistory, activeOpportunities, closedStudies} = studyStatuses;
      const isEligible = info.is_eligible !== false;
      const coreStudies = nonSortedStudies.length > 1
        ? nonSortedStudies.sort((a, b) => (+a.id) - (+b.id))
        : nonSortedStudies;
      const showBanner = isEligible && coreStudies?.length > 0;

      return {
        completedStudies, activeStudies, availableStudies, activeOpportunities, closedStudies, questionHistory,
        begunStudies, coreStudies, isEligible, info, showBanner,
      };
    })
  );
  activeStudies$: Observable<DashboardStatuses> = this.store.select(selectActiveStudies);
  questionHistory$: Observable<DashboardStatuses> = this.store.select(selectQuestionHistory);
  activeOpportunities$: Observable<DashboardStatuses> = this.store.select(selectActiveOpportunities);
  closedStudies$: Observable<DashboardStatuses> = this.store.select(selectClosedStudies);


  reviewAnswersStatus$: Observable<ReviewAnswerStatusDashboard> = combineLatest([
    this.store.select(selectQuestionnaireProcedure).pipe(filter(isRealValue)),
    this.store.select(selectSubmittedAnswers).pipe(filter(isRealValue))
  ]).pipe(
    map(([{created_at}, content]: [IProcedure, string]) => {
      const dateTime = moment(created_at).format(dateTimeMediumFormat);

      return {dateTime, content};
    })
  );
  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>
  ) {}

  getUserInfo(): void {
    this.store.dispatch(getUser({source: sourcesToComponent.dashboard}));
  }

  signSmsConsent(phone: string): void {
    this.store.dispatch(signSmsConsent({phone}));
  }

  reactivateStudyRequest({id}: DashboardExtendedStatus): void {
    this.store.dispatch(reactivateStudyRequest({userStudyId: id}));
  }

  refreshUserStudies(payload: DashboardStudyStatusType): void {
    this.store.dispatch(refreshUserStudies({payload}));
  }

  markStudyAsViewed(userStudyId: string): void {
    this.store.dispatch(markStudyAsViewed({userStudyId}));
  }

  getSubmittedAnswers(byPDF = false, toPrint = false, params: StudyDownloadParams): void {
    this.store.dispatch(byPDF ? getPDFOfSubmittedAnswers({toPrint, ...params}) : getSubmittedAnswers({...params}));
  }

  getQuestionnaireProcedure(params: UserProcedureParams): void {
    this.store.dispatch(getQuestionnaireProcedure({...params}));
  }
  
}
