import { Answers, isNonEmptyArray } from '@tr-common';

import { ParticipantDetails } from '../../models';
import { StudyQuestionRuleType } from '../../models/rules';
import { StudyQuestions } from '../../models/study-question';
import { AnsweredQuestion } from './answered-question';

export type FilterQuestionsByRulesFunction = (
  questions: StudyQuestions, answers: Answers, rules: StudyQuestionRuleType[], participant: ParticipantDetails
) => AnsweredQuestion[];

export const filterQuestionsByRules: FilterQuestionsByRulesFunction = (
  questions: StudyQuestions, answers: Answers, rules: StudyQuestionRuleType[], participant: ParticipantDetails
) => (isNonEmptyArray(questions) ? questions.map(question => new AnsweredQuestion(
  question, rules.filter((rule: StudyQuestionRuleType) => rule.question === question.id), answers && answers[question.id]
)) : []).filter((answeredQuestion: AnsweredQuestion) => {
    /**
     * Here is a list of all the cases for the fields StudyRule.rule_action and StudyQuestion.visible_by_default
     * to hide or show a question
     *
     * Case |ifConditionsMet | Rule Action | Visible by default | Result
     * -----------------------------------------------------------------
     * 1    | <omitted>      | <not used>  | True               | Show
     * 2    | <omitted>      | <not used>  | False              | Hide
     * 3    | False          | <not used>  | True               | Show
     * 4    | False          | <not used>  | False              | Hide
     * 5    | True           | Show        | <not used>         | Show
     * 6    | True           | Hide        | <not used>         | Hide
     * -----------------------------------------------------------------
     */
    let result = answeredQuestion.visible_by_default;

    if (answeredQuestion.ifConditionsMet(participant, answers)) {
      result = !answeredQuestion.isRuleHideQuestion;
    }

    return result;
  }
);
