import { createAction, props } from '@ngrx/store';

import { Consent, ConsentBody, ConsentStatusType } from '../../../consent/models';
import {
  IntroPageType,
  IStudyProcedure,
  MinimalStudyType,
  ParticipantDetails,
  ProcedureQuestions,
  UserStudyProcedureType,
} from '../../../models';
import { UserStudyType } from '../../models';
import { StudyQuestionRuleType } from '../../../models/rules';

export enum consentIntroActions {
  LOAD_STUDY_PARTIALLY = '[Consent] Load study and participant information partially to show a consent with headers properly',
  LOAD_STUDY_PARTIALLY_STAGE2 = '[Consent] Continue loading study information partially to show a consent with headers properly',
  LOAD_STUDY_PARTIALLY_SUCCESS = '[Consent] Loaded study and participant information partially to show a consent success',
  LOAD_STUDY_PARTIALLY_FAIL = '[Consent] Loaded study and participant information partially to show a consent fail',
  LOAD_INTRO = '[Intro] Load Intro Page',
  LOAD_INTRO_SUCCESS = '[Intro] Loaded Intro Page Success',
  LOAD_INTRO_FAIL = '[Intro] Loaded Intro Page Fail',
  LOAD_CONSENTS = '[Consent] Load Consents',
  LOAD_CONSENTS_SUCCESS = '[Consent] Load Consents Success',
  LOAD_CONSENTS_FAIL = '[Consent] Load Consents Fail',
  DEFINE_UNSIGNED_CONSENTS = '[Consent] Define any unsigned consents',
  LOAD_CONSENT_BODY = '[Consent] Load Consent Body',
  LOAD_CONSENT_BODY_SUCCESS = '[Consent] Load Consent Body Success',
  LOAD_CONSENT_BODY_FAIL = '[Consent] Load Consent Body Fail',
  REDIRECT_IF_NO_CONSENTS = '[Consent] Redirect to the no-consent page',
  LOAD_JAEB_CONSENT_BODY = '[Consent] Load Jaeb Consent Body',
  LOAD_JAEB_CONSENT_BODY_SUCCESS = '[Consent] Load Jaeb Consent Body Success',
  LOAD_JAEB_CONSENT_BODY_FAIL = '[Consent] Load Jaeb Consent Body Fail',
  SIGN_JAEB_CONSENT = '[Consent] Sign Consent Jaeb',
  SIGN_CONSENT = '[Consent] Sign Consent',
  SIGN_CONSENT_SUCCESS = '[Consent] Sign Consent Success',
  SIGN_CONSENT_FAIL = '[Consent] Sign Consent Fail',
  PROCEED_WITH_CONSENT_DATA = '[Consent] Proceed with consent data',
  CHECK_CONSENT_STUDY_TYPE = '[Consent] Check Consent`s study type',
  REVIEW_CONSENT = '[Consent] Review Consent',
  DOWNLOAD_CONSENT = '[Consent] Download Consent',
  PRINT_CONSENT = '[Consent] Print Consent',
  ADDITIONAL_CONSENT_ON_PUBLIC = '[Consent] Require signing an additional consent on Public',
  JAEB_JUMPS_TO_QUESTIONNAIRE = '[Consent] Jaeb user jumps to questionnaire in CS',
  CONTINUE = '[Intro] User jumps to a latest question from the INTRO procedure status',
}

export const loadStudyPartially = createAction(
  consentIntroActions.LOAD_STUDY_PARTIALLY,
  props<{userID: string; studyID: string}>()
);

export const loadStudyPartiallyStage2 = createAction(
  consentIntroActions.LOAD_STUDY_PARTIALLY_STAGE2,
  props<{participant: ParticipantDetails; userStudy: UserStudyType; studyProcedures: IStudyProcedure[]}>()
);

export const loadStudyPartiallySuccess = createAction(
  consentIntroActions.LOAD_STUDY_PARTIALLY_SUCCESS,
  props<{
    study: MinimalStudyType;
    participant: ParticipantDetails;
    userStudy: UserStudyType;
    studyProcedures: IStudyProcedure[];
    userProcedures: UserStudyProcedureType[];
    questions: ProcedureQuestions;
    rules: StudyQuestionRuleType[];
  }>()
);

export const loadStudyPartiallyFail = createAction(
  consentIntroActions.LOAD_STUDY_PARTIALLY_FAIL,
  props<{error: unknown}>()
);

export const loadIntroPage = createAction(
  consentIntroActions.LOAD_INTRO,
  props<{checkAndRedirectToStatus: boolean}>()
);

export const loadIntroPageSuccess = createAction(
  consentIntroActions.LOAD_INTRO_SUCCESS,
  props<{payload: IntroPageType, checkAndRedirectToStatus: boolean}>()
);

export const loadIntroPageFail = createAction(
  consentIntroActions.LOAD_INTRO_FAIL,
  props<{error: unknown}>()
);

export const signConsent = createAction(
  consentIntroActions.SIGN_CONSENT,
  props<{name: string, id: number}>()
);

export const signJaebConsent = createAction(
  consentIntroActions.SIGN_JAEB_CONSENT,
  props<{id: number}>()
);

export const signConsentSuccess = createAction(
  consentIntroActions.SIGN_CONSENT_SUCCESS,
  props<{payload: ConsentStatusType}>()
);

export const signConsentFail = createAction(
  consentIntroActions.SIGN_CONSENT_FAIL,
  props<{error: unknown}>()
);

export const loadConsents = createAction(
  consentIntroActions.LOAD_CONSENTS
);

export const loadConsentsSuccess = createAction(
  consentIntroActions.LOAD_CONSENTS_SUCCESS,
  props<{payload: Consent[]}>()
);

export const loadConsentsFail = createAction(
  consentIntroActions.LOAD_CONSENTS_FAIL,
  props<{error: any}>()
);

export const defineUnsignedConsents = createAction(
  consentIntroActions.DEFINE_UNSIGNED_CONSENTS,
  props<{payload: Consent[], studyID: string, userStudyID: string}>()
);

export const loadConsentBody = createAction(
  consentIntroActions.LOAD_CONSENT_BODY,
  props<{id: number}>()
);

export const loadConsentBodySuccess = createAction(
  consentIntroActions.LOAD_CONSENT_BODY_SUCCESS,
  props<{consentId: number, payload: ConsentBody}>()
);

export const loadConsentBodyFail = createAction(
  consentIntroActions.LOAD_CONSENT_BODY_FAIL,
  props<{payload: any}>()
);

export const redirectToNoConsentPage = createAction(
  consentIntroActions.REDIRECT_IF_NO_CONSENTS
);

export const loadJaebConsentBody = createAction(
  consentIntroActions.LOAD_JAEB_CONSENT_BODY,
  props<{id: number}>()
);

export const loadJaebConsentBodySuccess = createAction(
  consentIntroActions.LOAD_JAEB_CONSENT_BODY_SUCCESS,
  props<{consentId: number, payload: ConsentBody}>()
);

export const loadJaebConsentBodyFail = createAction(
  consentIntroActions.LOAD_JAEB_CONSENT_BODY_FAIL,
  props<{payload: unknown}>()
);

export const proceedWithConsentData = createAction(
  consentIntroActions.PROCEED_WITH_CONSENT_DATA,
);

export const checkConsentStudyType = createAction(
  consentIntroActions.CHECK_CONSENT_STUDY_TYPE,
  props<{payload: boolean}>()
);

export const reviewConsent = createAction(
  consentIntroActions.REVIEW_CONSENT,
  props<{consentId: string}>()
);

export const downloadConsent = createAction(
  consentIntroActions.DOWNLOAD_CONSENT,
  props<{consent: Consent}>()
);

export const printConsent = createAction(
  consentIntroActions.PRINT_CONSENT,
  props<{consentId: string}>()
);

export const additionalConsentOnPublic = createAction(
  consentIntroActions.ADDITIONAL_CONSENT_ON_PUBLIC,
  props<{require: boolean}>()
);

export const jaebJumpsToQuestionnaire = createAction(
  consentIntroActions.JAEB_JUMPS_TO_QUESTIONNAIRE
);

export const introContinue = createAction(
  consentIntroActions.CONTINUE
);
