import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { isNullOrUndefined, isRealValue } from '@tr-common';

import { BrowserGlobalRef } from '../../global/registry-global';

@Injectable({providedIn: 'root'})
export class SurveyVersionInterceptor implements HttpInterceptor {
  actualVersion: string = null;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(response => {
        if (response instanceof HttpResponse && isRealValue(response['headers'].get('X-TR-VERSION'))) {
          const versionFromHeader = response['headers'].get('X-TR-VERSION');

          if (isNullOrUndefined(this.actualVersion)) {
            this.actualVersion = versionFromHeader;
          } else if (this.actualVersion !== versionFromHeader) {
            console.warn(`Warning: BE Version is changed from ${this.actualVersion} to ${versionFromHeader}. Start reloading...`);
            new BrowserGlobalRef().reload();
          }
        }
      })
    );
  }
}
