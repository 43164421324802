import { isRealValue, OptionAnswerType } from '@tr-common';

import { TreeNode } from '../../models';
import { StudyOption } from '../../models/study-option';
import { BuildTreeSourceData, getQuestionAnswers } from './build-tree.helpers';

/**
 * special case for dropdowns
 * all dropdown options are converted to 1 option with dropdownOptions field
 * selected option id is stored in value field and is prefilled from questionAnswers
 *
 * @param sourceData
 * @param dropdownOptions
 * @param parent node
 * @param nodeIndex
 * @param parentOptionIndex
 */
export const generateDropdownNode = (
  sourceData: BuildTreeSourceData, dropdownOptions: StudyOption[], parent: TreeNode, nodeIndex: number, parentOptionIndex?: number,
): [] | [TreeNode] => {
  let dropdownNode: [] | [TreeNode] = [];

  if (dropdownOptions.length > 0) {
    const questionAnswer = getQuestionAnswers(sourceData);
    const optionAnswer = questionAnswer.find(q => dropdownOptions.some(({id}) => id === q.question_option));

    dropdownNode = [new TreeNode<OptionAnswerType>({
      dropdownOptions, showHint: parent.showHint, nodeIndex, parentOptionIndex, parent, isQuestion: false,
      questionID: sourceData.question.id,
      value: isRealValue(optionAnswer) ? {question_option: optionAnswer.question_option, value: optionAnswer.value === 'true'} : null
    })];
  }

  return dropdownNode;
};
