import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { ClosedStudyStatus, isStudyClosedForParticipant, UserStudyType } from '../../models';
import { hasStudyIntroProcedure, isPromotionalStudy, MinimalStudyType, Procedures } from '../../../models';
import { StudyFacade } from '../../store';
import { SurveyUrlFactoryProxy } from '../../services/survey-url-factory.service';

@Component({
  selector: 'lib-closed-study',
  templateUrl: './closed-study.component.html',
  styleUrls: ['./closed-study.component.scss']
})
export class ClosedStudyComponent implements OnInit {
  closedStudyStatus$: Observable<ClosedStudyStatus> = this.studyFacade.userStudy$.pipe(
    withLatestFrom(this.studyFacade.study$, this.studyFacade.procedures$),
    map(([userStudy, study, procedures]: [UserStudyType, MinimalStudyType, Procedures]) => ({
      study, userStudy, isResearchStudy: isPromotionalStudy(study.study_type),
      isROStudyClosedForParticipant: isStudyClosedForParticipant(userStudy) && !hasStudyIntroProcedure(procedures)
    })),
    tap(({isROStudyClosedForParticipant}) => !isROStudyClosedForParticipant && this.studyFacade.startSurveyFromRoot())
  );

  constructor(
    public urls: SurveyUrlFactoryProxy,
    public studyFacade: StudyFacade,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.studyFacade.getUserStudy();
  }
}
