import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { exchangeContactInfoT1D, SessionStorage, STORAGE, storageKeys } from 'tr-lib';

import { logout } from '@store/actions';
import { AppState } from '@app/model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isDashboard: boolean;
  @Input() noNavButtons: boolean;
  @Input() isJAEBMode = false;
  readonly contactInfo = exchangeContactInfoT1D;
  readonly termOfUseUrl = environment.termOfUseUrl;
  readonly privacyPolicyUrl = environment.privacyPolicyUrl;
  isLoggedIn: boolean;

  constructor(
    @Inject(STORAGE) private storage: SessionStorage<typeof storageKeys.token>,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = typeof this.storage.getItem(storageKeys.token) === 'string';
  }

  onLogout(): void {
    this.store.dispatch(logout({}));
  }
}
