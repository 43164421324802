import { Component } from '@angular/core';

@Component({
  selector: 'lib-society-info',
  template: `
    <h4>Follow us</h4>
    <lib-social-icons></lib-social-icons>
  `,
  styleUrls: ['./society-info.component.scss']
})
export class SocietyInfoComponent {}
