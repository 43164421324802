<section class="tr-description">
  <h1>How does the Registry work?</h1>
  <div class="tr-description-block">
    <a routerLink="/auth/register" class="tr-description-stepper light">
      <mat-icon svgIcon="create-account"></mat-icon>
      <div>
        <h4>Step 1</h4>
        <h3>Create an account and see if you’re eligible</h3>
      </div>
    </a>
    <a routerLink="/auth/register" class="tr-description-stepper tertiary">
      <mat-icon svgIcon="sign-consent"></mat-icon>
      <div>
        <h4>Step 2</h4>
        <h3>Read and sign the consent form</h3>
      </div>
    </a>
    <a routerLink="/auth/register" class="tr-description-stepper primary">
      <mat-icon svgIcon="complete"></mat-icon>
      <div>
        <h4>Step 3</h4>
        <h3>Complete a questionnaire</h3>
      </div>
    </a>
  </div>
  <div class="tr-description-button">
    <button mat-flat-button color="primary" routerLink="/auth/register" class="is-shadowed">Get Started</button>
  </div>
</section>
<section class="tr-description">
  <h1>Previous T1D Exchange research has led to:</h1>
  <div class="tr-description-block mt-100">
    <div class="tr-description-item">
      <mat-icon svgIcon="blood"></mat-icon>
      <h5>Insurance coverage for blood glucose meter strips.</h5>
    </div>
    <div class="tr-description-item">
      <mat-icon svgIcon="heartbeat"></mat-icon>
      <h5>Changes in American Diabetes Association guidelines for A1c goals for pediatric patients.</h5>
    </div>
    <div class="tr-description-item">
      <mat-icon svgIcon="finger-stick"></mat-icon>
      <h5>Food and Drug Administration’s decision to expand the Dexcom CGM labeling to include fingerstick replacement.</h5>
    </div>
    <div class="tr-description-item">
      <mat-icon svgIcon="cgm-device"></mat-icon>
      <h5>Medicare coverage of CGM devices.</h5>
    </div>
  </div>
</section>
