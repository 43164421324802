import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

import { isNonEmptyString } from '@tr-common';

import { IProcedure, isStudyBlocked, isStudyHeaderBlocked, MinimalStudyType, UserStudyProcedureType } from '../../models';
import { UserStudyType } from '../models';

export const surveyIntroPageSchema = t.type({
  content: t.string,
  title: t.string,
  button: t.string,
  approximateTime: t.union([t.string, t.number]),
  isCompleted: t.boolean,
  closedOn: t.union([t.string, DateFromISOString]),
  studyIsClosed: t.boolean,
  studyIsBlocked: t.boolean,
  numberOfUsers: t.number
}, 'surveyIntroPageSchema');

export const surveyIntroOrResearchPageSchema = t.intersection([
  surveyIntroPageSchema,
  t.type({
    id: t.string,
  }),
  t.partial({
    launchOn: t.union([t.string, DateFromISOString]),
    userID: t.string,
    userStudyID: t.string,
    url: t.string,
    isResearchOpportunity: t.boolean,
    add_participant_id_query_param: t.boolean,
  }
)], 'surveyIntroOrResearchPageSchema');
export type SurveyIntroOrResearchPageType = t.TypeOf<typeof surveyIntroOrResearchPageSchema>;

export const composeStatusOfIntroOrResearch = (
  content: string, study: MinimalStudyType, {dashboard_status}: UserStudyType, {id, created_at}: IProcedure, userProcedures: UserStudyProcedureType[]
): SurveyIntroOrResearchPageType => {
  const {status, title, approximate_complete_time, launch_on, closed_at, number_of_users_goal} = study;
  const isCompleted = isNonEmptyString(created_at);
  const buttonTextIfStudyCompleted = dashboard_status === 'completed' ? 'View Details' : 'Resume study';

  return {
    id, content, title, isCompleted,
    button: isCompleted ? buttonTextIfStudyCompleted : 'Start study',
    approximateTime: approximate_complete_time,
    launchOn: launch_on,
    closedOn: closed_at,
    studyIsClosed: status === 'closed',
    studyIsBlocked: study.study_type === 'promotional_study'
      ? isStudyBlocked(status, dashboard_status)
      : isStudyHeaderBlocked(status, userProcedures),
    numberOfUsers: number_of_users_goal,
  };
}
