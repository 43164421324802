import { GoogleAnalyticEvent } from './google-analytics-event';

export interface RegistryGlobal extends Window {
  env: string;
  recaptchaSiteToken: string;
  urlToOtherPart: string;
  t1dLoginButton: boolean;
  fbq: (track: 'track', PageView: 'PageView') => void;
  gtag: () => void;
  ga: (action: string, type: string, payload?: string | GoogleAnalyticEvent) => void;
  dataLayer: Array<unknown>;
  _hsq: Array<unknown>;
}

export abstract class GlobalRef<T = RegistryGlobal> {
  abstract get nativeGlobal(): T;
  abstract remove(k: keyof T): void;
  abstract reload(): void;
}

export class BrowserGlobalRef<T extends RegistryGlobal> extends GlobalRef<T> {
  get nativeGlobal(): T {
    return (window as unknown) as T;
  }

  remove(key: keyof T): void {
    if ((window as unknown)[key]) {
      try {
        delete (window as unknown)[key];
      } catch (e) {
        console.warn(`Could not remove ${key.toString()} from window`, e);
      }
    }
  }

  reload(): void {
    window.location.reload();
  }
}
