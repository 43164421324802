import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { isRealValue } from '@tr-common';

/** Error when invalid control is dirty or touched*/
export class WidgetErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl<unknown> | FormGroup<unknown> | null, form: FormGroupDirective | NgForm | null): boolean {
    return isRealValue(control) && control.invalid && (control.dirty || control.touched);
  }
}
