import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

import { isNullOrUndefined } from '@tr-common';

import { ValidateControlFuncType, ValidateControlsFuncType, ValidateResults } from './validate-common';

export const matchOtherValidator = (otherControlName: string, validationFunc: ValidateControlsFuncType): ValidateControlFuncType => {
  let thisControl: FormControl<unknown>;
  let otherControl: FormControl<unknown>;

  return (control: FormControl<unknown>): ValidateResults => {

    if (isNullOrUndefined(control.parent)) {
      return null;
    }

    // Initializing the validator.
    if (isNullOrUndefined(thisControl)) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl<unknown>;
      if (isNullOrUndefined(otherControl)) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      // TODO should implement unsubscribe
      otherControl.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }

    if (isNullOrUndefined(otherControl)) {
      return null;
    }

    return validationFunc(thisControl, otherControl);
  };
}

export const matchFields = (
  {value}: FormControl<unknown>, other: FormControl<unknown>
): ValidateResults => value !== other.value ? {match: true} : null;

export const matchFieldsWithLowerCase = (
  {value}: FormControl<string>, other: FormControl<string>
): ValidateResults => value?.toLowerCase() !== other.value?.toLowerCase() ? {match: true} : null;
/**
 * error field 'confirmError'
 *
 * @param otherFormField
 * @param targetFormField
 * @param errorText
 */
export const matchValidator = (
  otherFormField: string, targetFormField: string, errorText = `Doesn't match`
): ValidationErrors => ({controls}: UntypedFormGroup) => {
  const otherControl = controls[otherFormField];
  const targetControl = controls[targetFormField];
  const errors = targetControl.value === otherControl.value ? null : {confirmError: errorText};

  targetControl.setErrors(errors);
  targetControl.markAsTouched();

  return errors;
};

export function newPasswordNotSameAsOldValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }
    const oldPassword = control.parent.get('old_password');
    if (!oldPassword) {
      return null;
    }
    return control.value === oldPassword.value ? { sameAsOld: true } : null;
  };
}

export function triggerNewPasswordValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }
    const newPassword = control.parent.get('new_password');
    if (newPassword) {
      newPassword.updateValueAndValidity();
    }
    return null;
  };
}