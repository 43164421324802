import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { isRealValue, ProcedureTemplateTypes, TypeConsent } from '@tr-common';

import { hasAllParamIDs, isJAEB } from '../../models';
import { Consent, ConsentToSignWithPending, SignedConsentsWithPending } from '../../../consent/models';
import {
  changeActiveProcedure,
  downloadConsent,
  jaebJumpsToQuestionnaire,
  loadConsents,
  loadStudyPartially,
  printConsent,
  reviewConsent,
  selectConsent,
  selectConsentError,
  selectConsentPending,
  selectConsents,
  selectFullStudyLoading,
  selectJaebConsent,
  selectParamsIDs,
  selectParticipantDetails,
  selectUrlConsentID,
  signConsent,
  signJaebConsent,
  submitConsentProcedureForcibly,
} from '../index';
import { SurveyConsentState } from '../reducers';

@Injectable({providedIn: 'root'})
export class StudyConsentFacade {
  paramsForSignedConsent$ = combineLatest([
    this.store.select(selectParamsIDs).pipe(filter(hasAllParamIDs)), this.store.select(selectUrlConsentID)
  ]).pipe(
    map(([{userID}, consentID]) => ({consentID, userID}))
  );
  consentPending$: Observable<boolean> = this.store.select<boolean>(selectConsentPending);
  consentError$: Observable<boolean> = this.store.select<boolean>(selectConsentError);
  consentBodyJAEB$: Observable<string> = this.store.select(selectJaebConsent);
  consentStatus$: Observable<ConsentToSignWithPending> = combineLatest([
    this.store.select(selectConsent).pipe(filter(data => isRealValue(data?.id))),
    this.store.select(selectConsentPending),
    this.store.select(selectFullStudyLoading),
    this.store.select(selectParticipantDetails)
  ]).pipe(map((
    [{id, consent, agreement_text, isChildConsent}, pending, fullStudyLoading, profile]
  ) => ({id, consent, agreement_text, isChildConsent, pending: pending || fullStudyLoading, isJAEB: isJAEB(profile?.source)})));
  consentsStatus$: Observable<SignedConsentsWithPending> = combineLatest([
    this.store.select(selectConsents),
    this.store.select(selectConsentPending)
  ]).pipe(
    map(([consents, pending]) => ({
      pending, consents,
      jaebConsents: consents.filter(cs => cs.consent_type === TypeConsent.jaeb),
      registryConsents: consents.filter(cs => cs.consent_type !== TypeConsent.jaeb)
    }))
  );

  constructor(private store: Store<SurveyConsentState>) {}

  loadStudyPartially(userID: string, studyID: string): void {
    this.store.dispatch(loadStudyPartially({userID, studyID}));
  }

  loadConsents(): void {
    this.store.dispatch(loadConsents());
  }

  signConsent(name: string, id: number): void {
    this.store.dispatch(signConsent({name, id}));
  }

  signJaebConsent(id: number): void {
    this.store.dispatch(signJaebConsent({id}));
  }

  forceSubmitConsentProcedure(): void {
    this.store.dispatch(submitConsentProcedureForcibly());
  }

  gotoQuestionnaire(): void {
    this.store.dispatch(changeActiveProcedure({payload: ProcedureTemplateTypes.questionnaire}));
    this.store.dispatch(jaebJumpsToQuestionnaire());
  }

  reviewConsent(consentId: string): void {
    this.store.dispatch(reviewConsent({consentId}));
  }

  downloadConsent(consent: Consent): void {
    this.store.dispatch(downloadConsent({consent}));
  }

  printConsent(consentId: string): void {
    this.store.dispatch(printConsent({consentId}));
  }
}
