import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { Moment, MomentInput } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { dateTimeMediumFormat, filterRealValues, isRealValue, ModuleSettings, ModuleStage, ProcedureTemplateTypes } from '@tr-common';

import { IProcedure, procedureHasQuestions, ProcedureStatusType, studyState } from '../../../models';
import { hasAllParamIDs, ReviewAnswerStatus } from '../../models';
import {
  clearSubmittedAnswers,
  flowReviewAnswers,
  getPDFOfSubmittedAnswers,
  getSubmittedAnswers,
  redirectToProcedureStatus,
  selectActiveProcedure,
  selectIsEligible,
  selectModuleSettings,
  selectNextActiveProcedure,
  selectParamsIDs,
  selectStudy,
  selectSubmitPending,
  selectSubmittedAnswers,
  SurveyState,
} from '../index';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

@Injectable({providedIn: 'root'})
export class StudyStatusFacade {
  procedureStatus$: Observable<ProcedureStatusType> = combineLatest([
    this.store.select(selectModuleSettings).pipe(filterRealValues()),
    this.store.select(selectStudy).pipe(filterRealValues()),
    this.store.select(selectIsEligible),
    this.store.select(selectActiveProcedure).pipe(filterRealValues()),
    this.store.select(selectNextActiveProcedure),
    this.store.select(selectParamsIDs).pipe(filter(hasAllParamIDs)),
    this.store.select(selectSubmitPending)
  ]).pipe(
    map(([
      settings, {status}, isEligible, procedure,
      upcomingProcedure, {userID, userStudyID}, submitPending
    ]) => {
      const nextProcedureTitle = isEligible === false ? null : get(upcomingProcedure, 'title', 'Consent');
      const studyIsClosed = status === studyState.closed;
      const backButtonTitle = 'Back to Dashboard';
      const isResearch = procedure.id === ProcedureTemplateTypes.research;
      const showBanner = isResearch && studyIsClosed;
      const hasQuestions = procedureHasQuestions(procedure.id as ProcedureTemplateTypes);
      const isQuestionnaire = procedure.id === ProcedureTemplateTypes.questionnaire;
      const setting = settings[procedure.id]?.find(({module_type, stage}: ModuleSettings) =>
        module_type === 'page' && stage === (isRealValue(procedure.created_at) ? ModuleStage.conclusion : ModuleStage.confirmation)
      );

      return {
        ...procedure,
        userID, userStudyID, isResearch, isQuestionnaire, hasQuestions, nextProcedureTitle, setting, showBanner, backButtonTitle,
        submitPending
      } as ProcedureStatusType;
    })
  );
  reviewAnswersStatus$: Observable<ReviewAnswerStatus> = combineLatest([
    this.store.select(selectActiveProcedure).pipe(filter(isRealValue)),
    this.store.select(selectSubmittedAnswers).pipe(filter(isRealValue))
  ]).pipe(
    map(([{title, created_at}, content]: [IProcedure, string]) => {
      const dateTime = moment(created_at).format(dateTimeMediumFormat);

      return {title, dateTime, content};
    })
  );

  constructor(
    private store: Store<SurveyState>
  ) {}

  reviewAnswers(procedure: string): void {
    this.store.dispatch(flowReviewAnswers({procedure}));
  }

  getSubmittedAnswers(byPDF = false, toPrint = false): void {
    this.store.dispatch(byPDF ? getPDFOfSubmittedAnswers({toPrint}) : getSubmittedAnswers());
  }

  clearSubmittedAnswers(): void {
    this.store.dispatch(clearSubmittedAnswers());
  }

  redirectToProcedureStatus(procedure: string): void {
    this.store.dispatch(redirectToProcedureStatus({procedure}));
  }
}
