import * as t from 'io-ts';

export type SelectItemType<T = string> = {
  label: string;
  value: T;
}
export type SelectItemsType<T = string> = SelectItemType<T>[];

export const labelIdPairSchema = t.type({
  label: t.string,
  id: t.string
}, 'labelIdPairSchema');
export type LabelIdPairType = t.TypeOf<typeof labelIdPairSchema>;
