import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { isRealValue, ValidatorType } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption } from '../../../models/study-option';

@Component({
  selector: 'lib-base-for-option-and-radio',
  template: ``
})
export class BaseCheckboxAndRadioComponent implements WidgetPrototype<boolean>, OnChanges {
  @Input() option: StudyOption;
  @Input() value: boolean;
  @Output() modify = new EventEmitter<WidgetModify<boolean>>();
  checked = false;
  valid: ValidatorType = 'default';

  emitState(source: SourceChangeType): void {
    this.modify.emit({value: this.checked, valid: this.valid, source});
  }

  ngOnChanges({value}: SimpleChanges): void {
    if (isRealValue(value)) {
      this.checked = value.currentValue === true || value.currentValue === 'true';
      if (!value.firstChange) this.valid = 'VALID';
    }
  }

  onChange(value: boolean): void {
    this.valid = 'VALID'; this.checked = value; this.emitState(SourceChange.user);
  }
}
