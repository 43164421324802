import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-question-list',
  template: `
    <div class="close-button-wrapper">
      <button mat-flat-button class="survey-button is-squared is-with-icon" color="secondary" [mat-dialog-close]="null">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>

    <div mat-dialog-content>
      <lib-question-list (question)="toQuestion($event)"></lib-question-list>
    </div>
  `,
  styleUrls: ['./dialog-question-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogQuestionListComponent {
  constructor(public dialogRef: MatDialogRef<DialogQuestionListComponent>) {}

  toQuestion(id: string): void {
    this.dialogRef.close(id);
  }
}
