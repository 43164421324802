import { Injectable, Injector } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, Subject } from 'rxjs';
import { concatMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { isNonEmptyArray, isRealValue } from '@tr-common';

import { getDefaultProcedure, hasStudyIntroProcedure } from '../../../models';
import { TrackingService } from '../../../services';
import {
  findFirstUnansweredQuestionWithValidation,
  isProcedureJustSubmitted,
  isStudyClosedForParticipant,
  OptRedirectParamsType,
  ParamsIDs,
  SurveyPreselect,
} from '../../models';
import { SurveyDialogsService } from '../../services/survey-dialog.service';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import {
  changeActiveProcedure,
  changeActiveQuestion,
  clickResearchProcedure,
  clickResearchProcedureSuccess,
  findUnansweredParentQuestionId,
  finishPending,
  finishProcedure,
  flowPreviewAnswers,
  loadIntroPage,
  navigateToQuestion,
  nextUnansweredQuestion,
  preselectConsent,
  preselectProcedure,
  preselectQuestion,
  selectActiveProcedure,
  selectActiveProcedureID,
  selectActiveProcedureParentFilteredQuestionsByRules,
  selectAnswers,
  selectParamsIDs,
  selectParticipantDetails,
  selectPreviewMode,
  selectProcedures,
  selectStudy,
  selectUrlProcedure,
  selectUserStudy,
} from '../index';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class ProcedureEffects {
  actions$ = new Subject<Action>();

  preselectProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(preselectProcedure),
    withLatestFrom(
      this.store.select(selectParticipantDetails),
      this.store.select(selectUserStudy),
      this.store.select(selectProcedures),
      this.store.select(selectAnswers),
    ),
    concatMap(([{study}, profile, userStudy, procedures, answers]) => {
      const procedure = getDefaultProcedure(userStudy, procedures, answers, study);
      const isStudyClosed = isStudyClosedForParticipant(userStudy);
      const isUserProcedureCompleted = isRealValue(procedure.created_at);
      let outcomeActions: Action[] = [changeActiveProcedure({payload: procedure.id})];

      // console.log({handler: 'preselectProcedure', isStudyClosed, procedure, userStudy});
      if (isStudyClosed && !hasStudyIntroProcedure(procedures)) {
        const params: ParamsIDs = {userID: profile.id, userStudyID: userStudy.id.toString(), studyID: study.id};

        void this.surveyRouter.navigateToClosedStudyPage(params);
      } else if (isUserProcedureCompleted) {
        const opt: OptRedirectParamsType = {study, profile, userStudy};

        void this.surveyRouter.navigateToProcedureStatus({...opt, queryParams: {procedure: procedure.id}}, true);
      } else {
        outcomeActions = [
          ...outcomeActions,
          preselectQuestion({payload: SurveyPreselect.regular}),
          ...(procedure.isIntro ? [loadIntroPage({checkAndRedirectToStatus: true})] : []),
          ...(procedure.isConsent ? [preselectConsent()] : [])
        ];
        void this.surveyRouter.navigateToProcedureInCurrentStudy(procedure.id);
      }

      return outcomeActions;
    }),
  ));

  /**
   * This effect operates not on a single question level as i.e. answers save, but on a level of a study procedure and
   * inter-question navigation. Because of these reasons this effect grouped with procedures effects and not with question effects.
   */
  preselectQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(preselectQuestion),
    withLatestFrom(
      this.store.select(selectStudy),
      this.store.select(selectUserStudy),
      this.store.select(selectParticipantDetails),
      this.store.select(selectActiveProcedure),
      this.store.select(selectActiveProcedureParentFilteredQuestionsByRules),
      this.store.select(selectParamsIDs),
    ),
    filter(([,, userStudy]) => userStudy?.is_eligible !== false),
    map((
      [, study,, profile, procedure, questions, {userStudyID}]
    ) => ({study, profile, procedure, questions, userStudyID})),
    tap(({study, profile, procedure, questions, userStudyID}) => {
      // console.log({handler: 'preselectQuestion$', study, userStudyID, procedure, questions});
      if (isRealValue(procedure.created_at)) {
        this.surveyRouter.navigateToProcedureStatus({
          studyId: study.id, profileId: profile.id, userStudyId: userStudyID, queryParams: {procedure: procedure.id}
        }, false, isProcedureJustSubmitted(procedure)).then(() => this.store.dispatch(finishPending()));
      } else {
        const questionId = findFirstUnansweredQuestionWithValidation(questions);

        if (questionId) {
          this.surveyRouter.navigateToQuestionInCurrentStudy({procedureId: procedure.id, questionId}).then(() => this.store.dispatch(finishPending()));
        } else {
          const paramsIDs: ParamsIDs = {studyID: study.id, userID: profile.id, userStudyID};

          if (questions.length > 0) {
            this.surveyRouter.redirectToProcedureSubmit(paramsIDs, procedure.id).then(() => this.store.dispatch(finishPending()));
          }
        }
      }
    }),
  ), {dispatch: false});

  finishProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(finishProcedure),
    withLatestFrom(
      this.store.select(selectStudy),
      this.store.select(selectParticipantDetails),
      this.store.select(selectParamsIDs),
      this.store.select(selectActiveProcedureID),
      this.store.select(findUnansweredParentQuestionId),
    ),
    tap(([{preview, message}, study, profile, {userStudyID}, procedure, questionId]) => {
      if (isRealValue(questionId)) {
        // this.dialogService.showUnansweredQuestionsDialog(message).pipe(
        //   take(1), filter(isOK => isOK)
        // ).subscribe(() => this.store.dispatch(nextUnansweredQuestion({preview})));
        this.store.dispatch(nextUnansweredQuestion({preview}))
      } else {
        this.surveyRouter.redirectToProcedureSubmit(
          {studyID: study.id, userID: profile.id, userStudyID}, procedure
        ).then(() => this.store.dispatch(finishPending()));
      }
    })
  ), {dispatch: false});

  /**
   * survey always go to first question
   */
  previewAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(flowPreviewAnswers),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectUrlProcedure),
      this.store.select(selectActiveProcedureParentFilteredQuestionsByRules).pipe(filter(isNonEmptyArray), map(([question]) => question)),
    ),
    tap(([, {studyID, userID, userStudyID}, procedure, {id}]) => {
      this.surveyRouter.navigateToProcedureAnswersPreview({studyID, userID, userStudyID, questionId: id, procedure});
    })
  ), {dispatch: false});

  navigateToQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToQuestion),
    withLatestFrom(this.store.select(selectActiveProcedureID), this.store.select(selectPreviewMode)),
    tap(([{questionId}, procedure, previewMode]) => {
      const preview = previewMode ? {preview: true} : {};

      this.surveyRouter.navigateToQuestion({procedure, questionId, ...preview}).then(ok => {
        if (ok) {
          changeActiveQuestion({payload: questionId});
        }
      });
    })
  ), {dispatch: false});

  clickResearchProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(clickResearchProcedure),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{promotionID}, {userID, studyID, userStudyID}]) => forkJoin([
      this.ts.registerClickOnResearchProcedure(promotionID, userID, userStudyID),
      this.ts.trackResearchProcedure(userID, studyID),
    ])),
    map(() => clickResearchProcedureSuccess()),
  ));

  constructor(
    private injector: Injector,
    private ts: TrackingService,
    public surveyRouter: SurveyRouter,
    public store: Store<any>,
    public dialogService: SurveyDialogsService,
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }
}
