import * as t from 'io-ts';
import { get } from 'lodash';
import { isMoment, Moment, MomentInput } from 'moment';

import { clientPredefinedOperators, isNullOrUndefined, PredefinedOperatorsType } from '@tr-common';

import { parseDate } from '../date-helper';
import { DateRangeType, SimpleValueType } from '../study-option-types';
import { comparators, HandlerFunc } from './comparators';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export const predefinedActionsSchema = t.union([
  t.literal('empty'),
  t.literal('user_age'),
  t.literal('calc_age'),
  t.literal('calc_months'),
  t.literal('user_months'),
  t.literal('start_date'),
  t.literal('end_date'),
], 'predefinedActionsSchema');

export const manipulateConditionSchema = t.type({
  action: t.union([predefinedActionsSchema, t.literal('')]),
  manipulation: t.union([clientPredefinedOperators, t.literal('')]),
  value: t.union([t.string, t.number, t.null]),
});
export type IManipulateCondition = t.TypeOf<typeof manipulateConditionSchema>;

const getHandler = (
  op: '' | PredefinedOperatorsType, isDate: boolean
): HandlerFunc => op === '' ? null : get(comparators, [op, isDate ? 'handleDate' : 'handleVal']);

export class ManipulateCondition implements IManipulateCondition {
  action: IManipulateCondition['action'] = '';
  manipulation: IManipulateCondition['manipulation'] = '';
  value: number | string = '';
  readonly dateValue: Moment = null;
  private lNow: Moment;

  constructor(data?: {[k: string]: any} & IManipulateCondition, readonly now?: () => Moment) {
    if (isNullOrUndefined(this.now)) {
      this.now = moment;
    }
    this.lNow = this.now();
    if (![null, undefined].includes(data)) {
      Object.assign(this, data);
      switch (this.action) {
        case 'user_age':
          if (typeof this.value === 'number') this.dateValue = this.lNow.clone().subtract(this.value, 'years');
          break;
        case 'calc_age':
          if (typeof this.value === 'number') this.dateValue = this.lNow.clone().add(this.value, 'years');
          break;
        case 'calc_months':
          if (typeof this.value === 'number') this.dateValue = this.lNow.clone().add(this.value, 'month');
          break;
        case 'user_months':
          if (typeof this.value === 'number') this.dateValue = this.lNow.clone().subtract(this.value, 'month');
          break;
        case 'start_date':
        case 'end_date':
          this.dateValue = this.lNow.clone();
          break;
        default:
          if (parseDate(this.value)) this.dateValue = parseDate(this.value);
      }
    }
  }

  ifValueMatchesCondition(value: SimpleValueType | DateRangeType): boolean | null {
    const isLikeDate = parseDate(value) !== null;
    let func = getHandler(this.manipulation, isLikeDate);
    let convertedMatchedValue = isLikeDate ? parseDate(value) : value;
    let futureDate;
    let lDate: Moment;

    if (isMoment(convertedMatchedValue) && isMoment(this.dateValue)) {
      switch (this.action) {
        case 'user_age':
          convertedMatchedValue = this.lNow.diff(convertedMatchedValue as MomentInput, 'years');
          func = getHandler(this.manipulation, false);
          break;
        case 'calc_age':
          convertedMatchedValue = -this.lNow.diff(convertedMatchedValue as MomentInput, 'years');
          func = getHandler(this.manipulation, false);
          break;
        case 'calc_months':
          futureDate = moment(convertedMatchedValue.format('MM/YYYY'), 'MM/YYYY');
          convertedMatchedValue = futureDate.diff(moment(this.lNow.format('MM/YYYY'), 'MM/YYYY'), 'month');
          func = getHandler(this.manipulation, false);
          break;
        case 'user_months':
          lDate = moment(convertedMatchedValue.format('MM/YYYY'), 'MM/YYYY');
          convertedMatchedValue = this.lNow.diff(moment(lDate.format('MM/YYYY'), 'MM/YYYY'), 'month');
          func = getHandler(this.manipulation, false);
          break;
        case 'start_date':
          futureDate = moment((value as DateRangeType).beginDate.format('DD/MM/YYYY'), 'DD/MM/YYYY');
          convertedMatchedValue = futureDate;
          func = getHandler(this.manipulation, true);
          break;
        case 'end_date':
          futureDate = moment((value as DateRangeType).endDate.format('DD/MM/YYYY'), 'DD/MM/YYYY');
          convertedMatchedValue = futureDate;
          func = getHandler(this.manipulation, true);
          break;
        default:
          func = getHandler(this.manipulation, isLikeDate);
      }
    }

    return typeof func === 'function' ? (func as any)(convertedMatchedValue, this) : null;
  }
}
