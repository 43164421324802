import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppState, urlForJaebUsersPreselect } from '@app/model';
import { environment } from '@env/environment';
import { registerJaebUserClicksAgain, unauthorized } from '@store/actions';

@Injectable({providedIn: 'root'})
export class NotAuthorizedInterceptor implements HttpInterceptor {
  private apiUrl = environment.apiUrl;

  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {}, err => {
        if (err.status === 401) {
          const preselectUrl = urlForJaebUsersPreselect(this.apiUrl);

          if (req.url.includes(preselectUrl)) {
            this.store.dispatch(registerJaebUserClicksAgain({payload: req.url.split(preselectUrl).join('')}));
          }
          this.store.dispatch(unauthorized());
        }
      })
    );
  }
}
