import { Component } from '@angular/core';

@Component({
  selector: 'lib-not-eligible-jaeb-content',
  template: `
    <h3>Whoops!</h3>
    <p class="text-normal">
      It looks like some of the information you entered doesn't match our records. But don't worry! Please contact a study team
      member at Registry@T1DExchange.org, and we can get this sorted out for you
    </p>
    <p class="text-normal">
      Otherwise, T1D Exchange will keep your email address and responses in their database and may contact you
      in the future to confirm participation. We thank you for your enthusiasm in contributing to T1D research!
    </p>
  `,
  styleUrls: ['./not-eligible-jaeb-content.component.scss']
})
export class NotEligibleJaebContentComponent {}
