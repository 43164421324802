import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as t from 'io-ts';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { DialogEligibleTypeConsent, isNonEmptyArray, ModuleContent, schemaValidationPipe, TypeConsent } from '@tr-common';

import { REGISTRY_API_URL } from '../../global/tokens';
import { defaultDialogSettings, dialogPanelClass, getMaxWidth, Study } from '../../models';
import { LayoutService } from '../../services';
import { BeforeConsentAssentDialogComponent } from '../dialogs/before-consent-assent-dialog/before-consent-assent-dialog.component';
import { BeforeQuestionnaireDialogComponent } from '../dialogs/before-questionnaire-dialog/before-questionnaire-dialog.component';
import { IsEligibleDialogComponent } from '../dialogs/is-eligible-dialog/is-eligible-dialog.component';
import { SurveyPopupComponent } from '../dialogs/survey-popup/survey-popup.component';
import { UnansweredQuestionDialogComponent } from '../dialogs/unanswered-question-dialog/unanswered-question-dialog.component';
import { WelcomeDialogComponent } from '../dialogs/welcome-dialog/welcome-dialog.component';
import { dialogContentSchema, DialogContentType } from '../models';

@Injectable({providedIn: 'root'})
export class SurveyDialogsService {
  constructor(
    @Inject(REGISTRY_API_URL) private apiUrl: string,
    public dialog: MatDialog,
    private layoutService: LayoutService,
    private http: HttpClient,
  ) {}

  showWelcomeDialog(source: string, study: Study): Observable<unknown> {
    const defaultParams = defaultDialogSettings(this.layoutService.isMobile);
    const params = {source, action: 'screening_start', study_type: study.study_type};

    return this.http.get<DialogContentType[]>(`${this.apiUrl}/texts`, {params}).pipe(
      schemaValidationPipe(t.array(dialogContentSchema)),
      switchMap(data => this.dialog.open<WelcomeDialogComponent, DialogContentType, void>(
        WelcomeDialogComponent, {...defaultParams, data: isNonEmptyArray(data) ? data[0] : null}
      ).afterClosed()),
      catchError(() => of(this.dialog.open<WelcomeDialogComponent, DialogContentType, void>(
        WelcomeDialogComponent, defaultParams
      ).afterClosed())),
    );
  }
  /**
   * participant eligible, based on content type, consent list, screening answers
   *
   * @param consentType
   * @param source
   */
  showIsEligibleDialog(consentType: TypeConsent, source: string): Observable<unknown> {
    return this.dialog.open<IsEligibleDialogComponent, DialogEligibleTypeConsent, boolean>(IsEligibleDialogComponent, {
      maxWidth: getMaxWidth(this.layoutService.isMobile),
      panelClass: dialogPanelClass,
      data: {consentType, source},
      autoFocus: false,
    }).afterClosed();
  }

  showConsentDialog(consentType: TypeConsent): Observable<unknown> {
    return this.dialog.open(BeforeConsentAssentDialogComponent, {
      ...defaultDialogSettings(this.layoutService.isMobile), data: {consentType},
    }).afterClosed();
  }

  showBeforeQuestionnaireDialog(consentType: TypeConsent): Observable<unknown> {
    return this.dialog.open(BeforeQuestionnaireDialogComponent, {
      ...defaultDialogSettings(this.layoutService.isMobile),
      data: {consentType},
    }).afterClosed();
  }

  showUnansweredQuestionsDialog(message: string): Observable<boolean> {
    return this.dialog.open<UnansweredQuestionDialogComponent, {message: string}, boolean>(UnansweredQuestionDialogComponent, {
      ...defaultDialogSettings(this.layoutService.isMobile),
      data: {message},
    }).afterClosed();
  }

  showConfiguredPopup(data: ModuleContent): Observable<unknown> {
    return this.dialog.open(SurveyPopupComponent, {
      ...defaultDialogSettings(this.layoutService.isMobile), data
    }).afterClosed();
  }
}
