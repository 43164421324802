import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

import { DefaultPagesByPrivilege, isNullOrUndefined, isRealValue, ParticipantState, REGISTRY_LOCAL_URL } from 'tr-lib';

import { LogoutType, sourcesToComponent, SourcesToComponentType, T1D_CLIENT_ID, T1D_OAUTH_URL } from '@app/model';

@Injectable()
export class UserRouterService {
  constructor(
    @Inject(REGISTRY_LOCAL_URL) private _registryLocalUrl: string,
    @Inject(T1D_CLIENT_ID) private _t1dClientId: string,
    @Inject(T1D_OAUTH_URL) private _t1dOAuthPage: string,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  get queryParams() { return this._route.snapshot.queryParams; }

  navigateTo = (url: string[], extras?: NavigationExtras): Promise<boolean> => this._router.navigate(url, extras).then();

  navigateToDualFA(params: Params): Promise<boolean> {
    const {queryParams} = this._route.snapshot;
    const jtoken = queryParams.jtoken;

    return this.navigateTo(['auth/dual-factor-authentication'], {
      queryParams: {...params, ...queryParams, ...(isRealValue(jtoken) ? {jtoken} : null)}
    });
  }

  linkToT1DExchange(): string {
    const redirect = `${this._registryLocalUrl}/oauth/t1d`;

    return `${this._t1dOAuthPage}/oauth/authorize/?client_id=${this._t1dClientId}&response_type=code&redirect_uri=${redirect}`;
  }

  loginWithT1DExchange(): void {
    location.href = this.linkToT1DExchange();
  }

  userHasToBeReroutedIfNonRegular(payload: ParticipantState): Promise<boolean> {
    const defaultPage = DefaultPagesByPrivilege.find(page => page.state === payload);
    let route: string;

    if (isNullOrUndefined(defaultPage)) {
      throw Error('INVALID PARTICIPANT STATE: ' + payload);
    } else {
      route = defaultPage.url;
    }

    return this.navigateTo([route]);
  }

  userHasToBeReroutedIfRegular(source: SourcesToComponentType): Promise<boolean> {
    if (source === sourcesToComponent.fromRegister) {
      return this.navigateTo(['register-success']);
    } else {
      const params = this._route.snapshot.queryParams;

      return this._router.navigateByUrl('redirectTo' in params ? atob(params.redirectTo) : '/dashboard');
    }
  }

  redirectAfterLogout(payload: LogoutType): void {
    if (isRealValue(payload)) {
      if (typeof payload === 'string') {
        this.navigateTo([payload]).then();
      } else {
        const {commands, navigationExtras} = payload;
        this.navigateTo(commands, navigationExtras).then();
      }
    } else {
      this.navigateTo(['']).then();
    }
  }
}
