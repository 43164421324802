import { Achievement, AchievementCode } from '@app/dashboard/models';

export const addAchievementIcons = (achievements: Achievement[]): Achievement[] => {
  const codeToIcon: { [k in AchievementCode]: string } = {
    registered: 'achievement-0',
    screening: 'achievement-1',
    questionnaire: 'achievement-3',
    annual_questionnaire: 'achievement-3',
    consent_parental: 'achievement-2',
    consent_child: 'achievement-2',
    consent_adult: 'achievement-2',
    consent_universal: 'achievement-2',
    assent: 'achievement-4',
    reconsent: 'achievement-5',
    substudy: 'achievement-6',
    promotion_5_visits: '5-promotion-studies',
    promotion_10_visits: '10-promotion-studies',
    promotion_15_visits: '15-promotion-studies',
  };

  return achievements.map(a => ({...a, icon: codeToIcon[a.code]}))
};
