import { Answers, answerWidget, isNonEmptyArray, isNonEmptyString, isRealValue, StoredAnswerType } from '@tr-common';

import { hasToHaveSingleRadio, ProcedureQuestions, TreeNodeValueType } from '../../models';
import { StudyOption } from '../../models/study-option';
import { StudyQuestion } from '../../models/study-question';
import { DraftAnswersType, DraftAnswerType, DraftValueType } from './draft-answer';

export interface BuildTreeSourceData {
  question: StudyQuestion,
  unfilledList: string[],
  showHint: boolean,
  questionAnswers: Answers,
  draftAnswers: DraftAnswersType,
  optionQuestionMap: ProcedureQuestions
}
/**
 * TODO Set answer as a value to corresponding rules below
 *
 * Return value agreement:
 * boolean (true or false) - in any radio or checkbox widget. Only the value 'true' opens a sub-question if it's presented
 * number - a value of any numerical widget
 * string - in the rest of all other widgets including date widgets and A1C
 **/
export const getValueFromAnswers = (
  option: StudyOption, {question, draftAnswers, questionAnswers}: BuildTreeSourceData
): TreeNodeValueType => {
  const storedAnswers = questionAnswers[question.id];
  const answerScope = (isNonEmptyArray(draftAnswers) ? draftAnswers : storedAnswers) as DraftAnswersType;
  const answer = (answerScope ?? [])
    .find(({question_option}: DraftAnswerType | StoredAnswerType) => question_option === option.id);

  return isRealValue(answer)
    ? isNonEmptyString(answer.value) ? answer.value : true
    : null;
}

export const getQuestionAnswers = ({question: {id, options}, draftAnswers, questionAnswers}: BuildTreeSourceData): DraftAnswersType => {
  const storedAnswers = questionAnswers[id] ?? [];
  const transformedAnswers = storedAnswers.reduce((acc: DraftAnswersType, answer: StoredAnswerType) => {
    const option: StudyOption = options.find(({id}) => id === answer.question_option);
    const hasOption = isRealValue(option);
    let value: DraftValueType = answer.value;

    if (hasOption && option.widget === answerWidget.multiselectText) {
      try {
        value = JSON.parse(answer.value as string) as string[];
      } catch (e) {
        console.error(`can not parse the value ${value.toString()}`);
      }
    }

    return hasOption ? [...acc, {...answer, widget: option.widget, value}] : acc;
  }, []);
  const answerScope = (isNonEmptyArray(draftAnswers) ? draftAnswers : transformedAnswers) ?? [];

  return answerScope.filter(({question}) => question === id);
}

export const getOrderedOptions = ({options, question_type}: StudyQuestion): StudyOption[] => {
  let orderedOptions: StudyOption[] = [...options].sort((a, b) => a.order - b.order);

  if (hasToHaveSingleRadio(question_type)) {
    const [lastOption] = orderedOptions.filter(o => o.isRadioWidget);
    const filteredOptions = orderedOptions.filter(o => !o.isRadioWidget);
    const order = filteredOptions[filteredOptions.length - 1].order + 1;

    orderedOptions = [...filteredOptions, new StudyOption({...lastOption, order})];
  }

  return orderedOptions;
};
