import { NavigationExtras } from '@angular/router';
import { keyof, number, string, type, TypeOf } from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { nullable } from 'tr-lib';

export const achievementCodeSchema = keyof({
  registered: null,
  screening: null,
  questionnaire: null,
  annual_questionnaire: null,
  consent_parental: null,
  consent_child: null,
  consent_adult: null,
  consent_universal: null,
  assent: null,
  reconsent: null,
  substudy: null,
  promotion_5_visits: null,
  promotion_10_visits: null,
  promotion_15_visits: null,
});

export type AchievementCode = TypeOf<typeof achievementCodeSchema>;

export interface ActionButtonData {
  label: string;
  type: 'Download' | 'Review';
  navigateArgs: [any[], NavigationExtras];
}

export const achievementSchema = type({
  title: nullable(string),
  created_at: DateFromISOString,
  code: achievementCodeSchema,
  study_id: nullable(string),
  user_study_id: nullable(number),
}, 'achievementSchema');

export interface Achievement extends TypeOf<typeof achievementSchema> {
  payload?: any;
  icon?: string;
  actions?: ActionButtonData[];
}
