import { StudyQuestion, StudyQuestions } from '../../models/study-question';
import { isRealValue } from '../../common';

export const getParentQuestion = (maybeParentQuestion: string, questions: StudyQuestions = []): string => {
  const foundQuestion = questions.find(({id}) => id === maybeParentQuestion);
  let outcome = maybeParentQuestion;

  if (isRealValue(foundQuestion)) {
    if (foundQuestion instanceof StudyQuestion && foundQuestion.hasParent) {
      const parentOption = foundQuestion.parent_option_id;
      const foundParentQuestion = questions.find(({options}) => options.some(({id}) => id === parentOption));

      outcome = getParentQuestion(foundParentQuestion?.id, questions);
    }
  } else {
    outcome = null;
  }

  return outcome;
}

export const getParentQuestions = (ids: string[], questions: StudyQuestions): string[] => ids.reduce<string[]>((acc, id) => {
  const parentQuestionID = getParentQuestion(id, questions);

  return isRealValue(parentQuestionID) ? [...acc, parentQuestionID] : acc;
}, []);
