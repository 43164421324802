import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { getFileNameFromHttpHeaders, pdfDownload, pdfPrint } from '@tr-common';

import { REGISTRY_PUBLIC_PARTICIPANT, REGISTRY_USER_FILES } from '../global/tokens';

@Injectable({providedIn: 'root'})
export class ConsentService {
  document: Document;

  constructor(
    @Inject(DOCUMENT) webDocument: any,
    @Inject(REGISTRY_PUBLIC_PARTICIPANT) public endpoint: string,
    @Inject(REGISTRY_USER_FILES) public filesEndpoint: string,
    private http: HttpClient
  ) {
    this.document = webDocument as Document;
  }

  // noinspection JSUnusedGlobalSymbols
  getUserFiles(): Observable<any> {
    return this.http.get(this.filesEndpoint);
  }

  downloadConsent(participantId: string, consentId: string): Observable<HttpResponse<Blob>> {
    const url = this.endpoint + `/${participantId}/user-consents/${consentId}/pdf`;

    return this.http.get(url, {observe: 'response', responseType: 'blob'}).pipe(
      tap(({body, headers}: HttpResponse<Blob>) => pdfDownload(getFileNameFromHttpHeaders(headers), body, this.document)),
    );
  }

  printConsent(participantId: string, consentId: string): Observable<HttpResponse<Blob>> {
    const url = this.endpoint + `/${participantId}/user-consents/${consentId}/pdf`;

    return this.http.get(url, {observe: 'response', responseType: 'blob'}).pipe(
      tap(({body}: HttpResponse<Blob>) => pdfPrint(body, this.document))
    );
  }
}
