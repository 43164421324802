import { select } from '@ngrx/store';
import { Observable, pipe, UnaryFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { filterRealValues, isNullOrUndefined, participantSource, ParticipantSourceType } from '@tr-common';

import { ParticipantDetails } from '../../models';
import { selectParticipantDetails } from '../store/selectors';

export const isJAEB = (
  source: ParticipantSourceType | {source?: ParticipantSourceType}
): boolean => isNullOrUndefined(source) ? false : typeof source === 'string' ? source === participantSource.jaeb : isJAEB(source.source);

export const participantIsFromJAEBFilter: () => UnaryFunction<Observable<ParticipantDetails>, Observable<boolean>> = () => pipe(
  select(selectParticipantDetails), filterRealValues(), map(isJAEB)
);
