import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isNonEmptyString, isRealValue } from 'tr-lib';

export interface WithdrawalReasons {
  receiving_too_many_emails?: boolean;
  have_data_privacy_concerns?: boolean;
  takes_too_much_time_to_complete_qs?: boolean;
  not_wish_be_presented_with_pro?: boolean;
  have_difficult_accessing_account?: boolean;
  was_signed_up_by_parent?: boolean;
  was_signed_up_for_my_child?: boolean;
  not_wish_to_disclose_my_reason?: boolean;
  other?: boolean;
  reason?: string;
}

export const withdrawalValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const answer = getReasons(formGroup);
  const keys = Object.keys(answer);
  const isReasonEmpty = isRealValue(answer.other) && !isNonEmptyString(answer.reason);

  return keys.length === 0 ? {empty: true} : isReasonEmpty ? {reason: true} : null;
};

export const getReasons = (formGroup: UntypedFormGroup): WithdrawalReasons => Object.entries(formGroup.value).reduce((
  formValues, [key, value]
) => {
  const isFieldFilled = isRealValue(value) && (key !== 'reason' && value === true || key === 'reason' && isNonEmptyString(value));

  return isFieldFilled ? {...formValues, ...{[key]: value}} : {...formValues};
}, {}) as WithdrawalReasons;
