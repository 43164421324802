import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialImportsModule } from '../material-imports.module';
import { SurveyPipesModule } from '../pipes/survey-pipes.module';
import { ConsentFilesComponent } from './components/consent-files/consent-files.component';
import { SubmittedConsentComponent } from './components/submitted-consent/submitted-consent.component';

@NgModule({
  declarations: [SubmittedConsentComponent, ConsentFilesComponent],
  exports: [SubmittedConsentComponent, ConsentFilesComponent],
  imports: [CommonModule, RouterModule, MaterialImportsModule, SurveyPipesModule],
})
export class ConsentModule {}
