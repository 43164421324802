import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { GotoBackLinkModule } from 'tr-lib';

import { MissedStudyComponent } from './missed-study.component';
import { MissedStudiesDialogComponent } from './missed-studies-dialog.component';

@NgModule({
  declarations: [
    MissedStudiesDialogComponent,
    MissedStudyComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    GotoBackLinkModule,
    MatIconModule,
  ],
})
export class MissedStudiesDialogModule {
}
