import { MatDialogConfig } from '@angular/material/dialog';

import { isRealValue, getMaxWidth } from 'tr-lib';

export interface ButtonsForConfirmationData {
  cancel?: string;
  hideCancel?: boolean;
  confirm?: string;
  hideConfirm?: boolean;
}

export interface ConfirmationData {
  isDanger?: boolean;
  question?: string;
  action: string;
  what?: string;
  buttons?: ButtonsForConfirmationData;
  closeIcon?: boolean;
}

export const publicDialogClass = 'public-modal';
export const publicDialogSettings = (isMobile: boolean, customClass: string = null): Partial<MatDialogConfig> => ({
  maxWidth: getMaxWidth(isMobile),
  panelClass: isRealValue(customClass) ? [publicDialogClass, customClass] : publicDialogClass,
  autoFocus: 'dialog',
  restoreFocus: false,
});
