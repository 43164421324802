import { Answers, isRealValue } from '../../../common';
import { ParticipantDetails, ProcedureQuestions } from '../../../models';
import { AnsweredQuestion, ParentQuestionState } from '../../models';

export const projectorOfParentQuestionState = (
  questions: AnsweredQuestion[],
  questionAnswers: Answers,
  optionQuestionMap: ProcedureQuestions,
  participant: ParticipantDetails,
  activeQuestionID: string,
  numberOfCurrentQuestion: number
): ParentQuestionState => ({
  parentQuestion: questions.find(({id}: AnsweredQuestion) => id === activeQuestionID),
  questionAnswers, optionQuestionMap, participant, questions, numberOfCurrentQuestion
});

export const projectorOfProceduresWithAnswers = (questions: ProcedureQuestions, answers: Answers): string[] => {
  const questionWithAnswerKeys = isRealValue(answers) ? Object.keys(answers) : [];
  const procedures: string[] = isRealValue(questions) ? Object.keys(questions).reduce((acc, key) => {
    const isProcedureHasAnswer = questions[key].some(({id}) => questionWithAnswerKeys.includes(id));

    return isProcedureHasAnswer ? [...acc, key] : acc;
  }, [] as string[]) : [];

  return procedures;
};
