import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
  iPhoneLandscapeMediaQuery,
  likeDesktopQuery,
  matchQueryAndShare,
  mediumMediaQuery,
  mobileLandscapeMediaQuery,
  mobileMediaQuery,
  PreviewDeviceType,
  tabletMediaQuery,
} from '@tr-common';

import { REGISTRY_APPLICATION } from '../global/tokens';

@Injectable({providedIn: 'root'})
export class LayoutService {
  readonly fakeBreakpoint$ = new BehaviorSubject<PreviewDeviceType>(null);
  readonly isMobile$ = this.breakpointObserver.observe([mobileMediaQuery]).pipe(matchQueryAndShare);
  readonly isTablet$ = this.breakpointObserver.observe([tabletMediaQuery]).pipe(matchQueryAndShare);
  readonly isGadget$ = combineLatest([this.isMobile$, this.isTablet$]);
  readonly isMobileUsingLandscape$: Observable<boolean> = this.breakpointObserver.observe([
    mobileLandscapeMediaQuery, iPhoneLandscapeMediaQuery
    // Breakpoints.HandsetLandscape
  ]).pipe(
    map(x => {
      const orientation = (screen.orientation || {}).type;

      if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
        return ({matches: false});
      }

      return x;
    }),
    matchQueryAndShare
  );
  readonly isDesktop$ = this.isGadget$.pipe(map(([isMobile, isTablet]) => !isMobile && !isTablet));
  scrollTop$ = new BehaviorSubject<number>(0);
  scrollSrcElement: Element;
  scrollIt$ = new BehaviorSubject<boolean>(false);
  scrollRise$ = new BehaviorSubject<Event>(null);

  constructor(
    @Inject(REGISTRY_APPLICATION) public application: string,
    public breakpointObserver: BreakpointObserver,
    public mediaMatcher: MediaMatcher,
  ) {
    if (this.isAdminPlatform) {
      this.fakeBreakpoint$.next('desktop');
      this.isMobile$ = this.fakeBreakpoint$.pipe(map(breakpoint => breakpoint === 'mobile'), shareReplay(1));
      this.isTablet$ = this.fakeBreakpoint$.pipe(map(breakpoint => breakpoint === 'tablet'), shareReplay(1));
    }
  }

  get isAdminPlatform(): boolean {
    return this.application === 'Admin';
  }

  get isMobile(): boolean {
    return this.isAdminPlatform ? this.fakeBreakpoint$.value === 'mobile' : this.mediaMatcher.matchMedia(mobileMediaQuery).matches;
  }

  get isLandscapeMobile(): boolean {
    return this.mediaMatcher.matchMedia(mobileLandscapeMediaQuery).matches;
  }

  get isGadget(): boolean {
    return this.mediaMatcher.matchMedia(mobileMediaQuery).matches || this.mediaMatcher.matchMedia(tabletMediaQuery).matches;
  }

  get isMedium(): boolean {
    return this.mediaMatcher.matchMedia(mediumMediaQuery).matches;
  }

  get likeDesktop(): boolean {
    return this.mediaMatcher.matchMedia(likeDesktopQuery).matches;
  }
}
