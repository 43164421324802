import { NgModule } from '@angular/core';

import { SocialIconsComponent } from './social-icons.component';
import { SocietyInfoComponent } from './society-info.component';

@NgModule({
  declarations: [SocietyInfoComponent, SocialIconsComponent],
  exports: [SocietyInfoComponent, SocialIconsComponent]
})
export class SocietyInfoModule {
}
