import * as t from 'io-ts';

import { nullable } from '@tr-common';

import { studyConditionSchema } from '../study-condition-schema';
import { StudyBaseRule } from './study-base-rules';
import { StudyCondition } from './study-condition';

export const studyScreeningRuleSchema = t.intersection([
  t.type({
    description: nullable(t.string),
    conditions: nullable(t.array(studyConditionSchema)),
    study: nullable(t.string),
  }),
  t.partial({
    created_at: t.string,
    id: t.string,
    updated_at: nullable(t.string),
    updated_by: nullable(t.string),
  })
], 'studyScreeningRuleSchema');
export type StudyScreeningRuleType = t.TypeOf<typeof studyScreeningRuleSchema>;

export class StudyScreeningRule extends StudyBaseRule<StudyScreeningRule> implements StudyScreeningRuleType {
  description = '';
  changed_by = 'by_system';
  conditions: StudyCondition[];
  study: string;
  created_at: string;
  updated_at: string;
  updated_by: string;

  constructor(data?: Partial<StudyScreeningRule>) {
    super();
    super.setData(data);
  }

  clone(): StudyScreeningRule {
    return new StudyScreeningRule(this);
  }
}
