import { from, NEVER, Observable, ObservableInput, of, OperatorFunction } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { isRealValue } from './helpers';

/*
there is override for standard filter `filter<T, S extends T>(predicate: (value: T, index: number) => value is S, thisArg?: any):
 OperatorFunction<T, S>`
TODO: use that override
 */
// Usual filter doesn't allow the narrow type of output values - output type must the same as input type. So here is this operator
export const narrowingFilter = <TIn, TOut extends TIn>(
  predicate: (val: TIn) => val is TOut
): OperatorFunction<TIn, TOut> => switchMap((v) => predicate(v) ? of(v as TOut) : NEVER);

export const filterRealValues = <T>() => filter<T>(isRealValue);

/**
 * Works as switchMap except it doesn't propagate error to main(parent) stream and just logs to console
 */
export const switchMapOk = <T, R>(
  project: (value: T, index: number) => ObservableInput<R>
): OperatorFunction<T, R> => (input) => input.pipe(
  switchMap<T, Observable<R>>((val, index) => {
    const res1 = project(val, index);

    return from(res1).pipe(
      catchError<R, Observable<R>>((err: T): Observable<R> => {
        console.error(err);

        return NEVER;
      }),
    );
  }),
);
