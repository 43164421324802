import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { DialogTypeConsent, TypeConsent } from '@tr-common';

import { surveyDialogDefaultOptions } from '../../models';

@Component({
  selector: 'lib-before-questionnaire-dialog',
  template: `
    <img src="/assets/modal-welcome.svg" alt="welcome">
    <h3>Before you start</h3>
    <p *ngIf="data.consentType === typeConsent.adult">
      You will be reading and answering the following questions on your own behalf. Please remember to answer the
      question set as it pertains to your information, and not that of another family member.
    </p>
    <p *ngIf="data.consentType === typeConsent.child || data.consentType === typeConsent.parental ">
      You will be reading and answering the following questions on behalf of a minor (an individual aged 0-17 years),
      as you have indicated in the eligibility questions.
    </p>
    <button class="dialog-button is-shadowed" mat-flat-button color="primary" [mat-dialog-close]="true">Continue</button>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: surveyDialogDefaultOptions}],
  styleUrls: ['./before-questionnaire-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeforeQuestionnaireDialogComponent {
  typeConsent = TypeConsent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogTypeConsent) {}
}
