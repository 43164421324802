<div class="ui-kit">
  <h1>UI kit for redesign app</h1>
  <h4 style="padding-left: 40px">Buttons</h4>
  <div class="ui-kit-wrapper">
    <div>
      <h5>Flat color="primary"</h5>
      <button mat-flat-button [color]="'primary'">Primary</button>
      <button mat-flat-button [color]="'primary'" [disabled]="true">Primary disabled</button>
      <button mat-flat-button [color]="'primary'" class="is-shadowed">Primary shadowed</button>
    </div>
    <div>
      <h5>Flat color="secondary"</h5>
      <button mat-flat-button color="secondary">Secondary</button>
      <button mat-flat-button color="secondary" [disabled]="true">Secondary disabled</button>
    </div>
    <div>
      <h5>Flat color="tertiary"</h5>
      <button mat-flat-button color="tertiary">Tertiary</button>
    </div>
  </div>

  <div class="ui-kit-wrapper">
    <div>
      <h5>Flat color="primary" class="button-sm"</h5>
      <button mat-flat-button [color]="'primary'" class="button-sm">Sign In</button>
      <button mat-flat-button [color]="'primary'" [disabled]="true" class="button-sm">Sign In</button>
    </div>
    <div>
      <h5>Flat color="secondary" class="button-sm"</h5>
      <button mat-flat-button color="secondary" class="button-sm">Sign In</button>
      <button mat-flat-button color="secondary" [disabled]="true" class="button-sm">Sign In</button>
    </div>
    <div>
      <h5>Flat color="tertiary" class="button-sm"</h5>
      <button mat-flat-button color="tertiary" class="button-sm">Sign In</button>
    </div>
  </div>

  <div class="ui-kit-wrapper">
    <div>
      <h5>Flat color="secondary" class="tr-icon"</h5>
      <button mat-flat-button color="secondary" class="is-with-icon">
        <mat-icon [svgIcon]="'download'"></mat-icon>
        Download
      </button>
    </div>
    <div>
      <h5>Flat color="secondary" class="tr-icon tr-rounded"</h5>
      <button mat-flat-button color="secondary" class="is-with-icon is-rounded">
        <mat-icon [svgIcon]="'download'"></mat-icon>
      </button>
    </div>
    <div>
      <h5>Flat color="secondary" class="tr-icon tr-squared"</h5>
      <button mat-flat-button color="secondary" class="is-with-icon is-squared">
        <mat-icon [svgIcon]="'back-arrow'"></mat-icon>
      </button>
    </div>
  </div>

  <div class="ui-kit-input-wrapper">
    <div class="ui-kit-wrapper">
      <form action="">
        <h4>Inputs (must go with appearance="outline")</h4>
        <mat-form-field appearance="outline">
          <mat-label>Active</mat-label>
          <input matInput value="Sushi">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Disabled</mat-label>
          <input matInput value="Sushi" [disabled]="true">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Disabled Placeholder</mat-label>
          <input matInput [disabled]="true">
        </mat-form-field>
        <mat-form-field class="mat-form-field-invalid" appearance="outline">
          <mat-label>Invalid</mat-label>
          <input matInput value="Sushi">
        </mat-form-field>
      </form>
    </div>
    <div class="ui-kit-wrapper">
      <form action="">
        <h4>Dropdown (must go with appearance="outline")</h4>
        <mat-form-field appearance="outline">
          <mat-label>Active</mat-label>
          <mat-select value="volvo">
            <mat-option value="volvo">Volvo</mat-option>
            <mat-option value="saab">Saab</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Disabled</mat-label>
          <mat-select value="volvo" [disabled]="true">
            <mat-option value="volvo">Volvo</mat-option>
            <mat-option value="saab">Saab</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Disabled Placeholder</mat-label>
          <mat-select [disabled]="true">
            <mat-option value="volvo">Volvo</mat-option>
            <mat-option value="saab">Saab</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mat-form-field-invalid" appearance="outline">
          <mat-label>Invalid</mat-label>
          <mat-select value="volvo">
            <mat-option value="volvo">Volvo</mat-option>
            <mat-option value="saab">Saab</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="ui-kit-wrapper">
      <form action="">
        <h4>Datepicker (must go with appearance="outline")</h4>
        <mat-form-field appearance="outline">
          <mat-label>MM/YYYY</mat-label>
          <input matInput [matDatepicker]="closedDate"/>
          <mat-datepicker-toggle matSuffix [for]="closedDate">
            <mat-icon svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #closedDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>MM/YYYY</mat-label>
          <input matInput [matDatepicker]="closeDate1" disabled/>
          <mat-datepicker-toggle matSuffix [for]="closeDate1">
            <mat-icon svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #closeDate1></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="mat-form-field-invalid">
          <mat-label>MM/YYYY</mat-label>
          <input matInput [matDatepicker]="closeDate2"/>
          <mat-datepicker-toggle matSuffix [for]="closeDate2">
            <mat-icon svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #closeDate2></mat-datepicker>
        </mat-form-field>
      </form>
    </div>
  </div>

  <h4 style="padding-left: 40px">Icons</h4>
  <div class="ui-kit-wrapper">
    <mat-icon [svgIcon]="'create-account'"></mat-icon>
    <span class="dark-blue"><mat-icon [svgIcon]="'complete'"></mat-icon></span>
    <span class="light-blue"><mat-icon [svgIcon]="'sign-consent'"></mat-icon></span>
    <mat-icon [svgIcon]="'email'"></mat-icon>
    <mat-icon [svgIcon]="'phone'"></mat-icon>
    <span class="dark-blue"><mat-icon [svgIcon]="'facebook'"></mat-icon></span>
    <span class="dark-blue"><mat-icon [svgIcon]="'instagram'"></mat-icon></span>
    <span class="dark-blue"><mat-icon [svgIcon]="'twitter'"></mat-icon></span>
    <span class="dark-blue"><mat-icon [svgIcon]="'linkedin'"></mat-icon></span>
    <mat-icon [svgIcon]="'show-pass'"></mat-icon>
    <mat-icon svgIcon="tooltip"></mat-icon>
    <mat-icon [svgIcon]="'calendar'"></mat-icon>
    <mat-icon [svgIcon]="'close'"></mat-icon>
    <mat-icon [svgIcon]="'back-arrow'"></mat-icon>
    <mat-icon [svgIcon]="'download'"></mat-icon>
  </div>


  <h4 style="padding-left: 40px">Images</h4>
  <div class="ui-kit-wrapper">
    <img src="assets/img/child_img.png" alt="child_img" height="150px" width="150px">
    <img src="assets/img/man_img.png" alt="man_img" height="150px" width="150px">
    <img src="assets/img/group_img.png" alt="group_img" height="150px" width="125px">
    <img src="assets/img/landing_bg_element.png" alt="landing_bg_element" height="150px" width="100px">
    <img src="assets/img/get_in_touch_bg_element.png" alt="get_in_touch_bg_element" height="150px" width="100px">
    <img src="assets/img/t1d_banner.png" alt="t1d_banner.png" height="100px" width="300px">
    <img src="assets/img/scroll_down.png" alt="scroll_down.png" height="50px" width="50px">
  </div>
  <div class="ui-kit-wrapper">
    <img src="assets/img/people_with_phone.png" alt="people_with_phone" height="150px" width="150px">
    <img src="assets/img/checkmark.png" alt="checkmark.png" height="150px" width="150px">
    <img src="assets/img/sign_in_up_image.svg" alt="sign_in_up_image.png" height="150px" width="150px">
    <img src="assets/img/forgot_pass.svg" alt="forgot_pass.png" height="150px" width="150px">
    <img src="assets/img/question-mark.png" alt="question-mark.png" height="100px" width="250px">
    <img src="assets/img/security_img.png" alt="security_img.png" height="100px" width="250px">
  </div>

</div>
