import * as t from 'io-ts';

export const studyState = {
  active: 'active',
  closed: 'closed',
  pending: 'pending',
  ready_for_launch: 'ready_for_launch',
  inactive: 'inactive',
} as const;
export const studyStateSchema = t.keyof(studyState);
export type StudyState = t.TypeOf<typeof studyStateSchema>;
