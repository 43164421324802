import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
  baseChildProfileSchema,
  BaseChildProfileType,
  isNullOrUndefined,
  ParticipantDetails,
  participantDetailsSchema,
  RegisterParticipant,
  schemaValidationPipe,
  STORAGE,
  storageKeys,
} from 'tr-lib';

import { environment } from '@env/environment';
import { isOkSchema, IsOkType } from '@app/auth/models/smart-check';
import { LoginData } from '@app/auth/models';

@Injectable()
export class AuthService {
  private otpId: string;
  private _token: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(STORAGE) private storage: Storage,
  ) {
    this._token = this.storage.getItem(storageKeys.token);
  }

  get token(): string {
    return (isNullOrUndefined(this._token) || this._token === '') ? null : this._token;
  }

  set token(token: string) {
    this._token = token;
    this.storage.setItem(storageKeys.token, token);
    this.otpId = null;
  }

  registerParticipant(credentials: RegisterParticipant, reg_guard_data: unknown = null): Observable<ParticipantDetails> {
    const body = {...credentials, reg_guard_data};

    return this.http.post<ParticipantDetails>(environment.registerUrl, body).pipe(
      schemaValidationPipe(participantDetailsSchema),
    );
  }

  registerParticipantFromJAEB = (
    credentials: RegisterParticipant & {id: string}
  ): Observable<ParticipantDetails> => this.http.post<ParticipantDetails>(environment.registerJAEBUrl, credentials).pipe(
    schemaValidationPipe(participantDetailsSchema),
  );

  smartEmailCheck(email: string): Observable<IsOkType> {
    return this.http.post<IsOkType>(environment.apiUrl + '/email-spelling-status', {email}).pipe(
      schemaValidationPipe(isOkSchema)
    );
  }

  login({email, password, recaptchaToken}: LoginData): Observable<{token: string; otp_id?: string}> {
    const credentials = {email, password, recaptcha_token: recaptchaToken};

    return this.http.post<{token: string; otp_id?: string}>(environment.loginURL, credentials);
  }

  submitCode(codeCredentials: any): Observable<any> {
    return this.http.post(environment.otpURL, codeCredentials);
  }

  setOtpId(otpId: string): void {
    this.storage.setItem('otpId', otpId)
  }

  getOtpId(): string {
    return this.storage.getItem('otpId');
  }

  removeOtpId(): void {
    return this.storage.removeItem('otpId');
  }

  getAuthorizationHeader(): Array<{name: string, value: string}> {
    return [{
      name: 'Authorization',
      value: 'JWT ' + this.token
    }];
  }

  logout() {
    this.token = '';
    this.storage.removeItem(storageKeys.token);
  }

  trackEmailId(trEmailId: string): Observable<boolean> {
    if (isNullOrUndefined(trEmailId)) return of(false);

    return this.http.get(`${environment.apiUrl}/email-click/${trEmailId}`, {responseType: 'text'}).pipe(
      switchMap(() => of(true)),
      catchError(({error}) => {
        console.error(`Error while logging open from email`, error);
        return of(false);
      })
    );
  }
}
