import { OptionAnswerType } from '@tr-common';

import { TreeNode } from '../../models';
import { StudyOption } from '../../models/study-option';
import { BuildTreeSourceData, getQuestionAnswers } from './build-tree.helpers';
import { DraftAnswersType, DraftAnswerType } from './draft-answer';

export const generateMultiselectNode = (
  sourceData: BuildTreeSourceData, multiselectOptions: StudyOption[], parent: TreeNode, nodeIndex: number, parentOptionIndex?: number
): [] | [TreeNode] => {
  if (multiselectOptions.length === 0) return [];
  const {question} = sourceData;
  const treeNode = new TreeNode<OptionAnswerType[]>({
    multiselectOptions, nodeIndex, parentOptionIndex, parent,
    isQuestion: false, showHint: parent.showHint,
    questionID: question.id
  });
  const answers: DraftAnswersType = getQuestionAnswers(sourceData)
    .filter(({question_option}) => multiselectOptions.some(({id}) => id === question_option));
  let questionAnswers: OptionAnswerType[] = [];

  if (answers instanceof Array) {
    questionAnswers = answers.reduce<OptionAnswerType[]>(
      (acc: OptionAnswerType[], {question_option, value}: DraftAnswerType)=> {
        let outcome: OptionAnswerType | OptionAnswerType[] = {question_option, value: value as string | boolean};

        if (value instanceof Array) {
          outcome = value.map<OptionAnswerType>(v => ({question_option, value: v}));
        }

        return outcome instanceof Array ? [...acc, ...outcome] : [...acc, outcome];
      }, []
    );
  }
  treeNode.value = questionAnswers;

  return [treeNode];
};
