import { studyOptionValidatorOperators, StudyOptionValidatorOperatorsType } from '@tr-common';

export const compare2Values = (lValue: string | number, operator: StudyOptionValidatorOperatorsType, rValue: string | number): boolean => {
  let result = false;

  switch (studyOptionValidatorOperators[operator]) {
    case studyOptionValidatorOperators.lt:
      result = lValue < rValue;
      break;
    case studyOptionValidatorOperators.le:
      result = lValue <= rValue;
      break;
    case studyOptionValidatorOperators.gt:
      result = lValue > rValue;
      break;
    case studyOptionValidatorOperators.ge:
      result = lValue >= rValue;
      break;
    case studyOptionValidatorOperators.eq:
      result = lValue === rValue;
      break;
    case studyOptionValidatorOperators.ne:
      result = lValue !== rValue;
      break;
    default:
      throw new Error('Incorrect operator: ' + operator);
  }

  return result;
}
