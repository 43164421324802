import { MatDialogConfig } from '@angular/material/dialog';
import * as t from 'io-ts';

export const surveyDialogDefaultOptions: Partial<MatDialogConfig> = {hasBackdrop: false};

export const dialogContentSchema = t.type({
  title: t.string,
  body: t.string,
  action: t.literal('screening_start'),
  study_type: t.string
}, 'dialogContentSchema');
export type DialogContentType = t.TypeOf<typeof dialogContentSchema>;
