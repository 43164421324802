import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isRealValue, SessionStorage, STORAGE, storageKeys } from 'tr-lib';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(@Inject(STORAGE) private storage: SessionStorage) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sessionId = this.storage.getItem(storageKeys.response_id) as string;
    let application = this.storage.getItem(storageKeys.application) as string;
    let clonedRequest = req;

    if (isRealValue(sessionId)) {
      clonedRequest = req.clone({headers: req.headers.set('X-Session-ID', sessionId)});
    }
    if (req.url.match(/alliance/) && isRealValue(application) && ['qi', 'registry'].includes(application)) {
      if (application === 'registry' && req.body && req.body.adminRegister) {
        application += '_admin';
        delete req.body.adminRegister;
      }
      clonedRequest = clonedRequest.clone({headers: clonedRequest.headers.set('X-application-name', application)});
    }

    return next.handle(clonedRequest);
  }
}
