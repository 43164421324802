import { Answers, isNonEmptyArray, isNullOrUndefined, isRealValue } from '@tr-common';

import { ParticipantDetails, ProcedureQuestions } from '../../models';
import { AnsweredQuestion } from './answered-question';

export interface QuestionsData {
  parentQuestion: AnsweredQuestion;
  questionAnswers: Answers;
  optionQuestionMap: ProcedureQuestions;
  participant: ParticipantDetails;
}
export type QuestionDataWithId = Omit<QuestionsData, 'parentQuestion'> & {id: string}

export interface ParentQuestionState extends QuestionsData {
  questions: AnsweredQuestion[];
  numberOfCurrentQuestion: number;
}

export interface ActiveQuestionCollection extends ParentQuestionState {
  activeQuestionId: string;
  mobileLandscape?: boolean;
  previewMode: boolean;
  unansweredQuestions: boolean;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  isPending: boolean;
  nextButtonTitle: string;
  total: number;
}

export const calcParentQuestionState = (
  questionsData: ParentQuestionState, isPending = false, previewMode = false
): ActiveQuestionCollection => {
  const idx = questionsData.numberOfCurrentQuestion - 1;
  const activeQuestionId = questionsData.parentQuestion?.id
  const questions = isNonEmptyArray(questionsData?.questions) ? questionsData.questions : [];
  const question = questionsData.questions[idx];
  // Is there another unanswered question except the current one?
  const unansweredQuestions = isRealValue(question)
    && (isNullOrUndefined(question.answers)
    || questions.filter(q => q.id !== question.id && !q.answers).length > 0);
  const isLastQuestion = isRealValue(question) && questions.findIndex(q => q.id === activeQuestionId) === questions.length - 1;

  return {
    ...questionsData,
    activeQuestionId,
    total: isNonEmptyArray(questions) ? questions.length : 0,
    isFirstQuestion: isRealValue(question) && questions.findIndex(q => q.id === activeQuestionId) === 0,
    isLastQuestion,
    nextButtonTitle: isLastQuestion ? 'Finish' : 'Next',
    unansweredQuestions,
    isPending,
    previewMode
  };
};
