import { ParamMap } from '@angular/router';
import { createAction, props } from '@ngrx/store';

import { AlchemerLoggedInInfo, DexcomInState, DexcomModeType, OldAlchemerInState, PlatformType } from '@dexcom/model';
import { TypedAction } from '@ngrx/store/src/models';

export enum dexcomActions {
  DEFINE_DEXCOM_MODE = '[Dexcom] Define mode based on data in the routing table',
  DEFINE_DEXCOM_STATE = '[Dexcom] Define a start state of the component',
  DEFINE_DEXCOM_STATE_FAIL = '[Dexcom] Define a start state of the component failed',
  DEFINE_ALCHEMER_DEXCOM_OUT_STATE = '[Dexcom] Define an ongoing Alchemer state to Dexcom',
  DEFINE_ALCHEMER_DEXCOM_IN_STATE = '[Dexcom] Define an incoming Alchemer state from Dexcom',
  DEFINE_DEXCOM_OUT_STATE = '[Dexcom] Define an ongoing state to Dexcom',
  DEFINE_DEXCOM_IN_STATE = '[Dexcom] Define an incoming state from Dexcom',
  FILL_IN_STATE_ON_BE_SUCCESS = '[Dexcom] Saved token on BE success',
  FILL_IN_STATE_ON_BE_FAIL = '[Dexcom] Saved token on BE fail',
  GOTO_START_PAGE = '[Dexcom] Navigate to start page using the state',
  GOTO_ALCHEMER_DEXCOM_START_PAGE = '[Dexcom] Navigate to the ongoing Alchemer start page',
  GOTO_DEXCOM_START_PAGE = '[Dexcom] Navigate to the ongoing start page',
  GOTO_LANDING_PAGE = '[Dexcom] Goto the public landing page',
  GOTO_THANK_PAGE = '[Dexcom] Goto the thank page'
}

export const defineDexcomMode = createAction(
  dexcomActions.DEFINE_DEXCOM_MODE,
  props<{payload: DexcomModeType}>()
);

export const defineDexcomState = createAction(
  dexcomActions.DEFINE_DEXCOM_STATE,
  props<{payload: ParamMap}>()
);

export const defineDexcomStateFail = createAction(
  dexcomActions.DEFINE_DEXCOM_STATE_FAIL,
  props<{message: string}>()
);

export const defineAlchemerDexcomOutState = createAction(
  dexcomActions.DEFINE_ALCHEMER_DEXCOM_OUT_STATE,
  props<{alchemer_id: string; ref_id: string}>()
);

export const defineAlchemerDexcomInState = createAction(
  dexcomActions.DEFINE_ALCHEMER_DEXCOM_IN_STATE,
  props<{payload: AlchemerLoggedInInfo<OldAlchemerInState>}>()
);

export const defineDexcomOutState = createAction(
  dexcomActions.DEFINE_DEXCOM_OUT_STATE,
  props<{response_id: string; ref_id: string, study_code: PlatformType}>()
);

export const defineDexcomInState = createAction(
  dexcomActions.DEFINE_DEXCOM_IN_STATE,
  props<{payload: AlchemerLoggedInInfo<DexcomInState>}>()
);

export const fillInStateOnBeSuccess = createAction(
  dexcomActions.FILL_IN_STATE_ON_BE_SUCCESS,
  props<{ref_id: string}>()
);

export const fillInStateOnBeFail = createAction(
  dexcomActions.FILL_IN_STATE_ON_BE_FAIL,
  props<{message: string}>()
);

export const gotoStartPage = createAction(
  dexcomActions.GOTO_START_PAGE
);

export const gotoAlchemerDexcomStartPage = createAction(
  dexcomActions.GOTO_ALCHEMER_DEXCOM_START_PAGE,
  props<{payload: OldAlchemerInState}>()
)

export const gotoDexcomStartPage = createAction(
  dexcomActions.GOTO_DEXCOM_START_PAGE,
  props<{payload: DexcomInState}>()
)

export const gotoLandingPage = createAction(
  dexcomActions.GOTO_LANDING_PAGE
);

export const gotoThankPage = createAction(
  dexcomActions.GOTO_THANK_PAGE
);

export type DefinedDexcomActionType = TypedAction<
  dexcomActions.DEFINE_DEXCOM_STATE_FAIL
  | dexcomActions.DEFINE_ALCHEMER_DEXCOM_OUT_STATE
  | dexcomActions.DEFINE_ALCHEMER_DEXCOM_IN_STATE
  | dexcomActions.DEFINE_DEXCOM_OUT_STATE
  | dexcomActions.DEFINE_DEXCOM_IN_STATE
  | dexcomActions.GOTO_LANDING_PAGE
>;
