import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { isRealValue, ValidatorType } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption } from '../../../models/study-option';

@Component({
  selector: 'lib-text-widget',
  template: `
    <span *ngIf="option?.title" [innerHTML]="option?.title"></span>
    <mat-form-field class="full-width" appearance="outline">
      <textarea placeholder="Please specify your answer" matInput cdkTextareaAutosize [formControl]="formControl"
        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5">
      </textarea>
    </mat-form-field>
  `,
  styleUrls: ['./text-widget.component.scss']
})
export class TextWidgetComponent implements WidgetPrototype<string | boolean>, OnChanges, OnDestroy {
  @Input() option: StudyOption;
  @Input() value: string;
  @Output() modify = new EventEmitter<WidgetModify<string | boolean>>();
  formControl = new FormControl('', {updateOn: 'blur'});
  subscriptions = new Subscription();
  data: string;
  valid: ValidatorType = 'default';

  constructor() {
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe(() => {
        this.valid = 'VALID';
        this.emitState(SourceChange.user);
      })
    )
  }

  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);

  ngOnChanges({value}: SimpleChanges): void {
    if (isRealValue(value)) {
      const newValue = value?.currentValue;

      this.formControl.setValue(newValue === false ? '' : newValue, {emitEvent: false});
      this.valid = 'VALID';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  emitState(source: SourceChangeType): void {
    const value = this.formControl.value;

    this.modify.emit({value: value === '' ? false : value, valid: this.valid, source});
  }
}
