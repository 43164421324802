import * as t from 'io-ts';

import { isNonEmptyString, isRealValue, ProcedureTemplateType } from '@tr-common';

import { IProcedure, isSpecialProcedure } from '../../models';
import { AnsweredQuestion } from './answered-question';

export const loadFullStudySources = {
  scratch: 'Full survey load from the scratch',
  review: 'Full load from the review-answers router',
  status: 'Full load from the procedure status router',
  screening: 'Load after submit screening procedure',
  signJAEB: 'Load after sign consent if a user come from JAEB',
  consent: 'Load after submit consent procedure',
  questionnaire: 'Load after submit questionnaire procedure',
  notEligible: 'Load after the platform recognizes not-eligible participant for a study',
} as const;
export const loadFullStudySourcesSchema = t.keyof(loadFullStudySources);
export type LoadFullStudySourcesType = t.TypeOf<typeof loadFullStudySourcesSchema>;

export const areAllQuestionsHavingAnswers = qs => qs.filter(a => !isNonEmptyString(a.parent_option_id)).every(q => isRealValue(q.answers));

export const isProcedureReadyToStatus = (procedure: IProcedure, questions: AnsweredQuestion[] = []): boolean => {
  let isReady = false;

  if (isRealValue(procedure)) {
    isReady = isRealValue(procedure.created_at);
    if (!isSpecialProcedure(procedure.id as ProcedureTemplateType)) {
      isReady =  isReady || areAllQuestionsHavingAnswers(questions);
    }
  }

  return isReady;
};
