import { Moment, MomentInput } from 'moment/moment';

import {
  A1CRowValidator, FormGroupProtoType,
  isEmptyValue,
  isNonEmptyString,
  isNullOrUndefined,
  isRealValue,
  RowA1c,
  RowA1CFormType, RowA1cInWidget,
  ValidatorType,
} from '@tr-common';
import { FormArray, FormGroup } from '@angular/forms';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

const isRealValuesInPair = ({date, a1c}: RowA1c) => isRealValue(date) && !isEmptyValue(a1c);

const isDateButNotValid = (d: string): boolean => isNonEmptyString(d) && !moment(d, 'YYYY-DD').isValid();

export const hasA1CRowUnfinishedData = ({date, a1c}: RowA1c): boolean =>
  isNonEmptyString(date) && isEmptyValue(a1c) || !isEmptyValue(a1c) && !isNonEmptyString(date);

export const validateA1C = (data: RowA1c[], validity: A1CRowValidator[]): ValidatorType => {
  const hasEnteredValues = data.some(isRealValuesInPair) && !data.some(hasA1CRowUnfinishedData);
  const hasInvalidDates = data.map(({date}: RowA1c) => date).filter(isDateButNotValid).length > 0;
  const hasNotPassedValidation = validity.some(({date, a1c}) => date === 'INVALID' || a1c === 'INVALID');

  return !hasEnteredValues || hasInvalidDates || hasNotPassedValidation ? 'INVALID' : 'VALID';
};

export const fillA1cValidity = (data: RowA1c[], previousValidity: A1CRowValidator[] = []): A1CRowValidator[] => data.map(
  (d, idx) => ({
    date: isEmptyValue(d.date) ? 'default' : isNonEmptyString(previousValidity[idx]?.date) ? previousValidity[idx].date : 'VALID',
    a1c: isEmptyValue(d.a1c) ? 'default' : isNonEmptyString(previousValidity[idx]?.a1c) ? previousValidity[idx].a1c : 'VALID'})
);

const getValidityFromElementInPair = (
  pair: FormGroupProtoType<RowA1cInWidget>, key: keyof RowA1c
): ValidatorType => {
  const el = pair[key];

  return isEmptyValue(el.value) ? 'default' : isNullOrUndefined(el.errors) ? 'VALID' : 'INVALID';
}

export const fillA1cValidityFromForm = (
  controls: FormGroup<RowA1CFormType>[]
): A1CRowValidator[] => controls.map((rowA1c, idx) => {
  const pair: FormGroupProtoType<RowA1cInWidget> = controls[idx].controls;

  return {a1c: getValidityFromElementInPair(pair, 'a1c'), date: getValidityFromElementInPair(pair, 'date')};
});
