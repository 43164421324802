import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { IconsToRegister } from '@tr-common';

@Injectable({providedIn: 'root'})
export class IconsRegisterService {
  constructor(
    private iconReg: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  initSvgIcons(listOfAdditionalIcons: Array<IconsToRegister>) {
    listOfAdditionalIcons.forEach(
      ({iconName, iconPath}) => this.iconReg.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(iconPath))
    );
  };
}
