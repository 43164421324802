<div class="mobile-header-menu">
  <div class="mobile-header-menu-top">
    <img src="assets/icons/logo.svg" alt="#">
    <button mat-flat-button color="secondary" class="is-with-icon is-squared" (click)="dialogRef.close(null)">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div>
    <div class="mobile-header-menu-links">
      <a routerLinkActive="active" routerLink="/landing" (click)="closeDialog()" [routerLinkActiveOptions]="{exact: true}">Home</a>
      <a routerLink="/landing" fragment="contact-section" (click)="closeDialog(true)">Contact Us</a>
      <a routerLinkActive="active" routerLink="/faq" (click)="closeDialog()">FAQ</a>
    </div>

    <div class="flex mobile-header-menu-buttons">
      <a mat-flat-button color="secondary" class="button-sm" routerLink="/auth/login" (click)="closeDialog()">Sign In</a>
      <a mat-flat-button color="primary" class="button-sm is-shadowed" routerLink="/auth/register" (click)="closeDialog()">Sign Up</a>
    </div>

    <div class="mobile-header-menu-social-icons">
      <p>Find Us On:</p>
      <a mat-flat-button class="is-with-icon is-rounded" href="https://www.facebook.com/T1DExchange/" target="_blank" rel="noopener">
        <mat-icon svgIcon="facebook"></mat-icon>
      </a>
      <a mat-flat-button class="is-with-icon is-rounded" href="https://twitter.com/T1DExchange" target="_blank" rel="noopener">
        <mat-icon svgIcon="twitter"></mat-icon>
      </a>
      <a mat-flat-button class="is-with-icon is-rounded" href="https://www.instagram.com/t1dexchange/" target="_blank" rel="noopener">
        <mat-icon svgIcon="instagram"></mat-icon>
      </a>
      <a mat-flat-button class="is-with-icon is-rounded" href="https://www.linkedin.com/company/t1d-exchange/" target="_blank"
        rel="noopener"><mat-icon svgIcon="linkedin"></mat-icon>
      </a>
    </div>
  </div>
</div>
