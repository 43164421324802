import { boolean, number, string, type, TypeOf } from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { nullable, surveyTypeSchema } from 'tr-lib';

export const missedStudySchema = type({
  id: number, // same as user_study_id
  study_id: string,
  user_study_id: number, // same as id
  study_type: surveyTypeSchema,
  title: string,
  missed_at: DateFromISOString,
  notified: boolean,
  notified_at: nullable(DateFromISOString),
}, 'missedStudySchema');
export type MissedStudyType = TypeOf<typeof missedStudySchema>;

export interface MissedStudiesQuery {
  notified: boolean,
}
