import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ConsentToSignWithPending } from '../../../consent/models';
import { StudyConsentFacade } from '../../store';

@Component({
  selector: 'lib-survey-consent',
  template: `
    <ng-container *ngIf="consentStatus$ | async as state">
      <lib-sign-consent-form *ngIf="state.consent && !state.isJAEB" [consentBody]="state.consent" [consentAgreement]="state.agreement_text"
        [isChildConsent]="state.isChildConsent" (sign)="onSignConsent($event, state)">
      </lib-sign-consent-form>

      <lib-sign-jaeb-consent *ngIf="state.isJAEB" [consentBody]="consentBodyJAEB$ | async" (sign)="onSignConsentForJaeb(state)">
      </lib-sign-jaeb-consent>
    </ng-container>

    <ng-container *ngIf="consentError$ | async">
      An error may have occurred while signing consent, and the consent form has not been submitted.<br/>
      Contact us by phone <a href="tel:617-892-6165">617-892-6165</a>
      or email <a href="mailto:registry@T1DExchange.org">registry@T1DExchange.org</a>
    </ng-container>
  `,
})
export class SurveyConsentComponent {
  consentError$: Observable<boolean> = this.consent.consentError$;
  consentStatus$: Observable<ConsentToSignWithPending> = this.consent.consentStatus$;
  consentBodyJAEB$: Observable<string> = this.consent.consentBodyJAEB$;

  constructor(public consent: StudyConsentFacade) {}

  onSignConsent({name}: {name: string}, {id}: ConsentToSignWithPending): void {
    this.consent.signConsent(name, id);
  }

  onSignConsentForJaeb({id}: ConsentToSignWithPending): void {
    this.consent.signJaebConsent(id);
  }
}
