import { Moment, MomentInput } from 'moment';

import {
  commonMomentFormats,
  isEmptyString,
  isNonEmptyString,
  isNullOrUndefined,
  isRealValue,
  matchDateCondition,
  serverDateFormat,
  SignType,
} from '@tr-common';

import { GranularityFormat, GranularityType } from './date-helper';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export class StudyOptionCalcDate {
  when = 'now';
  sign: SignType = '';
  granularity: GranularityType = '';
  isCurrentDate = true;
  private _gain = 0;

  constructor(source?: string | Partial<StudyOptionCalcDate>, readonly format = serverDateFormat, public tick?: () => Moment) {
    const isTickNotDefined = isNullOrUndefined(this.tick);
    let defaultGranularityFormat = 'YYYY-MM-DD';

    if (isNonEmptyString(source)) {
      const expression = matchDateCondition(source);

      if (isRealValue(expression)) {
        const [, when, , sign, gain, granularity] = expression;

        Object.assign(this, {when});
        if (when === 'now') {
          this.tick = isTickNotDefined ? moment : this.tick;
        }
        if (isRealValue(sign) && isRealValue(gain) && isRealValue(granularity)) {
          Object.assign(this, {sign, gain: +gain, granularity});
          this.isCurrentDate = this.gain === 0;
        }
        defaultGranularityFormat = GranularityFormat[granularity];
        if (when === 'birthday' && isTickNotDefined) {
          throw new Error('Incorrect use of StudyOptionCalcDate while init: the third parameter should be defined');
        }
      } else {
        throw new Error('Invalid format of ' + source + ' for StudyOptionCalcDate');
      }
    } else if (isRealValue(source)) {
      Object.assign(this, source);
    }
    this.format = (isRealValue(format) && commonMomentFormats.includes(format)) ? format : defaultGranularityFormat;
    // the check below is only for test purposes
    if (isTickNotDefined) {
      this.tick = moment;
    }
  }

  get isFilled(): boolean {
    return this.when === 'now' && isEmptyString(this.sign) && isEmptyString(this.granularity)
      || isNonEmptyString(this.sign) && isNonEmptyString(this.granularity) && isRealValue(this.gain) && this.gain > 0;
  }

  get value(): string {
    const clone = this.tick().clone();
    let result: Moment = clone;

    if (this.granularity !== '') {
      result = this.sign === 'plus' ? clone.add(this.gain, this.granularity) : clone.subtract(this.gain, this.granularity);
    }

    return result.format(this.format);
  }

  get gain(): number {
    return this._gain;
  }

  set gain(newGain: number) {
    this.isCurrentDate = isNullOrUndefined(newGain) || newGain === 0;
    this._gain = newGain;
  }

  toString(): string {
    return `calc-date:${this.when}` + (this.isCurrentDate ? '' : `-${this.sign}-${this.gain}-${this.granularity}`);
  }
}
