import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { filterRealValues, isNonEmptyArray, isRealValue } from '@tr-common';

import { buildStudyNavigation, IProcedure, isStudyHeaderBlocked, StudyHeader } from '../../../models';
import { hasAllParamIDs } from '../../models';
import { SurveyState } from '../reducers';
import { selectParamsIDs, selectProcedures, selectProceduresWithAnswers, selectStudy, selectUserProcedures } from '../selectors';
import { gotoProcedure } from '../actions';

@Injectable({providedIn: 'root'})
export class StudyNavigationFacade {
  navigation$: Observable<StudyHeader> = combineLatest([
    this.store.select(selectProcedures).pipe(filter(isNonEmptyArray)),
    this.store.select(selectStudy).pipe(filterRealValues()),
    this.store.select(selectUserProcedures),
    this.store.select(selectProceduresWithAnswers),
    this.store.select(selectParamsIDs).pipe(filter(hasAllParamIDs))
  ]).pipe(
    map((
      [procedures, study, userProcedures, proceduresWithAnswers, storeParams]
    ) => buildStudyNavigation(procedures, study, isStudyHeaderBlocked(study.status, userProcedures), proceduresWithAnswers, storeParams))
  );

  constructor(private store: Store<SurveyState>) {}

  onProcedureClick(procedure: IProcedure): void {
    if (procedure.enabled) {
      this.store.dispatch(gotoProcedure({payload: procedure.id, isCompleted: isRealValue(procedure.created_at)}));
    }
  }

  onLeaveStudy(action: Action): void {
    if (isRealValue(action)) {
      this.store.dispatch(action);
    }
  }
}
