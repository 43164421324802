import { answerWidget, LabelIdPairType, OptionAnswerType } from '../../common';
import { StudyOptionType } from '../../models/study-option';

export const convertArrayValueToLabelPairs = (
  values: OptionAnswerType[], options: StudyOptionType[] = []
): LabelIdPairType[] => values.map(({question_option, value}) => {
  const option = options.find(({id}) => id === question_option);
  const label = option.widget === answerWidget.multiselectItem ? option.title : value.toString();

  return {id: question_option, label};
});
