import { createAction, props } from '@ngrx/store';

import {
  BaseChildProfileType,
  BaseParticipantType,
  ChangePassPayload,
  ParticipantDetails,
  ParticipantOwnProfile,
  ParticipantProfile,
  ParticipantState,
  ParticipantUpdateProfileWithIdType,
  ServerValidationErrors,
  UserNotification,
} from 'tr-lib';

import { SourcesToComponentType } from '@app/model/constants';
import { WithdrawalReasons } from '@app/user-profile/model';

export enum userActions {
  RECONSENT_FINISH_STEP1 = '[User] Reconsent Finish Step 1',
  GET_USER = '[User] Get Profile',
  GET_USER_SUCCESS = '[User] Get Profile Success',
  GET_USER_FAIL = '[User] Get Profile Fail',
  LOAD_USER = '[User] Load Profile',
  LOAD_USER_SUCCESS = '[User] Load Profile Success',
  LOAD_USER_FAIL = '[User] Load Profile Fail',
  RESET_STATE = '[User] Reset this store',
  CHANGE_PASSWORD = '[User] Change Password',
  CHANGE_PASSWORD_SUCCESS = '[User] Change Password Success',
  CHANGE_PASSWORD_RECONSENT = '[User] Change Password Reconsent',
  CHANGE_PASSWORD_RECONSENT_SUCCESS = '[User] Change Password Reconsent Success',
  CHANGE_PASSWORD_RECONSENT_FAIL = '[User] Change Password Reconsent Fail',
  UPDATE_PROFILE_RECONSENT = '[User] Update Profile Re-consent',
  UPDATE_PROFILE_RECONSENT_SUCCESS = '[User] Update Profile Re-consent Success',
  UPDATE_PROFILE_RECONSENT_FAIL = '[User] Update Profile Re-consent Fail',
  UPDATE_PROFILE_VIA_DIALOG = '[User] Update Profile via modal dialog to be sure',
  UPDATE_PROFILE_VIA_EMAIL_CHECK = '[User] Update Profile via email check',
  UPDATE_PROFILE_VIA_EMAIL_CHECK_SUCCESS = '[User] Updated Profile via email check success',
  UPDATE_PROFILE_VIA_EMAIL_CHECK_FAIL = '[User] Updated Profile via email check fail',
  UPDATE_PROFILE_WHILE_RECONSENT_PENDING = '[User] Update Profile while re-consent pending',
  UPDATE_PROFILE_WHILE_RECONSENT_PENDING_SUCCESS = '[User] Update Profile success while re-consent pending',
  USER_HAS_TO_BE_REROUTED_IF_REGULAR = '[User] User is back to regular process',
  USER_HAS_TO_BE_REROUTED_IF_NON_REGULAR = '[User] User in assent/consent/reconsent process',
  USER_HAS_TO_UPDATE_ITS_PROFILE_TYPE = '[User] User has to update its profile type or sign out',
  USER_UPDATE_ITS_PROFILE_TYPE = '[User] User update its profile type',
  USER_UPDATE_ITS_PROFILE_TYPE_SUCCESS = '[User] User updated its profile type success',
  USER_UPDATE_ITS_PROFILE_TYPE_FAIL = '[User] User updated its profile type fail',
  TOGGLE_NOTIFICATION_BANNER = '[User] Toggle Notification Banner',
  DEACTIVATE_ACCOUNT = '[User] Deactivate Account',
  DEACTIVATE_ACCOUNT_SUCCESS = '[User] Deactivate Account Success',
  DEFAULT_USER_STATE = '[User] Default User State'
}

export const getUser = createAction(
  userActions.GET_USER,
  props<{source?: SourcesToComponentType, checkSmsConsent?: boolean, loadChildInfo?: boolean}>()
);

export const getUserSuccess = createAction(
  userActions.GET_USER_SUCCESS,
  props<{payload: ParticipantOwnProfile, source: SourcesToComponentType}>()
);

export const getUserFail = createAction(
  userActions.GET_USER_FAIL,
  props<{error: unknown}>()
);

// `getUser` action is overloaded and some business logic relies on it, while this action purposed to just load data from server
export const loadUser = createAction(userActions.LOAD_USER);

export const loadUserSuccess = createAction(
  userActions.LOAD_USER_SUCCESS,
  props<Partial<ParticipantOwnProfile>>()
);

export const loadUserFail = createAction(
  userActions.LOAD_USER_FAIL,
  props<{error: unknown}>(),
);

export const resetState = createAction(
  userActions.RESET_STATE
);

export const changePass = createAction(
  userActions.CHANGE_PASSWORD,
  props<{payload: Partial<ChangePassPayload>}>()
);

export const changePassSuccess = createAction(
  userActions.CHANGE_PASSWORD_SUCCESS,
  props<{token: string}>()
);

export const changePasswordReconsent = createAction(
  userActions.CHANGE_PASSWORD_RECONSENT,
  props<{payload: ChangePassPayload}>()
);

export const changePasswordReconsentSuccess = createAction(
  userActions.CHANGE_PASSWORD_RECONSENT_SUCCESS
);

export const changePasswordReconsentFail = createAction(
  userActions.CHANGE_PASSWORD_RECONSENT_FAIL,
  props<{error: unknown}>()
);

export const updateProfileReconsent = createAction(
  userActions.UPDATE_PROFILE_RECONSENT,
  props<{payload: Partial<BaseParticipantType>, onSuccessPayload: {payload: ChangePassPayload}}>()
);

export const updateProfileReconsentSuccess = createAction(
  userActions.UPDATE_PROFILE_RECONSENT_SUCCESS,
  props<{payload: Partial<ParticipantProfile>, onSuccessPayload: {payload: ChangePassPayload}}>()
);

export const updateProfileReconsentFail = createAction(
  userActions.UPDATE_PROFILE_RECONSENT_FAIL,
  props<{error: unknown}>()
);

export const reconsentFinishStep1 = createAction(
  userActions.RECONSENT_FINISH_STEP1,
);

export const updateProfileViaDialog = createAction(
  userActions.UPDATE_PROFILE_VIA_DIALOG,
  props<{payload: Partial<ParticipantProfile>, message: string}>()
);

export const updateProfileViaEmailCheck = createAction(
  userActions.UPDATE_PROFILE_VIA_EMAIL_CHECK,
  props<{payload: Partial<ParticipantProfile>}>()
);

export const updateProfileViaEmailCheckSuccess = createAction(
  userActions.UPDATE_PROFILE_VIA_EMAIL_CHECK_SUCCESS,
  props<{payload: Partial<ParticipantProfile>, message: string}>()
);

export const updateProfileViaEmailCheckFail = createAction(
  userActions.UPDATE_PROFILE_VIA_EMAIL_CHECK_FAIL,
  props<{status: number, error: ServerValidationErrors<ParticipantProfile>}>()
);

export const updateProfileWhileReconsentPending = createAction(
  userActions.UPDATE_PROFILE_WHILE_RECONSENT_PENDING,
  props<{payload: Partial<BaseChildProfileType>}>()
);

export const updateProfileWhileReconsentPendingSuccess = createAction(
  userActions.UPDATE_PROFILE_WHILE_RECONSENT_PENDING_SUCCESS,
  props<{payload: Partial<BaseChildProfileType>}>()
);

export const toggleNotificationBanner = createAction(
  userActions.TOGGLE_NOTIFICATION_BANNER,
  props<{notification: UserNotification}>()
);

export const userHasToBeReroutedIfRegular = createAction(
  userActions.USER_HAS_TO_BE_REROUTED_IF_REGULAR,
  props<{source: SourcesToComponentType}>()
);

export const userHasToBeReroutedIfNonRegular = createAction(
  userActions.USER_HAS_TO_BE_REROUTED_IF_NON_REGULAR,
  props<{state: ParticipantState}>()
);

export const userHasToUpdateItsProfileType = createAction(
  userActions.USER_HAS_TO_UPDATE_ITS_PROFILE_TYPE,
  props<{id: string}>()
);

export const userUpdateItsProfileType = createAction(
  userActions.USER_UPDATE_ITS_PROFILE_TYPE,
  props<{payload: ParticipantUpdateProfileWithIdType}>()
);

export const userUpdateItsProfileTypeSuccess = createAction(
  userActions.USER_UPDATE_ITS_PROFILE_TYPE_SUCCESS,
  props<{payload: ParticipantDetails}>()
);

export const userUpdateItsProfileTypeFail = createAction(
  userActions.USER_UPDATE_ITS_PROFILE_TYPE_FAIL,
  props<{error: unknown}>()
);

export const deactivateAccount = createAction(
  userActions.DEACTIVATE_ACCOUNT,
  props<{payload: WithdrawalReasons}>()
);

export const deactivateAccountSuccess = createAction(
  userActions.DEACTIVATE_ACCOUNT_SUCCESS
);

export const defaultUserState = createAction(
  userActions.DEFAULT_USER_STATE
);
