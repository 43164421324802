import { Component, Input, ViewEncapsulation } from '@angular/core';

import { SignedConsent } from '../../../consent/models';

@Component({
  selector: 'lib-signed-consent-review',
  template: `
    <div [innerHTML]="signedConsent.consent_text | safeHtml" class="consent"></div>
    <div>{{ signedConsent.subject_name }}, <i>{{ signedConsent.agreement_text }}</i></div>
    <div>{{ signedConsent.created_at | date:'M/d/yyyy h:mm:ss a' }}</div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./signed-consent-review.component.scss']
})
export class SignedConsentReviewComponent {
  @Input() signedConsent: SignedConsent;
}
