import { ProcedureTemplateTypes } from '@tr-common';

import { getConsentsToShowDialog, showWelcomeQuestionnaireDialog, showWelcomeScreeningDialog } from '../store';

export const isProcedureHasWelcomeDialog = (procedure: ProcedureTemplateTypes): boolean => [
  ProcedureTemplateTypes.screening, ProcedureTemplateTypes.consent, ProcedureTemplateTypes.questionnaire
].some(p => p === procedure);

export const procedure2ActionForWelcomeDialog = {
  [ProcedureTemplateTypes.screening]: (study, profile) => showWelcomeScreeningDialog({profile, study}),
  [ProcedureTemplateTypes.consent]: () => getConsentsToShowDialog(),
  [ProcedureTemplateTypes.questionnaire]: (study, profile) => showWelcomeQuestionnaireDialog({profile})
};
