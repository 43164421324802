import { FormControl } from '@angular/forms';

import { isNonEmptyArray, isRealValue } from './helpers';

export const unknownErrorMessage = 'Unknown error';

const getSpannedMessage = (message: string) => `<span>${ message }</span>`;
const getDivedMessage = (message: string) => `<div class="mbe-5">${ message }</div>`;

export const getServerErrorsMessages = (control: FormControl<string>, bySpan = true): string => {
  const messages = control.errors?.server;

  return isNonEmptyArray(messages) ? messages.map((m: string) => bySpan ? getSpannedMessage(m) : getDivedMessage(m)).join('') : messages;
};

export const isErrorState = (control: FormControl<string>): boolean => isRealValue(control) && control.invalid && control.touched;
