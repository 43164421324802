import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AsyncValidatorFn, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Subscription } from 'rxjs';
import { Moment, MomentInput } from 'moment/moment';

import {
  A1CRowValidator,
  dateShortFormat,
  FormControlErrorHandler,
  getValueByMultiplier,
  isEmptyValue,
  isNonEmptyArray,
  isNonEmptyString,
  isNullOrFalse,
  isRealValue,
  RowA1c,
  RowA1CFormType,
  RowA1cInWidget,
  serverDateShortFormat,
  shortDateFormats,
  trimA1cRows,
  ValidatorType,
  WidgetErrorStateMatcher,
} from '@tr-common';

import {
  getErrorHandlers,
  hasOnlyAsyncWarnings,
  SourceChange,
  SourceChangeType,
  StudyOptionLimits,
  WidgetModify,
  WidgetPrototype,
} from '../../../models';
import { StudyOption } from '../../../models/study-option';
import {
  fillA1cValidity,
  fillA1cValidityFromForm,
  fillDataOutWithValidity,
  getA1cWidgetFormGroup,
  getDefaultA1cData,
  getDefaultA1cDataInWidget,
  hasA1CRowUnfinishedData,
  startNumberOfA1cPairs,
  validateA1C,
} from '../../models';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

@Component({
  selector: 'lib-a1c-dynamic-widget',
  templateUrl: './a1c-dynamic-widget.component.html',
  styleUrls: ['./a1c-dynamic-widget.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: shortDateFormats},
  ],
  encapsulation: ViewEncapsulation.None
})
export class A1cDynamicWidgetComponent implements WidgetPrototype<string>, OnChanges, OnDestroy {
  @Input() option: StudyOption;
  @Input() value: string;
  @Input() asyncValidators: AsyncValidatorFn[];
  @Output() modify = new EventEmitter<WidgetModify<string>>();
  protected readonly hasOnlyAsyncWarnings = hasOnlyAsyncWarnings;
  protected readonly placeholder = 'Enter value';
  protected readonly datePlaceholder = dateShortFormat;
  readonly dateErrorHandlers: FormControlErrorHandler[] = [{
    mustBeShowed: ({errors}: FormControl<Moment>) => isRealValue(errors?.matDatepickerParse), message: 'Invalid date.'
  }, {
    mustBeShowed: ({errors}: FormControl<Moment>) => isRealValue(errors?.matDatepickerMax), message: 'Date cannot be in the future.',
  }];
  errorMatcher = new WidgetErrorStateMatcher();
  formGroup = getA1cWidgetFormGroup();
  numberOfA1cPairs: number = startNumberOfA1cPairs;
  limits = new StudyOptionLimits();
  data: RowA1c[] = [];
  validity: A1CRowValidator[] = [];
  a1cErrorHandlers: FormControlErrorHandler[] = [];
  halfEmpty: boolean[] = [];
  trackByIndex = (idx: number, row: RowA1c) => `${idx}-${row.date}-${row.a1c as number}`;
  private subscriptions = new Subscription();

  constructor() {
    this.subscriptions.add(this.formGroup.valueChanges.subscribe(({pairs}) => {
      this.handleFormData(pairs);
      this.emitState(SourceChange.user);
    }));
  }

  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);

  get pairs(): FormArray<FormGroup<RowA1CFormType>> {
    return this.formGroup.controls.pairs;
  }

  get valid(): ValidatorType {
    return validateA1C(this.data, this.validity);
  }

  ngOnChanges({option, value, asyncValidators}: SimpleChanges): void {
    if (isRealValue(asyncValidators)) {
      this.pairs.controls.forEach(({controls: {date}}) => date.setAsyncValidators(this.asyncValidators));
    }
    if (isRealValue(option)) {
      this.limits = new StudyOptionLimits({...this.option?.numeric_limits});
      this.a1cErrorHandlers = getErrorHandlers(this.limits);
    }
    if (isRealValue(value)) {
      let a1cValue: RowA1cInWidget[] = getDefaultA1cDataInWidget();

      if (isNullOrFalse(this.value)) {
        this.data = getDefaultA1cData(); this.validity = fillA1cValidity(this.data, []);
      } else {
        const filledData = fillDataOutWithValidity(this.value, this.validity);

        [this.data, this.validity] = [filledData.data, filledData.validity];
        if (value.isFirstChange()) {
          this.numberOfA1cPairs = filledData.filled;
        }
        a1cValue = this.data.map(
          ({a1c, date}) => ({a1c, date: isNonEmptyString(date) ? moment(date, serverDateShortFormat) : null})
        );
      }
      /*
      console.log({
        handler: 'ngOnChanges', firstChange: value.isFirstChange(), a1cValue, numberOfA1cPairs: this.numberOfA1cPairs,
        value: this.value, this_data: this.data, this_validity: {...this.validity}
      });
      */
      this.formGroup.setValue({pairs: a1cValue}, {emitEvent: false});
      this.pairs.controls.forEach(({controls: {a1c, date}}) => {
        a1c.setValidators(Validators.compose([
          ...this.limits.getMinMaxValidators,
          this.option.isFloat ? this.limits.floatValidator : this.limits.integerValidator
        ]));
        if (isEmptyValue(a1c.value)) {
          a1c.setErrors(null, {emitEvent: false});
        }
        if (isEmptyValue(date.value)) {
          date.setErrors(null, {emitEvent: false});
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  emitState(source: SourceChangeType): void {
    const jsonValue = isNonEmptyArray(this.data) ? JSON.stringify(trimA1cRows(this.data)) : null;

    this.halfEmpty = this.data.map(row => hasA1CRowUnfinishedData(row));
    // console.log({handler: `emitState(${source})`, emitValue, data: this.data, validity: this.validity});
    this.modify.emit({value: jsonValue, valid: isEmptyValue(jsonValue) ? 'default' : this.valid, source});
  }

  addMoreClick(): void {
    this.numberOfA1cPairs++;
  }

  increment(field: FormControl<number>, multiplier = 1): void {
    field.setValue(getValueByMultiplier(field, multiplier, this.option.isFloat));
  }

  month(field: FormControl<Moment>, selectedMoment: Moment, datepicker: MatDatepicker<unknown>): void {
    const ctrlValue: Moment = field.value || moment();

    ctrlValue.year(selectedMoment.year()); ctrlValue.month(selectedMoment.month());
    field.setValue(ctrlValue);
    datepicker.close();
  }

  isRowInvalid(idx: number): boolean {
    return this.halfEmpty[idx] || this.validity[idx]?.a1c === 'INVALID' || this.validity[idx]?.date === 'INVALID';
  }

  private handleFormData(pairs: Partial<RowA1cInWidget>[]): void {
    this.data = pairs.map(({a1c, date}) => ({
      a1c, date: isRealValue(date) ? date.format(serverDateShortFormat) : null
    }));
    this.validity = fillA1cValidityFromForm(this.pairs.controls);
    // console.log({handler: 'handleFormData', pairs, data: this.data, validity: {...this.validity}});
  }
}
