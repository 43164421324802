import { Action, createReducer, on } from '@ngrx/store';

import { Consent, ConsentBody } from '../../../consent/models';
import {
  loadConsentBody,
  loadConsentBodyFail,
  loadConsentBodySuccess,
  loadConsents,
  loadConsentsFail,
  loadConsentsSuccess,
  loadJaebConsentBody,
  loadJaebConsentBodyFail,
  loadJaebConsentBodySuccess,
  signConsent,
  signConsentFail,
  signConsentSuccess,
} from '../index';

export interface SurveyConsentState {
  consentBody: ConsentBody;
  consentError: boolean;
  consentId: number;
  consents: Consent[];
  jaebConsent: string;
  pendingList: boolean;
  pendingBody: boolean;
  pendingSign: boolean;
}

export const initialConsentState: SurveyConsentState = {
  consentBody: null,
  consentError: false,
  consentId: null,
  consents: [],
  jaebConsent: null,
  pendingList: false,
  pendingSign: false,
  pendingBody: false,
};

const reducer = createReducer(
  initialConsentState,
  on(loadConsents, state => ({...state, pendingList: true, consentError: false})),
  on(signConsent, state => ({...state, pendingSign: true, consentError: false, consentBody: null})),
  on(loadConsentBody, loadJaebConsentBody, state => ({...state, pendingBody: true, consentError: false})),
  on(loadConsentsSuccess, (state, {payload}) => ({...state, consents: payload, pendingList: false})),
  on(signConsentSuccess, state => ({...state, pendingList: true, pendingSign: false})),
  on(loadConsentBodySuccess, (state, {consentId, payload}) => ({...state, consentId, consentBody: payload, pendingBody: false})),
  on(loadJaebConsentBodySuccess, (state, {consentId, payload}) => ({
    ...state, consentId, jaebConsent: payload.consent, pendingBody: false
  })),
  on(loadConsentsFail, loadConsentBodyFail, loadJaebConsentBodyFail, signConsentFail, state => ({
    ...state, pendingList: false, pendingSign: false, pendingBody: false, consentError: true
  })),
);

export const studyConsentReducer = (state: SurveyConsentState | undefined, action: Action) => reducer(state, action);
