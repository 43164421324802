import { Component } from '@angular/core';

import { SurveyIntroOrResearchPageType } from '../../models';
import { StudyFacade } from '../../store';

@Component({
  selector: 'lib-survey-intro-wrapper',
  template: `
    <lib-question-wrapper *ngIf="introPage$ | async as intro" class="flex intro-page" button="Back to Dashboard"
      (back)="study.returnToDashboard()">
      <div question-or-consent>
        <lib-survey-intro [page]="intro">
          <button mat-flat-button next-button class="survey-button" color="primary" (click)="nextProcedure(intro)">
            {{ intro.button }}
          </button>
          <!-- TODO We should consider to find a way to use the button above one time -->
          <button mat-flat-button next-button-2 class="survey-button" color="primary" (click)="nextProcedure(intro)">
            {{ intro.button }}
          </button>
        </lib-survey-intro>
      </div>
    </lib-question-wrapper>
  `
})
export class SurveyIntroWrapperComponent {
  introPage$ = this.study.introPage$;

  constructor(public study: StudyFacade) {}

  nextProcedure(userProcedure: SurveyIntroOrResearchPageType): void {
    if (userProcedure.isCompleted) {
      this.study.nextProcedure();
    } else {
      this.study.submitProcedure(userProcedure, false);
    }
  }
}
