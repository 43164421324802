import { ElementRef } from '@angular/core';
import * as t from 'io-ts';
import { Moment, MomentInput } from 'moment';

import { commonMomentFormats, isRealValue, studyOptionValidatorOperators, StudyOptionValidatorOperatorsType } from '@tr-common';

import { DateRangeType, ifValueIsSimpleType, SimpleValueType } from './study-option-types';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export const getDateValue = (
  dateInputElement: ElementRef
): string => [dateInputElement].filter(Boolean).map(e => e.nativeElement).filter(Boolean).map(e => e.value as string)[0];

export enum GranularityFormat {
  days = 'YYYY-MM-DD',
  months = 'YYYY-MM',
  years = 'YYYY'
}

export type GranularityType = '' | 'days' | 'months' | 'years';

export enum DateValidationEnum {
  past = 'PAST_DATE',
  future = 'FUTURE_DATE'
}

export const parseDate = (value: SimpleValueType | DateRangeType): Moment => {
  if (typeof value === 'number' || !isRealValue(value)) return null;
  const result = moment((ifValueIsSimpleType(value) ? value : value.beginDate) as MomentInput, commonMomentFormats);

  return result.isValid() ? result : null;
};

export const dateValidationSchema = t.union([t.literal(DateValidationEnum.past), t.literal(DateValidationEnum.future)]);

export const getGranularity = (value: Moment): 'days' | 'months' => {
  const creationFormat = value.creationData().format;

  return creationFormat === 'MM/YYYY' || creationFormat === 'YYYY-MM' ? 'months' : 'days';
}

export const compare2Moments = (lValue: Moment, operator: StudyOptionValidatorOperatorsType, rValue: Moment): boolean => {
  let result = false;

  switch (studyOptionValidatorOperators[operator]) {
    case studyOptionValidatorOperators.lt:
      result = lValue.isBefore(rValue, getGranularity(lValue));
      break;
    case studyOptionValidatorOperators.le:
      result = lValue.isSameOrBefore(rValue, getGranularity(lValue));
      break;
    case studyOptionValidatorOperators.gt:
      result = lValue.isAfter(rValue, getGranularity(lValue));
      break;
    case studyOptionValidatorOperators.ge:
      result = lValue.isSameOrAfter(rValue, getGranularity(lValue));
      break;
    case studyOptionValidatorOperators.eq:
      result = lValue.isSame(rValue, getGranularity(lValue));
      break;
    case studyOptionValidatorOperators.ne:
      result = !lValue.isSame(rValue, getGranularity(lValue));
      break;
    default:
      throw new Error('Incorrect operator: ' + operator);
  }

  return result;
}
