import * as t from 'io-ts';

import { optionAddressSchema, profileTypeSchema, studyConditionOperatorSchema, studySourceSchema } from '@tr-common';

import { comparisonDataTypeSchema } from './study-option-types';

export const studyConditionSchema = t.intersection([
  t.type({
    operator: studyConditionOperatorSchema,
  }),
  t.partial({
    l_value: studySourceSchema,
    r_value: t.union([profileTypeSchema, t.string, t.number]),
    stored_answer_path: optionAddressSchema,
    profile_field: t.string,
    comparison_data_type: comparisonDataTypeSchema,
  }),
], 'studyConditionSchema');
export type StudyConditionType = t.TypeOf<typeof studyConditionSchema>;
