<ng-container [formGroup]="parentForm">
  <mat-form-field class="full-width" appearance="outline" [class.shrink]="hasError(parentForm,'old_password', 'pattern')">
    <mat-label>Verify current password</mat-label>
    <mat-icon matSuffix [svgIcon]="showOldPass ? 'view-close' : 'view-open'" (click)="showOldPass = !showOldPass"></mat-icon>
    <input id="old-password" matInput formControlName="old_password" aria-label="Old password" autocomplete="current-password"
      aria-autocomplete="inline" [attr.type]="showOldPass ? 'text' : 'password'" />
    <mat-error [innerHTML]="oldPasswordError"></mat-error>
  </mat-form-field>

  <input id="username" type="text" class="no-display" formControlName="username" autocomplete="username" aria-autocomplete="inline" />

  <div class="new-password-wrapper">
    <mat-form-field class="full-width long-error" appearance="outline" [class.shrink]="hasError(parentForm,'new_password', 'pattern')">
      <mat-label>New password</mat-label>
      <mat-icon matSuffix [svgIcon]="showNewPass ? 'view-close' : 'view-open'" (click)="showNewPass = !showNewPass"></mat-icon>
      <input id="new-password" matInput formControlName="new_password" aria-label="New password" autocomplete="new-password"
        aria-autocomplete="inline" [attr.type]="showNewPass ? 'text' : 'password'" />
      <mat-error [innerHTML]="newPasswordError"></mat-error>
    </mat-form-field>

    <lib-password-creation-hint *ngIf="showPasswordCreationHint">
      <div class="flex create-pass-msg">
        <div class="create-pass-msg_icon"></div>
        <div>Here you can set up a password for your Registry account</div>
      </div>
    </lib-password-creation-hint>
  </div>

  <mat-form-field class="full-width" appearance="outline" [class.shrink]="hasError(parentForm,'new_password_confirm', 'pattern')">
    <mat-label>Confirm new password</mat-label>
    <mat-icon matSuffix [svgIcon]="showConfirmPass ? 'view-close' : 'view-open'" (click)="showConfirmPass = !showConfirmPass"></mat-icon>
    <input id="new-password-confirm" matInput formControlName="new_password_confirm" aria-label="Confirm new password"
      autocomplete="new-password" aria-autocomplete="none" [attr.type]="showConfirmPass ? 'text' : 'password'" />
    <mat-error [innerHTML]="passwordConfirmationError"></mat-error>
  </mat-form-field>

  <ng-content></ng-content>
</ng-container>
