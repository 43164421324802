import * as t from 'io-ts';

import { allGlobalConditionsSchema, nullable, threePartConditionSchema } from '@tr-common';

export enum CriteriaTypes {
  assigned = 'assigned',
  template = 'template'
}

export const criteriaTypeSchema = t.union([
  t.literal(CriteriaTypes.assigned),
  t.literal(CriteriaTypes.template)
], 'criteriaTypesSchema');
export type CriteriaType = t.TypeOf<typeof criteriaTypeSchema>;

export const conditionTypes = <A, B>(
  singleCodec: t.Type<A>, tupleCodec: t.Type<B>, name = `conditionTypes(${singleCodec.name}, ${tupleCodec.name})`
): t.Type<[A, B, ...B[]]> => {
  const arr = t.array(tupleCodec);
  const is = (u: unknown): u is [A, B, ...B[]] => Array.isArray(u) && arr.is(u.slice(1)) && singleCodec.is(u[0]) && u.length >= 2;

  return new t.Type(
    name,
    is,
    (u, c) => is(u) ? t.success(u) : t.failure(u, c),
    a => a.map(tupleCodec.encode) as [A, B, ...B[]]
  );
};

export const conditionLineGlobalSchema = conditionTypes(allGlobalConditionsSchema, threePartConditionSchema, 'conditionLineGlobalSchema');

export const conditionLineSchema = t.union([threePartConditionSchema, conditionLineGlobalSchema], 'conditionLineSchema');

export const fullConditionSchema = t.union([conditionLineSchema,
  conditionTypes(allGlobalConditionsSchema, conditionLineSchema, 'fullConditionSchema')
]);

export const criteriaSchema = t.intersection([
  t.type({
    queries: nullable(fullConditionSchema)
  }),
  t.partial({
    id: nullable(t.number),
    study: nullable(t.string),
    type: criteriaTypeSchema,
    pre_assigned_participants_count: t.number,
    participants_by_criteria_count: t.number,
    possible_to_assign_participants_count: t.number,
    name: nullable(t.string),
    can_edit: t.boolean,
    can_delete: t.boolean,
  })
], 'criteriaSchema');
