import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormGroupProtoType, emailPattern, getParticipantFormData, BaseParticipantType } from 'tr-lib';

export interface UserStateProfile extends BaseParticipantType {
  icon: string;
  isChild: boolean;
}

export const getUserProfileForm = () => new FormGroup<FormGroupProtoType<UserStateProfile>>({
  ...getParticipantFormData(),
  email: new FormControl<string>(null, Validators.compose([Validators.required, Validators.pattern(emailPattern)])),
  isChild: new FormControl<boolean>(false),
  icon: new FormControl<string>(null)
});
