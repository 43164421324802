import { Component, HostBinding, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationData } from '@app/model/dialogs';

@Component({
  selector: 'app-confirmation-dialog-registry',
  template: `
    <div mat-dialog-title><div class="close-icon" [mat-dialog-close]="null"><i class="material-icons">clear</i></div></div>

    <div mat-dialog-content>
      <img src="/assets/dialog/cloud-{{ data?.isDanger ? 'exclamatory.png' : 'question.svg' }}" alt="question">
      <h3>{{ data?.question ? data.question : 'Are you sure?' }}</h3>
      <p class="message" [class.danger]="data?.isDanger">
        <span class="action" [innerHTML]="data.action"></span>
        <span *ngIf="data?.what" class="name" [innerHTML]="data.what"></span>
      </p>
    </div>

    <div mat-dialog-actions [ngClass]="{'hide-confirm': data?.buttons?.hideConfirm, 'hide-cancel': data?.buttons?.hideCancel}">
      <button class="dialog-button" mat-flat-button color="primary" [mat-dialog-close]="false">
        {{ data?.buttons?.cancel ?? 'Cancel' }}
      </button>
      <button class="dialog-button" mat-flat-button color="secondary" [mat-dialog-close]="true">
        {{ data?.buttons?.confirm ?? 'Confirm' }}
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./confirm-dialog-registry.component.scss']
})
export class ConfirmationDialogRegistryComponent implements OnInit {
  @HostBinding('class.show-close-icon') showCloseIcon = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationData) {}

  ngOnInit() {
    this.showCloseIcon = this.data?.closeIcon;
  }
}
