import { Component, Input } from '@angular/core';

import { ProcedureStatusType } from '../../models';
import { StudyStatusFacade } from '../../study-survey/store';
import { LayoutService } from '../../services';

@Component({
  selector: 'lib-submitted-procedure-actions',
  template: `
    <ng-container *ngIf="completedOn">
      <p>You answered the {{ procedure.title | lowercase }} on:</p>
      <p><strong>{{ completedOn | date: 'medium' }}</strong></p>
    </ng-container>

    <div class="flex link-actions" [class.hide]="!procedure">
      <button *ngIf="reviewBtn" mat-flat-button class="survey-button is-with-icon" color="secondary" (click)="reviewAnswers()">
        <mat-icon svgIcon="view-open"></mat-icon>
        <span>View answers</span>
      </button>

      <button *ngIf="downloadBtn" mat-flat-button class="survey-button is-with-icon" color="secondary" (click)="downloadAnswers()">
        <mat-icon svgIcon="download"></mat-icon>
        <span>Download</span>
      </button>

      <button *ngIf="printBtn" mat-flat-button class="survey-button is-with-icon" color="secondary" (click)="printAnswers()">
        <mat-icon svgIcon="print"></mat-icon>
        <span>Print</span>
      </button>
    </div>
  `,
  styleUrls: ['./submitted-procedure-actions.component.scss'],
})
export class SubmittedProcedureActionsComponent {
  @Input() procedure: ProcedureStatusType;

  constructor(
    public studyStatus: StudyStatusFacade,
    private layout: LayoutService,
  ) {}

  get reviewBtn(): boolean {
    return this.procedure?.setting?.content?.review_answers_button;
  }

  get downloadBtn(): boolean {
    return this.procedure?.setting?.content?.download_button;
  }

  get printBtn(): boolean {
    // Gadgets print only first page
    return !this.layout.isGadget && this.procedure?.setting?.content?.print_button;
  }

  get completedOn(): string {
    return this.procedure?.setting?.content?.show_timestamp ? this.procedure?.created_at : null;
  }

  reviewAnswers(): void {
    this.studyStatus.reviewAnswers(this.procedure.id);
  }

  downloadAnswers(): void {
    this.studyStatus.getSubmittedAnswers(true);
  }

  printAnswers(): void {
    this.studyStatus.getSubmittedAnswers(true, true);
  }
}
