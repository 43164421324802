import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';

import { isNonEmptyArray, trySafely } from '@tr-common';

import { ConsentToSign, isConsentUnsigned,  } from '../../../consent/models';
import { SurveyConsentState } from '../reducers';

export const studyConsentProperty = 'study-consent';
export const selectSurveyConsentStore = createFeatureSelector<SurveyConsentState>(studyConsentProperty);
export const selectConsentPending = createSelector(
  selectSurveyConsentStore, ({pendingList, pendingSign, pendingBody}) => pendingList || pendingSign || pendingBody
);
export const selectConsentError = createSelector(selectSurveyConsentStore, ({consentError}) => consentError);
export const selectConsents = createSelector(selectSurveyConsentStore, s => get(s, ['consents'], []));
export const selectConsentId = createSelector(selectSurveyConsentStore, ({consentId}) => consentId);
export const selectConsentBody = createSelector(selectSurveyConsentStore, ({consentBody}) => consentBody);
export const selectJaebConsent = createSelector(selectSurveyConsentStore, ({jaebConsent}) => jaebConsent);
export const selectFirstUnsignedConsent = createSelector(selectConsents, all => isNonEmptyArray(all) ? all.find(isConsentUnsigned) : null);
export const selectIs1stUnsignedConsentAChildConsent = createSelector(
  selectFirstUnsignedConsent, (consent): boolean => trySafely(consent, c => c.consent_type === 'child')
);
export const selectConsent = createSelector(
  selectConsentId, selectConsentBody, selectIs1stUnsignedConsentAChildConsent,
  (id, consent, isChildConsent): ConsentToSign => ({...consent, id, isChildConsent})
);
