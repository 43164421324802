import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { array } from 'io-ts';
import { Observable } from 'rxjs';

import {
  isRealValue,
  MaybeApiError,
  REGISTRY_API_URL,
  REGISTRY_PARTICIPANT_URL,
  schemaValidationPipe,
  UserStudyProcedureType,
  userStudySchema,
  UserStudyType,
  validateSchemaWithExplicitError,
} from 'tr-lib';

import {
  Achievement,
  achievementSchema,
  lastViewedSchema,
  LastViewedType,
  MissedStudiesQuery,
  missedStudySchema,
  MissedStudyType,
} from '@app/dashboard/models';
import { smsConsentSchema, SmsConsentType } from '@app/dashboard/models/sms-consent';
import { pdfDownload, pdfPrint } from '@tr-common';
import { DOCUMENT } from '@angular/common';

@Injectable({providedIn: 'root'})
export class DashboardService {
  document: Document;
  constructor(
    private http: HttpClient,
    @Inject(REGISTRY_API_URL) private apiUrl: string,
    @Inject(REGISTRY_PARTICIPANT_URL) private participantUrl: string,
    @Inject(DOCUMENT) globalDocument: Document,
  ) {this.document = globalDocument;}

  getSMSConsent(userId: string): Observable<SmsConsentType> {
    return this.http.get<SmsConsentType>(`${this.apiUrl}/participant/${userId}/sms_consent`).pipe(
      schemaValidationPipe(smsConsentSchema, smsConsentSchema.name)
    );
  }

  getUserStudies(participantId: string): Observable<UserStudyType[]> {
    return this.http.get<UserStudyType[]>(`${this.apiUrl}/participant/${participantId}/user-studies`).pipe(
      schemaValidationPipe(array(userStudySchema), userStudySchema.name + '[]'),
    );
  }

  getUserAchievements(userId: string): Observable<Array<Achievement>> {
    return this.http.get<Array<Achievement>>(`${this.apiUrl}/participant/${userId}/achievements`).pipe(
      schemaValidationPipe(array(achievementSchema), achievementSchema.name + '[]'),
    );
  }

  getMissedStudies(userId: string, query?: MissedStudiesQuery): Observable<MaybeApiError<MissedStudyType[]>> {
    const params = {};

    if (query) {
      if ('notified' in query && isRealValue(query.notified)) {
        params['notified'] = query.notified;
      }
    }

    return this.http.get(`${this.apiUrl}/participant/${userId}/missed-opportunities`, {params}).pipe(
      validateSchemaWithExplicitError(array(missedStudySchema)),
    );
  }

  markMissedStudiesAsRead(userId: string, ids: {id: number}[]): Observable<MaybeApiError<MissedStudyType[]>> {
    return this.http.patch<MissedStudyType[]>(`${this.apiUrl}/participant/${userId}/missed-opportunities`, ids).pipe(
      validateSchemaWithExplicitError(array(missedStudySchema)),
    );
  }

  markStudyAsViewed(userId: string, userStudyId: string): Observable<LastViewedType> {
    return this.http.patch<LastViewedType>(`${this.apiUrl}/participant/${userId}/user_study/${userStudyId}/viewed`, {}).pipe(
      schemaValidationPipe(lastViewedSchema, lastViewedSchema.name)
    );
  }

  coreStudyScreeningReattempt(userId: string, userStudyId: number | string): Observable<unknown> {
    return this.http.post(`${this.apiUrl}/participants/${userId}/user-studies/${userStudyId}/screening_reattempt`, null);
  }

  loadHTMLVersion(userId: string, userStudyId: number | string, procedure: string): Observable<string> {
    const url = `${this.participantUrl}/${userId}/user-studies/${userStudyId}/procedures/${procedure}/answers_doc?document_format=html`;

    return this.http.get<string>(url);
  }

  loadPDFVersion(userId: string, userStudyId: number | string, procedure: string): Observable<HttpResponse<Blob>> {
    const url = `${this.participantUrl}/${userId}/user-studies/${userStudyId}/procedures/${procedure}/answers_doc?document_format=pdf`;

    return this.http.get(url, {observe: 'response', responseType: 'blob'});
  }

  downloadPDFVersion(filename: string, payload: Blob): void {
    pdfDownload(filename, payload, this.document);
  }

  printPDFVersion(payload: Blob): void {
    pdfPrint(payload, this.document);
  }

  studyUserProcedures(participantId: string, userStudyId: string): Observable<UserStudyProcedureType[]> {
    return this.http.get<UserStudyProcedureType[]>(
      `${this.participantUrl}/${participantId}/user-studies/${userStudyId}/user-procedures`
    );
  }
}
