import * as t from 'io-ts';
import { FormControl } from '@angular/forms';
import { Moment, MomentInput } from 'moment';

import { isObject, isRealValue, RowA1c } from '@tr-common';

import { getDateRangeObject, MatDateRange } from './date-range';

export type SimpleValueType = string | number | Moment | MomentInput;

export type AllValueTypesToValidate = SimpleValueType | DateRangeType | RowA1c;

export interface DateRangeType {
  beginDate: Moment;
  endDate: Moment;
}

export const getControlValueToValidate = (control: FormControl<unknown>): AllValueTypesToValidate => {
  const value: unknown = control?.value;
  const isDateRangeControl = isObject(value) && 'start' in value && 'end' in value;

  return (isDateRangeControl ? getDateRangeObject((value as unknown) as MatDateRange) : value) as AllValueTypesToValidate;
};

export const ifValueIsSimpleType = (
  value: SimpleValueType | DateRangeType
): value is SimpleValueType => !isRealValue((value as DateRangeType).beginDate);

export enum ComparisonData {
  date = 'value is treated as full date in format YYYY-MM-DD',
  float = 'value is casted to float',
  integer = 'value is casted to integer',
  'short-date' = 'value is treated as short date in format YYYY-MM',
  string = 'value is taken as string'
}
export const comparisonDataTypeSchema = t.keyof(ComparisonData);
export type ComparisonDataType = t.TypeOf<typeof comparisonDataTypeSchema>;
