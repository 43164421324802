import { NavigationExtras } from '@angular/router';
import * as t from 'io-ts';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { pick } from 'lodash';

import { ParticipantProfileType, filterRealValues, getPayloadFromToken, ParticipantOwnProfile, profileType } from 'tr-lib';

import { UserState } from '@store/reducers';

export const userIdFromToken = () => pipe(filterRealValues(), map(getPayloadFromToken), map(p => p.user_id), filterRealValues());

export const getTokenFromT1DSchema = t.type({
  token: t.string
}, 'getTokenFromT1DSchema');
export type GetTokenFromT1DType = t.TypeOf<typeof getTokenFromT1DSchema>;

export const childProfileFormErrorSchema = t.partial({
  child_phone: t.array(t.string),
  child_email: t.array(t.string),
}, 'childProfileFormErrorSchema');
export type ChildProfileFormError = t.TypeOf<typeof childProfileFormErrorSchema>;

export const getErrorsFromUpdateChildProfile = (parsed: ChildProfileFormError): ChildProfileFormError => ({
  ...('child_email' in parsed ? {child_email: parsed.child_email} : null),
  ...('child_phone' in parsed ? {child_phone: parsed.child_phone} : null),
});

export type LogoutType = string | {commands: string[], navigationExtras: NavigationExtras};

export const receiveErrors = (error: any): string => Object.values(error).join('/n');

export const convertUserSuccess = (state: UserState, payload: Partial<ParticipantOwnProfile>): UserState => payload
  ? {
    ...state, ...payload,
    dateJoined: new Date(payload['created_at']),
    profile_type: 'profile_type' in payload ? payload.profile_type : profileType.adult,
  } as UserState
  : state;

export const getParticipantProfile = (user: UserState): ParticipantProfileType => pick(user, [
  'first_name', 'last_name', 'email', 'phone', 'address', 'city', 'country_state', 'zip_code', 'timezone', 'profile_type'
]);
