import * as t from 'io-ts';

import { isNullOrUndefined, nullable } from '@tr-common';

export const researchProcedureSchema = t.type({
  id: t.string,
  title: t.string,
  button_text: t.string,
  text: nullable(t.string),
  url: nullable(t.string),
  add_participant_id_query_param: t.boolean,
  created_at: t.string,
}, 'researchProcedureSchema');
export type ResearchProcedure = t.TypeOf<typeof researchProcedureSchema>;

export const researchProcedureWithUserIdSchema = t.intersection([
  researchProcedureSchema,
  t.type({
    userID: t.string
  })
], 'researchProcedureWithUserIdSchema');
export type ResearchProcedureWithUserIDType = t.TypeOf<typeof researchProcedureWithUserIdSchema>;

export const composeURLForResearchStudy = (
  url: string, userID?: string, needToAdd?: boolean
): string => needToAdd ? `${url}${ isNullOrUndefined(url.match(/\?\S/)) ? '?' : '&' }ref_id=${userID}` : url;
