import { FormControl, FormGroup } from '@angular/forms';
import * as t from 'io-ts';

import { FormGroupProtoType, nullable } from '../common';

export const userHouseholdSchema = t.type({
  address: nullable(t.string),
  city: nullable(t.string),
  country_state: nullable(t.string),
  zip_code: nullable(t.string),
  timezone: nullable(t.number)
}, 'userHouseholdSchema');
export type UserHouseholdType = t.TypeOf<typeof userHouseholdSchema>;

export type UserHouseholdFormType = FormGroupProtoType<UserHouseholdType>;

export const getHouseholdForm = () => new FormGroup<UserHouseholdFormType>({
  address: new FormControl<string>(null),
  city: new FormControl<string>(null),
  country_state: new FormControl<string>(null),
  zip_code: new FormControl<string>(null),
  timezone: new FormControl<number>(null)
});
