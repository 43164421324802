<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!--not implemented placeholder-->
  <cdk-tree-node *cdkTreeNodeDef="let node;" class="flex tree-node" cdkTreeNodePadding [ngClass]="node.uiTestClass">
    <mat-label [style.color]="'#cc3322'">NOT IMPLEMENTED YET</mat-label>
  </cdk-tree-node>

  <!--question/sub-question text-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenIsQuestion" cdkTreeNodePadding widget="question"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <h3 [innerHTML]="node.data.titleForQuestion"></h3>
    <p class="show-more" (click)="node.data.shortTitleMode = !node.data.shortTitleMode">
      Show {{ node.data.shortTitleMode ? 'more' : 'less' }}
    </p>
    <div *ngIf="node.data['show_hint']" class="hint">
      <mat-icon svgIcon="tooltip"></mat-icon>
      Note: Select the required answers to proceed to the next question
    </div>
  </cdk-tree-node>
  <!-- likert_scale -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.likertScale)" cdkTreeNodePadding
    widget="likert-scale-widget" class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-likert-scale-widget class="flex" [option]="node.data" [value]="node.value" [filled]="!layout.isMobile"
      (modify)="onModify('likert-scale-widget', $event, node)">
    </lib-likert-scale-widget>
  </cdk-tree-node>

  <!-- matrix_scale -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenIsMatrixScale" cdkTreeNodePadding widget="matrix-scale-widget" class="flex tree-node"
    [ngClass]="node.cssClasses">
    <lib-matrix-scale-widget [options]="node.matrixScaleOptions" [value]="node.value"
      (modify)="onModify('matrix-scale-widget', $event, node)">
    </lib-matrix-scale-widget>
  </cdk-tree-node>

  <!-- radio -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.radio)" cdkTreeNodePadding widget="radio-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-radio-widget [option]="node.data" [value]="node.value" (modify)="onModify('radio-widget', $event, node)">
    </lib-radio-widget>
  </cdk-tree-node>

  <!--radio with text-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.radioWithText)"  cdkTreeNodePadding
    class="flex tree-node" [ngClass]="node.cssClasses" widget="radio-with-text-widget">
    <lib-radio-with-text-widget [option]="node.data" [value]="node.value"
      (modify)="onModify('radio-with-text-widget', $event, node)">
    </lib-radio-with-text-widget>
  </cdk-tree-node>

  <!--checkbox(option)-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.option)"  cdkTreeNodePadding widget="checkbox-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-checkbox-widget [option]="node.data" [value]="node.value"
      (modify)="onModify('checkbox-widget', $event, node)">
    </lib-checkbox-widget>
  </cdk-tree-node>

  <!--checkbox(option) with text-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.optionWithText)" cdkTreeNodePadding
    class="flex tree-node" [ngClass]="node.cssClasses" widget="checkbox-with-text-widget">
    <lib-checkbox-with-text-widget [option]="node.data" [value]="node.value"
      (modify)="onModify('checkbox-with-text-widget', $event, node)">
    </lib-checkbox-with-text-widget>
  </cdk-tree-node>

  <!--text-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.text)" cdkTreeNodePadding widget="text-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-text-widget class="flex" [option]="node.data" [value]="node.value" (modify)="onModify('text-widget', $event, node)">
    </lib-text-widget>
  </cdk-tree-node>

  <!--zip-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.zip)" cdkTreeNodePadding widget="zip-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-zip-widget [option]="node.data" [value]="node.value" (modify)="onModify('zip-widget', $event, node)">
    </lib-zip-widget>
  </cdk-tree-node>

  <!-- numerical integer -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.numerical)" cdkTreeNodePadding widget="numerical-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-numerical-widget [option]="node.data" [asyncValidators]="asyncValidators(node.data)" [value]="convertStringToNum(node.value)"
      (modify)="onModify('numerical-widget', $event, node)">
    </lib-numerical-widget>
  </cdk-tree-node>

  <!-- numerical float -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.float)" cdkTreeNodePadding widget="numerical-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-numerical-widget [option]="node.data" [asyncValidators]="asyncValidators(node.data)" [value]="convertStringToNum(node.value)"
      (modify)="onModify('numerical-widget', $event, node)">
    </lib-numerical-widget>
  </cdk-tree-node>

  <!--dropdown, special case node has to have dropdownOptions to be defined-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenIsDropdown" cdkTreeNodePadding widget="dropdown-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-dropdown-widget [options]="node.dropdownOptions" [value]="node.value"
      (modify)="onModify('dropdown-widget', $event, node)">
    </lib-dropdown-widget>
  </cdk-tree-node>

  <!-- autocomplete_(item|text), special case when a node has autocompleteOptions -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenIsAutocomplete" cdkTreeNodePadding widget="autocomplete-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-autocomplete-widget [options]="node.autocompleteOptions" [value]="node.value"
      (modify)="onModify('autocomplete-widget', $event, node)">
    </lib-autocomplete-widget>
  </cdk-tree-node>

  <!-- multiselect_(item|text), special case when a node has autocompleteOptions -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenIsMultiselect" cdkTreeNodePadding widget="multiselect-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-multiselect-widget class="full-width" [options]="node.multiselectOptions" [value]="node.value"
      (modify)="onModify('multiselect-widget', $event, node)">
    </lib-multiselect-widget>
  </cdk-tree-node>

  <!--label(checkbox without checkbox)-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.label)" cdkTreeNodePadding widget="label-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-label-widget [option]="node.data" (modify)="onModify('label-widget', $event, node)"></lib-label-widget>
  </cdk-tree-node>

  <!--date-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.date)" cdkTreeNodePadding widget="date-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-date-widget [asyncValidators]="asyncValidators(node.data)" [option]="node.data" [value]="node.value"
      (modify)="onModify('date-widget', $event, node)">
    </lib-date-widget>
  </cdk-tree-node>

  <!--short date-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.shortDate)" cdkTreeNodePadding widget="date-short-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-date-short-widget [option]="node.data" [value]="node.value" [asyncValidators]="asyncValidators(node.data)"
      (modify)="onModify('date-short-widget', $event, node)">
    </lib-date-short-widget>
  </cdk-tree-node>

  <!--date range-->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.dateRange)" cdkTreeNodePadding widget="date-range-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-date-range-widget [option]="node.data" [value]="node.value" [asyncValidators]="asyncValidators(node.data)"
      (modify)="onModify('date-range-widget', $event, node)">
    </lib-date-range-widget>
  </cdk-tree-node>

  <!-- a1c dynamic -->
  <cdk-tree-node *cdkTreeNodeDef="let node; when whenWidgetIs(answerWidget.a1cDynamic)" cdkTreeNodePadding widget="a1c-widget"
    class="flex tree-node" [ngClass]="node.cssClasses">
    <lib-a1c-dynamic-widget [option]="node.data" [value]="node.value" [asyncValidators]="asyncValidators(node.data)"
      (modify)="onModify('a1c-widget', $event, node)">
    </lib-a1c-dynamic-widget>
  </cdk-tree-node>
</cdk-tree>

<div *ngIf="answerSaveError" class="question-errors">
  <p *ngFor="let err of answerSaveError">{{ err }}</p>
</div>
