import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lib-study-is-closed-banner',
  template: `
    <img src="assets/man_with_loudspeaker.svg" alt="man with loudspeaker"/>
    <div class="text">This study is no longer open to enrollment through the T1D Exchange Registry.</div>
  `,
  styleUrls: ['./study-is-closed-banner.component.scss'],
})
export class StudyIsClosedBannerComponent {
  @Input() directionType = 'right' as 'right' | 'left';

  @HostBinding('class.direction-left') get isLeft() {
    return this.directionType === 'left';
  }

  @HostBinding('class.direction-right') get isRight() {
    return this.directionType === 'right';
  }
}
