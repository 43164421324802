import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/internal/operators/first';
import { switchMap } from 'rxjs/operators';

import { SignedConsent } from '../../../consent/models';
import { SurveyService } from '../../services/survey.service';
import { StudyConsentFacade, StudyFacade } from '../../store';
import { ParamsIDs } from '../../models';

@Component({
  selector: 'lib-consent-preview',
  template: `
    <div *ngIf="signedConsent$ | async as signedConsent" class="wrapper">
      <lib-goto-back-link (redirectTo)="location.back()"></lib-goto-back-link>
      <lib-signed-consent-review [signedConsent]="signedConsent"></lib-signed-consent-review>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./consent-preview.component.scss']
})
export class ConsentPreviewComponent implements OnInit {
  signedConsent$: Observable<SignedConsent> = this.consent.paramsForSignedConsent$.pipe(
    switchMap(({userID, consentID}) => this.service.loadSignedConsentBody(userID, consentID).pipe(first())),
    // tap(data => console.log(data))
  );

  constructor(
    public consent: StudyConsentFacade,
    public study: StudyFacade,
    public location: Location,
    public service: SurveyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const paramsIDs = this.route.snapshot.params as Partial<ParamsIDs>;

    // console.log({handler: 'ngOnInit', ...paramsIDs});
    this.study.setParamsIDsFromScratch(paramsIDs);
    this.consent.loadStudyPartially(paramsIDs.userID, paramsIDs.studyID);
  }
}
