import * as t from 'io-ts';

import { Answers, isRealValue, nullable, StudySource, StudyUuid } from '@tr-common';

import { ParticipantDetails } from '../participant-details';
import { studyConditionSchema } from '../study-condition-schema';
import { fillByLValue } from './fill-by-l-value';
import { StudyCondition } from './study-condition';

export const studyBaseRuleSchema = t.partial({
  description: nullable(t.string),
  conditions: t.array(studyConditionSchema),
}, 'studyBaseRuleSchema');
export type StudyBaseRuleType = t.TypeOf<typeof studyBaseRuleSchema>;

export abstract class StudyBaseRule<T extends StudyBaseRuleType> extends StudyUuid implements StudyBaseRuleType {
  description = '';
  conditions: StudyCondition[];
  invalid = false;
  disabled = false;
  isStoredOnBE = false;
  modifiedField: keyof T;

  ifValueMatchesConditions(participant: ParticipantDetails, userAnswers: Answers): boolean {
    return this.conditions.every(
      condition => condition.ifValueMatchesCondition(condition.l_value === StudySource.storedAnswer ? userAnswers : participant)
    );
  }

  protected setData(data: Partial<T>) {
    if (isRealValue(data)) {
      Object.assign(this, data);
      if (this.conditions) {
        this.conditions = this.conditions.map(condition => new StudyCondition(condition));
      }
    } else {
      this.conditions = [fillByLValue(StudySource.profile)];
    }
  }

  abstract clone(): T;
}
