export const faqContent = [
  {
    question: 'What is the Registry?',
    answer: `<p class="text-item">
      The T1D Exchange Registry is a research study, conducted over time, for individuals with type 1 diabetes and their supporters.
      Participants volunteer to provide their data for research (for example, by answering questions in annual surveys).
      Once enrolled, Registry participants have the opportunity to sign up for other studies on various topics related to type 1 diabetes.
      The goal is to:
    </p>
    <ul class="text-list">
      <li class="list-item">Improve knowledge of type 1 diabetes</li>
      <li class="list-item">Help accelerate the discovery and development of new treatments</li>
      <li class="list-item">Generate evidence to support policy or insurance coverage changes that help the T1D community</li>
    </ul>`
  },
  {
    question: 'Why should I join?',
    answer: `<p class="text-item">
      All prescription medications (even Tylenol or Advil) exist today because of research.
      Insurance decisions may also be made, in part, based on research data gathered from people undergoing treatment and care.
    </p>
    <p class="text-item">
      The T1D Exchange Registry is a way for you to participate in research that may make a difference in the treatment
      and care of type 1 diabetes. By sharing your opinions, experiences, and data, you will help create
      the most comprehensive data set of those diagnosed with type 1 diabetes in the United States, advancing meaningful treatment,
      care, and policy.
    </p>
    <p class="text-item">Be heard. Support the type 1 diabetes community. Help drive research that matters.</p>`
  },
  {
    question: 'What do I have to do?',
    answer: `<p class="text-item">To participate, you will be asked to:</p>
    <ul class="text-list">
      <li class="list-item">Read and sign an online informed consent form</li>
      <li class="list-item">Take a survey describing specific demographic and type 1 diabetes management information</li>
      <li class="list-item">Update your information annually</li>
      <li class="list-item">
        Periodically opt in for additional research opportunities (if you choose),
        i.e. taking new surveys or uploading health device data
      </li>
    </ul>`
  },
  {
    question: 'What is a research study?',
    answer: `<p class="text-item">
      A research study is a scientific approach to learn more about a problem or answer specific questions on how to prevent, diagnose,
      treat, and/or manage diseases and disorders. There are many types of research studies that may develop outcomes by:
    </p>
    <ul class="text-list">
      <li class="list-item">Testing if a product, such as a drug or equipment, is safe and effective</li>
      <li class="list-item">
        Using a survey or an interview to understand needs, problems, or feelings people have about a specific disease
      </li>
      <li class="list-item">Determining the best way to treat, prevent, or manage an illness</li>
    </ul>`
  },
  {
    question: 'Why are research studies important?',
    answer: `<p class="text-item">Research studies help:</p>
    <ul class="text-list">
      <li class="list-item">Scientists understand various diseases and expand medical knowledge that may aid in improving care</li>
      <li class="list-item">Investigators discover new treatments</li>
      <li class="list-item">Doctors make sure they are using existing treatments in the best possible ways</li>
      <li class="list-item">Healthcare professionals improve care or change the way they work</li>
    </ul>`
  },
  {
    question: 'What types of participants are needed?',
    answer: `<p class="text-item">T1D Exchange Registry is currently looking for participants</p>
    <ul class="text-list">
      <li class="list-item">Of all ages, genders, races, and ethnic groups</li>
      <li class="list-item">Living in the United States</li>
      <li class="list-item">Diagnosed with type 1 diabetes</li>
      <li class="list-item">Currently taking insulin or have had a pancreatic or islet cell transplant</li>
      <p class="text-item">
        When additional research opportunities open, they will be presented to you. They may have rules about who can — or cannot
        — participate, this is called “eligibility.” Your eligibility may be based on your age, gender, overall health, type and stage of
        a disease, treatment history, and other conditions.
      </p>
      <p class="text-item">
        Not everyone will be chosen to participate. For example, you may not qualify simply because a study is designed
        to focus on teenagers transitioning to adulthood and you are over the age of 65.
      </p>
    </ul>`
  },
  {
    question: 'What happens once I join?',
    answer: `<p class="text-item">
      After creating an online account, we will ask you to complete the following steps to fully enroll in the T1D Exchange Registry:
    </p>
    <ul class="text-list">
      <li class="list-item">Complete a few screening questions to decide your eligibility</li>
      <li class="list-item">Read and electronically sign the informed consent form</li>
      <li class="list-item">Complete the initial questionnaire and a follow up annually later</li>
    </ul>`
  },
  {
    question: 'What about other research opportunities?',
    answer: `<p class="text-item">We may also send you additional opportunities to participate in research (if you choose) such as:</p>
    <ul class="text-list">
      <li class="list-item">Uploading data from a continuous glucose monitor</li>
      <li class="list-item">Connecting your electronic medical record to the Registry</li>
      <li class="list-item">Participating in other internet-based research studies</li>
      <li class="list-item">Providing blood or other biological samples for researchers</li>
      <li class="list-item">Completing opinion surveys</li>
      <li class="list-item">Receiving information about research studies for which you may qualify</li>
    </ul>
    <p class="text-item">
      You always have the option to NOT participate in any additional research opportunities and still participate
      in the T1D Exchange Registry.
    </p>`
  },
  {
    question: 'What are the risks?',
    answer: `
    <p class="text-item">
      Your participation is completely voluntary and confidential. Before you enroll, our study team must make sure you understand
      all the possible risks, benefits, and alternatives to the study in a process called “informed consent”—or “informed assent”
      if you are between the ages of 13–17. This process allows you to review risks, data safety measures, study goals, processes,
      or any alternatives, while allowing time for any questions. You cannot participate in the Registry without completing
      the informed consent and/or assent.
    </p>
    <p class="text-item">
      Remember, the “informed consent” or “informed assent” documents are not contracts. They are meant to protect you, not bind you.
      At ANY time for ANY reason, you may choose NOT to participate in any research study, including the main T1D Exchange Registry
      study itself. You may also leave the study, for any reason, after being enrolled.
    </p>`
  },
  {
    question: 'What about privacy?',
    answer: `
    <p class="text-item">
      All participant information is kept confidential. Participation is completely voluntary. We will give you contact information
      to reach the study staff at any time if you would like to stop. Opting out at any point will not affect your care.
    </p>`
  },
  {
    question: 'What about my data?',
    answer: `
    <p class="text-item">
      The Registry will collect information about you. This information includes your email address, date of birth,
      and information from your medical records. Your information will be kept in an encrypted database in an anonymous way.
      This means in place of your name, you will be issued a randomly generated identification number.
    </p>
    <p class="text-item">
      Study results with your anonymous information may be shared in medical journals and at scientific meetings.
      However, your records themselves will be confidential. No one will share your identity in a medical journal
      or at a scientific meeting.
    </p>
    <p class="text-item">
      Results from the study will not be sent to you, but updates about the Registry or summaries about the research being done
      with the data from the Registry may be sent to you.
    </p>`
  },
];
