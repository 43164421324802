import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';

import { Answers, isNonEmptyArray, isRealValue, trySafely } from '@tr-common';

import { MinimalStudyType, ParticipantDetails, Procedures } from '../../../models';
import { StudyQuestionRuleType } from '../../../models/rules';
import { StudyQuestions } from '../../../models/study-question';
import { AnsweredQuestion, areAllQuestionsHavingAnswers, filterQuestionsByRules, findFirstUnansweredQuestion } from '../../models';
import { SurveyState } from '../reducers';
import { projectorOfParentQuestionState, projectorOfProceduresWithAnswers } from './projectors';

export const connectionServerError = 'Connection or server error. Check your connection or try to connect later';
export const studySurveyProperty = 'study-survey';
export const selectSurveyStore = createFeatureSelector<SurveyState>(studySurveyProperty);
export const selectParamsIDs = createSelector(selectSurveyStore, ({paramsIDs}) => paramsIDs);
export const selectStudy = createSelector(selectSurveyStore, ({study}): MinimalStudyType => study);
export const selectUserStudy = createSelector(selectSurveyStore, ({userStudy}) => userStudy);
export const selectIsEligible = createSelector(selectUserStudy, userStudy => userStudy?.is_eligible);
// TODO public and admin uses different endpoints for profile
export const selectParticipantDetails = createSelector(
  selectSurveyStore, (s: SurveyState): ParticipantDetails => get(s, ['participant'])
);
export const selectFullStudyLoading = createSelector(selectSurveyStore, ({fullStudyLoading}) => fullStudyLoading);
export const selectPending = createSelector(selectSurveyStore, ({pending}) => pending);
export const selectSubmitPending = createSelector(selectSurveyStore, ({submitPending}) => submitPending);
export const selectRefreshPending = createSelector(selectSurveyStore, ({refreshPending}) => refreshPending);
export const selectProcedures = createSelector(selectSurveyStore, ({procedures}) => procedures);
export const selectScreeningProcedure = createSelector(
  selectProcedures,
  (procedures: Procedures) => isNonEmptyArray(procedures) ? procedures.find(p => p.id === 'screening') : null
);
export const selectQuestions = createSelector(selectSurveyStore, s => get(s, ['questions']));
export const selectQuestionRules = createSelector(selectSurveyStore, s => get(s, ['rules']));
export const selectOptionQuestionMap = createSelector(selectSurveyStore, s => get(s, ['optionSubQuestionMap']));
export const selectAnswers = createSelector(selectSurveyStore, ({answers}) => answers);
export const selectUserProcedures = createSelector(selectSurveyStore, ({userProcedures}) => userProcedures);
export const selectProceduresWithAnswers = createSelector(
  selectQuestions, selectAnswers, projectorOfProceduresWithAnswers
);
export const selectActiveQuestionID = createSelector(selectSurveyStore, s => get(s, ['activeQuestionID']));
export const selectActiveProcedureID = createSelector(selectSurveyStore, s => get(s, ['activeProcedureID']));
export const selectActiveProcedure = createSelector(selectProcedures, selectActiveProcedureID,
  (all, id) => isRealValue(all) ? all.find(c => isRealValue(id) && c.id === id) : null
);
export const selectNextActiveProcedure = createSelector(selectProcedures, selectActiveProcedureID, (procedures, id) => {
  const currentIdx = isRealValue(procedures) ? procedures.findIndex(c => isRealValue(id) && c.id === id) : -1;

  return (currentIdx >= 0 && currentIdx + 1 < procedures.length) ? procedures[currentIdx + 1] : null;
});
export const selectActiveProcedureQuestions = createSelector(
  selectQuestions, selectActiveProcedureID, (all, id) => isRealValue(all) ? all[id] : []
);
export const selectActiveProcedureFilteredQuestionsByRules = createSelector(
  selectActiveProcedureQuestions, selectAnswers, selectQuestionRules, selectParticipantDetails, (
    questions: StudyQuestions, answers: Answers, rules: StudyQuestionRuleType[], participant: ParticipantDetails
  ) => filterQuestionsByRules(questions, answers, rules, participant)
);
export const selectActiveProcedureParentFilteredQuestionsByRules = createSelector(
  selectActiveProcedureFilteredQuestionsByRules, questions => questions.filter(question => !question.hasParent)
);
export const selectNumberOfCurrentQuestion = createSelector(selectActiveProcedureParentFilteredQuestionsByRules, selectActiveQuestionID, (
  activeQuestions: AnsweredQuestion[], id: string
) => activeQuestions.findIndex((question: AnsweredQuestion) => question.id === id) + 1);
/**
 * simplified implementation, assume (sub)answer validation during save - next
 */
export const selectCanSubmitActiveProcedure = createSelector(
  selectActiveProcedureFilteredQuestionsByRules, questions => trySafely(questions, areAllQuestionsHavingAnswers)
);
export const findUnansweredParentQuestionId = createSelector(
  selectActiveProcedureParentFilteredQuestionsByRules, questions => findFirstUnansweredQuestion(questions)
);
export const selectActiveQuestion = createSelector(selectActiveProcedureParentFilteredQuestionsByRules, selectActiveQuestionID,
  (activeQuestions, id) => trySafely(activeQuestions, qs => qs.find(x => x.id === id))
);
export const selectActiveAnswerSaveError = createSelector(selectSurveyStore, (survey) => survey.answersSaveError);
export const selectParentQuestionState = createSelector(
  selectActiveProcedureParentFilteredQuestionsByRules, selectAnswers, selectOptionQuestionMap, selectParticipantDetails,
  selectActiveQuestionID, selectNumberOfCurrentQuestion, projectorOfParentQuestionState
);
export const selectIntro = createSelector(selectSurveyStore, s => get(s, ['intro']));
export const selectModuleSettings = createSelector(selectSurveyStore, ({moduleSettings}) => moduleSettings);
export const selectSubmittedAnswers = createSelector(selectSurveyStore, ({submittedAnswers}) => submittedAnswers);
export const selectBlockedNavigation = createSelector(selectSurveyStore, ({blockNavigation}) => blockNavigation);
export const selectIsDonated = createSelector(selectSurveyStore, ({isDonated}) => isDonated);
