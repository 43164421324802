import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { isNonEmptyString, isRealValue, SelectedWithText, ValidatorType } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption } from '../../../models/study-option';

@Component({
  selector: 'lib-base-checkbox-radio-with-text',
  template: '',
})
export class BaseCheckboxRadioWithTextComponent implements WidgetPrototype<boolean | string>, OnChanges, OnDestroy {
  @Input() option: StudyOption;
  @Input() value: boolean | string = false;
  @Output() modify = new EventEmitter<WidgetModify<boolean | string>>();
  @HostBinding('class.flex') noTitle = false;
  formControl = new UntypedFormControl('', {updateOn: 'blur'});
  data = new SelectedWithText();
  valid: ValidatorType = 'default';
  subscriptions = new Subscription();

  constructor() {
    this.subscriptions.add(
      this.formControl.valueChanges.subscribe(newValue => {
        this.data.text = newValue;
        this.valid = this.data.validState;
        this.emitState(SourceChange.user);
      })
    )
  }

  @HostBinding('class.invalid') get isValid() { return this.valid === 'INVALID'; }

  ngOnChanges({value}: SimpleChanges): void {
    if (isRealValue(value)) {
      const newValue: boolean | string = value.currentValue;

      if (isRealValue(newValue)) {
        this.data = new SelectedWithText(this.value);
        this.formControl.setValue(this.data.text, {emitEvent: false});
        this.valid = this.data.validState;
      } else if (!value.firstChange) {
        this.data.text = '';
        this.data.selected = false;
        this.valid = this.data.validState;
        this.formControl.setValue(this.data.text, {emitEvent: false});
      }
      if (this.data.selected) {
        this.formControl.enable({emitEvent: false});
      } else {
        this.formControl.disable({emitEvent: false});
      }
    }
    this.noTitle = !isNonEmptyString(this.option.title);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  emitState(source: SourceChangeType) {
    this.modify.emit({value: this.data.value, valid: this.data.validState, source});
  }

  onChange(checked: boolean): void {
    this.data.selected = checked;
    this.valid = this.data.validState;
    this.emitState(SourceChange.user);
  }
}
