import * as t from 'io-ts';

import { ValueOf } from './helpers';

/**
 * sync with {@link questionTypeSchema}
 */
export const questionAnswerLogic = {
  single: 'single_required',
  all: 'all_required',
  multiple: 'multiple_required',
  multiple_or_single_radio: 'multiple_options_or_single_radio',
  all_or_single_radio: 'all_numerical_or_single_radio',
  all_mixed_or_single_radio: 'all_mixed_or_single_radio',
  all_required: 'all_required',
  last_required: 'last_required',
} as const;
export type QuestionTypeEnum = ValueOf<typeof questionAnswerLogic>

/**
 * sync with {@link questionAnswerLogic}
 */
export const questionTypeSchema: t.Type<QuestionTypeEnum> = t.union([
  t.literal(questionAnswerLogic.single),
  t.literal(questionAnswerLogic.all),
  t.literal(questionAnswerLogic.multiple),
  t.literal(questionAnswerLogic.multiple_or_single_radio),
  t.literal(questionAnswerLogic.all_or_single_radio),
  t.literal(questionAnswerLogic.all_mixed_or_single_radio),
  t.literal(questionAnswerLogic.all_required),
  t.literal(questionAnswerLogic.last_required),
]);

export interface PreviewQuestion {
  order: number;
  title: string;
  question_id: string;
  parent_option_id: string;
  selected_options: PreviewSelectedOption[];
}

export interface PreviewSelectedOption {
  order: number;
  title: string;
  widget: string;
  option_id: string;
  answer_value: string;
  sub_questions?: PreviewQuestion[];
}

export interface PreviewProcedureAnswers {
  id: string;
  study_id: string;
  procedure: string;
  data: PreviewQuestion[];
  created_on: string;
}

export const formatJSON = (s: string): string => {
  let result;

  try {
    const obj = JSON.parse(s);
    if (Array.isArray(obj)) {
      result = obj.reduce((a, c) => a + `<li>${Object.keys(c).sort().map(p => p + ': ' + c[p]).join(', ')}</li>`, '')
      result = `<ul>${result}</ul>`
    } else {
      result = Object.keys(obj).map(p => p + ': ' + obj[p]).join(', ');
    }
  } catch (e) {
    console.warn({error: e, value: s});
    return s;
  }

  return result === '' ? s : result;
}
