import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NEVER, Observable, of, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { selectUrl } from '../store';

@Injectable({providedIn: 'root'})
export class SurveyUrlFactory {
  getStudyRootUrl = (): string => '/survey';
  getDashboardUrl = (): string => '/dashboard'; // static url
  getDashboardUrl$ = (): Observable<string> => of('/dashboard'); // reactive url
}

@Injectable({providedIn: 'root'})
export class SurveyUrlFactoryProxy {
  constructor(readonly proxyFactory: SurveyUrlFactory) {}
}

@Injectable({providedIn: 'root'})
export class SurveyIsolatedActions {
  readonly _actions$ = new Subject<Action>();
  readonly actions$: Observable<Action> = this._actions$.asObservable();

  constructor(
    actions: Actions,
    private store: Store<unknown>,
    private urls: SurveyUrlFactoryProxy,
  ) {
    actions.pipe(
      mergeMap(a => this.store.select(selectUrl).pipe(
        take(1),
        mergeMap(url => url?.startsWith(this.urls.proxyFactory.getStudyRootUrl()) ? of(a) : NEVER)
      ))
    ).subscribe(this._actions$);
  }
}
