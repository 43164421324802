import * as t from 'io-ts';

import {
  nullable,
  participantSourceSchema,
  participantStateSchema,
  participantStatusSchema,
  participantTransitionSchema,
  profileType,
  profileTypeSchema,
  voidable,
} from '@tr-common';

import { userHouseholdSchema } from './user-household';
import { authMethodsSchema } from './auth-method';

export const baseChildProfileSchema = t.type({
    child_first_name: nullable(t.string),
    child_last_name: nullable(t.string),
    child_email: nullable(t.string),
    child_phone: nullable(t.string),
}, 'baseChildProfileSchema');
export type BaseChildProfileType = t.TypeOf<typeof baseChildProfileSchema>;

export const extendedChildProfileSchema = t.intersection([
  baseChildProfileSchema,
  t.partial({
    email: t.string,
    phone: nullable(t.string)
  })
], 'extendedChildProfileSchema');
export type ExtendedChildProfileType = t.TypeOf<typeof extendedChildProfileSchema>;

export const baseParticipantSchema = t.type({
  first_name: t.string,
  last_name: t.string,
  email: t.string,
  phone: nullable(t.string)
}, 'baseParticipantSchema');
export type BaseParticipantType = t.TypeOf<typeof baseParticipantSchema>;

export const baseParticipantAqSchema = t.type({
  first_name: t.string,
  last_name: t.string,
  email: t.string,
}, 'baseParticipantAqSchema');
export type BaseParticipantAqType = t.TypeOf<typeof baseParticipantAqSchema>;

export const registerParticipantSchema = t.intersection([
  baseParticipantSchema,
  t.type({
    password: t.string,
    profile_type: nullable(profileTypeSchema),
  }),
  t.partial({
    child_first_name: t.string,
    child_last_name: t.string,
    recaptcha_token: t.string
  })
], 'registerParticipantSchema');
export type RegisterParticipant = t.TypeOf<typeof registerParticipantSchema>;

export const anonymousParticipantDetailsSchema = t.intersection([
  t.type({id: t.string}),
  t.intersection([
    t.type({
      state: participantStateSchema,
      account_status: participantStatusSchema,
      available_statuses: t.array(participantTransitionSchema),
    }),
    t.partial({
      source: voidable(participantSourceSchema),
      birthday: voidable(t.string),
      created_at: voidable(t.string),
      last_ip_address: voidable(t.string),
      last_login: voidable(t.string),
      is_eligible: voidable(t.boolean),
      age_years: voidable(t.number)
    })
  ]),
  t.union([
    t.type({profile_type: t.null}),
    t.type({profile_type: t.literal(profileType.adult)}),
    t.type({profile_type: t.literal(profileType.child)}),
  ])
], 'anonymousParticipantDetailsSchema');
export type AnonymousParticipantDetails = t.TypeOf<typeof anonymousParticipantDetailsSchema>;

export const childProfileSchema = t.intersection([
  baseChildProfileSchema,
  t.type({profile_type: t.literal(profileType.child)}),
]);
export type ChildProfile = t.TypeOf<typeof childProfileSchema>;

export const baseAdultParticipantProfileSchema = t.intersection([
  t.type({
    first_name: t.string,
    last_name: t.string,
    email: t.string,
    profile_type: nullable(profileTypeSchema),
  }),
  t.partial({
    phone: nullable(t.string),
    new_jwt_token: t.string // BE sends this field only in cases if email is changed
  })
]);

export const participantProfileSchema = t.intersection([
  baseAdultParticipantProfileSchema,
  t.partial(userHouseholdSchema.props),
], 'participantProfileSchema');
export type ParticipantProfile = t.TypeOf<typeof participantProfileSchema>;
export type ParticipantProfileType = t.TypeOf<typeof participantProfileSchema>;

export interface UserNotification {
  id: string,
  type: string,
  text: string,
}

export const participantOwnProfileSchema = t.intersection([
  participantProfileSchema,
  t.type({
    account_status: participantStatusSchema,
    available_statuses: t.array(participantTransitionSchema),
    birthday: nullable(t.string),
    age_years: nullable(t.number),
    id: t.string,
    is_eligible: nullable(t.boolean),
    last_ip_address: nullable(t.string),
    last_login: nullable(t.string),
    source: t.string,
    state: participantStateSchema,
    gender_identity: nullable(t.string),
  }),
  t.partial({
    created_at: nullable(t.string),
    updated_at: nullable(t.string),
    auth_methods: authMethodsSchema,
  })
], 'participantOwnProfileSchema');
export type ParticipantOwnProfile = t.TypeOf<typeof participantOwnProfileSchema>;
export interface TimeZone {
  [k: string]: number
}
