import { FormControl } from '@angular/forms';

import { isEmptyValue } from './helpers';

export const suppressCharactersForNumbers = ['-', '+', 'e'];

export const getValueByMultiplier = (control: FormControl<number>, multiplier = 1, isFloat = false) => {
  const increment = multiplier * (isFloat ? 0.1 : 1);
  const inputValue: number = isEmptyValue(control.value) ? 0 : control.value;
  let value = inputValue + increment;

  if (isFloat) {
    value = Math.round(value * 10) / 10;
  }

  return value;
};
