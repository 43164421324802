import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { pick } from 'lodash';

import {
  answerWidget,
  AnswerWidgetType,
  answerWidgetsSchema,
  isDateWidget,
  isNonEmptyString,
  isRealValue,
  nullable,
  studyOptionLimitsSchema,
  StudyOptionLimitsType,
  StudyUuid,
} from '@tr-common';

import { DateValidationEnum, dateValidationSchema } from './date-helper';
import { StudyQuestions } from './study-question';
import { studyOptionValidatorSchema } from './study-validator-schema';

export const studyOptionWithoutDropdownOptionsSchema = t.intersection([
  t.type({
    id: t.union([t.string, UUID, t.null]),
    widget: answerWidgetsSchema,
    title: nullable(t.string),
    option_validators: t.array(studyOptionValidatorSchema),
    order: t.number,
    metadata: nullable(t.array(nullable(t.string)))
  }),
  t.partial({
    question_ids: t.array(t.string),
    validator: dateValidationSchema,
    numeric_limits: nullable(studyOptionLimitsSchema),
    isLastOption: t.boolean,
    disabled: t.boolean,
    invalid: t.boolean,
  }),
], 'studyOptionWithoutDropdownOptionsSchema');

export const studyOptionSchema: t.Type<StudyOptionType> = t.recursion('StudyOptionType', () =>
  t.intersection([
    studyOptionWithoutDropdownOptionsSchema,
    t.partial({
      options: t.array(studyOptionSchema),
      matrixOptions: nullable(t.array(studyOptionSchema))
    }),
  ])
);

// export type AdminStudyOption
export type StudyOptionType = t.TypeOf<typeof studyOptionWithoutDropdownOptionsSchema> & {options?: StudyOptionType[]};

// @dynamic
export class StudyOption extends StudyUuid implements StudyOptionType {
  options?: StudyOptionType[]; // for dropdown widget only
  widget: AnswerWidgetType;
  metadata: string[] = null;
  question_ids?: Array<string>;
  _title = '';
  validator?: DateValidationEnum;
  option_validators: StudyOptionType['option_validators'] = [];
  numeric_limits?: StudyOptionLimitsType;
  isLastOption = false;
  title: string; // get/set is defined in constructor
  order = 1;
  subQuestions?: StudyQuestions;
  disabled = false;
  invalid = false;

  constructor(initData?: string | Partial<StudyOptionType>) {
    super(typeof initData === 'string'
      ? (Object.keys(answerWidget).includes(initData) ? undefined : initData)
      : initData?.id
    );
    // _title shouldn't be enumerated when use spreadsheet
    Object.defineProperty(this, '_title', {
      enumerable: false,
      writable: true,
      configurable: true,
    });

    Object.defineProperty(this, 'title', {
      enumerable: true,
      configurable: true,
      set(newOne: string) { this._title = newOne; },
      get(): string {
        return this._title;
      }
    });

    if (isNonEmptyString(initData)) {
      this.widget = answerWidget[initData];
    } else if (isRealValue(initData) && typeof initData === 'object') {
      const picked = pick(initData, [
        'widget', 'question_ids', 'validator', 'numeric_limits', 'title', 'options', 'option_validators', 'order', 'subQuestions',
        'disabled', 'invalid', 'metadata'
      ]);

      if ('options' in picked) {
        picked.options = picked.options.map(option => new StudyOption(option) as StudyOptionType);
      }
      Object.assign(this, picked);
      if (this.numeric_limits) {
        this.numeric_limits = {...this.numeric_limits}; // need deep clone in case of deep freeze of object
      }
    }
  }

  get hasWidget(): boolean { return isRealValue(this.widget); }

  get isFloat(): boolean {
    return this.hasWidget && (this.widget === answerWidget.float || this.widget === answerWidget.a1cDynamic);
  }

  get isManipulationWidget(): boolean {
    return this.hasWidget && (this.widget === answerWidget.date || this.widget === answerWidget.shortDate);
  }

  get isRadioWidget(): boolean {
    return this.hasWidget && [answerWidget.radio, answerWidget.radioWithText].some(v => v === this.widget);
  }

  get isDateWidget(): boolean { return this.hasWidget && isDateWidget(this.widget); }

  clone(overrides?: StudyOptionType) {
    return new StudyOption({...this, ...overrides});
  }
}
