import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialImportsModule } from '../material-imports.module';
import { ButtonSpinnerComponent } from './button-spinner.component';
import { ButtonSpinnerDirective } from './button-spinner.directive';

@NgModule({
  imports: [
    MaterialImportsModule,
    CommonModule,
  ],
  declarations: [
    ButtonSpinnerComponent,
    ButtonSpinnerDirective,
  ],
  exports: [
    ButtonSpinnerComponent,
    ButtonSpinnerDirective,
  ]
})
export class ButtonSpinnerModule {}
