import * as t from 'io-ts';

export enum userStatus {
  active = 'active',
  deactivated = 'deactivated',
  loginBlocked = 'login_blocked',
  withdrawn = 'withdrawn',
}

export const participantStatusSchema = t.union([
  t.literal(userStatus.active),
  t.literal(userStatus.deactivated),
  t.literal(userStatus.loginBlocked),
  t.literal(userStatus.withdrawn),
], 'participantStatusSchema');
export type ParticipantStatusType = t.TypeOf<typeof participantStatusSchema>;

export const participantTransitionSchema = t.union([
  t.literal(userStatus.active),
  t.literal(userStatus.deactivated),
  t.literal(userStatus.loginBlocked),
  t.literal(userStatus.withdrawn),
], 'participantTransitionSchema');
export type ParticipantTransition = t.TypeOf<typeof participantTransitionSchema>;

export const participantNewStatusSchema = t.type({
  status: participantStatusSchema
}, 'participantNewStatusSchema');
export type ParticipantNewStatusType = t.TypeOf<typeof participantNewStatusSchema>;
