import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';
import { UUID } from 'io-ts-types/UUID';

import { nullable, previewMockUserStudy } from '@tr-common';

import { MinimalStudyType, mrpStatusStringSchema, studyStateSchema, surveyTypeSchema } from '../../models';
import { date } from 'io-ts-types/date';

export const userStudySchema = t.type({
  completed_at: t.union([t.string, DateFromISOString, t.null]),
  current_status: mrpStatusStringSchema,
  dashboard_status: t.string,
  expires_in_days: nullable(t.number),
  has_intro_page: t.boolean,
  id: t.union([t.number, t.literal(previewMockUserStudy)]),
  is_eligible: nullable(t.boolean),
  procedures_number: t.number,
  status: t.number,
  study_approximate_complete_time: nullable(t.string),
  study_closed_at: t.union([t.string, DateFromISOString, t.null]),
  study_id: UUID,
  study_launch_on: t.union([date, t.string, t.null]),
  study_reward_on: t.boolean,
  study_reward_text: nullable(t.string),
  study_status: studyStateSchema,
  study_type: surveyTypeSchema,
  study_title: t.string,
  study_number_of_users_goal: nullable(t.number),
  viewed_at: nullable(DateFromISOString),
  updated_at: t.union([t.string, DateFromISOString, t.null]),
  is_donated: nullable(t.boolean),
}, 'userStudySchema');
export type UserStudyType = t.TypeOf<typeof userStudySchema>;

export const getMinimalStudyInfoFromUserStudy = (userStudy: UserStudyType): MinimalStudyType => ({
  approximate_complete_time: userStudy.study_approximate_complete_time,
  closed_at: userStudy.study_closed_at,
  id: userStudy.study_id,
  reward_on: userStudy.study_reward_on,
  reward_text: userStudy.study_reward_text,
  status: userStudy.study_status,
  study_type: userStudy.study_type,
  title: userStudy.study_title,
  launch_on: userStudy.study_launch_on,
  number_of_users_goal: userStudy.study_number_of_users_goal
});

export const donateAqSchema = t.string;

export type DonateAqType = t.TypeOf<typeof donateAqSchema>;