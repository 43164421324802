import { UntypedFormGroup } from '@angular/forms';

import { combineOr } from './combine-or';

export const needToShowErrorWhenDirty = (formGroup: UntypedFormGroup, field: string, method: string): boolean => {
  const formField = formGroup.get(field);

  return formField.dirty && formField.errors && formField.errors[method];
};

export const needToShowErrorWhenTouched = (formGroup: UntypedFormGroup, field: string, method: string): boolean => {
  const formField = formGroup.get(field);

  return formField.touched && formField.errors && formField.errors[method];
};
export const needToShowErrorWhenTouchedOrDirty = combineOr([needToShowErrorWhenDirty, needToShowErrorWhenTouched]);
