import { FormControl, FormGroup, Validators } from '@angular/forms';

import { countryCodeValidator, FormGroupProtoType } from '@tr-common';

import { matchFieldsWithLowerCase, matchOtherValidator } from './validate-pair';
import { BaseChildProfileType } from './user-profile';

export type ChildInfoWithConfirmType = BaseChildProfileType & {
  confirm_email: string;
};
export type ChildFormType = FormGroupProtoType<BaseChildProfileType>;
export type ChildWithConfirmFormType = FormGroupProtoType<ChildInfoWithConfirmType>;

const getChildFormData = (): ChildFormType => ({
  child_first_name: new FormControl<string>(null, Validators.required),
  child_last_name: new FormControl<string>(null, Validators.required),
  child_email: new FormControl<string>(null),
  child_phone: new FormControl<string>({value: null, disabled: true}, countryCodeValidator())
});
export const getChildForm = () => new FormGroup<ChildFormType>(getChildFormData());
export const getChildReconsentForm = () => new FormGroup<ChildWithConfirmFormType>({
  ...getChildFormData(),
  child_email: new FormControl<string>(null, Validators.compose([Validators.required, Validators.email])),
  confirm_email: new FormControl<string>(null, matchOtherValidator('child_email', matchFieldsWithLowerCase)),
});
