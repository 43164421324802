<ng-container *ngIf="activeProcedure$ | async as activeProcedure; else isLoading">
  <ng-container *ngIf="!activeProcedure.isSpecial; else isConsentOrIntroOrResearch">
    <ng-container *ngIf="activeQuestion$ | async as activeQuestion">
      <lib-question-list (question)="toQuestion($event)"></lib-question-list>

      <ng-container *ngIf="!activeQuestion.mobileLandscape else onlyPortraitMode">
        <lib-question-wrapper *ngIf="activeQuestion.activeQuestionId" [preview]="activeQuestion.previewMode" [absentAnswers]="absentAnswers"
          (back)="backToDashboardOrSubmit(activeQuestion)" (questions)="toQuestions()" (click)="clickOnWrapper()">

          <div question-or-consent>
            <lib-question #libQuestion [source]="activeQuestion" [answerSaveError]="answerSaveError$ | async" (answer)="onAnswer($event)">
            </lib-question>
          </div>

          <mat-toolbar *ngIf="activeQuestion.parentQuestion" class="toolbar" question-navigation>
            <mat-toolbar-row class="flex toolbar-row-first survey">
              <button class="survey-button" mat-flat-button color="secondary" (click)="toPreviousQuestion(activeQuestion)"
                [disabled]="activeQuestion.isFirstQuestion || activeQuestion.isPending">
                <span>Previous </span>
              </button>

              <span class="mobile-progress">{{ activeQuestion.numberOfCurrentQuestion }}/{{ activeQuestion.total }}</span>

              <span class="button-with-tooltip" [ngClass]="showTooltip$ | async">
                <button class="survey-button" mat-flat-button color="primary" [disabled]="activeQuestion.isPending"
                  (click)="toNextQuestion(activeQuestion, $event, libQuestion)">
                  {{ activeQuestion.nextButtonTitle }}
                </button>

                <lib-next-tooltip class="invalid-state" (close)="hideTooltip()"></lib-next-tooltip>
                <lib-next-tooltip class="confirmation-warning" [message]="activeQuestion.parentQuestion.confirmation_warning"
                  (close)="hideTooltip()">
                </lib-next-tooltip>
              </span>
            </mat-toolbar-row>
          </mat-toolbar>
        </lib-question-wrapper>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #isConsentOrIntroOrResearch>
    <ng-container *ngIf="activeProcedure.isConsent; else isIntroOrResearch">
      <lib-question-wrapper class="consent-page" [pending]="consentPending$" (back)="study.returnToDashboard()">
        <div question-or-consent>
          <lib-survey-consent></lib-survey-consent>
        </div>
      </lib-question-wrapper>
    </ng-container>
  </ng-template>

  <ng-template #isIntroOrResearch>
    <lib-survey-intro-wrapper *ngIf="activeProcedure.isIntro; else isResearch"></lib-survey-intro-wrapper>
  </ng-template>

  <ng-template #isResearch>
    <ng-container *ngIf="researchPage$ | async as researchPage; else isLoading">
      <lib-question-wrapper class="flex research-page" button="Back to Dashboard" (back)="study.returnToDashboard()">
        <div question-or-consent>
          <lib-survey-intro [page]="researchPage">
            <a *ngIf="!researchPage.studyIsBlocked" mat-flat-button next-button class="survey-button is-shadowed" color="primary"
              target="_blank" rel="noopener" [href]="getUrl(researchPage)" [disabled]="!researchPage.url"
              (click)="study.clickResearchProcedure(researchPage.id)">{{ researchPage.button }}
            </a>
            <!-- TODO We should consider to find a way to use the button above one time -->
            <a *ngIf="!researchPage.studyIsBlocked" mat-flat-button next-button-2 class="survey-button is-shadowed" color="primary"
              target="_blank" rel="noopener" [href]="getUrl(researchPage)" [disabled]="!researchPage.url"
              (click)="study.clickResearchProcedure(researchPage.id)">{{ researchPage.button }}
            </a>
          </lib-survey-intro>
        </div>
      </lib-question-wrapper>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #isLoading>
  <div class="flex align-center loading-page">Loading a page...</div>
</ng-template>

<ng-template #onlyPortraitMode>
  <div class="center">The Registry platform does not support questionnaires in landscape mode.</div>
</ng-template>
