import * as t from 'io-ts';

import { procedureTemplateTypesSchema } from './procedure-template-type';

export const procedureLookupSchema = t.type({
  procedure: procedureTemplateTypesSchema,
  title: t.string,
  description: t.union([t.string, t.array(t.string)])
}, 'procedureLookupSchema');
export type ProcedureLookupType = t.TypeOf<typeof procedureLookupSchema>;
export type ProcedureLookupsType = ProcedureLookupType[];
