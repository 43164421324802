import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { AppState } from '@app/model';
import { UserState } from '@store/reducers';
import { logout } from '@store/actions';
import { selectUser } from '@store/selectors/user.selectors';

@Component({
  selector: 'app-authenticated-header',
  templateUrl: './authenticated-header.component.html',
  styleUrls: ['./authenticated-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthenticatedHeaderComponent {
  @Input() showContactInfo: boolean;
  @Input() hideUserProfile: boolean;
  user$: Observable<UserState> = this.store.select(selectUser).pipe(startWith(undefined as UserState));

  constructor(private store: Store<AppState>) {}

  onLogout(): void {
    this.store.dispatch(logout({}));
  }
}
