import { v4 as uuid } from 'uuid';

import { isNullOrUndefined } from './helpers';
import { AppComponentStorageType, SessionStorage, storageKeys } from './storage';

export const fillStorage = (storage: SessionStorage<AppComponentStorageType>, part = 'public'): void => {
  const sessionId = storage.getItem(storageKeys.response_id);

  if (isNullOrUndefined(sessionId)) {
    storage.setItem(storageKeys.response_id, uuid(4) as string);
  }
  storage.setItem(storageKeys.application, 'registry');
  storage.setItem(storageKeys.application_part, part);
}
