import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { SurveyIntroOrResearchPageType } from '../../models';

@Component({
  selector: 'lib-survey-intro',
  template: `
    <lib-study-is-closed-banner directionType="right"></lib-study-is-closed-banner>

    <div class="flex intro-header mbs-30">
      <div class="left-part">
        <div class="flex intro-title">
          <mat-icon svgIcon="sub-study"></mat-icon>
          <mat-icon svgIcon="research"></mat-icon>
          <h3 class="mbs-0 mbe-0">{{ page.title }}</h3>
        </div>

        <div class="flex intro-info">
          <lib-icon-time *ngIf="page.approximateTime" class="flex">{{ page.approximateTime }}</lib-icon-time>
          <lib-icon-time *ngIf="page.launchOn" mode="launchGreyOn" class="flex">{{ page.launchOn | date: 'mediumDate' }}</lib-icon-time>
          <lib-icon-time *ngIf="page.closedOn" mode="closedOn" class="flex">{{ page.closedOn | date: 'mediumDate' }}</lib-icon-time>
        </div>
      </div>

      <div *ngIf="!studyIsBlocked" class="right-part">
        <ng-content select=".survey-button[next-button]"></ng-content>
      </div>
    </div>
    <hr class="intro-title mbs-10 mbe-10"/>
    <h4 class="intro-title mbs-30">Study Details</h4>
    <div [innerHTML]="page.content | safeHtml"></div>

    <div *ngIf="page.numberOfUsers" class="flex study-enrollment-goal border-radius-30">
      <div class="flex left-part">
        <h3>Study Enrollment Goal</h3>
        <p>
          Help us reach our goal! By sharing your opinions, experiences, and data, you will help create the most comprehensive data set
          of people living with type 1 diabetes in the United States.
        </p>
      </div>

      <div class="right-part">
        <mat-icon svgIcon="mission"></mat-icon>
        <h1 class="mbs-10 mbe-10">{{ page.numberOfUsers }}</h1>
        <h4 class="mbs-0">Registry participants</h4>
        <span *ngIf="!studyIsBlocked">
          <ng-content select=".survey-button[next-button-2]"></ng-content>
        </span>
      </div>
    </div>
    <lib-study-is-closed-banner directionType="left"></lib-study-is-closed-banner>
  `,
  styleUrls: ['./survey-intro.component.scss']
})
export class SurveyIntroComponent implements OnInit {
  @Input() page: SurveyIntroOrResearchPageType;
  @HostBinding('class.blocked-study') studyIsBlocked = false;
  @HostBinding('class.research-opportunity') isResearchOpportunity = false;

  ngOnInit(): void {
    this.isResearchOpportunity = this.page.isResearchOpportunity;
    this.studyIsBlocked = this.page.studyIsBlocked;
  }
}
