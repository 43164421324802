import * as t from 'io-ts';

import { answerWidget } from '@tr-common';

export const widgetFieldSchema = t.union([
  t.literal('date'),
  t.literal('a1c'),
  t.literal('start'),
  t.literal('end'),
]);
export const widgetFieldArraySchema = t.array(widgetFieldSchema);
export type WidgetFieldsType = t.TypeOf<typeof widgetFieldArraySchema>;

export const comboWidgetSchema = t.union([
  t.literal(answerWidget.a1cDynamic),
  t.literal(answerWidget.dateRange)
]);
export type WidgetComboType = t.TypeOf<typeof comboWidgetSchema>;

export const isDateInComboType = ([id]: WidgetFieldsType): boolean => id !== 'a1c';
