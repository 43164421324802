import { DOCUMENT } from '@angular/common';
import { Injector } from '@angular/core';

import {
  ConsentService,
  GlobalRef,
  REGISTRY_ADMIN_URL,
  REGISTRY_API_URL,
  REGISTRY_APPLICATION,
  REGISTRY_PARTICIPANT_URL,
  REGISTRY_PUBLIC_PARTICIPANT,
  REGISTRY_PUBLIC_URL,
  REGISTRY_USER_FILES,
  SurveyPublicService,
  SurveyUrlFactory,
  SurveyUrlFactoryProxy,
} from 'tr-lib';

import { environment } from '@env/environment';

import { RegistryPublic } from './registry-public';

export const surveyServiceFactory = (injector: Injector, http) => new SurveyPublicService(
  injector.get(REGISTRY_APPLICATION),
  injector.get(REGISTRY_ADMIN_URL),
  injector.get(REGISTRY_PUBLIC_URL),
  injector.get(REGISTRY_PARTICIPANT_URL),
  injector.get(REGISTRY_API_URL),
  http
);

export const surveyUrlFactoryProxyFactory = (injector: Injector) => new SurveyUrlFactoryProxy(injector.get(SurveyUrlFactory));

export const consentServiceFactory = (injector: Injector, http) => new ConsentService(
  injector.get(DOCUMENT),
  injector.get(REGISTRY_PUBLIC_PARTICIPANT),
  injector.get(REGISTRY_USER_FILES),
  http
);

export const regGuardClientFactory = (
  ref: GlobalRef<RegistryPublic>
): string => ref.nativeGlobal.regGuardClientID ?? environment.regGuard.clientID;
export const t1dClientIdFactory = (ref: GlobalRef<RegistryPublic>): string => ref.nativeGlobal.t1dClientId ?? environment.t1dClientId;
export const t1dOAuthUrlFactory = (ref: GlobalRef<RegistryPublic>): string => ref.nativeGlobal.t1dOAuthUrl ?? environment.t1dOAuthUrl;
export const dexcomClientIdentifierFactory = (
  ref: GlobalRef<RegistryPublic>
): string => ref.nativeGlobal.dexcom?.dexcomAppClientId ?? environment.dexcom.clientId;
export const dexcomButtonOnFactory = (ref: GlobalRef<RegistryPublic>): boolean => ref.nativeGlobal.dexcom?.dexcomButtonOn === 'true';
export const alchemerDexcomClientIdentifierFactory = (
  ref: GlobalRef<RegistryPublic>
): string => ref.nativeGlobal.dexcom?.alchemerDexcomClientId ?? environment.alchemerDexcom.clientId;
export const alchemerDexcomModeFactory = (
  ref: GlobalRef<RegistryPublic>
): 'prod' | 'sandbox' => ref.nativeGlobal.dexcom?.alchemerDexcomMode ?? environment.alchemerDexcom.mode;
