import { flatten } from 'lodash';

import { AnswerWidgetType, isRealValue, OptionAnswerType } from '@tr-common';

import { FlatTreeNode, TreeNodeValueType } from '../../models';
import { isStudyOptionNodeWithValue } from './flat-tree-node.helpers';
import { getDraftSelectableAnswers } from './selectable-answer';
import { DraftAnswersType, DraftAnswerType, DraftValueType } from './draft-answer';

const createDraftAnswer = (
  question: string, question_option: string, value: DraftValueType, widget: AnswerWidgetType
): DraftAnswerType => ({question, question_option, value, widget});

const createDraftAnswerFromSelectable = <T = OptionAnswerType | OptionAnswerType[]>(
  node: FlatTreeNode<T>
): DraftAnswerType | DraftAnswersType => {
  const question = node.questionID;
  let outcome: DraftAnswerType | DraftAnswersType;

  if (node.value instanceof Array) {
    outcome = getDraftSelectableAnswers<DraftAnswerType>(node as FlatTreeNode<OptionAnswerType[]>);
  } else {
    const {question_option, value} = node.value as OptionAnswerType;

    outcome = {widget: node.getSelectableWidget(question_option), question, question_option, value};
  }

  return outcome;
};

const getDraftAnswerFromNode = <T = DraftAnswerType>(n: FlatTreeNode<TreeNodeValueType>): T | T[] => {
  let outcome: T | T[];

  if (isRealValue(n.matrixScaleOptions)) {
    const answers: unknown[] = JSON.parse(n.value as string);

    outcome = answers.filter(v => v !== false).map((v, idx) => createDraftAnswer(
      n.questionID, n.matrixScaleOptions[idx].id, v as string, n.matrixScaleOptions[idx].widget
    )) as T[];
  } else if (n.isSelectableNode && isRealValue(n.value)) {
    outcome = createDraftAnswerFromSelectable<OptionAnswerType | OptionAnswerType[]>(
      n as FlatTreeNode<OptionAnswerType | OptionAnswerType[]>
    ) as T[];
  } else {
    outcome = createDraftAnswer(n.questionID, n.answerOption, n.extractedValue, n.dataWidget) as T;
  }

  return outcome;
}

export const getAnswerValues = (dataNodes: FlatTreeNode[]): DraftAnswersType => flatten(
  dataNodes.filter(isStudyOptionNodeWithValue).map((n: FlatTreeNode) => getDraftAnswerFromNode<DraftAnswerType>(n))
);
