import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'lib-exit-confirmation-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  template: `
    <h3 mat-dialog-title>Confirmation</h3>

    <div mat-dialog-content>
      This page has unsaved changes. Your progress will be lost if you leave this page without saving.
    </div>

    <div mat-dialog-actions class="flex justify-center">
      <button mat-flat-button color="primary" [mat-dialog-close]="false">Cancel</button>
      <button mat-flat-button color="secondary" [mat-dialog-close]="true">Confirm</button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./exit-confirmation-dialog.component.scss']
})
export class ExitConfirmationDialogComponent {}
