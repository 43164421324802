import { ParamsIDs } from '../study-survey/models';
import { isNonEmptyArray, isNonEmptyString, isRealValue } from './helpers';
import { mockParticipantId } from './participant';

const studyIdReg = /study\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\/user/;
const userIdReg = new RegExp(`\/user\/(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}|${mockParticipantId})`);
const userStudyReg = /user-study\/(\d+)/;

export const getStudyIdFromUrl = (url: string): string => {
  const matchedID = url?.match(studyIdReg);

  return isRealValue(matchedID) ? matchedID[1] : '';
}

export const getParamsFromUrl = (url: string): ParamsIDs => {
  const matchedArray = [studyIdReg.exec(url), userIdReg.exec(url), userStudyReg.exec(url)];

  return isNonEmptyString(url) ? {
    studyID: isNonEmptyArray(matchedArray[0]) ? matchedArray[0][1] : null,
    userID: isNonEmptyArray(matchedArray[1]) ? matchedArray[1][1] : null,
    userStudyID: isNonEmptyArray(matchedArray[2]) ? matchedArray[2][1] : null
  } : null;
}
