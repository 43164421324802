import { createAction, props } from '@ngrx/store';

import { AllStates, BaseChildProfileType, ParticipantProfile, ServerValidationErrors, TimeZone, UserHouseholdType } from 'tr-lib';

import { ParticipantNotificationType, ParticipantNotificationUpdateType } from 'src/app/model';

export enum profileActions {
  GET_LIST_OF_USA = '[Profile] Get the list of USA',
  GET_LIST_OF_USA_SUCCESS = '[Profile] Got the list of USA success',
  GET_LIST_OF_USA_FAIL = '[Profile] Got the list of USA fail',
  GET_TIMEZONES = '[Profile] Get list of timezones',
  GET_TIMEZONES_SUCCESS = '[Profile] Get list of timezones success',
  GET_TIMEZONES_FAIL = '[Profile] Get list of timezones fail',
  GET_NOTIFICATIONS_ON = '[Profile] Get the participants notifications',
  TOGGLE_NOTIFICATION = '[Profile] Toggle the particular participants notification',
  GET_NOTIFICATIONS_ON_SUCCESS = '[Profile] Got the participants notifications success',
  GET_CHILD_INFO = '[Profile] Get child info',
  GET_CHILD_INFO_SUCCESS = '[Profile] Got child info success',
  GET_CHILD_INFO_FAIL = '[Profile] Got child info fail',
  UPDATE_CHILD_INFO = '[Profile] Update Child info',
  UPDATE_CHILD_INFO_SUCCESS = '[Profile] Updated Child info success',
  UPDATE_CHILD_INFO_FAIL = '[Profile] Updated Child info fail',
  UPDATE_PROFILE = '[Profile] Update Profile',
  UPDATE_PROFILE_SUCCESS = '[Profile] Update Profile Success',
  UPDATE_PROFILE_FAIL = '[Profile] Update Profile Fail',
  GET_HOUSEHOLD_INFO = '[Profile] Get household info',
  GET_HOUSEHOLD_INFO_SUCCESS = '[Profile] Got household info success',
  GET_HOUSEHOLD_INFO_FAIL = '[Profile] Got household info fail',
  UPDATE_HOUSEHOLD_INFO = '[Profile] Update household info',
  UPDATE_HOUSEHOLD_INFO_SUCCESS = '[Profile] Updated household info success',
  UPDATE_HOUSEHOLD_INFO_FAIL = '[Profile] Updated household info fail',
}

export const getListOfUSA = createAction(
  profileActions.GET_LIST_OF_USA
);

export const getListOfUSASuccess = createAction(
  profileActions.GET_LIST_OF_USA_SUCCESS,
  props<{payload: AllStates}>()
);

export const getListOfUSAFail = createAction(
  profileActions.GET_LIST_OF_USA_FAIL,
  props<{error: unknown}>()
);

export const getTimezones = createAction(
  profileActions.GET_TIMEZONES
);

export const getTimezonesSuccess = createAction(
  profileActions.GET_TIMEZONES_SUCCESS,
  props<{timeZones: TimeZone}>()
);

export const getTimezonesFail = createAction(
  profileActions.GET_TIMEZONES_FAIL,
  props<{error: unknown}>()
);

export const getNotificationsOn = createAction(
  profileActions.GET_NOTIFICATIONS_ON
);

export const toggleNotification = createAction(
  profileActions.TOGGLE_NOTIFICATION,
  props<{payload: ParticipantNotificationUpdateType}>()
);

export const getNotificationsOnSuccess = createAction(
  profileActions.GET_NOTIFICATIONS_ON_SUCCESS,
  props<{payload: ParticipantNotificationType}>()
);

export const getChildInfo = createAction(
  profileActions.GET_CHILD_INFO,
  props<{id: string}>()
);

export const getChildInfoSuccess = createAction(
  profileActions.GET_CHILD_INFO_SUCCESS,
  props<{payload: BaseChildProfileType}>()
);

export const getChildInfoFail = createAction(
  profileActions.GET_CHILD_INFO_FAIL,
  props<{error: unknown}>()
);

export const updateChildInfo = createAction(
  profileActions.UPDATE_CHILD_INFO,
  props<{payload: Partial<BaseChildProfileType>}>()
);

export const updateChildInfoSuccess = createAction(
  profileActions.UPDATE_CHILD_INFO_SUCCESS,
  props<{payload: BaseChildProfileType}>()
);

export const updateChildInfoFail = createAction(
  profileActions.UPDATE_CHILD_INFO_FAIL,
  props<{status: number, error: ServerValidationErrors<BaseChildProfileType>}>()
);

export const updateProfile = createAction(
  profileActions.UPDATE_PROFILE,
  props<{payload: Partial<ParticipantProfile>}>()
);

export const updateProfileSuccess = createAction(
  profileActions.UPDATE_PROFILE_SUCCESS,
  props<{payload: Partial<ParticipantProfile>}>()
);

export const updateProfileFail = createAction(
  profileActions.UPDATE_PROFILE_FAIL,
  props<{status: number, error: ServerValidationErrors<ParticipantProfile>}>()
);

export const getHouseholdInfo = createAction(
  profileActions.GET_HOUSEHOLD_INFO,
  props<{id: string}>()
);

export const getHouseholdInfoSuccess = createAction(
  profileActions.GET_HOUSEHOLD_INFO_SUCCESS,
  props<{payload: UserHouseholdType}>()
);

export const getHouseholdInfoFail = createAction(
  profileActions.GET_HOUSEHOLD_INFO_FAIL,
  props<{status: number, error: ServerValidationErrors<UserHouseholdType>}>()
);

export const updateHouseholdInfo = createAction(
  profileActions.UPDATE_HOUSEHOLD_INFO,
  props<{payload: UserHouseholdType}>()
);

export const updateHouseholdInfoSuccess = createAction(
  profileActions.UPDATE_HOUSEHOLD_INFO_SUCCESS,
  props<{response: UserHouseholdType}>()
);

export const updateHouseholdInfoFail = createAction(
  profileActions.UPDATE_HOUSEHOLD_INFO_FAIL,
  props<{status: number, error: ServerValidationErrors<UserHouseholdType>}>()
);
