import { isEmpty } from 'lodash';

import { StoredAnswersType, Answers, isNonEmptyArray, isNonEmptyString, isRealValue, ReviewOption } from '@tr-common';

import { getTitleFromOptionExceptManipulationWidget, ParticipantDetails } from '../../models';
import { StudyQuestionRule, StudyQuestionRuleType } from '../../models/rules';
import { StudyOption } from '../../models/study-option';
import { StudyQuestion } from '../../models/study-question';

export class AnsweredQuestion extends StudyQuestion {
  answers: {[option: string]: string} = null;
  questionRules: StudyQuestionRule[] = [];
  isRuleHideQuestion = true;
  hidden = false;
  reviewTitle: string;
  reviewOptions: ReviewOption[] = [];

  constructor(question: StudyQuestion, rules?: StudyQuestionRuleType[], answers?: StoredAnswersType) {
    super(question);
    this.reviewTitle = isRealValue(this.title) ? this.title : '';
    if (isNonEmptyArray(rules)) {
      this.questionRules = rules.map((rule: StudyQuestionRule) => new StudyQuestionRule(rule));
      this.isRuleHideQuestion = !rules.some((rule: StudyQuestionRule) => rule.rule_action === 'show');
    }
    if (isRealValue(answers)) {
      this.answers = {};
      answers.forEach(answer => this.answers[answer.question_option] = answer.value.toString());
    }
    this.createReviewOptions();
  }

  get completed() {
    return !isEmpty(this.answers);
  }

  get isRuleExisted(): boolean {
    return isNonEmptyArray(this.questionRules);
  }

  ifConditionsMet(participant: ParticipantDetails, allAnswers: Answers): boolean {
    return this.isRuleExisted && this.questionRules.some(questionRule => questionRule.ifValueMatchesConditions(participant, allAnswers));
  }

  needConfirmationWarning(notShowedYet = false): boolean {
    return isNonEmptyString(this.confirmation_warning) && notShowedYet;
  }

  private createReviewOptions(): void {
    const answerKeys = isRealValue(this.answers) ? Object.keys(this.answers) : [];

    answerKeys.filter(function(answerKey) {
      return this.indexOf(answerKey) >= 0;  // Here 'this' points to the second argument of the function filter()
    }, this.options.map(option => option.id)).forEach((key: string) => {
      const foundOption = this.options.find((option: StudyOption) => option.id === key);
      const title = getTitleFromOptionExceptManipulationWidget(foundOption);
      const delimiter = isNonEmptyString(title) ? ': ' : '';
      const value = this.answers[key] === 'true' ? '' : `${delimiter}${this.answers[key]}`;

      this.reviewOptions.push({title, value, mark: this.hasParent ? '' : 'A. ', style: ''});
    });
    this.reviewOptions.forEach((reviewOption: ReviewOption, idx: number) => {
      reviewOption.style = this.getOptionStyle(idx);
    });
  }

  private getOptionStyle(idx: number): string {
    const answerStyle = idx === 0 && this.reviewOptions.length === 1
      ? 'single-answer'
      : idx === 0
        ? 'first-answer'
        : this.reviewOptions.length - 1 === idx
          ? 'last-answer'
          : 'answer';

    return `${ this.hasParent ? 'sub-' : '' }${answerStyle}`;
  }
}
