/* eslint-disable @typescript-eslint/naming-convention */

export const iconStudyProgress = {
  Open: 'study-not-started',
  'Not started': 'study-not-started',
  'In progress': 'study-in-progress',
  Completed: 'study-completed',
  'Not viewed': 'study-not-viewed',
  Closed: 'study-not-viewed'
};
