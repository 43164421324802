import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { ModuleSettings } from '@tr-common';

@Component({
  selector: 'lib-confirmation-page',
  template: `
    <h5>{{ setting?.content?.title }}</h5>
    <div class="mbe-30" [innerHTML]="setting?.content?.body_text | safeHtml"></div>

    <div class="flex flex-wrap action-buttons">
      <ng-content></ng-content>

      <ng-container *ngIf="!pending && setting?.content?.review_answers_button">
        <button class="survey-button is-with-icon" mat-flat-button color="secondary" (click)="review.emit()">
          <mat-icon svgIcon="view-open"></mat-icon>
          <span>Review my answers</span>
        </button>
      </ng-container>
    </div>
  `,
  styleUrls: ['./confirmation-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationPageComponent {
  @Input() setting: ModuleSettings;
  @Input() pending = false;
  @Output() review = new EventEmitter();
}
