import * as t from 'io-ts';

import { nullable } from '@tr-common';

import { studyConditionSchema } from '../study-condition-schema';
import { ruleActionSchema, RuleActionType } from './rule-action';
import { StudyBaseRule } from './study-base-rules';
import { StudyCondition } from './study-condition';

export const studyQuestionRuleSchema = t.intersection([
  t.type({
    description: nullable(t.string),
    conditions: t.array(studyConditionSchema),
    question: t.string,
    rule_action: ruleActionSchema,
  }),
  t.partial({
    id: t.string
  })
], 'studyQuestionRuleSchema');
export type StudyQuestionRuleType = t.TypeOf<typeof studyQuestionRuleSchema>;

export class StudyQuestionRule extends StudyBaseRule<StudyQuestionRule> implements StudyQuestionRuleType {
  description = '';
  question = '';
  rule_action: RuleActionType = 'hide'; // By default any applied rule hides a question
  conditions: StudyCondition[];

  constructor(data?: Partial<StudyQuestionRule>) {
    super();
    super.setData(data);
  }

  clone(): StudyQuestionRule {
    return new StudyQuestionRule(this);
  }
}
