import * as t from 'io-ts';

import { nullable } from './helpers';

export enum Events {
  studyBegin = 'fe_study_begin',
  jaebIntroDialog = 'fe_jaeb_dashboard_intro', // jaeb intro dialog
  beforeScreening = 'fe_popup_before_screening',
  beforeConsent = 'fe_popup_before_consent',
  beforeQuestionnaire = 'fe_popup_before_questionnaire',
  afterScreening = 'fe_popup_after_screening',
  afterConsent = 'fe_popup_after_consent',
  afterQuestionnaire = 'fe_popup_after_questionnaire'
}

export const eventBeforePopupProcedureSchema = t.union([
  t.literal(Events.beforeScreening),
  t.literal(Events.beforeConsent),
  t.literal(Events.beforeQuestionnaire),
]);
export type EventBeforePopupProcedureType = t.TypeOf<typeof eventBeforePopupProcedureSchema>;

export const eventAfterPopupProcedureSchema = t.union([
  t.literal(Events.afterScreening),
  t.literal(Events.afterConsent),
  t.literal(Events.afterQuestionnaire),
]);
export type EventAfterPopupProcedureType = t.TypeOf<typeof eventBeforePopupProcedureSchema>;

export const eventsSchema = t.union([
  t.number, // it will be deprecated after upcoming migration which converts numbers to `fe_popup_before_<procedure>`
  t.literal(Events.studyBegin),
  t.literal(Events.jaebIntroDialog),
  eventBeforePopupProcedureSchema,
  eventAfterPopupProcedureSchema
], 'eventsSchema');
export type EventsType = t.TypeOf<typeof eventsSchema>;

export const baseEventSchema = t.intersection([
  t.type({
    event: eventsSchema,
  }),
  t.partial({
    study: nullable(t.string),
    user_study: nullable(t.number),
  })
], 'baseEventSchema');
export type BaseEventType = t.TypeOf<typeof baseEventSchema>;
