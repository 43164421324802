import { answerWidget, AnswerWidgetType, OptionAnswerType } from '@tr-common';

import { FlatTreeNode } from '../../models';
import { DraftAnswerType } from './draft-answer';

export interface MiddleOptionAnswer {
  optionID: string;
  value: string | string[];
  widget: AnswerWidgetType;
}
export type MiddleOptionAnswersType = MiddleOptionAnswer[];

export const getDraftSelectableAnswers = <T = DraftAnswerType>(n: FlatTreeNode<OptionAnswerType[]>): T[] => {
  const sourceValue = n.value;
  let outcome: T[] = [];

  if (sourceValue instanceof Array) {
    const unitedValue: MiddleOptionAnswersType = sourceValue.reduce((acc, {question_option, value}: OptionAnswerType) => {
      const foundIdx = acc.findIndex(({optionID}) => optionID === question_option);
      const justUpgradeAcc = foundIdx >= 0;
      const strValue = value.toString();
      let middleValue: MiddleOptionAnswer;

      if (foundIdx >= 0) {
        const accValue = acc[foundIdx].value;

        acc[foundIdx].value = accValue instanceof Array ? [...accValue, strValue] : [accValue, strValue]
      } else {
        const widget = n.getSelectableWidget(question_option);

        middleValue = {widget, optionID: question_option, value: widget === answerWidget.multiselectText ? [strValue] : strValue};
      }

      return justUpgradeAcc ? acc : [...acc, middleValue];
    }, [] as MiddleOptionAnswersType);

    outcome = unitedValue.map(uValue => {
      const {widget, optionID, value} = uValue

      return {widget, question: n.questionID, question_option: optionID, value} as T;
    });
  }

  return outcome;
}
