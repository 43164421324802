import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { get } from 'lodash';

@Component({
  selector: 'lib-unanswered-question-dialog',
  template: `
    <div mat-dialog-title>
      <div class="close-icon" [mat-dialog-close]="null"><i class="material-icons">clear</i></div>
    </div>

    <div class="tr-unanswered-question-modal" mat-dialog-content>
      <mat-icon svgIcon="alert"></mat-icon>
      <h3>Almost there!</h3>
      <p>{{ message }}</p>
    </div>

    <div mat-dialog-actions>
      <button class="dialog-button" mat-flat-button color="primary" [mat-dialog-close]="true">Go to question</button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
  styleUrls: ['./unanswered-question-dialog.component.scss'],
})
export class UnansweredQuestionDialogComponent implements OnInit {
  message = `You have a few unanswered questions left.
   To complete the questionnaire, please click on the “Go to question” button to answer the remaining questions.`;

  constructor(@Inject(MAT_DIALOG_DATA) private data: {message: string}) {}

  ngOnInit(): void {
    this.message = get(this.data, 'message', this.message);
  }
}
