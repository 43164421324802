import { get } from 'lodash';

import { isRealValue } from 'tr-lib';

import { Achievement, AchievementCode } from '@app/dashboard/models';

export interface AchievementAction {
  actions: {
    label: 'Download' | 'Review',
    type: 'Download' | 'Review',
    navigateArgs?: [string[], {queryParams: {procedure: AchievementCode}}?]
  }[]
}

export type AchievementActionsType = {
  [k in AchievementCode]?: AchievementAction
};

const surveyStudyPrefix = 'survey/study/:studyId/user';
export const achievementActions: AchievementActionsType = {
  screening: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}-study/:userStudyId/user/:userId/review-answers`], {
        queryParams: {procedure: 'screening'}
      }]
    }]
  },
  consent_adult: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}/:userId/signedConsent/:signed_consent_id`]]
    }]
  },
  consent_parental: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}/:userId/signedConsent/:signed_consent_id`]]
    }]
  },
  consent_child: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}/:userId/signedConsent/:signed_consent_id`]]
    }]
  },
  consent_universal: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}/:userId/signedConsent/:signed_consent_id`]]
    }]
  },
  questionnaire: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}-study/:userStudyId/user/:userId/review-answers`], {
        queryParams: {procedure: 'questionnaire'}
      }]
    }]
  },
  annual_questionnaire: {
    actions: [{
      label: 'Download',
      type: 'Download'
    }, {
      label: 'Review',
      type: 'Review',
      navigateArgs: [[`${surveyStudyPrefix}-study/:userStudyId/user/:userId/review-answers`], {
        queryParams: {procedure: 'questionnaire'}
      }]
    }]
  }
};

export const defineAction = (a: Achievement, userId) => {
  const stringifyActions = JSON.stringify(achievementActions[a.code]);
  const parametrisedActions = isRealValue(stringifyActions) ? JSON.parse(stringifyActions
    .replace(/:userId/g, userId)
    .replace(/:userStudyId/g, '' + a.user_study_id)
    .replace(/:signed_consent_id/g, get(a, ['payload', 'signed_consent_id'], ''))
    .replace(/:studyId/g, a.study_id)) : undefined;

  return {...a, ...((a.code in achievementActions) ? parametrisedActions : {})};
};
