import { Pipe, PipeTransform } from '@angular/core';

import { Dictionary, isRealValue, sourceTextReplace } from '@tr-common';

@Pipe({name: 'textReplace'})
export class TextReplacePipe implements PipeTransform {
  private sourceText = sourceTextReplace;

  getSourceData(source: string): Dictionary {
    return isRealValue(source) ? this.sourceText[source] : this.sourceText;
  }

  getParsedResult([target, ...keys]: string[], data: Dictionary): Dictionary | null {
    return !isRealValue(data)
      ? null
      : !isRealValue(target) ? data : this.getParsedResult(keys, Array.isArray(data[target]) ? data[target][0] : data[target]);
  }

  transform(value: string, ...args): string | Dictionary {
    const data = this.getSourceData(args[0]);
    const curVal = this.getParsedResult(value.split('.'), data);

    return isRealValue(curVal) ? curVal : value;
  }
}
