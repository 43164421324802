import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { ModuleContent } from '@tr-common';

import { Consent } from '../../models';

@Component({
  selector: 'lib-submitted-consent',
  template: `
    <div class="tr-consent-signed">
      <h3>{{content?.title}}</h3>
      <div [innerHTML]="content?.body_text | safeHtml"></div>

      <ng-container *ngIf="consentFiles.length; else progressbar">
        <lib-consent-files [data]="consentFiles" [isDownloadBtn]="content?.download_button" [isPrintBtn]="content?.print_button"
          [isReviewBtn]="content?.review_answers_button" [isTimeStamp]="content?.show_timestamp"
          (reviewConsent)="review.emit($event)" (downloadConsent)="download.emit($event)" (printConsent)="print.emit($event)"
        ></lib-consent-files>

        <div class="actions">
          <ng-content select=".actions-content"></ng-content>
        </div>
      </ng-container>

      <ng-template #progressbar>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
    </div>
  `,
  styleUrls: ['./submitted-consent.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmittedConsentComponent {
  @Input() title = 'We have your consent.';
  @Input() consentFiles: Consent[];
  @Input() content: ModuleContent;
  @Output() review = new EventEmitter<string>();
  @Output() download = new EventEmitter<Consent>();
  @Output() print = new EventEmitter<string>();
}
