import { isEmptyValue, isNonEmptyArray } from '@tr-common';

import { FlatTreeNode, isStudyOption, TreeNode } from '../../models';

export const treeNode2FlatTreeNode =  (node: TreeNode, level: number): FlatTreeNode => new FlatTreeNode({
  ...node, level, expandable: isNonEmptyArray(node.children)
});

export const getFlatTreeNodeLevel = ({level}: FlatTreeNode): number => level;

export const isFlatTreeNodeExpandable = ({expandable}: FlatTreeNode): boolean => expandable;

export const isStudyOptionNodeWithValue = (node: FlatTreeNode): boolean => node.hasToHaveValue && !isEmptyValue(node.value);

export const getParentQuestionID = (
  dataNodes: FlatTreeNode[]
): string[] => dataNodes.filter(n => n.isQuestion && n.level === 0).map(n => n.questionID);

export const markQuestionsAndOptions = (dataNodes: FlatTreeNode[], questionIDs: string[]): void => {
  for (const dataNode of dataNodes) {
    dataNode.showHint = questionIDs.some((id: string) => id === dataNode.data?.id || dataNode.parent?.data.id === id);
  }
}

export const hasTheSameLevelRadio = (dataNodes: FlatTreeNode[], node: FlatTreeNode): boolean => {
  const isLevelOption = (dn: FlatTreeNode, n: FlatTreeNode): boolean => dn.questionID === n.questionID && dn.data?.id !== n.data?.id;
  const onlyOptions = dataNodes.filter(n => !n.isQuestion && isLevelOption(n, node));

  return onlyOptions.some(({data}) => isStudyOption(data) && data.isRadioWidget);
}
