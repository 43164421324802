import { createAction, props } from '@ngrx/store';
import { Params } from '@angular/router';

import { RegisterParticipant } from 'tr-lib';

import { SourcesToComponentType } from '@app/model/constants';
import { LogoutType } from '@app/model/user.helpers';
import { LoginData } from '@app/auth/models';
import { DetailsError, RegisterError } from '@app/model';

export enum authActions {
  LOGIN = '[Auth] Log In',
  LOGIN_SUCCESS = '[Auth] Log In Success',
  LOGIN_FORBIDDEN = '[Auth] Log In Forbidden',
  LOGIN_WITH_DUAL_FA = '[Auth] Login with Dual FA',
  LOGIN_WITH_T1D_EXCHANGE = '[Auth] Sign in with T1DExchange credentials',
  SMART_EMAIL_CHECK = '[Auth] Check email',
  SMART_EMAIL_CHECK_COINCIDENT = '[Auth] Checked email is coincident with confirmed',
  SMART_EMAIL_CHECK_SUCCESS = '[Auth] Checked email success',
  SMART_EMAIL_CHECK_FAIL = '[Auth] Checked email fail',
  REGISTER_PARTICIPANT = '[Auth] Register a new participant',
  REGISTER_JAEB = '[Auth] Register a new participant from JAEB platform',
  REGISTER_SUCCESS = '[Auth] Register Success',
  REGISTER_REJECT = '[Auth] Imperium rejected the participant\'s registration',
  LOG_OUT = '[Auth] Log Out',
  SUBMIT_DUAL_FACTOR_CODE = '[Auth] Submit Dual Factor Code',
  SUBMIT_DUAL_FACTOR_CODE_SUCCESS = '[Auth] Submit Dual Factor Code Success',
  SUBMIT_DUAL_FACTOR_CODE_FAIL = '[Auth] Submit Dual Factor Code Fail',
  GET_TOKEN_FROM_T1D = '[Auth] Try to get a token from T1D',
  GET_TOKEN_FROM_T1D_SUCCESS = '[Auth] Got the token from T1D success',
  RESET_DUAL_FACTOR_ERROR = '[Auth] Reset Dual Factor Error',
  CLEAN_STORE = '[Auth] Clean all the store during logout'
}

export const login = createAction(
  authActions.LOGIN,
  props<{loginData: LoginData, params?: {register: boolean}}>()
);

export const loginWithDualFA = createAction(
  authActions.LOGIN_WITH_DUAL_FA,
  props<{params: Params, otpId: string}>()
);

export const loginSuccess = createAction(
  authActions.LOGIN_SUCCESS,
  props<{payload: string, source: SourcesToComponentType}>()
);

export const loginForbidden = createAction(
  authActions.LOGIN_FORBIDDEN,
);

export const loginWithT1DExchange = createAction(
  authActions.LOGIN_WITH_T1D_EXCHANGE
);

export const smartEmailCheck = createAction(
  authActions.SMART_EMAIL_CHECK,
  props<{payload: string}>()
);

export const smartEmailCheckCoincident = createAction(
  authActions.SMART_EMAIL_CHECK_COINCIDENT,
  props<{payload: boolean}>()
);

export const smartEmailCheckSuccess = createAction(
  authActions.SMART_EMAIL_CHECK_SUCCESS,
  props<{message: string}>()
);

export const smartEmailCheckFail = createAction(
  authActions.SMART_EMAIL_CHECK_FAIL,
  props<{message: string}>()
);

export const registerParticipant = createAction(
  authActions.REGISTER_PARTICIPANT,
  props<{userData: RegisterParticipant, regGuardInfo: unknown}>()
);

export const registerJaeb = createAction(
  authActions.REGISTER_JAEB,
  props<{userData: RegisterParticipant, id: string}>()
);

export const registerSuccess = createAction(
  authActions.REGISTER_SUCCESS,
  props<{loginData: LoginData; id: string, accessToken: string, isJaeb: boolean}>()
);

export const registerReject = createAction(
  authActions.REGISTER_REJECT,
  props<{reason: string}>()
);

export const logout = createAction(
  authActions.LOG_OUT,
  props<{payload?: LogoutType}>()
);

export const submitDualFactorCode = createAction(
  authActions.SUBMIT_DUAL_FACTOR_CODE,
  props<{payload: any}>()
);

export const submitDualFactorCodeSuccess = createAction(
  authActions.SUBMIT_DUAL_FACTOR_CODE_SUCCESS,
  props<{payload: string}>()
);

export const submitDualFactorCodeFail = createAction(
  authActions.SUBMIT_DUAL_FACTOR_CODE_FAIL,
  props<{error: unknown}>()
);

export const resetDualFactorError = createAction(
  authActions.RESET_DUAL_FACTOR_ERROR
);

export const getTokenFromT1D = createAction(
  authActions.GET_TOKEN_FROM_T1D,
  props<{code: string, jump: string}>()
);

export const getTokenFromT1DSuccess = createAction(
  authActions.GET_TOKEN_FROM_T1D_SUCCESS,
  props<{token: string, jump: string}>()
);

export const cleanStore = createAction(
  authActions.CLEAN_STORE,
  props<{withToken?: boolean}>()
)
