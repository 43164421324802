import { isNonEmptyString, isRealValue } from '@tr-common';

import { StudyOption } from './study-option';

export const getTitleFromOptionExceptManipulationWidget = (option: StudyOption): string => {
  let title = '';

  if (isRealValue(option) && isNonEmptyString(option.title) && !option.isManipulationWidget) {
    title = option.title;
  }

  return title;
};
