import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { Observable, pipe, UnaryFunction } from 'rxjs';

import { filterRealValues } from 'tr-lib';

import { getParticipantProfile } from '@app/model/user.helpers';
import { UserState } from '@store/reducers';
import { AppState } from '@app/model';

export const selectUser = createFeatureSelector<UserState>('user');
export const selectUserId = createSelector(selectUser, ({id}: UserState) => id);
export const selectParticipantPhone = createSelector(selectUser, ({phone}) => phone);
export const selectReconsentStep1Finished = createSelector(selectUser, ({reconsentStep1Finished}: UserState) => reconsentStep1Finished);
export const selectPersonalInfo = createSelector(
  selectUser, user => getParticipantProfile(user)
);
export const selectNotificationsOn = createSelector(selectUser, ({notifications}: UserState) => notifications);
export const selectUserProfileType = createSelector(selectUser, ({profile_type}: UserState) => profile_type);
export const selectBannersVisibility = createSelector(selectUser, user => Boolean(user) && user.bannersVisibility);
export const selectProfileSource = createSelector(selectUser, ({source}) => source);
export const selectProfileLoginMethods = createSelector(selectUser, ({auth_methods}) => auth_methods);
export const selectIsOAuthMethodPresented = createSelector(selectProfileLoginMethods, auth_methods => auth_methods.includes('oauth'));
export const selectChangePasswordSuccess = createSelector(selectUser, ({changePasswordInProfileSuccess}) => changePasswordInProfileSuccess);

export const selectRealUserId = (): UnaryFunction<Observable<AppState>, Observable<string>> => pipe(
  select(selectUserId), filterRealValues()
);
