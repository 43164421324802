import { pick } from 'lodash';
import { Moment } from 'moment';

import { serverDateFormat } from '@tr-common';

import { StudyOptionCalcDate } from '../../models';

export const getCorrectVal = (val: StudyOptionCalcDate, tick?: () => Moment): StudyOptionCalcDate => {
  const isRightInstance = val instanceof StudyOptionCalcDate;
  const correctVal = isRightInstance ? val : new StudyOptionCalcDate(val, serverDateFormat, tick);

  if (!isRightInstance) {
    Object.assign(correctVal, pick(val, ['when', 'sign', 'granularity', 'isCurrentDate', 'gain', 'value', 'format']));
  }

  return correctVal;
};
