import { Inject, Injectable } from '@angular/core';
import { CanActivateChild, CanLoad, Router } from '@angular/router';

import { isNonEmptyString, SessionStorage, STORAGE, storageKeys } from 'tr-lib';

@Injectable()
export class DexcomGuard implements CanActivateChild, CanLoad {
  constructor(
    @Inject(STORAGE) private storage: SessionStorage<typeof storageKeys.dexcom_mode>,
    private router: Router
  ) {}

  canActivateChild(): boolean {
    return this.canActivateOrLoad();
  }

  canLoad(): boolean {
    return this.canActivateOrLoad();
  }

  private canActivateOrLoad(): boolean {
    const isDexcomMode = isNonEmptyString(this.storage.getItem(storageKeys.dexcom_mode) as string);

    if (!isDexcomMode) this.router.navigate(['/dashboard']).then();

    return isDexcomMode;
  }
}
