import { FormControl, FormGroup } from '@angular/forms';

import { isNonEmptyString, isRealValue } from '@tr-common';

import { CountryInfo } from './country-codes';

export const defaultPlaceholder = 'Enter phone number';

export interface FormGroupClass {
  'mat-form-group-invalid': boolean;
}

export interface PhoneInputGroup {
  countryCode: string;
  phoneNumber: string;
}

export interface PhoneInputFormGroup {
  countryCode: FormControl<string>;
  phoneNumber: FormControl<string>;
}

export const freshPhoneInputGroup = (): PhoneInputGroup => ({countryCode: null, phoneNumber: null});

export const createPhoneInputFormGroup = (): FormGroup<PhoneInputFormGroup> => new FormGroup<PhoneInputFormGroup>({
  countryCode: new FormControl<string>(null),
  phoneNumber: new FormControl<string>(null)
});

export const splitInternationalTelNumber = (tel: string, codes: CountryInfo[]): PhoneInputGroup => {
  let plainValue: PhoneInputGroup = {countryCode: null, phoneNumber: tel};

  if (isRealValue(codes) && isNonEmptyString(tel)) {
    const found = codes.find(code => tel.toString().indexOf(code.phone) === 0);

    if (found === undefined) {
      console.warn(`Can't split phone number by country code; set as is`);
    } else {
      plainValue = {countryCode: found.phone, phoneNumber: tel.substring(found.phone.length)};
    }
  }

  return plainValue;
};
