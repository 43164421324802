import { Action, createReducer, on } from '@ngrx/store';

import { splitAllStudies, studyFilters } from '@app/dashboard/models';
import {
  getUserStudies,
  getAllUsersStudiesFail,
  getAllUsersStudiesSuccess,
  getBegunUserStudiesSuccess,
  loadAchievements,
  loadAchievementsFail,
  loadAchievementsSuccess,
  loadMissedStudies,
  loadMissedStudiesFail,
  loadMissedStudiesSuccess,
  loadNotReadMissedStudies,
  loadNotReadMissedStudiesFail,
  loadNotReadMissedStudiesSuccess,
  markMissedStudiesAsReadFail,
  markMissedStudiesAsReadSuccess,
  refreshUserStudies,
  resetState,
  getSubmittedAnswersSuccess,
  getProcedureSuccess,
  getSubmittedAnswersFail,
  getProcedureFail,
} from '@app/dashboard/store/dashboard.actions';
import { DashboardState } from '@app/dashboard/models/dashboard-state';

export const initialDashboardState: DashboardState = {
  userId: null,
  userStudies: [],
  begunStudies: [],
  activeStudies: [],
  activeOpportunities: [],
  coreStudies: [],
  closedStudies: [],
  questionHistory: [],
  availableStudies: [],
  completedStudies: [],
  achievements: [],
  missedStudies: {
    studies: [],
    pending: false,
  },
  notReadMissedStudies: {
    studies: [],
    pending: false,
  },
  errorMessage: null,
  pendingStudies: false,
  pendingAchievements: false,
  submittedAnswers: null,
  studyProcedure: null
};

const reducer = createReducer(
  initialDashboardState,
  on(getUserStudies, (state) => ({...state, pendingStudies: true})),
  on(getBegunUserStudiesSuccess, (state, {payload}) => ({...state, begunStudies: payload})),
  on(getAllUsersStudiesSuccess, (state, {userId, studies}) => ({
    ...state, pendingStudies: false, errorMessage: null, userId, userStudies: studies, ...splitAllStudies(studies, userId)
  })),
  on(refreshUserStudies, (state, {payload}) => {
    const storeKey = studyFilters[payload].store;
    return {...state, [storeKey]: [...state[storeKey]]};
  }),
  on(getAllUsersStudiesFail, (state, {error}) => ({...state, pendingStudies: false, errorMessage: error})),
  on(resetState, () => ({...initialDashboardState})),
  on(loadAchievements, (state) => ({...state, pendingAchievements: true})),
  on(loadAchievementsSuccess, (state, {achievements}) => ({...state, pendingAchievements: false, achievements})),
  on(loadAchievementsFail, (state) => ({...state, pendingAchievements: false})),
  on(loadNotReadMissedStudies, (state) => ({
    ...state,
    notReadMissedStudies: {...state.notReadMissedStudies, pending: true},
  })),
  on(loadNotReadMissedStudiesSuccess, (state, {payload}) => ({
    ...state,
    notReadMissedStudies: {studies: payload, pending: false},
  })),
  on(loadNotReadMissedStudiesFail, (state) => ({
    ...state,
    notReadMissedStudies: {...state.notReadMissedStudies, pending: false},
  })),
  on(loadMissedStudies, (state) => ({
    ...state,
    missedStudies: {...state.missedStudies, pending: true},
  })),
  on(loadMissedStudiesSuccess, (state, {payload}) => ({
    ...state,
    missedStudies: {pending: false, studies: payload},
  })),
  on(loadMissedStudiesFail, (state) => ({
    ...state,
    missedStudies: {...state.missedStudies, pending: false},
  })),
  on(markMissedStudiesAsReadSuccess, state => ({...state, notReadMissedStudies: {studies: [], pending: false}})),
  on(markMissedStudiesAsReadFail, state => ({
    ...state, notReadMissedStudies: {studies: state.notReadMissedStudies.studies, pending: false}
  })),
  on(getSubmittedAnswersSuccess, (state, {payload}) => ({...state, submittedAnswers: payload})),
  on(getProcedureSuccess, (state, {payload}) => ({...state, studyProcedure: payload})),
  on(getSubmittedAnswersFail, (state) => ({...state, submittedAnswers: null})),
  on(getProcedureFail, (state) => ({...state, studyProcedure: null})),
);

export const dashboardReducer = (state: DashboardState | undefined, action: Action) => reducer(state, action);
