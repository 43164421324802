import { Component, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { debounce } from 'lodash-decorators';

import { isNullOrUndefined, isRealValue } from '@tr-common';

import { StudyQuestion } from '../../../models/study-question';
import { AnsweredQuestion, getPercents, QuestionListCssClasses } from '../../models';
import { StudyFacade } from '../../store';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnDestroy {
  @Output() question = new EventEmitter<string>();
  subscription = new Subscription();
  procedureQuestions$: Observable<AnsweredQuestion[]> = this.study.procedureQuestions$.pipe(
    tap(() => this.scrollToActiveQuestion())
  );
  progress$: Observable<number> = this.study.procedureQuestions$.pipe(map(getPercents), startWith(0));

  constructor(
    public study: StudyFacade,
    private route: ActivatedRoute,
    private elRef: ElementRef,
  ) {
    this.subscription.add(
      this.study.activeQuestionID$.subscribe(() => this.scrollToActiveQuestion())
    );
  }

  @debounce(300) scrollToActiveQuestion(): void {
    const questionWrapper: HTMLElement = this.elRef.nativeElement;
    const questionList = questionWrapper.querySelector('.question-list');

    if (questionList.children.length > 0) {
      const activeQuestion = questionList.querySelector('li.active-question');
      const isFirstInList = isRealValue(activeQuestion) && isNullOrUndefined(activeQuestion.previousElementSibling);

      if (isFirstInList || isNullOrUndefined(activeQuestion)) {
        questionWrapper.scrollTop = 0;
      } else {
        activeQuestion.scrollIntoView({block: 'start', behavior: 'smooth'});
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  questionSelect({id}: StudyQuestion | AnsweredQuestion) {
    this.question.emit(id);
  }

  getItemCssFormQuestion({id, completed, disabled}: AnsweredQuestion): QuestionListCssClasses {
    const questionId = this.route.snapshot.queryParamMap.get('questionId');

    return {'active-question': questionId === id, 'completed-question': completed, 'disabled-question': disabled};
  }
}
