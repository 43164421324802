import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-goto-back-link',
  template: `
    <button mat-flat-button class="survey-button is-squared is-with-icon" color="secondary" (click)="redirectTo.emit()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <span>{{ buttonTitle }}</span>
  `,
  styleUrls: ['./goto-back-link.component.scss']
})
export class GotoBackLinkComponent {
  @Input() buttonTitle = 'Go back';
  @Output() redirectTo = new EventEmitter();
}
