<div mat-dialog-title>
  <div class="close-icon" [mat-dialog-close]="null"><i class="material-icons">clear</i></div>
  <app-back-button [mat-dialog-close]="null">Back to My Profile</app-back-button>
  <h3 class="mbs-0">Reasons for Withdrawal</h3>
</div>

<div mat-dialog-content>
  <p>Are you sure you want to withdraw from the Registry? Withdrawing from the Registry is permanent and cannot be undone.</p>
  <p><strong>Please select the main reasons why you have decided to withdraw:</strong></p>

  <form [formGroup]="withdrawalForm">
    <mat-checkbox formControlName="receiving_too_many_emails">I am receiving too many emails.</mat-checkbox>
    <mat-checkbox formControlName="have_data_privacy_concerns">I have data privacy concerns.</mat-checkbox>
    <mat-checkbox formControlName="takes_too_much_time_to_complete_qs">
      I think it takes too much time to complete the questionnaires.
    </mat-checkbox>
    <mat-checkbox formControlName="not_wish_be_presented_with_pro">
      I do not wish to be presented with partner (non-T1D Exchange) research opportunities.
    </mat-checkbox>
    <mat-checkbox formControlName="have_difficult_accessing_account">
      I am having difficulties accessing my account/information.
    </mat-checkbox>
    <mat-checkbox formControlName="was_signed_up_by_parent">
      I was signed up by my parent and no longer wish to participate in research.
    </mat-checkbox>
    <mat-checkbox formControlName="was_signed_up_for_my_child">
      I signed up for my child and he/she no longer wishes to participate in research.
    </mat-checkbox>
    <mat-checkbox formControlName="not_wish_to_disclose_my_reason">
      I don't wish to disclose my reason for withdrawing.
    </mat-checkbox>
    <div class="flex other-checkbox">
      <mat-checkbox formControlName="other">Other.</mat-checkbox>
      <div class="flex textarea-wrapper ml-40 mbe-10">
        <textarea formControlName="reason" placeholder="State your own reason." rows="2" spellcheck="true"></textarea>
      </div>
    </div>
  </form>

  <mat-error *ngIf="withdrawalForm.errors?.empty">Please select at least one reason for withdrawal.</mat-error>
  <mat-error *ngIf="withdrawalForm.errors?.reason">Please state your own reason for withdrawal.</mat-error>
</div>

<div mat-dialog-actions class="confirmation-actions">
  <button class="dialog-button" mat-flat-button color="primary" [mat-dialog-close]="null">Cancel</button>
  <button class="dialog-button" mat-flat-button color="secondary" (click)="onSubmit()">Withdraw</button>
</div>
