import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';

import { isRealValue } from 'tr-lib';

import { receiveErrors } from '@app/model/user.helpers';
import { ErrorState } from '@store/reducers/error.reducer';

export const errorStoreProperty = 'error';
export const selectError = createFeatureSelector<ErrorState>(errorStoreProperty);
export const selectCheckEmail = createSelector(selectError, ({checkEmail}) => checkEmail);
export const selectProfileError = createSelector(selectError, state => get(state, ['profileError'], {}));
export const selectReconsentPasswordError = createSelector(selectError, state => get(state, ['reconsentPasswordError'], {}));
export const selectProfileChildError = createSelector(selectError, ({profileChildError}) => profileChildError);
export const selectHouseholdError = createSelector(selectError, ({householdError}) => householdError ?? {});
export const selectRegisterError = createSelector(selectError, ({registerError}) => registerError);
export const selectLoginError = createSelector(selectError, ({loginError}) => loginError);
export const selectUserDeactivateError = createSelector(selectError, ({userDeactivateError}) => userDeactivateError);
export const selectSubmitDualFactorCodeFail = createSelector(selectError, ({validationError}) => validationError);
export const selectTokenError = createSelector(selectError, ({tokenError}) => tokenError);
export const selectChangePassError = createSelector(selectError, ({changePassError}) => changePassError);
export const selectNewPassError = createSelector(selectError, ({newPassError}) => newPassError);
export const selectResetPassError = createSelector(
  selectError, ({resetPassError}) => isRealValue(resetPassError) ? receiveErrors(resetPassError) : null
);
export const selectReconsentFormErrors = createSelector(
  selectProfileError, selectReconsentPasswordError,
  (reconsentProfileError, reconsentPasswordError) => ({...reconsentProfileError, ...reconsentPasswordError})
);
