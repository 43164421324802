import * as t from 'io-ts';

import { participantSource, ParticipantSourceType } from 'tr-lib';

export const jaebUserInfoSchema = t.type({
  email: t.string,
});
export type JaebUserInfo = t.TypeOf<typeof jaebUserInfoSchema>;

export type JaebUserInfoWithId = {id: string} & JaebUserInfo;

export const urlForJaebUsersPreselect = (apiUrl: string): string => `${apiUrl}/converted_users_preselect/`;

export const isJaebRelated = (
  source: ParticipantSourceType
): boolean => source === participantSource.jaeb || source === participantSource.registry_jaeb;
