import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

import { nullable } from './helpers';

export const promotionSchema = t.intersection([
  t.type({
    id: nullable(t.string),
    title: nullable(t.string),
    text: nullable(t.string),
    button_text: nullable(t.string),
    url: nullable(t.string),
    add_participant_id_query_param: t.boolean,
  }),
  t.partial({
    disabled: t.boolean,
    invalid: t.boolean
  })
], 'promotionSchema');
export type PromotionType = t.TypeOf<typeof promotionSchema>;

export const studyPromotionSchema = t.intersection([
  t.type({
    id: t.string,
    title: t.string,
    text: nullable(t.string),
    button_text: nullable(t.string),
    url: nullable(t.string),
    add_participant_id_query_param: t.boolean,
  }),
  t.partial({
    disabled: t.boolean,
    invalid: t.boolean,
    modifiedField: t.string
  })
], 'studyPromotionSchema');
export type StudyPromotionType = t.TypeOf<typeof studyPromotionSchema>;
