import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CountryFlagModule } from '../country-flag/country-flag.module';
import { PhoneInputComponent } from './phone-input.component';

@NgModule({
  declarations: [ PhoneInputComponent ],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatIconModule, MatTooltipModule,
    CountryFlagModule
  ],
  exports: [ PhoneInputComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PhoneInputModule {}
