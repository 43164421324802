import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialImportsModule } from '../material-imports.module';
import { ContactInfoComponent } from './contact-info.component';
import { IconTimeComponent } from './icon-time.component';

@NgModule({
  declarations: [
    IconTimeComponent,
    ContactInfoComponent,
  ],
  imports: [
    CommonModule,
    MaterialImportsModule,
  ],
  exports: [
    IconTimeComponent,
    ContactInfoComponent,
  ]
})
export class ContactInfoModule {}
