import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';

import { studyEditSaveSchema } from '@tr-common';

import { Procedures } from './procedure';
import { Study } from './study';
import { StudyState, studyState } from './study-state';
import { UserStudyType } from '../study-survey/models';

export const introPageSchema = t.intersection([
  t.interface({
    id: UUID
  }),
  t.type({
    content: t.string
  })
], 'introPageSchema');
export type IntroPageType = t.TypeOf<typeof introPageSchema>;

export const introPageEditSchema = t.intersection([introPageSchema, studyEditSaveSchema], 'introPageEditSchema');
export type IntroPageEditType = t.TypeOf<typeof introPageEditSchema>;

export const isStudyBlocked = (
  status: StudyState, dashboardStatus: string
): boolean => status === studyState.closed && dashboardStatus !== 'in_progress';
