import { PathAndLabel } from 'tr-lib';

const defaultPath = '*';

const allPaths: PathAndLabel[] = [
  {path: '/dashboard', label: 'Dashboard'},
  {path: '/user-profile', label: 'Profile'},
  {path: '/survey/study', label: 'Study'},
  {path: defaultPath, label: 'Page'}
];

export const getLabel = (url: string): string => allPaths.find(({path}) => path === defaultPath || url.match(path)).label;
