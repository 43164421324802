import * as t from 'io-ts';

import { isRealValue, nullable } from './helpers';
import { ModuleContent } from './module-content';
import { ProcedureTemplateTypes, procedureTemplateTypesSchema } from './procedure-template-type';

export interface ModuleSettings {
  module_type: ModuleType;
  stage: ModuleStage;
  content: ModuleContent;
  procedure: ProcedureTemplateTypes;
  id?: string;
  study?: string;
  description?: string;
  can_edit?: boolean;
  can_delete?: boolean;
}

export type ModuleType = 'popup' | 'page';
export type GroupedSettingsByProcedure = { [k in ProcedureTemplateTypes]: ModuleSettings[] };

export enum ModuleStage {
  pre_procedure = 'before_procedure',
  confirmation = 'confirmation',
  conclusion = 'conclusion',
  screening_failed = 'screening_failed'
}

export interface CreateModuleData {
  settings: Partial<ModuleSettings>,
  title: string
}

export type ModuleForView = {
  [k in ModuleStage]: ModuleTypeView
}

export type ModuleTypeView = {
  [k1 in ModuleType]: ModuleSettings
}

export const moduleTypeSchema = t.union([t.literal('page'), t.literal('popup')]);
export const moduleContentSchema = t.intersection([
  t.type({
    label: t.string,
    title: t.string,
  }),
  t.partial({
    button_text: nullable(t.string),
    body_text: nullable(t.string),
    download_button: nullable(t.boolean),
    review_answers_button: nullable(t.boolean),
    print_button: nullable(t.boolean),
    social_links: nullable(t.boolean),
    show_timestamp: nullable(t.boolean),
    contact_phone: nullable(t.string),
    contact_email: nullable(t.string),
  }),
]);
export const moduleStageSchema = t.union([
  t.literal(ModuleStage.pre_procedure),
  t.literal(ModuleStage.confirmation),
  t.literal(ModuleStage.conclusion),
  t.literal(ModuleStage.screening_failed),
]);

export const moduleSettingsSchema = t.intersection([
  t.type({
    module_type: moduleTypeSchema,
    stage: moduleStageSchema,
    procedure: procedureTemplateTypesSchema,
    content: moduleContentSchema,
  }),
  t.partial({
    id: nullable(t.string),
    study: nullable(t.string),
    description: nullable(t.string),
    can_edit: nullable(t.boolean),
    can_delete: nullable(t.boolean),
  }),
]);

export const selectSettingByTypeAndStage = (type: ModuleType, stage: ModuleStage, settings: ModuleSettings[]) =>
  settings.find(s => s.stage === stage && s.module_type === type);
export const filterPopupAndBeforeProcedure = (
  {module_type, stage}: ModuleSettings
): boolean => module_type === 'popup' && stage === ModuleStage.pre_procedure;
export const filterPopupAndConclusion = (
  {module_type, stage}: ModuleSettings
): boolean => module_type === 'popup' && stage === ModuleStage.conclusion;

const groupByStage = (settings: ModuleSettings[]): ModuleForView => settings.reduce((a, c) => ({
  ...a,
  [c.stage]: a.hasOwnProperty(c.stage) ? {...a[c.stage], [c.module_type]: c} : {[c.module_type]: c},
}), {} as ModuleForView);

export const groupedDescriptionByStage = (
  settings: ModuleSettings[]
): ModuleForView => isRealValue(settings) ? groupByStage(settings) : {} as ModuleForView;
