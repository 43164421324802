import * as t from 'io-ts';

export const surveyType = {
  annual_questionnaire: 'annual_questionnaire',
  core_study: 'core_study',
  promotional_study: 'promotional_study',
  sub_study: 'sub_study',
} as const;
export const surveyTypeSchema = t.keyof(surveyType, 'surveyTypeSchema');
export type SurveyType = t.TypeOf<typeof surveyTypeSchema>;

export const isSubStudyOrPromotional = (
  st: SurveyType
): boolean => [surveyType.sub_study, surveyType.promotional_study].some(s => s === st);

export const isStudyCoreOrAnnual = (
  st: SurveyType
): boolean => [surveyType.core_study, surveyType.annual_questionnaire].some(s => s === st);

export const isAnnualQuestionnaire = (st: SurveyType): boolean => st === surveyType.annual_questionnaire;
export const isPromotionalStudy = (st: SurveyType): boolean => st === surveyType.promotional_study;
