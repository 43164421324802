import { isNullOrUndefined } from 'tr-lib';

import { DashboardComponentState } from './dashboard';
import { DashboardStatuses } from './dashboard-extended-status';

export enum CoreStudiesTypes {
  aq = 'aq',
  cs = 'cs'
}

export interface DashboardBannerContent {
  title: string;
  content: string;
}

export type DashboardBannerContentType = {
  [key in CoreStudiesTypes]: DashboardBannerContent;
};

export const defaultButtonTitle = 'Start Study';
export const defaultPrimaryBannerLine = 'Core study completion unlocks all research opportunities.';
export const bannerContent: DashboardBannerContentType = {
  aq: {
    title: 'Our Annual Questionnaire is what makes the Registry so impactful.',
    content: `
      The AQ allows researchers to learn more about
      the progression of type 1 diabetes and how an individual’s management or treatment options can change year to year.
      This data can help generate real-world changes in type 1 diabetes care!
    `
  },
  cs: {
    title: 'Let’s get started!',
    content: `
      To begin, answer a few brief questions to determine your eligibility. If you are eligible, then answer questions
      including demographic information, medical history, medications that you may take, glucose monitoring, income,
      education level, and insurance coverage.
    `
  }
};

const getNumberOfOmitStudies = (statuses: DashboardStatuses): number => statuses.filter(
  ({viewed_at, study_status}) => isNullOrUndefined(viewed_at) && study_status !== 'closed'
).length;

const composeStudiesLine = (
  amount = 1, single = 'research opportunity', plural = 'research opportunities'
) => `${amount} new ${amount > 1 ? plural : single}`;

export const getWelcomeBackAdditionalLine = ({availableStudies, activeOpportunities}: DashboardComponentState): string => {
  const [studies, opportunities] = [getNumberOfOmitStudies(availableStudies), getNumberOfOmitStudies(activeOpportunities)];
  const studiesLine = studies > 0 ? composeStudiesLine(studies, 'study', 'studies') : '';
  const opportunitiesLine = opportunities > 0 ? composeStudiesLine(opportunities) : '';
  let result = 'New opportunities are coming soon!'

  if (studies > 0 && opportunities === 0) {
    result = `You have ${studiesLine} available.`;
  } else if (studies === 0 && opportunities > 0) {
    result = `You have ${opportunitiesLine} available.`;
  } else if (studies > 0 && opportunities > 0) {
    result = `You have ${studiesLine} and ${opportunitiesLine} available.`;
  }

  return result;
};
