import { isRealValue } from '@tr-common';

import { TreeNode } from '../../models';
import { StudyOption } from '../../models/study-option';
import { BuildTreeSourceData, getQuestionAnswers } from './build-tree.helpers';

export const generateMatrixScaleNode = (
  sourceData: BuildTreeSourceData, matrixScaleOptions: StudyOption[], parent: TreeNode, nodeIndex: number, parentOptionIndex?: number,
): [] | [TreeNode] => {
  let matrixScaleNode: [] | [TreeNode] = [];

  if (matrixScaleOptions.length > 0) {
    const questionID = sourceData.question.id;
    const optionAnswer = getQuestionAnswers(sourceData).filter(q => matrixScaleOptions.some(({id}) => id === q.question_option));
    const sortedOptionAnswer = matrixScaleOptions.map(({id}) => optionAnswer.find(({question_option}) => question_option === id));
    const value = optionAnswer.length > 0 ? JSON.stringify(sortedOptionAnswer.map(a => a?.value)) : false;

    matrixScaleNode = [new TreeNode({
      matrixScaleOptions, showHint: parent.showHint, nodeIndex, parentOptionIndex, parent, isQuestion: false, questionID, value
    })];
  }

  return matrixScaleNode;
};

export const sortNodesWithMatrixScale = (
  [matrixScaleNode]: TreeNode[], treeOptionNodes: TreeNode[]
): TreeNode[] => isRealValue(matrixScaleNode)
? [matrixScaleNode, ...treeOptionNodes].sort((a: TreeNode, b: TreeNode): number => {
  let outcome = 0;

  if (isRealValue(a) && isRealValue(b)) {
    const optionA = isRealValue(a.data) ? a.data : a.matrixScaleOptions[0];
    const optionB = isRealValue(b.data) ? b.data : b.matrixScaleOptions[0];

    outcome = optionA.order - optionB.order;
  }

  return outcome;
})
: treeOptionNodes;
