import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { SessionStorage, STORAGE, storageKeys, StorageKeys } from 'tr-lib';

@Injectable()
export class ReConsentAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    @Inject(STORAGE) private storage: SessionStorage<StorageKeys>,
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const isOk = this.storage.getItem(storageKeys.token) === 'reconsent_pending';
    if (!isOk) {
      this.router.navigate(['/auth/login']);
    }
    return of(isOk);
  }
}
