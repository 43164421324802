import { isNonEmptyArray } from '@tr-common';

const maxColumnWidthInGrid = '90px';

export enum gridTemplateStyles {
  rows = 'grid-template-rows',
  columns = 'grid-template-columns'
}

export type GridTemplateStyleType = {
  [k in gridTemplateStyles]: string
};

export const makeGridStyles = (
  metadata: string[], isRowPrefix: boolean, isMobile: boolean
): GridTemplateStyleType | unknown => {
  const outcome = {};

  if (isNonEmptyArray(metadata)) {
    const style = isMobile ? gridTemplateStyles.rows : gridTemplateStyles.columns;
    const prefix = isRowPrefix ? '230px ' : '';
    const columns = metadata.length;

    outcome[style] = prefix + new Array(columns).fill(isMobile ? '52px' : maxColumnWidthInGrid).join(' ');
  }

  return outcome;
}
