import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SurveyRouter } from '../services/survey-router.service';
import {
  CommonEffects,
  ConsentIntroEffects,
  DialogEffects,
  ProcedureEffects,
  ProcedureSubmitEffects,
  QuestionEffects,
  SurveyEffects,
} from './effects';
import { studyConsentReducer, studySurveyReducer } from './reducers';
import { studyConsentProperty, studySurveyProperty } from './selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(studySurveyProperty, studySurveyReducer),
    StoreModule.forFeature(studyConsentProperty, studyConsentReducer),
    EffectsModule.forFeature([
      DialogEffects,
      SurveyEffects,
      ProcedureEffects,
      ProcedureSubmitEffects,
      QuestionEffects,
      ConsentIntroEffects,
      CommonEffects
    ]),
  ],
  providers: [SurveyRouter],
})
export class StudySurveyStoreModule {}
