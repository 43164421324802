import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';

import { isNonEmptyString } from '@tr-common';

@Component({
  selector: 'lib-country-flag',
  template: `<div *ngIf="isNotEmptyCode" class="flag flag-{{ codeToDisplay }}"></div>`,
  styleUrls: ['./country-flag.component.scss']
})
export class CountryFlagComponent implements OnChanges {
  @Input() code: string;
  private _codeToDisplay: string;
  get isNotEmptyCode(): boolean { return isNonEmptyString(this._codeToDisplay); }
  get codeToDisplay(): string { return this._codeToDisplay; }
  set codeToDisplay(code: string) { this._codeToDisplay = typeof code === 'string' ? code.toLowerCase() : null; }

  ngOnChanges(changes: SimpleChanges): void {
    this.codeToDisplay = get(changes, ['code', 'currentValue'], null);
  }
}
