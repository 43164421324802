import * as t from 'io-ts';

export enum MrpHead {
  Screening = 'Screening',
  Consent = 'Consent',
  Questionnaire = 'Questionnaire',
  Study = 'Study',
  Invalid = 'Invalid Status',
}

export enum MrpStage {
  opened = 'Open',
  intro = 'Intro',
  in_progress = 'In progress',
  completed = 'Completed',
  not_started = 'Not started',
  closed_by_system = 'Closed by system',
  closed = 'Closed',
}

export interface MrpStatusBrand {
  readonly MrpStatusString: unique symbol;
}

export type MrpStatusString = t.Branded<string, MrpStatusBrand>;
export type MrpStatusStringC = t.Type<MrpStatusString | string>;
