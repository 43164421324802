import { isNonEmptyArray } from '@tr-common';

import { AnsweredQuestion } from './answered-question';

const completedQuestionsLength = qs => qs.filter(q => q.completed).length;

export const getPercents = (questions: AnsweredQuestion[]): number => {
  let percents = 0;

  if (isNonEmptyArray(questions)) {
    percents = Math.round(100 * completedQuestionsLength(questions) / questions.length);
  }

  return percents;
}
