import { participantSource, TypeConsent } from '@tr-common';

import { getConsentTypeFromParticipantProfile, ParticipantDetails } from './participant-details';
import { Study } from './study';
import { isStudyCoreOrAnnual } from './survey-type';

export interface ErrorDialogData {
  sorry: string;
  action: string;
  whatToDo: string;
  hideCloseIcon?: boolean;
}

export const defaultErrorDialogData: ErrorDialogData = {
  sorry: 'Internal Server Error',
  action: 'The server encountered an internal error or misconfiguration and was unable to complete your request',
  whatToDo: `
    Please contact us at <a href="mailto:info@T1DExchange.org">info@T1DExchange.org</a>
    to inform of the time this error occurred, and the actions you performed just before this error
  `,
  hideCloseIcon: false,
};

export const isDialogShouldBeFromModuleConstructor = (p: ParticipantDetails, s: Study): boolean => {
  const isParticipantFromRegistry = p.source === participantSource.registry;
  let result = false;

  if (isParticipantFromRegistry) {
    const consentType = getConsentTypeFromParticipantProfile(p);

    result = consentType === TypeConsent.adult || !isStudyCoreOrAnnual(s.study_type);
  }

  return result;
}

const dialogMaxWidth = '600px'; // default width
const dialogMobileMaxWidth = '100vw';
export const dialogPanelClass = 'tr-modal-default';
export const getMaxWidth = (isMobile: boolean) => isMobile ? dialogMobileMaxWidth : dialogMaxWidth;
export const defaultDialogSettings = (isMobile: boolean) => ({
  maxWidth: getMaxWidth(isMobile),
  panelClass: dialogPanelClass,
  autoFocus: false,
});
