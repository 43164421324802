import { isRight } from 'fp-ts/Either';
import * as t from 'io-ts';
import { Moment, MomentInput, now } from 'moment';

import { nullable, TypeConsent } from '@tr-common';

import { anonymousParticipantDetailsSchema, baseParticipantSchema } from './user-profile';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export const participantDetailsSchema = t.intersection([
  baseParticipantSchema,
  anonymousParticipantDetailsSchema,
  t.partial({
    access_token: nullable(t.string)
  })
], 'participantDetailsSchema');
export type ParticipantDetails = t.TypeOf<typeof participantDetailsSchema>;

export const participantFullName = (details: ParticipantDetails): string => {
  const maybeDecoded = baseParticipantSchema.decode(details);

  return isRight(maybeDecoded) ? `${maybeDecoded.right.first_name} ${maybeDecoded.right.last_name}` : null;
}

export const getUserAgeFromParticipantProfile = (
  profile: ParticipantDetails
): number => moment(now()).diff(profile.birthday, 'years', false);

export const getConsentTypeFromParticipantProfile = (
  profile: ParticipantDetails
): TypeConsent => profile.profile_type === 'child'
  ? (getUserAgeFromParticipantProfile(profile) <= 13 ? TypeConsent.parental : TypeConsent.child)
  : TypeConsent.adult;
