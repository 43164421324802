import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-registry-snack-bar',
  template: `<mat-icon [svgIcon]="data?.icon"></mat-icon> <span>{{ data?.message }}</span>`,
  styleUrls: ['./registry-snack-bar.component.scss']
})
export class RegistrySnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}
