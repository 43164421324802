import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Delay } from 'lodash-decorators';
import { Observable } from 'rxjs';

import { ErrorDialogData, LayoutService, ParticipantUpdateProfileWithIdType, successSnackbarConfig } from 'tr-lib';

import { ConfirmationData, publicDialogClass, publicDialogSettings } from '@app/model';
import { ConfirmationDialogRegistryComponent } from '@app/dialogs/confirm-dialog/confirm-dialog-registry.component';
import { ErrorDialogComponent } from '@app/dialogs/error-dialog/error-dialog.component';
import { MissedProfileTypeDialogComponent } from '@app/dialogs/missed-profile-type-dialog/missed-profile-type-dialog.component';
import { WithdrawalReasons } from '@app/user-profile/model';
import { WithdrawalReasonsDialogComponent } from '@app/user-profile/dialogs/withdrawal-reasons-dialog.component';

import { RegistrySnackBarComponent } from '../components/registry-snack-bar/registry-snack-bar.component';

@Injectable({providedIn: 'root'})
export class DialogsService {
  constructor(
    public dialog: MatDialog,
    private layoutService: LayoutService,
    private snackBar: MatSnackBar,
  ) {}

  @Delay(100) showUpdateProfileDialog() {
    this.snackBar.openFromComponent(RegistrySnackBarComponent, successSnackbarConfig('Your account has been updated'));
  }

  @Delay(100) showInternalServerErrorDialog() {
    if (!this.dialog.openDialogs || this.dialog.openDialogs.length === 0) {
      // TODO Check if ErrorDialogComponent works properly
      this.dialog.open(ErrorDialogComponent, publicDialogSettings(this.layoutService.isMobile));
    }
  }

  @Delay(100) showSessionExpiredDialog() {
    this.snackBar.openFromComponent(RegistrySnackBarComponent, {
      panelClass: 'success-snackbar',
      verticalPosition: 'top',
      duration: 1000,
      data: {message: 'Session expired', icon: 'notify-success'},
    });
  }

  showErrorDialog(data: ErrorDialogData): void {
    if (!this.dialog.openDialogs || this.dialog.openDialogs.length === 0) {
      this.dialog.open(ErrorDialogComponent, {
        ...publicDialogSettings(this.layoutService.isMobile),
        data
      });
    }
  }

  showUpdateProfileTypeDialog(id: string): Observable<ParticipantUpdateProfileWithIdType | null> {
    return this.dialog.open(MissedProfileTypeDialogComponent, {
      ...publicDialogSettings(this.layoutService.isMobile, 'tr-modal-missed-profile'),
      disableClose: true,
      data: {id},
    }).afterClosed();
  }

  showReactivateDialog(): Observable<unknown> {
    return this.dialog.open<ConfirmationDialogRegistryComponent, ConfirmationData, boolean>(ConfirmationDialogRegistryComponent, {
      ...publicDialogSettings(this.layoutService.isMobile, 'confirmation-dialog'),
      data: {
        closeIcon: false,
        action: 'You are about to re-take the screening questions for the T1D Exchange Registry.' +
          ' By doing this, your previously reported screening questions’ answers will be lost.' +
          ' By clicking "Try Again" you will be able to re-take the screening questions.',
        buttons: {cancel: 'No, cancel', confirm: 'Yes, Try Again'}
      }
    }).afterClosed();
  }

  showSuspectEmailConfirmationDialog(message: string, email: string): Observable<boolean> {
    return this.dialog.open<ConfirmationDialogRegistryComponent, ConfirmationData, boolean>(ConfirmationDialogRegistryComponent, {
      ...publicDialogSettings(this.layoutService.isMobile, 'confirmation-dialog'),
      data: {
        isDanger: true,
        closeIcon: false,
        question: 'Whoops!',
        action: message,
        what: email
      }
    }).afterClosed();
  }

  showProfileCloseDialog(): Observable<boolean> {
    return this.dialog.open<ConfirmationDialogRegistryComponent, ConfirmationData, boolean>(ConfirmationDialogRegistryComponent, {
      ...publicDialogSettings(this.layoutService.isMobile, 'confirmation-dialog'),
      autoFocus: false,
      data: {
        closeIcon: false,
        action: 'You have unsaved changes',
        buttons: {cancel: 'Stay on the page', confirm: 'Leave without saving'}
      }
    }).afterClosed();
  }

  showWithdrawalReasonsDialog(): Observable<WithdrawalReasons> {
    return this.dialog.open<WithdrawalReasonsDialogComponent, void, WithdrawalReasons>(WithdrawalReasonsDialogComponent, {
      panelClass: [publicDialogClass, 'withdrawal-modal'],
      maxWidth: this.layoutService.isGadget ? '100vw' : '712px',
      autoFocus: false
    }).afterClosed();
  }
}
