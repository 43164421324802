import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { HeaderComponent } from '@app/shared/components/header/header.component';

@Component({
  selector: 'app-not-auth-header',
  template: `
    <header class="flex space-between">
      <div class="flex align-center">
        <a routerLink="/landing">
          <img class="l-desktop" src="/assets/icons/logo.svg" alt="# /">
          <img class="l-medium" src="/assets/icons/logo.svg" alt="#" />
        </a>
        <a class="link" routerLinkActive="active" routerLink="/faq">FAQ</a>
        <a class="link" routerLink="/landing" fragment="contact-section">Contact Us</a>
      </div>

      <div class="flex align-center" [ngClass]="{'jaeb-mode': isJAEBMode}">
        <button mat-flat-button color="secondary" class="button-sm l-desktop weight-600" routerLink="/auth/login">Sign In</button>
        <button mat-flat-button color="primary" class="button-sm l-desktop" routerLink="/auth/register">Sign Up</button>
        <button mat-flat-button class="button-icon l-medium" disableRipple (click)="openMenuForMobile()">
          <mat-icon svgIcon="hamburger_dark"></mat-icon>
        </button>
      </div>
    </header>
  `,
  styleUrls: ['./not-auth-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotAuthHeaderComponent extends HeaderComponent {
  @Input() isJAEBMode = false;

  constructor(dialog: MatDialog) {
    super(dialog);
  }
}
