import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';

import { isNonEmptyString, isRealValue, SessionStorage, STORAGE, storageKeys } from 'tr-lib';
import { cutTrEmailIdFromUrl, getTrEmailIdFromUrl } from '@app/model/tr-email-id';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  private token: string;

  constructor(
    private router: Router,
    private location: Location,
    @Inject(STORAGE) private storage: SessionStorage<typeof storageKeys.token>
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateOrLoad();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.canActivateOrLoad();
  }

  canActivateOrLoad(): Observable<boolean> {
    const isTokenPresented = this.getTokenFromStorage();

    if (!isTokenPresented) {
      const sourceURL = this.location.path(true);
      const emailId = getTrEmailIdFromUrl(sourceURL);
      const trackEmailId = isRealValue(emailId) ? {'tr-email-id': emailId} : null;

      this.router.navigate(['/auth/login'], {
        queryParams: {redirectTo: btoa(cutTrEmailIdFromUrl(sourceURL)), ...trackEmailId}
      }).then();
    }

    return of(isTokenPresented);
  }

  private getTokenFromStorage(): boolean {
    this.token = this.storage.getItem(storageKeys.token) as string;

    return isNonEmptyString(this.token);
  }
}
