import { Injectable, Injector } from '@angular/core';
import { Params, QueryParamsHandling, Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { Subject } from 'rxjs';

import { isRealValue, ProcedureTemplateTypes } from '@tr-common';

import {
  AllIDs,
  getOptRedirectParams,
  getProcedureSubmitPath,
  getSurveyRootPath,
  makeSurveyPath,
  makeSurveyUrl,
  OptRedirectParamsType,
  ParamsIDs,
  UrlStudyWithIsStatus,
  UrlStudyWithProcedureAndQuestion,
  UrlStudyWithProcedureAndSource,
} from '../models';
import { SurveyIsolatedActions, SurveyUrlFactoryProxy } from './survey-url-factory.service';

@Injectable()
export class SurveyRouter {
  actions$ = new Subject<Action>();
  urlFactory = this.urls.proxyFactory;

  constructor(
    private router: Router,
    private urls: SurveyUrlFactoryProxy,
    private injector: Injector,
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }

  navigateToRoot(paramsIDs: ParamsIDs): Promise<boolean> {
    return this.router.navigate(getSurveyRootPath(this.urlFactory.getStudyRootUrl(), paramsIDs), {replaceUrl: true});
  }

  navigateToDashboard(): Promise<boolean> {
    return this.router.navigate([this.urlFactory.getDashboardUrl()]);
  }

  navigateToNotEligiblePage({userID, studyID, userStudyID, source}: UrlStudyWithProcedureAndSource): Promise<boolean> {
    return this.router.navigate([
      ...getSurveyRootPath(this.urlFactory.getStudyRootUrl(), {userID, studyID, userStudyID}), 'not-eligible'
      ], {queryParams: {source}, replaceUrl: true},
    );
  }

  navigateToNoConsentPage(paramsIDs: ParamsIDs): Promise<boolean> {
    return this.router.navigateByUrl(makeSurveyUrl(this.urlFactory.getStudyRootUrl(), paramsIDs) + '/no-consent');
  }

  navigateToQuestion(params: {procedure: string, questionId: string, preview?: boolean}): Promise<boolean> {
    return this.router.navigate([], {queryParams: params});
  }
  /**
   * @param opt
   * @param toStatus
   * @description navigates to procedure in user's study using `userID, studyID, userStudyID, procedureId`.
   * procedure is required; questionId can be optional
   */
  navigateToProcedure(
    opt: ParamsIDs & ({procedureId: string} | {procedureId: string, questionId: string}),
    toStatus = false
  ): Promise<boolean> {
    const {procedureId, userID, userStudyID, studyID} = opt;
    const allIDs = {userID, studyID, userStudyID};
    const surveyPath = makeSurveyPath(this.urlFactory.getStudyRootUrl(), allIDs, toStatus);

    // console.log({handler: 'navigateToProcedure', surveyPath, procedureId, userID, userStudyID, studyID});
    return this.router.navigate(surveyPath, {queryParams: {
      procedure: procedureId, ...('questionId' in opt ? {questionId: opt.questionId} : null)
    }});
  }

  navigateToProcedureInCurrentStudy(procedure: string): Promise<boolean> {
    return this.router.navigate([], {queryParams: {procedure}, replaceUrl: true});
  }

  navigateToClosedStudyPage({userID, studyID, userStudyID}: ParamsIDs): Promise<boolean> {
    const url = this.urlFactory.getStudyRootUrl() + `/study/${studyID}/user-study/${userStudyID}/user/${userID}/closed-study`;

    return this.router.navigateByUrl(url, {replaceUrl: true});
  }

  navigateToQuestionInCurrentStudy({procedureId, questionId}: {procedureId: string, questionId: string}): Promise<boolean> {
    return this.router.navigate([], {queryParams: {procedure: procedureId, questionId}, replaceUrl: true});
  }

  navigateToQuestionFromSubmit(opt: ParamsIDs, procedure: string, questionId: string): Promise<boolean> {
    const url = makeSurveyPath(this.urlFactory.getStudyRootUrl(), opt);
    const queryParams = {procedure, ...(isRealValue(questionId) ? {questionId} : {})};

    return this.router.navigate(url, {queryParams});
  }

  navigateToProcedureAnswersPreview(
    {studyID, userID, userStudyID, procedure, questionId}: UrlStudyWithProcedureAndQuestion
  ): void {
    void this.router.navigate(
      makeSurveyPath(this.urlFactory.getStudyRootUrl(), {studyID, userID, userStudyID}),
      {queryParams: {procedure, questionId, preview: true}}
    );
  }

  navigateToAnswersReview({userID, studyID, userStudyID, procedure}: AllIDs): void {
    const url = this.urlFactory.getStudyRootUrl() + `/study/${studyID}/user-study/${userStudyID}/user/${userID}`;

    void this.router.navigateByUrl(url + `/review-answers?procedure=${procedure}`);
  }

  navigateToConsentReview(studyId: string, participantId: string, consentId: string): Promise<boolean> {
    return this.router.navigate([this.urlFactory.getStudyRootUrl(),
      'study', studyId, 'user', participantId, 'signedConsent', consentId
    ]);
  }

  navigateForward(paramsIDs: ParamsIDs): Promise<boolean> {
    return this.router.navigate([makeSurveyUrl(this.urlFactory.getStudyRootUrl(), paramsIDs)]);
  }

  // TODO: check if it is identical with this.navigateToProcedureStatus
  navigateToProcedurePreservingParams({procedureId, userID, userStudyID, studyID, isStatus}: UrlStudyWithIsStatus): Promise<boolean> {
    return this.router.navigate(
      makeSurveyPath(this.urlFactory.getStudyRootUrl(), {userID, studyID, userStudyID}, isStatus),
      {queryParams: {procedure: procedureId}, queryParamsHandling: 'merge'}
    );
  }

  /**
   * @param opt
   * @description used to forced navigate in case submitConsentProcedureForcibly
   */
  navigateToQuestionProcedure(opt: ParamsIDs): void {
    const url = [makeSurveyUrl(this.urlFactory.getStudyRootUrl(), opt)];

    void this.router.navigate(url, {queryParams: {procedure: ProcedureTemplateTypes.questionnaire}, replaceUrl: true});
  }

  navigateToStudyStart(studyID: string, userStudyID: string | number, userID: string): void {
    const url = `/study/${studyID}/user-study/${userStudyID}/user/${userID}`;

    void this.router.navigateByUrl(this.urlFactory.getStudyRootUrl() + url);
  }

  // TODO: this function obviously does not only do navigate to procedure status and it violates the Single Responsibility Principle
  navigateToProcedureStatus(opt: OptRedirectParamsType, urlShouldBeReplaced = false, shouldBeMerged = false): Promise<boolean> {
    const queryParams: Params = isRealValue(opt.queryParams) ? opt.queryParams : {};
    const replaceUrl = urlShouldBeReplaced ? {replaceUrl: true} : {};
    const mergeUrl: {queryParamsHandling?: QueryParamsHandling | null} = shouldBeMerged ? {queryParamsHandling: 'merge'} : {};

    return this.router.navigate([
      makeSurveyUrl(this.urlFactory.getStudyRootUrl(), getOptRedirectParams(opt), true),
    ], {queryParams, ...mergeUrl, ...replaceUrl});
  }

  redirectToProcedureStatus(paramsIDs: ParamsIDs, procedure: string, withJustSubmitStatus = false): Promise<boolean> {
    const surveyPath = makeSurveyPath(this.urlFactory.getStudyRootUrl(), paramsIDs, true);
    const queryParams = {procedure, ...(withJustSubmitStatus ? {status: 'just_submitted'} : {})};

    return this.router.navigate(surveyPath, {queryParams});
  }

  redirectToProcedureSubmit(paramsIDs: ParamsIDs, procedure: string): Promise<boolean> {
    return this.router.navigate(getProcedureSubmitPath(this.urlFactory.getStudyRootUrl(), paramsIDs), {queryParams: {procedure}});
  }
}
