import { FlatTreeControl } from '@angular/cdk/tree';

import { answerWidget, AnswerWithWidgetType, isNullOrFalse, isRealValue, isTextWidget, questionAnswerLogic } from '@tr-common';

import { FlatTreeNode, isStudyOption, ProcedureQuestions } from '../../models';
import { StudyQuestion } from '../../models/study-question';
import { getAnswerValues } from './answer-value';
import { getParentQuestionID } from './flat-tree-node.helpers';
import { DraftAnswersType, DraftAnswerType } from './draft-answer';

const getUnAnsweredNodes = (dataNodes: FlatTreeNode[], question: StudyQuestion): FlatTreeNode[] => dataNodes
  .filter(v => isStudyOption(v.data) && !v.data.isRadioWidget || v.isSelectableNode)
  .filter(v => v.questionID === question.id)
  .filter(v => isNullOrFalse(v.value));

const hasRadioWidget = (
  answers: DraftAnswersType
): boolean => answers.some(({widget}: AnswerWithWidgetType) => widget === answerWidget.radio || widget === answerWidget.radioWithText);

const validateQuestion = (dataNodes: FlatTreeNode[], question: StudyQuestion, answers: DraftAnswersType) => {
  const filteredNodes = dataNodes.filter(n => n.questionID === question.id);
  const filteredAnswers = answers.filter(a => a.question === question.id);

  if (filteredAnswers.filter(({widget}) => isTextWidget(widget)).some(a => a.value === '')) return false;

  switch (question.question_type) {
    case questionAnswerLogic.single:
    case questionAnswerLogic.multiple:
    case questionAnswerLogic.multiple_or_single_radio:
      return filteredAnswers.length > 0;
    case questionAnswerLogic.all_mixed_or_single_radio:
    case questionAnswerLogic.all_or_single_radio:
      return hasRadioWidget(filteredAnswers) || getUnAnsweredNodes(filteredNodes, question).length === 0;
    case questionAnswerLogic.all_required:
      return filteredAnswers.length === question.options.length;
    default:
      return false;
  }
};

const getUnansweredSubQuestions = (answers: DraftAnswersType, optionQuestionMap: ProcedureQuestions): string[] => {
  const subQuestionList = answers.map(({question_option}: AnswerWithWidgetType) => optionQuestionMap[question_option])
    .filter(isRealValue).reduce(
      (acc, c) => [...acc, ...c.filter(q => !answers.some(a => a.question === q.id))], []
    );

  return subQuestionList.map(q => q.id);
}

const getQuestionListWithInvalidLogic = (
  dataNodes: FlatTreeNode[], answers: DraftAnswersType
): string[] => answers.reduce((
  uniqueQuestions: DraftAnswersType, current: DraftAnswerType
) => uniqueQuestions.find(a => a.question === current.question) ? uniqueQuestions : [...uniqueQuestions, current], []).filter(a => {
  const selectedQuestion = dataNodes.find(n => n.isQuestion && n.questionID === a.question)?.data as StudyQuestion;

  return !(isRealValue(selectedQuestion) && validateQuestion(dataNodes, selectedQuestion, answers));
}).map(a => a.question);

export const validateAnswers = (dataNodes: FlatTreeNode[], optionQuestionMap: ProcedureQuestions): string[] => {
  const answers: DraftAnswersType = getAnswerValues(dataNodes);
  const invalidOptions = answers.length > 0 ? dataNodes.filter(n => !n.isQuestion && n.validStatus === 'INVALID') : [];
  let questionIDs: string[];

  if (answers.length === 0) {
    questionIDs = getParentQuestionID(dataNodes);
  } else if (invalidOptions.length > 0) {
    questionIDs = invalidOptions.map(o => o.questionID);
  } else {
    const unAnsweredSubQuestions = getUnansweredSubQuestions(answers, optionQuestionMap);

    questionIDs = unAnsweredSubQuestions.length > 0 ? unAnsweredSubQuestions : getQuestionListWithInvalidLogic(dataNodes, answers);
  }

  return [...(new Set(questionIDs))];
};

export const isValidByTreeControlState = (treeControl: FlatTreeControl<FlatTreeNode>): boolean => {
  const {dataNodes} = treeControl;
  const answers: DraftAnswersType = getAnswerValues(dataNodes);
  const onlyQuestions = dataNodes.filter(({isQuestion}) => isQuestion);
  const allQuestionsIDs= onlyQuestions.filter(n => {
    const question: StudyQuestion = n.data as StudyQuestion;
    let isParentOptionExpanded = false;

    if(question.hasParent) {
      const foundOption = dataNodes.filter(
        n => isRealValue(n.data)
      ).find(({data: {id}}) => question.parent_option_id === id);

      isParentOptionExpanded = treeControl.isExpanded(foundOption);
    }

    return !question.hasParent || isParentOptionExpanded;
  }).map(({data: {id}}) => id);

  // console.log({dataNodes, onlyQuestions, allQuestionsIDs, answers});

  return answers.length > 0 && answers.length >= allQuestionsIDs.length;
}
