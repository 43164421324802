import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
  FormGroupProtoType,
  getServerErrorsMessages,
  htmlPasswordRequires,
  isRealValue,
  needToShowErrorWhenDirty,
  passwordIsRequired,
  unknownErrorMessage,
} from '@tr-common';

import { ChangePassFormData } from '../../models';
import { newPasswordSameAsOld } from '../../common';

@Component({
  selector: 'lib-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordFormComponent implements OnChanges {
  @Input() parentForm: FormGroup<FormGroupProtoType<ChangePassFormData>>;
  @Input() showPasswordCreationHint = false;
  @Input() resetEyes: boolean = false;
  hasError = needToShowErrorWhenDirty;
  showOldPass = false;
  showNewPass = false;
  showConfirmPass = false;

  get userName(): FormControl<string> { return this.parentForm?.controls.username; }
  get newPassword(): FormControl<string> { return this.parentForm?.controls.new_password; }
  get oldPassword(): FormControl<string> { return this.parentForm?.controls.old_password; }
  get passwordConfirmation(): FormControl<string> { return this.parentForm?.controls.new_password_confirm; }

  get oldPasswordError(): string {
    const errors = this.oldPassword?.errors;
    let message = unknownErrorMessage;

    if (isRealValue(errors?.required)) {
      message = passwordIsRequired;
    } else if (isRealValue(errors?.server)) {
      message = getServerErrorsMessages(this.oldPassword);
    }

    return message;
  }

  get newPasswordError(): string {
    const errors = this.newPassword?.errors;
    let message = unknownErrorMessage;

    if (isRealValue(errors?.required)) {
      message = passwordIsRequired;
    } else if (isRealValue(errors?.pattern)) {
      message = htmlPasswordRequires;
    } else if (errors?.server) {
      message = getServerErrorsMessages(this.newPassword, false);
    } else if (isRealValue(errors?.sameAsOld)) {
      message = newPasswordSameAsOld;
    }

    return message;
  }

  get passwordConfirmationError(): string {
    const errors = this.passwordConfirmation?.errors;
    let message = unknownErrorMessage;

    if (isRealValue(errors?.match)) {
      message = 'Password confirmation does not match';
    } else if (isRealValue(errors?.server)) {
      message = getServerErrorsMessages(this.passwordConfirmation);
    }

    return message;
  }

  ngOnChanges({resetEyes}: SimpleChanges) {
    if (isRealValue(resetEyes)) {
      if (this.resetEyes) {
        [this.showOldPass, this.showNewPass, this.showConfirmPass] = [false, false, false];
      }
    }
  }
}
