import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { isRealValue } from 'tr-lib';

import { AuthService } from '@services/auth.service';
import { trEmailId } from '@app/model/tr-email-id';

@Injectable({providedIn: 'root'})
export class TrackEmailRedirect implements CanActivate {
  constructor(private login: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const emailId = route.queryParams[trEmailId];

    if (isRealValue(emailId)) {
      this.login.trackEmailId(emailId).pipe(take(1)).subscribe();
    }

    return of(true);
  }
}
