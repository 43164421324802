import { isNonEmptyArray, isRealValue } from '@tr-common';

import { Procedures, UserStudyProcedureType } from './procedure';
import { StudyState, studyState } from './study-state';
import { MinimalStudyType } from './study';
import { ParamsIDs } from '../study-survey/models';

export interface StudyHeader {
  procedures: Procedures;
  isStudyBlocked: boolean;
  routerLink: string[];
}

export const isStudyHeaderBlocked = (
  status: StudyState, userProcedures: UserStudyProcedureType[]
): boolean => status === studyState.closed && userProcedures?.length === 0;

export const buildStudyNavigation = (
  procedures: Procedures, study: MinimalStudyType, isStudyBlocked = false,
  proceduresWithAnswers: string[], {studyID, userID, userStudyID}: ParamsIDs
): StudyHeader => {
  const studyHeader: StudyHeader = {
    procedures: [], isStudyBlocked, routerLink: ['/survey', 'study', studyID, 'user-study', userStudyID, 'user', userID]
  };

  if (isNonEmptyArray(procedures) && isRealValue(study)) {
    const isSingleRO = procedures.length === 1 && procedures[0].isResearch;

    if (!isSingleRO) {
      studyHeader.procedures = procedures.map(procedure => ({
        ...procedure, enabled: procedure.enabled || proceduresWithAnswers.includes(procedure.id)
      }));
    }
  }
  // console.log({handler: 'buildStudyHeader', studyHeader, study, procedures, proceduresWithAnswers});

  return studyHeader;
};
