import { Component, Input } from '@angular/core';
import { getLabel } from '@app/model/loader-text';

@Component({
  selector: 'app-loader',
  template: `
    <!-- Divs for loader circles -->
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    <h3 *ngIf="url">Loading your {{ point }},<br> this may take a few seconds…</h3>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() url: string = null;

  get point(): string {
    return getLabel(this.url);
  }
}
