import { InjectionToken } from '@angular/core';

export const REG_GUARD_CLIENT_ID = new InjectionToken<string>('REG_GUARD_CLIENT_ID');
export const T1D_CLIENT_ID = new InjectionToken<string>('T1D_CLIENT_ID');
export const DEXCOM = new InjectionToken<string>('DEXCOM');
export const DEXCOM_BUTTON_ON = new InjectionToken<string>('DEXCOM_BUTTON_ON');
export const DEXCOM_API_TEST = new InjectionToken<string>('DEXCOM_API');
export const DEXCOM_API_PROD = new InjectionToken<string>('DEXCOM_API');
export const ALCHEMER_DEXCOM = new InjectionToken<string>('ALCHEMER_DEXCOM');
export const ALCHEMER_DEXCOM_MODE = new InjectionToken<string>('ALCHEMER_DEXCOM_MODE');
export const T1D_OAUTH_URL = new InjectionToken<string>('T1D_OAUTH_URL');
