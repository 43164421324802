import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { isNonEmptyString, SessionStorage, STORAGE, storageKeys } from 'tr-lib';

@Component({
  selector: 'app-passed-to-child',
  template: `
    <div class="content">
      <h4>Thank you for participating in the T1D Exchange Registry on behalf of your child.</h4>
      <p class="text-normal">
        We have sent an email to <a class="link" href="mailto:{{ email }}">{{ email }}</a>, the email address you provided
        for your child. The email includes instructions to complete the re-consent process and transfer the account.
      </p>
      <button mat-flat-button color="primary" (click)="goLandingPage()">Go to Landing Page</button>
    </div>
  `,
  styleUrls: ['./passed-to-child.component.scss']
})
export class PassedToChildComponent implements OnInit, OnDestroy {
  email = 'nobody@nowhere.org';

  constructor(
    @Inject(STORAGE) private storage: SessionStorage<typeof storageKeys.child_email>,
    private router: Router
  ) {}

  ngOnInit(): void {
    const child_email = this.storage.getItem(storageKeys.child_email) as string;

    if (isNonEmptyString(child_email)) {
      this.email = child_email;
    }
  }

  ngOnDestroy(): void {
    this.storage.removeItem('child_email');
  }

  goLandingPage(): void {
    void this.router.navigate(['/']);
  }
}
