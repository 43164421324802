import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { isNonEmptyString } from '@tr-common';

import { cleanAbsentAnswersFromStorage, hasAllParamIDs, ParamsIDs, QueryParamsIDs } from '../../models';
import { StudyFacade } from '../../store';

@Component({
  selector: 'lib-survey-wrapper',
  template: `
    <div class="no-display">
      <mat-icon>close</mat-icon>
      <span class="material-icons up">keyboard_arrow_up</span>
      <span class="material-icons down">keyboard_arrow_down</span>
    </div>
    <router-outlet></router-outlet>
  `,
  styleUrls: []
})
export class SurveyWrapperComponent implements OnInit, OnDestroy {
  @HostBinding('class.loading') loading = true;
  @HostBinding('class.lib-survey-wrapper') isWrapped = true; // To extend preview functionality in the admin part
  subscription = new Subscription();

  constructor(
    public study: StudyFacade,
    private route: ActivatedRoute,
  ) {
    this.subscription.add(
      this.study.fullStudyLoading$.subscribe(isLoading => this.loading = isLoading)
    );
  }
  /**
   * Survey entrance
   */
  ngOnInit(): void {
    const routeParams = this.route.snapshot.params as ParamsIDs;
    const {procedure} = this.route.snapshot.queryParams as QueryParamsIDs;

    // console.log({handler: 'ngOnInit', ...routeParams, ...queryParams});
    cleanAbsentAnswersFromStorage();
    this.study.setParamsIDsFromScratch(routeParams);
    if (hasAllParamIDs(routeParams)) {
      this.study.loadStudyFull(routeParams);
      this.study.checkIfStudyAlreadyStarted();
    }
    if (isNonEmptyString(procedure)) {
      this.study.setActiveProcedure(procedure);
    }
 }

  ngOnDestroy() {
    // console.log({handler: 'ngOnDestroy'});
    this.study.cleanAllIDs();
    this.study.studyResetState();
    this.subscription.unsubscribe();
  }
}
