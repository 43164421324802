import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { pick } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { Answers, DatesFromProfileType, isRealValue } from '@tr-common';

import {
  AsyncMessageType,
  getControlValueToValidate,
  ParticipantDetails,
  StudyOptionValidator,
  StudyOptionValidatorType,
  WidgetValidationErrors,
} from '../../models';
import { selectAnswers, SurveyState } from '../store';

@Injectable({providedIn: 'root'})
export class WidgetValidatorsService {
  constructor(
    private store: Store<SurveyState>
  ) {}

  asyncValidator(studyValidator: StudyOptionValidator): AsyncValidatorFn {
    return (control: FormControl<unknown>): Observable<WidgetValidationErrors | null> => of(null).pipe(
      withLatestFrom(this.store.select(selectAnswers)),
      switchMap(([, answers]: [null, Answers]) => {
        const value = getControlValueToValidate(control);
        const matchCondition = isRealValue(value) && studyValidator.ifValueMatchesCondition(value, answers);
        const validatorType: AsyncMessageType = studyValidator.action === 'error' ? 'asyncErrors' : 'asyncWarnings';

        return of(matchCondition ? {[studyValidator.message]: validatorType} : null);
      })
    );
  }

  convert(validators: StudyOptionValidatorType[], participant: ParticipantDetails): AsyncValidatorFn[] {
    const datesFromProfile: Partial<DatesFromProfileType> = pick(participant, ['birthday', 'created_at']);

    return validators.map(v => this.asyncValidator(new StudyOptionValidator(v, datesFromProfile)));
  }
}
