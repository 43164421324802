import * as t from 'io-ts';

import { ValueOf } from './helpers';

export const privilegeSchema = t.type({
  id: t.string,
  title: t.string,
}, 'privilegeSchema');
export type PrivilegeType = t.TypeOf<typeof privilegeSchema>;

export const SubPrivileges = {
  normal: 'standard user',
  reconsent_pending: 'Adult is required to reassign account to the child',
  reconsent: 'Participant who received account from an adult',
  assent: 'Child 13 yo has to sign assent',
  pending: 'Pending User',
} as const;
export type SubPrivileges = ValueOf<typeof SubPrivileges>;

export const ParticipantState = {
  regular: 'regular',
  reconsent_pending: 'reconsent_pending',
  reconsent: 'reconsent',
  assent: 'assent',
  pending: 'pending'
} as const;
export const participantStateSchema = t.keyof(ParticipantState);
export type ParticipantState = t.TypeOf<typeof participantStateSchema>;

export interface DefaultPageForPrivilege {
  privilege: SubPrivileges;
  state: ParticipantState;
  url: string;
}

export const DefaultPagesByPrivilege: DefaultPageForPrivilege[] = [
  {privilege: SubPrivileges.normal, state: ParticipantState.regular, url: '/dashboard'},
  {privilege: SubPrivileges.pending, state: ParticipantState.pending, url: '/dashboard'},
  {privilege: SubPrivileges.reconsent_pending, state: ParticipantState.reconsent_pending, url: '/child-profile'},
  {privilege: SubPrivileges.reconsent, state: ParticipantState.reconsent, url: '/re-consent'},
  {privilege: SubPrivileges.assent, state: ParticipantState.assent, url: '/assent'}
];
