import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { DialogTypeConsent, TypeConsent } from '@tr-common';

import { surveyDialogDefaultOptions } from '../../models';

@Component({
  selector: 'lib-before-consent-assent-dialog',
  template: `
    <div class="tr-is-eligible-modal">
      <img src="/assets/modal-welcome.svg" alt="welcome consent">
      <div [ngSwitch]="data.consentType">
        <ng-container *ngSwitchCase="typeConsent.adult">
          <h3>Informed Consent</h3>
          <p>
            Informed consent is a process for getting your permission before you take part in research. In the following
            document, called the consent form, you will read all of the possible risks and benefits, including how we keep
            your data safe, by joining the T1D Exchange Registry.
          </p>
          <p>
            If you decide to take part in the Registry, you will be asked to enter your name and check “I agree to take
            part” before moving forward.
          </p>
        </ng-container>
        <!-- childAge < 12 -->
        <ng-container *ngSwitchCase="typeConsent.parental">
          <h3>Informed Consent</h3>
          <p>
            Informed consent is a process for getting your permission before you and your child take part in research. In
            the following document, called the consent form, you will read all of the possible risks and benefits, including
            how we keep your data safe, by joining the T1D Exchange Registry.
          </p>
          <p>
            If you decide to take part in the Registry, you will be asked to enter your name as the parent, or legal
            guardian, and check “I agree to take part” before you and your child can move forward.
          </p>
        </ng-container>
        <!-- childAge > 12 && childAge < 18 -->
        <ng-container *ngSwitchCase="typeConsent.child">
          <h3>Informed Consent and Assent</h3>
          <p>
            Informed consent and Assent is a process for getting you and your child’s permission before you take part in
            research. In the following documents, called the consent form for adults and the assent form for minors, you
            will read all of the possible risks and benefits, including how we keep your data safe, by joining the T1D
            Exchange Registry.
          </p>
          <p>
            If you decide to take part in the Registry, you will be asked to enter your name and check “I agree to take
            part” before moving forward. First we will ask the parent, or legal guardian, to read and sign the consent form.
            Then we will ask the minor to read and sign the assent form.
          </p>
        </ng-container>
      </div>
      <button class="dialog-button is-shadowed" mat-flat-button color="primary" [mat-dialog-close]="true">Continue</button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: surveyDialogDefaultOptions}],
  styleUrls: ['./before-consent-assent-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeforeConsentAssentDialogComponent {
  typeConsent = TypeConsent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogTypeConsent) {}
}
