import * as t from 'io-ts';
import { capitalize } from 'lodash';

import {
  isRealValue,
  moduleSettingsSchema,
  nullable,
  participantSourceSchema,
  ProcedureTemplateType,
  ProcedureTemplateTypes,
  procedureTemplateTypesSchema,
} from '@tr-common';

export const isPromotionalProcedure = (procedureType: ProcedureTemplateType): boolean => procedureType === ProcedureTemplateTypes.research;
export const isIntroOrPromotionalProcedure = (
  procedure: ProcedureTemplateType
): boolean => [ProcedureTemplateTypes.intro, ProcedureTemplateTypes.research].includes(procedure);
export const isScreeningProcedure = (
  procedureType: ProcedureTemplateType
): boolean => [ProcedureTemplateTypes.screening, ProcedureTemplateTypes.consent].includes(procedureType);

export const isSpecialProcedure = (
  procedure: ProcedureTemplateType
): boolean => [ProcedureTemplateTypes.intro, ProcedureTemplateTypes.consent, ProcedureTemplateTypes.research].includes(procedure);

export const procedureSchema = t.intersection([
  t.type({
    id: t.string,
    title: t.string,
    order: t.number,
    isIntro: t.boolean,
    isScreening: t.boolean,
    isConsent: t.boolean,
    isResearch: t.boolean,
    isSpecial: t.boolean,
  }),
  t.partial({
    created_at: nullable(t.string),
    enabled: t.boolean,
    study: t.string,
  })
], 'procedureSchema');
export type IProcedure = t.TypeOf<typeof procedureSchema>;
export type Procedures = IProcedure[];

export const beStudyProcedureSchema = t.intersection([
  t.type({
    procedure_id: procedureTemplateTypesSchema,
    title: t.string,
    order: t.number,
    created_at: t.string,
  }),
  t.partial({
    study_id: t.string,
  })
], 'beStudyProcedureSchema');
export type BEStudyProcedure = t.TypeOf<typeof beStudyProcedureSchema>

export const procedureStatusSchema = t.intersection([
  procedureSchema,
  t.type({
    nextProcedureTitle: nullable(t.string),
    isQuestionnaire: t.boolean,
    isResearch: t.boolean,
    hasQuestions: t.boolean,
    userID: t.string,
    userStudyID: t.string,
    setting: moduleSettingsSchema
  }),
  t.partial({
    backButtonTitle: t.string,
    source: participantSourceSchema,
    activeProcedure: procedureSchema,
    showBanner: t.boolean,
    submitPending: t.boolean
  })
], 'procedureStatusSchema');
export type ProcedureStatusType = t.TypeOf<typeof procedureStatusSchema>;

export const procedureTemplateSchema = t.type({
  id: t.string,
  title: t.string,
  order: t.number,
  created_at: t.string,
  updated_at: t.string,
}, 'procedureTemplateSchema');
export type IProcedureTemplate = t.TypeOf<typeof procedureTemplateSchema>;

export const studyProcedureSchema = t.intersection([
  t.type({
    id: procedureTemplateTypesSchema,
    order: t.number,
  }),
  t.partial({
    created_at: t.string,
    title: t.string,
  })
], 'studyProcedureSchema');
export type IStudyProcedure = t.TypeOf<typeof studyProcedureSchema>;

export const userStudyProcedureSchema = t.intersection([
  t.type({
    procedure_id: t.string,
    created_at: nullable(t.string),
    user_study_id: t.number,
    study_id: t.string,
  }),
  t.partial({
    user_study_is_eligible: nullable(t.boolean)
  })
], 'userStudyProcedureSchema');
export type UserStudyProcedureType = t.TypeOf<typeof userStudyProcedureSchema>

export interface ProcedurePdfDetails {
  studyName: string,
  procedureName: string,
  participantName: string,
}

export const makeTitle = (
  {studyName, procedureName, participantName}: ProcedurePdfDetails
): string => `T1D Exchange Registry's study "${studyName}", procedure: "${capitalize(procedureName)}", participant ${participantName}`;

export const makeFilename = (
  procedureName?: string
): string => `T1D The Registry: ${isRealValue(procedureName) ? procedureName + '-' : ''}answers.pdf`;
