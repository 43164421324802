import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-button-spinner',
  template: `
    <div class="lds-ring" [style.width]="size" [style.height]="size">
      <div [style.borderWidth]="borderWidth"></div>
      <div [style.borderWidth]="borderWidth"></div>
      <div [style.borderWidth]="borderWidth"></div>
      <div [style.borderWidth]="borderWidth"></div>
    </div>
  `,
  styleUrls: ['./button-spinner.component.scss']
})
export class ButtonSpinnerComponent {
  @Input() size = '30px';
  @Input() borderWidth = '3px';
}
