import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { ConsentModule } from '../consent/consent.module';
import { ContactInfoModule } from '../contact-info/contact-info.module';
import { GotoBackLinkModule } from '../goto-back-link/goto-back-link.module';
import { MaterialImportsModule } from '../material-imports.module';
import { ModuleSettingsModule } from '../module-settings/module-settings.module';
import { SurveyPipesModule } from '../pipes/survey-pipes.module';
import { TrLibModule } from '../tr-lib.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { AbsentAnswersComponent } from './components/absent-answers/absent-answers.component';
import { NextTooltipComponent } from './components/next-tooltip/next-tooltip.component';
import { QuestionListComponent } from './components/question-list/question-list.component';
import { QuestionWrapperComponent } from './components/question-wrapper/question-wrapper.component';
import { QuestionModule } from './components/question/question.module';
import { SignedConsentReviewComponent } from './components/signed-consent-review/signed-consent-review.component';
import { SurveyConsentComponent } from './components/survey-consent/survey-consent.component';
import { SurveyIntroWrapperComponent } from './components/survey-intro-wrapper/survey-intro-wrapper.component';
import { SurveyIntroComponent } from './components/survey-intro/survey-intro.component';
import { DialogQuestionListComponent } from './dialogs/dialog-question-list/dialog-question-list.component';
import { surveyDialogDefaultOptions } from './models';
import { ClosedStudyComponent } from './pages/closed-study/closed-study.component';
import { ConsentPreviewComponent } from './pages/consent-preview/consent-preview.component';
import { NotEligibleContentComponent } from './pages/not-eligible/not-eligible-content.component';
import { NotEligibleJaebContentComponent } from './pages/not-eligible/not-eligible-jaeb-content.component';
import { NotEligibleComponent } from './pages/not-eligible/not-eligible.component';
import { ProcedureConsentStatusComponent } from './pages/procedure-status/procedure-consent-status.component';
import { ProcedureResearchStatusComponent } from './pages/procedure-status/procedure-research-status.component';
import { ProcedureStatusComponent } from './pages/procedure-status/procedure-status.component';
import { ReviewAnswersComponent } from './pages/review-answers/review-answers.component';
import { SurveyWrapperComponent } from './pages/survey-wrapper/survey-wrapper.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { UnavailableConsentComponent } from './pages/unavailable-consent/unavailable-consent.component';
import { StudySurveyStoreModule } from './store/study-survey-store.module';
import { StudySurveyRoutingModule } from './study-survey-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    SurveyPipesModule,
    StudySurveyRoutingModule,
    ModuleSettingsModule,
    TrLibModule,
    ConsentModule,
    WidgetsModule,
    StudySurveyStoreModule,
    ContactInfoModule,
    GotoBackLinkModule,
    QuestionModule,
  ],
  declarations: [
    SurveyComponent,
    SurveyConsentComponent,
    ConsentPreviewComponent,
    QuestionListComponent,
    ProcedureStatusComponent,
    ReviewAnswersComponent,
    ProcedureConsentStatusComponent,
    ProcedureResearchStatusComponent,
    QuestionWrapperComponent,
    NextTooltipComponent,
    SurveyIntroComponent,
    ClosedStudyComponent,
    SurveyWrapperComponent,
    SurveyIntroWrapperComponent,
    NotEligibleComponent,
    NotEligibleContentComponent,
    NotEligibleJaebContentComponent,
    SignedConsentReviewComponent,
    UnavailableConsentComponent,
    AbsentAnswersComponent,
    DialogQuestionListComponent,
  ],
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: surveyDialogDefaultOptions}],
  exports: [
    SignedConsentReviewComponent,
    NotEligibleComponent,
    NotEligibleContentComponent,
    NotEligibleJaebContentComponent,
  ]
})
export class StudySurveyModule {}
