const userStageWording = {
  register: {
    header: 'Let\'s create <br/> your account',
    button: 'Sign Up',
    success_text: `You are now part of the research study, the T1D Exchange Registry.
      Together we can work towards the goal of improving the lives \n of people affected by type 1 diabetes.`,
    success_title: 'Success!<br/> Your account<br/> has been created!',
    password: 'Password',
    password_confirm: 'Confirm Password'
  },
  dashboard: {
    banner: `To begin, answer a few brief questions to determine your eligibility. If you are eligible, you'll then answer questions.`
  },
  not_eligible: {
    upper_text: `We are so sorry! Unfortunately, at this time do you not qualify to take part in the T1D Exchange Registry.
      If you would like to talk to a study team member, our contact information is below.`,
    lower_text: `T1D Exchange will keep your email address and responses in our database.
      If the eligibility criteria for the Registry changes, we will reach out to you.
      We thank you for your enthusiasm in contributing to T1D research!`
  },
  screening: {
    success_adult: `You are eligible to take part in the Registry!
      You will now move on to the informed consent.`,
    success_child: `Your child is eligible to take part in the Registry!
      You will now move on to the informed consent and assent.`,
    success_parental: `Your child is eligible to take part in the Registry!
      You will now move on to the parental informed consent.`
  }
};

export const sourceTextReplace = {
  jaeb: {
    register: {
      header: 'Let\'s confirm <br/> your account',
      button: 'Let\'s Go',
      success_text: `You are continuing your participation in the T1D Exchange Registry.
      Together we can work towards the goal of improving the lives \n of people affected by type 1 diabetes.
      There are a couple more steps to confirm your information, click on "Let's get started" to begin!`,
      success_title: 'Success!<br/> Your account<br/> has been confirmed!',
      password: 'New Password',
      password_confirm: 'Retype Password'
    },
    dashboard: {
      banner: `To begin, answer a few brief questions to confirm your information. Then, you'll be asked to answer questions.`
    },
    not_eligible: {
      upper_text: 'Whoops! It looks like the information you entered does not match our records.',
      lower_text: `Please contact a study team member using the information below for assistance!
        If you decide not to contact us, T1D Exchange will keep your email address and responses in our database.
        If the eligibility criteria for the Registry changes, we will reach out to you.
        We thank you for your enthusiasm in contributing to T1D research!`
    },
    screening: {
      success_adult: `You are one step closer to influencing the future of type 1 diabetes research and care!
      You will now move on to review your informed consent.`,
      success_child: `Your child is one step closer to influencing the future of type 1 diabetes research and care!
      You will now move on to review your informed consent and assent.`,
      success_parental: `Your child is one step closer to influencing the future of type 1 diabetes research and care!
      You will now move on to review your parental informed consent.`
    }
  },
  registry: {...userStageWording},
  registry_jaeb: {...userStageWording},
};
