/* eslint-disable @typescript-eslint/member-ordering */

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NgControl, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Memoize } from 'lodash-decorators';
import { Subject, Subscription } from 'rxjs';

import { isEmptyValue, isNonEmptyArray, isNonEmptyString, isRealValue, phoneLabel } from '@tr-common';

import {
  CountryInfo,
  createPhoneInputFormGroup,
  defaultPlaceholder,
  findCodeInInfo,
  freshPhoneInputGroup,
  isKeyAllowedForPhoneNumber,
  isKeyNumberOrPlus,
  normalizePhoneNumber,
  PhoneInputFormGroup,
  splitInternationalTelNumber,
} from '../models';

// noinspection JSUnusedGlobalSymbols
@Component({
  selector: 'lib-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: MatFormFieldControl, useExisting: PhoneInputComponent}],
})
export class PhoneInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnChanges, OnInit, OnDestroy {
  static nextId = 0;
  @ViewChild('phoneNumber', {static: true}) phoneNumberInput: ElementRef;
  @ViewChild('matSelect', {static: true}) matSelect: MatSelect;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @Input() validity: boolean;
  @HostBinding('attr.aria-describedby') describedBy = '';
  id = `phone-field-${PhoneInputComponent.nextId++}`;
  parts: FormGroup<PhoneInputFormGroup> = createPhoneInputFormGroup();
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType?: string;
  autofilled?: boolean;
  subscription = new Subscription();
  onChange: (arg: string) => void;
  onTouched: () => void;
  readonly phoneLabel = phoneLabel;
  private _codes: CountryInfo[];
  private _placeholder: string;
  private _disabled = false;
  private _required = false;
  private _timeouts: number[] = [];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    if (isRealValue(ngControl)) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    this.subscription.add(
      this.parts.valueChanges.subscribe(value => {
        const normalizedValue = normalizePhoneNumber(value, this.codes);

        if (isRealValue(normalizedValue)) this.parts.setValue(normalizedValue, {emitEvent: false});
        if (typeof this.onChange === 'function') this.onChange(this.value);
      })
    );
  }

  @HostBinding('class.floating') get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get countryCode(): FormControl<string> {
    return this.parts.controls.countryCode;
  }

  get phoneNumber(): FormControl<string> {
    return this.parts.controls.phoneNumber;
  }

  get errorState(): boolean {
    let needInvalidate = true;

    if (isRealValue(this.errorStateMatcher)) {
      const control = this.ngControl.control as UntypedFormControl;
      const form = isRealValue(this._parentFormGroup) ? this._parentFormGroup : this._parentForm;

      needInvalidate = Boolean(this.errorStateMatcher.isErrorState(control, form));
    }

    return needInvalidate && isRealValue(this.ngControl.errors);
  }

  get empty(): boolean {
    return isEmptyValue(this.countryCode.value) && isEmptyValue(this.phoneNumber.value);
  }

  get notEmpty(): boolean {
    return isNonEmptyString(this.countryCode.value) || isNonEmptyString(this.phoneNumber.value);
  }

  get value(): string | null {
    const {phoneNumber, countryCode} = this.parts.value;

    return this.empty ? null : `${isNonEmptyString(countryCode) ? countryCode : ''}${isNonEmptyString(phoneNumber) ? phoneNumber : ''}`;
  }
  @Input() set value(tel: string | null) {
    this.parts.setValue(splitInternationalTelNumber(tel, this.codes));
  }

  get isPlaceholderHasToShow(): boolean {
    return !(isNonEmptyString(this.describedBy) && !/error/.test(this.describedBy));
  }

  get placeholder(): string {
    return this.isPlaceholderHasToShow ? this._placeholder ?? defaultPlaceholder : null;
  }
  @Input() set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input() get codes(): CountryInfo[] {
    return this._codes;
  }
  set codes(value: CountryInfo[]) {
    this._codes = value;
  }

  @Input() get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input() get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @HostListener('body:scroll') bodyScroll = () => this.blurFocus();
  @HostListener('window:scroll') windowScroll = () => this.blurFocus();

  ngOnChanges({codes}: SimpleChanges): void {
    if (isRealValue(codes) && isRealValue(this.value)) {
      this.parts.setValue(splitInternationalTelNumber(this.value, this.codes), {emitEvent: false});
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.fm.monitor(this.elRef, true).subscribe(origin => {
        this.focused = isRealValue(origin);
        this.stateChanges.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setDisabledState(value: boolean): void {
    this.disabled = value;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (arg: string) => void): void {
    this.onChange = fn;
  }
  // ControlValueAccessor Interface and mutator
  propagateChange = (): void => {}; // Don't remove this method from this component

  writeValue(value: string): void {
    this.value = value;
  }

  clearPhoneField(): void {
    this.parts.setValue(freshPhoneInputGroup());
  }

  @Memoize() getCountryNameFromCode(countryPhoneNumber: string): string {
    return isNonEmptyArray(this.codes) ? findCodeInInfo(countryPhoneNumber, this.codes)?.country : null;
  }

  @Memoize() getIsoFromPhone(countryPhoneNumber: string): string {
    return isNonEmptyArray(this.codes) ? findCodeInInfo(countryPhoneNumber, this.codes)?.iso : null;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    if (!this.focused) {
      this.focused = true;
      this.phoneNumberInput.nativeElement.focus();
      this.stateChanges.next();
    }
  }

  checkPhonePattern(event: KeyboardEvent): boolean {
    let isAllowed = isKeyAllowedForPhoneNumber(event);

    if (event.key === 'Backspace' && isEmptyValue(this.phoneNumber.value)) {
      this.parts.setValue(freshPhoneInputGroup());
    }
    if (isAllowed && isKeyNumberOrPlus(event.key)) {
      isAllowed = isEmptyValue(this.phoneNumber.value) || /[0-9]/.test(event.key);
    }
    if (!isAllowed) event.preventDefault();

    return isAllowed;
  }

  onFocusOut(event: FocusEvent): void {
    if (!this.elRef.nativeElement.contains(event.relatedTarget as Element)) {
      const handler = setTimeout(() => {
        this.focused = this.matSelect.panelOpen;
        this.onTouched();
        this.touched = true;
        this.stateChanges.next();
      }, 300) as unknown;

      this._timeouts.push(handler as number);
    }
  }

  inputFocusin(): void {
    if (isNonEmptyArray(this._timeouts)) {
      this._timeouts.forEach(tm => clearInterval(tm));
      this._timeouts = [];
    }
  }

  selectOpened(): void {
    this.focused = true;
    this.inputFocusin();
  }

  private blurFocus() {
    if (this.matSelect.panelOpen) this.matSelect.close();
    /* TODO Find another solution to lose focus for the input field during only participant's scrolling (not keyboard one)
    if (this.phoneNumberInput && document.activeElement.ariaLabel === this.phoneLabel) {
      this.phoneNumberInput.nativeElement.blur();
    }
    */
  }
}
