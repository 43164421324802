import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const countryCodePattern = '^\\+\\d';

export const countryCodeValidator = (): ValidatorFn => (control: AbstractControl): ValidationErrors => {
  const {value} = control;
  const isPassed = value ? new RegExp(countryCodePattern).test(value) : false;

  return (!value || isPassed) ? null : {countryError: 'Missing country code'};
};
