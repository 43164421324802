import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  BaseChildProfileType,
  ChangePassPayload,
  filterRealValues,
  isNonEmptyArray,
  ParticipantProfile,
  profileType,
  ServerValidationErrors,
  TimeZone,
  UserHouseholdType,
  UserNotification,
} from 'tr-lib';

import { AppState, ParticipantNotificationUpdateType, sourcesToComponent } from '@app/model';
import { getWarning } from '@app/auth/models';
import {
  changePass,
  cleanPhoneErrorState,
  deactivateAccount,
  getUser,
  resetErrorState,
  smartEmailCheck,
  toggleNotificationBanner,
  updateProfileViaEmailCheck,
} from '@store/actions';
import { ProfileFullState, WithdrawalReasons } from '@app/user-profile/model';
import {
  selectBannersVisibility,
  selectChangePassError,
  selectChangePasswordSuccess,
  selectCheckEmail,
  selectHouseholdError,
  selectIsOAuthMethodPresented,
  selectNotificationsOn,
  selectPersonalInfo,
  selectProfileChildError,
  selectProfileError,
  selectProfileLoginMethods,
  selectUserProfileType,
} from '@store/selectors';

import { selectAdditionalInfo, selectStatesOfUSA, selectTimeZones } from './profile.selectors';
import { getListOfUSA, getTimezones, toggleNotification, updateChildInfo, updateHouseholdInfo, updateProfile } from './profile.actions';

@Injectable({providedIn: 'root'})
export class ProfileFacade {
  bannersVisibility$ = this.store.select(selectBannersVisibility);
  timeZones$: Observable<TimeZone> = this.store.select(selectTimeZones);
  emailChangeHint$ = this.store.select(selectIsOAuthMethodPresented);
  passwordHint$ = this.store.select(selectProfileLoginMethods).pipe(map(methods => !methods.includes('native')));
  profileFullState$: Observable<ProfileFullState> = combineLatest([
    this.store.select(selectPersonalInfo),
    this.store.select(selectAdditionalInfo),
    this.store.select(selectStatesOfUSA).pipe(filter(isNonEmptyArray)),
    this.store.select(selectProfileLoginMethods),
    this.store.select(selectNotificationsOn).pipe(filterRealValues()),
    this.store.select(selectUserProfileType)
  ]).pipe(
    map(([
      info, {householdInfo, childInfo}, states, auth_methods, settings, profile
    ]) => ({info, householdInfo, childInfo, states, auth_methods, settings, isChild: profile === profileType.child})),
  );
  profileError$ = this.store.select(selectProfileError);
  householdError$ = this.store.select(selectHouseholdError);
  childInfoError$ = this.store.select(selectProfileChildError);
  changePassError$: Observable<ServerValidationErrors<ChangePassPayload>> = this.store.select(selectChangePassError);
  changePasswordSuccess$ = this.store.select(selectChangePasswordSuccess).pipe(filter(Boolean));
  checkEmail$ = this.store.select(selectCheckEmail).pipe(map(getWarning));

  constructor(private store: Store<AppState>) {}

  getFullInfo(): void {
    this.store.dispatch(getUser({loadChildInfo: true}));
    this.store.dispatch(getListOfUSA());
    this.store.dispatch(getTimezones());
  }

  refreshUserInfo(): void {
    this.store.dispatch(getUser({source: sourcesToComponent.dashboard, loadChildInfo: true, checkSmsConsent: true}));
    this.resetErrorState();
  }

  cleanPhoneErrorState(): void {
    this.store.dispatch(cleanPhoneErrorState());
  }

  resetErrorState(): void {
    this.store.dispatch(resetErrorState());
  }

  toggleNotificationBanner(notification: UserNotification): void {
    this.store.dispatch(toggleNotificationBanner({notification}));
  }

  smartEmailCheck(payload: string): void {
    this.store.dispatch(smartEmailCheck({payload}));
  }

  deactivateAccount(payload: WithdrawalReasons): void {
    this.store.dispatch(deactivateAccount({payload}));
  }

  toggleNotification(payload: ParticipantNotificationUpdateType): void {
    this.store.dispatch(toggleNotification({payload}));
  }

  updateChildInfo(payload: Partial<BaseChildProfileType>): void {
    this.store.dispatch(updateChildInfo({payload}));
  }

  updateHouseholdInfo(payload: UserHouseholdType): void {
    this.store.dispatch(updateHouseholdInfo({payload}));
  }

  updateProfileViaEmailCheck(checkEmail: boolean, payload: Partial<ParticipantProfile>): void {
    this.store.dispatch(checkEmail ? updateProfileViaEmailCheck({payload}) : updateProfile({payload}));
  }

  updatePassword(payload: Partial<ChangePassPayload>): void {
    this.store.dispatch(changePass({payload}));
  }
}
