import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PositionType } from '@tr-common';

import { finishAnsweringTooltip } from '../../models';

@Component({
  selector: 'lib-next-tooltip',
  template: `
    <div class="tooltip-wrapper" [ngClass]="position" (click)="close.emit()">
      <mat-icon>close</mat-icon>
      <span class="tooltip-text">{{ message }}</span>
    </div>
  `,
  styleUrls: ['./next-tooltip.component.scss']
})
export class NextTooltipComponent {
  @Input() position: PositionType = 'right';
  @Input() message = finishAnsweringTooltip;
  @Output() close = new EventEmitter();
}
