import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { RegistryStateUrlSerializer, studyConsentProperty, studySurveyProperty } from 'tr-lib';

import { UserNotificationsService } from '@app/user-profile/services/user-notifications.service';
import { AuthEffects } from '@store/effects/auth.effects';
import { loaderReducer } from '@store/reducers/loader.reducer';
import { resetPasswordProperty, resetPasswordReducer } from '@store/reducers/reset-password.reducer';
import { InterceptorEffects, UserEffects } from '@store/effects';
import { UserRouterService } from '@services/user.router.service';

// noinspection ES6PreferShortImport
import { environment } from '../../environments/environment';
import { errorReducer, userReducer } from './reducers';
import { errorStoreProperty } from './selectors';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({router: routerReducer}, {
      runtimeChecks: {strictStateImmutability: true, strictActionImmutability: true}
    }),
    EffectsModule.forRoot([UserEffects, AuthEffects, InterceptorEffects,]),
    StoreRouterConnectingModule.forRoot(
      {
        serializer: RegistryStateUrlSerializer,
        navigationActionTiming: NavigationActionTiming.PostActivation, // router actions after guards, resolvers etc
      }
    ),
    StoreModule.forFeature(studySurveyProperty, state => state), // It replaces by StudySurveyModule
    StoreModule.forFeature(studyConsentProperty, state => state), // It replaces by StudySurveyModule
    StoreModule.forFeature('user', userReducer),
    StoreModule.forFeature(errorStoreProperty, errorReducer),
    StoreModule.forFeature('loader', loaderReducer),
    StoreModule.forFeature(resetPasswordProperty, resetPasswordReducer),
    StoreDevtoolsModule.instrument({maxAge: 55, logOnly: environment.production}),
  ],
  declarations: [],
  providers: [UserRouterService, UserNotificationsService]
})
export class RegistryStoreModule {}
