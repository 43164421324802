import { createAction, props } from '@ngrx/store';

import { SetNewPassPayload } from 'tr-lib';

export enum resetPasswordActions {
  RESET_PASSWORD = '[Reset Password] Reset Password',
  RESET_PASSWORD_SUCCESS = '[Reset Password] Reset Password Success',
  CHECK_TOKEN = '[Reset Password] Check Token',
  CHECK_TOKEN_SUCCESS = '[Reset Password] Check Token Success',
  SET_NEW_PASSWORD = '[Reset Password] Set New Password',
  SET_NEW_PASSWORD_SUCCESS = '[Reset Password] Set New Password Success',
  SET_INITIAL_STATE = '[Reset Password] Set Initial State'
}

export const checkToken = createAction(
  resetPasswordActions.CHECK_TOKEN,
  props<{payload: string}>()
);

export const checkTokenSuccess = createAction(
  resetPasswordActions.CHECK_TOKEN_SUCCESS
);

export const resetPassword = createAction(
  resetPasswordActions.RESET_PASSWORD,
  props<{payload: string}>()
);

export const resetPasswordSuccess = createAction(
  resetPasswordActions.RESET_PASSWORD_SUCCESS
);

export const setNewPass = createAction(
  resetPasswordActions.SET_NEW_PASSWORD,
  props<{payload: SetNewPassPayload}>()
);

export const setNewPassSuccess = createAction(
  resetPasswordActions.SET_NEW_PASSWORD_SUCCESS
);

export const setPasswordInitialState = createAction(
  resetPasswordActions.SET_INITIAL_STATE
);
