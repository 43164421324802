import { Component, ViewEncapsulation } from '@angular/core';

import { BaseCheckboxRadioWithTextComponent } from '../../helpers';

@Component({
  selector: 'lib-radio-with-text-widget',
  template: `
    <mat-radio-group class="mbe-10" [value]="data.selected" (change)="onChange($event.value)">
      <mat-radio-button color="primary" [aria-label]="option.title" [value]="true">
        <span [innerHTML]="option.title"></span>
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="outline" class="text-input">
      <input type="text" autocomplete="off" matInput [formControl]="formControl" />
    </mat-form-field>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./radio-with-text-widget.component.scss']
})
export class RadioWithTextWidgetComponent extends BaseCheckboxRadioWithTextComponent {
  constructor() {
    super();
  }
}
