<footer class="footer" [class.dashboard]="isDashboard">
  <div class="footer-block">
    <div class="footer-item">
      <a href="{{ contactInfo.site.href }}"><img src="/assets/img/t1d_logo_btn.svg" alt="#"></a>
      <address>
        {{ contactInfo.line1 }}<br/>
        {{ contactInfo.line2 }}<br/>
        Phone: {{ contactInfo.phone }}<br/>
        Email: {{ contactInfo.email }}
      </address>
    </div>

    <div class="flex gap-20 footer-item l-line" *ngIf="!noNavButtons" [ngClass]="{'jaeb-mode': isJAEBMode}">
      <ng-container *ngIf="!isLoggedIn else loggedIn">
        <a routerLink="/faq">FAQ</a>
        <a routerLink="/auth/login">Sign In</a>
        <a routerLink="/auth/register">Sign Up</a>
      </ng-container>

      <ng-template #loggedIn>
        <a routerLink="/user-profile">My Profile</a>
        <a (click)="onLogout()">Logout</a>
      </ng-template>

      <div class="flex gap-20">
        <a [href]="termOfUseUrl" target="_blank">Terms of Use</a>
        <a [href]="privacyPolicyUrl" target="_blank">Privacy Policy</a>
      </div>
    </div>
  </div>

  <div class="footer-block">
    <a mat-flat-button class="is-with-icon is-rounded" href="https://www.facebook.com/T1DExchange/" target="_blank"
      rel="noopener"><mat-icon svgIcon="facebook"></mat-icon>
    </a>
    <a mat-flat-button class="is-with-icon is-rounded" href="https://twitter.com/T1DExchange" target="_blank"
      rel="noopener"><mat-icon svgIcon="twitter"></mat-icon>
    </a>
    <a mat-flat-button class="is-with-icon is-rounded" href="https://www.instagram.com/t1dexchange/" target="_blank"
      rel="noopener"><mat-icon svgIcon="instagram"></mat-icon>
    </a>
    <a mat-flat-button class="is-with-icon is-rounded" href="https://www.linkedin.com/company/t1d-exchange/" target="_blank"
      rel="noopener"><mat-icon svgIcon="linkedin"></mat-icon>
    </a>
  </div>
</footer>
