import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { groupBy } from 'lodash';

import {
  failSnackBottomConstantConfig,
  getFileNameFromHttpHeaders,
  isRealValue,
  ProcedureTemplateTypes,
  somethingWentWrong,
} from '@tr-common';

import {
  AllIDs,
  defineIfErrorHasAbsentAnswers,
  getErrorWithAbsentAnswers,
  getParentQuestions,
  LoadFullStudySourcesType,
  ParamsIDs,
  participantIsFromJAEBFilter,
  saveAbsentQuestions,
} from '../../models';
import { SurveyPdfService } from '../../services/survey-pdf.service';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import { SurveyService } from '../../services/survey.service';
import {
  defineEligibleDialog,
  finishSubmitPending,
  getPDFOfSubmittedAnswers,
  getPDFOfSubmittedAnswersFail,
  getPDFOfSubmittedAnswersSuccess,
  getSubmittedAnswers,
  getSubmittedAnswersFail,
  getSubmittedAnswersSuccess,
  loadAnswers,
  loadStudyFull,
  loadStudyFullStage2,
  navigateToQuestionProcedure,
  nextProcedure,
  redirectToProcedureStatus,
  refreshHeaderWithProcedures,
  refreshStudyQuestions,
  refreshStudyQuestionsFail,
  refreshStudyQuestionsSuccess,
  selectActiveProcedureQuestions,
  selectNextActiveProcedure,
  selectParamsIDs,
  selectParticipantDetails,
  selectUrlProcedure,
  showConclusionDialog,
  startRefreshPending,
  submitConsentProcedureForcibly,
  submitProcedure,
  submitProcedureAbsentAnswers,
  submitProcedureFail,
  submitProcedureSuccess,
} from '../index';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class ProcedureSubmitEffects {
  actions$ = new Subject<Action>();

  refreshStudyQuestions$ = createEffect(() => this.actions$.pipe(
    ofType(refreshStudyQuestions),
    withLatestFrom(
      this.store.select(selectParticipantDetails),
      this.store.select(selectParamsIDs)
    ),
    switchMap(([, {profile_type}, {studyID}]) => this.surveyService.loadQuestions(profile_type, studyID).pipe(
      map(response => refreshStudyQuestionsSuccess({payload: groupBy(response, 'procedure')})),
      catchError(({error}) => of(refreshStudyQuestionsFail({error})))
    ))
  ));

  submitProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedure),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{id, userID, hasConclusionPopup}, {userStudyID}]) => this.surveyService.submitProcedure(userID, userStudyID, id).pipe(
      tap(() => !hasConclusionPopup && this.store.dispatch(finishSubmitPending())),
      map(payload => submitProcedureSuccess({payload})),
      catchError(({error}) => of(defineIfErrorHasAbsentAnswers(error)
        ? submitProcedureAbsentAnswers({error, procedure: id})
        : submitProcedureFail({error})
      )),
    )),
  ));

  submitConsentProcedureForcibly$ = createEffect(() => this.actions$.pipe(
    ofType(submitConsentProcedureForcibly),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([, {userStudyID, userID, studyID}]) => this.surveyService.submitProcedure(
      userID, userStudyID, ProcedureTemplateTypes.consent
    ).pipe(
      concatMap(() => [finishSubmitPending(), navigateToQuestionProcedure({studyID, userStudyID, userID})]),
      catchError(({error}) => of(submitProcedureFail({error})))
    ))
  ));

  navigateToQuestionProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(navigateToQuestionProcedure),
    tap(({userStudyID, userID, studyID}) => {
      this.surveyRouter.navigateToQuestionProcedure({studyID, userStudyID, userID});
    })
  ), {dispatch: false});

  redirectToProcedureStatus$ = createEffect(() => this.actions$.pipe(
      ofType(redirectToProcedureStatus),
      withLatestFrom(this.store.select(selectParamsIDs)),
      tap(([{procedure}, paramsIDs]) => void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure))
  ), {dispatch: false});

  submitProcedureFail$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedureFail),
    tap(({error}) => this.snackBar.open(error.toString(), 'X', failSnackBottomConstantConfig())),
    map(() => finishSubmitPending())
  ));

  submitProcedureAbsentAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedureAbsentAnswers),
    withLatestFrom(this.store.select(selectActiveProcedureQuestions)),
    map((
      [{procedure, error}, questions]
    ) => ({procedure, absentQuestions: getParentQuestions(getErrorWithAbsentAnswers(error), questions)})),
    tap(({absentQuestions}) => saveAbsentQuestions(absentQuestions)),
    // tap((data) => console.log({handler: 'submitProcedureAbsentAnswers$', ...data, storage: sessionStorage.getItem(absentAnswerKey)})),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectParticipantDetails)),
    tap(([{procedure, absentQuestions: [questionID]}, paramsIDs]) => void this.surveyRouter.navigateToQuestionFromSubmit(paramsIDs, procedure, questionID)),
    concatMap(([, {userID, studyID, userStudyID}, participant]) => [
      finishSubmitPending(),
      startRefreshPending(),
      loadAnswers({userStudyID, participantID: userID}),
      loadStudyFullStage2({userID, studyID, userStudyID, sourceType: 'status', participant})
    ])
  ));

  submitProcedureSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(submitProcedureSuccess),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectNextActiveProcedure),
      this.store.select(selectUrlProcedure),
      this.store.pipe(participantIsFromJAEBFilter()),
    ),
    tap(([{payload}, {userID, studyID, userStudyID}, upcomingProcedure, procedure,  isJaeb]) => {
      const procedureId = (isRealValue(upcomingProcedure) && isJaeb) ? upcomingProcedure.id : procedure;
      const paramsIDs: ParamsIDs = {userID, studyID, userStudyID};
      const props = {...paramsIDs, sourceType: procedure as LoadFullStudySourcesType};
      const isEligible = payload.user_study_is_eligible;

      switch (procedure) {
        case ProcedureTemplateTypes.intro:
          this.store.dispatch(finishSubmitPending());
          this.store.dispatch(refreshHeaderWithProcedures());
          this.store.dispatch(nextProcedure());
          break;
        case ProcedureTemplateTypes.screening:
          // check eligibility only after screening submit
          this.store.dispatch(isEligible ? defineEligibleDialog({payload: paramsIDs}) : loadStudyFull(props));
          void this.surveyRouter.redirectToProcedureStatus(paramsIDs, procedure);
          break;
        case ProcedureTemplateTypes.consent:
          this.store.dispatch(finishSubmitPending());
          void this.surveyRouter.navigateToProcedurePreservingParams(
            {...props, isStatus: !isJaeb, procedureId}
          );
          this.store.dispatch(loadStudyFull(props));
          break;
        default: // ProcedureTemplateTypes.questionnaire and so on
          if (payload.procedure_id === procedure) {
            this.store.dispatch(showConclusionDialog());
          }
          this.store.dispatch(finishSubmitPending());
          this.store.dispatch(loadStudyFull(props));
          void this.surveyRouter.redirectToProcedureStatus(
            paramsIDs, procedure, procedure === ProcedureTemplateTypes.questionnaire
          );
          break;
      }
    }),
  ), {dispatch: false});

  getSubmittedAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(getSubmittedAnswers),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectUrlProcedure)),
    map(([, {userStudyID, userID}, procedure]: [unknown, AllIDs, string]) => ({userStudyID, userID, procedure})),
    switchMap(({userStudyID, userID, procedure}) => this.surveyPdf.loadHTMLVersion(userID, userStudyID, procedure).pipe(
      map(payload => getSubmittedAnswersSuccess({payload})),
      catchError(({error}) => of(getSubmittedAnswersFail({error})))
    ))
  ));

  getPDFOfSubmittedAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(getPDFOfSubmittedAnswers),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectUrlProcedure)),
    map((
      [{toPrint}, {userStudyID, userID}, procedure]: [{toPrint: boolean}, AllIDs, string]
    ) => ({userStudyID, userID, procedure: procedure ?? ProcedureTemplateTypes.screening, toPrint})),
    switchMap(({toPrint, userStudyID, userID, procedure}) => this.surveyPdf.loadPDFVersion(userID, userStudyID, procedure).pipe(
      map(({body, headers}: HttpResponse<Blob>) => ({payload: body, filename: getFileNameFromHttpHeaders(headers)})),
      map(({payload, filename}) => getPDFOfSubmittedAnswersSuccess({payload, filename, toPrint})),
      catchError(({error}) => of(getPDFOfSubmittedAnswersFail({error})))
    ))
  ));

  getPDFOfSubmittedAnswersSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getPDFOfSubmittedAnswersSuccess),
    tap(({payload, filename, toPrint}) => toPrint
      ? this.surveyPdf.printPDFVersion(payload)
      : this.surveyPdf.downloadPDFVersion(filename, payload)
    )
  ), {dispatch: false});

  getFail$ = createEffect(() => this.actions$.pipe(
    ofType(getSubmittedAnswersFail, getPDFOfSubmittedAnswersFail, refreshStudyQuestionsFail),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig())),
  ), {dispatch: false});

  constructor(
    public surveyRouter: SurveyRouter,
    public store: Store<any>,
    public surveyService: SurveyService,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private surveyPdf: SurveyPdfService
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }
}
