import { createAction, props } from '@ngrx/store';

import { BaseEventType } from 'tr-lib';

import { Achievement, AllUsersStudiesStatuses, DashboardStudyStatusType, LastViewedType, MissedStudyType, StudyDownloadParams, StudyReviewParams } from '@app/dashboard/models';

export enum dashboardActions {
  RESET_STATE = '[DASHBOARD] Reset this store',
  GET_ALL_USERS_STUDIES = '[DASHBOARD] Get all the user\'s studies',
  GET_ALL_USERS_STUDIES_SUCCESS = '[DASHBOARD] Got all the user\'s studies success',
  GET_ALL_USERS_STUDIES_FAIL = '[DASHBOARD] Got all the user\'s studies fail',
  GET_BEGUN_USER_STUDIES = '[DASHBOARD] Get studies which were a participant already started',
  GET_BEGUN_USER_STUDIES_SUCCESS = '[DASHBOARD] Got studies which were a participant already started success',
  GET_BEGUN_USER_STUDIES_FAIL = '[DASHBOARD] Got studies which were a participant already started fail',
  REFRESH_USERS_STUDIES = '[DASHBOARD] Refresh user studies to filter on dashboard',
  LOAD_ACHIEVEMENTS = '[DASHBOARD] Load Achievements',
  LOAD_ACHIEVEMENTS_FAIL = '[DASHBOARD] Load Achievements Fail',
  LOAD_ACHIEVEMENTS_SUCCESS = '[DASHBOARD] Load Achievements Success',
  LOAD_JAEB_INTRO_EVENTS = '[DASHBOARD] Load Jaeb Intro Events',
  LOAD_JAEB_INTRO_EVENTS_SUCCESS = '[DASHBOARD] Load Jaeb Intro Events Success',
  LOAD_JAEB_INTRO_EVENTS_FAIL = '[DASHBOARD] Load Jaeb Intro Events Fail',
  SEND_JAEB_INTRO_EVENT = '[DASHBOARD] Send Jaeb Intro Event',
  SEND_JAEB_INTRO_EVENT_SUCCESS = '[DASHBOARD] Send Jaeb Intro Event Success',
  SEND_JAEB_INTRO_EVENT_FAIL = '[DASHBOARD] Send Jaeb Intro Event Fail',
  LOAD_MISSED_STUDIES = '[DASHBOARD] Load Missed Studies',
  LOAD_MISSED_STUDIES_SUCCESS = '[DASHBOARD] Load Missed Studies Success',
  LOAD_MISSED_STUDIES_FAIL = '[DASHBOARD] Load Missed Studies Fail',
  LOAD_NOT_READ_MISSED_STUDIES = '[DASHBOARD] Load Not Read Missed Studies',
  LOAD_NOT_READ_MISSED_STUDIES_SUCCESS = '[DASHBOARD] Load Not Read Missed Studies Success',
  LOAD_NOT_READ_MISSED_STUDIES_FAIL = '[DASHBOARD] Load Not Read Missed Studies Fail',
  MARK_MISSED_STUDIES_AS_READ = '[DASHBOARD] Mark missed studies as already read',
  MARK_MISSED_STUDIES_AS_READ_SUCCESS = '[DASHBOARD] Marked missed studies as already read success',
  MARK_MISSED_STUDIES_AS_READ_FAIL = '[DASHBOARD] Marked missed studies as already read fail',
  MARK_STUDY_AS_VIEWED = '[DASHBOARD] Mark a study as viewed',
  MARK_STUDY_AS_VIEWED_SUCCESS = '[DASHBOARD] Marked the study as viewed success',
  MARK_STUDY_AS_VIEWED_FAIL = '[DASHBOARD] Marked the study as viewed fail',
  REACTIVATE_STUDY_REQUEST = '[DASHBOARD] Request Reactivating Study',
  REACTIVATE_STUDY = '[DASHBOARD] Reactivate Study',
  REACTIVATE_STUDY_FAIL = '[DASHBOARD] Reactivated Study Fail',
  REVIEW_ANSWERS_DASHBOARD = '[DASHBOARD] Review answers',
  GET_PDF_OF_SUBMITTED_ANSWERS = '[DASHBOARD] Get PDF version of submitted answers for the current procedure',
  GET_PDF_OF_SUBMITTED_ANSWERS_SUCCESS = '[DASHBOARD] Got PDF version of submitted answers for the current procedure success',
  GET_PDF_OF_SUBMITTED_ANSWERS_FAIL = '[DASHBOARD] Got PDF version of submitted answers for the current procedure fail',
  GET_SUBMITTED_ANSWERS = '[DASHBOARD] Get submitted answers for the current procedure',
  GET_SUBMITTED_ANSWERS_SUCCESS = '[DASHBOARD] Got submitted answers for the current procedure success',
  GET_SUBMITTED_ANSWERS_FAIL = '[DASHBOARD] Got submitted answers for the current procedure fail',
  GET_QUESTION_PROCEDURE_SUCCESS = '[DASHBOARD] Got study dashboard review procedure success',
  GET_QUESTION_PROCEDURE_FAIL = '[DASHBOARD] Got study dashboard review procedure fail',
}

export const resetState = createAction(
  dashboardActions.RESET_STATE
);

export const getUserStudies = createAction(
  dashboardActions.GET_ALL_USERS_STUDIES
);

export const getAllUsersStudiesSuccess = createAction(
  dashboardActions.GET_ALL_USERS_STUDIES_SUCCESS,
  props<AllUsersStudiesStatuses>()
);

export const getAllUsersStudiesFail = createAction(
  dashboardActions.GET_ALL_USERS_STUDIES_FAIL,
  props<{error: unknown}>()
);

export const getBegunUserStudies = createAction(
  dashboardActions.GET_BEGUN_USER_STUDIES,
  props<{id: string}>()
);

export const getBegunUserStudiesSuccess = createAction(
  dashboardActions.GET_BEGUN_USER_STUDIES_SUCCESS,
  props<{payload: number[]}>()
);

export const getBegunUserStudiesFail = createAction(
  dashboardActions.GET_BEGUN_USER_STUDIES_FAIL,
  props<{error: unknown}>()
);

export const refreshUserStudies = createAction(
  dashboardActions.REFRESH_USERS_STUDIES,
  props<{payload: DashboardStudyStatusType}>()
);

export const loadAchievements = createAction(
  dashboardActions.LOAD_ACHIEVEMENTS,
  props<{userId: string}>()
);

export const loadAchievementsFail = createAction(
  dashboardActions.LOAD_ACHIEVEMENTS_FAIL,
  props<{error: unknown}>()
);

export const loadAchievementsSuccess = createAction(
  dashboardActions.LOAD_ACHIEVEMENTS_SUCCESS,
  props<{achievements: Achievement[]}>()
);

export const loadJaebIntroEvents = createAction(
  dashboardActions.LOAD_JAEB_INTRO_EVENTS,
);

export const loadJaebIntroEventsSuccess = createAction(
  dashboardActions.LOAD_JAEB_INTRO_EVENTS_SUCCESS,
  props<{payload: BaseEventType[]}>()
);

export const loadJaebIntroEventsFail = createAction(
  dashboardActions.LOAD_JAEB_INTRO_EVENTS_FAIL,
  props<{error: unknown}>()
);

export const sendJaebIntroEvent = createAction(
  dashboardActions.SEND_JAEB_INTRO_EVENT,
);

export const sendJaebIntroEventSuccess = createAction(
  dashboardActions.SEND_JAEB_INTRO_EVENT_SUCCESS,
);

export const sendJaebIntroEventFail = createAction(
  dashboardActions.SEND_JAEB_INTRO_EVENT_FAIL,
  props<{error: unknown}>()
);

export const loadMissedStudies = createAction(
  dashboardActions.LOAD_MISSED_STUDIES,
  props<{user_id: string}>(),
);

export const loadMissedStudiesSuccess = createAction(
  dashboardActions.LOAD_MISSED_STUDIES_SUCCESS,
  props<{payload: MissedStudyType[]}>(),
);

export const loadMissedStudiesFail = createAction(
  dashboardActions.LOAD_MISSED_STUDIES_FAIL,
  props<{error: unknown}>(),
);

export const loadNotReadMissedStudies = createAction(
  dashboardActions.LOAD_NOT_READ_MISSED_STUDIES,
  props<{user_id: string}>(),
);

export const loadNotReadMissedStudiesSuccess = createAction(
  dashboardActions.LOAD_NOT_READ_MISSED_STUDIES_SUCCESS,
  props<{payload: MissedStudyType[]}>(),
);

export const loadNotReadMissedStudiesFail = createAction(
  dashboardActions.LOAD_NOT_READ_MISSED_STUDIES_FAIL,
  props<{error: unknown}>(),
);

export const markMissedStudiesAsRead = createAction(
  dashboardActions.MARK_MISSED_STUDIES_AS_READ,
  props<{userId: string, missedStudies: MissedStudyType[]}>(),
);

export const markMissedStudiesAsReadSuccess = createAction(
  dashboardActions.MARK_MISSED_STUDIES_AS_READ_SUCCESS,
  props<{payload: MissedStudyType[]}>(),
);

export const markMissedStudiesAsReadFail = createAction(
  dashboardActions.MARK_MISSED_STUDIES_AS_READ_FAIL,
  props<{error: unknown}>(),
);

export const markStudyAsViewed = createAction(
  dashboardActions.MARK_STUDY_AS_VIEWED,
  props<{userStudyId: string}>()
);

export const markStudyAsViewedSuccess = createAction(
  dashboardActions.MARK_STUDY_AS_VIEWED_SUCCESS,
  props<LastViewedType>()
);

export const markStudyAsViewedFail = createAction(
  dashboardActions.MARK_STUDY_AS_VIEWED_FAIL,
  props<{error: unknown}>()
);

export const reactivateStudyRequest = createAction(
  dashboardActions.REACTIVATE_STUDY_REQUEST,
  props<{userStudyId: string | number}>(),
);

export const reactivateStudy = createAction(
  dashboardActions.REACTIVATE_STUDY,
  props<{userId: string, userStudyId: string | number}>(),
);

export const reactivateStudyFail = createAction(
  dashboardActions.REACTIVATE_STUDY_FAIL,
  props<{error: string[]}>()
);

export const flowReviewAnswersDashboard = createAction(
  dashboardActions.REVIEW_ANSWERS_DASHBOARD,
  props<StudyReviewParams>()
);

export const getPDFOfSubmittedAnswers = createAction(
  dashboardActions.GET_PDF_OF_SUBMITTED_ANSWERS,
  props<{toPrint?: boolean, procedure: string, userStudyId: number | string, userId: string}>()
);

export const getPDFOfSubmittedAnswersSuccess = createAction(
  dashboardActions.GET_PDF_OF_SUBMITTED_ANSWERS_SUCCESS,
  props<{payload: Blob, filename: string, toPrint: boolean}>()
);

export const getPDFOfSubmittedAnswersFail = createAction(
  dashboardActions.GET_PDF_OF_SUBMITTED_ANSWERS_FAIL,
  props<{error: unknown}>()
);

export const getSubmittedAnswers = createAction(
  dashboardActions.GET_SUBMITTED_ANSWERS,
  props<{procedure: string, userStudyId: number | string, userId: string}>()
);

export const getQuestionnaireProcedure = createAction(
  dashboardActions.GET_SUBMITTED_ANSWERS,
  props<{procedure: string, userStudyId: string, userId: string}>()
);

export const getSubmittedAnswersSuccess = createAction(
  dashboardActions.GET_SUBMITTED_ANSWERS_SUCCESS,
  props<{payload: string}>()
);

export const getSubmittedAnswersFail = createAction(
  dashboardActions.GET_SUBMITTED_ANSWERS_FAIL,
  props<{error: unknown}>()
);

export const getProcedureSuccess = createAction(
  dashboardActions.GET_QUESTION_PROCEDURE_SUCCESS,
  props<{payload: any}>()
);

export const getProcedureFail = createAction(
  dashboardActions.GET_QUESTION_PROCEDURE_FAIL,
  props<{error: unknown}>()
);