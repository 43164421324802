import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormGroupProtoType } from '@tr-common';

import { passwordRegExp } from './validate-common';
import { matchFields, matchOtherValidator, newPasswordNotSameAsOldValidator, triggerNewPasswordValidation } from './validate-pair';

export interface SetNewPassPayload {
  'new_password': string,
  'new_password1': string,
  'token': string
}

export interface ChangePassPayload {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
}

export interface ChangePassFormData extends ChangePassPayload {
  username: string;
}

export const getChangePasswordForm = (username: string = null) => new FormGroup<FormGroupProtoType<ChangePassFormData>>({
  username: new FormControl<string>(username),
  old_password: new FormControl<string>(null, Validators.compose([Validators.required, triggerNewPasswordValidation()])),
  new_password: new FormControl<string>(null, Validators.compose([Validators.required, Validators.pattern(passwordRegExp), newPasswordNotSameAsOldValidator()])),
  new_password_confirm: new FormControl<string>(null, matchOtherValidator('new_password', matchFields))
});

export const changePasswordForm2Payload = ({value}: FormGroup<FormGroupProtoType<ChangePassFormData>>): Partial<ChangePassPayload> => ({
  old_password: value?.old_password, new_password: value?.new_password, new_password_confirm: value?.new_password_confirm
});
