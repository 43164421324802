import { isRealValue } from 'tr-lib';

export interface PreventCopyPasteSource {
  keyCode: number;
  metaKey?: boolean;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  code: string;
}

const preventCopyPasteCombinations: PreventCopyPasteSource[] = [
  {keyCode: 65, ctrlKey: true, code: 'KeyA'},
  {keyCode: 67, ctrlKey: true, code: 'KeyC'},
  {keyCode: 86, ctrlKey: true, code: 'KeyV'},
  {keyCode: 65, metaKey: true, code: 'KeyA'},
  {keyCode: 67, metaKey: true, code: 'KeyC'},
  {keyCode: 86, metaKey: true, code: 'KeyV'},
  {keyCode: 45, shiftKey: true, code: 'Insert'},
  {keyCode: 45, ctrlKey: true, code: 'Insert'}
];

export class PreventCopyPaste implements PreventCopyPasteSource {
  keyCode: number;
  ctrlKey = false;
  shiftKey = false;
  metaKey = false;
  code = '';

  constructor(event?: Partial<KeyboardEvent>) {
    if (isRealValue(event)) {
      const {keyCode, ctrlKey, shiftKey, metaKey, code} = event;

      Object.assign(this, {keyCode, ctrlKey, shiftKey, metaKey, code});
    }
  }

  get isPreventedCode(): boolean {
    return preventCopyPasteCombinations.some((
      {keyCode, ctrlKey, shiftKey, metaKey, code}
    ) => {
      const isSpecialKey = ctrlKey && this.ctrlKey === ctrlKey || shiftKey && this.shiftKey === shiftKey || metaKey === this.metaKey;

      return isSpecialKey && keyCode === this.keyCode && code === this.code;
    });
  }
}
