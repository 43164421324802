import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { defaultErrorDialogData, ErrorDialogData } from 'tr-lib';

@Component({
  selector: 'app-error-dialog',
  template: `
    <div mat-dialog-title>
      <div class="close-icon" mat-dialog-close><i class="material-icons">clear</i></div>
      <div class="flex error-icon"><mat-icon svgIcon="error-msg"></mat-icon></div>
      <h3>{{ content.sorry }}</h3>
    </div>

    <div mat-dialog-content>
      <p class="text" [innerText]="content.action"></p>
      <p class="text" [innerHTML]="content.whatToDo"></p>
    </div>

    <div mat-dialog-actions>
      <button class="dialog-button" mat-flat-button color="primary" mat-dialog-close>Close</button>
    </div>
  `,
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  @HostBinding('class.hide-close-icon') hideCloseIcon = false;
  content: ErrorDialogData = defaultErrorDialogData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorDialogData) {}

  ngOnInit(): void {
    if (this.data) {
      Object.assign(this.content, {...this.data});
      this.hideCloseIcon = this.data.hideCloseIcon ?? this.hideCloseIcon;
    }
  }
}
