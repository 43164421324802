import * as t from 'io-ts';

import { MrpHead, MrpStage, MrpStatusString, MrpStatusStringC } from '@tr-common';

export const mrpStatusStringSchema: MrpStatusStringC = t.brand(
  t.string,
  (s): s is MrpStatusString => {
    const parts = s.split(':').map(part => part.trim());
    const fst = Object.values(MrpHead).map(h => h.toLowerCase()).includes(parts[0]?.toLowerCase() as any);
    const snd = Object.values(MrpStage).map(h => h.toLowerCase()).includes(parts[1].toLowerCase() as any);

    return fst && snd;
  },
  'MrpStatusString'
);
