import { isNonEmptyArray } from '@tr-common';

import { StudyOptionType } from '../../models/study-option';

export const baseDropdownLength = 27
export const baseDropdownPixels = 240;

export const calculateDropdownWidth = (options: StudyOptionType[]): number => {
  const longestOption = isNonEmptyArray(options)
    ? options.reduce((a: StudyOptionType, b: StudyOptionType) => (a.title ?? '').length > (b.title ?? '').length ? a : b)
    : {title: ''};

  return longestOption.title.length > baseDropdownLength
    ? Math.floor(baseDropdownPixels * longestOption.title.length / baseDropdownLength)
    : baseDropdownPixels;
};
