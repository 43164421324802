import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
  additionalConsentOnPublic,
  Consent,
  ConsentBody,
  ConsentService,
  failSnackBottomConstantConfig,
  filterRealValues,
  isConsentUnsigned,
  isRealValue,
  LayoutService,
  somethingWentWrong,
  SurveyService,
  UserStudyType,
} from 'tr-lib';

import { DashboardService } from '@app/dashboard/services/dashboard.service';
import {
  clearSmsConsentState,
  getCoreStudyIdFail,
  getCoreStudyIdSuccess,
  getSMSConsent,
  getSMSConsentFail,
  getSMSConsentSuccess,
  getUserStudyIdSuccess,
  loadDashboardStudiesSuccess,
  loadResignConsentBodyFail,
  loadResignConsentBodySuccess,
  loadResignConsents,
  loadResignConsentsFail,
  loadResignConsentsSuccess,
  resignConsent,
  resignConsentFail,
  resignConsentSuccess,
  showSmsConsentDialog,
  signedSmsConsentFail,
  signedSmsConsentSuccess,
  signSmsConsent,
  skipSmsConsent,
  skipSmsConsentFail,
  skipSmsConsentSuccess,
} from '@store/actions';
import { AppState, isStudyCoreAndActive, publicDialogClass } from '@app/model';
import {
  selectConsentUserStudyId,
  selectParticipantPhone,
  selectRealUserId,
  selectUnsignedReconsent,
  selectUserId,
} from '@store/selectors';
import { UserService } from '@services/user.service';
import { SmsConsentDialogComponent } from '@app/dialogs/sms-consent/sms-consent-dialog.component';
import { SmsPhoneData } from '@app/dashboard/models';

// noinspection JSUnusedGlobalSymbols
@Injectable({providedIn: 'root'})
export class ConsentSignEffects {
  additionalConsentOnPublic$  = createEffect(() => this.actions$.pipe(
    ofType(additionalConsentOnPublic),
    filter(({require}) => require),
    map(() => getSMSConsent())
  ));

  getSMSConsent$ = createEffect(() => this.actions$.pipe(
    ofType(getSMSConsent),
    withLatestFrom(this.store.pipe(selectRealUserId())),
    switchMap(([, id]) => this.dashboardService.getSMSConsent(id).pipe(
      map(({required}) => getSMSConsentSuccess({payload: required})),
      catchError(({error}) => of(getSMSConsentFail({error})))
    ))
  ));

  getSMSConsentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getSMSConsentSuccess),
    filter(({payload}) => payload),
    map(() => showSmsConsentDialog())
  ));

  signSmsConsent$ = createEffect(() => this.actions$.pipe(
    ofType(signSmsConsent),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([{phone}, userId]) => this.userService.updatePhoneAndSmsConsent(userId, phone).pipe(
      map(() => signedSmsConsentSuccess()),
      catchError(({error}) => {
        const message = isRealValue(error?.phone) ? error?.phone : error;

        return of(signedSmsConsentFail({error: message}));
      })
    ))
  ));

  skipSmsConsent$ = createEffect(() => this.actions$.pipe(
    ofType(skipSmsConsent),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([, userId]) => this.userService.skipSmsConsent(userId).pipe(
      map(() => skipSmsConsentSuccess()),
      catchError(({error}) => of(skipSmsConsentFail({error})))
    ))
  ));

  loadResignConsents$ = createEffect(() => this.actions$.pipe(
    ofType(loadResignConsents),
    withLatestFrom(this.store.pipe(selectRealUserId())),
    switchMap(([, participantId]) => this.dashboardService.getUserStudies(participantId).pipe(
      map((studies: UserStudyType[]) => studies.filter(isStudyCoreAndActive)),
      // if there are no studies then we can't dispatch Success action
      filter((studies: UserStudyType[]) => studies.length > 0),
      map(([study]: UserStudyType[]) => study),
      tap(({id}) => {
        if (typeof id !== 'number') console.warn('study.user_study_id is not a number');
      }),
      concatMap(({study_id, id}) => [
        getCoreStudyIdSuccess({payload: study_id}),
        getUserStudyIdSuccess({payload: id}),
        loadDashboardStudiesSuccess({participantId, userStudyId: +id})
      ]),
      catchError(({error}) => of(getCoreStudyIdFail({error}))),
    ))
  ));

  loadDashboardStudiesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadDashboardStudiesSuccess),
    switchMap(({participantId, userStudyId}) => this.preview.loadConsents(participantId, userStudyId).pipe(
      map((payload: Consent[]) => loadResignConsentsSuccess({payload})),
      catchError(({error}) => of(loadResignConsentsFail({error})))
    )),
  ));

  loadResignConsentsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadResignConsentsSuccess),
    map(({payload}) => payload.find(isConsentUnsigned)),
    filterRealValues(),
    switchMap(({id}) => this.preview.loadConsentBody(id).pipe(
      map((payload: ConsentBody) => loadResignConsentBodySuccess({payload})),
      catchError(({error}) => of(loadResignConsentBodyFail({error})))
    ))
  ));

  resignConsent$ = createEffect(() => this.actions$.pipe(
    ofType(resignConsent),
    switchMap(action => combineLatest([
      this.store.select(selectConsentUserStudyId).pipe(take(1)),
      this.store.select(selectUnsignedReconsent).pipe(filterRealValues(), take(1)),
      this.store.pipe(selectRealUserId(), take(1)),
    ]).pipe(
      map(([studyId, consent, userId]) => [action, studyId, consent, userId] as const)
    )),
    switchMap(([action, studyId, consent, userId]) => this.preview.signConsent(
      userId, studyId, consent.id, {subject_name: action.payload, user_consent_id: consent.id}
    ).pipe(
      /**
       * assume that consent is already submitted and after re-sign consent we do not need to re-submit procedure
       */
      concatMap(payload => [
        resignConsentSuccess({payload}),
        loadResignConsents()
      ]),
      catchError(error => of(resignConsentFail({error})))
    ))
  ));

  somethingWentWrong$ = createEffect(() => this.actions$.pipe(
    ofType(getSMSConsentFail, skipSmsConsentFail),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig()))
  ), {dispatch: false});

  showSmsConsentDialog$ = createEffect(() => this.actions$.pipe(
    ofType(showSmsConsentDialog),
    withLatestFrom(this.store.select(selectParticipantPhone)),
    switchMap(([, phone]) => this.dialog.open<SmsConsentDialogComponent, SmsPhoneData, boolean>(
      SmsConsentDialogComponent, {
        panelClass: [publicDialogClass, 'sms-consent-dialog'],
        autoFocus: false,
        maxWidth: (this.layoutService.isMobile || this.layoutService.isLandscapeMobile) ? '100vw' :  '555px',
        disableClose: true,
        data: {phone},
      },
    ).afterClosed().pipe(
      map(afterClose => afterClose ? clearSmsConsentState() : skipSmsConsent())
    ))
  ));

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private snackBar: MatSnackBar,
    private consentService: ConsentService,
    private preview: SurveyService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
  ) {}
}
