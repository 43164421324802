import { get } from 'lodash';

import { Answers, isNullOrUndefined, isRealValue, ProcedureTemplateType, ProcedureTemplateTypes } from '@tr-common';

import { IProcedure, isSpecialProcedure, IStudyProcedure, Procedures, UserStudyProcedureType } from './procedure';
import { ProcedureQuestions } from './procedure-questions';
import { StudyQuestion } from './study-question';
import { MinimalStudyType } from './study';
import { UserStudyType } from '../study-survey/models';
import { studyState } from './study-state';

export const procedureHasQuestions = (
  id: ProcedureTemplateTypes
): boolean => [ProcedureTemplateTypes.screening, ProcedureTemplateTypes.questionnaire].some(p => p === id);

export const expandedStudyProcedures = (
  studyProcedures: IStudyProcedure[], userProcedures: UserStudyProcedureType[]
): Procedures => studyProcedures.map((studyProcedure: IStudyProcedure) => {
  const userProcedure = userProcedures.find(({procedure_id}) => procedure_id === studyProcedure.id);
  const {id, order, title} = studyProcedure;
  const created_at = get(userProcedure, ['created_at'], null);

  return {
    ...studyProcedure, title, order, created_at,
    isSpecial: isSpecialProcedure(id),
    isIntro: id === ProcedureTemplateTypes.intro,
    isScreening: id === ProcedureTemplateTypes.screening,
    isConsent: id === ProcedureTemplateTypes.consent,
    isResearch: id === ProcedureTemplateTypes.research,
    enabled: isRealValue(created_at),
  }
});

export const updateProceduresAfterSubmit = (
  procedures: Procedures, userProcedures: UserStudyProcedureType[]
): Procedures => procedures.map(procedure => {
  const userProcedure = userProcedures.find(({procedure_id}) => procedure_id === procedure.id);
  const created_at = get(userProcedure, ['created_at'], null);

  return {...procedure, ...userProcedure, enabled: isRealValue(created_at)};
});

export const enableProcedures = (
  procedures: Procedures, questions: ProcedureQuestions, answers: Answers
): Procedures => isRealValue(procedures) ? procedures.map((procedure, idx) => {
  let enabled = isRealValue(procedure.created_at) || idx > 0 && isRealValue(procedures[idx - 1].created_at);

  if (!enabled && procedureHasQuestions(procedure.id as ProcedureTemplateType)) {
    const procedureQuestions = questions[procedure.id];
    const questionsIds = isRealValue(procedureQuestions) ? procedureQuestions.map((q: StudyQuestion) => q.id) : [];
    const answersIds = isRealValue(answers) ? Object.keys(answers) : [];

    enabled = questionsIds.some(q => answersIds.includes(q));
  }

  return {...procedure, enabled};
}) : [];

export const findTargetProcedure = (procedures: Procedures, answers: Answers): IProcedure => {
  // it picks the first procedure if there are no created_at
  const foundIdx = procedures.findIndex(({created_at}) => isNullOrUndefined(created_at));
  const actualId = foundIdx > -1 ? foundIdx : procedures.length - 1;

  return procedures[actualId];
}

const isStudyNotCompleted = (
  {status}: MinimalStudyType, {dashboard_status}: UserStudyType
): boolean => status === studyState.closed || dashboard_status !== 'completed';

export const getDefaultProcedure = (
  userStudy: UserStudyType, procedures: Procedures, answers: Answers, study: MinimalStudyType
): IProcedure => {
  const introProcedure = procedures.find(p => p.isIntro);

  return isStudyNotCompleted(study, userStudy) ? (introProcedure ?? findTargetProcedure(procedures, answers)) : procedures[0];
}

export const getDefaultProcedureExceptIntro = (
  userStudy: UserStudyType, procedures: Procedures, answers: Answers, study: MinimalStudyType
): IProcedure => isStudyNotCompleted(study, userStudy) ? findTargetProcedure(procedures, answers) : procedures[procedures.length - 1];

export const updateAfterSubmit = (
  userStudyProcedure: UserStudyProcedureType, userProcedures: UserStudyProcedureType[]
): UserStudyProcedureType[] => {
  const newUserProcedures = userProcedures.length === 0 ? [userStudyProcedure] : [...userProcedures];
  const foundIdx = userProcedures.findIndex(userProcedure => userProcedure.procedure_id === userStudyProcedure.procedure_id);

  if (foundIdx > -1) {
    newUserProcedures.splice(foundIdx, 1, userStudyProcedure);
  }
  newUserProcedures.push(userStudyProcedure);

  return newUserProcedures;
}

export const hasStudyIntroProcedure = (procedures: Procedures): boolean => procedures.some(({isIntro}) => isIntro);
