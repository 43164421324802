import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { Memoize } from 'lodash-decorators';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isRealValue } from '@tr-common';

import { composeURLForResearchStudy, ResearchProcedureWithUserIDType } from '../../models';
import { StudyFacade } from '../../store';

@Component({
  selector: 'lib-procedure-research-status',
  template: `
    <lib-study-is-closed-banner directionType="right"></lib-study-is-closed-banner>

    <div class="procedure-research-status" *ngIf="researchProcedure$ | async as researchProcedure">
      <div class="flex intro-title">
        <mat-icon svgIcon="research"></mat-icon>
        <h3 class="procedure-status-title" [innerHTML]="researchProcedure.title"></h3>
      </div>
      <hr class="intro-title mbs-10 mbe-10"/>
      <h4 class="intro-title mbs-30">Study Details</h4>
      <div *ngIf="researchProcedure.text" [innerHTML]="researchProcedure.text | safeHtml"></div>
      <a *ngIf="!showBanner" mat-flat-button class="survey-button is-shadowed" color="primary" target="_blank" rel="noopener"
        [href]="getUrl(researchProcedure)" (click)="study.clickResearchProcedure(researchProcedure.id)">
        {{ researchProcedure['button_text'] }}
      </a>
    </div>

    <lib-study-is-closed-banner directionType="left"></lib-study-is-closed-banner>
  `,
  styleUrls: ['./procedure-research-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcedureResearchStatusComponent {
  @HostBinding('class.show-banner') @Input() showBanner = false;
  researchProcedure$: Observable<ResearchProcedureWithUserIDType> = this.study.researchProcedure$.pipe(filter(isRealValue));

  constructor(public study: StudyFacade) {}

  @Memoize() getUrl({url, userID, add_participant_id_query_param}: ResearchProcedureWithUserIDType): string {
    return composeURLForResearchStudy(url, userID, add_participant_id_query_param);
  }
}
