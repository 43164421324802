import { isEmptyValue, isNonEmptyArray, isNonEmptyString, isNullOrUndefined, isObject, ValidatorType } from '@tr-common';

import { isStudyOption } from './study-option-widget';
import { StudyQuestion } from './study-question';
import { TreeNode, TreeNodeValueType } from './tree-node';

export const cssClassForTitle = (data: StudyQuestion, showHint: boolean): string[] => [
  showHint ? 'show-hint' : null,
  data.isLongTitle ? 'long-question' : 'question',
  isNonEmptyString(data?.titleForQuestion) ? null : 'empty-question',
  isNonEmptyString(data?.titleForQuestion) || showHint ? null : 'no-display'
];

// noinspection JSUnusedGlobalSymbols
export class FlatTreeNode<T = TreeNodeValueType> extends TreeNode<T> {
  level: number;
  expandable: boolean;
  validStatus: ValidatorType = 'default';

  constructor(initData?: Partial<FlatTreeNode>) {
    super(initData);
    if (isObject(initData)) {
      Object.assign(this, initData);
    }
  }

  get extractedValue(): string {
    return typeof this.value === 'string' ? this.value.toString() : JSON.stringify(this.value);
  }

  get clearValue(): null | false {
    return this.isStudyOptionWithDateWidget || this.isSelectableNode ? null : false;
  }

  get cssClasses(): string[] {
    return (this.isQuestion ? this.questionCssClasses : this.optionCssClasses).filter(isNonEmptyString);
  }

  private get questionCssClasses(): string[] {
    return [
      this.level > 0 ? 'tr-subquestion' : null,
      ...cssClassForTitle(this.data as StudyQuestion, this.showHint),
      this.uiTestClass[0], this.uiTestClass[1]
    ];
  }
  private get optionCssClasses(): string[] { return [...this.uiTestClass, this.showHint ? 'show-hint' : null]; }
  private get isStudyOptionWithDateWidget(): boolean { return isStudyOption(this.data) && this.data.isDateWidget; }

  unselectOtherRadioButtons(dataNodes: FlatTreeNode[]): void {
    const needToCleanRadio = (v: FlatTreeNode): boolean => v.questionID === this.questionID
      && (isNullOrUndefined(this.data) || isNullOrUndefined(v.data) || this.data.id !== v.data.id)
      && v.hasToHaveValue;

    for (const dataNode of dataNodes) {
      if (needToCleanRadio(dataNode)) {
        dataNode.clearValueRecursive.call(dataNode, dataNodes, 'VALID');
      }
    }
  }

  clearSiblingRadio(dataNodes: FlatTreeNode[]): void {
    const isSiblingRadio = (v: FlatTreeNode): boolean => v.questionID === this.questionID
      && (isNullOrUndefined(this.data) || isNullOrUndefined(v.data) || this.data.id !== v.data.id)
      && isStudyOption(v.data) && v.data.isRadioWidget;

    for (const dataNode of dataNodes) {
      if (isSiblingRadio(dataNode)) {
        dataNode.value = dataNode.clearValue; dataNode.validStatus = 'VALID';
      }
    }
  }

  private clearValueRecursive(dataNodes: FlatTreeNode[], validStatus: ValidatorType = 'default'): void {
    const {children} = this;

    this.value = this.clearValue as T;
    this.validStatus = validStatus;
    if (children.length > 0) {
      const ids = new Set<string>();

      for (const child of children) {
        if (isStudyOption(child.data)) ids.add(child.data.id);
      }
      for (const dataNode of dataNodes) {
        if (dataNode.isSelectableNode) {
          dataNode.value =  typeof dataNode.value === 'object' ? null : dataNode.clearValue;
        } else if (!dataNode.isQuestion && ids.has(dataNode.data?.id)) {
          dataNode.clearValueRecursive(dataNodes);
        }
      }
    }
  }
}
