import { Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormControlErrorHandler, isEmptyValue, isRealValue, ValidatorType, WidgetErrorStateMatcher } from '@tr-common';

import {
  checkAsyncWarnings,
  hasOnlyAsyncWarnings,
  SourceChange,
  SourceChangeType,
  WidgetModify,
  WidgetPrototype,
} from '../../../models';
import { StudyOption } from '../../../models/study-option';

@Component({
  selector: 'lib-zip-widget',
  templateUrl: './zip-widget.component.html'
})
export class ZipWidgetComponent implements WidgetPrototype<string | boolean>, OnChanges, OnDestroy {
  @Input() option: StudyOption;
  @Input() value: string; // there are some zip codes with leading by zeros
  @Output() modify = new EventEmitter<WidgetModify<string | boolean>>();
  readonly errorHandlers: FormControlErrorHandler[] = [{
    mustBeShowed: control => isRealValue(control.errors) && control.errors['pattern'],
    message: `Please enter a valid 5-digit zip code`
  }];
  hasOnlyAsyncWarnings = hasOnlyAsyncWarnings;
  valid: ValidatorType = 'default';
  errorMatcher = new WidgetErrorStateMatcher();
  input = new FormControl(null, {updateOn: 'blur'});
  subscriptions = new Subscription();

  constructor() {
    this.subscriptions.add(
      this.input.statusChanges.pipe(
        map(x => checkAsyncWarnings(x as ValidatorType, this.input.errors))
      ).subscribe((status: ValidatorType) => {
        this.valid = status;
        this.emitState(SourceChange.user);
      })
    );
  }

  @HostBinding('class.invalid') get isValid() { return this.valid === 'INVALID'; }
  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);

  ngOnChanges({value}: SimpleChanges): void {
    if (isRealValue(value)) {
      this.input.setValue(isEmptyValue(this.value) ? '' : this.value, {emitEvent: false});
      this.input.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  emitState(source: SourceChangeType): void {
    const {value} = this.input;
    const {valid} = this;

    this.modify.emit({value: isEmptyValue(value) ? false : value, valid, source});
  }
}
