import { Injectable, Injector } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { flatten, groupBy } from 'lodash';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import { StoredAnswersType, StoredAnswerType } from '@tr-common';

import { getReviewOrFullPreselect, transform2UserStudiesAndOptions, UserStudiesOptions } from '../../models';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import { SurveyService } from '../../services/survey.service';
import {
  checkPreselect,
  loadAnswers,
  loadAnswersFail,
  loadAnswersFromAnotherStudy,
  loadAnswersFromAnotherStudyFail,
  loadAnswersFromAnotherStudySuccess,
  loadAnswersSuccess,
} from '../actions';
import { fetchUserStudyIdsFromQuestions, fetchUserStudyIdsFromRules } from '../study.helpers';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class CommonEffects {
  actions$ = new Subject<Action>();

  loadAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(loadAnswers),
    switchMap(({participantID, userStudyID}) => this.surveyService.loadAnswers(participantID, userStudyID).pipe(
      map(a => loadAnswersSuccess({payload: groupBy(a, 'question')})),
      catchError(error => of(loadAnswersFail(error))),
    )),
  ));

  loadAnswersFromAnotherStudy$ = createEffect(() => this.actions$.pipe(
    ofType(loadAnswersFromAnotherStudy),
    switchMap(({sourceType, questions, rules, userStudies, studyID, participantID}) => {
      const preselect = getReviewOrFullPreselect(sourceType);
      const sources: UserStudiesOptions = transform2UserStudiesAndOptions([
        ...fetchUserStudyIdsFromRules(rules, userStudies, studyID),
        ...fetchUserStudyIdsFromQuestions(questions, userStudies, studyID),
      ]);
      const loads = sources.userStudies.length === 0
        ? of([])
        : forkJoin(sources.userStudies.map(userStudy => this.surveyService.loadAnswers(participantID, userStudy)));

      return loads.pipe(
        map((answers: StoredAnswerType[][]) => flatten(answers).filter(
          (answer: StoredAnswerType) => sources.options.includes(answer.question_option)
        ).map((answer: StoredAnswerType) => ({...answer, isAnswerFromOtherStudy: true}))),
        concatMap(a => [
          loadAnswersFromAnotherStudySuccess({payload: groupBy(flatten(a) as StoredAnswersType, 'question')}),
          checkPreselect({payload: preselect}) // TODO Check sub-study
        ]),
        catchError(error => of(loadAnswersFromAnotherStudyFail(error))),
      );
    }),
  ));

  constructor(
    private injector: Injector,
    public surveyRouter: SurveyRouter,
    public store: Store<any>,
    public surveyService: SurveyService,
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }
}
