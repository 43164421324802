import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialImportsModule } from '../material-imports.module';
import { GotoBackLinkComponent } from './goto-back-link.component';

@NgModule({
  declarations: [
    GotoBackLinkComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialImportsModule,
  ],
  exports: [
    GotoBackLinkComponent,
  ]
})
export class GotoBackLinkModule {}
