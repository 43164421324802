import { isRealValue, surveyType, SurveyType } from 'tr-lib';

export const convertToWeeksOrDaysRemaining = (sT: SurveyType, expiresInDays: number): string => {
  let convertedValue: string = isRealValue(expiresInDays) ? expiresInDays.toString() : undefined;

  if (sT === surveyType.annual_questionnaire && isRealValue(expiresInDays) && expiresInDays > 0) {
    const shouldBeInDays = expiresInDays < 7;
    const roundedValue = shouldBeInDays ? expiresInDays : Math.round(expiresInDays / 7);

    convertedValue = `${roundedValue} ${shouldBeInDays ? 'days' : 'weeks'} remaining`;
  }

  return convertedValue;
}
