import { createAction, props } from '@ngrx/store';

import { BaseEventType, DialogTypeConsent, ProcedureTemplateType } from '@tr-common'

import { ParamsIDs } from '../../models';
import { Consent } from '../../../consent/models';
import { ParticipantDetails, Study } from '../../../models';

export enum surveyDialogActions {
  DEFINE_SOURCE_OF_DIALOG = '[Dialog] Define source of the dialog (DB or Hardcode)',
  DEFINE_ELIGIBLE_DIALOG = '[Dialog] Define Eligible Dialog',
  SHOW_ELIGIBLE_DIALOG_FOR_REGULAR = '[Dialog] Show Eligible Dialog For Regular',
  SHOW_ELIGIBLE_DIALOG_FOR_NON_REGULAR = '[Dialog] Show Eligible Dialog For Non Regular',
  GET_CONSENTS_TO_SHOW_DIALOG = '[Dialog] Get list of consents to show dialog',
  DEFINE_CONSENT_DIALOG = '[Dialog] Define Consent Dialog',
  SHOW_CONSENT_DIALOG_FOR_REGULAR = '[Dialog] Show Consent Dialog For Regular',
  SHOW_CONSENT_DIALOG_FOR_NON_REGULAR = '[Dialog] Show Consent Dialog For Non Regular',
  SHOW_CONCLUSION_DIALOG = '[Dialog] Show conclusion dialog',
  SHOW_WELCOME_DIALOG_FROM_DB = '[Dialog] Show Welcome dialog using settings from database',
  DEFINE_HARDCODED_WELCOME_DIALOG = '[Dialog] Define Hardcoded Welcome dialog',
  SHOW_WELCOME_SCREENING_DIALOG = '[Dialog] Show Hardcoded Welcome Screening dialog',
  SHOW_WELCOME_QUESTIONNAIRE_DIALOG = '[Dialog] Show Hardcoded Welcome Questionnaire dialog',
  REGISTER_SHOWING_WELCOME_DIALOG = '[Dialog] Register showing welcome dialog',
  REGISTER_SHOWING_WELCOME_DIALOG_SUCCESS = '[Dialog] Registered showing welcome dialog success',
  REGISTER_SHOWING_WELCOME_DIALOG_FAIL = '[Dialog] Registered showing welcome dialog fail',
}

export const defineSourceOfDialog = createAction(
  surveyDialogActions.DEFINE_SOURCE_OF_DIALOG,
  props<{procedure: ProcedureTemplateType}>()
);

export const showWelcomeDialogFromDB = createAction(
  surveyDialogActions.SHOW_WELCOME_DIALOG_FROM_DB,
  props<{procedure: ProcedureTemplateType}>()
);

export const defineHardcodedWelcomeDialog = createAction(
  surveyDialogActions.DEFINE_HARDCODED_WELCOME_DIALOG,
  props<{procedure: ProcedureTemplateType, profile: ParticipantDetails, study: Study}>()
);

export const showWelcomeScreeningDialog = createAction(
  surveyDialogActions.SHOW_WELCOME_SCREENING_DIALOG,
  props<{profile: ParticipantDetails, study: Study}>()
);

export const showWelcomeQuestionnaireDialog = createAction(
  surveyDialogActions.SHOW_WELCOME_QUESTIONNAIRE_DIALOG,
  props<{profile: ParticipantDetails}>()
);

export const registerShowingWelcomeDialog = createAction(
  surveyDialogActions.REGISTER_SHOWING_WELCOME_DIALOG,
  props<{procedure: ProcedureTemplateType}>()
);

export const registerShowingWelcomeDialogSuccess = createAction(
  surveyDialogActions.REGISTER_SHOWING_WELCOME_DIALOG_SUCCESS,
  props<{payload: BaseEventType}>()
);

export const registerShowingWelcomeDialogFail = createAction(
  surveyDialogActions.REGISTER_SHOWING_WELCOME_DIALOG_FAIL,
  props<{payload: unknown}>()
);

export const defineEligibleDialog = createAction(
  surveyDialogActions.DEFINE_ELIGIBLE_DIALOG,
  props<{payload: ParamsIDs}>()
);

export const showEligibleDialogForRegular = createAction(
  surveyDialogActions.SHOW_ELIGIBLE_DIALOG_FOR_REGULAR
);

export const showEligibleDialogForNonRegular = createAction(
  surveyDialogActions.SHOW_ELIGIBLE_DIALOG_FOR_NON_REGULAR
);

export const getConsentsToShowDialog = createAction(
  surveyDialogActions.GET_CONSENTS_TO_SHOW_DIALOG
);

export const defineConsentDialog = createAction(
  surveyDialogActions.DEFINE_CONSENT_DIALOG,
  props<{payload: Array<Consent>}>()
);

export const showConsentDialogForRegular = createAction(
  surveyDialogActions.SHOW_CONSENT_DIALOG_FOR_REGULAR
);

export const showConsentDialogForNonRegular = createAction(
  surveyDialogActions.SHOW_CONSENT_DIALOG_FOR_NON_REGULAR,
  props<DialogTypeConsent>()
);

export const showConclusionDialog = createAction(
  surveyDialogActions.SHOW_CONCLUSION_DIALOG
);
