import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

import { getEmailErrorHandlers, isRealValue } from '../../common';

import { CountryInfo } from '../../models';
import { LayoutService } from '../../services';

@Component({
  selector: 'lib-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./personal-info-form.component.scss'],
})
export class PersonalInfoFormComponent implements OnChanges, OnInit, OnDestroy {
  @HostBinding('class.change-hint') @Input() emailChangeHint = false;
  @Input() floatLabel: FloatLabelType;
  @Input() parentForm: FormGroup;
  @Input() countryCodes: CountryInfo[];
  // @ts-ignore
  @Output() emailFocusOut = new EventEmitter<FocusEvent>();
  readonly emailErrorHandlers = getEmailErrorHandlers();
  emailWarning = false;
  isMobile$ = this.layoutService.isMobile$;
  subscription = new Subscription();

  constructor(
    private layoutService: LayoutService,
    private cdRef: ChangeDetectorRef
  ) {}

  get infoPhone(): FormControl { return this.parentForm.get('phone') as FormControl; }
  get email(): FormControl { return this.parentForm.get('email') as FormControl; }
  get icon(): FormControl { return this.parentForm.get('icon') as FormControl; }

  ngOnChanges(changes: SimpleChanges): void {
    if (isRealValue(changes?.countryCodes)) {
      this.cdRef.detectChanges();
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.email.statusChanges.pipe(debounce(() => timer(100))).subscribe(status => {
        if (status === 'INVALID') {
          const errors = this.email.errors;
          const [valueOfError] = isRealValue(errors) && Object.values(errors);

          this.emailWarning = valueOfError === 'asyncWarnings';
          this.icon?.setValue(this.emailWarning ? 'warn' : 'error');
        } else {
          this.icon?.setValue(this.icon?.value === 'checking' ? 'ok' : null);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // @ts-ignore
  emailAction(event: PointerEvent, iconValue: string): void {
    if (iconValue === 'error') {
      event.preventDefault();
      this.parentForm.get('email').setValue(null);
    }
  }
}
