import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlSegment, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import {
  DefaultPageForPrivilege,
  DefaultPagesByPrivilege,
  isRealValue,
  SessionStorage,
  STORAGE,
  storageKeys,
  SubPrivileges,
  tryWithFallback,
} from 'tr-lib';

import { PrivilegesService } from '@services/privileges.service';
import { logout } from '@store/actions';
import { AppState } from '@app/model';

@Injectable()
export class AlreadyLoggedInGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private stateService: PrivilegesService,
    @Inject(STORAGE) private storage: SessionStorage
  ) {}

  canActivate(): boolean {
    return this.canActivateOrLoad();
  }

  canLoad(): boolean {
    return this.canActivateOrLoad();
  }

  canActivateOrLoad() {
    const extractedUrl: UrlTree = this.router.getCurrentNavigation().extractedUrl;
    const maybeSignUpUrl = get(extractedUrl, ['root', 'children', 'primary', 'segments'], [] as UrlSegment[])
      .reduce((acc, segment) => acc + '/' + segment.path, '');
    const isSignUp = maybeSignUpUrl === '/auth/register';
    const jtoken = this.router.getCurrentNavigation().extractedUrl.queryParamMap.get('jtoken');
    const hasJaebToken = isRealValue(jtoken);
    const token = this.storage.getItem(storageKeys.token);
    const isTokenPresented = isRealValue(token);

    if (isSignUp && hasJaebToken) {
      if (isTokenPresented) {
        // log out if jaeb sign up link used otherwise allow if jaeb access link used and user is not logged in
        this.store.dispatch(logout({payload: {commands: ['auth', 'register'], navigationExtras: {queryParams: {jtoken}}}}));
      }

      return !isTokenPresented;
    }

    if (isTokenPresented) {
      const foundUrl = DefaultPagesByPrivilege.find(
        (defaultPage: DefaultPageForPrivilege) => defaultPage.privilege === tryWithFallback(
          SubPrivileges[this.stateService.state], x => x, SubPrivileges.normal
        )
      );

      this.router.navigate([foundUrl ? foundUrl.url : DefaultPagesByPrivilege[0].url]).then();
    }

    return !isTokenPresented;
  }
}
