import { FormControl, FormGroup, Validators } from '@angular/forms';

import { countryCodeValidator, FormGroupProtoType } from '@tr-common';

import { matchFieldsWithLowerCase, matchOtherValidator } from './validate-pair';
import { BaseParticipantType } from './user-profile';

export type ParticipantFormType = FormGroupProtoType<BaseParticipantType>;

export interface ReconsentParticipantForm extends ParticipantFormType {
  confirm_email: FormControl<string>;
}

export const getParticipantFormData = (): ParticipantFormType => ({
  first_name: new FormControl<string>(null, Validators.required),
  last_name: new FormControl<string>(null, Validators.required),
  phone: new FormControl<string>({value: null, disabled: true}, countryCodeValidator()),
  email: new FormControl<string>(null, Validators.compose([Validators.required, Validators.email]))
});

export const getParticipantForm = () => new FormGroup<ParticipantFormType>(getParticipantFormData());

export const getEmailConfirmation = (field = 'email') => new FormControl<string>(null, matchOtherValidator(field, matchFieldsWithLowerCase));

export const getReconsentParticipantForm = () => new FormGroup<ReconsentParticipantForm>({
  ...getParticipantFormData(),
  confirm_email: getEmailConfirmation()
});
