<div class="content">
  <lib-absent-answers class="filled align-center mbe-30" [counter]="absentAnswers"></lib-absent-answers>

  <div class="flex space-between info-container" *ngIf="mustShowInfoContainer()" [class.is-preview]="preview">
    <lib-goto-back-link [buttonTitle]="button" (redirectTo)="back.emit()"></lib-goto-back-link>
    <span class="flex align-center">
      <a mat-flat-button color="secondary" class="is-with-icon is-squared question-list" (click)="questions.emit()">
        <mat-icon svgIcon="question-list"></mat-icon>
      </a>
      <lib-contact-info class="ml-10" [flatBtn]="true"></lib-contact-info>
    </span>
  </div>

  <ng-content select="[question-or-consent]"></ng-content>
</div>

<ng-content select="[question-navigation]"></ng-content>

<mat-spinner mode="indeterminate"></mat-spinner>
