import { ActivatedRouteSnapshot } from '@angular/router';
import { Moment, MomentInput, now } from 'moment/moment';

import { IProcedure, ProcedureStatusType } from '../../models';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export interface ReviewAnswerStatus {
  title: string;
  dateTime: string;
  content: string;
}

export interface ProcedureStatusState {
  procedure: ProcedureStatusType;
  hasConclusionPopup: boolean;
  pending: boolean;
  isEligible: boolean;
  hasNotSubmitted: boolean;
  submitPending: boolean;
}

export const checkIfNextStateIsSurveyComponent = (nextState: ActivatedRouteSnapshot): boolean => {
  let isSurveyComponent = nextState.data?.preventBackFromSubmitted;

  if (!isSurveyComponent && nextState.children.length > 0) {
    isSurveyComponent = checkIfNextStateIsSurveyComponent(nextState.children[0]);
  }

  return isSurveyComponent;
};

export const isProcedureJustSubmitted = (procedure: IProcedure): boolean => {
  const submittedMoment = moment(procedure.created_at);
  const diffInMinutes = moment(now()).diff(submittedMoment, 'minutes');

  return diffInMinutes < 1;
};
