import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AsyncValidatorFn, FormControl } from '@angular/forms';
import { Moment, MomentInput } from 'moment';
import { Subscription } from 'rxjs';

import {
  dateLongFormat,
  dateStrictPattern,
  FormControlErrorHandler,
  isEmptyValue,
  isNonEmptyString,
  isNullOrUndefined,
  isRealValue,
  serverDateFormat,
  ValidatorType,
  WidgetErrorStateMatcher,
} from '@tr-common';

import {
  checkAsyncWarnings,
  getDateValue,
  hasOnlyAsyncWarnings,
  SourceChange,
  SourceChangeType,
  WidgetModify,
  WidgetPrototype,
} from '../../../models';
import { StudyOption } from '../../../models/study-option';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

@Component({
  selector: 'lib-base-date',
  template: ``,
})
export class BaseDateComponent implements WidgetPrototype<string>, OnChanges, OnDestroy {
  @ViewChild('dateInput', {static: true}) dateInputElement: ElementRef;
  @Input() option: StudyOption;
  @Input() value: string;
  @Input() asyncValidators: AsyncValidatorFn[];
  @Output() modify = new EventEmitter<WidgetModify<string>>();
  dateControl = new FormControl<Moment>(null, {updateOn: 'blur'});
  selected = null;
  valid: ValidatorType = 'default';
  errorMatcher = new WidgetErrorStateMatcher();
  format = dateLongFormat;
  serverFormat = serverDateFormat;
  pattern = dateStrictPattern;
  hasOnlyAsyncWarnings = hasOnlyAsyncWarnings;
  subscription = new Subscription();
  readonly errorHandlers: FormControlErrorHandler[] = [{
    mustBeShowed: (control: FormControl<Moment>): boolean => isRealValue(control?.errors?.matDatepickerParse), message: 'Invalid date.'
  }];

  constructor() {
    this.subscription.add(
      this.dateControl.valueChanges.subscribe((value: Moment) => {
        this.valid = checkAsyncWarnings(this.dateControl.status as ValidatorType, this.dateControl.errors);
        this.getDateValueAndEmit(value?.format(this.format));
      })
    );
  }

  @HostBinding('class.invalid') get isValid() { return this.valid === 'INVALID'; }

  ngOnChanges({value, asyncValidators}: SimpleChanges): void {
    const noEmit = {emitEvent: false};

    if (isRealValue(asyncValidators)) {
      this.dateControl.setAsyncValidators(this.asyncValidators);
    }
    if (isRealValue(value)) {
      if (isNonEmptyString(this.value)) {
        this.selected = this.value;
        this.dateControl.setValue(moment(this.value, this.serverFormat), noEmit);
        this.valid = this.dateControl.valid ? 'VALID' : 'INVALID';
        this.dateControl.markAsDirty();
      } else if (!value?.firstChange) {
        this.selected = null;
        this.valid = 'default';
        this.dateControl.reset(null, noEmit);
      }
      this.dateControl.updateValueAndValidity(noEmit);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  emitState(source: SourceChangeType): void {
    this.modify.emit({value: this.selected, valid: this.valid, source});
  }

  getDateValueAndEmit(value?: string): void {
    const dateInputValue = value ?? getDateValue(this.dateInputElement);

    // if input has been cleared then emit new value
    if (isEmptyValue(dateInputValue)) {
      this.selected = null;
      this.dateControl.setValue(this.selected, {emitEvent: false});
      this.emitState(SourceChange.user);
      return;
    }

    this.selected = [moment(dateInputValue, this.format)].filter(v => v.isValid()).map(v => v.format(this.serverFormat))[0];

    if (isNullOrUndefined(this.selected)) {
      this.selected = null;
    }

    // if input contains valid value then emit new value
    if (isRealValue(this.selected) && isNonEmptyString(dateInputValue) && new RegExp(this.pattern).test(dateInputValue)) {
      this.emitState(SourceChange.user);
    }
  }
}
