import { createAction } from '@ngrx/store';

export enum loaderActions {
  GLOBAL_LOADER_START = '[Loader] Global Loader Start',
  GLOBAL_LOADER_END = '[Loader] Global Loader End',
}

export const globalLoaderStart = createAction(
  loaderActions.GLOBAL_LOADER_START
);

export const globalLoaderEnd = createAction(
  loaderActions.GLOBAL_LOADER_END
);
