import { isEmptyValue, isNonEmptyArray, isNonEmptyString, isRealValue } from '@tr-common';

import { filterPhoneNumber } from './filter-phone-number';

export interface FullPhoneNumber {
  countryCode: string;
  phoneNumber: string;
}

export interface CountryInfo {
  country: string;
  iso: string;
  iso3: string;
  national_number: string;
  phone: string;
}

export const sortCountries = (src: CountryInfo[]): CountryInfo[] => {
  const [recordUSA, ...results] = src;
  const sortedResults = results.sort((a, b) => a.country.localeCompare(b.country));

  return [recordUSA, ...sortedResults];
};

export const findCodeInInfo = (
  countryCode: string, codes: CountryInfo[]
): CountryInfo => isNonEmptyString(countryCode) ? codes?.find(({phone}) => countryCode.indexOf(phone) === 0) : null;

export const normalizePhoneNumber = ({countryCode, phoneNumber}: Partial<FullPhoneNumber>, codes: CountryInfo[]): FullPhoneNumber => {
  const filteredPhoneNumber = isNonEmptyString(phoneNumber) ? filterPhoneNumber(phoneNumber) : '';
  const isPhoneNumberStartedFromPlus = /^\+\d/.test(filteredPhoneNumber);
  // The information below was found on https://www.ricksteves.com/travel-tips/phones-tech/call-uk-europe
  const isPhoneNumberStartedFrom011 = /^011\d/.test(filteredPhoneNumber);
  const isPhoneNumberStartedFrom2Zeroes = /^00\d/.test(filteredPhoneNumber);
  let normalizedValue: FullPhoneNumber = null;

  if (isPhoneNumberStartedFromPlus) {
    const foundCode = findCodeInInfo(filteredPhoneNumber, codes);

    if (isRealValue(foundCode)) {
      normalizedValue = {countryCode: foundCode.phone, phoneNumber: filteredPhoneNumber.slice(foundCode.phone.length)};
    }
  } else if (isPhoneNumberStartedFrom011 || isPhoneNumberStartedFrom2Zeroes) {
    const adaptedPhoneNumber = '+' + filteredPhoneNumber.slice(isPhoneNumberStartedFrom2Zeroes ? 2 : 3);
    const foundCode = findCodeInInfo(adaptedPhoneNumber, codes);

    if (isRealValue(foundCode)) {
      normalizedValue = {countryCode: foundCode.phone, phoneNumber: adaptedPhoneNumber.slice(foundCode.phone.length)};
    }
  }

  return normalizedValue;
}

export const reformatInputPhoneNumber = (value: string): string => {
  if (isEmptyValue(value)) return '';

  return (value[0] === '+' ? value.substring(1) : value).toLowerCase();
};

export const splitTelNumber = (tel: string, codes: CountryInfo[]): FullPhoneNumber => {
  let plainValue: FullPhoneNumber = {countryCode: '', phoneNumber: tel};

  if (isNonEmptyArray(codes) && tel !== '') {
    const found = codes.find(({phone}) => tel.indexOf(phone) === 0);

    if (found === undefined) {
      console.warn(`Can't split phone number by country code; set as is`);
    } else {
      plainValue = {countryCode: found.phone, phoneNumber: tel.substring(found.phone.length)};
    }
  }

  return plainValue;
};

export function filterDecimalsInPhone(event: KeyboardEvent): boolean {
  const {value} = this;
  const {key} = event;
  const isSingleSymbol = isRealValue(key) && key.length === 1;
  const isControlSymbol = isRealValue(key) && key.length > 1;
  const keyIsDecimalOrControl = isSingleSymbol && isRealValue(key.match(/\d/)) || isControlSymbol;

  return value.length <= 15 && keyIsDecimalOrControl;
}
