import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Memoize } from 'lodash-decorators';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AllStates, filterStates, isNonEmptyString, isNullOrUndefined, isRealValue } from '@tr-common';

import { TimeZone, UserHouseholdFormType } from '../../models';

@Component({
  selector: 'lib-household-info-form',
  templateUrl: './household-info-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./household-info-form.component.scss']
})
export class HouseholdInfoFormComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup<UserHouseholdFormType>;
  @Input() states: AllStates;
  @Input() timeZones: TimeZone;
  values$: Observable<AllStates>;
  subscriptions = new Subscription();

  get householdAddress(): FormControl<string> { return this.parentForm.controls.address; }
  get householdCity(): FormControl<string> { return this.parentForm.controls.city; }
  get householdState(): FormControl<string> { return this.parentForm.controls.country_state; }
  get householdZip(): FormControl<string> { return this.parentForm.controls.zip_code; }
  get timezone(): FormControl<number> { return this.parentForm.controls.timezone; }
  get isStateFieldNotEmpty(): boolean { return isNonEmptyString(this.householdState.value); }
  get isIncorrectState(): boolean {
    return this.isStateFieldNotEmpty && isNullOrUndefined(this.householdState.errors?.server);
  }

  @Memoize() getTimeZoneKeys(): string[] {
    return isRealValue(this.timeZones) ? Object.keys(this.timeZones) : [];
  }

  ngOnInit(): void {
    this.values$ = this.householdState.valueChanges.pipe(
      startWith(null),
      map((value: string) => filterStates(value, this.states)),
    );
    this.subscriptions.add(
      this.householdZip.valueChanges.subscribe(value => {
        const newValue = value.trim();

        if (newValue !== value) {
          this.householdZip.setValue(newValue, {emitEvent: false});
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clearStateField(): void {
    this.householdState.setValue('');
    this.householdState.setErrors(null);
  }

  onSelectState({option}: MatAutocompleteSelectedEvent): void {
    this.householdState.setValue(option.value);
  }

  // @ts-ignore
  openStatesDropdown(event: MouseEvent, autocomplete: HTMLInputElement): void {
    event.stopPropagation();
    autocomplete.focus();
  }
}
