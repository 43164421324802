import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { exchangeContactInfoT1D } from '@tr-common';

@Component({
  selector: 'lib-sign-consent-form',
  template: `<ng-container *ngIf="consentBody">
    <div #consentBodyContainer [innerHTML]="consentBody | safeHtml" class="consent-text"></div>

    <div class="sign-container">
      <div class="inputs-container">
        <mat-form-field appearance="outline">
          <mat-label>Signature</mat-label>
          <input autocomplete="given-name" matInput [(ngModel)]="name"/>
        </mat-form-field>
      </div>

      <div class="desktop-checkbox">
        <mat-checkbox #agreeConfirm [(ngModel)]="confirmCheckbox" color="primary">{{ consentAgreement }}</mat-checkbox>
      </div>

      <button class="survey-button is-shadowed" mat-flat-button color="primary" [disabled]="!agreeConfirm.checked || name === ''"
        (click)="onSignClick(consentBodyContainer)">
        Continue
      </button>
    </div>

    <p class="tr-consent-note">
      If you have any questions before you consent to take part in this study, please contact us at
      <a href="tel:{{ contactInfo.phone }}">{{ contactInfo.phone }}</a> or
      <a href="mailto:{{ contactInfo.email }}">{{ contactInfo.email }}</a>
    </p>
  </ng-container>`,
  styleUrls: ['./sign-consent-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignConsentFormComponent {
  @Input() consentBody: string;
  @Input() consentAgreement: string;
  @Input() isChildConsent: boolean;
  @Output() sign = new EventEmitter<{name: string}>();
  name = '';
  contactInfo = exchangeContactInfoT1D;
  confirmCheckbox: false;

  onSignClick(consentBodyContainer: HTMLDivElement) {
    consentBodyContainer.scrollIntoView(true);
    this.sign.emit({name: this.name});
    this.name = '';
    this.confirmCheckbox = false;
  }
}
