import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { isObject, isRealValue, OptionAnswerType, ValidatorType } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption, StudyOptionType } from '../../../models/study-option';
import { calculateDropdownWidth } from '../../models';

@Component({
  selector: 'lib-dropdown-widget',
  template: `
    <mat-form-field appearance="outline" [ngStyle]="{'width': cssWidth + 'px'}">
      <mat-select [value]="selected" (valueChange)="onValueChange($event)" placeholder="Please select">
        <ng-container *ngIf="optionsToDisplay">
          <mat-option *ngFor="let option of optionsToDisplay" [value]="option.id">{{ option.title }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: ['./dropdown-widget.component.scss']
})
export class DropdownWidgetComponent implements WidgetPrototype<OptionAnswerType>, OnChanges {
  @Input() option: StudyOption; // it's always undefined
  @Input() options: StudyOptionType[];
  @Input() value: OptionAnswerType;
  @Output() modify = new EventEmitter<WidgetModify<OptionAnswerType>>();
  selected: string = null;
  valid: ValidatorType = 'default';
  // It's work-around due to inconsistent implementation of dropdown StudyOption items in study-survey and study-tree
  cssWidth: number;

  get optionsToDisplay(): StudyOptionType[] { return this.options || this.option?.options; }

  ngOnChanges({value, options}: SimpleChanges): void {
    if (isRealValue(value)) {
      if (value.currentValue) {
        this.selected = isObject(this.value) ? this.value.question_option : null;
        this.valid = this.options.some(o => this.selected === o.id) ? 'VALID' : 'INVALID';
      } else if (isRealValue(value) && !value.firstChange) {
        this.selected = null;
        this.valid = 'VALID';
      }
    }
    if (isRealValue(options)) {
      this.cssWidth = calculateDropdownWidth(this.options);
    }
  }

  onValueChange(question_option: string): void {
    this.selected = question_option;
    this.valid = 'VALID';
    this.emitState(SourceChange.user);
  }

  emitState(source: SourceChangeType): void {
    this.modify.emit({
      value: isRealValue(this.selected) ? {question_option: this.selected, value: true} : null,
      valid: this.valid,
      source
    });
  }
}
