import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-reject-registration-dialog',
  template: `
    <div mat-dialog-title><img class="mbs-30" src="/assets/dialog/cloud-reject.svg" alt="Error!" /></div>

    <div mat-dialog-content class="center">
      <p class="message">
        Your responses show irregularities that suggest you have already registered and are trying to register again,
        or that you/your device are otherwise trying to bypass security features.
      </p>
      <p>Unfortunately, you will not be able to register.</p>
      <p>
        If you believe you are eligible and that this is in error, please contact us at
        <a href="mailto:Registry@T1DExchange.org" tabindex="-1">Registry@T1DExchange.org</a>
      </p>
    </div>

    <div mat-dialog-actions class="mt-30">
      <button mat-flat-button color="primary" mat-dialog-close>Close</button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule]
})
export class RejectRegistrationPopUpComponent {}
