import { Component, OnDestroy, OnInit } from '@angular/core';

import { isRealValue, LayoutService } from 'tr-lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-landing-info',
  template: `
    <app-main-banner></app-main-banner>
    <app-registry-description></app-registry-description>
    <app-registry-security-info></app-registry-security-info>
    <app-get-in-touch></app-get-in-touch>
    <app-footer></app-footer>
    <div *ngIf="isButtonVisible" class="landing-button-up">
      <button mat-flat-button class="is-squared is-with-icon" color="secondary" (click)="scrollTop()">
        <mat-icon svgIcon="arrow-up"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./landing-info.component.scss'],
})
export class LandingInfoComponent implements OnInit, OnDestroy {
  isButtonVisible = false;
  private subscription = new Subscription();

  constructor(private layout: LayoutService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.layout.scrollTop$.subscribe((scrollTop: number) => {
        this.isButtonVisible = scrollTop > 200;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scrollTop(): void {
    if (isRealValue(this.layout.scrollSrcElement)) {
      this.layout.scrollSrcElement.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
