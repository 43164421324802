import { Component, Input, ViewEncapsulation } from '@angular/core';

import { participantSource, ParticipantSourceType } from '@tr-common';

import { ProcedureStatusType } from '../../models';

@Component({
  selector: 'lib-not-eligible-wrapper',
  template: `
    <ng-container *ngIf="source">
      <div class="not-eligible-image"><img src="assets/people_with_phone.svg" alt="Not eligible state"></div>

      <div class="content" [ngClass]="{'jaeb': source === (participantSource.jaeb || participantSource.registry_jaeb)}">
        <ng-content select="source-content"></ng-content>
        <ng-content select="source-buttons"></ng-content>
        <lib-society-info *ngIf="socialLinks"></lib-society-info>

        <address class="text-normal">
          Phone: <a href="tel:{{ phone }}">{{ phone }}</a><br>
          Email: <a href="mailto:{{ email }}">{{ email }}</a>
        </address>
      </div>
    </ng-container>
  `,
  styleUrls: ['./not-eligible-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotEligibleWrapperComponent {
  @Input() source: ParticipantSourceType;
  @Input() email: string;
  @Input() phone: string;
  @Input() procedureStatus: ProcedureStatusType;
  @Input() socialLinks: boolean;
  readonly participantSource = participantSource;
}
