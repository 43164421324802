export const trEmailId = 'tr-email-id';

export const getTrEmailIdFromUrl = (url: string): string => {
  const isURLHasQueryParams = url.match(/\?/);
  let result = null;

  if (isURLHasQueryParams) {
    const [, tail] = url.split('?');
    const queryParams = tail.split('&');
    const idx = queryParams.findIndex(q => q.match(trEmailId));

    if (idx >= 0) {
      [, result] = queryParams[idx].split('=');
    }
  }

  return result;
}

export const cutTrEmailIdFromUrl = (url: string): string => {
  const isURLHasQueryParams = url.match(/\?/);
  let result = url;

  if (isURLHasQueryParams) {
    const [lead, tail] = url.split('?');
    let queryParams = tail;

    if (tail.match(trEmailId)) {
      const arr = tail.split('&');
      const idx = arr.findIndex(q => q.match(trEmailId));

      arr.splice(idx, 1);
      queryParams = arr.length > 0 ? arr.join('&') : '';
    }
    result = lead + (queryParams.length > 0 ? `?${queryParams}` : '');
  }

  return result;
};
