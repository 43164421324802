import { answerWidget, isObject, isRealValue, OptionAnswerType } from '@tr-common';

import { TreeNode } from '../../models';
import { StudyOption } from '../../models/study-option';
import { BuildTreeSourceData, getQuestionAnswers } from './build-tree.helpers';

/**
 * it's a special case for the autocomplete widgets
 * all the autocomplete options are converted to an option with the autocompleteOptions field
 * selected option id is stored in value field and is pre-populated from questionAnswers
 *
 * @param sourceData
 * @param autocompleteOptions
 * @param parent node
 * @param nodeIndex
 * @param parentOptionIndex
 */
export const generateAutocompleteNode = (
  sourceData: BuildTreeSourceData, autocompleteOptions: StudyOption[], parent: TreeNode, nodeIndex: number, parentOptionIndex?: number
): [] | [TreeNode] => {
  if (autocompleteOptions.length === 0) return [];

  const {question} = sourceData;
  const treeNode = new TreeNode<OptionAnswerType>({
    autocompleteOptions, nodeIndex, parentOptionIndex, parent,
    isQuestion: false, showHint: parent.showHint,
    questionID: question.id
  });
  const questionAnswer = getQuestionAnswers(sourceData).find(q => autocompleteOptions.some(a => a.id === q.question_option));
  let optionAnswer: OptionAnswerType = null;

  if (isObject(questionAnswer)) {
    const {question_option, value} = questionAnswer;
    const answerOption = autocompleteOptions.find(option => option.id === questionAnswer.question_option);
    const isOptionAutocompleteText = isRealValue(answerOption) && answerOption.widget === answerWidget.autocompleteText;

    optionAnswer = {question_option, value: (value === 'true' || value) as string | boolean};
    if (isOptionAutocompleteText) {
      treeNode.data = new StudyOption(answerOption);
    }
  }
  treeNode.value = optionAnswer;

  return [treeNode];
};
