import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { isRealValue, ValidatorType  } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption } from '../../../models/study-option';
import { LayoutService } from '../../../services';

@Component({
  selector: 'lib-matrix-scale-widget',
  template: `
    <ng-container *ngIf="{isMobile: layout.isMobile$ | async} as device">
      <div *ngFor="let option of options; let idx = index; let filled = even;" class="with-scale-widget">
        <mat-label *ngIf="device.isMobile" [innerHTML]="option.title"></mat-label>
        <lib-likert-scale-widget [option]="option" [value]="allValues[idx]"
          [suppressTitles]="idx > 0 && !device.isMobile" [filled]="filled && !device.isMobile" (modify)="onModify($event, idx)">
        </lib-likert-scale-widget>
      </div>
    </ng-container>
  `,
  styleUrls: ['./matrix-scale-widget.component.scss']
})
export class MatrixScaleWidgetComponent implements WidgetPrototype<string | boolean>, OnChanges {
  @Input() option: StudyOption; // it's always undefined
  @Input() options: StudyOption[];
  @Input() value: string | boolean;
  @Output() modify = new EventEmitter<WidgetModify<string | boolean>>();
  allValues: number[] | boolean[];
  valid: ValidatorType = 'default';

  constructor(public layout: LayoutService) {}

  @HostBinding('class.is-mobile') get isMobile(): boolean {
    return this.layout.isMobile;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const defaultValues = new Array(this.options.length).fill(false);

    if (isRealValue(changes.options)) {
      this.allValues = defaultValues;
    }
    if (isRealValue(changes.value)) {
      this.allValues = typeof this.value === 'string' ? JSON.parse(this.value).map(v => v !== false ? +v : v) : defaultValues;
    }
  }

  emitState(source: SourceChangeType) {
    this.modify.emit({value: JSON.stringify(this.allValues), valid: this.valid, source});
  }

  onModify(response: WidgetModify<number | boolean>, idx: number): void {
    const isFilled = (v: number | boolean): boolean => v !== false;

    this.allValues[idx] = response.value;
    this.valid = (this.allValues as boolean[]).every(isFilled) ? 'VALID' : 'INVALID';
    this.emitState(SourceChange.user);
  }
}
