import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-sign-jaeb-consent',
  template: `
    <div class="consent-text" [innerHTML]="consentBody | safeHtml"></div>
    <div class="bottom">
      <button mat-flat-button color="primary" class="survey-button submit-btn is-shadowed" (click)="sign.emit()">
        Go to questionnaire
      </button>
    </div>
  `,
  styleUrls: ['./sign-jaeb-consent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignJaebConsentComponent {
  @Input() consentBody: string;
  @Output() sign = new EventEmitter<void>();
}
