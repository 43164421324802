import * as t from 'io-ts';

export enum CriteriaField {
  accountStatus = 'account_status',
  age = 'age',
  biologicalSex = 'sex',
  coreHowTakeInsulin = 'core_how_take_insulin',
  coreStudyStatus = 'core_study_status',
  cgm = 'cgm_shared',
  coreUseCGM = 'core_use_cgm',
  diagnosisDate = 'diagnosis_date',
  didNotAnswerQuestion = 'did_not_answer_question',
  ethnicity = 'ethnicity',
  isEligible = 'is_eligible',
  profileType = 'profile_type',
  race = 'race',
  source = 'source',
  studyAnswer = 'study_answer',
  usaState = 'addr_state',
  zipCode = 'zip',
}
export const allCriteriaFieldsSchema = t.union([
  t.literal(CriteriaField.accountStatus),
  t.literal(CriteriaField.age),
  t.literal(CriteriaField.biologicalSex),
  t.literal(CriteriaField.coreHowTakeInsulin),
  t.literal(CriteriaField.coreStudyStatus),
  t.literal(CriteriaField.coreUseCGM),
  t.literal(CriteriaField.cgm),
  t.literal(CriteriaField.diagnosisDate),
  t.literal(CriteriaField.didNotAnswerQuestion),
  t.literal(CriteriaField.ethnicity),
  t.literal(CriteriaField.isEligible),
  t.literal(CriteriaField.profileType),
  t.literal(CriteriaField.race),
  t.literal(CriteriaField.source),
  t.literal(CriteriaField.studyAnswer),
  t.literal(CriteriaField.usaState),
  t.literal(CriteriaField.zipCode),
], 'allCriteriaFieldsSchema');
