import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as t from 'io-ts';

import { isEmptyString, isNonEmptyArray, isRealValue } from './helpers';

export const allStatesSchema = t.array(t.string, 'allStatesSchema');
export type AllStates = t.TypeOf<typeof allStatesSchema>;

export const getStateValidator  = (allStates: AllStates): ValidatorFn => ({value}: FormControl<string>): ValidationErrors | null => {
  const isValid = isEmptyString(value) || allStates.indexOf(value) >= 0;

  return isValid ? null : {invalid: true};
};

export const filterStates = (value: string, allStates: AllStates): AllStates => {
  const filterValue = isRealValue(value) ? value.toLowerCase() : '';

  return isNonEmptyArray(allStates) ? allStates.filter(option => option.toLowerCase().includes(filterValue)) : [];
};
