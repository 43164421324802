import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as t from 'io-ts';

import { isNonEmptyString } from 'tr-lib';

import { smartEmailCheck, smartEmailCheckCoincident } from '@store/actions';

export interface SmartConfirmation {
  isCoincident: boolean;
  isDoubtful: boolean;
  message: string;
}

export const smartConfirmationInitialState: SmartConfirmation = {
  isCoincident: false,
  isDoubtful: false,
  message: 'OK'
};

export const isOkSchema = t.type({
  is_ok: t.boolean
}, 'isOkSchema');
export type IsOkType = t.TypeOf<typeof isOkSchema>;

export const getMessage = (
  isOk: boolean
): string => isOk ? 'OK' : 'Looks like you might have made a spelling error. Please confirm the email address.';

export const getWarning = (
  {isCoincident, isDoubtful, message}: SmartConfirmation
): null | {[message: string]: 'asyncWarnings'} | {['server']: string} => isCoincident
  ? null
  : isDoubtful
    ? {[message]: 'asyncWarnings'}
    : message === 'OK' ? null : {server: message};

export const smartEmailCheckAndDispatch = (
  emailControl: FormControl<string>, emailConfirmControl: FormControl<string>, store: Store
): boolean => {
  const payload = emailControl?.value;
  const needToCheck = !emailControl?.invalid && isNonEmptyString(payload);

  if (needToCheck) {
    const confirmationEmail = emailConfirmControl?.value;

    store.dispatch(smartEmailCheck({payload}));
    if (isNonEmptyString(confirmationEmail) && confirmationEmail !== payload) {
      store.dispatch(smartEmailCheckCoincident({payload: false}));
    }
  }

  return needToCheck;
};
