import { AbstractControl, FormControl } from '@angular/forms';
import { isMoment } from 'moment';
import { Moment, MomentInput } from 'moment/moment';

import { ValidateResults } from './validate-common';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export const pastDate = (
  {value}: AbstractControl
): ValidateResults => moment(value).isBefore(moment().startOf('d')) ? {pastDate: true} : null;

export const rangeCheck = (endDate: FormControl<Moment>, startDate: FormControl<Moment>): ValidateResults => {
  const startValue = startDate.value;
  const endValue = endDate.value;

  return isMoment(endValue) && isMoment(startValue) && startValue.isAfter(endValue) ? {invalidRange: true} : null;
}
