import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';

import { isRealValue, nullable } from '@tr-common';

import { studyConditionSchema } from '../../models';

export const consentInfoSchema = t.intersection([
  t.type({
    consent_type: t.string,
    id: t.number,
    order: t.number,
    rule_description: nullable(t.string),
    rule: nullable(t.array(studyConditionSchema)),
  }),
  t.interface({
    text: nullable(UUID),
    study: nullable(UUID),
  }),
  t.partial({
    description: nullable(t.string),
    created_at: nullable(t.string),
    updated_at: nullable(t.string),
  })
], 'consentInfoSchema');
export type ConsentInfoType = t.TypeOf<typeof consentInfoSchema>;

export const consentsSchema = t.array(consentInfoSchema, 'consentArrayInfoSchema');
export type Consents = t.TypeOf<typeof consentsSchema>;

export class ConsentInfo implements ConsentInfoType {
  consent_type = 'universal';
  id = 0;
  order = 0;
  rule_description = null;
  rule = null;
  text = null;
  study = null;
  description = 'Empty consent';
  created_at = new Date().toISOString();
  updated_at = null;

  constructor(data?: ConsentInfoType) {
    if (isRealValue(data)) {
      Object.assign(this, data);
    }
  }
}
