import * as t from 'io-ts';

import { LoadFullStudySourcesType } from './survey-helpers';

export enum SurveyPreselect {
  fullLoad = 'Check after full study load',
  regular = 'Check after every router change',
  review = 'Check when participant has chosen review answers',
  status = 'Check when rerouted to the procedure status page'
}
export const surveyPreselectSchema = t.union([
  t.literal(SurveyPreselect.fullLoad),
  t.literal(SurveyPreselect.regular),
  t.literal(SurveyPreselect.review),
  t.literal(SurveyPreselect.status),
], 'surveyPreselectSchema');
export type SurveyPreselectType = t.TypeOf<typeof surveyPreselectSchema>;

// TODO We are supposed to figure out if we can use 'sourceType' as a return value (https://unitio.atlassian.net/browse/TR-3479)
export const getReviewOrFullPreselect = (
  sourceType: LoadFullStudySourcesType
): SurveyPreselectType => sourceType === 'review' ?  SurveyPreselect.review : SurveyPreselect.fullLoad;
