import { ErrorDialogData } from 'tr-lib';

export const errorMaxReattemptsReached = 'Max number of screening reattempts reached';

export const errorReattemptDialogData: ErrorDialogData = {
  hideCloseIcon: true,
  sorry: 'Sorry!',
  action: 'It looks like you completed our screening questions multiple times.' +
    ' Unfortunately, at this time you are not eligible to participate in the Registry.' +
    ' We will contact you in the future if this changes.',
  whatToDo: `Thank you for your interest in supporting our type 1 diabetes research!`
};
