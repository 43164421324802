import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { isRealValue, ITrackingService } from '@tr-common';

import { GoogleAnalyticEvent } from '../global/google-analytics-event';
import { GlobalRef } from '../global/registry-global';
import { REGISTRY_PARTICIPANT_URL, TRACKING } from '../global/tokens';

@Injectable({providedIn: 'root'})
export class TrackingService implements ITrackingService {
  private lastTrackedUrl = '';

  constructor(
    @Inject(TRACKING) private isTracking: boolean,
    @Inject(REGISTRY_PARTICIPANT_URL) private apiUrl: string,
    private http: HttpClient,
    private global: GlobalRef
  ) {}

  private get canTrack(): boolean {
    const ga = this.global.nativeGlobal.ga;

    return this.isTracking && typeof ga === 'function';
  };

  registerClickOnResearchProcedure(promotionID: string, userID: string, userStudyID: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/${userID}/user_study/${userStudyID}/promotions/${promotionID}/click`, {});
  }

  trackPage(url: string): void {
    if (!this.isTracking) return;
    if (this.lastTrackedUrl !== url) {
      this.googleTrackPage(url);
      this.googleTagPage(url);
      this.facebookTrackPage();
      this.lastTrackedUrl = url;
    } else {
      console.warn(`It was an attempt to track the same url ${url}`);
     }
  }

  trackRotate(): void {
    if (this.canTrack) {
      const payload = {
        eventAction: 'Rotate mobile to landscape mode',
        eventCategory: 'Consumption',
        eventLabel: ''
      };

      this.global.nativeGlobal.ga('send', 'event', payload);
    }
  }

  trackResearchProcedure(trUserId: string, studyID: string): Observable<boolean> {
    let canRegister = false;

    if (this.canTrack) {
      const payload = {
        eventAction: 'Registration Research Click',
        eventCategory: `studyID: ${studyID}`,
        eventLabel: `userID: ${trUserId}`
      };

      this.global.nativeGlobal.ga('send', 'event', payload);
      canRegister = true;
    }

    return of(canRegister);
  }

  trackConnectionToDexcom(alchemerID: string, dexcomID: string): void {
    if (this.canTrack) {
      const payload = {
        eventAction: 'Registration Connection to Dexcom Account',
        eventCategory: `alchemerID: ${alchemerID}`,
        eventLabel: `dexcomID: ${dexcomID}`
      };

      this.global.nativeGlobal.ga('send', 'event', payload);
    }
  }

  facebookTrackPage(): void {
    // it was implemented according to https://developers.facebook.com/docs/facebook-pixel/implementation
    const fbq = this.global.nativeGlobal.fbq;

    if (fbq && typeof fbq === 'function') {
      fbq('track', 'PageView')
    }
  }

  /*
   * https://developers.google.com/analytics/devguides/collection/analyticsjs
   * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
   *
   * After you've set the new page value, all subsequents hits sent will use that new value. To record a pageview,
   * send a pageview hit immediately after updating the tracker.
   *
   * ga('set', 'page', '/new-page.html');
   * ga('send', 'pageview');
   *
   */
  googleTrackPage(url: string): void {
    if (this.canTrack) {
      const ga = this.global.nativeGlobal.ga;

      ga('set', 'page', url.split(/[?&]jtoken=/)[0]);
      ga('send', 'pageview');
      this.trackJaebRegistration(url);
    }
  }

  googleTagPage(url: string): void {
    // It has been implemented as it described on
    // https://stackoverflow.com/questions/33299429/google-tag-manager-and-single-page-apps
    const dataLayer = this.global.nativeGlobal.dataLayer || [];

    dataLayer.push({event: 'virtualPageView', page: {url}});
  }
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
  private trackJaebRegistration(url: string): void {
    const ga = this.global.nativeGlobal.ga;
    let payload: GoogleAnalyticEvent;

    if (url.match(/register\?jtoken=/)) {
      const [, trUserId, again] = url.split(/[=&]/);

      payload = {
        eventAction: 'Registration Jaeb users',
        eventCategory: 'FromEmail' + (isRealValue(again) ? ' (iteration)' : ''),
        eventLabel: trUserId
      };
    } else if (url.match(/dual-factor-authentication\?register=true&jtoken=/)) {
      const [, , trUserId] = url.split('=');

      payload = {
        eventAction: 'Registration Jaeb users (2FA)',
        eventCategory: 'FromEmail',
        eventLabel: trUserId
      };
    }

    if (isRealValue(payload)) {
      ga('send', 'event', payload);
    }
  }
}
