import { answerWidget, isRealValue, isSelectWidgetsPresented } from '@tr-common';

import { TreeNode } from '../../models';
import { StudyOption } from '../../models/study-option';
import { BuildTreeSourceData, getOrderedOptions, getValueFromAnswers } from './build-tree.helpers';
import { generateAutocompleteNode } from './generate-autocomplete-node';
import { generateMultiselectNode } from './generate-multiselect-node';
import { generateDropdownNode } from './generate-dropdown-node';
import { generateMatrixScaleNode, sortNodesWithMatrixScale } from './generate-matrix-scale-node';

/**
 * StudyOption to TreeNode conversion with setting answer into value recursive build tree for children
 *
 * @param sourceData
 * @param parent,
 * @param optionNodeIndex,
 * @param parentOptionIndex
 */
export const optionToNode = (
  sourceData: BuildTreeSourceData, parent: TreeNode, optionNodeIndex: number, parentOptionIndex?: number
) => (option: StudyOption, nodeIndex = 0): TreeNode => {
  const {question: {id}, optionQuestionMap} = sourceData;
  const {showHint} = parent;
  const treeNode = new TreeNode(
    {parent, showHint, nodeIndex, parentOptionIndex, data: option, questionID: id, isQuestion: false
  });
  const subQuestions = optionQuestionMap[option.id];

  treeNode.value = getValueFromAnswers(option, sourceData);
  if (isRealValue(subQuestions)) {
    treeNode.children = subQuestions.map(
      (s, i) => buildTree({...sourceData, question: s}, i, nodeIndex + 1)
    ).reduce((a: TreeNode[], c: TreeNode[]) => [...a, ...c], []);
  }

  return treeNode;
};
/**
 * Question 1
 * Option 1
 * Option 2 -
 *          Question 2
 *          Option 3
 *          Option 4
 *
 * @param sourceData
 * @param parentOptionIndex
 * @param nodeIndex
 */
export const buildTree = (
  sourceData: BuildTreeSourceData, nodeIndex?: number, parentOptionIndex?: number
): TreeNode[] => {
  const {question} = sourceData;
  const data = question.clone({shortTitleMode: true});
  const treeNode = new TreeNode({
    data, questionID: question.id, nodeIndex, parentOptionIndex,
    showHint: sourceData.showHint === true && sourceData.unfilledList.includes(question.id)
  });
  const options = getOrderedOptions(question);
  const [dropdownOptions, autocompleteOptions, multiselectOptions, matrixScaleOptions, allOtherOptions] = [
    options.filter(({widget}) => widget === answerWidget.dropdown),
    options.filter(({widget}) => widget === answerWidget.autocompleteItem || widget === answerWidget.autocompleteText),
    options.filter(({widget}) => widget === answerWidget.multiselectItem || widget === answerWidget.multiselectText),
    options.filter(({widget}) => widget === answerWidget.matrixScale),
    options.filter(({widget}) => !isSelectWidgetsPresented(widget))
  ];
  const dropdownNode = generateDropdownNode(sourceData, dropdownOptions, treeNode, nodeIndex, parentOptionIndex);
  const autocompleteNode = generateAutocompleteNode(sourceData, autocompleteOptions, treeNode, nodeIndex, parentOptionIndex);
  const multiselectNode = generateMultiselectNode(sourceData, multiselectOptions, treeNode, nodeIndex, parentOptionIndex);
  const matrixScaleNode = generateMatrixScaleNode(sourceData, matrixScaleOptions, treeNode, nodeIndex, parentOptionIndex);
  const treeOptionNodes = allOtherOptions.map(optionToNode(sourceData, treeNode, nodeIndex, parentOptionIndex));
  const orderedNodes = sortNodesWithMatrixScale(matrixScaleNode, treeOptionNodes);

  return [treeNode, ...dropdownNode, ...autocompleteNode, ...multiselectNode, ...orderedNodes];
};
