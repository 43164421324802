// noinspection JSUnusedGlobalSymbols

import * as t from 'io-ts';
import { isEqual as _isEqual, isObject as _isObject } from 'lodash';

export const nullable = <C extends t.Mixed>(codec: C) => t.union([t.null, codec]);
export const twoTypesNullable = <F extends t.Mixed, S extends t.Mixed>(first: F, second: S) => t.union([t.null, first, second]);
export const voidable = <C extends t.Mixed>(codec: C) => t.union([t.null, t.undefined, codec]);
export const isNullOrUndefined = (val: unknown): val is (null | undefined) => val === null || val === undefined;
export const isNullOrFalse = (val: unknown): val is (null | false) => val === null || val === false;
export const isRealValue = <T = unknown>(val: T): val is Exclude<T, null | undefined> => !isNullOrUndefined(val);
export const isEmptyValue = (val: unknown): val is (undefined | null | false) => isNullOrUndefined(val) || val === false || val === '';
export const tryWithFallback = <T, TOut>(val: T, cont: (v: T) => TOut, fallback: TOut): TOut => isRealValue(val) ? cont(val) : fallback;
export const trySafely = <T, TOut>(val: T, cont: (v: T) => TOut): TOut => tryWithFallback(val, cont, null);
export const isNonEmptyArray = <T>(val: T[]): val is T[] => (val instanceof Array) && (val.length > 0);
export const isEmptyArray = <T = unknown>(val: unknown): val is Array<T> => (val instanceof Array) && (val.length === 0);
export const isEmptyString = (val: unknown): val is string => typeof val === 'string' && val.length === 0;
export const isNonEmptyString = (val: unknown): val is string => typeof val === 'string' && val.length > 0;
/**
 * @description wrapper on lodash `isObject`. Preserve types for input
 * @param val
 */
export const isObject = (val: unknown): val is {[k: string]: unknown} => _isObject(val);
/**
 * @description wrapper on lodash `isEqual`. Preserve types for input
 */
export const isEqual = <T>(fst: T, snd: T): boolean => _isEqual(fst, snd);

export type ValueOf<T> = T[keyof T];
export type KeysOf<TSource extends unknown, TVal> = { [k in keyof TSource]: TVal }
