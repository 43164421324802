import { pipe } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { mediumViewportWidth, mobileViewportWidth, tabletViewportWidth } from './constants';

export type PreviewDeviceType = 'desktop' | 'tablet' | 'mobile';

export const mobileMediaQuery = `(max-device-width: ${mobileViewportWidth}px)`;

export const mobileLandscapeMediaQuery = `only screen and (max-device-height: ${mobileViewportWidth}px)`;
export const iPhoneLandscapeMediaQuery = `only screen and (max-device-width: ${mobileViewportWidth}px) and (orientation:landscape)`;

export const tabletMediaQuery = `(min-device-width: ${mobileViewportWidth + 1}px) and (max-device-width: ${tabletViewportWidth}px)`;

export const mediumMediaQuery = `(max-device-width: ${mediumViewportWidth - 1}px)`;

export const likeDesktopQuery = `only screen and (min-device-width: ${tabletViewportWidth + 1}px)`;

export const matchQueryAndShare = pipe(
  map(({matches}: {matches: boolean}): boolean => matches),
  shareReplay(1), // simple `share` delivers fresh value only on first subscription or on new emit
);
