import { Store } from '@ngrx/store';

import { isNonEmptyArray, isNonEmptyString, isRealValue } from '@tr-common';

import { ProcedureQuestions, StudyConditionType, StudyOptionValidatorType } from '../../models';
import { StudyQuestionRuleType } from '../../models/rules';
import { StudyOption } from '../../models/study-option';
import { StudyQuestion } from '../../models/study-question';
import { AllIDs, filterConditionsByStudyIdAndOptionId, IStudyIDs, UserStudyType } from '../models';
import { changeActiveProcedure, changeActiveQuestion } from './actions';

export const setActiveProcedureAndQuestion = (store: Store, {procedure, questionID}: AllIDs): void => {
  if (isNonEmptyString(procedure)) {
    store.dispatch(changeActiveProcedure({payload: procedure}));
  }
  if (isNonEmptyString(questionID)) {
    store.dispatch(changeActiveQuestion({payload: questionID}));
  }
};

export const fetchUserStudyIdsFromRules = (
  rules: StudyQuestionRuleType[], studies: UserStudyType[], studyID: string
): IStudyIDs[] => rules.filter((rule) => isRealValue(rule.conditions)).reduce(
  (acc: IStudyIDs[], rule: StudyQuestionRuleType) => [
    ...acc,
    ...filterConditionsByStudyIdAndOptionId(acc, studyID, rule).reduce((a: IStudyIDs[], cond: StudyConditionType) => {
      const studyData = studies.find((study) => study.study_id === cond.stored_answer_path.study_id);

      return isRealValue(studyData)
        ? [...a, {user_study_id: studyData.id, optionId: cond.stored_answer_path.option_id}]
        : a
    }, [] as IStudyIDs[]),
  ],
[]);

export const fetchUserStudyIdsFromQuestions = (
  questions: ProcedureQuestions, studies: UserStudyType[], studyID: string
): IStudyIDs[] => {
  const validatorsOptions = {};
  // Making pair option_id and user_study_id to avoid duplicated calls
  Object.keys(questions).forEach(procedure => {
    questions[procedure].filter(
      (studyQuestion: StudyQuestion) => studyQuestion.options.some((studyOption: StudyOption) => studyOption.option_validators.length > 0)
    ).forEach((studyQuestion: StudyQuestion) => {
      studyQuestion.options.filter(
        (studyOption: StudyOption) => isNonEmptyArray(studyOption.option_validators),
      ).forEach((studyOption: StudyOption) => {
        studyOption.option_validators.filter(
          (optionValidator: StudyOptionValidatorType) => optionValidator?.stored_study_id !== studyID,
        ).forEach((optionValidator: StudyOptionValidatorType) => {
          const participantStudy: UserStudyType = studies.find(
            study => study.study_id === optionValidator.stored_study_id,
          );

          if (isRealValue(participantStudy)) {
            validatorsOptions[optionValidator.stored_option_id] = participantStudy.id;
          }
        });
      });
    });
  });

  return Object.keys(validatorsOptions).map((id: string) => ({optionId: id, user_study_id: validatorsOptions[id]}));
};
