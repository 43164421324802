<ng-container [formGroup]="formGroup">
  <ng-container formArrayName="pairs">
    <div *ngFor="let pair of pairs.controls; let i = index; trackBy: trackByIndex" class="flex align-center mbe-10 a1c-line"
      [class.no-display]="i >= numberOfA1cPairs" [formGroup]="pair" [class.invalid]="isRowInvalid(i)">
      <span class="flex column a1c-number">
        <span *ngIf="option.title" class="option-label mbe-5" [innerHTML]="option.title"></span>
        <mat-form-field appearance="outline" subscriptSizing="dynamic" [class.warning]="hasOnlyAsyncWarnings(pair.controls.a1c.errors)"
          [hintLabel]="limits.placeholder">
          <input id="{{ 'a1c-value-' + i }}" type="number" autocomplete="off" aria-autocomplete="none" matInput formControlName="a1c"
            [step]="option.isFloat ? 0.1 : 1" [placeholder]="placeholder" [errorStateMatcher]="errorMatcher"
          />
          <mat-error><lib-form-control-error [control]="pair.controls.a1c" [checkTouched]="true" [errorHandlers]="a1cErrorHandlers">
          </lib-form-control-error></mat-error>

          <div class="flex column numerical-arrows">
            <span class="material-icons up" (click)="increment(pair.controls.a1c)">keyboard_arrow_up</span>
            <span class="material-icons down" (click)="increment(pair.controls.a1c, -1)">keyboard_arrow_down</span>
          </div>
        </mat-form-field>
      </span>

      <mat-form-field class="a1c-date" appearance="outline" [class.warning]="hasOnlyAsyncWarnings(pair.controls.date.errors)">
        <input id="{{ 'a1c-date-' + i }}" type="text" autocomplete="off" aria-autocomplete="none" matInput formControlName="date"
          [placeholder]="datePlaceholder" [matDatepicker]="shortDP" [errorStateMatcher]="errorMatcher"
        />
        <mat-datepicker-toggle matSuffix [for]="shortDP">
          <mat-icon svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
        </mat-datepicker-toggle>
        <mat-error><lib-form-control-error [control]="pair.controls.date" [checkTouched]="true" [errorHandlers]="dateErrorHandlers">
        </lib-form-control-error></mat-error>
      </mat-form-field>

      <mat-datepicker #shortDP panelClass="short-datepicker" startView="multi-year"
        (monthSelected)="month(pair.controls.date, $event, shortDP)">
      </mat-datepicker>
    </div>
  </ng-container>
</ng-container>

<button type="button" class="survey-button" color="primary" mat-button [disabled]="numberOfA1cPairs >= 12" (click)="addMoreClick()">
  + Add More
</button>
