import { Observable } from 'rxjs';

import { BaseEventType, EventBeforePopupProcedureType, Events } from './events';
import { ProcedureTemplateType, ProcedureTemplateTypes, ProcedureWelcomeDialogType } from './procedure-template-type';

export interface EventsLog {
  registerStartedStudy(userId: string, study: string, user_study: number): Observable<BaseEventType>;

  registerStartedProcedure(userId: string, study: string, user_study: number, procedure: ProcedureTemplateType): Observable<BaseEventType>;

  sendJaebIntroEvent(userId: string): Observable<BaseEventType>;

  hasProcedureShowedWelcomeDialog(userId: string, study: string, user_study: string, procedure: ProcedureTemplateType): Observable<boolean>;

  getAlreadyStartedStudies(userId: string): Observable<BaseEventType[]>;

  getJaebIntroEvent(userId: string): Observable<BaseEventType[]>;
}

export type BeforeProcedureEventLogType = {
  [key in ProcedureWelcomeDialogType]: EventBeforePopupProcedureType
};

const relationProcedureAndEvent: BeforeProcedureEventLogType = {
  [ProcedureTemplateTypes.screening]: Events.beforeScreening,
  [ProcedureTemplateTypes.consent]: Events.beforeConsent,
  [ProcedureTemplateTypes.questionnaire]: Events.beforeQuestionnaire
};

export const hasProcedureWelcomeDialog = (
  procedure: ProcedureTemplateType
): boolean => [
  ProcedureTemplateTypes.screening, ProcedureTemplateTypes.consent, ProcedureTemplateTypes.questionnaire
].some(template => template === procedure);

export const getEventForWelcomeDialog = (
  procedure: ProcedureTemplateType
): EventBeforePopupProcedureType => hasProcedureWelcomeDialog(procedure) ? relationProcedureAndEvent[procedure] : null;
