import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { connectionError, internalServerError } from '@store/actions';
import { AppState } from '@app/model';

@Injectable({providedIn: 'root'})
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap({
      next: () => {},
      error: ({status, statusText}) => {
        if (status === 500) {
          this.store.dispatch(internalServerError());
        } else if (statusText === 'Unknown Error') {
          this.store.dispatch(connectionError())
        }
      },
    }));
  }
}
