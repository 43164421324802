import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { isNullOrUndefined, ParticipantSourceType } from 'tr-lib';

import { UserState } from '@store/reducers';
import { selectProfileSource } from '@store/selectors/user.selectors';
import { isJaebKeyForRegisterSuccess } from '@app/auth/models';

@Component({
  selector: 'app-register-success',
  template: `
    <app-not-auth-header class="only-logo"></app-not-auth-header>
    <div class="content">
      <div class="col-1">
        <h3 class="greetings" [innerHTML]="'register.success_title' | textReplace: (source$ | async) | safeHtml"></h3>
        <div class="img-container">
          <img src="/assets/img/checkmark.svg" alt="Checkmark on smartphone">
        </div>
      </div>

      <div class="col-2 description-section">
        <p [innerHTML]="'register.success_text' | textReplace: (source$ | async) | safeHtml"></p>
        <p>The T1D Exchange Registry aims to improve in these three key areas:</p>
        <ol>
          <li>Treatment</li>
          <li>Policy</li>
          <li>Coverage</li>
        </ol>
        <p>What new treatments or improvements will your participation yield in the next 5 years?</p>
        <p>T1D Exchange is committed to keeping your information private and secure. Please contact us with questions or concerns.</p>

        <div class="auth-actions">
          <button mat-flat-button color="primary" class="submit-btn" routerLink="/dashboard">Let's get started</button>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  `,
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent {
  source$: Observable<ParticipantSourceType> = this.store.select(selectProfileSource).pipe(
    startWith(null),
    map(profileSource => {
      let outcome = profileSource;

      if (isNullOrUndefined(profileSource)) {
        outcome = isJaebKeyForRegisterSuccess() ? 'jaeb' : 'registry';
      }

      return outcome;
    })
  );

  constructor(private store: Store<UserState>) {}
}
