import { SelectItemsType, surveyType, SurveyType } from 'tr-lib';

export type DashboardStudyStatusType = 'studies' | 'researches' | 'history' | 'closes';
export type DashboardStudyStatusStoreType = 'activeStudies' | 'activeOpportunities' | 'questionHistory' | 'closedStudies';
export type DashboardStudyIconType = 'active-studies-group' | 'active-research-group' | '';
export type DashboardStudyStatusFilterType = {
  [section in DashboardStudyStatusType]: {
    title: string,
    icon: DashboardStudyIconType,
    store: DashboardStudyStatusStoreType,
    items: SelectItemsType
  };
};
export type DashboardCombineFilterType = {
  [section in DashboardStudyStatusType]: string
}

export const dateOptionsForCompletedStudy = {year: 'numeric' as 'numeric', month: 'short' as 'short', day: 'numeric' as 'numeric'};
export const tooltipForStudyResearchAndCompleted = 'If you\'ve completed this study, the completion status will not show as ' +
  '"completed" and will only show as "viewed".'

export const studyFilters: DashboardStudyStatusFilterType = {
  studies: {
    title: 'Studies',
    icon: 'active-studies-group',
    store: 'activeStudies',
    items: [
      {value: 'Not started', label: 'Not started'},
      {value: 'In progress', label: 'In progress'},
      {value: 'Completed', label: 'Completed'},
    ],
  },
  researches: {
    title: 'External Research Opportunities',
    icon: 'active-research-group',
    store: 'activeOpportunities',
    items: [
      {value: 'Viewed', label: 'Viewed'},
      {value: 'Not viewed', label: 'Not viewed'},
    ],
  },
  history: {
    title: 'Questionnaire History',
    icon: '',
    store: 'questionHistory',
    items: [
      {value: 'annual_questionnaire', label: 'Annual Questionnaires'},
      {value: 'core_study', label: 'Core Questionnaires'},
    ],
  },
  closes: {
    title: 'Closed Studies & Research Opportunities',
    icon: '',
    store: 'closedStudies',
    items: [
      {value: 'Viewed', label: 'Viewed'},
      {value: 'Not viewed', label: 'Not viewed'},
      {value: 'Completed', label: 'Completed'},
      {value: 'Closed', label: 'Closed'},
    ],
  },
};

export const setIconParams = (type: SurveyType, status: string): string => {
  let icon = '';

  switch (type) {
    case surveyType.core_study:
      icon = type === 'core_study' && status === 'completed' ? 'lock-unlocked' : 'lock-key';
      break;
    case surveyType.annual_questionnaire:
      icon = 'annual';
      break;
    case surveyType.promotional_study:
      icon = 'research';
      break;
    case surveyType.sub_study:
      icon = 'sub-study';
      break;
    default:
      throw new Error(`Type is not valid ${type}`);
  }

  return icon;
};
