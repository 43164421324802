import { UntypedFormGroup } from '@angular/forms';
import { forOwn } from 'lodash';

export const resetErrors = (
  form: UntypedFormGroup
): void => Object.keys(form.controls).forEach((key) => form.controls[key].setErrors(null));

export const setErrors = (error: any, form: UntypedFormGroup): void => forOwn(error, (value: string | string[], key) => {
  if (key in form.controls) {
    const formControl = form.controls[key];

    formControl.setErrors({server: value});
    formControl.markAsTouched();
  }
});

export const setErrorsInFormGroups = (errors: any, forms: UntypedFormGroup[]): void => forms.forEach(form => {
  Object.keys(form.controls).forEach(key => {
    const control = form.get(key);

    if (key in errors) {
      control.setErrors({server: errors[key].join('')});
    } else {
      const nonServerErrors = control.errors;

      control.setErrors(nonServerErrors);
    }
  });
});
