import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialImportsModule } from '../material-imports.module';
import { PhoneInputModule } from '../phone-input/phone-input.module';
import { TrLibModule } from '../tr-lib.module';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ChildProfileFormComponent } from './child-profile-form/child-profile-form.component';
import { HouseholdInfoFormComponent } from './household-info-form/household-info-form.component';
import { PasswordCreationHintComponent } from './password-creation-hint/password-creation-hint.component';
import { PersonalInfoFormComponent } from './personal-info-form/personal-info-form.component';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@NgModule({
  declarations: [
    PersonalInfoFormComponent,
    HouseholdInfoFormComponent,
    ChangePasswordFormComponent,
    ChildProfileFormComponent,
    PasswordCreationHintComponent
  ],
  imports: [
    CommonModule,
    MaterialImportsModule,
    ReactiveFormsModule,
    FormsModule,
    PhoneInputModule,
    TrLibModule
  ],
  exports: [
    PersonalInfoFormComponent,
    HouseholdInfoFormComponent,
    ChangePasswordFormComponent,
    ChildProfileFormComponent
  ],
  providers: [
    {provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]}
  ]
})
export class UserProfileFormsModule {}
