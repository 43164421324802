import * as t from 'io-ts';

import { nullable, twoTypesNullable } from './helpers';

export const studyOptionLimitsSchema = t.partial({
  value_from: twoTypesNullable(t.string, t.number),
  value_to: twoTypesNullable(t.string, t.number),
  decimal: nullable(t.number)
}, 'studyOptionLimitsSchema');
export type StudyOptionLimitsType = t.TypeOf<typeof studyOptionLimitsSchema>;
