import { isNonEmptyString, ValidatorType } from '@tr-common';

export class SelectedWithText {
  _selected = false;
  text = '';

  constructor(source?: string | boolean) {
    if (isNonEmptyString(source) || source === true) {
      this._selected = true;
      if (isNonEmptyString(source) && source !== 'true') {
        this.text = source;
      }
    }
  }

  get valid(): boolean {
    return this.selected ? isNonEmptyString(this.text) : true;
  }

  get validState(): ValidatorType {
    return this.valid ? 'VALID' : 'INVALID';
  }

  get value(): boolean | string {
    return isNonEmptyString(this.text) ? this.text : this.selected;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(newValue: boolean) {
    if (!newValue) this.text = '';
    this._selected = newValue;
  }
}
