import * as t from 'io-ts';

import { participantStatusSchema, privilegeSchema } from '@tr-common';

export const userShortInfoInTokenSchema = t.type({
  user_id: t.string,
  account_status: participantStatusSchema,
  privileges: t.array(privilegeSchema),
  exp: t.number,
  orig_iat: t.number,
}, 'userShortInfoInTokenSchema');
export type UserShortInfoInToken = t.TypeOf<typeof userShortInfoInTokenSchema>;
