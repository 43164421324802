import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Memoize } from 'lodash-decorators';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { UUID } from 'io-ts-types/lib/UUID';

import { filterRealValues, isNullOrUndefined, isRealValue, ProcedureTemplateType, ProcedureTemplateTypes } from '@tr-common';

import { REGISTRY_DOCUMENT } from '../../../global/tokens';
import { IProcedure, MinimalStudyType, procedureHasQuestions } from '../../../models';
import { LayoutService } from '../../../services';
import { DialogQuestionListComponent } from '../../dialogs/dialog-question-list/dialog-question-list.component';
import {
  ActiveQuestionCollection,
  composeURLForResearchStudy,
  DraftAnswer,
  DraftAnswersType,
  getAmountOfAbsentAnswers,
  QueryParamsIDs,
  SurveyIntroOrResearchPageType,
  TooltipClassType,
} from '../../models';
import {
  finishProcedure,
  NavigateActions,
  nextQuestion,
  nextUnansweredQuestionAfterSubmit,
  previousQuestion,
  returnToDashboard,
  StudyConsentFacade,
  StudyFacade,
} from '../../store';
import { QuestionComponent } from '../../components/question/question.component';

@Component({
  selector: 'lib-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  // @ts-ignore
  providers: [{provide: REGISTRY_DOCUMENT, useValue: window.document}],
})
export class SurveyComponent implements OnDestroy {
  activeQuestion: string = undefined;
  activeProcedure$: Observable<IProcedure> = this.study.activeProcedure$;
  researchPage$ = this.study.researchPage$;
  answerSaveError$ = this.study.answerSaveError$;
  valid$ = new BehaviorSubject<boolean>(false);
  currentProcedure: ProcedureTemplateType;
  draftAnswers: DraftAnswersType = [];
  showTooltip$ = new BehaviorSubject<TooltipClassType>(null);
  confirmation_was_showed = false;
  attempt_first = false;
  absentAnswers = 0;
  warningStatus: string | null
  consentPending$ = combineLatest([this.consent.consentPending$, this.study.fullStudyLoading$]).pipe(
    map(([consentPending, fullStudyLoading]) => consentPending || fullStudyLoading)
  );
  activeQuestion$: Observable<ActiveQuestionCollection> = combineLatest([
    this.study.activeQuestion$, this.layoutService.isMobileUsingLandscape$
  ]).pipe(
    tap(([collection]) => this.checkIfActiveQuestionSet(collection)),
    tap(([collection]) => this.resetValidState(collection)),
    map(([collection, mobileLandscape]) => ({...collection, mobileLandscape})),
    // tap(data => console.log({handler: 'activeQuestion$', ...data}))
  );
  loadModuleSettingsAndConsents$ = combineLatest([
    this.study.blockedNavigation$, this.study.study$, this.route.queryParams
  ]).pipe(
    map((
      [isBlocked, {id}, {procedure, questionId}]: [boolean, MinimalStudyType, Params]
    ) => ({isBlocked, studyID: id, procedure, questionId})),
    filter(({isBlocked, procedure}) => !isBlocked && isRealValue(procedure)),
    tap(({studyID, procedure, questionId}) => this.resetProcedureState(studyID, procedure, questionId)),
    tap(({questionId}) => this.resetParentQuestionState(questionId))
  );
  subscription = new Subscription();
  isMobile$ = this.layoutService.isMobile$;
  isMobileDevice:any;
  constructor(
    public study: StudyFacade,
    public consent: StudyConsentFacade,
    public layoutService: LayoutService,
    public matDialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.subscription.add(this.loadModuleSettingsAndConsents$.subscribe());
  }

  @Memoize() getUrl({url, userID, add_participant_id_query_param}: SurveyIntroOrResearchPageType): string {
    return composeURLForResearchStudy(url, userID, add_participant_id_query_param);
  }

  get hasUnsavedChanges(): boolean {
    return this.draftAnswers.length > 0;
  }

  ngOnInit(): void{
    this.layoutService.isMobile$.subscribe(isMobile => {
      this.isMobileDevice = isMobile;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clickOnWrapper = (): void => { this.showTooltip$.next(null); };

  scrollToTop(): void {
    if(this.isMobileDevice){
      const scrollableElement = document.querySelector('.lib-survey-wrapper') as HTMLElement;
      if (scrollableElement) {
        // Scroll to top of the element
        scrollableElement.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling behavior
        });
      }
    }
    
  }

  onAnswer({answers, validity, hasWarning}: DraftAnswer): void {
    // console.log({handler: 'onAnswer', answers, validity, hasWarning});
    this.warningStatus = null;
    this.showTooltip$.next(null);
    this.draftAnswers = answers;
    if (validity && isRealValue(this.study.showHint$.value)) {
      this.study.showHint$.next(false);
    }
    if(hasWarning){
      this.warningStatus = 'INVALID-WARNING'
      this.valid$.next(hasWarning);
    }
    this.valid$.next(validity);
  }

  toQuestions(): void {
    this.matDialog.open<DialogQuestionListComponent, void, string>(
      DialogQuestionListComponent, {maxWidth: '100vw', panelClass: 'question-list', hasBackdrop: true}
    ).afterClosed().pipe(take(1), filterRealValues()).subscribe(id => this.study.navigateToQuestion(id));
  }

  // @ts-ignore
  toNextQuestion(activeQuestion: ActiveQuestionCollection, event: MouseEvent, libQuestion: QuestionComponent): void {
    this.scrollToTop();
    const draftAnswers = [...this.draftAnswers];
    const isNotValid = !(this.valid$.value && libQuestion.isValidByTreeControlState);
    const {previewMode, isLastQuestion} = activeQuestion;
    const preview = previewMode ? previewMode : undefined;
    const navigate: NavigateActions = isLastQuestion
      ? finishProcedure
      : this.absentAnswers > 1 ? nextUnansweredQuestionAfterSubmit : nextQuestion;
    const tooltipClass: TooltipClassType = isNotValid
      ? 'invalid-state'
      : (!isNotValid && activeQuestion.parentQuestion.needConfirmationWarning(!this.confirmation_was_showed))
        ? 'confirmation-warning'
        : null;
    const warningMessage = (this.warningStatus === 'INVALID-WARNING' && !this.attempt_first) ? 'show-warning' : null;

    event.stopPropagation();
    this.study.showHint$.next(isNotValid);
    this.showTooltip$.next(tooltipClass);
    // console.log({handler: 'toNextQuestion', tooltipClass, draftAnswers, isNotValid, navigate});
    if (draftAnswers.length > 0 && !isNotValid) {
      if (tooltipClass === 'confirmation-warning') {
        this.confirmation_was_showed = true;
      } else if (warningMessage === 'show-warning') {
        this.attempt_first = true;
      } else {
        this.attempt_first = false;
        this.draftAnswers = [];
        this.study.saveAnswers(draftAnswers, navigate, preview);
      }
    } else if (isNotValid) {
      console.warn('Question logic is not valid');
    } else {
      this.study.nextQuestion(activeQuestion.isLastQuestion, preview);
    }
  }

  toPreviousQuestion(activeQuestion: ActiveQuestionCollection): void {
    this.scrollToTop();
    const draftAnswers = [...this.draftAnswers];
    let preview = activeQuestion.previewMode ? activeQuestion.previewMode : undefined;
    // The block below was added because of the procedure status autodetect and detect unanswered question engines,
    // so we turn preview mode on automatically if all the questions have answers
    if (activeQuestion.isLastQuestion && !activeQuestion.unansweredQuestions && isNullOrUndefined(preview)) {
      preview = true;
    }
    this.study.showHint$.next(false);
    if (draftAnswers.length > 0 && this.valid$.value) {
      this.draftAnswers = [];
      this.study.saveAnswers(draftAnswers, previousQuestion, activeQuestion.isLastQuestion || preview);
    } else {
      this.study.previousQuestion(preview);
    }
  }

  backToDashboardOrSubmit({previewMode}: ActiveQuestionCollection): void {
    const draftAnswers = [...this.draftAnswers];

    this.draftAnswers = [];
    if (draftAnswers.length > 0 && this.valid$.value) {
      this.study.saveAnswers(draftAnswers, previewMode ? finishProcedure : returnToDashboard, previewMode);
    } else {
      this.study.returnToDashboard(previewMode);
    }
  }

  toQuestion(id: string): void {
    this.study.navigateToQuestion(id);
  }

  hideTooltip(): void {
    this.showTooltip$.next(null);
  }

  private checkIfActiveQuestionSet({activeQuestionId}: ActiveQuestionCollection): void {
    const {questionId} = this.route.snapshot.queryParams as QueryParamsIDs;

    if (isRealValue(questionId) && isNullOrUndefined(activeQuestionId)) {
      this.study.setActiveQuestion(questionId);
    }
    // console.log({handler: 'checkIfActiveQuestionSet', questionId, activeQuestionId});
  }

  private resetValidState({activeQuestionId, questionAnswers}: ActiveQuestionCollection): void {
    const questionAnswer = isRealValue(questionAnswers) ? questionAnswers[activeQuestionId] : null;

    this.valid$.next(isRealValue(questionAnswer));
  }

  private resetProcedureState(studyID: UUID | string, procedure: ProcedureTemplateType, questionId: string): void {
    if (isRealValue(procedure)  && this.currentProcedure !== procedure) {
      this.currentProcedure = procedure;
      this.study.setActiveProcedure(procedure);
      this.study.loadModuleSettings(studyID, procedure, true);
      if (procedure === ProcedureTemplateTypes.consent) {
        this.consent.loadConsents();
      } else if (procedureHasQuestions(procedure) && isNullOrUndefined(questionId)) {
        this.study.preselectQuestion();
      }
    }
  }

  private resetParentQuestionState(questionId: string): void {
    if (isRealValue(questionId) && questionId !== this.activeQuestion) {
      this.activeQuestion = questionId;
      this.showTooltip$.next(null);
      this.confirmation_was_showed = false;
      this.draftAnswers = [];
      this.absentAnswers = getAmountOfAbsentAnswers();
      this.study.resetAnswersSaveError();
      this.layoutService.scrollIt$.next(true);
      this.study.showHint$.next(null);
      this.study.setActiveQuestion(questionId);
    }
  }
}
