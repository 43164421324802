import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { isRealValue, mockParticipantId } from '@tr-common';

import { dialogPanelClass, getMaxWidth } from '../models';
import { SurveyComponent } from './pages/survey/survey.component';
import { ExitConfirmationDialogComponent } from './dialogs/exit-confirmation-dialog/exit-confirmation-dialog.component';
import { ProcedureStatusComponent } from './pages/procedure-status/procedure-status.component';
import { checkIfNextStateIsSurveyComponent } from './models';

export const unsavedChangesGuard: CanDeactivateFn<SurveyComponent> = (
  component: SurveyComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot
): Observable<boolean> | boolean => {
  const dialogConfig: Partial<MatDialogConfig> = {
    maxWidth: getMaxWidth(component.layoutService.isMobile),
    panelClass: dialogPanelClass,
    disableClose: true,
    hasBackdrop: true,
  };
  const isMockUser = route.params['userID'] === mockParticipantId;
  const noConfirmation = !component.hasUnsavedChanges || isMockUser;

  // console.log({handler: 'unsavedChangesGuard', noConfirmation, unsavedChanges: component.hasUnsavedChanges, nextState, route, state});

  return noConfirmation
    ? of(true)
    : component.matDialog.open<ExitConfirmationDialogComponent, void, boolean>(ExitConfirmationDialogComponent, dialogConfig).afterClosed();
}

export const preventBackToSurveyIfSubmitted: CanDeactivateFn<ProcedureStatusComponent> = (
  component: ProcedureStatusComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot, nextState: RouterStateSnapshot
): Observable<boolean> | boolean => component.state$.pipe(
  shareReplay(1),
  map(componentState => {
    const isStateProcedureSet = isRealValue(state.root.queryParams['procedure']);
    const isTheSameProcedure = isStateProcedureSet && state.root.queryParams['procedure'] === nextState.root.queryParams['procedure'];
    const nextStateHasSurveyComponent = checkIfNextStateIsSurveyComponent(nextState.root);
    const isProcedureSubmittedOrPending = !componentState.hasNotSubmitted || componentState.submitPending;
    const canNotBeAllowed = isTheSameProcedure && isProcedureSubmittedOrPending && nextStateHasSurveyComponent;

    // console.log({handler: 'preventBackToSurveyIfSubmitted', canNotBeAllowed, component, nextStateHasSurveyComponent, route, state, nextState});

    return !canNotBeAllowed;
  })
);
