import { previewMockUserStudy } from '@tr-common';

import { IStudyProcedure, MinimalStudyType, ProcedureQuestions, Study, UserStudyProcedureType } from '../../models';
import { UserStudyType } from '../models';
import { StudyQuestionRuleType } from '../../models/rules';
import { LoadFullStudySourcesType } from './survey-helpers';

export interface FullStudy {
  study: MinimalStudyType,
  studyProcedures: IStudyProcedure[],
  userProcedures: UserStudyProcedureType[],
  rules: StudyQuestionRuleType[],
  questions: ProcedureQuestions,
  userStudies: UserStudyType[],
  userStudy: UserStudyType,
  studyID: string,
  participantID: string,
  userStudyID: string,
  sourceType: LoadFullStudySourcesType,
}

export const isMockUserStudy = ({userStudyID}: FullStudy): boolean => userStudyID === previewMockUserStudy;
export const isCoreStudy = ({userStudy}: FullStudy): boolean => userStudy.study_type === 'core_study';
