import { Action, createReducer, on } from '@ngrx/store';

import { Consent } from 'tr-lib';

import {
  clearSmsConsentState,
  getCoreStudyIdSuccess,
  getUserStudyIdSuccess,
  loadResignConsentBodySuccess,
  loadResignConsents,
  loadResignConsentsFail,
  loadResignConsentsSuccess,
  resignConsent,
  resignConsentFail,
  resignConsentSuccess,
  signedSmsConsentFail,
  signedSmsConsentSuccess,
  signSmsConsent,
  skipSmsConsentFail,
  skipSmsConsentSuccess,
} from '@store/actions';

export interface ConsentState {
  optInPending: boolean;
  optInAllowed: boolean;
  optInErrors: string;
  coreStudyId: string;
  userStudyId: string | number;
  consentFiles: any[];
  consents: Consent[];
  consentBody: string;
  consentAgreement: string;
  pendingList: boolean;
  pendingSign: boolean;
}

export const initialConsentState: ConsentState = {
  optInPending: false,
  optInAllowed: false,
  optInErrors: null,
  coreStudyId: null,
  userStudyId: null,
  consentFiles: [],
  consents: null,
  pendingList: false,
  pendingSign: false,
  consentBody: null,
  consentAgreement: null,
};

const reducer = createReducer<ConsentState>(
  initialConsentState,
  on(resignConsent, state => ({...state, pendingSign: true})),
  on(resignConsentSuccess, resignConsentFail, state => ({...state, pendingSign: false})),
  on(signSmsConsent, state => ({...state, optInPending: true})),
  on(signedSmsConsentSuccess, state => ({...state, optInPending: false, optInErrors: null, optInAllowed: true})),
  on(signedSmsConsentFail, (state, {error}) => ({...state, optInPending: false, optInErrors: error.toString()})),
  on(getCoreStudyIdSuccess, (state, {payload}) => ({...state, coreStudyId: payload})),
  on(getUserStudyIdSuccess, (state, {payload}) => ({...state, userStudyId: payload})),
  on(loadResignConsents, state => ({...state, pendingList: true})),
  on(loadResignConsentsSuccess, (state, {payload}) => ({...state, consents: payload, pendingList: false})),
  on(loadResignConsentsFail, (state) => ({...state, consents: [], pendingList: false})),
  on(loadResignConsentBodySuccess, (state, {payload}) => ({
    ...state, consentBody: payload.consent, consentAgreement: payload.agreement_text
  })),
  on(clearSmsConsentState, skipSmsConsentSuccess, skipSmsConsentFail, state => (
    {...state, optInPending: false, optInAllowed: false, optInErrors: null}
  ))
);

export const reConsentReducer = (state: ConsentState | undefined, action: Action): ConsentState => reducer(state, action);
