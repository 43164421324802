import * as t from 'io-ts';

import { isNullOrUndefined, isRealValue, nullable, TypeConsent } from '@tr-common';

import { consentInfoSchema } from './study-consent';

export const consentSignSchema = t.type({
  subject_name: t.string,
  user_consent_id: t.number
}, 'consentSignSchema');
export type ConsentSignType = t.TypeOf<typeof consentSignSchema>;

export const consentSignatureSchema = t.intersection([
  consentSignSchema,
  t.type({
    created_at: nullable(t.string),
  })
], 'consentSignatureSchema');
export type ConsentSignatureType = t.TypeOf<typeof consentSignatureSchema>;

export const consentStatusSchema = t.intersection([
  consentSignatureSchema,
  // Signing a consent on a sub-study do not require the field sms_consent_required in response
  t.partial({
    sms_consent_required: t.boolean,
  })
], 'consentStatusSchema');
export type ConsentStatusType = t.TypeOf<typeof consentStatusSchema>;

export type ConsentNumbersType = [] | [number];
export const orderNumByConsentType = (ct: TypeConsent): ConsentNumbersType => {
  switch (ct) {
    case TypeConsent.adult:
      return [0];
    case TypeConsent.parental:
      return [0];
    case TypeConsent.child:
      return [1];
    case TypeConsent.jaeb:
      return [0];
    default:
      return [];
  }
};
export const isConsentType = (str: string): str is TypeConsent => Object.values<string>(TypeConsent).includes(str);

/**
 * needs sync with {@link TypeConsent}
 */
export const consentTypeSchema = t.union([
  t.literal(TypeConsent.parental),
  t.literal(TypeConsent.child),
  t.literal(TypeConsent.adult),
  t.literal(TypeConsent.jaeb),
  t.literal(TypeConsent.universal),
]);

export const consentBodySchema = t.intersection([
  t.type({
    agreement_text: t.string,
    consent: t.string,
    consent_type: consentTypeSchema,
    description: nullable(t.string),
    id: t.number,
    order: nullable(t.number),
  }),
  t.partial({
    created_at: t.string,
    updated_at: nullable(t.string)
  })
], 'consentBodySchema');
export type ConsentBody = t.TypeOf<typeof consentBodySchema>;

export interface ConsentBodies {
  [id: string]: ConsentBody
}

export const consentToSignSchema = t.intersection([
  consentBodySchema,
  t.type({
    id: t.number,
    isChildConsent: t.boolean
  })
]);
export type ConsentToSign = t.TypeOf<typeof consentToSignSchema>;

export interface ConsentToSignWithPending {
  id: number;
  consent: string;
  agreement_text: string;
  isChildConsent: boolean;
  pending: boolean;
  isJAEB: boolean;
}

export interface SignedConsentsWithPending {
  pending: boolean;
  consents: Consent[];
  jaebConsents: Consent[];
  registryConsents: Consent[];
}

export const consentSchema = t.intersection([
  t.type({
    id: t.number,
    consent_type: consentTypeSchema,
    order: nullable(t.number),
    signature: nullable(consentSignatureSchema),
  }),
  t.partial({
    created_at: nullable(t.string),
    procedureIdx: nullable(t.number),
    updated_at: nullable(t.string)
  })
], 'consentSchema');
export type Consent = t.TypeOf<typeof consentSchema>;

export const consentListSchema = t.intersection([
  consentInfoSchema,
  t.type({
    description: t.string
  })
], 'consentListSchema');
export type ConsentListType = t.TypeOf<typeof consentListSchema>;
/**
 * TODO check if this is correct logic
 *
 * @param consents
 */
export const calculateConsentType = (consents: Consent[]): TypeConsent => {
  let consentType = TypeConsent.adult;

  if (consents.length === 2) {
    consentType = TypeConsent.child
  } else if (consents[0].consent_type === TypeConsent.parental) {
    consentType = TypeConsent.parental;
  }

  return consentType;
}

export const isConsentUnsigned = ({signature}: Consent) => isNullOrUndefined(signature);
export const isThereParentOrAdultConsent = (
  consents: Consent[]
): boolean => isRealValue(
  consents.find(c => c.consent_type === TypeConsent.parental)
) && isRealValue(
  consents.find(c => c.consent_type === TypeConsent.adult)
);
