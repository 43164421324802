<form [formGroup]="parts" class="flex wrapper" [class.tr-focused]="focused" (focusout)="onFocusOut($event)">
  <mat-form-field class="country-code" appearance="fill">
    <mat-select #matSelect formControlName="countryCode" placeholder="Code" [disableRipple]="true" panelClass="country-code-list"
      (opened)="selectOpened()" (closed)="phoneNumber.select()">
      <mat-select-trigger [matTooltip]="getCountryNameFromCode(countryCode.value)">
        <lib-country-flag [code]="getIsoFromPhone(countryCode.value)"></lib-country-flag>
      </mat-select-trigger>
      <mat-option class="phone-opt" *ngFor="let option of codes" [value]="option.phone" [title]="option.country">
        <span class="phone-code">{{ option.phone }}</span>
        <lib-country-flag [code]="option.iso"></lib-country-flag>
        <span>{{ option.country }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="phone-number" appearance="fill">
    <span *ngIf="countryCode.value" matPrefix>{{ countryCode.value }}</span>
    <input #phoneNumber type="tel" matInput [attr.aria-label]="phoneLabel" formControlName="phoneNumber"
      autocomplete="tel-national" aria-autocomplete="both" [placeholder]="placeholder"
      (keydown)="checkPhonePattern($event)" (focusin)="inputFocusin()"
    />
  </mat-form-field>

  <span *ngIf="notEmpty" class="flex flex-end align-center icon-container" (click)="clearPhoneField()">
    <mat-icon svgIcon="close"></mat-icon>
  </span>
</form>
