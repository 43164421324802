import { Component } from '@angular/core';

@Component({
  selector: 'lib-password-creation-hint',
  template: `
    <div class="content">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./password-creation-hint.component.scss']
})
export class PasswordCreationHintComponent {}
