// libSvgIcons and email-*.svg icons in tr-lib/shared-icons/ have got the same suffixes
// noinspection JSUnusedGlobalSymbols

import { FormControlErrorHandler } from './form-control-error-handler';
import { isRealValue } from './helpers';

export type EmailStatusType = 'ok' | 'warn' | 'error' | 'checking';

export const getEmailErrorHandlers = (): FormControlErrorHandler[] => [{
  mustBeShowed: control => isRealValue(control.errors?.required),
  message: 'Email is required'
}, {
  mustBeShowed: control => isRealValue(control.errors?.email),
  message: 'Email format is invalid'
}, {
  mustBeShowed: control => isRealValue(control.errors?.pattern),
  message: 'Email is not valid'
}, {
  mustBeShowed: control => isRealValue(control.errors?.match),
  message: 'Email does not match'
}, {
  mustBeShowed: control => isRealValue(control.errors?.server),
  message: control => control.errors.server
}];
