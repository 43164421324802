import { firstPeriodInParagraphRegExp, isNonEmptyString, isNullOrUndefined, isObject, isRealValue, StudyUuid } from '@tr-common';

import { StudyOption } from './study-option';
import { StudyQuestionType } from './study-question-types';

// @dynamic
export class StudyQuestion extends StudyUuid implements StudyQuestionType {
  procedure: string;
  question_type: StudyQuestionType['question_type'];
  parent_option_id?: string;
  options: StudyOption[] = [];
  shortTitleMode = false;
  order = 1;
  visible_by_default = true;
  confirmation_warning: string = null;
  show_hint = true;
  studyId = null;
  isStoredOnBE: boolean;
  disabled = false;
  invalid = false;
  created_at = '';
  private _title = '';
  private _isTagModeForLongTitle = false;

  constructor(initData?: StudyQuestion | Partial<StudyQuestionType>) {
    super(isRealValue(initData) ? initData.id : null);
    if (isObject(initData)) {
      Object.assign(this, initData);
      this.options = this.options.map(option => new StudyOption(option));
    }
  }

  get hasParent(): boolean { return isRealValue(this.parent_option_id); }

  get isLongTitle(): boolean {
    return isNonEmptyString(this.title) && this._isTagModeForLongTitle;
  }

  get titleForList(): string {
    let title = this._title;

    if (isNonEmptyString(this._title)) {
      const trimmedTitleWithoutHtmlAndNewlines = this._title
        .replace(/<\/?br[^>]*>/gm, ' ')
        .replace(/<[^>]*>/g, '')
        .replace(/\s{2,}/gm, ' ')
        .trim();
      const regResult = new RegExp(firstPeriodInParagraphRegExp).exec(trimmedTitleWithoutHtmlAndNewlines);

      title = isNullOrUndefined(regResult)
        ? trimmedTitleWithoutHtmlAndNewlines
        : trimmedTitleWithoutHtmlAndNewlines.slice(0, regResult.index + 1);
    }

    return isNonEmptyString(title) ? title : null;
  }

  get titleForQuestion(): string {
    let result = this.title;

    if (this.shortTitleMode && this._isTagModeForLongTitle) {
      const offset = this._title.search('<sm />');

      result = result.substring(0, offset) + '&mldr;';
    }

    return result;
  }

  get title(): string {
    return this._isTagModeForLongTitle
      ? this._title.replace(/<sm \/>/gi, '<span>') + '</span>'
      : this._title;
  }

  set title(newTitle: string) {
    this._title = newTitle;
    this._isTagModeForLongTitle = isNonEmptyString(newTitle) && newTitle.search('<sm />') >= 0;
  }

  clone(initData?: Partial<StudyQuestionType>): StudyQuestion {
    const newStudyQuestion = new StudyQuestion(this);

    Object.assign(newStudyQuestion, initData);

    return newStudyQuestion;
  }

  setParent(studyQuestion: StudyQuestion): void {
    this.parent_option_id = studyQuestion.id;
  }
}

export type StudyQuestions = StudyQuestion[];
