import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ConsentState } from '@store/reducers/consent-sign.reducer';
import { getFirstUnsignedConsent } from '@app/model';

export const reconsentProperty = 're-consent';
export const selectConsentState = createFeatureSelector<ConsentState>(reconsentProperty);
export const selectConsentBody = createSelector(selectConsentState, ({consentBody}) => consentBody);
export const selectConsentAgreement = createSelector(selectConsentState, ({consentAgreement}) => consentAgreement);
export const selectConsentUserStudyId = createSelector(selectConsentState, ({userStudyId}) => userStudyId);
export const selectConsents = createSelector(selectConsentState, ({consents}) => consents);
export const selectConsentsPending = createSelector(selectConsentState, ({pendingList}) => pendingList);
export const selectPending = createSelector(selectConsentState, ({pendingList, pendingSign}) => pendingList || pendingSign);
export const selectUnsignedReconsent = createSelector(
  selectConsents, selectConsentsPending,
  (all, pending, ) => getFirstUnsignedConsent(all, pending)
);
export const selectOptInSettings = createSelector(selectConsentState, (
  {optInAllowed, optInPending, optInErrors}: ConsentState
) => ({optInAllowed, optInPending, optInErrors}));
