import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment, MomentInput } from 'moment';

import {
  dateShortFormat,
  dateShortPattern,
  FormControlErrorHandler,
  isRealValue,
  serverDateShortFormat,
  shortDateFormats,
} from '@tr-common';

import { SourceChange } from '../../../models';
import { BaseDateComponent } from '../../helpers';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

@Component({
  selector: 'lib-date-short-widget',
  template: `
    <mat-form-field appearance="outline" [class.warning]="hasOnlyAsyncWarnings(dateControl.errors)">
      <mat-label>{{ format }}</mat-label>
      <input autocomplete="off" #dateInput matInput [matDatepicker]="shortDP" [formControl]="dateControl"
        [errorStateMatcher]="errorMatcher" />

      <mat-datepicker-toggle matSuffix [for]="shortDP">
        <mat-icon svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #shortDP [panelClass]="'short-datepicker'" startView="multi-year" (monthSelected)="month($event, shortDP)">
      </mat-datepicker>

      <mat-error><lib-form-control-error [control]="dateControl" [errorHandlers]="errorHandlers"></lib-form-control-error></mat-error>
    </mat-form-field>
  `,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: shortDateFormats},
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: [`./date-short-widget.component.scss`],
})
export class DateShortWidgetComponent extends BaseDateComponent {
  @ViewChild('shortDP') shortDP: MatDatepicker<unknown>;
  format = dateShortFormat;
  serverFormat = serverDateShortFormat;
  pattern = dateShortPattern;
  readonly errorHandlers: FormControlErrorHandler[] = [{
    mustBeShowed: ({errors}: FormControl<Moment>) => isRealValue(errors?.matDatepickerParse), message: 'Invalid date.'
  }, {
    mustBeShowed: ({errors}: FormControl<Moment>) => isRealValue(errors?.matDatepickerMax), message: 'Date cannot be in the future.',
  }];

  constructor() {
    super();
  }

  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);
  @HostListener('window:scroll', ['$event']) windowScroll() {
    this.shortDP.close();
  }

  month(selectedMoment: Moment, datepicker: MatDatepicker<unknown>): void {
    const ctrlValue: Moment = isRealValue(selectedMoment) ? moment(selectedMoment.format(this.serverFormat), this.serverFormat) : moment();

    this.dateControl.setValue(ctrlValue);
    datepicker.close();
    this.getDateValueAndEmit(ctrlValue.format(this.format));
  }
}
