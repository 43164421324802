import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-landing',
  template: `
    <app-header></app-header>
    <app-landing-info></app-landing-info>
  `,
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  suppressSideImage: boolean;
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getDataFromRoute();
    this.checkRouterEvents();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkRouterEvents(): void {
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.getDataFromRoute());
  }

  private getDataFromRoute(): void {
    this.suppressSideImage = this.route.children.some(child => child.snapshot.data['suppress-side-image']);
  }
}
