import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { pick } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, take, tap } from 'rxjs/operators';

import {
  failSnackBottomConstantConfig,
  getPayloadFromToken,
  isNonEmptyArray,
  isRealValue,
  LayoutService,
  SessionStorage,
  somethingWentWrong,
  STORAGE,
  storageKeys,
  StorageKeys,
  studyResetState,
} from 'tr-lib';

import { RejectRegistrationPopUpComponent } from '@app/auth/dialogs/reject-registration/reject-registration-dialog.component';
import { getMessage } from '@app/auth/models/smart-check';
import { resetState as dashboardResetState } from '@app/dashboard/store/dashboard.actions';
import { UserState } from '@store/reducers';
import { storeJaebKeyForRegisterSuccess } from '@app/auth/models';
import {
  authActions,
  checkToken,
  checkTokenSuccess,
  cleanStore,
  getTokenFromT1D,
  getTokenFromT1DSuccess,
  getUser,
  globalLoaderEnd,
  globalLoaderStart,
  login,
  loginForbidden,
  loginSuccess,
  loginWithDualFA,
  loginWithT1DExchange,
  logout,
  registerJaeb,
  registerParticipant,
  registerReject,
  registerSuccess,
  resetDualFactorError,
  resetErrorState,
  resetPassword,
  resetPasswordSuccess,
  resetState,
  setLoginError,
  setNewPass,
  setNewPassError,
  setNewPassSuccess,
  setRegisterError,
  setResetPassError,
  setTokenError,
  smartEmailCheck,
  smartEmailCheckFail,
  smartEmailCheckSuccess,
  submitDualFactorCode,
  submitDualFactorCodeFail,
  submitDualFactorCodeSuccess,
} from '@store/actions';
import { UserRouterService } from '@services/user.router.service';
import { publicDialogClass, sourcesToComponent } from '@app/model';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AuthEffects {
  // Register flow
  register$ = createEffect(() => this.actions$.pipe(
    ofType(registerParticipant),
    switchMap(({userData, regGuardInfo}) => this.authService.registerParticipant(userData, regGuardInfo).pipe(
      map(({id, access_token}) => registerSuccess({
        id, loginData: {...pick(userData, 'email', 'password')}, accessToken: access_token, isJaeb: false
      })),
      catchError(({error, status}) => of(
        status === 403 ? registerReject({reason: error?.detail}) : setRegisterError({error})
      ))
    ))
  ));

  registerJaeb$ = createEffect(() => this.actions$.pipe(
    ofType(registerJaeb),
    switchMap(({userData, id}) => this.authService.registerParticipantFromJAEB({...userData, id}).pipe(
      map(({access_token}) => registerSuccess({
        id, loginData: {...pick(userData, 'email', 'password')}, accessToken: access_token, isJaeb: true
      })),
      catchError(error => of(setRegisterError({error: error?.error})))
    ))
  ));

  registerSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(registerSuccess),
    tap(({isJaeb}) => storeJaebKeyForRegisterSuccess(isJaeb)),
    map(({accessToken}) => loginSuccess({payload: accessToken, source: sourcesToComponent.fromRegister})),
  ));

  registerReject$ = createEffect(() => this.actions$.pipe(
    ofType(registerReject),
    tap(({reason}) => {
      if (reason === 'registration is blocked') {
        this.dialog.open<RejectRegistrationPopUpComponent, void, void>(RejectRegistrationPopUpComponent, {
          maxWidth:  this.layout.isMobile ? '95vw' : '555px',
          panelClass: [publicDialogClass, 'border-20'],
        }).afterClosed().pipe(
          take(1)
        ).subscribe(() => {
          void this.userRouterService.navigateTo(['/']);
        })
      } else {
        this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig());
      }
    })
  ), {dispatch: false});

  smartEmailCheck$ = createEffect(() => this.actions$.pipe(
    ofType(smartEmailCheck),
    switchMap(({payload}) => this.authService.smartEmailCheck(payload).pipe(
      map(({is_ok}) => smartEmailCheckSuccess({message: getMessage(is_ok)})),
      catchError(error => {
        const messages: string[] = error['error'];
        const outcome = isNonEmptyArray(messages) ? messages.join(', ') : error?.message;

        return of(smartEmailCheckFail({message: outcome}));
      })
    ))
  ));
  // Login Flow
  login$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(login),
    switchMap(({loginData, params}) => this.authService.login(loginData).pipe(
      map(({otp_id, token}) => isRealValue(otp_id)
        ? loginWithDualFA({params, otpId: otp_id})
        : this.onSuccessLogin(token, params)
      ),
      catchError(({error}) => of(setLoginError({error})))
    ))
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loginSuccess),
    tap(({payload}) => this.storage.setItem(storageKeys.token, payload)),
    map(({source}) => getUser({source, checkSmsConsent: true}))
  ));

  loginForbidden$ = createEffect(() => this.actions$.pipe(
    ofType(loginForbidden),
    tap(() => this.userRouterService.navigateTo(['/auth/forbidden-access']).then())
  ), {dispatch: false});

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(logout),
    tap(({payload}) => this.userRouterService.redirectAfterLogout(payload)),
    map(() => cleanStore({withToken: true}))
  ));

  cleanStore$ = createEffect(() => this.actions$.pipe(
    ofType(cleanStore),
    tap(({withToken}) => withToken && this.storage.removeItem(storageKeys.token)),
    concatMap(() => [resetState(), dashboardResetState(), studyResetState(), resetErrorState()])
  ));

  // Reset password flow
  resetPassword$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(resetPassword),
    switchMap((action) => this.userService.resetPassword(action.payload).pipe(
      map(() => resetPasswordSuccess()),
      catchError(reject => of(setResetPassError({error: reject.error})))
    ))
  ));

  checkToken$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(checkToken),
    switchMap((action) => this.userService.checkToken(action.payload).pipe(
      map(() => checkTokenSuccess()),
      catchError(reject => of(setTokenError({error: reject.error})))
    ))
  ));

  setNewPassword$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(setNewPass),
    switchMap((action) => this.userService.setNewPass(action.payload).pipe(
      map(() => setNewPassSuccess()),
      catchError(reject => of(setNewPassError({error: reject.error})))
    ))
  ));

  // T1D exchange and refresh token flows
  loginWithT1DExchange = createEffect(() => this.actions$.pipe(
    ofType(loginWithT1DExchange),
    tap(() => this.userRouterService.loginWithT1DExchange())
  ), {dispatch: false});

  getTokenFromT1D$ = createEffect(() => this.actions$.pipe(
    ofType(getTokenFromT1D),
    tap(() => this.store.dispatch(globalLoaderStart())),
    switchMap(({code, jump}) => this.userService.getTokenFromT1D(code).pipe(
      concatMap(({token}) => [
        globalLoaderEnd(),
        getTokenFromT1DSuccess({token, jump})
      ]),
      catchError(({error: [message]}) => of(setLoginError({error: message})))
    ))
  ));

  setLoginError$ = createEffect(() => this.actions$.pipe(
    ofType(setLoginError),
    map(() => globalLoaderEnd()),
  ));

  getTokenFromT1DSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getTokenFromT1DSuccess),
    tap(({jump}) => {
      const dashboardUrl = jump === 'core_study' ? ['/dashboard', 'jump-to-core-study'] : ['/dashboard'];

      this.userRouterService.navigateTo(dashboardUrl).then();
    })
  ), {dispatch: false});

  refreshTokenSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getTokenFromT1DSuccess),
    tap(({token}) => this.storage.setItem(storageKeys.token, token)),
  ), {dispatch: false});

  // Deprecated logic for public with Dual FA
  loginWithDualFA$ = createEffect(() => this.actions$.pipe(
    ofType(loginWithDualFA),
    tap(({otpId}) => this.storage.setItem('otpId', otpId)),
    tap(({params}) => this.userRouterService.navigateToDualFA(params).then()),
    concatMap(() => [resetDualFactorError(), setLoginError({error: null})])
  ));

  submitDualFactorCode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(submitDualFactorCode),
    switchMap((action) => this.authService.submitCode({
      otp_id: action.payload.otpId,
      password: action.payload.code
    }).pipe(
      map(response => submitDualFactorCodeSuccess({payload: response.token})),
      catchError(error => of(submitDualFactorCodeFail({error})))
    ))
  ));

  submitDualFactorCodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(submitDualFactorCodeSuccess),
    tap(() => this.storage.removeItem('otpId')),
    map(({payload}) => this.onSuccessLogin(payload, this.userRouterService.queryParams))
  ));

  constructor(
    @Inject(STORAGE) private storage: SessionStorage<StorageKeys | 'otpId'>,
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private userRouterService: UserRouterService,
    private store: Store<UserState>,
    private layout: LayoutService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  onSuccessLogin(token: string, params: Params): TypedAction<authActions.LOGIN_FORBIDDEN | authActions.LOGIN_SUCCESS> {
    const isAdmin = getPayloadFromToken(token).privileges.some(p => p.id.split(':')[1] !== 'user');
    const register = isRealValue(params) ? params['register'] : null;

    return isAdmin
      ? loginForbidden()
      : loginSuccess({payload: token, source: isRealValue(register) ? sourcesToComponent.fromRegister : sourcesToComponent.default})
    ;
  }
}
