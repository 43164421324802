import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { isRealValue, SessionStorage, STORAGE, storageKeys } from 'tr-lib';

import { getAuthorizationHeaders } from '@app/model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(STORAGE) private storage: SessionStorage) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any> {
    const isRequiredJWT = req.url.match(/\/registry\//) !== null;
    const token = isRequiredJWT ? this.storage.getItem(storageKeys.token) as string : null;
    let authReq;

    if (isRealValue(token)) {
      authReq = req.clone({headers: new HttpHeaders({...getAuthorizationHeaders(token)})});
    }

    return next.handle(authReq && req.responseType !== 'arraybuffer' ? authReq : req);
  }
}
