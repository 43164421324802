import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

import { ValidatorType } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption } from '../../../models/study-option';

@Component({
  selector: 'lib-label-widget',
  template: `<div [innerHTML]="option?.title"></div>`,
  styleUrls: ['./label-widget.component.scss']
})
export class LabelWidgetComponent implements WidgetPrototype<boolean>, AfterViewInit {
  @Input() option: StudyOption;
  @Input() value = true;
  @Output() modify = new EventEmitter<WidgetModify<boolean>>();
  valid: ValidatorType = 'VALID';
  // There was a bug in AQ after answered "In the past 12 months, how many times have you experienced any of the following events
  // associated with diabetes-related ketoacidosis", pressed "Next", then "back" and "next" again - answer save to server failed
  // the reason for this is when that this valueChange trigger's labelChange in questionComponent and overrides it's dirty answers with
  // info from the next question, and this combination of questionId and optionId was not being recognized by the server
  ngAfterViewInit(): void {
    this.emitState(SourceChange.user);
  }

  emitState(source: SourceChangeType): void {
    this.modify.emit({value: true, valid: this.valid, source});
  }
}
