import { Component } from '@angular/core';

@Component({
  selector: 'lib-social-icons',
  template: `
    <a href="https://www.facebook.com/T1DExchange/" target="_blank" rel="noopener">
      <img src="/assets/facebook_bg.svg" alt="Facebook">
    </a>
    <a href="https://twitter.com/T1DExchange" target="_blank" rel="noopener">
      <img src="/assets/twitter_bg.svg" alt="Twitter">
    </a>
    <a href="https://www.instagram.com/t1dexchange/" target="_blank" rel="noopener">
      <img src="/assets/instagram_bg.svg" alt="Twitter">
    </a>
    <a href="https://www.linkedin.com/company/t1d-exchange/" target="_blank" rel="noopener">
      <img src="/assets/linkedin_bg.svg" alt="Linkedin">
    </a>
  `,
  styles: [`
    :host {
      display: block;
    }

    a img {
      width: 38px;
      height: 38px;
      display: inline-block;
      margin: 0 5px;
    }
  `]
})
export class SocialIconsComponent {}
