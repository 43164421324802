import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ClosedStudyComponent } from './pages/closed-study/closed-study.component';
import { ConsentPreviewComponent } from './pages/consent-preview/consent-preview.component';
import { NotEligibleComponent } from './pages/not-eligible/not-eligible.component';
import { ProcedureStatusComponent } from './pages/procedure-status/procedure-status.component';
import { ReviewAnswersComponent } from './pages/review-answers/review-answers.component';
import { SurveyWrapperComponent } from './pages/survey-wrapper/survey-wrapper.component';
import { SurveyComponent } from './pages/survey/survey.component';
import { UnavailableConsentComponent } from './pages/unavailable-consent/unavailable-consent.component';
import { preventBackToSurveyIfSubmitted, unsavedChangesGuard } from './study-survey.guard';

export const routes: Routes = [
  {path: 'study/:studyID/user-study/:userStudyID/user/:userID', component: SurveyWrapperComponent, children: [
    {
      path: '', component: SurveyComponent, data: {preventBackFromSubmitted: true},
      canDeactivate: [unsavedChangesGuard], runGuardsAndResolvers: 'always'
    },
    {path: 'procedureStatus', component: ProcedureStatusComponent, canDeactivate: [preventBackToSurveyIfSubmitted], data: {showFooter: true}},
    {path: 'procedureSubmit', component: ProcedureStatusComponent, data: {showFooter: true, submitMode: true}},
    {path: 'not-eligible', component: NotEligibleComponent},
    {path: 'review-answers', component: ReviewAnswersComponent, data: {showFooter: true}},
    {path: 'closed-study', component: ClosedStudyComponent},
    {path: 'no-consent', component: UnavailableConsentComponent, data: {showHeader: true}},
  ]},
  {path: 'study', component: SurveyWrapperComponent, children: [
    {path: ':studyID/user/:userID/signedConsent/:consentID', component: ConsentPreviewComponent}
  ]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudySurveyRoutingModule {}
