import { isDevMode } from '@angular/core';

import { isNullOrUndefined } from '@tr-common';

import { AnsweredQuestion } from './answered-question';

export const findFirstUnansweredQuestion = (questions: AnsweredQuestion[] = []): string => {
  const questionsWithoutAnswer = questions.filter(q => isNullOrUndefined(q.answers));

  return questionsWithoutAnswer[0]?.id;
};

export const findFirstUnansweredQuestionWithValidation = (questions: AnsweredQuestion[]): string => {
  const questionsWithoutAnswer = questions.filter(q => isNullOrUndefined(q.answers));
  const [unansweredQuestion] = questionsWithoutAnswer.length ? questionsWithoutAnswer : [];

  // console.log({handler: 'findFirstUnansweredQuestionWithValidation', unansweredQuestion, questions});

  return unansweredQuestion?.id;
};

export const disabledQuestionsWithoutAnswer = (questions: AnsweredQuestion[]): AnsweredQuestion[] => {
  let disabled = false;

  return questions.map((question: AnsweredQuestion) => {
    question.disabled = disabled;
    // first unanswered is still enabled
    disabled = disabled || isNullOrUndefined(question.answers);

    return question;
  });
};
