import { capitalize } from 'lodash';
import * as t from 'io-ts';
import { dateOptions, isRealValue, MrpStage, SurveyType, UserStudyType } from 'tr-lib';
import { nullable } from '@tr-common';

export type StudyTypeIconHash = {
  [surveyType in SurveyType]: string;
};

export const studyTypeIconHash: StudyTypeIconHash = {
  core_study: 'lock-key',
  annual_questionnaire: 'annual',
  promotional_study: 'research',
  sub_study: 'sub-study'
};

export interface AllUsersStudiesStatuses {
  userId: string;
  studies: UserStudyType[];
}

export type StudyStatusStyleType = 'available' | 'active' | 'completed' | 'research-opportunity-progress' | 'to-hide';
export type StudyStatusNumberType = number | {min: number, max: number};
export interface InfoStudyStatus {
  status: StudyStatusNumberType;
  dashboard_status: string | string[];
  launchButton: string;
  messageIfEmpty: string;
  styleClass: StudyStatusStyleType;
  mrpStage: MrpStage;
}

export const statusForCompletedStudy = 60;
export const statusForClosedBySystemStudy = 100;
export const isStudyInactiveOrClose = (status: string): boolean => status === 'inactive' || status === 'closed';
export const isStudyInProgressForParticipant = (status: number): boolean => status > 0 && status < statusForCompletedStudy;
export const isStudyCompletedForParticipant = (status: number): boolean => status === statusForCompletedStudy;
export const isStudyNotClosedBySystem = (status: number): boolean => status <= statusForCompletedStudy;

export const infoStudyStatuses: InfoStudyStatus[] = [{
  status: 0, dashboard_status: ['open', 'not_started'], launchButton: 'Start the study',
  messageIfEmpty: 'New studies are upcoming soon. You will be notified by an email message.', styleClass: 'available',
  mrpStage: MrpStage.not_started,
},{
  status: {min: 2, max: statusForCompletedStudy - 1}, dashboard_status: 'in_progress', launchButton: 'Resume',
  messageIfEmpty: 'Go to “Available studies” to select a study.', styleClass: 'active',
  mrpStage: MrpStage.in_progress,
},{
  status: statusForCompletedStudy, dashboard_status: 'completed', launchButton: 'View details',
  messageIfEmpty: 'Click “Start the study” to participate.', styleClass: 'completed',
  mrpStage: MrpStage.completed,
},{
  status: statusForClosedBySystemStudy, dashboard_status: 'closed', launchButton: undefined, messageIfEmpty: '', styleClass: 'completed',
  mrpStage: MrpStage.closed,
}];

export const findInfoStudyStatusByNumber = (status: number): InfoStudyStatus => infoStudyStatuses.find(
  i => typeof i.status === 'number' ? i.status === status : status >= i.status.min && status <= i.status.max
);

export const getCurrentProgressForPromotional = (
  viewedAt: Date
) => isRealValue(viewedAt) ? `Viewed: ${new Date(viewedAt).toLocaleDateString('en-US', dateOptions)}` : 'Not viewed';

export const numberUserStudyStatus2String = (status: number): string => findInfoStudyStatusByNumber(status)?.mrpStage ?? 'Unknown status';

export interface StudyReviewParams {
  procedure: string;
  userID: string;
  userStudyID: string;
  studyID: string;
}
export interface StudyDownloadParams {
  procedure: string;
  userId: string;
  userStudyId: number | string;
}

export interface UserProcedureParams {
  procedure: string;
  userId: string;
  userStudyId: string;
}

export const procedureSchema = t.intersection([
  t.type({
    procedure_id: t.string,
  }),
  t.partial({
    created_at: nullable(t.string),
  })
], 'procedureSchema');
export type IProcedure = t.TypeOf<typeof procedureSchema>;
export type Procedures = IProcedure[];

export interface ReviewAnswerStatusDashboard {
  dateTime: string;
  content: string;
}