import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

import { UserNotificationForm } from '@tr-common';

@Component({
  selector: 'lib-notification-settings',
  template: `
    <ng-container [formGroup]="formGroup">
      <div class="mbs-20 setting-card" [class.admin-mode]="adminMode">
        <div class="flex align-center justify-between header" [class.underline]="openedReminders">
          <div>
            <h5>Reminders</h5>
            <span class="grey-label">Notifications to remind you about study and research opportunities.</span>
          </div>

          <button type="button" mat-flat-button color="secondary" (click)="openedReminders = !openedReminders">
            {{ openedReminders ? 'Close' : 'Manage' }}
          </button>
        </div>

        <div *ngIf="openedReminders">
          <lib-notification-toggle class="mbs-20" formGroupName="reminders_new_ro_and_studies"
            label="New Research Opportunities &amp; Studies" [group]="true">
            <ng-container *ngIf="!adminMode"><span class="grey-label">Remind me about:</span></ng-container>
          </lib-notification-toggle>

          <lib-notification-toggle class="grey-filled" formGroupName="reminders_ongoing_studies" label="Ongoing Studies"
            [group]="true" [header]="false">
          </lib-notification-toggle>
        </div>
      </div>

      <div class="mbs-20 setting-card">
        <lib-notification-toggle formGroupName="newsletter"
          label="Insights to current Registry data, follow-ups from sub-studies, articles, etc.">
          <h5 class="align-center">Newsletters</h5>
        </lib-notification-toggle>
      </div>

      <div class="mbs-20 setting-card">
        <lib-notification-toggle formGroupName="management_resources" label="Resources and news for our T1D Community.">
          <h5 class="align-center">Management Resources</h5>
        </lib-notification-toggle>
      </div>
    </ng-container>
  `,
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  @Input() adminMode = false;
  formGroup!: FormGroup<UserNotificationForm>;
  openedReminders = false;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.openedReminders = this.adminMode;
    this.formGroup = this.controlContainer.control as FormGroup<UserNotificationForm>;
  }
}
