import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-page-not-found',
  template: `<h1>Page Not Found</h1>`,
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  constructor(public meta: Meta) {}

  ngOnInit() {
    const noindex: MetaDefinition = {name: 'robots', content: 'noindex'};

    this.meta.addTag(noindex, true);
  }
}
