import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

import { FormControlErrorHandler, isEmptyValue, isNullOrUndefined, isRealValue, StudyOptionLimitsType } from '@tr-common';

import { ValidateResults, ValidateControlFuncType } from './validate-common';

export class StudyOptionLimits {
  min: number = null;
  max: number = null;
  decimal = 3;

  constructor(limits?: Partial<StudyOptionLimitsType>) {
    if (isRealValue(limits)) {
      this.min = isNullOrUndefined(limits.value_from) || isNaN(+limits.value_from) ? null : +limits.value_from;
      this.max = isNullOrUndefined(limits.value_to) || isNaN(+limits.value_to) ? null : +limits.value_to;
      if (isRealValue(limits.decimal)) {
        this.decimal = isNaN(+limits.decimal) ? this.decimal : +limits.decimal;
      }
    }
  }

  get placeholder(): string {
    const areBothEmpty =  isNullOrUndefined(this.max) &&  isNullOrUndefined(this.min) || isNaN(this.min) && isNaN(this.max);
    const min = isRealValue(this.min) ? this.min.toString() : '\u2026';
    const max = isRealValue(this.max) ? this.max.toString() : '\u2026';

    return areBothEmpty ? null : `${min} - ${max}`;
  }

  get getMinMaxValidators(): Array<ValidatorFn> {
    return [Validators.min(this.min), Validators.max(this.max)];
  }

  get integerValidator(): ValidateControlFuncType {
    return ({value}: AbstractControl): ValidateResults => new RegExp(/^-?\d*$/).test(value) ? null : {integer: true};
  }

  get floatValidator(): ValidateControlFuncType {
    return ({value}: AbstractControl): ValidateResults => {
     let validationPass: {float: true} = null;

      if (!isEmptyValue(value)) {
        validationPass = new RegExp(`^-?\\d*(\\.\\d{0,${this.decimal}})?\$`).test(value) ? null : {float: true};
      }

      return validationPass;
    };
  }
}

export const getErrorHandlers = (limits: StudyOptionLimits): FormControlErrorHandler[] => {
  const exceededFloatDecimal: FormControlErrorHandler[] = isRealValue(limits.decimal) ? [{
      mustBeShowed: control => isRealValue(control.errors?.float),
      message: `Number should not exceed ${limits.decimal} decimal points`
    }] : [];

  return [...exceededFloatDecimal, {
    mustBeShowed: control => isRealValue(control.errors?.integer),
    message: 'Value should be a whole number'
  }, {
    mustBeShowed: control => isRealValue(control.errors?.min) && isNullOrUndefined(control.errors?.integer),
    message: `Min value is ${limits.min}`
  }, {
    mustBeShowed: control => isRealValue(control.errors?.max) && isNullOrUndefined(control.errors?.integer),
    message: `Max value is ${limits.max}`
  }];
}
