import * as t from 'io-ts';

import { answerWidget } from './answer-widgets';
import { voidable } from './helpers';

export const datesFromProfileSchema = t.type({
  birthday: voidable(t.string),
  created_at: t.string,
}, 'datesFromProfileSchema');
export type DatesFromProfileType = t.TypeOf<typeof datesFromProfileSchema>;

export enum LeftValueFormat {
  jsonStartEnd = 'json:start,end',
  jsonStart = 'json:start',
  jsonEnd = 'json:end',
  jsonA1C = 'json:a1c',
  jsonDate = 'json:date',
  number = 'number',
  string = 'string'
}
export const leftValueFormatSchema = t.union([
  t.literal(LeftValueFormat.jsonStartEnd),
  t.literal(LeftValueFormat.jsonStart),
  t.literal(LeftValueFormat.jsonEnd),
  t.literal(LeftValueFormat.jsonA1C),
  t.literal(LeftValueFormat.jsonDate),
  t.literal(LeftValueFormat.number),
  t.literal(LeftValueFormat.string)
], 'leftValueFormatSchema');
export type LeftValueFormatType = t.TypeOf<typeof leftValueFormatSchema>;

export const widgetsToValidateSchema = t.union([
  t.literal(answerWidget.shortDate),
  t.literal(answerWidget.date),
  t.literal(answerWidget.numerical),
  t.literal(answerWidget.float),
  t.literal(answerWidget.a1cDynamic),
  t.literal(answerWidget.dateRange)
], 'widgetsToValidateSchema');
export type WidgetsToValidateType = t.TypeOf<typeof widgetsToValidateSchema>;

// The below set of types was taken from https://unitio.atlassian.net/wiki/spaces/RS/pages/897482851/Option+validators
export enum RightValueSource {
  static = 'value stored at ‘value’ field',
  dynamic = 'r value is calculated',
  stored_answer = 'value stored at registry_answers table by study_id, question_id, option_id path',
  profile_field = 'value from user profile (created_at, last_login)'
}
export const rightValueSourceSchema = t.keyof(RightValueSource);
export type RightValueSourceType = t.TypeOf<typeof rightValueSourceSchema>;

export const widgetToLValueFormatMap: {
  [key in WidgetsToValidateType]: LeftValueFormatType
} = {
  [answerWidget.shortDate]: LeftValueFormat.string,
  [answerWidget.date]: LeftValueFormat.string,
  [answerWidget.numerical]: LeftValueFormat.number,
  [answerWidget.float]: LeftValueFormat.number,
  [answerWidget.a1cDynamic]: LeftValueFormat.jsonA1C,
  [answerWidget.dateRange]: LeftValueFormat.jsonStartEnd
};
