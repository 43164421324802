import * as t from 'io-ts';

import { allCriteriaFieldsSchema } from './criteria-field';

export const allGlobalConditionsSchema = t.union([
  t.literal('&'),
  t.literal('|'),
]);

export const allPossibleConditionsSchema = t.union([
  t.literal('>'),
  t.literal('>='),
  t.literal('<'),
  t.literal('<='),
  t.literal('='),
  t.literal('!='),
  t.literal('~'),
  t.literal('~*'),
  t.literal('exists'),
], 'allPossibleConditionsSchema');

export const threePartConditionSchema = t.tuple([
  allPossibleConditionsSchema,
  allCriteriaFieldsSchema,
  t.string
]);
