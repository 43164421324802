import * as t from 'io-ts';

import { nullable } from './helpers';

export const optionAddressSchema = t.type({
  study_id: nullable(t.string),
  question_id: nullable(t.string),
  option_id: nullable(t.string),
}, 'optionAddressSchema');
export type OptionAddressType = t.TypeOf<typeof optionAddressSchema>;
export type OptionAddressesType = OptionAddressType[];

export const optionAddressV2Schema = t.type({
  stored_study_id: nullable(t.string),
  stored_question_id: nullable(t.string),
  stored_option_id: nullable(t.string),
}, 'optionAddressV2Schema');
export type OptionAddressV2Type = t.TypeOf<typeof optionAddressV2Schema>;

export type ValidationAddress = { validation_id: string; } & OptionAddressType;
export const optionAddressToV2 = ({study_id, question_id, option_id}: OptionAddressType): OptionAddressV2Type => ({
  stored_study_id: study_id, stored_question_id: question_id, stored_option_id: option_id
});
