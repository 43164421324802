import { Component } from '@angular/core';

@Component({
  selector: 'app-reconsent-wrapper',
  template: `
    <div class="content-wrapper">
      <app-authenticated-header [hideUserProfile]="true"></app-authenticated-header>
      <router-outlet></router-outlet>
    </div>
    <app-footer [noNavButtons]="true"></app-footer>
  `,
  styleUrls: ['./reconsent-wrapper.component.scss']
})
export class ReconsentWrapperComponent {
}
