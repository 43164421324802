import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router } from '@angular/router';
import { get, intersection, isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';

import { ParticipantState, tryWithFallback } from 'tr-lib';

import { PrivilegesService } from '@services/privileges.service';

/**
 * @param privileges
 * @param participantState
 *
 * @example
 * _.isEqual(checkPrivilegeAvailability(undefined, 'assent'), true)
 * _.isEqual(checkPrivilegeAvailability(['re-consent'], 'assent'), false)
 * _.isEqual(checkPrivilegeAvailability(['assent'], 'assent'), true)
 */
const checkPrivilegeAvailability = (privileges: string[], participantState: string): boolean =>
  isEmpty(privileges) ? true : intersection(privileges, [participantState]).length > 0;

/**
 * @param privileges
 * @param participantState
 *
 * @example
 * _.isEqual(checkPrivilegeAbsence(undefined, 'assent'), true)
 * _.isEqual(checkPrivilegeAbsence(['re-consent'], 'assent'), true)
 * _.isEqual(checkPrivilegeAbsence(['assent'], 'assent'), false)
 */
const checkPrivilegeAbsence = (privileges: string[], participantState: string): boolean =>
  isEmpty(privileges) ? true : intersection(privileges, [participantState]).length === 0;

@Injectable({providedIn: 'root'})
export class PrivilegeAuthorizationGuard implements CanActivate, CanLoad {
  constructor(
    private stateService: PrivilegesService,
    private router: Router
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canLoadOrActivate(snapshot.data);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.canLoadOrActivate(route.data);
  }

  canLoadOrActivate(data: Data): Observable<boolean> {
    const state = tryWithFallback(this.stateService.state, x => x, ParticipantState.regular);
    const privileges: ParticipantState[] = get(data, ['privileges'], []);
    const antiPrivileges: ParticipantState[] = get(data, ['forbiddenPrivileges'], []);
    const result = checkPrivilegeAvailability(privileges, state) && checkPrivilegeAbsence(antiPrivileges, state);

    if (!result) {
      this.router.navigate(['']).then();
    }

    return of(result);
  }
}
