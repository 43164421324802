/**
 * needs sync with {@link consentTypeSchema}
 */
export enum TypeConsent {
  parental = 'parental',
  adult = 'adult',
  child = 'child',
  jaeb = 'jaeb',
  universal = 'universal'
}

export interface DialogTypeConsent {
  consentType: TypeConsent;
}

export interface DialogEligibleTypeConsent extends DialogTypeConsent {
  source: string;
}
