import { EventEmitter } from '@angular/core';

import { ValidatorType } from '@tr-common';

import { StudyOption } from './study-option';

export enum SourceChange {
  user = 'User',
  click = 'Click'
}
export type SourceChangeType = SourceChange.user | SourceChange.click;

export interface WidgetBase<WidgetType> {
  value: WidgetType;
  valid: ValidatorType;
  hasWarning?: boolean;
}

export interface WidgetModify<WidgetType> extends WidgetBase<WidgetType> {
  source: SourceChangeType;
  id?: string;
}

export interface WidgetPrototype<WidgetType> extends WidgetBase<WidgetType> {
  modify: EventEmitter<WidgetModify<WidgetType>>;
  option: StudyOption;
  emitState(source: SourceChangeType): void;
}
