import { createAction, props } from '@ngrx/store';

export enum interceptorActions {
  UNAUTHORIZED = '[INTERCEPTOR] Unauthorized',
  REGISTER_JAEB_USER_CLICKS_AGAIN = '[INTERCEPTOR] Jaeb user clicks the link on its letter again',
  INTERNAL_SERVER_ERROR = '[INTERCEPTOR] Internal Server Error',
  CONNECTION_ERROR = '[INTERCEPTOR] Connection or server error'
}

export const unauthorized = createAction(
  interceptorActions.UNAUTHORIZED
);

export const registerJaebUserClicksAgain = createAction(
  interceptorActions.REGISTER_JAEB_USER_CLICKS_AGAIN,
  props<{payload: string}>()
);

export const internalServerError = createAction(
  interceptorActions.INTERNAL_SERVER_ERROR
);

export const connectionError = createAction(
  interceptorActions.CONNECTION_ERROR
);
