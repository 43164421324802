import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { SurveyVersionInterceptor } from 'tr-lib';

import { NotAuthorizedInterceptor } from './not-authorized-interceptor';
import { ServerErrorInterceptor } from './server-error-interceptor';
import { AuthInterceptor } from './auth.interceptor';
import { AddHeaderInterceptor } from './auth-header.interceptor';

export const httpInterceptorProviders: Provider[] = [
  {provide: HTTP_INTERCEPTORS, useClass: NotAuthorizedInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: SurveyVersionInterceptor, multi: true},
];
