import { FormArray, FormControl, FormGroup } from '@angular/forms';

import {
  A1CDataWithValidity,
  A1CFormGroupType,
  A1CRowValidator,
  isRealValue,
  newA1cInWidgetPair,
  newA1CPair,
  RowA1c,
  RowA1CFormType,
  RowA1cInWidget,
} from '@tr-common';

import { fillA1cValidity } from './a1c-validation';

export const startNumberOfA1cPairs = 4;
export const maximumNumberOfA1cPairs = 12;

export const getDefaultA1cData = (): RowA1c[] => Array.from({length: maximumNumberOfA1cPairs}).map(newA1CPair);
export const getDefaultA1cDataInWidget = (): RowA1cInWidget[] => Array.from({length: maximumNumberOfA1cPairs}).map(newA1cInWidgetPair);

export const fillDataOutWithValidity = (value: string, previousValidity: A1CRowValidator[]): A1CDataWithValidity => {
  let data: RowA1c[] = [];
  let filled: number = startNumberOfA1cPairs;

  try { data = JSON.parse(value); } catch (e) { console.warn(e); }
  if (Array.isArray(data)) {
    filled = data.length < startNumberOfA1cPairs ? startNumberOfA1cPairs : data.length;
    if (data.length < maximumNumberOfA1cPairs) {
      Array.from({length: maximumNumberOfA1cPairs}).forEach((v, i) => {
        if (!isRealValue(data[i])) {
          data[i] = newA1CPair();
        }
      });
    }
  } else {
    data = getDefaultA1cData();
  }

  return {data, validity: fillA1cValidity(data, previousValidity), filled};
};

export const getA1cWidgetFormPairs = () => new FormGroup<RowA1CFormType>({
  a1c: new FormControl<RowA1cInWidget['a1c']>(null, {updateOn: 'blur'}),
  date: new FormControl<RowA1cInWidget['date']>(null, {updateOn: 'blur'})
});

export const getA1cWidgetFormGroup = (numberOfPairs = maximumNumberOfA1cPairs) => new FormGroup<A1CFormGroupType>({
  pairs: new FormArray(Array.from({length: numberOfPairs}).map(getA1cWidgetFormPairs))
});
