import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { checkProfileTypeForm, ParticipantUpdateProfileWithIdType, validateProfileType } from 'tr-lib';

@Component({
  selector: 'app-missed-profile-type-dialog',
  template: `
    <div mat-dialog-content>
      <img src="assets/people_with_phone.svg" alt="profile type">
      <h3>Select a profile type.</h3>
      <app-select-profile-type [form]="form" tooltipPosition="left-middle"></app-select-profile-type>
    </div>

    <div mat-dialog-actions class="confirmation-actions mbs-20">
      <button class="dialog-button is-shadowed" mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
      <button class="dialog-button" mat-flat-button color="secondary" mat-dialog-close>Sign out</button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./missed-profile-type-dialog.component.scss']
})
export class MissedProfileTypeDialogComponent {
  form: UntypedFormGroup = this.fb.group({
    profileType: ['', validateProfileType()]
  }, {updateOn: 'change'}); // Do not change the 'updateOn' strategy

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {id: string},
    public fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MissedProfileTypeDialogComponent>
  ) {}

  get choice(): ParticipantUpdateProfileWithIdType {
    const {profileType, ...data} = this.form.value;

    return {...data, id: this.data.id, profile_type: profileType};
  }

  onConfirm(): void {
    checkProfileTypeForm(this.form);
    if (this.form.valid) {
      this.dialogRef.close(this.choice);
    }
  }
}
