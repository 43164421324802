import { createAction, props } from '@ngrx/store';

import { UserStudyProcedureType } from '../../../models';

export enum ProcedureSubmitActions {
  SUBMIT_PROCEDURE = '[SUBMIT] Submit procedure',
  SUBMIT_PROCEDURE_SUCCESS = '[SUBMIT] Submit procedure Success',
  SUBMIT_PROCEDURE_FAIL = '[SUBMIT] Submit procedure Fail',
  SUBMIT_PROCEDURE_ABSENT_ANSWERS = '[SUBMIT] Submit procedure fail because of absent answers on BE',
  SUBMIT_CONSENT_PROCEDURE_FORCIBLY = '[SUBMIT] Forced submit the consent procedure if anything went wrong on BE part',
  NAVIGATE_TO_QUESTION_PROCEDURE = '[SUBMIT] Navigate to the question procedure after successful auto submit',
  REDIRECT_TO_PROCEDURE_STATUS = '[SUBMIT] Navigate to a procedure status in case of load the submit page for already submitted procedure',
  START_REFRESH_PENDING = '[SUBMIT] Start refresh pending',
  GET_SUBMITTED_ANSWERS = '[SUBMIT] Get submitted answers for the current procedure',
  GET_SUBMITTED_ANSWERS_SUCCESS = '[SUBMIT] Got submitted answers for the current procedure success',
  GET_SUBMITTED_ANSWERS_FAIL = '[SUBMIT] Got submitted answers for the current procedure fail',
  GET_PDF_OF_SUBMITTED_ANSWERS = '[SUBMIT] Get PDF version of submitted answers for the current procedure',
  GET_PDF_OF_SUBMITTED_ANSWERS_SUCCESS = '[SUBMIT] Got PDF version of submitted answers for the current procedure success',
  GET_PDF_OF_SUBMITTED_ANSWERS_FAIL = '[SUBMIT] Got PDF version of submitted answers for the current procedure fail',
  CLEAR_SUBMITTED_ANSWERS = '[SUBMIT] Clear submitted answers in the store'
}

export const submitProcedure = createAction(
  ProcedureSubmitActions.SUBMIT_PROCEDURE,
  props<{id: string, userID: string, hasConclusionPopup: boolean}>()
);

export const submitProcedureSuccess = createAction(
  ProcedureSubmitActions.SUBMIT_PROCEDURE_SUCCESS,
  props<{payload: UserStudyProcedureType}>()
);

export const submitProcedureFail = createAction(
  ProcedureSubmitActions.SUBMIT_PROCEDURE_FAIL,
  props<{error: unknown}>()
);

export const submitProcedureAbsentAnswers = createAction(
  ProcedureSubmitActions.SUBMIT_PROCEDURE_ABSENT_ANSWERS,
  props<{error: string[], procedure: string}>()
);

export const submitConsentProcedureForcibly = createAction(
  ProcedureSubmitActions.SUBMIT_CONSENT_PROCEDURE_FORCIBLY
);

export const navigateToQuestionProcedure = createAction(
  ProcedureSubmitActions.NAVIGATE_TO_QUESTION_PROCEDURE,
  props<{studyID: string; userID: string, userStudyID: string}>()
);

export const redirectToProcedureStatus = createAction(
  ProcedureSubmitActions.REDIRECT_TO_PROCEDURE_STATUS,
  props<{procedure: string}>()
);

export const startRefreshPending = createAction(
  ProcedureSubmitActions.START_REFRESH_PENDING
);

export const getSubmittedAnswers = createAction(
  ProcedureSubmitActions.GET_SUBMITTED_ANSWERS
);

export const getSubmittedAnswersSuccess = createAction(
  ProcedureSubmitActions.GET_SUBMITTED_ANSWERS_SUCCESS,
  props<{payload: string}>()
);

export const getSubmittedAnswersFail = createAction(
  ProcedureSubmitActions.GET_SUBMITTED_ANSWERS_FAIL,
  props<{error: unknown}>()
);

export const getPDFOfSubmittedAnswers = createAction(
  ProcedureSubmitActions.GET_PDF_OF_SUBMITTED_ANSWERS,
  props<{toPrint: boolean}>()
);

export const getPDFOfSubmittedAnswersSuccess = createAction(
  ProcedureSubmitActions.GET_PDF_OF_SUBMITTED_ANSWERS_SUCCESS,
  props<{payload: Blob, filename: string, toPrint: boolean}>()
);

export const getPDFOfSubmittedAnswersFail = createAction(
  ProcedureSubmitActions.GET_PDF_OF_SUBMITTED_ANSWERS_FAIL,
  props<{error: unknown}>()
);

export const clearSubmittedAnswers = createAction(
  ProcedureSubmitActions.CLEAR_SUBMITTED_ANSWERS
);
