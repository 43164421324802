import { PreventCopyPaste } from './prevent-copy-paste';

export const controlCopy = (event: Event | KeyboardEvent): void => {
  if (event instanceof KeyboardEvent) {
    const preventCopyPaste = new PreventCopyPaste(event);

    if (preventCopyPaste.isPreventedCode) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
};
