import { Component } from '@angular/core';

import { exchangeContactInfoT1D } from 'tr-lib';

@Component({
  selector: 'app-registry-security-info',
  template: `
    <section class="tr-security">
      <div class="tr-security-banner">
        <div class="tr-security-banner-background">
          <img class="l-desktop" src="assets/img/t1d_banner.png" alt="#">
          <img class="l-tablet" src="assets/img/t1d_banner_tablet.png" alt="#">
          <img class="l-mobile" src="assets/img/t1d_banner_mobile.png" alt="#">
          <div>
            <h1>Be heard.<br>Support the type 1 diabetes community. <br> Help drive research that matters.</h1>
            <button mat-flat-button color="primary" routerLink="/auth/register" class="is-shadowed">Join Now</button>
          </div>
        </div>
      </div>
      <img class="security-img" src="assets/img/security_img.png" alt="#">
      <div class="tr-security-text">
        <h1>T1D Exchange takes data privacy and security seriously.</h1>
        <p>We will do everything to ensure that the information you entrust to us is secure.</p>
        <p>
          Please contact
          <a target="_blank" rel="noopener" href="mailto:{{ contactInfo.email }}" (click)="$event.stopPropagation()">T1D Exchange</a>
          for more information about our Privacy Policy
        </p>
      </div>
    </section>
  `,
  styleUrls: ['./registry-security-info.component.scss']
})
export class RegistrySecurityInfoComponent {
  readonly contactInfo = exchangeContactInfoT1D;
}
