import { ParticipantSourceType } from '@tr-common';

import { ParticipantDetails, Study } from '../../models';
import { UserStudyType } from './user-study';
import { ParamsIDs } from './survey-url';

export interface UrlStudyWithProcedureAndSource extends ParamsIDs {
  source: ParticipantSourceType;
}

export interface UrlStudyWithIsStatus extends ParamsIDs {
  procedureId: string;
  isStatus?: boolean;
}

export interface UrlStudyWithProcedureAndQuestion extends ParamsIDs {
  procedure: string;
  questionId: string;
}

export type OptRedirectParamsType = (
  {study: Study, profile: ParticipantDetails, userStudy: UserStudyType} | {studyId: string, profileId: string, userStudyId: string}
) & {queryParams?: {procedure: string, questionId?: string | null}};

export const getOptRedirectParams = (opt: OptRedirectParamsType): ParamsIDs => {
  const studyID = 'studyId' in opt ? opt.studyId : opt.study.id;
  const userID = 'profileId' in opt ? opt.profileId : opt.profile.id;
  const userStudyID: string = 'userStudyId' in opt ? opt.userStudyId : opt.userStudy.id.toString();

  return {studyID, userStudyID, userID};
};
