import * as t from 'io-ts';

import {
  answerWidgetsSchema,
  nullable,
  optionAddressV2Schema,
  rightValueSourceSchema,
  studyOptionValidatorActionSchema,
  studyOptionValidatorOperatorsSchema,
  studyUUIDSchema,
  twoTypesNullable,
  voidable,
} from '@tr-common';

import { widgetFieldArraySchema } from './widget-fields';

/**
 * This model was created using https://unitio.atlassian.net/wiki/spaces/RS/pages/897482851/Option+validators+v2
 */
export const studyOptionValidatorSchema = t.intersection([
  studyUUIDSchema,
  optionAddressV2Schema,
  t.type({
    option: nullable(t.string),
    read_only: t.boolean,
    label: t.string,
    message: t.string,
    action: studyOptionValidatorActionSchema,
    l_option_widget: answerWidgetsSchema,
    l_widget_fields: voidable(widgetFieldArraySchema),
    operator: studyOptionValidatorOperatorsSchema,
    r_value_source: rightValueSourceSchema,
    r_value_dynamic: nullable(t.string),
    r_value_static: twoTypesNullable(t.string, t.number),
    profile_field: nullable(t.string),
  }),
  t.partial({
    skip_if_no_stored_answer: nullable(t.boolean),
    created_at: voidable(t.string),
    updated_at: voidable(t.string),
  })
], 'studyOptionValidatorSchema');
export type StudyOptionValidatorType = t.TypeOf<typeof studyOptionValidatorSchema>;
