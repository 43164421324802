import { Action, createReducer, on } from '@ngrx/store';

import { ParticipantSourceType, ParticipantState, ParticipantStatusType, ProfileType } from 'tr-lib';

import { convertUserSuccess, ParticipantNotificationType } from '@app/model';
import {
  changePass,
  changePassSuccess,
  changePasswordReconsent,
  defaultUserState,
  getUserSuccess,
  loadUserSuccess,
  loginSuccess,
  loginWithDualFA,
  logout,
  reconsentFinishStep1,
  resetState,
  toggleNotificationBanner,
  updateProfileReconsent,
  updateProfileWhileReconsentPendingSuccess,
  userUpdateItsProfileTypeSuccess,
} from '@store/actions';
import { getNotificationsOnSuccess, updateHouseholdInfoSuccess, updateProfileSuccess } from '@app/user-profile/store/profile.actions';
import { AuthMethod } from '@app/auth/models';

export interface UserState {
  account_status: ParticipantStatusType;
  address: string;
  auth_methods: AuthMethod[];
  bannersVisibility: { [id: string]: boolean };
  changePasswordInProfileSuccess: boolean;
  child_email: string;
  child_first_name: string;
  child_last_name: string;
  child_phone: string;
  city: string;
  country_state: string;
  dateJoined: Date;
  email: string;
  first_name: string;
  gender_identity: string;
  id: string;
  is_eligible: boolean;
  last_name: string;
  notifications: ParticipantNotificationType;
  phone: string;
  profile_type: ProfileType;
  reconsentStep1Finished: boolean;
  source: ParticipantSourceType;
  state: ParticipantState;
  timezone: number;
  zip_code: string;
}

export const initialUserState: UserState = {
  account_status: null,
  address: null,
  auth_methods: [],
  bannersVisibility: {},
  changePasswordInProfileSuccess: false,
  child_email: null,
  child_first_name: null,
  child_last_name: null,
  child_phone: null,
  city: null,
  country_state: null,
  dateJoined: null,
  email: null,
  first_name: null,
  gender_identity: null,
  id: null,
  is_eligible: null,
  last_name: null,
  notifications: null,
  phone: null,
  profile_type: null,
  reconsentStep1Finished: false,
  source: null,
  state: null,
  timezone: null,
  zip_code: null,
};

const reducer = createReducer<UserState, Action>(
  initialUserState,
  on(getUserSuccess, (state, {payload}) => convertUserSuccess(state, payload)),
  on(loadUserSuccess, (state, a: ReturnType<typeof loadUserSuccess>) => convertUserSuccess(state, a)),
  on(loginSuccess, loginWithDualFA, state => state),
  on(logout, () => ({...initialUserState})),
  on(getNotificationsOnSuccess, (state, {payload}) => ({...state, notifications: payload})),
  on(updateProfileSuccess, (state, {payload}) => convertUserSuccess(state, payload)),
  on(updateHouseholdInfoSuccess, (state, {response}) => ({...state, ...response})),
  on(updateProfileWhileReconsentPendingSuccess, (state, {payload}) => ({...state, ...payload})),
  on(resetState, () => ({...initialUserState})),
  on(updateProfileReconsent, (state) => ({...state, reconsentStep1Finished: false})),
  on(changePasswordReconsent, (state) => ({...state, reconsentStep1Finished: false})),
  on(reconsentFinishStep1, (state) => ({...state, reconsentStep1Finished: true})),
  on(toggleNotificationBanner, (state, {notification}) => {
    const id = notification.id;
    const idExists = id in state.bannersVisibility;
    const newVal = idExists ? !state.bannersVisibility[id] : false; // if exists then invert else create as `false`

    return {...state, bannersVisibility: {...state.bannersVisibility, [id]: newVal}};
  }),
  on(userUpdateItsProfileTypeSuccess, (state, {payload}) => ({...state, ...payload})),
  on(changePass, (state) => ({...state, changePasswordInProfileSuccess: false})),
  on(changePassSuccess, (state) => ({...state, changePasswordInProfileSuccess: true})),
  on(defaultUserState, (state) => ({...state}))
);

export const userReducer = (state: UserState, action: Action) => reducer(state, action);
