import { createAction, props } from '@ngrx/store';

import { BaseChildProfileType, ChangePassPayload, ParticipantProfile, ServerValidationErrors, UserHouseholdType } from 'tr-lib';

export enum errorActions {
  UPDATE_PROFILE_INFO_ERROR = '[Error] Update Profile info',
  UPDATE_HOUSEHOLD_INFO_ERROR = '[Error] Update Household info',
  UPDATE_CHILD_INFO_ERROR = '[Error] Update Profile info',
  SET_LOGIN_ERROR = '[Error] Set Login Error',
  SET_REGISTER_ERROR = '[Error] Set Register Error',
  SET_NEW_PASS_ERROR = '[Error] Set New Password Error',
  SET_VALIDATION_ERROR = '[Error] Set Validation Error',
  SET_TOKEN_ERROR = '[Error] Set Token Error',
  SET_USER_DEACTIVATE_ERROR = '[Error] Set User Deactivate Error',
  SET_CHANGE_PASS_ERROR = '[Error] Set Change Password Error',
  SET_RESET_PASS_ERROR = '[Error] Set Reset Password Error',
  SET_NOTIFICATIONS_ON_ERROR = '[Error] Set notifications on Error',
  SET_RECONSENT_PASS_ERROR = '[Error] Set Reconsent Password Error',
  CLEAN_PHONE_ERROR_STATE = '[Error] Clean error state if a participants clear the phone field ',
  RESET_ERROR_STATE = '[Error] Reset Error State',
}

export const updateProfileInfoError = createAction(
  errorActions.UPDATE_PROFILE_INFO_ERROR,
  props<{error: ServerValidationErrors<ParticipantProfile>}>()
);

export const updateHouseholdInfoError = createAction(
  errorActions.UPDATE_HOUSEHOLD_INFO_ERROR,
  props<{error: ServerValidationErrors<UserHouseholdType>}>()
);

export const updateChildInfoError = createAction(
  errorActions.UPDATE_CHILD_INFO_ERROR,
  props<{error: ServerValidationErrors<BaseChildProfileType>}>()
);

export const setRegisterError = createAction(
  errorActions.SET_REGISTER_ERROR,
  props<{error: unknown}>()
);

export const setLoginError = createAction(
  errorActions.SET_LOGIN_ERROR,
  props<{error: unknown}>()
);

export const setNewPassError = createAction(
  errorActions.SET_NEW_PASS_ERROR,
  props<{error: unknown}>()
);

export const setChangePassError = createAction(
  errorActions.SET_CHANGE_PASS_ERROR,
  props<{error: ServerValidationErrors<ChangePassPayload>}>()
);

export const setResetPassError = createAction(
  errorActions.SET_RESET_PASS_ERROR,
  props<{error: unknown}>()
);

export const setNotificationsOnError = createAction(
  errorActions.SET_NOTIFICATIONS_ON_ERROR,
  props<{error: unknown}>()
);

export const setValidationError = createAction(
  errorActions.SET_VALIDATION_ERROR,
  props<{error: unknown}>()
);

export const setTokenError = createAction(
  errorActions.SET_TOKEN_ERROR,
  props<{error: unknown}>()
);

export const setUserDeactivateError = createAction(
  errorActions.SET_USER_DEACTIVATE_ERROR,
  props<{error: unknown}>()
);

export const setReconsentPassError = createAction(
  errorActions.SET_RECONSENT_PASS_ERROR,
  props<{error: unknown}>()
);

export const cleanPhoneErrorState = createAction(
  errorActions.CLEAN_PHONE_ERROR_STATE,
);

export const resetErrorState = createAction(
  errorActions.RESET_ERROR_STATE,
);
