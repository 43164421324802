import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainBannerComponent {
  scroll = () => document.querySelector('#questionnaire-section').scrollIntoView({behavior: 'smooth'});
}
