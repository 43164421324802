import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SafeHtmlPipe } from './safe-html.pipe';
import { TextReplacePipe } from './text-replace.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TextReplacePipe, SafeHtmlPipe],
  exports: [TextReplacePipe, SafeHtmlPipe]
})
export class SurveyPipesModule {}
