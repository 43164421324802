import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { ParticipantState } from 'tr-lib';

@Injectable({providedIn: 'root'})
export class PrivilegesService {
  participantState$: ReplaySubject<ParticipantState> = new ReplaySubject(1);
  private _state: ParticipantState;
  /**
   * @return ParticipantState
   */
  get state(): ParticipantState {
    return this._state;
  }
  /**
   * @param newState
   */
  set state(newState: ParticipantState) {
    this._state = newState;
    this.participantState$.next(newState);
  }
}
