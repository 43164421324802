import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialImportsModule } from '../../../material-imports.module';
import { WidgetsModule } from '../../../widgets/widgets.module';
import { QuestionComponent } from './question.component';

@NgModule({
  declarations: [
    QuestionComponent
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    MaterialImportsModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetsModule,
  ],
  exports: [
    QuestionComponent,
  ],
})
export class QuestionModule {}
