import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';

import { BrowserGlobalRef, ContactInfoModule, GlobalRef, MaterialImportsModule, TrLibModule } from 'tr-lib';

import { RegistryPublic } from '@app/model';
import { SignConsentComponent } from '@app/assent-re-consent/components/sign-consent/sign-consent.component';

import { AuthenticatedHeaderComponent } from './components/authenticated-header/authenticated-header.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { CloseTooltipOnScrollDirective } from './scroll-close.directive';
import { ContentWrapperComponent } from './components/content-wrapper/content-wrapper.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MinimalisticLoggedInHeaderComponent } from './components/minimalistic-logged-in-header/minimalistic-logged-in-header.component';
import { MobileHeaderMenuComponent } from './components/mobile-header-menu/mobile-header-menu.component';
import { NotAuthHeaderComponent } from './components/not-auth-header/not-auth-header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReconsentWrapperComponent } from './components/reconsent-wrapper/reconsent-wrapper.component';
import { RegistrySnackBarComponent } from './components/registry-snack-bar/registry-snack-bar.component';
import { SelectProfileTypeComponent } from './components/select-profile-type/select-profile-type.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StudyHeaderComponent } from './components/study-header/study-header.component';
import { UiKitComponent } from './components/ui-kit/ui-kit.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { UseT1ExchangeComponent } from './components/use-t1-exchange/use-t1-exchange.component';
import { StudyHeaderNoProcedureComponent } from './components/study-header/study-header-no-procedure.component';

/**
 * All the “dumb” components and pipes should be implemented here.
 * These components don’t import and inject services from core or other features in their constructors.
 * They should receive all data though attributes in the template of the component using them.
 * This all sums up to the fact that SharedModule does not have any dependency to the rest of our application.
 * (except "HasPrivilegeDirective", but it doesn't depend on redux store, so it's ok)
 */
@NgModule({
  imports: [
    TrLibModule,
    TextMaskModule,
    ContactInfoModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialImportsModule,
  ],
  declarations: [
    AuthenticatedHeaderComponent,
    BackButtonComponent,
    CloseTooltipOnScrollDirective,
    ContentWrapperComponent,
    FooterComponent,
    HeaderComponent,
    MinimalisticLoggedInHeaderComponent,
    MobileHeaderMenuComponent,
    NotAuthHeaderComponent,
    PageNotFoundComponent,
    ReconsentWrapperComponent,
    RegistrySnackBarComponent,
    SelectProfileTypeComponent,
    SignConsentComponent,
    SpinnerComponent,
    StudyHeaderComponent,
    UiKitComponent,
    UnderConstructionComponent,
    UseT1ExchangeComponent,
    StudyHeaderNoProcedureComponent
  ],
  exports: [
    AuthenticatedHeaderComponent,
    BackButtonComponent,
    CloseTooltipOnScrollDirective,
    ContentWrapperComponent,
    FooterComponent,
    HeaderComponent,
    MinimalisticLoggedInHeaderComponent,
    MobileHeaderMenuComponent,
    NotAuthHeaderComponent,
    PageNotFoundComponent,
    ReconsentWrapperComponent,
    SelectProfileTypeComponent,
    SignConsentComponent,
    SpinnerComponent,
    StudyHeaderComponent,
    UiKitComponent,
    UnderConstructionComponent,
    UseT1ExchangeComponent,
    StudyHeaderNoProcedureComponent
  ]
})
export class SharedModule {
  static forBrowser(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule, providers: [
        {provide: GlobalRef<RegistryPublic>, useClass: BrowserGlobalRef<RegistryPublic>}
      ]
    };
  }
}
