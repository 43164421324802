import { getPayloadFromToken } from 'tr-lib';

/* eslint-disable @typescript-eslint/naming-convention */
export interface AuthorizationHeaders {
  'Cache-Control': string;
  Pragma: string;
  Expires: string;
  'Content-type': string;
  'X-Client-ID': string;
  Authorization: string;
}

const ie11AvoidCacheIssuesHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1'
};

export const getAuthorizationHeaders = (token: string): AuthorizationHeaders => ({
  ...ie11AvoidCacheIssuesHeaders,
  'Content-type': 'application/json',
  'X-Client-ID': getPayloadFromToken(token).user_id,
  Authorization: 'JWT ' + token
}) as const;
