import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, isDevMode } from '@angular/core';

import { isObject, isRealValue } from 'tr-lib';

import { ErrorService } from './error.service';

@Injectable()
export class RegistryErrorHandler implements ErrorHandler {
  constructor(private errorService: ErrorService) {}

  handleError(error: unknown): void {
    const isErrorNotFromBE = !(error instanceof HttpErrorResponse);
    console.error(error);

    if (isErrorNotFromBE && !isDevMode()) {
      const {message, stack} = (isObject(error) ? error : {}) as {message: unknown, stack: unknown};

      if (isRealValue(message) || isRealValue(stack)) {
        this.errorService.sendErrorLog(message as string, stack).subscribe();
      }
    }
  }
}
