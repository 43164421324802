import { Action, createReducer, on } from '@ngrx/store';

import { checkTokenSuccess, resetPasswordSuccess, setNewPassSuccess, setPasswordInitialState } from '@store/actions';

export interface ResetPasswordState {
  resetPassEmailSubmitted: boolean;
  checkTokenSuccess: boolean;
  resetPassSuccess: boolean;
}

export const resetPasswordProperty = 'reset-password';

export const initialResetPasswordState: ResetPasswordState = {
  resetPassEmailSubmitted: false,
  checkTokenSuccess: false,
  resetPassSuccess: false
}

const reducer = createReducer(
  initialResetPasswordState,
  on(checkTokenSuccess, (state) => ({...state, checkTokenSuccess: true})),
  on(setNewPassSuccess, (state) => ({...state, resetPassSuccess: true})),
  on(resetPasswordSuccess, (state) => ({...state, resetPassEmailSubmitted: true})),
  on(setPasswordInitialState, () => ({...initialResetPasswordState})),
);

export const resetPasswordReducer = (state = initialResetPasswordState, action: Action): ResetPasswordState => reducer(state, action);
