import { Component } from '@angular/core';

import { BaseCheckboxAndRadioComponent } from '../../helpers';

@Component({
  selector: 'lib-checkbox-widget',
  template: `
    <mat-checkbox color="primary" [aria-label]="option?.title" [checked]="checked" (change)="onChange($event.checked)">
      <span [innerHTML]="option.title"></span>
    </mat-checkbox>
  `
})
export class CheckboxWidgetComponent extends BaseCheckboxAndRadioComponent {
  constructor() {
    super();
  }
}
