import { StudySource } from '@tr-common';

import { StudyConditionType } from '../../models';
import { StudyQuestionRuleType } from '../../models/rules';

export type UserStudyMockType = number | 'preview-mock-id';
export interface IStudyIDs {
  user_study_id: UserStudyMockType;
  optionId: string;
}

export const filterConditionsByStudyIdAndOptionId = (
  acc: IStudyIDs[], studyID: string, rule: StudyQuestionRuleType
): StudyConditionType[] => rule.conditions.filter((condition: StudyConditionType) => {
  let isFound = condition.l_value === StudySource.storedAnswer;

  if (isFound) {
    const path = condition.stored_answer_path;

    isFound = path.study_id !== studyID && acc.findIndex(data => path.option_id === data.optionId) === -1;
  }

  return isFound;
});
