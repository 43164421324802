import { isRealValue } from 'tr-lib';

import { ErrorState } from '@app/_store';

export interface RegisterError {
  [k: string]: string[];
}

export interface DetailsError {
  detail: string;
}

export type ServerSuspendedType = string | {message: string, account_status?: 'suspended', recaptcha_token?: string[]};

export const cleanPhoneErrorFromState = (state: ErrorState): ErrorState => {
  let profileError = isRealValue(state.profileError) ? {...state.profileError} : null;

  if (isRealValue(profileError?.phone)) {
    delete profileError.phone;
    if (Object.keys(profileError).length === 0) profileError = null;
  }

  return {...state, profileError};
};
