export interface IconsToRegister {
  iconName: string;
  iconPath: string;
}

export const libSvgIcons: IconsToRegister[] = [{
  iconName: 'cancel-ic-msg', iconPath: '/assets/icons/cancel_ic_msg.svg'
}, {
  iconName: 'gift-letter', iconPath: '/assets/icons/gift-letter.svg',
}, {
  iconName: 'alert', iconPath: '/assets/icons/alert.svg',
}, {
  iconName: 'email-ok', iconPath: '/assets/icons/email-ok.svg',
}, {
  iconName: 'email-warn', iconPath: '/assets/icons/email-warn.svg',
}, {
  iconName: 'email-error', iconPath: '/assets/icons/email-error.svg',
}, {
  iconName: 'email-checking', iconPath: '/assets/icons/email-checking.svg',
}, {
  iconName: 'time', iconPath: '/assets/icons/time.svg',
}, {
  iconName: 'research', iconPath: '/assets/icons/research-opportunity.svg',
}, {
  iconName: 'mission', iconPath: '/assets/icons/mission.svg',
}, {
  iconName: 'calendar', iconPath: '/assets/icons/calendar.svg',
}, {
  iconName: 'calendar-ok-grey', iconPath: '/assets/icons/calendar-ok-grey.svg',
}, {
  iconName: 'calendar-crossed-grey', iconPath: '/assets/icons/calendar-crossed-grey.svg',
}, {
  iconName: 'calendar-crossed-light', iconPath: '/assets/icons/calendar-crossed-light.svg',
}, {
  iconName: 'weeks-remaining', iconPath: '/assets/icons/weeks-remaining.svg',
}, {
  iconName: 'question-list', iconPath: '/assets/icons/question-list.svg',
}, {
  iconName: 'sub-study', iconPath: '/assets/icons/sub-study.svg',
}];
