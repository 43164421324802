import { isObject, isRealValue, validateSchema } from '@tr-common';

import { UserShortInfoInToken, userShortInfoInTokenSchema } from './user-info-in-token';

export const getPayloadFromToken = (token: string): UserShortInfoInToken => {
  const [, payload] = isRealValue(token) ? token.split('.') : [null, null];
  const res: UserShortInfoInToken = isRealValue(payload) ? JSON.parse(atob(payload)) : null;

  validateSchema(userShortInfoInTokenSchema)(res);

  return res;
};

export const userHasRole = (token: UserShortInfoInToken | string, role: string): boolean => {
  const parsedToken = isObject(token) ? token : getPayloadFromToken(token);

  return parsedToken.privileges.some(p => p.id.includes(role));
};
