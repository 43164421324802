export const pdfPrint = (payload: Blob, document: Document): void => {
  const foundIframe = document.querySelector('iframe#print');
  const url = URL.createObjectURL(payload);
  let iframe;

  if (foundIframe === null) {
    const newIframe = document.createElement('iframe');

    newIframe.setAttribute('id', 'print');
    iframe = document.body.appendChild(newIframe);
  } else {
    iframe = foundIframe;
  }

  const loadEventListener = () => {
    const target = iframe.contentWindow || iframe.contentDocument.defaultView;

    setTimeout(() => {
      target.focus();
      target.print();
      iframe.removeEventListener('load', loadEventListener);
      URL.revokeObjectURL(url);
    }, 1000);
  };

  iframe.style.display = 'none';
  iframe.addEventListener('load', loadEventListener);
  iframe.src = url;
};
