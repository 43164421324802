import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GotoBackLinkModule, TrLibModule } from 'tr-lib';

import { GetInTouchComponent } from '@app/landing/components/info/get-in-touch/get-in-touch.component';
import { MainBannerComponent } from '@app/landing/components/info/main-banner/main-banner.component';
import { RegistryDescriptionComponent } from '@app/landing/components/info/registry-description/registry-description.component';
import { RegistrySecurityInfoComponent } from '@app/landing/components/info/registry-security-info/registry-security-info.component';
import { IsDeactivatedComponent } from '@app/landing/components/is-deactivated/is-deactivated.component';
import { SharedModule } from '@app/shared';

import { FaqComponent } from './components/faq/faq.component';
import { LandingInfoComponent } from './components/info/landing-info.component';
import { LandingComponent } from './components/landing.component';
import { PassedToChildComponent } from './components/passed-to-child/passed-to-child.component';
import { LandingRoutingModule } from './landing-routing.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    GotoBackLinkModule,
    TrLibModule,
    LandingRoutingModule
  ],
  providers: [],
  declarations: [
    LandingComponent,
    LandingInfoComponent,
    FaqComponent,
    IsDeactivatedComponent,
    PassedToChildComponent,
    MainBannerComponent,
    RegistryDescriptionComponent,
    RegistrySecurityInfoComponent,
    GetInTouchComponent,
  ]
})
export class LandingModule {
}
