import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { exchangeContactInfoT1D, PositionType, profileType, SelectItemsType } from 'tr-lib';

@Component({
  selector: 'app-select-profile-type',
  template: `
    <ng-container [formGroup]="form">
      <div class="tr-profile-type-radio-buttons">
        <label class="for-who" for="profile-type">
          <span>Who are you completing these questions for?</span>
          <lib-info-tooltip [position]="tooltipPosition">
            <div>
              If you are under 18 years of age your parent or legal guardian can register, so you can then
              both complete the questions on your behalf. This may involve both you <i>and</i> your parent or legal
              guardian giving permission to participate in research, also called giving consent.
            </div>
            <div class="contact-us">
              Questions? Contact us at <a [href]="'mailto:' + contactEmail">{{ contactEmail }}</a>
            </div>
          </lib-info-tooltip>
        </label>
        <mat-radio-group id="profile-type" formControlName="profileType">
          <mat-radio-button color="primary" *ngFor="let t of profileTypes" [value]="t.value">
            <span [innerHTML]="t.label"></span>
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="!profileType.pristine && profileType.errors?.invalidProfileType">Profile type is required</mat-error>
      </div>

      <div *ngIf="isForChild" class="tr-user-name-fields">
        <mat-form-field class="full-width name-form-field" appearance="outline">
          <input matInput placeholder="Child's First Name" formControlName="child_first_name">
          <mat-error>First name is required</mat-error>
        </mat-form-field>

        <mat-form-field class="full-width surname-form-field" appearance="outline">
          <input matInput placeholder="Child's Last Name" formControlName="child_last_name">
          <mat-error>Last name is required</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./select-profile-type.component.scss']
})
export class SelectProfileTypeComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() tooltipPosition: PositionType = 'top-middle';
  readonly contactEmail = exchangeContactInfoT1D.email;
  profileTypes: SelectItemsType = [
    {label: 'Myself – I am over 18 years of age.', value: 'adult'},
    {label: 'My Child – who is under the age of 18.', value: 'child'}
  ];
  subscriptions = new Subscription();
  isForChild: boolean;

  get profileType(): AbstractControl {
    return this.form.get('profileType');
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.profileType.valueChanges.subscribe((val: string) => {
        this.isForChild = val === profileType.child;
        if (this.isForChild) {
          this.form.addControl('child_first_name', new UntypedFormControl('', Validators.required));
          this.form.addControl('child_last_name', new UntypedFormControl('', Validators.required));
        } else {
          this.form.removeControl('child_first_name');
          this.form.removeControl('child_last_name');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
