import { ValueOf } from './helpers';

export const storageKeys = Object.freeze({
  dexcom_mode: 'dexcom_mode',
  jwt: 'jwt',
  application: 'application',
  application_part: 'application_part',
  child_email: 'child_email',
  response_id: 'response_id',
  token: 'token',
});
export type StorageKeys = ValueOf<typeof storageKeys>;

export interface SessionStorage<TKeys extends string = StorageKeys> {
  setItem(k: TKeys, v: string | boolean): void;
  getItem(k: TKeys): string | boolean;
  removeItem(k: TKeys): void;
  clear(): void;
}

export type AppComponentStorageType = typeof storageKeys.application
  | typeof storageKeys.application_part
  | typeof storageKeys.jwt
  | typeof storageKeys.token
  | typeof storageKeys.response_id;
