import { Action, createReducer, on } from '@ngrx/store';

import { globalLoaderEnd, globalLoaderStart } from '@store/actions';

export interface LoaderState {
  globalLoader: boolean
}

const initialLoaderState: LoaderState = {
  globalLoader: false
}

const reducer = createReducer(
  initialLoaderState,
  on(globalLoaderStart, (state) => ({...state, globalLoader: true})),
  on(globalLoaderEnd, (state) => ({...state, globalLoader: false})),
);

export const loaderReducer = (state = initialLoaderState, actions: Action): LoaderState => reducer(state, actions);
