import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import { TagComponent } from '../tag/tag.component';
import { MaterialImportsModule } from '../material-imports.module';
import {
  A1cDynamicWidgetComponent,
  AutocompleteWidgetComponent,
  CheckboxWidgetComponent,
  CheckboxWithTextWidgetComponent,
  DateRangeWidgetComponent,
  DateShortWidgetComponent,
  DateWidgetComponent,
  DropdownWidgetComponent,
  LabelWidgetComponent,
  LikertScaleWidgetComponent,
  MatrixScaleWidgetComponent,
  MultiselectWidgetComponent,
  NumericalWidgetComponent,
  RadioWidgetComponent,
  RadioWithTextWidgetComponent,
  TextWidgetComponent,
  ZipWidgetComponent,
} from './components';
import {
  BaseCheckboxAndRadioComponent,
  BaseCheckboxRadioWithTextComponent,
  BaseDateComponent,
  CloseAutocompleteOnScrollDirective,
  FormControlErrorComponent,
} from './helpers';

@NgModule({
  declarations: [
    A1cDynamicWidgetComponent,
    AutocompleteWidgetComponent,
    BaseCheckboxAndRadioComponent,
    BaseCheckboxRadioWithTextComponent,
    BaseDateComponent,
    CloseAutocompleteOnScrollDirective,
    CheckboxWidgetComponent,
    CheckboxWithTextWidgetComponent,
    DateRangeWidgetComponent,
    DateShortWidgetComponent,
    DateWidgetComponent,
    DropdownWidgetComponent,
    FormControlErrorComponent,
    LabelWidgetComponent,
    LikertScaleWidgetComponent,
    MatrixScaleWidgetComponent,
    MultiselectWidgetComponent,
    NumericalWidgetComponent,
    RadioWidgetComponent,
    RadioWithTextWidgetComponent,
    TextWidgetComponent,
    ZipWidgetComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    TextMaskModule,
    TagComponent,
  ],
  exports: [
    A1cDynamicWidgetComponent,
    AutocompleteWidgetComponent,
    CloseAutocompleteOnScrollDirective,
    CheckboxWidgetComponent,
    CheckboxWithTextWidgetComponent,
    DateRangeWidgetComponent,
    DateShortWidgetComponent,
    DateWidgetComponent,
    DropdownWidgetComponent,
    FormControlErrorComponent,
    LabelWidgetComponent,
    LikertScaleWidgetComponent,
    MatrixScaleWidgetComponent,
    MultiselectWidgetComponent,
    NumericalWidgetComponent,
    RadioWidgetComponent,
    RadioWithTextWidgetComponent,
    TextWidgetComponent,
    ZipWidgetComponent,
  ]
})
export class WidgetsModule {}
