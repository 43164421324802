import { createAction, props } from '@ngrx/store';

import { Consent, ConsentBody } from 'tr-lib';

export enum reconsentActions {
  GET_SMS_CONSENT = '[CONSENT] Get SMS consent',
  GET_SMS_CONSENT_SUCCESS = '[CONSENT] Got SMS consent success',
  GET_SMS_CONSENT_FAIL = '[CONSENT] Got SMS consent fail',
  SHOW_SMS_CONSENT_DIALOG = '[CONSENT] Show SMS consent dialog to sign',
  SIGN_SMS_CONSENT = '[CONSENT] User signs the SMS consent',
  SIGNED_SMS_CONSENT_SUCCESS = '[CONSENT] User signed SMS consent success',
  SIGNED_SMS_CONSENT_FAIL = '[CONSENT] User signed SMS consent fail',
  SKIP_SMS_CONSENT = '[CONSENT] User requests to skip the SMS consent',
  SKIP_SMS_CONSENT_SUCCESS = '[CONSENT] User requested to skip the SMS consent success',
  SKIP_SMS_CONSENT_FAIL = '[CONSENT] User requested to skip the SMS consent fail',
  CLEAR_SMS_CONSENT_STATE = '[CONSENT] Clear SMS consent state',
  GET_CORE_STUDY_ID_SUCCESS = '[CONSENT] Get Core Study Id success',
  GET_USER_STUDY_ID_SUCCESS = '[CONSENT] Get User Study Id success',
  GET_CORE_STUDY_ID_FAIL = '[CONSENT] Get Core Study Id fail',
  LOAD_RESIGN_CONSENTS = '[CONSENT] Load Consents for assent/reconsent',
  LOAD_DASHBOARD_STUDIES_SUCCESS = '[CONSENT] Loaded and filtered dashboard studies success',
  LOAD_RESIGN_CONSENTS_SUCCESS = '[CONSENT] Load Consents for assent/reconsent success',
  LOAD_RESIGN_CONSENTS_FAIL = '[CONSENT] Load Consents for assent/reconsent fail',
  LOAD_RESIGN_CONSENT_BODY_SUCCESS = '[CONSENT] Load Consent Body success',
  LOAD_RESIGN_CONSENT_BODY_FAIL = '[CONSENT] Load Consent Body fail',
  RESIGN_CONSENT = '[CONSENT] Sign Consent',
  RESIGN_CONSENT_SUCCESS = '[CONSENT] Sign Consent success',
  RESIGN_CONSENT_FAIL = '[CONSENT] Sign Consent fail',
  COMPLETE_RECONSENT = '[CONSENT] Complete Reconsent',
  COMPLETE_RECONSENT_SUCCESS = '[CONSENT] Complete Reconsent Success',
  COMPLETE_RECONSENT_FAIL = '[CONSENT] Complete Reconsent Fail',
  REFRESH_ASSENT_RECONSENT_STATE = '[CONSENT] Refresh user state from /me',
}

export const getSMSConsent = createAction(
  reconsentActions.GET_SMS_CONSENT
);

export const getSMSConsentSuccess = createAction(
  reconsentActions.GET_SMS_CONSENT_SUCCESS,
  props<{payload: boolean}>()
);

export const getSMSConsentFail = createAction(
  reconsentActions.GET_SMS_CONSENT_FAIL,
  props<{error: unknown}>()
);

export const showSmsConsentDialog = createAction(
  reconsentActions.SHOW_SMS_CONSENT_DIALOG
);

export const signSmsConsent = createAction(
  reconsentActions.SIGN_SMS_CONSENT,
  props<{phone: string}>()
);

export const signedSmsConsentSuccess = createAction(
  reconsentActions.SIGNED_SMS_CONSENT_SUCCESS
);

export const signedSmsConsentFail = createAction(
  reconsentActions.SIGNED_SMS_CONSENT_FAIL,
  props<{error: unknown}>()
);

export const skipSmsConsent = createAction(
  reconsentActions.SKIP_SMS_CONSENT
);

export const skipSmsConsentSuccess = createAction(
  reconsentActions.SKIP_SMS_CONSENT_SUCCESS
);

export const skipSmsConsentFail = createAction(
  reconsentActions.SKIP_SMS_CONSENT_FAIL,
  props<{error: unknown}>()
);

export const clearSmsConsentState = createAction(
  reconsentActions.CLEAR_SMS_CONSENT_STATE
);

export const getCoreStudyIdSuccess = createAction(
  reconsentActions.GET_CORE_STUDY_ID_SUCCESS,
  props<{payload: string}>()
);

export const getUserStudyIdSuccess = createAction(
  reconsentActions.GET_USER_STUDY_ID_SUCCESS,
  props<{payload: string | number}>()
);

export const getCoreStudyIdFail = createAction(
  reconsentActions.GET_CORE_STUDY_ID_FAIL,
  props<{error: unknown}>()
);

export const loadResignConsents = createAction(
  reconsentActions.LOAD_RESIGN_CONSENTS
);

export const loadDashboardStudiesSuccess = createAction(
  reconsentActions.LOAD_DASHBOARD_STUDIES_SUCCESS,
  props<{participantId: string, userStudyId: number}>()
);

export const loadResignConsentsSuccess = createAction(
  reconsentActions.LOAD_RESIGN_CONSENTS_SUCCESS,
  props<{payload: Consent[]}>()
);

export const loadResignConsentsFail = createAction(
  reconsentActions.LOAD_RESIGN_CONSENTS_FAIL,
  props<{error: unknown}>()
);

export const loadResignConsentBodySuccess = createAction(
  reconsentActions.LOAD_RESIGN_CONSENT_BODY_SUCCESS,
  props<{payload: ConsentBody}>()
);

export const loadResignConsentBodyFail = createAction(
  reconsentActions.LOAD_RESIGN_CONSENT_BODY_FAIL,
  props<{error: unknown}>()
);

export const resignConsent = createAction(
  reconsentActions.RESIGN_CONSENT,
  props<{payload: string}>()
);

export const resignConsentSuccess = createAction(
  reconsentActions.RESIGN_CONSENT_SUCCESS,
  props<{payload: any}>()
);

export const resignConsentFail = createAction(
  reconsentActions.RESIGN_CONSENT_FAIL,
  props<{error: unknown}>()
);

export const completeReconsent = createAction(
  reconsentActions.COMPLETE_RECONSENT,
);

export const completeReconsentSuccess = createAction(
  reconsentActions.COMPLETE_RECONSENT_SUCCESS
);

export const completeReconsentFail = createAction(
  reconsentActions.COMPLETE_RECONSENT_FAIL,
  props<{error: unknown}>()
);

export const refreshAssentReconsentState = createAction(
  reconsentActions.REFRESH_ASSENT_RECONSENT_STATE,
);
