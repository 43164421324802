import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';

import {
  ChangePassFormData,
  ChangePassPayload,
  changePasswordForm2Payload,
  ChildFormType,
  CountryInfo,
  extractChangesFromUserProfileForm,
  FormGroupProtoType,
  getChangePasswordForm,
  getChildForm,
  getHouseholdForm,
  getStateValidator,
  isEmptyValue,
  isNonEmptyArray,
  isNonEmptyString,
  isRealValue,
  ParticipantProfile,
  ParticipantProfileType,
  ServerValidationErrors,
  transform2UserNotification,
  transformUserNotification2Form,
  UserHouseholdFormType,
  UserHouseholdType,
  UserNotificationFormType,
} from 'tr-lib';

import { getUserNotificationForm, ParticipantNotificationType } from '@app/model';
import { CountryCodesService } from '@services/country-codes.service';

import { getUserProfileForm, ProfileFullState, ProfileState, resetErrors, setErrors, UserStateProfile } from '../model';
import { ProfileFacade } from '../store/profile.facade';

@Injectable({providedIn: 'root'})
export class UserProfileFormService {
  infoForm: FormGroup<FormGroupProtoType<UserStateProfile>>;
  childForm: FormGroup<ChildFormType>;
  householdForm: FormGroup<UserHouseholdFormType>;
  userProfPassword: FormGroup<FormGroupProtoType<ChangePassFormData>>;
  pristineInfo: ParticipantProfileType & ParticipantNotificationType;
  isAfterUpdate: boolean;
  countryCodes$: Observable<CountryInfo[]> = this.countryCodesService.getReorderedCountryCodes().pipe(
    filter(isNonEmptyArray), share(),
    tap(() => {
      this.infoForm.controls.phone.enable({emitEvent: false});
      this.childForm.controls.child_phone.enable({emitEvent: false});
    }),
  );
  subscriptions: Subscription;
  notificationSettingsForm = getUserNotificationForm();
  infoLoading$ = new BehaviorSubject<boolean>(false);
  childLoading$ = new BehaviorSubject<boolean>(false);
  householdLoading$ = new BehaviorSubject<boolean>(false);
  settingsLoading$ = new BehaviorSubject<boolean>(false);
  passwordLoading$ = new BehaviorSubject<boolean>(false);
  state$: Observable<ProfileState> = this.profileFacade.profileFullState$.pipe(
    // tap(data => console.log({handler: 'state$', ...data})),
    tap((data: ProfileFullState) => this.fillAllForms(data)),
    tap(({info, householdInfo, childInfo, states, settings, isChild}: ProfileFullState) => {
      this.pristineInfo = {...info, ...householdInfo, ...settings, ...(isChild ? childInfo : {})};
      this.householdForm.controls.country_state.setValidators(getStateValidator(states));
    }),
    tap(({auth_methods}: ProfileFullState) => {
      const controlAction = auth_methods.includes('native') ? 'enable' : 'disable';

      this.userProfPassword.controls.old_password[controlAction]();
    }),
    map(({info, states, settings, isChild}) => ({
      states, settings: {...settings, smsDisabled: !isNonEmptyString(info.phone)}, isChild
    }))
  );

  constructor(
    private profileFacade: ProfileFacade,
    public countryCodesService: CountryCodesService,
  ) {
    this.initAllForms();
  }

  initAllForms(): void {
    if (isRealValue(this.subscriptions)) {
      this.subscriptions.unsubscribe();
    }
    this.infoForm = getUserProfileForm();
    this.childForm = getChildForm();
    this.householdForm = getHouseholdForm();
    this.userProfPassword = getChangePasswordForm();
    this.pristineInfo = null;
    this.isAfterUpdate = false;
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.profileFacade.profileError$.subscribe(errors => this.updateUserFormErrors(errors))
    );
    this.subscriptions.add(
      this.profileFacade.householdError$.subscribe(errors => this.updateHouseholdFormErrors(errors))
    );
    this.subscriptions.add(
      this.profileFacade.childInfoError$.subscribe(errors => this.updateChildFormErrors(errors))
    );
    this.subscriptions.add(
      this.profileFacade.changePassError$.subscribe(errors => this.updatePasswordFormErrors(errors))
    );
    this.subscriptions.add(this.profileFacade.changePasswordSuccess$.subscribe(() => {
      this.passwordLoading$.next(false); resetErrors(this.userProfPassword); this.userProfPassword.reset();
    }));
    this.subscriptions.add(
      this.infoForm.controls.phone.valueChanges.subscribe(value => {
        // console.log({handler: 'infoForm.controls.phone', value});
        if (isEmptyValue(value)) {
          this.profileFacade.cleanPhoneErrorState();
        }
      })
    );
  }

  getDirtyFields = (): Partial<ParticipantProfile & ParticipantNotificationType> => {
    const settings = transform2UserNotification(this.notificationSettingsForm.value as UserNotificationFormType);
    const {icon, isChild, ...info} = this.infoForm.value;
    const payload = {...info, ...this.householdForm.value, ...settings, ...(isChild ? this.childForm.value : {})};

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Object.keys(payload).reduce((a, k) => this.pristineInfo[k] !== payload[k] ? {...a, [k]: payload[k]} : a, {});
  }

  fillAllForms({info, householdInfo, childInfo, settings, isChild}: ProfileFullState): void {
    this.userProfPassword.patchValue({username: info.email}, {emitEvent: false});
    if (isRealValue(info) && (!this.isAfterUpdate || this.infoLoading$.getValue())) {
      this.infoForm.patchValue(info, {emitEvent: false});
    }
    if (this.isAfterUpdate) {
      this.infoForm.controls['icon'].setValue('ok', {emitEvent: false});
    }
    this.infoForm.controls.isChild.setValue(isChild, {emitEvent: false});
    if (isRealValue(childInfo) && (!this.isAfterUpdate || this.childLoading$.getValue())) {
      this.childForm.patchValue(childInfo, {emitEvent: false});
    }
    if (isRealValue(householdInfo) && (!this.isAfterUpdate || this.householdLoading$.getValue())) {
      this.householdForm.patchValue(householdInfo, {emitEvent: false});
      if (isEmptyValue(householdInfo.country_state)) {
        this.householdForm.controls.country_state.setErrors(null, {emitEvent: false});
      }
    }
    if (isRealValue(settings) && (!this.isAfterUpdate || this.settingsLoading$.getValue())) {
      const transformedSettings = transformUserNotification2Form(settings);

      this.notificationSettingsForm.patchValue(transformedSettings, {onlySelf: false, emitEvent: false});
    }
    this.infoLoading$.next(false);
    this.childLoading$.next(false);
    this.householdLoading$.next(false);
    this.settingsLoading$.next(false);
  }

  updateUserFormErrors(error: any): void {
    this.infoLoading$.next(false);
    if (isRealValue(error)) {
      setErrors(error, this.infoForm);
    } else {
      resetErrors(this.infoForm);
    }
  }

  updateHouseholdFormErrors(error: any): void {
    this.householdLoading$.next(false);
    if (isRealValue(error)) {
      setErrors(error, this.householdForm);
    } else {
      resetErrors(this.householdForm);
    }
  }

  updateChildFormErrors(error: any): void {
    this.childLoading$.next(false);
    if (isRealValue(error)) {
      setErrors(error, this.childForm);
    } else {
      resetErrors(this.childForm);
    }
  }

  updatePasswordFormErrors(error: ServerValidationErrors<ChangePassPayload>): void {
    this.passwordLoading$.next(false);
    if (isRealValue(error)) {
      setErrors(error, this.userProfPassword);
    } else {
      resetErrors(this.userProfPassword);
    }
  }

  submitInformation = (checkEmail?: boolean): void => {
    const payload = extractChangesFromUserProfileForm(this.infoForm.value) as Partial<ParticipantProfile>;

    this.infoLoading$.next(true);
    this.isAfterUpdate = true;
    if (checkEmail) {
      this.infoForm.controls['icon'].setValue('checking', {emitEvent: false});
    }
    this.profileFacade.updateProfileViaEmailCheck(checkEmail, payload);
  };

  submitChild = (): void => {
    if (this.childForm.valid) {
      this.childLoading$.next(true);
      this.isAfterUpdate = true;
      this.profileFacade.updateChildInfo(this.childForm.value);
    }
  };

  submitHousehold = (): void => {
    if (this.householdForm.valid) {
      this.householdLoading$.next(true);
      this.isAfterUpdate = true;
      this.profileFacade.updateHouseholdInfo(extractChangesFromUserProfileForm(this.householdForm.value) as UserHouseholdType);
    }
  };

  submitPassword = (): void => {
    if (this.userProfPassword.valid) {
      this.passwordLoading$.next(true);

      this.profileFacade.updatePassword(changePasswordForm2Payload(this.userProfPassword));
    } else {
      this.userProfPassword.markAllAsTouched();
    }
  };
}
