import * as t from 'io-ts';
import { FormControl } from '@angular/forms';
import { nullable } from 'tr-lib';

export const smsConsentSchema = t.type({
  required: t.boolean
}, 'smsConsentSchema');
export type SmsConsentType = t.TypeOf<typeof smsConsentSchema>;

export interface SmsPhoneData {
  phone: string
}

export interface SmsConsentForm {
  phone: FormControl<string>;
  optIn: FormControl<boolean>;
}

export const smsConsentUpdateSchema = t.union([
  t.type({
    status: t.literal('accepted'),
    phone: nullable(t.string)
  }),
  t.type({
    status: t.literal('skipped')
  })
], 'smsConsentUpdateSchema');
export type SmsConsentUpdateType = t.TypeOf<typeof smsConsentUpdateSchema>;
