import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TypeConsent } from '@tr-common';

import { Consent } from '../../models';

@Component({
  selector: 'lib-consent-files',
  templateUrl: './consent-files.component.html',
  styleUrls: ['./consent-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentFilesComponent {
  @Input() data: Consent[] = [];
  @Input() isReviewBtn = true;
  @Input() isDownloadBtn = true;
  @Input() isPrintBtn = true;
  @Input() isTimeStamp = true;
  @Output() reviewConsent = new EventEmitter<string>();
  @Output() downloadConsent = new EventEmitter<Consent>();
  @Output() printConsent = new EventEmitter<string>();

  onReviewConsent = (consentId: string): void => this.reviewConsent.emit(consentId);
  onDownloadConsent = (consent: Consent): void => this.downloadConsent.emit(consent);
  onPrintConsent = (consentId: string): void => this.printConsent.emit(consentId);

  isNonUniversalType(consent: Consent): boolean {
    return consent.consent_type !== TypeConsent.universal;
  }
}
