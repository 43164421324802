import { FormControlErrorHandler } from './form-control-error-handler';
import { isRealValue } from './helpers';

export const htmlPasswordRequires = 'Password must be a minimum of eight (8) characters in length, contain at least one uppercase' +
  ' character, one lowercase character, one digit and one special character: ~!@#$%^&*()+=_\\/-{}[]|:;"\'?<>,.';
export const passwordIsRequired = 'Password is required';
export const passwordDoesNotMatch = 'Password does not match';
export const newPasswordSameAsOld = 'New password cannot be the same as the current password';

export const getPasswordErrorHandlers = (): FormControlErrorHandler[] => [{
  mustBeShowed: control => isRealValue(control.errors?.required),
  message: passwordIsRequired
}, {
  mustBeShowed: control => isRealValue(control.errors?.match),
  message: passwordDoesNotMatch
}];
