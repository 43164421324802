import { left, right } from 'fp-ts/Either';
import * as t from 'io-ts';
import { isObject, pick } from 'lodash';

import { studyConditionSchema } from '../study-condition-schema';

export const dropRedundantProps = <TProps extends t.Props, C extends t.TypeC<TProps> | t.PartialC<TProps>>(c: C): t.Type<t.TypeOf<C>> => {
  const hasRedundantProps = (toCheck: unknown, props: any) => Object.keys(toCheck).some(p => !(p in props));
  const is = (u: t.TypeOf<C>): u is t.Type<t.TypeOf<C>> => isObject(u) && !hasRedundantProps(u, c) && c.is(u);

  return new t.Type(
    `DropRedundantProps<${c.name}>`,
    is,
    (inp: any, context: t.Context) => is(inp) ? right(inp) : left([{
      value: inp,
      message: `incorrect value`,
      context: [],
    }]),
    (a): t.TypeOf<C> => c.encode(pick(a, Object.keys(c.props)) as any),
  );
};
export const strictStudyConditionSchema = t.intersection([
  t.strict(studyConditionSchema.types[0].props),
  dropRedundantProps(t.partial(studyConditionSchema.types[1].props)),
])
export type StrictStudyCondition = t.TypeOf<typeof strictStudyConditionSchema>;
