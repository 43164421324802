import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-is-deactivated',
  template: `
    <div>
      <img src="assets/people_with_phone.svg" alt="is deactivated">
    </div>
    <div>
      <h4>Your account has been deleted and you have been withdrawn from the T1D Exchange Registry</h4>
      <p>We appreciate your time and interest in the Registry. We have sent a confirmation email to the address provided;
        you will not receive additional correspondence from T1D Exchange regarding the Registry.</p>
      <h4>Follow us:</h4>
      <lib-social-icons></lib-social-icons>
      <p>
        If your account deletion is an error, please contact the Registry study team at
        <a href="mailto:Registry@T1DExchange.org">Registry@T1DExchange.org</a>
      </p>
      <button mat-flat-button color="primary" class="is-shadowed" (click)="goLandingPage()">Go to landing page</button>
    </div>
  `,
  styleUrls: ['./is-deactivated.component.scss']
})
export class IsDeactivatedComponent {
  constructor(
    private router: Router
  ) {}

  goLandingPage(): void {
    this.router.navigate(['/']).then();
  }
}
