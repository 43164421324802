import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  countryCodeValidator,
  emailPattern,
  getParticipantFormData,
  isNonEmptyString,
  matchFields,
  matchOtherValidator,
  ParticipantFormType,
  passwordMinimalLength,
  passwordRegExp,
  profileType,
  ProfileType,
  RegisterParticipant,
  validateProfileType,
} from 'tr-lib';
import { JaebUserInfoWithId } from '@app/model';

// Any types stand due to FormBuilder takes parameter with [k:string]: any
export interface RegisterForm extends ParticipantFormType {
  emailConfirmation?: FormControl<string>;
  password: FormControl<string>;
  passwordConfirmation: FormControl<string>;
  profileType: FormControl<ProfileType>,
  child_first_name?: FormControl<string>,
  child_last_name?: FormControl<string>,
  recaptchaToken?: FormControl<string>
}

const localStorageJaebKeyForRegisterSuccess = 'registeredAsJaeb';

export const storeJaebKeyForRegisterSuccess = (isJaeb: boolean): void => localStorage.setItem(
  localStorageJaebKeyForRegisterSuccess, isJaeb.toString()
);

export const isJaebKeyForRegisterSuccess = (): boolean => {
  const localStorageJaebKey = localStorage.getItem(localStorageJaebKeyForRegisterSuccess);

  return isNonEmptyString(localStorageJaebKey) && localStorageJaebKey === 'true';
};

export const createRegisterForm = (withRecaptcha = false) => {
  const baseForm: RegisterForm = {
    ...getParticipantFormData(),
    email: new FormControl<string>(null, Validators.compose([Validators.required, Validators.pattern(emailPattern)])),
    password: new FormControl<string>(null, Validators.compose([Validators.required, Validators.pattern(passwordRegExp)])),
    passwordConfirmation: new FormControl<string>(null, matchOtherValidator('password', matchFields)),
    phone: new FormControl<string>(null, {
      validators: Validators.compose([Validators.minLength(passwordMinimalLength), countryCodeValidator()]),
      updateOn: 'blur',
    }),
    profileType: new FormControl<ProfileType>(null, validateProfileType())
  };

  return new FormGroup<RegisterForm>(
    withRecaptcha ? {...baseForm, recaptchaToken: new FormControl<string>('')} : baseForm,
    {updateOn: 'change'}
  );
}

const getEmailValue = (dataForm: FormGroup<RegisterForm>, isJAEBFlow = false): string => {
  const emailControl = dataForm.controls.email;
  let emailValue: string;

  if (isJAEBFlow) emailControl.enable({emitEvent: false});
  emailValue = emailControl.value.toLowerCase();
  if (isJAEBFlow) emailControl.disable({emitEvent: false});

  return emailValue;
};

export const makePostData = (
  dataForm: FormGroup<RegisterForm>, withRecaptcha = false, isJAEBFlow = false
): RegisterParticipant => {
  const postData: RegisterParticipant = {
    first_name: dataForm.controls.first_name.value,
    last_name: dataForm.controls.last_name.value,
    password: dataForm.controls.password.value,
    email: getEmailValue(dataForm, isJAEBFlow),
    phone: isNonEmptyString(dataForm.controls.phone.value) ? dataForm.controls.phone.value : undefined,
    profile_type: dataForm.controls.profileType.value,
    recaptcha_token: withRecaptcha ? dataForm.controls.recaptchaToken.value : ''
  };

  return postData.profile_type === profileType.child ? Object.assign(postData, {
    child_first_name: dataForm.controls.child_first_name.value,
    child_last_name: dataForm.controls.child_last_name.value
  }) as RegisterParticipant : postData;
};
