import * as t from 'io-ts';

import { nullable } from '@tr-common';

import { studyConditionSchema } from '../study-condition-schema';
import { StudyBaseRule } from './study-base-rules';
import { StudyCondition } from './study-condition';

export const studyConsentRuleSchema = t.intersection([
  t.type({
    consent_type: t.string,
    consent_id: nullable(t.number),
    study_id: nullable(t.string),
  }, 'studyConsentRuleSchema'),
  t.partial({
    description: nullable(t.string),
    id: t.string,
    conditions: t.array(studyConditionSchema),
  })
]);
export type StudyConsentRuleType = t.TypeOf<typeof studyConsentRuleSchema>;

export class StudyConsentRule extends StudyBaseRule<StudyConsentRule> implements StudyConsentRuleType {
  consent_type = 'universal';
  description = '';
  consent_id: number;
  study_id: string;
  conditions: StudyCondition[];

  constructor(data?: Partial<StudyConsentRule>) {
    super();
    super.setData(data);
  }

  clone(): StudyConsentRule {
    return new StudyConsentRule(this);
  }
}
