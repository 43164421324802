import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isEmptyValue, isNonEmptyString, isRealValue, ScriptService } from 'tr-lib';

import { ImperiumBack, ImperiumRegistrationForm } from '@app/model';
import { environment } from '@env/environment';

export const imperiumLoginFormID = '03e6b2ee-a70e-430b-86a3-f27ac85452ca';
export const imperiumRegistrationFormID = '5eeca55f-40b0-4412-9f03-52be7ad547dc';

export const checkAndLoadImperiumScript = (regGuardClientID: string, scripts: ScriptService): Observable<boolean> => {
  const regGuard = environment.regGuard;
  let outcome = of(false);

  if (isNonEmptyString(regGuardClientID) && isRealValue(regGuard) && !scripts.isLoaded(regGuard.scriptName)) {
    const {scriptID, scriptName, scriptSrc} = environment.regGuard;

    scripts.register([{id: scriptID, name: scriptName, src: scriptSrc}]);
    outcome = scripts.loadScript(scriptName).pipe(map(() => true));
  }

  return outcome;
};

export const getImperiumRegistrationForm = (clientID: string, registrationFormID: string): ImperiumRegistrationForm => ({
  callback: registerUserDataAfterImperium, clientID, language: 'EN', registrationFormID, runAsync: false
});

export const registerUserDataAfterImperium = (params: unknown = null): void => {
  const regGuardID = isRealValue(params) ? params['ID'] : null;
  const imperium: ImperiumBack = window['imperiumBack'];

  imperium.facade.registerUserData.call(imperium.facade, imperium.userData, imperium.jaebInfo, params);
  setTimeout(() => delete window['imperiumBack'], 0);
  if (isEmptyValue(regGuardID)) {
    throw new Error('Got an empty imperium ID for ' + imperium.userData.email);
  }
};

export const loginUserDataAfterImperium = (params: unknown = null): void => {
  const regGuardID = isRealValue(params) ? params['ID'] : null;
  const imperium: ImperiumBack = window['imperiumBack'];

  imperium.facade.login.call(imperium.facade, imperium.userData, regGuardID);
  setTimeout(() => delete window['imperiumBack'], 0);
};
