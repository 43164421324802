import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';

import { isRealValue } from '@tr-common';

import { CountryInfo } from '../../models';

@Component({
  selector: 'lib-child-profile-form',
  templateUrl: './child-profile-form.component.html',
})
export class ChildProfileFormComponent implements OnChanges {
  @Input() floatLabel: FloatLabelType;
  @Input() parentForm: FormGroup;
  @Input() countryCodes: CountryInfo[];

  constructor(private cdRef: ChangeDetectorRef) {}

  get childEmail(): AbstractControl {
    return this.parentForm.get('child_email');
  }

  get childPhone(): AbstractControl {
    return this.parentForm.get('child_phone');
  }

  ngOnChanges({countryCodes}: SimpleChanges) {
    if (isRealValue(countryCodes)) {
      this.cdRef.detectChanges();
    }
  }
}
