import { Injectable } from '@angular/core';
import { forkJoin, Observable, Observer } from 'rxjs';

import { isNonEmptyString, isRealValue, LoadedScript, Script } from '@tr-common';

declare const document: any;

@Injectable({providedIn: 'root'})
export class ScriptService {
  private scripts: {[name: string]: {loaded: boolean, src: string, id?: string}} = {};

  register(scripts: Script[]): void {
    scripts.forEach((script: Script) => this.scripts[script.name] = {loaded: false, src: script.src, id: script.id ?? ''});
  }

  isLoaded(name: string): boolean {
    const registeredScript = this.scripts[name];

    return isRealValue(registeredScript) && registeredScript.loaded;
  }

  unloadScript(name: string, selector = ''): void {
    if (this.isLoaded(name)) {
      const registeredScript = this.scripts[name];
      const baseSelector = `script#${registeredScript.id}`;
      const additionalSelector = isNonEmptyString(selector) ? ',' + selector : '';

      document.querySelectorAll(baseSelector + additionalSelector).forEach(el => el.remove());
    }
  }

  loadAll(scripts: string[]): Observable<LoadedScript[]> {
    return forkJoin(scripts.map(name => this.loadScript(name)));
  }

  loadScript(name: string, delay = 0): Observable<LoadedScript> {
    return new Observable<LoadedScript>((observer: Observer<LoadedScript>) => {
      const status = this.scripts[name];
      const selector = `script[src="${status.src}"]`;
      const nodeList = document.querySelectorAll(selector);

      if (nodeList.length > 0) { // resolve if already loaded
        observer.next({script: name, loaded: true, status: 'Already Loaded'});
      } else {
        const script = document.createElement('script'); // load script

        script.type = 'text/javascript';
        script.src = status.src;
        script.id = status.id;
        script.onload = () => {
          this.scripts[name].loaded = true;
          observer.next({script: name, loaded: true, status: 'Loaded'});
        };
        script.onerror = (error: any) => observer.error({err: {script: name, loaded: false, status: 'Loaded'}});
        setTimeout(() => document.getElementsByTagName('body')[0].appendChild(script), delay);
      }
    });
  }
}
