<div *ngIf="state$ | async as state" class="wrapper" [class.pending]="state.pending">
  <ng-container *ngIf="state.hasNotSubmitted; else status">
    <div class="flex shortcuts">
      <lib-goto-back-link *ngIf="urls.proxyFactory.getDashboardUrl$() | async as url"
        [buttonTitle]="state.procedure['backButtonTitle']" (redirectTo)="router.navigateByUrl(url)">
      </lib-goto-back-link>
      <lib-contact-info [flatBtn]="true"></lib-contact-info>
    </div>

    <lib-procedure-research-status *ngIf="state.procedure['isResearch']" [showBanner]="state.procedure['showBanner']">
    </lib-procedure-research-status>

    <lib-confirmation-page *ngIf="isReadyToShowConfirmationPage(state.procedure)" [setting]="state.procedure['setting']"
      [pending]="state.submitPending" (review)="study.flowPreviewAnswers()">
      <button class="survey-button is-shadowed" mat-flat-button color="primary" [spinner]="state.submitPending"
        [disabled]="state.submitPending" (click)="study.submitProcedure(state.procedure, state.hasConclusionPopup)">
        {{ state.procedure['setting']?.content?.button_text }}
      </button>
    </lib-confirmation-page>
  </ng-container>

  <ng-template #status>
    <div class="flex shortcuts">
      <lib-goto-back-link [buttonTitle]="'Back to Dashboard'" *ngIf="urls.proxyFactory.getDashboardUrl$() | async as url"
        (redirectTo)="router.navigateByUrl(url)"></lib-goto-back-link>
      <lib-contact-info [flatBtn]="true"></lib-contact-info>
    </div>

    <ng-container *ngIf="state.procedure['isIntro'] && (introPage$ | async) as intro">
      <lib-survey-intro [page]="intro">
        <button mat-flat-button next-button class="survey-button" color="primary"
          (click)="study.continueAfterIntro()">{{ intro.button }}
        </button>
        <!-- TODO We should consider to find a way to use the button above one time -->
        <button mat-flat-button next-button-2 class="survey-button" color="primary"
          (click)="study.continueAfterIntro()">{{ intro.button }}
        </button>
      </lib-survey-intro>
    </ng-container>

    <lib-procedure-consent-status *ngIf="state.procedure['isConsent']" [setting]="state.procedure['setting']">
    </lib-procedure-consent-status>

    <ng-container *ngIf="state.procedure['hasQuestions']">
      <lib-conclusion-page [setting]="state.procedure?.setting" [pending]="state.submitPending" [isEligible]="state?.isEligible"
        (clickNext)="study.nextProcedure()" (clickLast)="returnToDashboard()" 
        (clickDonate)="handleDonate()" [isDonated]="isDonated" [donateSuccess]="donateSuccess">
        <lib-submitted-procedure-actions [procedure]="state.procedure"></lib-submitted-procedure-actions>
      </lib-conclusion-page>
    </ng-container>
  </ng-template>

  <mat-spinner mode="indeterminate"></mat-spinner>
</div>
