import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { DialogEligibleTypeConsent, TypeConsent } from '@tr-common';

import { SurveyPipesModule } from '../../../pipes/survey-pipes.module';
import { surveyDialogDefaultOptions } from '../../models';

@Component({
  selector: 'lib-is-eligible-dialog',
  template: `
    <img src="/assets/modal-success.svg" alt="success">
    <h3>Success!</h3>

    <ng-container [ngSwitch]="data.consentType">
      <p *ngSwitchCase="typeConsent.adult" [innerHTML]="'screening.success_adult' | textReplace: data.source"></p>
      <p *ngSwitchCase="typeConsent.parental" [innerHTML]="'screening.success_parental' | textReplace: data.source"></p>
      <p *ngSwitchCase="typeConsent.child" [innerHTML]="'screening.success_child' | textReplace: data.source"></p>
    </ng-container>

    <button class="dialog-button is-shadowed" mat-flat-button color="primary" [mat-dialog-close]="true">Continue</button>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, SurveyPipesModule],
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: surveyDialogDefaultOptions}],
  styleUrls: ['./is-eligible-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IsEligibleDialogComponent {
  typeConsent = TypeConsent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogEligibleTypeConsent) {}
}
