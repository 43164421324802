import { Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { answerWidget, isEmptyValue, isNonEmptyString, isRealValue, OptionAnswerType } from '@tr-common';

import { SourceChange, SourceChangeType } from '../../../models';
import { StudyOptionType } from '../../../models/study-option';
import { LayoutService } from '../../../services';
import { DropdownWidgetComponent } from '../dropdown-widget/dropdown-widget.component';
import { getValidity, getValueForAutocomplete } from '../../models';

@Component({
  selector: 'lib-autocomplete-widget',
  template: `
    <mat-form-field appearance="outline" class="full-width">
      <input #inputTrigger type="text" autocomplete="off" placeholder="Start typing" matInput [formControl]="autoCompletedValue"
        [matAutocomplete]="auto" (click)="openAutocompletePanel()"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styleUrls: ['./autocomplete-widget.component.scss']
})
export class AutocompleteWidgetComponent extends DropdownWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('inputTrigger', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;
  autoCompletedValue = new FormControl<string>('');
  filterMask$= new BehaviorSubject('');
  filteredOptions: Observable<string[]> = merge(this.autoCompletedValue.valueChanges, this.filterMask$).pipe(
    map(v => this.optionsFilter(v))
  );
  subscriptions = new Subscription();
  scrollEvent = (event: any): void => {
    if(this.trigger.panelOpen){
      this.trigger.updatePosition();
    };
  }
  constructor(private layoutService: LayoutService) {
    super();
    this.subscriptions.add(
      this.autoCompletedValue.valueChanges.subscribe(changedValue => {
        this.valid = getValidity(changedValue, this.optionsToDisplay, this.hasAutocompleteText);
        this.emitState(SourceChange.user);
      })
    );
  }

  get hasAutocompleteText(): boolean { return this.options.some(option => option.widget === answerWidget.autocompleteText); }
  get optionsToDisplay(): StudyOptionType[] { return this.options.filter(option => option.widget === answerWidget.autocompleteItem); }

  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);

  ngOnChanges({value}: SimpleChanges): void {
    if (isRealValue(value)) {
      const initValue = getValueForAutocomplete(this.value, this.options);
      const isValueEmpty = isEmptyValue(initValue);
      const isPreviousTheSame = !value.firstChange && isRealValue(value.currentValue)
        && value.currentValue?.question_option === value.previousValue?.question_option;

      if (!isPreviousTheSame) {
        this.filterMask$.next(isValueEmpty ? '' : initValue);
        this.autoCompletedValue.setValue(isValueEmpty ? null : initValue, {emitEvent: false});
      }
    }
  }

  ngOnInit(): void {
    // this.subscriptions.add(
    //   this.layoutService.scrollRise$.pipe(
    //     filter(() => this.trigger instanceof MatAutocompleteTrigger && this.trigger.panelOpen),
    //   ).subscribe(() => this.trigger.closePanel())
    // );
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  openAutocompletePanel() {
    console.log("this.trigger", this.trigger);
    this.trigger.openPanel();
  }
  emitState(source: SourceChangeType): void {
    const selectedValue = this.autoCompletedValue.value;
    const predefinedOption = this.optionsToDisplay.find(option => option.title === selectedValue);
    const customOption = this.options.find(({widget}) => widget === answerWidget.autocompleteText);
    const isValueFromPredefinedOption = isRealValue(predefinedOption);
    let value: OptionAnswerType = null;

    // the following line is to prevent emitting events if the options set doesn't have the autocompleteText widget
    if (!isValueFromPredefinedOption && !this.hasAutocompleteText) {
      this.valid = 'INVALID';
    }
    // console.log({handler: 'emitState', valid: this.valid, selectedValue, predefinedOption, customOption});
    if (isNonEmptyString(selectedValue)) {
      value = {
        question_option: isValueFromPredefinedOption ? predefinedOption.id : customOption?.id,
        value: isValueFromPredefinedOption || selectedValue
      }
    }
    this.modify.emit({value, valid: this.valid, source});
  }

  private optionsFilter(value: string): string[] {
    const filterValue = isRealValue(value) ? value.toLowerCase() : '';

    return this.optionsToDisplay.filter(option => option.title.toLowerCase().includes(filterValue)).map(option => option.title);
  }
}
