import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import {
  answerWidget,
  isEmptyValue,
  isNonEmptyArray,
  isNullOrFalse,
  isRealValue,
  LabelIdPairType,
  OptionAnswerType,
  tabletViewportWidth,
  ValidatorType,
} from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption, StudyOptionType } from '../../../models/study-option';
import { LayoutService } from '../../../services';
import { calculateDropdownWidth, convertArrayValueToLabelPairs } from '../../models';

@Component({
  selector: 'lib-multiselect-widget',
  templateUrl: './multiselect-widget.component.html',
  styleUrls: ['./multiselect-widget.component.scss']
})
export class MultiselectWidgetComponent implements WidgetPrototype<OptionAnswerType[]>, OnChanges, OnInit, OnDestroy {
  @Input() option: StudyOption; // it's always undefined
  @Input() options: StudyOptionType[] = [];
  @Input() value: OptionAnswerType[];
  @Output() modify = new EventEmitter<WidgetModify<OptionAnswerType[]>>();
  @ViewChild('inputTrigger', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;
  @ViewChild('submitButton', {static: false}) submit: MatButton;
  isDesktop$ = this.layoutService.isDesktop$;
  multiSelectValue = new FormControl<string>('', {updateOn: 'change'});
  tagList: LabelIdPairType[] = [];
  multiselectWidth = '100%';
  filteredOptions$: Observable<LabelIdPairType[]> = this.multiSelectValue.valueChanges.pipe(
    startWith(''),
    map(v => this.optionsFilter(v))
  );
  valid: ValidatorType = 'default';
  subscriptions = new Subscription();
  scrollEvent = (event: any): void => {
    if(this.trigger.panelOpen){
      this.trigger.updatePosition();
    };
  }
  constructor(public layoutService: LayoutService) {}

  get optionsToDisplay(): StudyOptionType[] {
    return this.options.filter(({widget}) => widget === answerWidget.multiselectItem);
  }

  get isSubmitNecessary(): boolean {
    return this.options.some(({widget}) => widget === answerWidget.multiselectText);
  }

  get isSubmitDisabled(): boolean {
    const multiSelectValue = this.multiSelectValue.value;

    return isEmptyValue(multiSelectValue) || !this.isSubmitNecessary || this.tagList.some(({label}) => label === multiSelectValue);
  }

  trackById: TrackByFunction<LabelIdPairType> = (index, {id}) => id;

  ngOnChanges({options, value}: SimpleChanges): void {
    if (isRealValue(options)) {
      const dropdownWidth = calculateDropdownWidth(this.options.filter(({widget}) => widget === answerWidget.multiselectItem));

      this.multiselectWidth = dropdownWidth > tabletViewportWidth ? '100%' : `${dropdownWidth}px`;
    }
    if (isRealValue(value)) {
      this.tagList = this.value instanceof Array ? convertArrayValueToLabelPairs(this.value, this.options) : [];
      if (isNullOrFalse(this.value)) {
        this.multiSelectValue.setValue('');
      }
    }
  }

  ngOnInit(): void {
    // this.subscriptions.add(
    //   this.layoutService.scrollRise$.pipe(
    //     filter(() => this.trigger instanceof MatAutocompleteTrigger && this.trigger.panelOpen)
    //   ).subscribe(() => this.trigger.closePanel())
    // );
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  optionSelected(): void {
    const newTagValue = this.multiSelectValue.value;
    const predefinedOption = this.optionsToDisplay.find(({title}) => title === newTagValue);

    if (isRealValue(predefinedOption)) {
      this.valid = 'VALID';
      this.tagList = [...this.tagList, {id: predefinedOption.id, label: predefinedOption.title}];
    }
    this.multiSelectValue.setValue(null);
    this.emitState(SourceChange.user);
  }

  clickSubmit(): void {
    if (this.submit instanceof MatButton) {
      this.submit._elementRef.nativeElement.focus();
      this.submit._elementRef.nativeElement.click();
    }
  }

  addCustomValue(): void {
    const customOption = this.options.find(({widget}) => widget === answerWidget.multiselectText);

    if (isRealValue(customOption)) {
      this.valid = 'VALID';
      this.tagList = [...this.tagList, {id: customOption.id, label: this.multiSelectValue.value}];
    } else {
      this.valid = 'INVALID';
    }
    this.multiSelectValue.setValue('');
    this.emitState(SourceChange.user);
  }

  removeTag(idx: number): void {
    this.tagList.splice(idx, 1);
    this.tagList = [...this.tagList];
    this.valid = this.tagList.length > 0 ? 'VALID' : 'INVALID';
    this.multiSelectValue.updateValueAndValidity();
    this.emitState(SourceChange.user);
  }

  emitState(source: SourceChangeType): void {
    const values: OptionAnswerType[] = this.tagList.map(({id, label}) => {
      const option = this.optionsToDisplay.find(o => o.id === id);

      return {question_option: id, value: isRealValue(option) || label};
    });

    this.modify.emit({source, valid: this.valid, value: values});
  }

  private optionsFilter(value: string): LabelIdPairType[] {
    const filterValue = isRealValue(value) ? value.toLowerCase() : '';
    const options = this.optionsToDisplay;

    return isNonEmptyArray(options)
      ? options.filter(({title}) => title.toLowerCase().includes(filterValue))
        .map(({id, title}) => ({id, label: title}))
        .filter(({label}) => !this.tagList.some(tag => tag.label === label))
      : [];
  }
}
