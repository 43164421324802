import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash';

import { ParticipantSourceType } from '@tr-common';

export const studyRouterProperty = 'router';
export const selectRouterStore = createFeatureSelector<RouterReducerState<any>>(studyRouterProperty);
export const selectUrl = createSelector(
  selectRouterStore,
  (router): string => get(router, ['state', 'url'], null) as string
);
export const selectUrlConsentID = createSelector(
  selectRouterStore,
  (router) => get(router, ['state', 'params', 'consentID'], null) as string
);
export const selectUrlProcedure = createSelector(
  selectRouterStore,
  (router) => get(router, ['state', 'queryParams', 'procedure'], null) as string
);
export const selectUrlQuestion = createSelector(
  selectRouterStore,
  (router) => get(router, ['state', 'queryParams', 'questionId'], null) as string
);
export const selectPreviewMode = createSelector(
  selectRouterStore,
  (router) => get(router, ['state', 'queryParams', 'preview'], null) === 'true'
);
export const selectAllQueryParameters = createSelector(
  selectUrlProcedure, selectUrlQuestion, selectPreviewMode,
  (procedure, questionId, preview) => ({procedure, questionId, preview})
);
export const selectSourceType = createSelector(
  selectRouterStore,
  (router) => get(router, ['state', 'queryParams', 'source'], 'registry') as ParticipantSourceType
);
