import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { of, Subject } from 'rxjs';
import { catchError, concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { isNonEmptyArray, isNonEmptyString, isNullOrUndefined } from '@tr-common';

import { compareWithAbsentAnswersAndUpdate, extractStoredAnswerFromDraft, getAbsentQuestions } from '../../models';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import { SurveyService } from '../../services/survey.service';
import {
  connectionServerError,
  findUnansweredParentQuestionId,
  finishPending,
  nextQuestion,
  nextUnansweredQuestion,
  nextUnansweredQuestionAfterSubmit,
  previousQuestion,
  saveAnswers,
  saveAnswersFail,
  saveAnswersSuccess,
  selectActiveProcedureID,
  selectActiveProcedureParentFilteredQuestionsByRules,
  selectActiveProcedureQuestions,
  selectActiveQuestion,
  selectActiveQuestionID,
  selectParamsIDs,
  updateAbsentAnswers,
} from '../index';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class QuestionEffects {
  actions$ = new Subject<Action>();

  saveAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(saveAnswers),
    withLatestFrom(this.store.select(selectParamsIDs), this.store.select(selectActiveQuestionID)),
    switchMap(([
      {navigate, preview, draftAnswers}, {userID, userStudyID}, questionId
    ]) => this.surveyService.saveAnswers(userID, userStudyID, extractStoredAnswerFromDraft(draftAnswers), questionId).pipe(
      concatMap(storedAnswers => [
        updateAbsentAnswers({savedAnswers: draftAnswers}),
        saveAnswersSuccess({payload: groupBy(storedAnswers, 'question')}),
        navigate({preview}),
      ]),
      catchError(({error, status}: HttpErrorResponse) => {
        const errorMessage = isNonEmptyArray(error) ? error.map(e => e['error_message'] ?? e) : [error.toString()];

        return of(saveAnswersFail({error: status === 400 ? errorMessage : [connectionServerError]}));
      })
    ))
  ));

  updateAbsentAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(updateAbsentAnswers),
    withLatestFrom(this.store.select(selectActiveProcedureQuestions)),
    tap(([{savedAnswers}, questions]) => compareWithAbsentAnswersAndUpdate(savedAnswers, questions))
  ), {dispatch: false});

  nextUnansweredQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(nextUnansweredQuestion),
    withLatestFrom(this.store.select(selectActiveProcedureID), this.store.select(findUnansweredParentQuestionId)),
    tap(([{preview}, procedure, questionId]) => {
      this.surveyRouter.navigateToQuestion({procedure, questionId, preview}).then(() => this.store.dispatch(finishPending()));
    }),
  ), {dispatch: false});

  nextUnansweredQuestionAfterSubmit$ = createEffect(() => this.actions$.pipe(
    ofType(nextUnansweredQuestionAfterSubmit),
    withLatestFrom(this.store.select(selectActiveProcedureID), this.store.select(findUnansweredParentQuestionId)),
    tap(([, procedure, unansweredQuestionId]) => {
      let [questionId] = getAbsentQuestions();

      if (isNullOrUndefined(questionId)) {
        questionId = unansweredQuestionId;
      }
      if (isNonEmptyString(questionId)) {
        this.surveyRouter.navigateToQuestion({procedure, questionId}).then(() => this.store.dispatch(finishPending()));
      }
    }),
  ), {dispatch: false});

  nextQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(nextQuestion),
    withLatestFrom(
      this.store.select(selectActiveProcedureID),
      this.store.select(selectActiveQuestion),
      this.store.select(selectActiveProcedureParentFilteredQuestionsByRules),
    ),
    tap(([{preview}, procedure, activeQuestion, questions]) => {
      const activeIndex = questions.indexOf(activeQuestion);
      const questionId = (activeIndex + 1) < questions.length ? questions[activeIndex + 1].id : undefined;

      this.surveyRouter.navigateToQuestion({procedure, questionId, preview}).then(() => this.store.dispatch(finishPending()));
    }),
  ), {dispatch: false});

  previousQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(previousQuestion),
    withLatestFrom(
      this.store.select(selectActiveProcedureID),
      this.store.select(selectActiveQuestion),
      this.store.select(selectActiveProcedureParentFilteredQuestionsByRules),
    ),
    tap(([{preview}, procedure, activeQuestion, questions]) => {
      const activeIndex = questions.indexOf(activeQuestion);
      const questionId = (activeIndex - 1) >= 0 ? questions[activeIndex - 1].id : activeQuestion.id;

      this.surveyRouter.navigateToQuestion({procedure, questionId, preview}).then(() => {
        this.store.dispatch(finishPending());
      });
    }),
  ), {dispatch: false});

  constructor(
    private injector: Injector,
    public surveyRouter: SurveyRouter,
    public store: Store<any>,
    public surveyService: SurveyService,
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }
}
