import { FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as t from 'io-ts';

import { isRealValue } from './helpers';

export enum PersonalityEnum { myself = 'Myself', child = 'My child' }

export const profileTypeSchema = t.union([
  t.literal('child'),
  t.literal('adult')
], 'profileTypeSchema');
export type ProfileType = t.TypeOf<typeof profileTypeSchema>;

export const profileType: { [k in ProfileType]: k } = {child: 'child', adult: 'adult'} as const;

export const participantUpdateProfileTypeSchema = t.union([
  t.type({profile_type: t.literal(profileType.adult)}),
  t.type({
    profile_type: t.literal(profileType.child),
    child_first_name: t.string,
    child_last_name: t.string,
  })
], 'participantUpdateProfileTypeSchema');
export type ParticipantUpdateProfileType = t.TypeOf<typeof participantUpdateProfileTypeSchema>;
export type ParticipantUpdateProfileWithIdType = ParticipantUpdateProfileType & {id: string};

export const checkProfileTypeForm = (formGroup: UntypedFormGroup): void => {
  const {controls} = formGroup;

  for (const controlKey in controls) {
    if (controls.hasOwnProperty(controlKey)) {
      controls[controlKey].markAsDirty();
    }
  }
  formGroup.markAllAsTouched();
};

export const validateProfileType = (): ValidatorFn => (
  control: FormControl<ProfileType>
): ValidationErrors  => [profileType.adult, profileType.child].includes(control.value) ? null : {invalidProfileType: true};

export const transformQuestion = (value: string, personality: string): string => {
  let result = value;

  if (isRealValue(value) && isRealValue(personality) && value.match(/\{.*\|.*\}/g)) {
    const options = value.substring(value.search(/{/) + 1, value.search(/}/)).split('|');
    const convertedValue = value.replace(/{(.*?)}/, options[personality === PersonalityEnum.myself ? 0 : 1]);

    result = transformQuestion(convertedValue, personality);
  }

  return result;
};
