/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import { InjectionToken } from '@angular/core';

export const REGISTRY_API_URL = new InjectionToken<string>('REGISTRY');
export const VERSION_URLS_TOKEN = new InjectionToken<string>('VERSIONS');
export const REGISTRY_APPLICATION = new InjectionToken<string>('REGISTRY-APPLICATION');
export const TRACKING = new InjectionToken<boolean>('TRACKING');
export const REGISTRY_PUBLIC_URL = new InjectionToken<string>('REGISTRY-PUBLIC');
export const REGISTRY_EMAIL_SENDER_URL = new InjectionToken<string>('REGISTRY-EMAIL-SENDER-URL');
export const REGISTRY_PARTICIPANT_URL = new InjectionToken<string>('REGISTRY-PARTICIPANT');
export const REGISTRY_PUBLIC_PARTICIPANT = new InjectionToken<string>('REGISTRY-PUBLIC-PARTICIPANT');
export const REGISTRY_USER_FILES = new InjectionToken<string>('REGISTRY-USER-FILES');
export const REGISTRY_STUDY_ANSWER = new InjectionToken<string>('REGISTRY-STUDY-ANSWER');
export const REGISTRY_ADMIN_URL = new InjectionToken<string>('REGISTRY-ADMIN');
export const REGISTRY_LOCAL_URL = new InjectionToken<string>('REGISTRY-LOCAL');
export const REGISTRY_DOCUMENT = new InjectionToken('REGISTRY_DOCUMENT');
export const STORAGE = new InjectionToken('STORAGE');
