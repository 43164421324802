import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, map, Observable, Subscription } from 'rxjs';

import { isRealValue } from '@tr-common';

import { LayoutService } from '../../../services';
import { ReviewAnswerStatus } from '../../models';
import { StudyFacade, StudyStatusFacade } from '../../store';

@Component({
  selector: 'lib-review-answers',
  templateUrl: './review-answers.component.html',
  styleUrls: ['./review-answers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ReviewAnswersComponent implements OnInit, OnDestroy {
  @ViewChild('iframeContainer', {read: ElementRef}) iframeContainer: ElementRef<HTMLElement>;
  reviewAnswersStatus$: Observable<ReviewAnswerStatus> = this.studyStatus.reviewAnswersStatus$;
  state$ = combineLatest([this.study.study$, this.route.queryParams]).pipe(
    map(([{id}, {procedure}]) => ({studyID: id, procedure}))
  );
  subscriptions = new Subscription();

  constructor(
    public location: Location,
    public studyStatus: StudyStatusFacade,
    public study: StudyFacade,
    public layout: LayoutService,
    private route: ActivatedRoute
  ) {
    this.subscriptions.add(
      this.state$.subscribe(({studyID, procedure}) => this.study.loadModuleSettings(studyID, procedure))
    );
  }

  ngOnInit() {
    this.studyStatus.getSubmittedAnswers();
  }

  ngOnDestroy(): void {
    this.studyStatus.clearSubmittedAnswers();
    this.subscriptions.unsubscribe();
  }

  onParsedHtml(): void {
    const nativeElement = this.iframeContainer?.nativeElement;

    if (isRealValue(nativeElement)) {
      const contentHeight = nativeElement['contentDocument'].body?.clientHeight;

      if (isRealValue(contentHeight)) {
        nativeElement.style.height = contentHeight + 75 + 'px';
      }
    }
  }

  printPDF(): void {
    this.studyStatus.getSubmittedAnswers(true, true);
  }

  downloadPDF(): void {
    this.studyStatus.getSubmittedAnswers(true);
  }
}
