import { Inject, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { groupBy } from 'lodash';

import {
  failSnackBottomConstantConfig,
  filterRealValues,
  isNonEmptyString,
  isNullOrUndefined,
  isRealValue,
  somethingWentWrong,
} from '@tr-common';

import { ConsentService } from '../../../consent/consent.service';
import { Consent, isConsentUnsigned } from '../../../consent/models';
import { REGISTRY_APPLICATION } from '../../../global/tokens';
import { getDefaultProcedureExceptIntro, participantFullName, surveyType } from '../../../models';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import { SurveyService } from '../../services/survey.service';
import { getMinimalStudyInfoFromUserStudy, hasAllParamIDs, isJAEB, OptRedirectParamsType } from '../../models';
import {
  additionalConsentOnPublic,
  checkConsentStudyType,
  defineUnsignedConsents,
  downloadConsent,
  finishPending,
  gotoProcedure,
  introContinue,
  jaebJumpsToQuestionnaire,
  loadAnswersSuccess,
  loadConsentBody,
  loadConsentBodyFail,
  loadConsentBodySuccess,
  loadConsents,
  loadConsentsFail,
  loadConsentsSuccess,
  loadIntroPage,
  loadIntroPageFail,
  loadIntroPageSuccess,
  loadJaebConsentBody,
  loadJaebConsentBodyFail,
  loadJaebConsentBodySuccess,
  loadParticipantDetailsSuccess,
  loadStudyFull,
  loadStudyPartially,
  loadStudyPartiallyFail,
  loadStudyPartiallyStage2,
  loadStudyPartiallySuccess,
  preselectConsent,
  printConsent,
  proceedWithConsentData,
  redirectToNoConsentPage,
  reviewConsent,
  selectActiveProcedure,
  selectAnswers,
  selectParamsIDs,
  selectParticipantDetails,
  selectProcedures,
  selectStudy,
  selectUserStudy,
  signConsent,
  signConsentFail,
  signConsentSuccess,
  signJaebConsent,
  updateActiveSurvey,
} from '../index';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class ConsentIntroEffects {
  actions$ = new Subject<Action>();

  loadStudyPartially$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudyPartially),
    switchMap(({studyID, userID}) => forkJoin([
      this.surveyService.getParticipantDetails(userID),
      this.surveyService.getUserStudies(userID),
      this.surveyService.loadProcedures(studyID),
    ]).pipe(
      concatMap(([participant, userStudies, studyProcedures]) => [
        loadParticipantDetailsSuccess({payload: participant}),
        loadStudyPartiallyStage2({
          participant, userStudy: userStudies.find(({study_id}) => study_id === studyID), studyProcedures
        })
      ]),
      catchError(({error}) => of(loadStudyPartiallyFail({error})))
    ))
  ));

  loadStudyPartiallyStage2$  = createEffect(() => this.actions$.pipe(
    ofType(loadStudyPartiallyStage2),
    switchMap(({participant, studyProcedures, userStudy}) => forkJoin([
      this.surveyService.studyUserProcedures(participant.id, userStudy.id.toString()),
      this.surveyService.loadQuestionsRules(userStudy.study_id),
      this.surveyService.loadAnswers(participant.id, userStudy.id.toString()),
      this.surveyService.loadQuestions(participant.profile_type, userStudy.study_id).pipe(map(a => groupBy(a, 'procedure'))),
    ]).pipe(
      concatMap(([userProcedures, rules, answers, questions, ]) => [
        updateActiveSurvey({userStudyID: userStudy.id.toString()}),
        loadStudyPartiallySuccess({
          study: getMinimalStudyInfoFromUserStudy(userStudy), userStudy, studyProcedures, participant, userProcedures, questions, rules
        }),
        loadAnswersSuccess({payload: groupBy(answers, 'question')}),
      ]),
      catchError(({error}) => of(loadStudyPartiallyFail({error})))
    ))
  ));

  loadIntroPage$ = createEffect(() => this.actions$.pipe(
    ofType(loadIntroPage),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{checkAndRedirectToStatus}, {studyID}]) => this.surveyService.loadIntroPages(studyID).pipe(
      map(([payload]) => loadIntroPageSuccess({payload, checkAndRedirectToStatus})),
      catchError(({error}) => of(loadIntroPageFail({error})))
    ))
  ));

  loadIntroPageSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadIntroPageSuccess),
    withLatestFrom(
      this.store.select(selectStudy),
      this.store.select(selectParticipantDetails),
      this.store.select(selectActiveProcedure),
      this.store.select(selectParamsIDs),
    ),
    filter((
      [{checkAndRedirectToStatus}, , , activeProcedure]
    ) => checkAndRedirectToStatus && isNonEmptyString(activeProcedure?.created_at)),
    tap(([, {id}, profile, procedure, {userStudyID}]) => this.navigateToProcedureStatus(
      {studyId: id, profileId: profile.id, userStudyId: userStudyID, queryParams: {procedure: procedure.id}}
    ))
  ), {dispatch: false});

  preselectConsent$ = createEffect(() => this.actions$.pipe(
    ofType(preselectConsent),
    withLatestFrom(
      this.store.select(selectStudy),
      this.store.select(selectUserStudy),
      this.store.select(selectParticipantDetails),
      this.store.select(selectActiveProcedure),
      this.store.select(selectParamsIDs),
    ),
    filter(([,, userStudy, , procedure]) => isRealValue(userStudy) && userStudy.is_eligible !== false && isRealValue(procedure?.created_at)),
    tap(([, {id},, profile, procedure, {userStudyID}]) => this.navigateToProcedureStatus({
      studyId: id, profileId: profile.id, userStudyId: userStudyID, queryParams: {procedure: procedure.id}
    })),
  ), {dispatch: false});

  loadConsents$ = createEffect(() => this.actions$.pipe(
    ofType(loadConsents),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([, {studyID, userID, userStudyID}]) => this.surveyService.loadConsents(userID, userStudyID).pipe(
      concatMap(payload => [
        defineUnsignedConsents({payload, studyID, userStudyID}),
        loadConsentsSuccess({payload})
      ]),
      catchError(error => of(loadConsentsFail({error}))),
    )),
  ));

  defineUnsignedConsents$ = createEffect(() => this.actions$.pipe(
    ofType(defineUnsignedConsents),
    withLatestFrom(
      this.store.select(selectParticipantDetails),
      this.store.select(selectActiveProcedure)
    ),
    tap(([{payload, studyID, userStudyID}, profile, procedure]) => {
      const isUserFromJAEB = isJAEB(profile);
      const unsignedConsent: Consent = payload.find(isConsentUnsigned);
      let id = payload.length > 0 ? payload[0].id : undefined;

      if (payload.length === 0 && isNullOrUndefined(procedure.created_at)) {
        this.store.dispatch(redirectToNoConsentPage());
      } else if (isNullOrUndefined(unsignedConsent)) {
        if (isUserFromJAEB && isRealValue(id)) {
          this.store.dispatch(loadJaebConsentBody({id}));
        }
        this.navigateToProcedureStatus({
          studyId: studyID, profileId: profile.id, userStudyId: userStudyID, queryParams: {procedure: procedure.id},
        });
      } else {
        id = unsignedConsent.id;
        this.store.dispatch(isUserFromJAEB ? loadJaebConsentBody({id}) : loadConsentBody({id}));
      }
    }),
  ), {dispatch: false});

  loadConsentBody$ = createEffect(() => this.actions$.pipe(
    ofType(loadConsentBody),
    switchMap(({id}) => this.surveyService.loadConsentBody(id).pipe(
      map(payload => loadConsentBodySuccess({consentId: id, payload})),
      catchError(error => of(loadConsentBodyFail(error))),
    )),
  ));

  loadJaebConsentBody$ = createEffect(() => this.actions$.pipe(
    ofType(loadJaebConsentBody),
    switchMap(({id}) => this.surveyService.loadConsentBody(id).pipe(
      map((payload) => loadJaebConsentBodySuccess({consentId: id, payload})),
      catchError(error => of(loadJaebConsentBodyFail(error))),
    )),
  ));

  signConsent$ = createEffect(() => this.actions$.pipe(
    ofType(signConsent),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{name, id}, {userID, userStudyID}]) => this.surveyService.signConsent(
      userID, userStudyID, id, {subject_name: name, user_consent_id: id},
    ).pipe(
      map(payload => signConsentSuccess({payload})),
      catchError(({error}) => of(signConsentFail({error}))),
    )),
  ));

  signConsentJaeb$ = createEffect(() => this.actions$.pipe(
    ofType(signJaebConsent),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectParticipantDetails)
    ),
    switchMap(([{id}, {userID, studyID, userStudyID}, p]) => this.surveyService.signConsent(
      userID, userStudyID, id, {subject_name: participantFullName(p) ?? '', user_consent_id: id},
    ).pipe(
      map(() => loadStudyFull({userID, studyID, userStudyID, sourceType: 'signJAEB'})),
      catchError(({error}) => of(signConsentFail({error}))),
    )),
  ));

  jaebJumpsToQuestionnaire$ = createEffect(() => this.actions$.pipe(
    ofType(jaebJumpsToQuestionnaire),
    withLatestFrom(
      this.store.select(selectStudy).pipe(filterRealValues()),
      this.store.select(selectParticipantDetails).pipe(filterRealValues()),
      this.store.select(selectParamsIDs),
    ),
    tap(([, study, profile, {userStudyID}]) => void this.surveyRouter.navigateForward(
      {studyID: study.id, userID: profile.id, userStudyID}
    ))
  ), {dispatch: false});

  introContinue$ = createEffect(() => this.actions$.pipe(
    ofType(introContinue),
    withLatestFrom(
      this.store.select(selectStudy),
      this.store.select(selectUserStudy),
      this.store.select(selectProcedures),
      this.store.select(selectAnswers),
    ),
    map(([, study, userStudy, procedures, answers]) => {
      const nextProcedure = getDefaultProcedureExceptIntro(userStudy, procedures, answers, study);

      // console.log({handler: 'introContinue', ...nextProcedure});
      return gotoProcedure({payload: nextProcedure.id, isCompleted: isRealValue(nextProcedure.created_at)});
    })
  ));

  signConsentFail$ = createEffect(() => this.actions$.pipe(
    ofType(signConsentFail, loadStudyPartiallyFail),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig()))
  ), {dispatch: false});

  signConsentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(signConsentSuccess),
    withLatestFrom(this.store.select(selectParamsIDs).pipe(filter(hasAllParamIDs))),
    switchMap(([{payload: {sms_consent_required}}, {userID, userStudyID}]) => this.surveyService.loadConsents(userID, userStudyID).pipe(
      concatMap(consents => {
        const unsignedConsent = consents?.find(isConsentUnsigned);

        return [
          loadConsentsSuccess({payload: consents}),
          isRealValue(unsignedConsent)
            ? loadConsentBody({id: unsignedConsent.id})
            : checkConsentStudyType({payload: sms_consent_required})
        ];
      }),
      catchError(error => of(loadConsentsFail({error}))),
    )),
  ));

  reviewConsent$ = createEffect(() => this.actions$.pipe(
    ofType(reviewConsent),
    withLatestFrom(this.store.select(selectParamsIDs)),
    filter(([, {studyID, userID}]) => isRealValue(userID)),
    tap(([{consentId}, {studyID, userID}]) => this.surveyRouter.navigateToConsentReview(studyID, userID, consentId))
  ), {dispatch: false});

  downloadConsent$ = createEffect(() => this.actions$.pipe(
    ofType(downloadConsent),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{consent: {signature}}, {userID}]) => this.consentService.downloadConsent(userID, signature.user_consent_id.toString(10)))
  ), {dispatch: false});

  printConsent$ = createEffect(() => this.actions$.pipe(
    ofType(printConsent),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{consentId}, {userID}]) => this.consentService.printConsent(userID, consentId))
  ), {dispatch: false});

  checkConsentStudyType$ = createEffect(() => this.actions$.pipe(
    ofType(checkConsentStudyType),
    withLatestFrom(this.store.select(selectStudy)),
    tap(([{payload}, {study_type}]) => {
      if (study_type === surveyType.core_study && this.application === 'Public') {
        this.store.dispatch(additionalConsentOnPublic({require: payload}))
      }
    }),
    map(() => proceedWithConsentData())
  ));

  proceedWithConsentData$ = createEffect(() => this.actions$.pipe(
    ofType(proceedWithConsentData),
    withLatestFrom(this.store.select(selectParamsIDs)),
    map(([, {userID, studyID, userStudyID}]) => loadStudyFull({userID, studyID, userStudyID, sourceType: 'consent'}))
  ));

  redirectToNoConsentPage$ = createEffect(() => this.actions$.pipe(
    ofType(redirectToNoConsentPage),
    withLatestFrom(this.store.select(selectParamsIDs)),
    tap(([, paramsIDs]) => this.surveyRouter.navigateToNoConsentPage(paramsIDs).then())
  ), {dispatch: false});

  constructor(
    @Inject(REGISTRY_APPLICATION) private application: string,
    public store: Store<any>,
    public surveyService: SurveyService,
    public surveyRouter: SurveyRouter,
    private injector: Injector,
    private snackBar: MatSnackBar,
    private consentService: ConsentService
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }

  navigateToProcedureStatus(opt: OptRedirectParamsType): void {
    this.surveyRouter.navigateToProcedureStatus(opt).then(() => this.store.dispatch(finishPending()));
  }
}
