<div *ngIf="{isDesktop: isDesktop$ | async} as l" class="flex align-center gap-10">
  <mat-form-field class="multiselect-input no-errors" appearance="outline"
    [ngStyle]="{'width': l.isDesktop ? multiselectWidth : '100%'}">
    <input #inputTrigger type="text" autocomplete="off" placeholder="Start typing" matInput [formControl]="multiSelectValue"
      [matAutocomplete]="auto" (keyup.enter)="isSubmitDisabled || clickSubmit()" />

    <mat-autocomplete #auto="matAutocomplete" class="multiselect-autofill-panel" (optionSelected)="optionSelected()">
      <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.label">{{ option.label }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button *ngIf="isSubmitNecessary" #submitButton type="button" mat-button (click)="addCustomValue()" [disabled]="isSubmitDisabled">Submit</button>
</div>

<div *ngIf="tagList.length" class="flex align-center gap-10 mbs-10 flex-wrap">
  <lib-tag *ngFor="let tag of tagList; index as idx; trackBy: trackById" flat [text]="tag.label"
    (cancel)="removeTag(idx)">
  </lib-tag>
</div>
