import { FormControl } from '@angular/forms';

export interface ValidateResults {
  [key: string]: boolean;
}

export type ValidateControlFuncType = (a: FormControl<unknown>) => ValidateResults;
export type ValidateControlsFuncType = (a: FormControl<unknown>, b: FormControl<unknown>) => ValidateResults;

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()+=_\\/\-{}\[\]|:;"'?<>,.])(?=.{8,})/;
