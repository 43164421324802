import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

import { SmsEmailToggleFormType, SmsEmailToggleType } from '@tr-common';

@Component({
  selector: 'lib-notification-toggle',
  template: `
    <div class="flex wrapper" [class.grouped]="group" [formGroup]="formGroup">
      <div class="flex column justify-between left-part">
        <div *ngIf="header"><ng-content></ng-content></div>
        <div class="{{ group ? 'bold' : 'grey' }}-label row-2" [innerHTML]="label"></div>
      </div>

      <div class="flex column justify-between right-part">
        <div class="grid align-center headers" [class.hide-for-non-mobiles]="!header">
          <span class="grey-label">Email</span>
          <span class="grey-label">SMS</span>
        </div>

        <div class="grid align-center toggles row-2">
          <mat-slide-toggle color="primary" formControlName="email_on"></mat-slide-toggle>
          <mat-slide-toggle color="primary" formControlName="sms_on"></mat-slide-toggle>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./notification-toggle.component.scss']
})
export class NotificationToggleComponent implements OnInit {
  @Input() label: string;
  @Input() header = true;
  @Input() group = false;
  @Output() modify = new EventEmitter<Partial<SmsEmailToggleType>>();
  formGroup!: FormGroup<SmsEmailToggleFormType>;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formGroup = this.controlContainer.control as FormGroup<SmsEmailToggleFormType>;
  }
}
