import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { ModuleContent } from '@tr-common';

import { SurveyPipesModule } from '../../../pipes/survey-pipes.module';
import { surveyDialogDefaultOptions } from '../../models';

@Component({
  selector: 'lib-survey-popup',
  template: `
    <img src="/assets/modal-welcome.svg" alt="welcome">
    <h3 mat-dialog-title>{{ data?.title }}</h3>
    <div mat-dialog-content class="inner-text" [innerHTML]="data?.body_text | safeHtml"></div>
    <div mat-dialog-actions>
      <button class="survey-button dialog-button is-shadowed" mat-flat-button color="primary" [mat-dialog-close]="true">
        {{ data?.button_text }}
      </button>
    </div>
  `,
  standalone: true,
  providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: surveyDialogDefaultOptions}],
  imports: [CommonModule, MatButtonModule, MatDialogModule, SurveyPipesModule],
  styleUrls: ['./survey-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveyPopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ModuleContent) {}
}
