import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { isEmptyValue, isNonEmptyString, isRealValue, ValidatorType } from '@tr-common';

import { SourceChange, SourceChangeType, WidgetModify, WidgetPrototype } from '../../../models';
import { StudyOption } from '../../../models/study-option';
import { LayoutService } from '../../../services';
import { GridTemplateStyleType, makeGridStyles } from '../../models';

@Component({
  selector: 'lib-likert-scale-widget',
  template: `
    <div class="flex wrapper" [class.no-title]="!isRowPrefix">
      <div *ngIf="!suppressTitles" class="column-titles-row" [ngStyle]="gridStyles">
        <span *ngIf="isRowPrefix" class="empty-column-title">&nbsp;</span>
        <span *ngFor="let columnTitle of option.metadata" class="likert-label" [innerHTML]="columnTitle"></span>
      </div>

      <mat-radio-group [formControl]="likertScaleControl" aria-label="Select an option" [ngStyle]="gridStyles" [class.filled]="filled">
        <mat-label *ngIf="isRowPrefix" [innerHTML]="option.title"></mat-label>
        <mat-radio-button *ngFor="let radio of option.metadata; index as idx" [value]="idx"></mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./likert-scale-widget.component.scss']
})
export class LikertScaleWidgetComponent implements WidgetPrototype<number | boolean>, OnChanges, OnDestroy {
  @Input() option: StudyOption;
  @Input() value: number | boolean;
  @Input() suppressTitles = false;
  @Input() filled = false;
  @Output() modify = new EventEmitter<WidgetModify<number | boolean>>();
  isRowPrefix = false;
  likertScaleControl: UntypedFormControl = new UntypedFormControl(null);
  valid: ValidatorType = 'default';
  subscriptions = new Subscription();
  gridStyles: GridTemplateStyleType | unknown = {};

  constructor(private layout: LayoutService) {
    this.subscriptions.add(
      this.likertScaleControl.valueChanges.subscribe(value => {
        this.value = value; this.valid = 'VALID';
        this.emitState(SourceChange.user);
      })
    );
    this.subscriptions.add(
      this.layout.isMobile$.subscribe(isMobile => {
        this.isRowPrefix = !isMobile && isNonEmptyString(this.option?.title);
        this.gridStyles = makeGridStyles(this.option?.metadata, this.isRowPrefix, isMobile);
      })
    );
  }

  @HostBinding('class.is-mobile') get isMobile(): boolean { return this.layout.isMobile; }
  @HostBinding('class.is-not-mobile') get isNotMobile(): boolean { return !this.layout.isMobile; }
  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);

  ngOnChanges({option, value, suppressTitles}: SimpleChanges) {
    if (isRealValue(option)) {
      this.isRowPrefix = !this.layout.isMobile && isNonEmptyString(this.option.title);
      this.gridStyles = makeGridStyles(this.option?.metadata, this.isRowPrefix, this.layout.isMobile);
    }
    if (isRealValue(value)) {
      this.likertScaleControl.setValue(isEmptyValue(this.value) ? null : +this.value, {emitEvent: false});
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  emitState(source: SourceChangeType): void {
    this.modify.emit({value: this.value, valid: this.valid, source});
  }
}
