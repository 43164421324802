import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MobileHeaderMenuComponent } from '@app/shared/components/mobile-header-menu/mobile-header-menu.component';

@Component({
  selector: 'app-header',
  template: `
    <header class="flex">
      <div class="flex align-center left-part">
        <a routerLink="/landing">
          <img class="l-desktop" src="assets/icons/logo.svg" alt="#">
          <img class="l-medium" src="assets/img/logo_mobile.png" alt="#">
        </a>
        <a class="link" routerLink="/faq">FAQ</a>
        <a class="link" (click)="scrollToContact()">Contact Us</a>
      </div>

      <div class="flex align-center right-part">
        <button mat-flat-button color="tertiary" class="button-sm l-desktop" routerLink="/auth/login">Sign In</button>
        <button mat-flat-button color="primary" class="button-sm l-desktop" routerLink="/auth/register">Sign Up</button>
        <button mat-flat-button color="tertiary" class="is-with-icon is-squared" disableRipple (click)="openMenuForMobile()">
          <mat-icon svgIcon="hamburger"></mat-icon>
        </button>
      </div>
      <ng-template #viewContainerRef></ng-template>
    </header>
  `,
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {
  constructor(private dialog: MatDialog) {}

  openMenuForMobile(): void {
    this.dialog.open(MobileHeaderMenuComponent, {panelClass: 'header-menu'});
  }

  scrollToContact(): void {
    const contact = document.querySelector('#contact-section');

    contact.scrollIntoView({behavior: 'smooth'});
  }
}
