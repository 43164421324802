import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { AppComponentStorageType, SessionStorage } from '@tr-common';

import { STORAGE } from '../../global/tokens';

@Component({selector: 'lib-logout', template: ''})
export class LogoutComponent {
  constructor(
    @Inject(STORAGE) private storage: SessionStorage<AppComponentStorageType>,
    private router: Router,
  ) {
    this.storage.removeItem(storage.getItem('application_part') === 'public' ? 'token' : 'jwt');
    void this.router.navigate(['/']);
  }
}
