import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LogoutComponent } from 'tr-lib';

import { PrivilegeAuthorizationGuard } from '@app/auth-guards/privilege-authorization.guard';
import { ContentWrapperWithBackButtonComponent } from '@app/components/content-wrapper/content-wrapper-with-back-button.component';
import { FaqComponent } from '@app/landing';
import { IsDeactivatedComponent } from '@app/landing/components/is-deactivated/is-deactivated.component';
import { PassedToChildComponent } from '@app/landing/components/passed-to-child/passed-to-child.component';
import {
  assentPrivilege,
  assentReconsentPrivileges,
  reconsentPendingPrivilege,
  reconsentPrivilege,
} from '@app/model/consent-or-assent-or-reconsent';
import { ReconsentWrapperComponent } from '@app/shared/components/reconsent-wrapper/reconsent-wrapper.component';
import { TrackEmailRedirect } from '@app/auth-guards/track-email-redirect';
import { UiKitComponent } from '@app/shared/components/ui-kit/ui-kit.component';
import { DexcomWrapperComponent } from '@dexcom/pages/dexcom-wrapper/dexcom-wrapper.component';

import { AlreadyLoggedInGuard } from './auth-guards/already-logged-in.guard';
import { AuthGuard } from './auth-guards/auth.guard';
import { RegisterSuccessComponent } from './components/register-success/register-success.component';
import { ContentWrapperComponent } from './shared/components/content-wrapper/content-wrapper.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const authRoutePart = {
  canLoad: [AlreadyLoggedInGuard], canActivate: [AlreadyLoggedInGuard],
  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
};

const dexcomRoutePart = {
  component: DexcomWrapperComponent, loadChildren: () => import('./dexcom/alchemer-dexcom.module').then(m => m.AlchemerDexcomModule)
};

const routes: Routes = [
  {...dexcomRoutePart, data: {mode: 'old'}, path: 'alchemer/dexcom'},
  {...dexcomRoutePart, data: {mode: 'new'}, path: 'linking/dexcom'},
  {path: 'logout', component: LogoutComponent},
  {
    path: '', canActivate: [TrackEmailRedirect], children: [
      {path: '', redirectTo: '/landing', pathMatch: 'full'},
      {
        path: 'survey', canLoad: [AuthGuard], canActivate: [AuthGuard, PrivilegeAuthorizationGuard],
        data: {forbiddenPrivileges: assentReconsentPrivileges},
        loadChildren: () => import('./study/study.module').then(m => m.StudyModule),
      },
      {path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)},
      {...authRoutePart, path: 'auth'},
      {...authRoutePart, path: 'oauth'},
      {path: 'register-success', component: RegisterSuccessComponent, canActivate: [AuthGuard]},
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard, PrivilegeAuthorizationGuard],
        canLoad: [AuthGuard, PrivilegeAuthorizationGuard],
        data: {forbiddenPrivileges: assentReconsentPrivileges},
      },
      {
        path: '', component: ContentWrapperComponent, canActivate: [AlreadyLoggedInGuard],
        children: [
          {path: 'faq', component: FaqComponent, data: {showNotAuthHeader: true}},
          {path: 'passed-to-child', component: PassedToChildComponent},
          {path: 'is-deactivated', component: IsDeactivatedComponent},
        ],
      },
      {
        path: 'user-profile', component: ContentWrapperWithBackButtonComponent,
        canLoad: [AuthGuard, PrivilegeAuthorizationGuard],
        canActivate: [AuthGuard, PrivilegeAuthorizationGuard],
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
        data: {forbiddenPrivileges: assentReconsentPrivileges},
      },
      {
        path: 'child-profile', component: ReconsentWrapperComponent,
        canLoad: [AuthGuard, PrivilegeAuthorizationGuard], canActivate: [AuthGuard, PrivilegeAuthorizationGuard],
        loadChildren: () => import('./child-profile/child-profile.module').then(m => m.ChildProfileModule),
        data: {privileges: [reconsentPendingPrivilege]},
      },
      {
        path: 're-consent', component: ReconsentWrapperComponent,
        canLoad: [AuthGuard, PrivilegeAuthorizationGuard], canActivate: [AuthGuard, PrivilegeAuthorizationGuard],
        loadChildren: () => import('./assent-re-consent/re-consent.module').then(m => m.ReConsentModule),
        data: {privileges: [reconsentPrivilege]},
      },
      {
        path: 'assent', component: ReconsentWrapperComponent,
        canLoad: [AuthGuard, PrivilegeAuthorizationGuard],
        canActivate: [AuthGuard, PrivilegeAuthorizationGuard],
        loadChildren: () => import('./assent-re-consent/assent.module').then(m => m.AssentModule),
        data: {privileges: [assentPrivilege]},
      },
      {path: 'ui-kit', component: UiKitComponent},
      {
        path: 'sms-off',
        loadChildren: () => import('./sms-unsubscribe/sms-unsubscribe.module').then(m => m.SmsUnsubscribeModule)
      },
      {path: '**', component: PageNotFoundComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
