import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { v4 as uuid } from 'uuid';

import { isNonEmptyString } from './helpers';

export const studyUUIDSchema = t.type({
  id: t.union([t.string, UUID])
}, 'studyUUIDSchema');

export class StudyUuid implements t.TypeOf<typeof studyUUIDSchema> {
  id: UUID | string;

  constructor(id?: string) {
    this.id = isNonEmptyString(id) ? id : uuid();
  }
}
