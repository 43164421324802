import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { failSnackBottomConstantConfig, STORAGE, storageKeys, TrackingService } from 'tr-lib';

import { DialogsService } from '@services/dialogs.service';

import { connectionError, internalServerError, registerJaebUserClicksAgain, unauthorized } from '../actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class InterceptorEffects {
  redirectToLogin$ = createEffect(() => this.actions$.pipe(
    ofType(unauthorized),
    tap(() => {
      this.storage.removeItem(storageKeys.token);
      this.router.navigate(['auth/login']).then();
      this.dialogService.showSessionExpiredDialog();
    }),
  ), {dispatch: false});

  registerJaebUserClicksAgain$ = createEffect(() => this.actions$.pipe(
    ofType(registerJaebUserClicksAgain),
    tap(action => this.ts.trackPage(`/auth/register?jtoken=${action.payload}&again`)),
  ), {dispatch: false});

  showInternalError$ = createEffect(() => this.actions$.pipe(
    ofType(internalServerError),
    tap(() => this.dialogService.showInternalServerErrorDialog()),
  ), {dispatch: false});

  connectionError$ = createEffect(() => this.actions$.pipe(
    ofType(connectionError),
    tap(() => this.snackBar.open(
      'Connection or server error. Check your connection or try to connect later.', 'X', failSnackBottomConstantConfig()
    ))
  ), {dispatch: false});

  constructor(
    @Inject(STORAGE) private storage,
    private router: Router,
    private actions$: Actions,
    private ts: TrackingService,
    private dialogService: DialogsService,
    private snackBar: MatSnackBar,
  ) {}
}
