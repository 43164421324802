import { HttpHeaders } from '@angular/common/http';

export const getFileNameFromHttpHeaders = (headers: HttpHeaders): any => {
  const [, filename] = headers.get('content-disposition').split('=');

  return filename;
};

export const pdfDownload = (filename: string, payload: Blob, document: Document): void => {
  const a = document.createElement('a');
  const click = (node) => {
    try {
      node.dispatchEvent(new MouseEvent('click'))
    } catch (e) {
      const evt = document.createEvent('MouseEvents');

      evt.initMouseEvent(
        'click', true, true, window, 0, 0, 0, 80,
        20, false, false, false, false, 0, null
      );
      node.dispatchEvent(evt);
    }
  };

  Object.assign(a, {download: filename, rel: 'noopener', href: URL.createObjectURL(payload)});
  setTimeout(() => { click(a); }, 0);
  setTimeout(() => { URL.revokeObjectURL(a.href); a.remove(); }, 30000);
};
