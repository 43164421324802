import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { REGISTRY_API_URL } from 'tr-lib';

@Injectable({providedIn: 'root'})
export class ErrorService {

  constructor(
    @Inject(REGISTRY_API_URL) private apiUrl: string,
    private http: HttpClient
  ) {}

  sendErrorLog(message = 'Unknown error', stackTrace: unknown): Observable<unknown> {
    return this.http.post(`${this.apiUrl}/frontend-log`, {message, stackTrace}).pipe(first());
  }
}
