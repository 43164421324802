import { pick } from 'lodash';
import { Moment, MomentInput } from 'moment';

import {
  Answers,
  commonMomentFormats,
  isNonEmptyArray,
  isNullOrUndefined,
  isRealValue,
  matchDateCondition,
  OptionAddressType,
  serverDateFormat,
  StoredAnswerType,
  StudyConditionOperatorType,
  StudyOptionValidatorOperatorsType,
  StudySource,
  StudySourceType,
} from '@tr-common';

import { getCorrectVal } from '../../study-survey/models';
import { compare2Moments } from '../date-helper';
import { ParticipantDetails } from '../participant-details';
import { StudyConditionType } from '../study-condition-schema';
import { StudyOptionCalcDate } from '../study-option-calc-date';
import { ComparisonDataType } from '../study-option-types';
import { compare2Values } from './compare-values';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export class StudyCondition implements StudyConditionType {
  l_value: StudySourceType = undefined;
  operator: StudyConditionOperatorType = undefined;
  r_value: string;
  stored_answer_path: OptionAddressType = undefined;
  comparison_data_type: ComparisonDataType = 'string';
  profile_field: string = undefined;
  private _dynamicValue: StudyOptionCalcDate;

  constructor(data?: Partial<StudyConditionType>, public now?: () => Moment) {
    // the check below is only for test purposes
    if (isNullOrUndefined(this.now)) {
      this.now = moment;
    }
    if (isRealValue(data)) {
      Object.assign(this, data);
      if (this.dynamicValue) {
        this.dynamicValue = this.dynamicValue; // making sure that _dynamicValue is instanceof StudyOptionCalcDate
      } else if (matchDateCondition(this.r_value)) {
        this._dynamicValue = new StudyOptionCalcDate(this.r_value, serverDateFormat, this.now);
      }
    }
  }

  get isNumberType(): boolean {
    return this.comparison_data_type === 'integer' || this.comparison_data_type === 'float';
  }

  get isDateType(): boolean {
    return this.comparison_data_type === 'date' || this.comparison_data_type === 'short-date';
  }

  get serialize(): StudyConditionType {
    const outcome = pick(this, ['l_value', 'operator', 'stored_answer_path', 'profile_field', 'comparison_data_type']);
    const r_value = this.r_value;

    return isRealValue(r_value) ? {...outcome, r_value} : outcome;
  }

  get dynamicValue(): StudyOptionCalcDate {
    return this._dynamicValue;
  }
  set dynamicValue(val: StudyOptionCalcDate) {
    this._dynamicValue = isNullOrUndefined(val) ? null : getCorrectVal(val, this.now);
  }

  ifValueMatchesCondition(lookupData: ParticipantDetails | Answers): boolean {
    let result = false;

    switch (this.l_value) {
      case StudySource.userAgeYears:
        result = this.ifValueMatchesBirthdayCondition(lookupData as ParticipantDetails);
        break;
      case StudySource.profile:
        result = this.ifValueMatchesConditionInProfile(lookupData as ParticipantDetails);
        break;
      case StudySource.storedAnswer:
        result = this.ifValueMatchesConditionInAnswers(lookupData as Answers);
        break;
      default:
        throw new Error('Incompatible value in \'l_value\' = ' + this.l_value);
    }

    return result;
  }

  private ifValueMatchesBirthdayCondition(lookupData: ParticipantDetails): boolean {
    const birthday = lookupData.birthday;
    let result = isRealValue(birthday);

    if (result) {
      const lValue = this.now().clone().diff(moment(birthday), 'years');

      result = compare2Values(lValue, this.operator as StudyOptionValidatorOperatorsType, +this.r_value);
    }

    return result;
  }

  private ifValueMatchesConditionInProfile(lookupData: ParticipantDetails): boolean {
    const foundData = lookupData[this.profile_field];
    let result = isRealValue(foundData);

    if (result) {
      const lValue: string | number = this.isNumberType ? +foundData : foundData.toString();
      const rValue: string | number = this.isNumberType ? +this.r_value : this.r_value.toString();

      result = compare2Values(lValue, this.operator as StudyOptionValidatorOperatorsType, rValue);
    }

    return result;
  }

  private ifValueMatchesConditionInAnswers(lookupData: Answers): boolean {
    const pointer = this.stored_answer_path;
    const answerToQuestion = isRealValue(lookupData) && isRealValue(pointer) ? lookupData[pointer.question_id] : [];
    const userAnswer: StoredAnswerType = isNonEmptyArray(answerToQuestion)
      ? answerToQuestion.find(a => a.question === pointer.question_id && a.question_option === pointer.option_id)
      : null;

    return this.operator === 'exists' ? isRealValue(userAnswer) : this.compare2Values(userAnswer);
  }

  private compare2Values(userAnswer: StoredAnswerType): boolean {
    let outcome = true;

    if (isRealValue(userAnswer)) {
      if (this.isNumberType) {
        outcome = compare2Values(+userAnswer?.value, this.operator as StudyOptionValidatorOperatorsType, +this.r_value);
      } else if (this.isDateType) {
        const lValue = moment(userAnswer?.value as string, commonMomentFormats);
        const rValue = isRealValue(this._dynamicValue) ? this.dynamicValue.value : this.r_value;

        outcome = compare2Moments(lValue, this.operator as StudyOptionValidatorOperatorsType, moment(rValue, commonMomentFormats));
      }
    }else{
      outcome = false;
    }

    return outcome;
  }
}
