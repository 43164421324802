import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as t from 'io-ts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  baseEventSchema,
  BaseEventType,
  Events,
  EventsLog,
  getEventForWelcomeDialog,
  isNonEmptyArray,
  isRealValue,
  ProcedureTemplateType,
  schemaValidationPipe,
} from '@tr-common';

import { REGISTRY_API_URL } from '../global/tokens';

@Injectable({providedIn: 'root'})
export class EventsLogService implements EventsLog {
  constructor(
    private http: HttpClient,
    @Inject(REGISTRY_API_URL) private registryUrl: string,
  ) {}

  isStudyAlreadyStarted(userId: string, study: string, user_study: number): Observable<boolean> {
    const data: BaseEventType = {event: Events.studyBegin, study, user_study};

    return this.getEventsLog(userId, data).pipe(
      schemaValidationPipe(t.array(baseEventSchema), baseEventSchema.name + '[]'),
      map(events => events.some(event => event.user_study === user_study))
    );
  }

  registerStartedStudy(userId: string, study: string, user_study: number): Observable<BaseEventType> {
    const body = {study, user_study, event: Events.studyBegin};

    return this.http.post<BaseEventType>(this.eventsUrl(userId), body).pipe(
      schemaValidationPipe(baseEventSchema, baseEventSchema.name)
    );
  }

  registerStartedProcedure(userId: string, study: string, user_study: number, procedure: ProcedureTemplateType): Observable<BaseEventType> {
    const event = getEventForWelcomeDialog(procedure);

    return this.http.post<BaseEventType>(this.eventsUrl(userId), {study, user_study, event});
  }

  sendJaebIntroEvent(userId: string): Observable<BaseEventType> {
    return this.http.post<BaseEventType>(this.eventsUrl(userId), {event: Events.jaebIntroDialog});
  }

  hasProcedureShowedWelcomeDialog(
    userId: string, study: string, userStudy: string, procedure: ProcedureTemplateType,
  ): Observable<boolean> {
    const event = getEventForWelcomeDialog(procedure);

    return this.getEventsLog(userId, {study, user_study: +userStudy, event}).pipe(map(isNonEmptyArray));
  }

  getAlreadyStartedStudies(userId: string): Observable<BaseEventType[]> {
    return this.getEventsLog(userId, {event: Events.studyBegin});
  }

  getJaebIntroEvent(userId: string): Observable<BaseEventType[]> {
    return this.getEventsLog(userId, {event: Events.jaebIntroDialog});
  }

  private eventsUrl(userId: string) {
    return `${this.registryUrl}/participant/${userId}/events`;
  }

  private getEventsLog(userId: string, {study, user_study, event}: BaseEventType): Observable<BaseEventType[]> {
    return this.http.get<BaseEventType[]>(this.eventsUrl(userId), {
      params: {
        ...(isRealValue(study) ? {study} : null),
        ...(isRealValue(user_study) ? {user_study} : null),
        ...(isRealValue(event) ? {event} : null),
      },
    });
  }
}
