// noinspection JSUnusedGlobalSymbols

import { MatDateFormats } from '@angular/material/core';

export const mobileViewportWidth = 480;
export const tabletViewportWidth = 768;
export const mediumViewportWidth = 992;
export const exchangeContactInfoT1D = {
  address: '101 Federal Street, Suite 440 Boston, MA 02110, USA',
  line1: '101 Federal Street, Suite 440',
  line2: 'Boston, MA 02110',
  phone: '617-892-6165',
  email: 'registry@T1DExchange.org',
  site: {
    href: 'https://t1dexchange.org',
    label: 'T1DExchange.org'
  }
};
export const passwordMinimalLength = 8;
export const passwordSpecialSymbols = '~!@#$%^&*()+=_\\\\/\\-{}\\[\\]|:;"\'?<>,.';
export const phoneInputMask = ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const phonePattern = '(\\+\\d{1,4})?([\\s-]?\\(?\\d{2,3}\\)?[\\s-]?\\d{3}[\\s-]?\\d{4})?';
export const phoneLabel = 'Mobile Phone Number';
export const emailPattern = '\\S*@\\S*\\.\\S{2,4}';
export const passwordSentence = 'Password should';
export const dateShortFormat = 'MM/YYYY';
export const dateLongFormat = 'MM/DD/YYYY';
export const serverDateFormat = 'YYYY-MM-DD';
export const serverDateShortFormat = 'YYYY-MM';
export const dateStrictPattern = '^\\d{2}\\/\\d{2}\\/\\d{4}$';
export const dateTimeMediumFormat = 'MMM D, YYYY, h:mm:ss a';
export const dateOptions = {year: 'numeric' as 'numeric', month: 'long' as 'long', day: 'numeric' as 'numeric'};
export const commonMomentFormats = [dateShortFormat, dateLongFormat, serverDateFormat, serverDateShortFormat];
export const firstPeriodInParagraphRegExp = /[.?!]\s/g;
export const uuidRegExp = /[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{4}-[0-9A-Za-z]{12}/g;
export const numberRegExp = /\d+/g;
export const dateShortPattern = '^\\d{2}\\/\\d{4}$';

export const shortDateFormats: MatDateFormats = {
  parse: {
    dateInput: dateShortFormat,
  } as const,
  display: {
    dateInput: dateShortFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  } as const,
};

export const fullDateFormats: MatDateFormats = {
  parse: {
    dateInput: dateLongFormat,
  },
  display: {
    dateInput: dateLongFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const navigationKeys = [
  'Backspace',
  'Delete',
  'Tab',
  'Escape',
  'Enter',
  'Home',
  'End',
  'ArrowLeft',
  'ArrowRight',
  'Clear',
  'Copy',
  'Paste',
];
