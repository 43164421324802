import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { schemaValidationPipe, UserNotification } from 'tr-lib';

import { environment } from '@env/environment';
import { AppState, participantNotificationSchema, ParticipantNotificationType, ParticipantNotificationUpdateType } from '@app/model';
import { selectRealUserId } from '@store/selectors';

@Injectable()
export class UserNotificationsService {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  getNotificationsOn(id: string): Observable<ParticipantNotificationType> {
    return this.http.get<ParticipantNotificationType>(`${environment.apiParticipantUrl}/${id}/notification-settings`).pipe(
      schemaValidationPipe(participantNotificationSchema)
    );
  }

  toggleNotification(id: string, body: ParticipantNotificationUpdateType): Observable<ParticipantNotificationType> {
    return this.http.patch<ParticipantNotificationType>(`${environment.apiParticipantUrl}/${id}/notification-settings`, body).pipe(
      schemaValidationPipe(participantNotificationSchema)
    );
  }

  getBanners(): Observable<UserNotification[]> {
    return this.store.pipe(
      selectRealUserId(),
      take(1),
      switchMap((id) => this.http.get<UserNotification[]>(`${environment.apiParticipantUrl}/${id}/notifications`).pipe(
        catchError(({error}) => {
          console.error(error);

          return of([]);
        })
      )),
      map(ns => ns.filter(n => n.type === 'banner')),
    );
  }
}
