import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DexcomFacade } from '@dexcom/store/facade';
import { DexcomModeType } from '@dexcom/model';

@Component({
  selector: 'app-dexcom-wrapper',
  template: `
    <router-outlet></router-outlet>

    <app-footer [noNavButtons]="true"></app-footer>
  `,
  styleUrls: ['./dexcom-wrapper.component.scss']
})
export class DexcomWrapperComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private facade: DexcomFacade
  ) {}

  ngOnInit(): void {
    const {mode} = this.route.snapshot.data as {mode: DexcomModeType};

    this.facade.defineDexcomMode(mode);
  }
}
