import { ParamMap } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';

import { isRealValue } from 'tr-lib';

import { selectDexcomStage } from '@dexcom/store/selectors';
import { defineDexcomMode, defineDexcomState, gotoThankPage } from '@dexcom/store/actions';
import { DexcomModeType, StateType } from '@dexcom/model';

import { DexcomState } from './reducer';

@Injectable({providedIn: 'root'})
export class DexcomFacade {
  state$: Observable<StateType> = this.store.select(selectDexcomStage).pipe(filter(({stage}) => isRealValue(stage)));

  constructor(private store: Store<DexcomState>) {}

  defineDexcomMode(payload: DexcomModeType): void {
    this.store.dispatch(defineDexcomMode({payload}));
  }

  defineDexcomState(payload: ParamMap): void {
    this.store.dispatch(defineDexcomState({payload}));
  }

  gotoThankPage(): void {
    this.store.dispatch(gotoThankPage());
  }
}
