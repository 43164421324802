import { Consent, isConsentUnsigned, isNonEmptyArray, ParticipantState, surveyType, UserStudyType } from 'tr-lib';

export const assentPrivilege: ParticipantState = 'assent';
export const reconsentPrivilege: ParticipantState = 'reconsent';
export const reconsentPendingPrivilege: ParticipantState = 'reconsent_pending';
export const assentReconsentPrivileges = Object.freeze([assentPrivilege, reconsentPendingPrivilege, reconsentPrivilege]);
export const getFirstUnsignedConsent = (all: Consent[], pendingList: boolean): Consent => {
  const firstUnsignedConsent = isNonEmptyArray(all) ? all.find(isConsentUnsigned) : null;

  return pendingList ? null : firstUnsignedConsent;
};

export interface OptInSettings {
  optInAllowed: boolean;
  optInPending: boolean;
  optInErrors: string;
}

const commonPart = `By opting in, you will receive secure text messages in this study to provide reminders and announcements.`
export const smsConsentFullFooterMessage = `${commonPart}
  The text messages are called secure because there are steps in place to help keep people from seeing these messages on your phone.
  It is possible that someone else may see the text messages on your phone. If they do, they might know that
  you are in a study or see a detail about the study. The frequency of messages you receive may vary.
  The company that manages the system that sends the texts will see your phone number. They use this information to send the texts.
  They are not allowed to send your phone numbers to other people or companies.
  Please ask the study team if you would like a copy of the company’s privacy policy.
`;
export const smsConsentShortFooterMessage = `${commonPart}..`;

export const isStudyCoreAndActive = (
  {study_status, study_type}: UserStudyType
): boolean => study_status === 'active' && study_type === surveyType.core_study;
