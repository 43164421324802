import { Injectable } from '@angular/core';
import { defer, Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { CountryInfo, sortCountries } from 'tr-lib';

@Injectable()
export class CountryCodesService {
  getCountryCodes(): Observable<CountryInfo[]> {
    return defer(() => import('tr-lib/src/country-codes')).pipe(
      retry(3),
      map(({countriesCodesList}) => sortCountries(countriesCodesList.results)),
    );
  }

  getReorderedCountryCodes(): Observable<CountryInfo[]> {
    return this.getCountryCodes().pipe(
      map(results => results.map(
        (item) => ({...item, phone: '+' + item.phone})
      )),
      catchError(error => throwError(() => new Error(error)))
    );
  }
}
