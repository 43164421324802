import * as t from 'io-ts';
import { string, type } from 'io-ts';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { defer, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import {
  AllStates,
  allStatesSchema,
  baseChildProfileSchema,
  BaseChildProfileType,
  ChangePassPayload,
  ParticipantDetails,
  participantDetailsSchema,
  ParticipantOwnProfile,
  participantOwnProfileSchema,
  ParticipantProfile,
  participantProfileSchema,
  ParticipantUpdateProfileWithIdType,
  REGISTRY_API_URL,
  schemaValidationPipe,
  SessionStorage,
  SetNewPassPayload,
  STORAGE,
  TimeZone,
  userHouseholdSchema,
  UserHouseholdType,
  validateSchema,
} from 'tr-lib';

import { JaebUserInfo, jaebUserInfoSchema, urlForJaebUsersPreselect } from '@app/model/jaeb-users';
import {
  childProfileFormErrorSchema,
  getErrorsFromUpdateChildProfile,
  getTokenFromT1DSchema,
  GetTokenFromT1DType,
  userIdFromToken,
} from '@app/model/user.helpers';
import { WithdrawalReasons } from '@app/user-profile/model/user-withdraw';
import { environment } from '@env/environment';
import { SmsConsentUpdateType } from '@app/dashboard/models';

@Injectable({providedIn: 'root'})
export class UserService {
  private userId$: Observable<string> = defer(() => of(this.storage.getItem('token'))).pipe(userIdFromToken());
  private resetPassUrl = environment.resetPassUrl;
  private checkResetPassTokenUrl = environment.checkResetPassTokenUrl;
  private setNewPassUrl = environment.setNewPassUrl;

  constructor(
    private http: HttpClient,
    @Inject(REGISTRY_API_URL) private apiUrl: string,
    @Inject(STORAGE) private storage: SessionStorage
  ) {}

  getProfile(): Observable<ParticipantOwnProfile> {
    return this.http.get<ParticipantOwnProfile>(environment.profileURL).pipe(
      schemaValidationPipe(participantOwnProfileSchema),
    );
  }

  getChildInfo(id: string): Observable<BaseChildProfileType> {
    return this.http.get<BaseChildProfileType>(`${environment.apiParticipantUrl}/${id}/child-info`).pipe(
      schemaValidationPipe(baseChildProfileSchema, baseChildProfileSchema.name)
    );
  }

  updateChildInfo(id: string, body: Partial<BaseChildProfileType>): Observable<BaseChildProfileType> {
    return this.http.patch<BaseChildProfileType>(`${environment.apiParticipantUrl}/${id}/child-info`, body).pipe(
      schemaValidationPipe(baseChildProfileSchema, baseChildProfileSchema.name)
    );
  }

  getTokenFromT1D(token: string): Observable<GetTokenFromT1DType> {
    return this.http.post<GetTokenFromT1DType>(`${this.apiUrl}/oauth/account`, {provider: 't1d', token}).pipe(
      schemaValidationPipe(getTokenFromT1DSchema)
    );
  }

  reconsentComplete(): Observable<unknown> {
    return this.userId$.pipe(take(1), switchMap(id => this.http.post(this.reconsentCompleteUrl(id), {})));
  }

  resetPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(this.resetPassUrl, {email});
  }

  checkToken(token: string): Observable<boolean> {
    return this.http.post<boolean>(this.checkResetPassTokenUrl, {token});
  }

  setNewPass(body: SetNewPassPayload): Observable<boolean> {
    return this.http.post<boolean>(this.setNewPassUrl, body);
  }

  changePass(body: Partial<ChangePassPayload>): Observable<{token: string}> {
    return this.http.post(`${this.apiUrl}/change_password`, body).pipe(
      schemaValidationPipe(type({token: string}))
    );
  }

  updateProfile(data: Partial<ParticipantProfile>): Observable<ParticipantProfile> {
    return this.userId$.pipe(
      take(1),
      switchMap(id => this.http.patch<ParticipantProfile>(this.participantUrl(id), data).pipe(
        schemaValidationPipe(participantProfileSchema, participantProfileSchema.name)
      ))
    );
  }

  getHouseholdInfo(id: string): Observable<UserHouseholdType> {
    return this.http.get<UserHouseholdType>(`${this.participantUrl(id)}/household-info`).pipe(
      schemaValidationPipe(userHouseholdSchema, userHouseholdSchema.name)
    );
  }

  updateHouseholdInfo(data: UserHouseholdType): Observable<UserHouseholdType> {
    return this.userId$.pipe(
      take(1),
      switchMap(id => this.http.patch<UserHouseholdType>(`${this.participantUrl(id)}/household-info`, data).pipe(
        schemaValidationPipe(userHouseholdSchema, userHouseholdSchema.name)
      ))
    );
  }

  updatePhoneAndSmsConsent(userId: string, phone: string): Observable<string> {
    const url = this.participantUrl(userId) + '/sms_consent';
    const body: SmsConsentUpdateType = {status: 'accepted', phone};

    return this.http.post<string>(url, body).pipe(schemaValidationPipe(t.string));
  }

  skipSmsConsent(userId: string): Observable<string> {
    const url = this.participantUrl(userId) + '/sms_consent';
    const body: SmsConsentUpdateType = {status: 'skipped'};

    return this.http.post<string>(url, body).pipe(schemaValidationPipe(t.string));
  }

  updateReconsentPendingProfile(data: Partial<BaseChildProfileType>): Observable<string> {
    return this.userId$.pipe(
      take(1),
      switchMap(id => this.http.post<string>(this.reconsentReassignUrl(id), data)),
      catchError((serverErr: HttpErrorResponse) => {
        validateSchema(childProfileFormErrorSchema)(serverErr.error);

        return throwError(() => Object.assign(serverErr, getErrorsFromUpdateChildProfile(serverErr.error)));
      }),
    );
  }

  getUnitedStatesOfAmerica(): Observable<AllStates> {
    return this.http.get<AllStates>(`${this.apiUrl}/admin/addr_states`).pipe(
      schemaValidationPipe(allStatesSchema)
    );
  }

  deactivateAccount(reasons: WithdrawalReasons): Observable<WithdrawalReasons> {
    return this.userId$.pipe(
      take(1),
      switchMap(id => this.http.post(`${this.apiUrl}/participant-withdraw/${id}`, reasons))
    );
  }

  getConvertedUserInfo(id: string): Observable<JaebUserInfo> {
    return this.http.get<JaebUserInfo>(urlForJaebUsersPreselect(this.apiUrl) + id).pipe(
      schemaValidationPipe(jaebUserInfoSchema),
    )
  }

  profileTypeSetup(body: ParticipantUpdateProfileWithIdType): Observable<ParticipantDetails>  {
    return this.http.post<ParticipantDetails>(`${this.participantUrl(body.id)}/profile_setup`, body).pipe(
      schemaValidationPipe(participantDetailsSchema)
    );
  }

  getTimezones(): Observable<TimeZone> {
    return this.http.get<TimeZone>(`${this.apiUrl}/time-zones`);
  }

  private participantUrl = (participantId: string) => `${environment.apiParticipantUrl}/${participantId}`;

  private reconsentReassignUrl = (participantId: string) => this.participantUrl(participantId) + '/reconsent/reassign_account';

  private reconsentCompleteUrl = (participantId: string) => this.participantUrl(participantId) + '/reconsent/complete';
}
