import { StudySource } from '@tr-common';

import { StudyCondition } from './study-condition';

export const fillByLValue = (value: StudySource): StudyCondition => {
  const basicSetUp = {l_value: value, operator: 'eq' as 'eq'};

  switch (value) {
    case StudySource.profile:
      return new StudyCondition({...basicSetUp, comparison_data_type: 'string' as 'string', profile_field: 'profile_type'});
    case StudySource.userAgeYears:
      return new StudyCondition({...basicSetUp, comparison_data_type: 'string' as 'string', r_value: null});
    case StudySource.storedAnswer:
      return new StudyCondition({
        l_value: value, operator: 'exists', stored_answer_path: {question_id: null, option_id: null, study_id: null}
      });
    default:
      throw new Error('Study source is not valid');
  }
};
