import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  checkStudyEligibility,
  failSnackBottomConstantConfig,
  isNonEmptyString,
  isRealValue,
  ParticipantState,
  profileType,
  somethingWentWrong,
} from 'tr-lib';

import { DialogsService } from '@services/dialogs.service';
import { PrivilegesService } from '@services/privileges.service';
import { UserService } from '@services/user.service';
import { UserRouterService } from '@services/user.router.service';

import {
  getSMSConsent,
  getUser,
  getUserFail,
  getUserSuccess,
  globalLoaderEnd,
  globalLoaderStart,
  loadUser,
  loadUserFail,
  loadUserSuccess,
  logout,
  userHasToBeReroutedIfNonRegular,
  userHasToBeReroutedIfRegular,
  userHasToUpdateItsProfileType,
  userUpdateItsProfileType,
  userUpdateItsProfileTypeFail,
  userUpdateItsProfileTypeSuccess,
} from '@store/actions';
import { AppState } from '@app/model';
import { getChildInfo, getHouseholdInfo, getNotificationsOn } from '@app/user-profile/store/profile.actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class UserEffects {
  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(getUser),
    tap(() => this.store.dispatch(globalLoaderStart())),
    switchMap(({source, checkSmsConsent, loadChildInfo}) => this.userService.getProfile().pipe(
      concatMap(payload => [
        getUserSuccess({payload, source}),
        getHouseholdInfo({id: payload.id}),
        (payload.profile_type === profileType.child && loadChildInfo) ? getChildInfo({id: payload.id}) : globalLoaderEnd(),
        ...(checkSmsConsent ? [getSMSConsent()] : [])
      ]),
      catchError(({error}) => of(getUserFail({error})))
    ))
  ));

  getUserFail$ = createEffect(() => this.actions$.pipe(
    ofType(getUserFail),
    map(() => globalLoaderEnd())
  ));

  getUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(getUserSuccess),
    concatMap(({payload: {state, profile_type, id}, source}) => {
      const isRegular = state === ParticipantState.regular;

      this.stateService.state = state;

      return [
        ...(isNonEmptyString(profile_type) ? [] : [userHasToUpdateItsProfileType({id})]),
        getNotificationsOn(),
        isRegular ? userHasToBeReroutedIfRegular({source}) : userHasToBeReroutedIfNonRegular({state})
      ];
    })
  ));

  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(loadUser),
    switchMap(() => this.userService.getProfile().pipe(
      map((response) => loadUserSuccess(response)),
      catchError(error => of(loadUserFail(error)))
    ))
  ));

  loadUserSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserSuccess),
    tap((payload) => {
      this.stateService.state = payload.state;
    })
  ), {dispatch: false});

  userHasToUpdateItsProfileType$ = createEffect(() => this.actions$.pipe(
    ofType(userHasToUpdateItsProfileType),
    debounceTime(1000),
    switchMap(({id}) => this.dialogsService.showUpdateProfileTypeDialog(id).pipe(
      map(payload => payload?.id === id ? userUpdateItsProfileType({payload}) : logout({}))
    ))
  ));

  userUpdateItsProfileType$ = createEffect(() => this.actions$.pipe(
    ofType(userUpdateItsProfileType),
    switchMap(({payload}) => this.userService.profileTypeSetup(payload).pipe(
      map((data) => userUpdateItsProfileTypeSuccess({payload: data})),
      catchError(({error}) => of(userUpdateItsProfileTypeFail({error})))
    ))
  ));

  rerouteIfNonRegularUser$ = createEffect(() => this.actions$.pipe(
    ofType(userHasToBeReroutedIfNonRegular),
    tap(({state}) => this.userRouterService.userHasToBeReroutedIfNonRegular(state).then())
  ), {dispatch: false});

  rerouteIfRegularUser$ = createEffect(() => this.actions$.pipe(
    ofType(userHasToBeReroutedIfRegular),
    filter(({source}) => isRealValue(source)),
    tap(({source}) => this.userRouterService.userHasToBeReroutedIfRegular(source).then())
  ), {dispatch: false});

  reloadProfileAfterEligibilityCheck = createEffect(() => this.actions$.pipe(
    ofType(checkStudyEligibility),
    map(() => getUser({})),
  ));

  somethingWentWrong$ = createEffect(() => this.actions$.pipe(
    ofType(userUpdateItsProfileTypeFail),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig(null)))
  ), {dispatch: false});

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private stateService: PrivilegesService,
    private dialogsService: DialogsService,
    private userRouterService: UserRouterService
  ) {}
}
