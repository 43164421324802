import { FormControl, FormGroup } from '@angular/forms';
import * as t from 'io-ts';

import { KeysOf } from './helpers';

export const userNotificationSchema = t.type({
  reminders_new_ro_and_studies_email_on: t.boolean,
  reminders_new_ro_and_studies_sms_on: t.boolean,
  reminders_ongoing_studies_email_on: t.boolean,
  reminders_ongoing_studies_sms_on: t.boolean,
  newsletter_email_on: t.boolean,
  newsletter_sms_on: t.boolean,
  management_resources_email_on: t.boolean,
  management_resources_sms_on: t.boolean,
}, 'userNotificationSchema');
export type UserNotificationType = t.TypeOf<typeof userNotificationSchema>;

const keysSmsEmailToggle = ['email_on', 'sms_on'] as const;
export const smsEmailToggleSchema = t.type({
  email_on: t.boolean,
  sms_on: t.boolean
}, 'smsEmailToggleSchema');
export type SmsEmailToggleType = t.TypeOf<typeof smsEmailToggleSchema>;

export const userNotificationFormSchema = t.type({
  reminders_new_ro_and_studies: smsEmailToggleSchema,
  reminders_ongoing_studies: smsEmailToggleSchema,
  newsletter: smsEmailToggleSchema,
  management_resources: smsEmailToggleSchema
}, 'userNotificationFormSchema');
export type UserNotificationFormType = t.TypeOf<typeof userNotificationFormSchema>;

export type NotificationSetType = keyof UserNotificationFormType;
export type TypeOfNotification = keyof SmsEmailToggleType;

export type SmsEmailToggleFormType = KeysOf<SmsEmailToggleType, FormControl<boolean>>;
export const getSmsEmailToggleForm = (): FormGroup<SmsEmailToggleFormType> => new FormGroup<SmsEmailToggleFormType>({
  email_on: new FormControl<boolean>(null),
  sms_on: new FormControl<boolean>(null)
});

export type UserNotificationForm = KeysOf<UserNotificationFormType, FormGroup<SmsEmailToggleFormType>>;

const keysUserNotificationForm = [
  'reminders_new_ro_and_studies', 'reminders_ongoing_studies', 'newsletter', 'management_resources'
] as const;

export const transformUserNotification2Form = (
  data: UserNotificationType
): UserNotificationFormType => keysUserNotificationForm.reduce((fullAcc, leftKey) => {
  const pair = keysSmsEmailToggle.reduce((partAcc, rightKey) => {
    const middle = {[rightKey]: data[`${leftKey}_${rightKey}`]};

    return {...partAcc, ...middle};
  }, {} as SmsEmailToggleType);

  return {...fullAcc, [leftKey]: pair};
}, {} as UserNotificationFormType);

export const transform2UserNotification = (data: UserNotificationFormType): UserNotificationType => Object.keys(
  data
).reduce<UserNotificationType>((globalAcc: UserNotificationType, leftKey: string) => {
  const intKeys: SmsEmailToggleType = data[leftKey];
  const pair: Partial<UserNotificationType> = Object.keys(intKeys).reduce((intAcc: Partial<UserNotificationType>, rightKey: string) => {
    const value: boolean = intKeys[rightKey];
    const key = `${leftKey}_${rightKey}`;

    return {...intAcc, [key]: value};
  }, {});

  return {...globalAcc, ...pair};
}, {} as UserNotificationType);
