import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { countryCodeValidator, isRealValue, LayoutService } from 'tr-lib';

import { getHtml, OptInSettings, smsConsentFullFooterMessage, smsConsentShortFooterMessage } from '@app/model';
import { SmsConsentForm, SmsPhoneData } from '@app/dashboard/models/sms-consent';
import { UserProfileFormService } from '@app/user-profile/services/user-profile-form.service';
import { DashboardFacade } from '@app/dashboard/store/dashboard.facade';

@Component({
  selector: 'app-sms-consent',
  templateUrl: './sms-consent-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sms-consent-dialog.component.scss']
})
export class SmsConsentDialogComponent implements OnInit, OnDestroy {
  smsConsentFooter = smsConsentFullFooterMessage;
  isCut = false;
  formGroup = new FormGroup<SmsConsentForm>({
    phone: new FormControl<string>(this.data.phone, {
      validators: Validators.compose([Validators.required, countryCodeValidator()])
    }),
    optIn: new FormControl<boolean>(null, {validators: Validators.requiredTrue})
  });
  optInSettings$: Observable<OptInSettings> = this.facade.optInSettings$.pipe(
    tap(({optInAllowed, optInPending, optInErrors}) => {
      if (optInPending) return;
      if (optInAllowed) {
        this.dialogRef.close(true);
      } else if (isRealValue(optInErrors)) {
        this.phone.setErrors({server: optInErrors});
      }
    })
  );
  showError = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SmsPhoneData,
    readonly profile: UserProfileFormService,
    public dialogRef: MatDialogRef<SmsConsentDialogComponent>,
    public layout: LayoutService,
    public facade: DashboardFacade,
  ) {}

  get phone(): AbstractControl {
    return this.formGroup.controls.phone as AbstractControl;
  }

  get optIn(): AbstractControl {
    return this.formGroup.controls.optIn as AbstractControl;
  }

  ngOnInit(): void {
    this.isCut = this.layout.likeDesktop;
    if (this.isCut) {
      this.smsConsentFooter = smsConsentShortFooterMessage;
    }
    if (this.layout.isGadget) {
      getHtml().classList.add('no-scroll');
    }
  }

  ngOnDestroy(): void {
    if (this.layout.isGadget) {
      getHtml().classList.remove('no-scroll');
    }
  }

  showMore(): void {
    this.smsConsentFooter = this.isCut ? smsConsentFullFooterMessage : smsConsentShortFooterMessage;
    this.isCut = !this.isCut;
  }

  confirm(): void {
    this.showError = true;
    if (this.formGroup.valid) {
      this.facade.signSmsConsent(this.formGroup.value.phone);
    } else {
      this.phone.markAsTouched();
      this.phone.markAsDirty();
      this.optIn.markAsTouched();
    }
  }
}
