import { AfterViewInit, Component } from '@angular/core';
import { isRealValue, LayoutService } from 'tr-lib';

@Component({
  selector: 'app-content-wrapper',
  template: `
    <app-not-auth-header></app-not-auth-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  `,
  styleUrls: ['./content-wrapper.component.scss']
})
export class ContentWrapperComponent implements AfterViewInit {
  constructor(private layoutService: LayoutService) {}

  ngAfterViewInit(): void {
    if (isRealValue(this.layoutService.scrollSrcElement)) {
      this.layoutService.scrollSrcElement.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
