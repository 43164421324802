import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isNonEmptyArray } from 'tr-lib';

import { DashboardState } from '../models/dashboard-state';
import { Procedures } from '../models';

export const selectDashboard = createFeatureSelector<DashboardState>('dashboard');
export const selectAchievements = createSelector(selectDashboard, ({achievements}) => achievements);
export const selectUserStudies = createSelector(selectDashboard, ({userStudies}) => userStudies);
export const selectCoreStudy = createSelector(selectDashboard, ({coreStudies}) => isNonEmptyArray(coreStudies) ? coreStudies[0] : null);
export const selectUserStudiesWithStatusV2 = createSelector(selectDashboard, (
  {coreStudies, completedStudies, activeStudies, questionHistory, availableStudies, activeOpportunities}: DashboardState
) => ({coreStudies, completedStudies, activeStudies, questionHistory, availableStudies, activeOpportunities}));
export const selectBegunStudies = createSelector(selectDashboard, ({begunStudies}) => begunStudies);
export const selectActiveStudies = createSelector(selectDashboard, ({activeStudies}) => activeStudies);
export const selectQuestionHistory = createSelector(selectDashboard, ({questionHistory}) => questionHistory);
export const selectActiveOpportunities = createSelector(selectDashboard, ({activeOpportunities}) => activeOpportunities);
export const selectClosedStudies = createSelector(selectDashboard, ({closedStudies}) => closedStudies);
export const selectNotReadMissedStudies = createSelector(selectDashboard, ({notReadMissedStudies}) => notReadMissedStudies?.studies);
export const selectPendingAchievements = createSelector(selectDashboard, ({pendingAchievements}) => pendingAchievements);
export const selectPendingStudies = createSelector(selectDashboard, ({pendingStudies}) => pendingStudies);
export const selectSubmittedAnswers = createSelector(selectDashboard, ({submittedAnswers}) => submittedAnswers);
export const selectProcedures = createSelector(selectDashboard, ({studyProcedure}) => studyProcedure);
export const selectQuestionnaireProcedure = createSelector(
  selectProcedures,
  (procedures: Procedures) => isNonEmptyArray(procedures) ? procedures.find(p => p.procedure_id === 'questionnaire') : null
);
