import * as t from 'io-ts';
/**
 * This model was created using https://unitio.atlassian.net/wiki/spaces/RS/pages/897482851/Option+validators+v2
 */
export const studyOptionValidatorOperators = {
  lt: 'less than ( < )',
  le: 'less than or equal ( <= )',
  gt: 'greater than ( > )',
  ge: 'greater than or equal (>= )',
  eq: 'equal ( == )',
  ne: 'not equal ( != )'
} as const;
export const studyOptionValidatorOperatorsSchema = t.keyof(studyOptionValidatorOperators);
export type StudyOptionValidatorOperatorsType = t.TypeOf<typeof studyOptionValidatorOperatorsSchema>;

export const studyConditionOperators = {
  ...studyOptionValidatorOperators,
  exists: 'Unary operator which checks existing left value'
} as const;
export const studyConditionOperatorSchema = t.keyof(studyConditionOperators);
export type StudyConditionOperatorType = t.TypeOf<typeof studyConditionOperatorSchema>;
/**
 * needs sync with {@link clientPredefinedOperators} and {@link PredefinedOperatorsType}
 */
export const predefinedOperators = t.union([
  t.literal('greaterThan'),
  t.literal('greaterThanOrEqualTo'),
  t.literal('greaterThanOrEqualTo'),
  t.literal('lessThan'),
  t.literal('lessThanOrEqualTo'),
  t.literal('lessThanOrEqualTo'),
  t.literal('equalTo'),
  t.literal('notEqualTo'),
  t.literal('beforeToday'),
  t.literal('sameOrBeforeToday'),
  t.literal('afterToday'),
  t.literal('sameOrAfterToday'),
]);
/**
 * needs sync with {@link predefinedOperators}
 */
export const clientPredefinedOperators = t.union([
  t.literal('gt'),
  t.literal('gte'),
  t.literal('ge'),
  t.literal('lt'),
  t.literal('lte'),
  t.literal('le'),
  t.literal('eq'),
  t.literal('ne'),
  t.literal('before_today'),
  t.literal('same_or_before_today'),
  t.literal('after_today'),
  t.literal('same_or_after_today'),
  t.literal('same_or_before_this_month'),
  t.literal('same_or_after_this_month'),
]);
export type PredefinedOperatorsType = t.TypeOf<typeof clientPredefinedOperators>
