import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  filterPopupAndBeforeProcedure,
  filterPopupAndConclusion,
  filterRealValues,
  isRealValue,
  ModuleSettings,
  ProcedureTemplateTypes,
  TypeConsent,
} from '@tr-common';

import { calculateConsentType } from '../../../consent/models';
import { getConsentTypeFromParticipantProfile, isDialogShouldBeFromModuleConstructor } from '../../../models';
import { EventsLogService } from '../../../services';
import { isJAEB, isProcedureHasWelcomeDialog, procedure2ActionForWelcomeDialog } from '../../models';
import { SurveyDialogsService } from '../../services/survey-dialog.service';
import { SurveyService } from '../../services/survey.service';
import {
  checkShowingWelcomeDialog,
  defineConsentDialog,
  defineEligibleDialog,
  defineHardcodedWelcomeDialog,
  defineSourceOfDialog,
  finishSubmitPending,
  getConsentsToShowDialog,
  loadConsentsFail,
  loadUserEventsForStudy,
  loadUserEventsForStudyFail,
  loadUserEventsForStudySuccess,
  registerShowingWelcomeDialog,
  registerShowingWelcomeDialogFail,
  registerShowingWelcomeDialogSuccess,
  selectModuleSettings,
  selectParamsIDs,
  selectParticipantDetails,
  selectStudy,
  showConclusionDialog,
  showConsentDialogForNonRegular,
  showConsentDialogForRegular,
  showEligibleDialogForNonRegular,
  showEligibleDialogForRegular,
  showWelcomeDialogFromDB,
  showWelcomeQuestionnaireDialog,
  showWelcomeScreeningDialog,
  SurveyState,
} from '../index';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class DialogEffects {
  checkShowingWelcomeDialog$ = createEffect(() => this.actions$.pipe(
    ofType(checkShowingWelcomeDialog),
    withLatestFrom(this.store.select(selectParticipantDetails).pipe(filterRealValues())),
    filter(([{procedure}, {source}]) => !(procedure === ProcedureTemplateTypes.consent && isJAEB(source))),
    map(([{procedure}]) => loadUserEventsForStudy({procedure})),
  ));

  loadUserEventsForStudy$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserEventsForStudy),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap((
      [{procedure}, {studyID, userStudyID, userID}]
    ) => this.eventsLogService.hasProcedureShowedWelcomeDialog(userID, studyID, userStudyID, procedure).pipe(
      map(hasRegisteredEvents => loadUserEventsForStudySuccess({procedure, hasRegisteredEvents})),
      catchError(error => of(loadUserEventsForStudyFail(error))),
    )),
  ));

  loadUserEventsForStudySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadUserEventsForStudySuccess),
    filter(({hasRegisteredEvents}) => !hasRegisteredEvents),
    map(({procedure}) => defineSourceOfDialog({procedure}))
  ));

  defineSourceOfDialog$ = createEffect(() => this.actions$.pipe(
    ofType(defineSourceOfDialog),
    withLatestFrom(
      this.store.select(selectStudy).pipe(filterRealValues()),
      this.store.select(selectParticipantDetails).pipe(filterRealValues())
    ),
    map(([{procedure}, study, profile]) => isDialogShouldBeFromModuleConstructor(profile, study)
      ? showWelcomeDialogFromDB({procedure})
      : defineHardcodedWelcomeDialog({procedure, profile, study})
    )
  ));

  defineHardcodedWelcomeDialog$ = createEffect(() => this.actions$.pipe(
    ofType(defineHardcodedWelcomeDialog),
    filter(({procedure}) => isProcedureHasWelcomeDialog(procedure)),
    map(({procedure, profile, study}) => procedure2ActionForWelcomeDialog[procedure](study, profile)),
  ));

  showWelcomeDialogFromDB$ = createEffect(() => this.actions$.pipe(
    ofType(showWelcomeDialogFromDB),
    withLatestFrom(this.store.select(selectModuleSettings)),
    map(([{procedure}, settings]) => ({procedure, settings: settings[procedure]?.find(filterPopupAndBeforeProcedure)})),
    filter(({settings}) => isRealValue(settings)),
    switchMap(({procedure, settings}) => this.dialogService.showConfiguredPopup(settings.content).pipe(
      map(() => registerShowingWelcomeDialog({procedure}))
    ))
  ));

  showWelcomeScreeningDialog$ = createEffect(() => this.actions$.pipe(
    ofType(showWelcomeScreeningDialog),
    switchMap(({profile, study}) => this.dialogService.showWelcomeDialog(profile.source, study).pipe(
      map(() => registerShowingWelcomeDialog({procedure: ProcedureTemplateTypes.screening})),
    )),
  ));

  showWelcomeQuestionnaireDialog$ = createEffect(() => this.actions$.pipe(
    ofType(showWelcomeQuestionnaireDialog),
    switchMap(({profile}) => this.dialogService.showBeforeQuestionnaireDialog(
      getConsentTypeFromParticipantProfile(profile),
    ).pipe(
      map(() => registerShowingWelcomeDialog({procedure: ProcedureTemplateTypes.questionnaire})),
    )),
  ));

  registerShowingWelcomeDialog$ = createEffect(() => this.actions$.pipe(
    ofType(registerShowingWelcomeDialog),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([{procedure}, {studyID, userStudyID, userID}]) => this.eventsLogService.registerStartedProcedure(
      userID, studyID, +userStudyID, procedure,
    ).pipe(
      map(results => registerShowingWelcomeDialogSuccess({payload: results})),
      catchError(error => of(registerShowingWelcomeDialogFail(error))),
    )),
  ));

  getConsentsToShowDialog$ = createEffect(() => this.actions$.pipe(
    ofType(getConsentsToShowDialog),
    withLatestFrom(this.store.select(selectParamsIDs)),
    map(([,{studyID, userID, userStudyID}]) => ({studyID, userID, userStudyID})),
    switchMap(({studyID, userID, userStudyID}) => this.surveyService.loadConsents(userID, userStudyID).pipe(
      map(payload => defineConsentDialog({payload})),
      catchError(({error}) => of(loadConsentsFail({error})))
    ))
  ));

  showConsentDialog$ = createEffect(() => this.actions$.pipe(
    ofType(defineConsentDialog),
    map(({payload}) => calculateConsentType(payload)),
    map((consentType) => consentType === TypeConsent.adult
      ? showConsentDialogForRegular()
      : showConsentDialogForNonRegular({consentType}))
  ));

  showConsentDialogForNonRegular$ = createEffect(() => this.actions$.pipe(
    ofType(showConsentDialogForNonRegular),
    switchMap(({consentType}) => this.dialogService.showConsentDialog(consentType).pipe(
      map(() => registerShowingWelcomeDialog({procedure: ProcedureTemplateTypes.consent}))
    ))
  ));

  showConsentDialogRegular$ = createEffect(() => this.actions$.pipe(
    ofType(showConsentDialogForRegular),
    withLatestFrom(this.store.select(selectModuleSettings)),
    map(([, settings]) => settings.consent?.find(filterPopupAndBeforeProcedure)),
    filter(isRealValue),
    switchMap(({content, procedure}) => this.dialogService.showConfiguredPopup(content).pipe(
      map(() => registerShowingWelcomeDialog({procedure}))
    ))
  ));
  // End of welcome dialogs

  // Eligible and Conclusion Dialog
  showEligibleDialog$ = createEffect(() => this.actions$.pipe(
    ofType(defineEligibleDialog),
    withLatestFrom(this.store.select(selectParticipantDetails), this.store.select(selectStudy)),
    map(([, participant, study]): boolean => isDialogShouldBeFromModuleConstructor(participant, study)),
    map(fromConstructor => fromConstructor ? showEligibleDialogForRegular() : showEligibleDialogForNonRegular()),
  ));

  showEligibleDialogForNonRegular$ = createEffect(() => this.actions$.pipe(
    ofType(showEligibleDialogForNonRegular),
    withLatestFrom(this.store.select(selectParticipantDetails)),
    switchMap(([, participant]) => this.dialogService.showIsEligibleDialog(
      getConsentTypeFromParticipantProfile(participant), participant.source
    ).pipe(
      map(() => finishSubmitPending())
    ))
  ));

  showEligibleDialogForRegular$ = createEffect(() => this.actions$.pipe(
    ofType(showEligibleDialogForRegular),
    withLatestFrom(this.store.select(selectModuleSettings)),
    map(([, settings]): ModuleSettings => settings[ProcedureTemplateTypes.screening]?.find(filterPopupAndConclusion)),
    filter(setting => isRealValue(setting)),
    switchMap(setting => this.dialogService.showConfiguredPopup(setting.content).pipe(
      map(() => finishSubmitPending())
    )),
  ));

  showQuestionnaireDialog$ = createEffect(() => this.actions$.pipe(
    ofType(showConclusionDialog),
    withLatestFrom(this.store.select(selectModuleSettings)),
    map(([, settings]) => settings[ProcedureTemplateTypes.questionnaire]?.find(filterPopupAndConclusion)),
    filterRealValues(),
    tap((setting: ModuleSettings) => this.dialogService.showConfiguredPopup(setting.content)),
  ), {dispatch: false});

  constructor(
    public surveyService: SurveyService,
    private actions$: Actions,
    private store: Store<SurveyState>,
    private dialogService: SurveyDialogsService,
    private eventsLogService: EventsLogService,
  ) {}
}
