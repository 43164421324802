import { IconsToRegister } from 'tr-lib';

export const allSvgIcons: Array<IconsToRegister> = [{
  iconName: 'active-studies-group', iconPath: '/assets/dashboard/active-studies-group.svg',
}, {
  iconName: 'active-research-group', iconPath: '/assets/dashboard/active-research-group.svg',
}, {
  iconName: 'checkmark-blue', iconPath: '/assets/icons/checkmark-blue.svg',
}, {
  iconName: 'checkmark-white', iconPath: '/assets/icons/checkmark-white.svg',
}, {
  iconName: 'checkmark-round-blue', iconPath: '/assets/icons/checkmark-blue-round.svg',
}, {
  iconName: 'checkmark-round-gray', iconPath: '/assets/icons/checkmark-gray-round.svg',
}, {
  iconName: 'lock-key', iconPath: '/assets/lock-key.svg',
}, {
  iconName: 'lock-unlocked', iconPath: '/assets/lock-unlocked.svg',
}, {
  iconName: 'lock-locked', iconPath: '/assets/lock-locked.svg',
}, {
  iconName: 'annual', iconPath: '/assets/dashboard/annual.svg',
}, {
  iconName: 'study-not-started', iconPath: '/assets/dashboard/study-not-started.svg',
}, {
  iconName: 'study-in-progress', iconPath: '/assets/dashboard/study-in-progress.svg',
}, {
  iconName: 'study-completed', iconPath: '/assets/dashboard/study-completed.svg',
}, {
  iconName: 'study-last-viewed', iconPath: '/assets/dashboard/study-last-viewed.svg',
}, {
  iconName: 'study-not-viewed', iconPath: '/assets/dashboard/study-not-viewed.svg',
}, {
  iconName: 'phone-registry', iconPath: '/assets/icons/phone-registry.svg',
}, {
  iconName: 'view-open', iconPath: '/assets/icons/show_pass.svg',
}, {
  iconName: 'view-close', iconPath: '/assets/icons/hide_pass.svg',
}, {
  iconName: 'go-forward', iconPath: '/assets/icons/go-forward.svg',
}, {
  iconName: 'next-step', iconPath: '/assets/icons/next-step.svg',
}, {
  iconName: 'edit-participant-profile', iconPath: '/assets/icons/edit-participant-profile.svg',
}, {
  iconName: 'review-participant', iconPath: '/assets/icons/review-participant.svg',
}, {
  iconName: 'download-participant', iconPath: '/assets/icons/download-participant.svg',
}, {
  iconName: 'print-participant', iconPath: '/assets/icons/print-participant.svg',
}, {
  iconName: 'achievement-0', iconPath: '/assets/achievements/registration-successful.svg',
}, {
  iconName: 'achievement-1', iconPath: '/assets/achievements/questions-complete.svg',
}, {
  iconName: 'achievement-2', iconPath: '/assets/achievements/consent-signed.svg',
}, {
  iconName: 'achievement-3', iconPath: '/assets/achievements/questions-complete.svg',
}, {
  iconName: 'achievement-4', iconPath: '/assets/achievements/assent.svg',
}, {
  iconName: 'achievement-5', iconPath: '/assets/achievements/reconsent.svg',
}, {
  iconName: 'achievement-6', iconPath: '/assets/achievements/substudy-complete.svg',
}, {
  iconName: '5-promotion-studies', iconPath: '/assets/achievements/5-promotion-studies.svg',
}, {
  iconName: '10-promotion-studies', iconPath: '/assets/achievements/10-promotion-studies.svg',
}, {
  iconName: '15-promotion-studies', iconPath: '/assets/achievements/15-promotion-studies.svg',
}, {
  iconName: 'error-msg', iconPath: '/assets/icons/error-msg.svg',
}, {
  iconName: 'facebook', iconPath: '/assets/icons/facebook.svg',
}, {
  iconName: 'instagram', iconPath: '/assets/icons/instagram.svg',
}, {
  iconName: 'linkedin', iconPath: '/assets/icons/linkedin.svg',
}, {
  iconName: 'twitter', iconPath: '/assets/icons/twitter.svg',
}, {
  iconName: 'create-account', iconPath: '/assets/icons/create_acc_icon.svg',
}, {
  iconName: 'complete', iconPath: '/assets/icons/complete_icon.svg',
}, {
  iconName: 'email', iconPath: '/assets/icons/email_icon.svg',
}, {
  iconName: 'phone', iconPath: '/assets/icons/phone_icon.svg',
}, {
  iconName: 'sign-consent', iconPath: '/assets/icons/sign_consent_icon.svg',
}, {
  iconName: 'show-pass', iconPath: '/assets/icons/show_pass.svg',
}, {
  iconName: 'tooltip', iconPath: '/assets/icons/tooltip.svg',
}, {
  iconName: 'back-arrow', iconPath: '/assets/icons/back_arrow.svg',
}, {
  iconName: 'close', iconPath: '/assets/close.svg',
}, {
  iconName: 'download', iconPath: '/assets/icons/download.svg',
}, {
  iconName: 'print', iconPath: '/assets/icons/print.svg',
}, {
  iconName: 'bullet-point', iconPath: '/assets/icons/bullet_point.svg',
}, {
  iconName: 'blood', iconPath: '/assets/icons/blood_ic.svg',
}, {
  iconName: 'cgm-device', iconPath: '/assets/icons/cgm_device_ic.svg',
}, {
  iconName: 'finger-stick', iconPath: '/assets/icons/finger_stick_ic.svg',
}, {
  iconName: 'heartbeat', iconPath: '/assets/icons/heartbeat_ic.svg',
}, {
  iconName: 'arrow-up', iconPath: '/assets/icons/arrow_up.svg',
}, {
  iconName: 'two-arrows', iconPath: '/assets/icons/two_arrows.svg',
}, {
  iconName: 'logo', iconPath: '/assets/icons/logo.svg',
}, {
  iconName: 'hamburger', iconPath: '/assets/icons/hamburger.svg',
}, {
  iconName: 'hamburger_dark', iconPath: '/assets/icons/hamburger_dark.svg',
}, {
  iconName: 'help', iconPath: '/assets/help.svg',
}, {
  iconName: 'profile', iconPath: '/assets/icons/icons_profile.svg',
}, {
  iconName: 'key', iconPath: '/assets/icons/icons_key.svg',
}, {
  iconName: 'sand-time', iconPath: '/assets/icons/sand_time.svg',
}, {
  iconName: 'review', iconPath: '/assets/icons/review.svg',
}, {
  iconName: 'progress', iconPath: '/assets/icons/progress.svg',
}, {
  iconName: 'notify-success', iconPath: '/assets/icons/notify_success.svg',
}, {
  iconName: 'notify-warn', iconPath: '/assets/icons/notify_warn.svg',
}, {
  iconName: 'notify-failed', iconPath: '/assets/icons/notify_failed.svg',
}, {
  iconName: 'calendar-missed-orange', iconPath: '/assets/icons/calendar_missed_orange.svg',
}, {
  iconName: 'history-questions', iconPath: '/assets/icons/question-history.svg',
}];
