<ng-container [formGroup]="parentForm">
  <mat-form-field appearance="outline">
    <mat-label>Address</mat-label>
    <input type="text" matInput pattern="^[a-zA-Z0-9 ]*$" formControlName="address" aria-label="Address" autocomplete="address-line1" aria-autocomplete="inline" />
    <mat-error *ngIf="householdAddress.errors?.required">Address is required</mat-error>
    <mat-error *ngIf="householdAddress.errors?.pattern">Please enter valid address</mat-error>
    <mat-error *ngIf="householdAddress.errors?.server"><span
      *ngFor="let e of householdAddress.errors.server">{{ e }}</span></mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>City</mat-label>
    <input type="text" matInput pattern="^[a-zA-Z0-9 ]*$" formControlName="city" aria-label="City" autocomplete="address-level2" aria-autocomplete="inline" />
    <mat-error *ngIf="householdCity.errors?.required">City is required</mat-error>
    <mat-error *ngIf="householdCity.errors?.pattern">Please enter valid city</mat-error>
    <mat-error *ngIf="householdCity.errors?.server"><span
      *ngFor="let e of householdCity.errors.server">{{ e }}</span></mat-error>
  </mat-form-field>

  <div class="flex align-center gap-10 state-field">
    <mat-form-field appearance="outline">
      <mat-label>State</mat-label>
      <input id="state" type="text" #stateInput matInput aria-label="State" placeholder="Type state's name" formControlName="country_state"
        autocomplete="address-level1" aria-autocomplete="both" [matAutocomplete]="stateAutocomplete" />
      <div class="flex align-center icon-container" (click)="openStatesDropdown($event, stateInput)">
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
      <mat-error>
        <span *ngIf="isIncorrectState">State does not exist</span>
        <ng-container *ngIf="householdState.errors?.server">
          <span *ngFor="let e of householdState.errors.server">{{ e }}</span>
        </ng-container>
      </mat-error>

      <mat-autocomplete #stateAutocomplete="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="onSelectState($event)">
        <mat-option *ngFor="let state of values$ | async" [value]="state">{{ state }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <span class="icon-container" (click)="clearStateField()"><mat-icon svgIcon="close"></mat-icon></span>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Postal/ZIP code</mat-label>
    <input type="text" matInput pattern="^\d{5}$" formControlName="zip_code" autocomplete="postal-code" aria-label="ZIP Code"
      aria-autocomplete="inline" />
    <mat-error *ngIf="householdZip.errors">
      <span *ngIf="householdZip.errors.pattern">Please enter a valid 5-digit zip code</span>
      <ng-container *ngIf="householdZip.errors.server"><span *ngFor="let e of householdZip.errors.server">{{ e }}</span></ng-container>
    </mat-error>
  </mat-form-field>

  <div class="tooltip-field">
    <mat-form-field *ngIf="timeZones" class="no-errors" appearance="outline">
      <mat-label>My Timezone</mat-label>
      <mat-select formControlName="timezone">
        <mat-option *ngFor="let key of getTimeZoneKeys()" [value]="timeZones[key]">{{ key }}</mat-option>
      </mat-select>
    </mat-form-field>

    <lib-info-tooltip class="ml-10" position="right-bottom">
      <strong>Why are we asking this?</strong><br />
      By providing your time zone, we can send you notifications about new research opportunities at an appropriate time.
    </lib-info-tooltip>
  </div>
  <ng-content></ng-content>
</ng-container>
