import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-header-menu',
  templateUrl: './mobile-header-menu.component.html',
  styleUrls: ['./mobile-header-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MobileHeaderMenuComponent {
  constructor(
    public dialogRef: MatDialogRef<MobileHeaderMenuComponent>,
    private router: Router
  ) {}

  closeDialog(toContactgUs?: boolean) {
    this.dialogRef.close(null);
    if (toContactgUs === true) {
      this.redirectToContact();
    }
  }

  redirectToContact() {
    this.router.navigate(['/'], {fragment: 'contact-section'}).then(() => {
      this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
        const contact = document.querySelector('#contact-section');
        contact.scrollIntoView({behavior: 'smooth'});
      });
      this.dialogRef.close('contact-section');
    })
  }
}
