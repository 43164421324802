import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { faqContent } from '@app/landing/components/faq/faq-content';

@Component({
  selector: 'app-faq',
  template: `
    <section class="faq">
      <h1>How can we help you?</h1>
      <img src="assets/img/question-mark.svg" alt="#">
      <div class="faq-block" [ngClass]="{'open': i === openedIndex}" *ngFor="let link of faq; let i = index">
        <div class="faq-block-header">
          <h5>Q{{ i + 1 }}: {{ link.question }}</h5>
          <mat-icon (click)="openIndex(i)" svgIcon="arrow-up"></mat-icon>
        </div>
        <div class="faq-block-text" [@slideUpDown]="i === openedIndex" [innerHTML]="link.answer"></div>
      </div>
    </section>
  `,
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('slideUpDown', [
      state('1', style({'max-height': '800px', opacity: 1})),
      state('0', style({'max-height': '0px', opacity: 0})),
      transition(':enter', animate('300ms ease-in-out')),
      transition(':leave', animate('300ms ease-in-out')),
      transition('* => *', animate('300ms ease-in-out')),
    ])
  ]
})
export class FaqComponent {
  openedIndex: number;
  faq = faqContent;

  openIndex(i: number): void {
    this.openedIndex = this.openedIndex === i ? null : i;
  }
}
