<div class="option-label mbe-5" *ngIf="option.title" [innerHTML]="option.title"></div>
<mat-form-field appearance="outline" [class.warning]="hasOnlyAsyncWarnings(input.errors)" [hintLabel]="limits.placeholder"
  subscriptSizing="dynamic">
  <input type="number" autocomplete="off" matInput [formControl]="input" [placeholder]="placeholder" [errorStateMatcher]="errorMatcher"
    [step]="option.isFloat ? 0.1 : 1" />

  <mat-error *ngIf="input.dirty && input.errors">
    <lib-form-control-error [control]="input" [checkTouched]="true" [errorHandlers]="errorHandlers"></lib-form-control-error>
  </mat-error>

  <div class="flex column numerical-arrows">
    <span class="material-icons up" (click)="increment()">keyboard_arrow_up</span>
    <span class="material-icons down" (click)="increment(-1)">keyboard_arrow_down</span>
  </div>
</mat-form-field>
