import { UUID } from 'io-ts-types/UUID';

import { AnswerWidgetType, isNonEmptyArray, isObject, isRealValue, OptionAnswerType } from '@tr-common';

import { StudyOption, StudyOptionType } from './study-option';
import { isStudyOption } from './study-option-widget';
import { StudyQuestion } from './study-question';

export type StudyEntityType = StudyOption | StudyQuestion;
export type TreeNodeValueType = boolean | string | number | OptionAnswerType | OptionAnswerType[];

export class TreeNode<T = TreeNodeValueType> {
  data: StudyEntityType;
  value: T;
  children: TreeNode<TreeNodeValueType>[] = [];
  questionID: string;
  dropdownOptions: StudyOptionType[] = [];
  autocompleteOptions: StudyOptionType[] = [];
  multiselectOptions: StudyOptionType[] = [];
  matrixScaleOptions?: StudyOptionType[];
  uiTestClass: string[];
  nodeIndex: number;
  parent?: TreeNode;
  parentOptionIndex: number;
  isQuestion = true;
  showHint = false;

  constructor(initData?: Partial<TreeNode>) {
    if (isObject(initData)) {
      Object.assign(this, initData);
    }
    const hasParent = isRealValue(this.parentOptionIndex);
    this.uiTestClass = this.isQuestion ? [
      isRealValue(this.nodeIndex) ? `subquestion-${this.nodeIndex}` : 'question',
      hasParent ? `subquestion-of-option-${this.parentOptionIndex}` : ''
    ] : [
      'option',
      hasParent ? this.parent.uiTestClass[0] + '-suboption' : '',
      hasParent ? 'child-option' : 'parent-option', // for auto test purposes
      `option-index-${this.isSelectableNode ? 0 : (this.nodeIndex + 1)}`,
      hasParent ? `suboption-of-group-${this.parent.nodeIndex + 1}-of-option-${this.parentOptionIndex}` : ''
    ];
  }

  get isDropDown(): boolean {
    return isNonEmptyArray(this.dropdownOptions);
  }

  get isAutocompleteOptions(): boolean {
    return isNonEmptyArray(this.autocompleteOptions);
  }

  get isMultiselectOptions(): boolean {
    return isNonEmptyArray(this.multiselectOptions);
  }

  get answerOption(): UUID | string {
    return isStudyOption(this.data) ? this.data.id : isRealValue(this.value) ? `${this.value}` : undefined;
  }

  get dataWidget(): AnswerWidgetType {
    return isStudyOption(this.data) ? this.data.widget : null;
  }

  get isSelectableNode(): boolean {
    return this.isDropDown || this.isAutocompleteOptions || this.isMultiselectOptions;
  }

  get allSelectableOptions(): StudyOptionType[] {
    return [...this.dropdownOptions, ...this.autocompleteOptions, ...this.multiselectOptions];
  }

  get hasToHaveValue(): boolean {
    return this.isSelectableNode || isNonEmptyArray(this.matrixScaleOptions) || isStudyOption(this.data);
  }

  set autocomplete(v: T) {
    const optionId = isObject(v) ? v.question_option : null;
    const foundOption = this.autocompleteOptions.find(({id}) => id === optionId) as StudyOption;

    this.data = isObject(foundOption) ? foundOption : undefined;
    this.value = v;
  }

  set multiselect(v: T) {
    this.value = v;
  }

  getSelectableWidget(optionID: string): AnswerWidgetType {
    const found = this.allSelectableOptions.find(({id}) => id === optionID);

    return isRealValue(found) ? found.widget : null;
  }
}

export const getChildrenFromTreeNode = ({children}: TreeNode): TreeNode[] => children;
