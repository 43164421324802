import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonSpinnerModule } from '../button-spinner/button-spinner.module';
import { MaterialImportsModule } from '../material-imports.module';
import { SurveyPipesModule } from '../pipes/survey-pipes.module';
import { SocietyInfoModule } from '../society-info/society-info.module';
import { ConclusionPageComponent } from './conclusion-page/conclusion-page.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { NotEligibleWrapperComponent } from './not-eligible-wrapper/not-eligible-wrapper.component';
import { SubmittedProcedureActionsComponent } from './submitted-procedure-actions/submitted-procedure-actions.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialImportsModule,
    SocietyInfoModule,
    SurveyPipesModule,
    ButtonSpinnerModule,
  ],
  declarations: [
    ConfirmationPageComponent,
    ConclusionPageComponent,
    NotEligibleWrapperComponent,
    SubmittedProcedureActionsComponent,
  ],
  exports: [
    ConfirmationPageComponent,
    ConclusionPageComponent,
    NotEligibleWrapperComponent,
    SubmittedProcedureActionsComponent
  ],
})
export class ModuleSettingsModule {}
