import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';

import { ModuleSettings } from '@tr-common';

import { Consent, SignedConsentsWithPending } from '../../../consent/models';
import { StudyConsentFacade, StudyFacade } from '../../store';

@Component({
  selector: 'lib-procedure-consent-status',
  template: `
    <ng-container *ngIf="consentsStatus$ | async as state">
      <div *ngIf="!state.pending && state.consents.length" class="consent-status-info">
        <img src="../../assets/consent_status.svg" alt="survey status">

        <lib-submitted-consent *ngIf="state.registryConsents.length" [consentFiles]="state.registryConsents"
          [content]="setting?.content"
          (review)="reviewConsent($event)" (download)="downloadConsent($event)" (print)="printConsent($event)">
          <div class="actions-content">
            <button mat-flat-button color="primary" class="survey-button is-shadowed" (click)="nextProcedure()">
              {{ setting?.content?.button_text }}
            </button>
          </div>
        </lib-submitted-consent>

        <div *ngIf="state.jaebConsents.length">
          <div [innerHtml]="consentBodyJAEB$ | async"></div>
          <div class="actions-content">
            <button mat-flat-button class="survey-button" color="primary" (click)="consent.gotoQuestionnaire()">
              Go to Questionnaire
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <lib-unavailable-consent *ngIf="!state.pending && !state.consents.length"></lib-unavailable-consent>
    </ng-container>
  `
})
export class ProcedureConsentStatusComponent implements OnInit {
  @Input() setting: ModuleSettings;
  consentsStatus$: Observable<SignedConsentsWithPending> = combineLatest([
    this.consent.consentsStatus$, this.study.fullStudyLoading$
  ]).pipe(
    map(([status, fullStudyLoading]) => ({...status, pending: status.pending || fullStudyLoading}))
  );
  consentBodyJAEB$: Observable<string> = this.consent.consentBodyJAEB$;

  constructor(
    public study: StudyFacade,
    public consent: StudyConsentFacade
  ) {}

  ngOnInit(): void {
    this.consent.loadConsents();
  }

  nextProcedure(): void {
    this.study.nextProcedure();
  }

  reviewConsent(consentId: string): void {
    this.consent.reviewConsent(consentId);
  }

  downloadConsent(consent: Consent): void {
    this.consent.downloadConsent(consent);
  }

  printConsent(consentId: string): void {
    this.consent.printConsent(consentId);
  }
}
