import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialImportsModule } from '../material-imports.module';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { NotificationToggleComponent } from './notification-toggle/notification-toggle.component';

@NgModule({
  declarations: [
    NotificationSettingsComponent,
    NotificationToggleComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialImportsModule
  ],
  exports: [
    NotificationSettingsComponent,
    NotificationToggleComponent
  ]
})
export class CommunicationModule { }
