import { isRealValue, makeSurveyUrl, MrpStage, surveyType, UserStudyType } from 'tr-lib';

import { isStudyCompletedByDashboardStatus, isStudyCoreStudy } from './dashboard';
import { DashboardExtendedStatus } from './dashboard-extended-status';
import {
  findInfoStudyStatusByNumber,
  getCurrentProgressForPromotional,
  isStudyCompletedForParticipant,
  isStudyInProgressForParticipant,
  numberUserStudyStatus2String,
  statusForClosedBySystemStudy,
  statusForCompletedStudy,
  StudyStatusStyleType,
} from './user-study';
import { getUserStudyReward } from './user-study-reward';
import { convertToWeeksOrDaysRemaining } from './expires-in';

/**
 * extendUsersStudy() creates some statuses using the documentation on the url
 * https://unitio.atlassian.net/wiki/spaces/RS/pages/2060681221
 *
 * @param usersStudy
 * @param userID
 * @return DashboardExtendedStatus
 */
export const extendUsersStudy = (usersStudy: UserStudyType, userID: string): DashboardExtendedStatus => {
  const studyID = usersStudy.study_id;
  const status = usersStudy.status;
  const isResearchOpportunity = usersStudy.study_type === surveyType.promotional_study;
  const isActiveStudy = usersStudy.study_status === 'active';
  const isStudyClosed = usersStudy.study_status === 'closed' && (status === 0 || status === statusForCompletedStudy)
    || usersStudy.study_status === 'inactive' && (status === statusForCompletedStudy || status === statusForClosedBySystemStudy)
    || status === statusForClosedBySystemStudy;
  const isNotEligibleForCoreStudy = isStudyCoreStudy(usersStudy.study_type) && usersStudy.is_eligible === false;
  const isStudyCompleted = isStudyCompletedForParticipant(status);
  // The line below was added just in case from the previous implementation extendStudy() which was in dashboard.ts
  const isCompletedByDashboardStatus = isStudyCompletedByDashboardStatus(usersStudy.dashboard_status);
  const foundInfoStudy = findInfoStudyStatusByNumber(status);
  const currentProgress = isResearchOpportunity
    ? getCurrentProgressForPromotional(usersStudy.viewed_at)
    : isNotEligibleForCoreStudy ? MrpStage.completed : numberUserStudyStatus2String(status);
  const styleClass: StudyStatusStyleType = isResearchOpportunity
    ? 'research-opportunity-progress'
    : isNotEligibleForCoreStudy ? 'completed' : foundInfoStudy?.styleClass;
  const study_reward_on = getUserStudyReward(usersStudy);
  let launchButton = isResearchOpportunity || isNotEligibleForCoreStudy ? 'View details' : foundInfoStudy?.launchButton;
  let routerLink = makeSurveyUrl('/survey', {studyID, userStudyID: usersStudy.id.toString(), userID});

  if (!isActiveStudy) {
    const isStudyInProgress = isStudyInProgressForParticipant(status);
    const isButtonShouldBePresented = isResearchOpportunity || isStudyInProgress || isStudyCompleted || usersStudy.has_intro_page;

    launchButton = isButtonShouldBePresented ? isStudyInProgress ? launchButton : 'View details' : undefined;
  }
  if (isResearchOpportunity && usersStudy.study_status === 'closed' && status === 0) {
    routerLink += '/closed-study';
  }

  return {
    hasToShowProgress: isRealValue(usersStudy.study_approximate_complete_time) && !isStudyCompleted,
    isStudyCompleted, isCompletedByDashboardStatus,
    ...usersStudy, isActiveStudy, isResearchOpportunity, isStudyClosed, currentProgress, styleClass, routerLink,
    expiresIn: convertToWeeksOrDaysRemaining(usersStudy.study_type, usersStudy.expires_in_days),
    study_reward_on, launchButton
  };
};
