import * as t from 'io-ts';

export const optionAnswerSchema = t.type({
  question_option: t.string,
  value: t.union([t.string, t.boolean])
}, 'optionAnswerSchema');
export type OptionAnswerType = t.TypeOf<typeof optionAnswerSchema>;

export const storedAnswerSchema = t.intersection([
  optionAnswerSchema,
  t.type({question: t.string}),
  t.partial({isAnswerFromOtherStudy: t.boolean})
]);
export type StoredAnswerType = t.TypeOf<typeof storedAnswerSchema>;
export type StoredAnswersType = StoredAnswerType[];

export interface ServerDateRange {
  start: string; // MM/DD/YYYY
  end: string; // MM/DD/YYYY
}

export interface Answers {
  [k: string]: StoredAnswersType
}
