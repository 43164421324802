import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { isNonEmptyString, isNullOrUndefined, isRealValue, ProcedureTemplateType } from '@tr-common';

import { ProcedureStatusType } from '../../../models';
import { ProcedureStatusState, QueryParamsIDs } from '../../models';
import { SurveyUrlFactoryProxy } from '../../services/survey-url-factory.service';
import { selectIsDonated, selectUserStudy, StudyConsentFacade, StudyFacade, StudyStatusFacade } from '../../store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lib-procedure-status',
  templateUrl: './procedure-status.component.html',
  styleUrls: ['./procedure-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcedureStatusComponent implements OnInit, OnDestroy {
  donateSuccess = false;
  isDonated = false;
  public state$: Observable<ProcedureStatusState> = combineLatest([
    this.studyStatus.procedureStatus$,
    this.study.pending$,
    this.study.fullStudyLoading$,
    this.consent.consentPending$,
    this.study.popupSettings$,
  ]).pipe(
    debounce(() => timer(100)),
    map(([
      procedure, pending, fullStudyLoading, consentPending, popupSettings
    ]) => ({
      procedure, pending: pending || consentPending || fullStudyLoading, submitPending: procedure.submitPending,
      isEligible: isRealValue(procedure.nextProcedureTitle),
      hasNotSubmitted: isNullOrUndefined(procedure.created_at),
      hasConclusionPopup: procedure.isScreening || popupSettings.length > 0
    })),
  );
  submitMode$ = this.route.data.pipe(
    map(({submitMode}) => submitMode),
    // tap(data => console.log(data)),
    filter(submitMode => submitMode),
    withLatestFrom(this.studyStatus.procedureStatus$),
    filter(([, {created_at}]) => isNonEmptyString(created_at)),
    tap(([, {id}]) => this.studyStatus.redirectToProcedureStatus(id)),
  );
  currentProcedure: ProcedureTemplateType;
  introPage$ = this.study.introPage$;
  loadModuleSettings$ = combineLatest([
    this.study.study$,
    this.route.queryParams
  ]).pipe(
    map(([{id}, {procedure}]) => ({studyID: id, procedure})),
    distinctUntilChanged(),
    filter(({procedure}) => isRealValue(procedure) && procedure !== this.currentProcedure),
    tap(({studyID, procedure}) => {
      this.currentProcedure = procedure;
      if (procedure === 'intro') {
        this.study.loadIntroPage();
      } else {
        this.study.loadModuleSettings(studyID, procedure);
      }
    })
  );
  subscription = new Subscription();

  constructor(
    public urls: SurveyUrlFactoryProxy,
    public router: Router,
    public route: ActivatedRoute,
    public studyStatus: StudyStatusFacade,
    public study: StudyFacade,
    public consent: StudyConsentFacade,
    private store: Store
  ) {
    this.subscription.add(this.loadModuleSettings$.subscribe());
    this.subscription.add(this.submitMode$.subscribe());
  }

  ngOnInit(): void {
    const {procedure} = this.route.snapshot.queryParams as QueryParamsIDs;
    if (isRealValue(procedure)) {
      this.study.setActiveProcedure(procedure);
    }

    this.subscription.add(this.store.select(selectUserStudy).subscribe(userStudy => {
      this.isDonated = userStudy?.is_donated ?? false;
    }));
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isReadyToShowConfirmationPage(procedure: ProcedureStatusType): boolean {
    return !procedure.isResearch && isRealValue(procedure.setting);
  }

  returnToDashboard(): void {
    this.router.navigate(['/', 'dashboard']).then();
  }

  handleDonate(): void {
    if(!this.isDonated){
      const donationSubscription = this.study.study$.pipe(
        tap(study => {
          this.study.aqDonate(study.id);
        })
      ).subscribe(
        () => {
          this.store.select(selectIsDonated).subscribe(isDonated => {
            this.donateSuccess = isDonated;
          });
        }
      );
  
      donationSubscription.unsubscribe();
    }
  }
  
}
