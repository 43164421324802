import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { isNonEmptyString, isRealValue, ParticipantProfile, SessionStorage, STORAGE, storageKeys } from 'tr-lib';

import { sourcesToComponent } from '@app/model/constants';
import { UserRouterService } from '@services/user.router.service';
import { UserService } from '@services/user.service';
import { UserState } from '@store/reducers';

import {
  changePasswordReconsent,
  changePasswordReconsentFail,
  changePasswordReconsentSuccess,
  completeReconsent,
  completeReconsentFail,
  completeReconsentSuccess,
  defaultUserState,
  getSMSConsent,
  getUser,
  reconsentFinishStep1,
  refreshAssentReconsentState,
  resetErrorState,
  resetState,
  updateProfileInfoError,
  updateProfileReconsent,
  updateProfileReconsentFail,
  updateProfileReconsentSuccess,
  updateProfileWhileReconsentPending,
  updateProfileWhileReconsentPendingSuccess,
} from '../actions';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AssentReconsentEffects {
  updateProfileWhileReconsentPending$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(updateProfileWhileReconsentPending),
    tap(() => this.store.dispatch(resetErrorState())),
    switchMap(({payload}) => this.userService.updateReconsentPendingProfile(payload).pipe(
      map(() => updateProfileWhileReconsentPendingSuccess({payload})),
      catchError(({status, error}) => of(status === 400 ? updateProfileInfoError({error}) : defaultUserState()))
    ))
  ));

  updateProfileWhileReconsentPendingSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfileWhileReconsentPendingSuccess),
    tap(({payload: {child_email}}) => {
      this.storage.setItem(storageKeys.child_email, child_email);
      this.storage.removeItem(storageKeys.token);
      void this.userRouter.navigateTo(['/passed-to-child']);
    }),
    map(() => resetState())
  ));

  updateProfileReconsent$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfileReconsent),
    switchMap(({payload, onSuccessPayload}) => this.userService.updateProfile(payload).pipe(
      map((resp: ParticipantProfile) => updateProfileReconsentSuccess({payload: resp, onSuccessPayload})),
      catchError(err => of(updateProfileReconsentFail({error: err.error ? err.error : err})))
    ))
  ));

  updateProfileReconsentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateProfileReconsentSuccess),
    tap(({payload}) => {
      if (isNonEmptyString(payload.new_jwt_token)) {
        this.storage.setItem(storageKeys.token, payload.new_jwt_token);
      }
    }),
    map(({onSuccessPayload}) => isRealValue(onSuccessPayload) ? changePasswordReconsent(onSuccessPayload) : reconsentFinishStep1())
  ));

  changePasswordReconsent$ = createEffect(() => this.actions$.pipe(
    ofType(changePasswordReconsent),
    switchMap(({payload}) => this.userService.changePass(payload).pipe(
      concatMap(() => [changePasswordReconsentSuccess(), reconsentFinishStep1()]),
      catchError(reject => of(changePasswordReconsentFail({error: reject.error})))
    ))
  ));

  completeReconsent$ = createEffect(() => this.actions$.pipe(
    ofType(completeReconsent),
    switchMap(() => this.userService.reconsentComplete().pipe(
      map(() => completeReconsentSuccess()),
      catchError(reject => of(completeReconsentFail({error: reject.error})))
    ))
  ));

  completeReconsentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(completeReconsentSuccess),
    map(() => refreshAssentReconsentState())
  ));

  refreshAssentReconsentState$ = createEffect(() => this.actions$.pipe(
    ofType(refreshAssentReconsentState),
    concatMap(() => [
      getUser({source: sourcesToComponent.assentReconsent, loadChildInfo: true}),
      getSMSConsent()
    ])
  ));

  constructor(
    @Inject(STORAGE) private storage: SessionStorage,
    private actions$: Actions,
    private store: Store<UserState>,
    private userService: UserService,
    private userRouter: UserRouterService,
  ) {}
}
