import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BaseChildProfileType, UserHouseholdType } from 'tr-lib';

import { profileProperty, ProfileState } from './profile.reducer';

export const profileSelector = createFeatureSelector<ProfileState>(profileProperty);
export const selectStatesOfUSA = createSelector(profileSelector, ({statesOfUSA}) => statesOfUSA);
export const selectTimeZones = createSelector(profileSelector, ({timeZones}) => timeZones);
export const selectHouseholdInfo = createSelector(
  profileSelector, ({householdInfo}): UserHouseholdType => householdInfo
);
export const selectChildInfo = createSelector(
  profileSelector, ({childInfo}): BaseChildProfileType => childInfo
);
export const selectAdditionalInfo = createSelector(
  selectHouseholdInfo, selectChildInfo,
  (householdInfo, childInfo) => ({householdInfo, childInfo})
);
