import { StoredAnswersType, isNonEmptyArray, isRealValue, isNonEmptyString } from '@tr-common';
import { DraftAnswersType } from './draft-answer';
import { StudyQuestions } from '../../models/study-question';
import { getParentQuestion, getParentQuestions } from './get-parent-questions';

const magicPhrase = 'There are not answered questions: ';

export const absentAnswerKey = 'absent-answers';

export const saveAbsentQuestions = (
  absentQuestions: string[]
): void => sessionStorage.setItem(absentAnswerKey, JSON.stringify(absentQuestions))

export const getAbsentQuestions = (): string[] => {
  const item: string = sessionStorage.getItem(absentAnswerKey);
  let outcome: string[] = [];

  if (isNonEmptyString(item)) {
    try {
      outcome = JSON.parse(item);
    } catch (e) {
      console.warn(`Could not read value from ${absentAnswerKey}`);
    }
  }

  return outcome;
}

export const cleanAbsentAnswersFromStorage = (): void => sessionStorage.removeItem(absentAnswerKey);

export const defineIfErrorHasAbsentAnswers = (error: string[]): boolean => isNonEmptyArray(error) && error.some(e => e.match(magicPhrase));

export const getErrorWithAbsentAnswers = (error: string[]): string[] => {
  const foundLine = error.find(e => e.match(magicPhrase));

  return foundLine.split(': ')[1].replace(/[\\\[{'",}\\\]]/g, '').split(' ');
};

export const getAmountOfAbsentAnswers = (): number => {
  const storedAbsentAnswers = sessionStorage.getItem(absentAnswerKey);
  let parsedAbsentAnswers: string[] = [];

  if (isRealValue(storedAbsentAnswers)) {
    try {
      parsedAbsentAnswers = JSON.parse(storedAbsentAnswers);
    } catch (e) {
      console.warn(e);
    }
  }

  return parsedAbsentAnswers?.length ?? 0;
};

export const compareWithAbsentAnswersAndUpdate = (savedAnswers: DraftAnswersType, questions: StudyQuestions): void => {
  const rootSavedQuestion = getParentQuestion(savedAnswers[0].question, questions);
  const absentAnswersInStorage = sessionStorage.getItem(absentAnswerKey);
  let absentAnswers: string[] = [];

  if (isRealValue(absentAnswersInStorage)) {
    absentAnswers = (JSON.parse(absentAnswersInStorage) as string[]).filter(questionId => questionId !== rootSavedQuestion);

    if (absentAnswers.length > 0) {
      sessionStorage.setItem(absentAnswerKey, JSON.stringify(absentAnswers));
    } else {
      cleanAbsentAnswersFromStorage();
    }
  }
  // console.log({handler: 'updateAbsentAnswers$', savedAnswers, rootSavedQuestion, questions, absentAnswers});
};
