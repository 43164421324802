import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lib-absent-answers',
  template: `
    <img src="assets/no-answer-exclamatory.svg" width="130" height="68" alt="no-answers"/>
    <div class="text-part">
      <h4 class="mbs-0 mbe-10">You have {{ counter }} unanswered question{{ wordSuffix }}!</h4>
      <p class="mbs-10 mbe-0">Please answer all questions to submit the study.</p>
    </div>
  `,
  styleUrls: ['./absent-answers.component.scss']
})
export class AbsentAnswersComponent {
  @Input() counter = 0;

  @HostBinding('class.must-show') get hasAbsentAnswers(): boolean {
    return this.counter > 0;
  }

  get wordSuffix(): string {
    return this.counter > 1 ? 's' : '';
  }
}
