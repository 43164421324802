<ng-container [formGroup]="parentForm">
  <mat-form-field appearance="outline" [floatLabel]="floatLabel">
    <mat-label>First Name</mat-label>
    <input type="text" matInput pattern="^[a-zA-Z0-9\-\u2013\u2014'\u0027]+$" formControlName="first_name" aria-label="First Name" autocomplete="given-name" aria-autocomplete="none" />
    <mat-error *ngIf="parentForm.get('first_name').hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="parentForm.get('first_name').hasError('pattern')">Enter valid first name</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" [floatLabel]="floatLabel">
    <mat-label>Last Name</mat-label>
    <input type="text" matInput pattern="^[a-zA-Z0-9\-\u2013\u2014'\u0027]+$" formControlName="last_name" aria-label="Last Name" autocomplete="family-name" aria-autocomplete="none" />
    <mat-error *ngIf="parentForm.get('last_name').hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="parentForm.get('last_name').hasError('pattern')">Enter valid last name</mat-error>
  </mat-form-field>
  <!-- preloaded icons -->
  <ng-container *ngIf="icon">
    <mat-icon class="no-display" svgIcon="email-checking"></mat-icon>
    <mat-icon class="no-display" svgIcon="email-ok"></mat-icon>
    <mat-icon class="no-display" svgIcon="email-warn"></mat-icon>
    <mat-icon class="no-display" svgIcon="email-error"></mat-icon>
  </ng-container>

  <mat-form-field class="long-error" appearance="outline" [floatLabel]="floatLabel" [class.warning]="emailWarning">
    <mat-label>Email</mat-label>
    <input id="email" matInput type="email" formControlName="email" aria-label="Email" autocomplete="email" aria-autocomplete="none"
      (focusout)="emailFocusOut.emit($event)" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()" />
    <ng-container *ngIf="icon?.value">
      <mat-icon class="email-check" [svgIcon]="'email-' + icon.value" (click)="emailAction($event, icon.value)"></mat-icon>
    </ng-container>

    <lib-info-tooltip class="for-email-field" [styles]="{width: (isMobile$ | async) ? '306px' : '345px'}" position="right-bottom">
      By changing your email, you will no longer be able to log in to the Registry using the "Continue with T1D Exchange Account" option.
      Changing your email requires you to create a new password.
      Please update your password below, and use your new email and password upon your next log in.
    </lib-info-tooltip>

    <mat-error>
      <lib-form-control-error [control]="email" checkTouched="true" [errorHandlers]="emailErrorHandlers"></lib-form-control-error>
    </mat-error>
  </mat-form-field>

  <span class="flex align-center phone-input-wrapper">
    <mat-form-field appearance="outline" [floatLabel]="floatLabel">
      <mat-label id="personal-phone-label">Mobile Phone Number</mat-label>
      <lib-phone-input class="full-width" formControlName="phone" [codes]="countryCodes" [validity]="!!infoPhone.errors"
        aria-describedby="personal-phone-label">
      </lib-phone-input>
      <mat-error *ngIf="infoPhone.errors">
        <span *ngFor="let e of infoPhone.errors?.server">{{ e }}</span>
        <span *ngIf="infoPhone.errors?.countryError">{{ infoPhone.errors?.countryError }}</span>
      </mat-error>
    </mat-form-field>
    <ng-content select="lib-info-tooltip"></ng-content>
  </span>

  <ng-content></ng-content>
</ng-container>
