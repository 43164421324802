import { FormArray, FormGroup } from '@angular/forms';
import { Moment } from 'moment/moment';

import { FormGroupProtoType } from './form-interface';
import { ValidatorType } from './validator-type';
import { isEmptyValue } from './helpers';

export interface RowA1c {
  date: string;
  a1c: number;
}
export const newA1CPair = (): RowA1c => ({date: null, a1c: null});

export interface RowA1cInWidget {
  date: Moment;
  a1c: number;
}
export const newA1cInWidgetPair = (): RowA1cInWidget => ({date: null, a1c: null});

export interface A1CRowValidator {
  date: ValidatorType;
  a1c: ValidatorType;
}

export interface A1CDataWithValidity {
  data: RowA1c[];
  validity: A1CRowValidator[];
  filled: number;
}

export type RowA1CFormType = FormGroupProtoType<RowA1cInWidget>;
export type A1CFormGroupType = {
  pairs: FormArray<FormGroup<RowA1CFormType>>;
};

export const trimA1cRows = (src: RowA1c[]): RowA1c[] => {
  let enough: boolean = false;

  return src.reduceRight<RowA1c[]>((acc, {a1c, date}) => {
    const isBothEmpty = isEmptyValue(a1c) && isEmptyValue(date);

    if (!isBothEmpty) { enough = true; }

    return (isBothEmpty && !enough) ? acc : [...acc, {a1c, date}];
  }, []).reverse();
}
