import { isEmptyString, isRealValue } from '@tr-common';

import { UserHouseholdFormType, UserHouseholdType } from './user-household';
import { ExtendedChildProfileType, ParticipantProfile } from './user-profile';

export const extractChangesFromUserProfileForm = (
  form: unknown | UserHouseholdFormType
): Partial<ParticipantProfile | UserHouseholdType | ExtendedChildProfileType> => Object.entries(form).reduce(
  (acc, [k, v]) => isRealValue(v) ? {...acc, [k]: isEmptyString(v) ? null : v} : acc, {}
);
