import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DexcomState } from './reducer';

export const dexcomStoreProperty = 'dexcom';

export const selectDexcom = createFeatureSelector<DexcomState>(dexcomStoreProperty);
export const selectDexcomStage = createSelector(
  selectDexcom,
  ({stage, error, alchemer_id, ref_id, response_id, study_code}) => ({stage, error, alchemer_id, ref_id, response_id, study_code})
);
export const selectDexcomMode = createSelector(selectDexcom, ({mode}) => mode);
export const selectDexcomStateToReachStartPage = createSelector(
  selectDexcom, ({mode, alchemer_id, ref_id, response_id, study_code}) => ({mode, alchemer_id, response_id, ref_id, study_code})
);
