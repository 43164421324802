import { Action, createReducer, on } from '@ngrx/store';
import { flatten, groupBy, isObject } from 'lodash';

import { Answers, GroupedSettingsByProcedure } from '@tr-common';

import {
  calculateSubQuestionHierarchy,
  enableProcedures,
  expandedStudyProcedures,
  IntroPageType,
  IStudyProcedure,
  MinimalStudyType,
  ParticipantDetails,
  ProcedureQuestions,
  Procedures,
  updateAfterSubmit,
  updateProceduresAfterSubmit,
  UserStudyProcedureType,
} from '../../../models';
import { StudyQuestionRuleType } from '../../../models/rules';
import { ParamsIDs, UserStudyType } from '../../models';
import {
  changeActiveProcedure,
  changeActiveQuestion,
  clearSubmittedAnswers,
  defineSourceOfDialog,
  donateAqSuccess,
  finishPending,
  finishSubmitPending,
  getSubmittedAnswersSuccess,
  getUserStudySuccess,
  loadAnswers,
  loadAnswersFromAnotherStudySuccess,
  loadAnswersSuccess,
  loadIntroPageSuccess,
  loadModuleSettingsSuccess,
  loadParticipantDetailsSuccess,
  loadStudyFull,
  loadStudyFullFail,
  loadStudyFullIfNotEligibleSuccess,
  loadStudyFullSuccess,
  loadStudyPartially,
  loadStudyPartiallyFail,
  loadStudyPartiallySuccess,
  markProceduresEnabled,
  refreshStudyQuestionsSuccess,
  registerShowingWelcomeDialogFail,
  registerShowingWelcomeDialogSuccess,
  resetAnswersSaveError,
  saveAnswers,
  saveAnswersFail,
  saveAnswersSuccess,
  setActiveSurvey,
  startRefreshPending,
  studyResetState,
  submitConsentProcedureForcibly,
  submitProcedure,
  submitProcedureSuccess,
  updateActiveSurvey,
} from '../index';

export interface SurveyState {
  activeProcedureID: string; // mirrored TO URL
  activeQuestionID: string; // mirrored TO URL
  answers: Answers;
  answersSaveError: string[];
  blockNavigation: boolean;
  fullStudyLoading: boolean;
  intro: IntroPageType;
  moduleSettings: GroupedSettingsByProcedure,
  optionSubQuestionMap: ProcedureQuestions;
  paramsIDs: ParamsIDs; // mirrored TO URL
  participant: ParticipantDetails;
  pending: boolean;
  procedures: Procedures;
  questions: ProcedureQuestions;
  refreshPending: boolean;
  rules: StudyQuestionRuleType[];
  study: MinimalStudyType;
  studyProcedures: IStudyProcedure[];
  submitPending: boolean;
  submittedAnswers: string;
  userProcedures: UserStudyProcedureType[];
  userStudy: UserStudyType;
  isDonated: boolean;
}

export const initialSurveyState: SurveyState = {
  activeProcedureID: null,
  activeQuestionID: null,
  answers: null,
  answersSaveError: null,
  blockNavigation: false,
  fullStudyLoading: true,
  intro: null,
  moduleSettings: {} as GroupedSettingsByProcedure,
  optionSubQuestionMap: null,
  paramsIDs: null,
  participant: null,
  pending: false,
  procedures: [],
  questions: null,
  refreshPending: false,
  rules: null,
  study: null,
  studyProcedures: [],
  submitPending: false,
  submittedAnswers: null,
  userProcedures: null,
  userStudy: null,
  isDonated: false
};

const reducer = createReducer(
  initialSurveyState,
  on(setActiveSurvey, (state, {payload}) => ({...state, paramsIDs: payload as ParamsIDs})),
  on(updateActiveSurvey, (state, {userStudyID}) => ({...state, paramsIDs: {...state.paramsIDs, userStudyID}})),
  on(loadParticipantDetailsSuccess, (state, action) => ({...state, participant: action.payload} as SurveyState)),
  on(getUserStudySuccess, (state, {payload}) => ({...state, userStudy: payload})),
  on(loadStudyFull, loadStudyPartially, state => ({...state, fullStudyLoading: true})),
  on(loadStudyFullFail, loadStudyPartiallyFail, state => ({...state, fullStudyLoading: false})),
  on(loadStudyFullSuccess, loadStudyFullIfNotEligibleSuccess, loadStudyPartiallySuccess, (
    state, {study, studyProcedures, userProcedures, rules, questions, userStudy}
  ) => {
    const optionSubQuestionMap = calculateSubQuestionHierarchy(questions);
    const procedures = expandedStudyProcedures(studyProcedures, userProcedures);

    return {
      ...state, study, procedures, studyProcedures, userProcedures, rules, questions, optionSubQuestionMap, userStudy,
      refreshPending: false, fullStudyLoading: false
    };
  }),
  on(refreshStudyQuestionsSuccess, (state, {payload}) => {
    const optionSubQuestionMap = calculateSubQuestionHierarchy(payload);

    return {...state, questions: payload, optionSubQuestionMap};
  }),
  on(resetAnswersSaveError, state => ({...state, answersSaveError: null})),
  on(loadAnswersSuccess, saveAnswersSuccess, (state, {payload}) => {
    const answers = isObject(state.answers)
      ? flatten(Object.values(state.answers)).filter((a) => a.isAnswerFromOtherStudy)
      : [];
    const groupedAnswers = groupBy(answers, 'question');

    return ({...state, pending: false, answersSaveError: null, answers: {...groupedAnswers, ...payload}});
  }),
  on(loadAnswersFromAnotherStudySuccess, (state, {payload}) => ({...state, answers: {...state.answers, ...payload}})),
  on(changeActiveQuestion, (state, {payload}) => ({...state, activeQuestionID: payload})),
  on(changeActiveProcedure, (state, action) => ({...state, activeProcedureID: action.payload})),
  on(saveAnswers, loadAnswers, state => ({...state, pending: true})),
  on(submitProcedure, submitConsentProcedureForcibly, state => ({...state, submitPending: true})),
  on(saveAnswersFail, (state, action) => ({...state, pending: false, answersSaveError: action.error})),
  on(finishPending, state => ({...state, pending: false})),
  on(finishSubmitPending, state => ({...state, submitPending: false})),
  on(startRefreshPending, state => ({...state, refreshPending: true})),
  on(loadIntroPageSuccess, (state, {payload}) => ({...state, intro: payload})),
  on(markProceduresEnabled, state => ({...state, procedures: enableProcedures(state.procedures, state.questions, state.answers)})),
  on(submitProcedureSuccess, (state, {payload}) => {
    const userProcedures = updateAfterSubmit(payload, state.userProcedures);
    const procedures = enableProcedures(
      updateProceduresAfterSubmit(state.procedures, userProcedures), state.questions, state.answers
    );

    return {...state, userProcedures, procedures};
  }),
  on(loadModuleSettingsSuccess, (state, {moduleSettings}) => ({...state, moduleSettings})),
  on(defineSourceOfDialog, state => ({...state, blockNavigation: true})),
  on(registerShowingWelcomeDialogSuccess, registerShowingWelcomeDialogFail, state => ({...state, blockNavigation: false})),
  on(getSubmittedAnswersSuccess, (state, {payload}) => ({...state, submittedAnswers: payload})),
  on(clearSubmittedAnswers, state => ({...state, submittedAnswers: null})),
  on(studyResetState, state => ({...initialSurveyState, moduleSettings: {...state.moduleSettings}})),
  on(donateAqSuccess, state => ({...state, isDonated: true})),
);

export const studySurveyReducer = (state: SurveyState | undefined, action: Action) => reducer(state, action);
