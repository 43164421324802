/* eslint-disable @angular-eslint/directive-selector */
import { ComponentRef, Directive, Input, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';

import { ButtonSpinnerComponent } from './button-spinner.component';

@Directive({selector: 'button[spinner]'})
export class ButtonSpinnerDirective implements OnChanges {
  @Input('spinner') loading: boolean;
  @Input() disabled: boolean;

  private readonly _element: HTMLButtonElement;
  private _spinner: ComponentRef<ButtonSpinnerComponent>;

  constructor(
    private _viewContainer: ViewContainerRef,
    private _renderer: Renderer2,
  ) {
    this._element = this._viewContainer.element.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.loading) {
      return;
    }

    if (changes.loading.currentValue) {
      this._element.disabled = true;
      this._element.classList.add('mat-loading');
      this.createSpinner();
    } else if (!changes.loading.firstChange) {
      this._element.disabled = this.disabled;
      this._element.classList.remove('mat-loading');
      this.removeSpinner();
    }
  }

  private createSpinner(): void {
    this._spinner = this._viewContainer.createComponent(ButtonSpinnerComponent);
    this._renderer.appendChild(this._element, this._spinner.location.nativeElement);
  }

  private removeSpinner(): void {
    this._spinner.destroy();
    this._spinner = null;
  }
}
