import { StudyQuestions } from './study-question';

export interface ProcedureQuestions {
  [p: string]: StudyQuestions
}

export const calculateSubQuestionHierarchy = (questions: ProcedureQuestions): ProcedureQuestions => {
  const all = Object.values(questions).reduce((a, c) => [...a, ...c], []);
  const subQuestions = all.filter(q => q.parent_option_id);

  return subQuestions.reduce((a, c) => {
    const aElement = a[c.parent_option_id] || [];
    aElement.push(c);
    return {...a, [c.parent_option_id]: aElement}
  }, {});
}
