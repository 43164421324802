import { Component } from '@angular/core';

import { BaseCheckboxAndRadioComponent } from '../../helpers';

@Component({
  selector: 'lib-radio-widget',
  template: `
    <mat-radio-group [value]="checked" (change)="onChange($event.value)">
      <mat-radio-button color="primary" [aria-label]="option.title" [value]="true">
        <span [innerHTML]="option.title"></span>
      </mat-radio-button>
    </mat-radio-group>
  `
})
export class RadioWidgetComponent extends BaseCheckboxAndRadioComponent {
  constructor() {
    super();
  }
}
