import { ValidationErrors } from '@angular/forms';

import { isObject, ValidatorType } from '@tr-common';

export const asyncErrors = ['asyncErrors', 'asyncWarnings'] as const;
export type AsyncMessageType = typeof asyncErrors[number];

export interface WidgetValidationErrors {
  [key: string]: AsyncMessageType;
}

const hasAsyncWarnings = (errors: ValidationErrors): boolean => isObject(errors) && Object.values(errors).includes('asyncWarnings');

export const hasAsyncErrors = (errors: ValidationErrors): boolean => isObject(errors) && Object.values(errors).includes('asyncErrors');

export const hasOnlyAsyncWarnings = (errors: ValidationErrors): boolean => hasAsyncWarnings(errors) && !hasAsyncErrors(errors);

export const getAsyncMessages = (
  errors: ValidationErrors, type: AsyncMessageType
): string[] => isObject(errors) ? Object.keys(errors).filter(error => errors[error] === type).map(key => key) : [];

// this function maps only warnings to VALID
export const checkAsyncWarnings = (
  data: ValidatorType, errors: ValidationErrors
): ValidatorType => data === 'INVALID' && hasOnlyAsyncWarnings(errors) ? 'VALID' : data;
