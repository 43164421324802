import { AutofillMonitor } from '@angular/cdk/text-field';
import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Either, isLeft, left, right } from 'fp-ts/Either';
import * as t from 'io-ts';
import { combineLatest, of, pipe } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { isObject } from './helpers';
import { isRegistryProd } from './registry-prod';

export class SchemaValidationError extends Error {
  constructor(readonly errors: t.Errors) {
    super();
  }
}

export const validateAlwaysSchema = <T extends t.Any>(schema: T, name?: string) => (data: t.TypeOf<T>): t.TypeOf<T> => {
  const maybeDecoded = schema.decode(data);
  if (isLeft(maybeDecoded)) {
    console.error(maybeDecoded.left, name);
    throw new SchemaValidationError(maybeDecoded.left)
  } else {
    return maybeDecoded.right;
  }
};

export const validateSchema = <T extends t.Any>(schema: T, name?: string) => (data: t.TypeOf<T>): t.TypeOf<T> => {
  if ('tr_enable_schema_validation' in window && (window as any).tr_enable_schema_validation === false) {
    return data
  }
  if (isRegistryProd()) {
    return data;
  }
  const maybeDecoded = schema.decode(data);
  if (isLeft(maybeDecoded)) {
    console.error(maybeDecoded.left, name);
    throw new SchemaValidationError(maybeDecoded.left)
  } else {
    return maybeDecoded.right;
  }
};

export const schemaValidationPipe = <T extends t.Any>(a: T, name?: string) => map(validateSchema(a, name));

export const schemaValidationAlwaysPipe = <T extends t.Any>(a: T, name?: string) => map(validateAlwaysSchema(a, name));
/**
 * Detects if inputs were autofilled and remove validators in that case. If user change autofilled data then validation enables again
 *
 * @param autofillMonitor - AutofillMonitor service
 * @param form
 * @param validators - validators to set again on controls after user change autofilled data
 * @param elements - view's elements used by Angular Form
 */
export const disableValidationOnAutofill = (
  autofillMonitor: AutofillMonitor,
  form: UntypedFormGroup,
  validators: {[k: string]: ValidatorFn},
  elements: ElementRef<HTMLElement>[]
) => combineLatest(
  elements.filter(el => isObject(el)).map((el) => autofillMonitor.monitor(el))
).pipe(
  finalize(() => {elements.forEach(el => autofillMonitor.stopMonitoring(el))}),
  map((autofilledStatuses) => autofilledStatuses.reduce((acc, curr) => acc && curr.isAutofilled, true)),
  tap((isAutoFilled) => {
    for (const controlName of Object.getOwnPropertyNames(form.controls)) {
      if (!form.controls.hasOwnProperty(controlName)) return;
      if (isAutoFilled) {
        form.controls[controlName].clearValidators();
      } else {
        form.controls[controlName].setValidators(validators[controlName]);
      }
      form.controls[controlName].updateValueAndValidity();
    }
  }),
);

export const validateSchemaWithExplicitError = <T extends t.Any, E>(a: T, name: string = null) => pipe(
  schemaValidationPipe(a, name),
  map(res => right(res)),
  catchError((e: E) => of(left(e))),
);

export const validateSchemaAlwaysWithExplicitError = <T extends t.Any, E>(a: T, name: string = null) => pipe(
  schemaValidationAlwaysPipe(a, name),
  map(res => right(res)),
  catchError((e: E) => of(left(e))),
);
export type MaybeApiError<T> = Either<SchemaValidationError | HttpErrorResponse, T>;
