import { Store } from '@ngrx/store';

import { Consent } from '../../consent/models';
import { IProcedure, ParticipantDetails } from '../../models';
import { loadIntroPage, preselectConsent } from '../store';
import { SurveyPreselectType } from './survey-preselect';

export const checkIsIntroOrConsentCompletedThenNext = (
  selectType: SurveyPreselectType, procedure: IProcedure, consents: Consent[], participant: ParticipantDetails, store: Store<any>
): void => {
  if (procedure.isIntro) {
    store.dispatch(loadIntroPage({checkAndRedirectToStatus: true}));
  } else if (procedure.isConsent) {
    store.dispatch(preselectConsent());
  }
};
