import { answerWidget, isEmptyValue, isRealValue, OptionAnswerType, ValidatorType } from '@tr-common';

import { StudyOptionType } from '../../models/study-option';

export const getValueForAutocomplete = (storedAnswer: OptionAnswerType, options: StudyOptionType[]): string => {
  let initValue: string = null;

  if (isRealValue(storedAnswer)) {
    const {question_option, value} = storedAnswer;
    const foundOption = options.find(({id}) => id === question_option);

    if (isRealValue(foundOption)) {
      initValue = foundOption.widget === answerWidget.autocompleteText ? value.toString() : foundOption.title;
    }
  }

  return initValue;
};

export const getValidity = (value: string, options: StudyOptionType[], hasAutocompleteText: boolean): ValidatorType => {
  let isValid = isEmptyValue(value);

  if (!isValid) {
    const isFoundInOptions = options.findIndex(option => option.title === value) >= 0;

    isValid = isFoundInOptions || hasAutocompleteText;
  }

  return isValid ? 'VALID' : 'INVALID';
}
