import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { pdfDownload, pdfPrint } from '@tr-common';

import { REGISTRY_PARTICIPANT_URL } from '../../global/tokens';

@Injectable({providedIn: 'root'})
export class SurveyPdfService {
  document: Document;

  constructor(
    @Inject(REGISTRY_PARTICIPANT_URL) private participantUrl: string,
    @Inject(DOCUMENT) globalDocument: Document,
    private http: HttpClient,
  ) {
    this.document = globalDocument;
  }

  loadHTMLVersion(userId: string, userStudyId: number | string, procedure: string): Observable<string> {
    const url = `${this.participantUrl}/${userId}/user-studies/${userStudyId}/procedures/${procedure}/answers_doc?document_format=html`;

    return this.http.get<string>(url);
  }

  loadPDFVersion(userId: string, userStudyId: number | string, procedure: string): Observable<HttpResponse<Blob>> {
    const url = `${this.participantUrl}/${userId}/user-studies/${userStudyId}/procedures/${procedure}/answers_doc?document_format=pdf`;

    return this.http.get(url, {observe: 'response', responseType: 'blob'});
  }

  downloadPDFVersion(filename: string, payload: Blob): void {
    pdfDownload(filename, payload, this.document);
  }

  printPDFVersion(payload: Blob): void {
    pdfPrint(payload, this.document);
  }
}
