import { get, pick } from 'lodash';
import { isMoment, Moment, MomentInput } from 'moment';

import {
  Answers,
  answerWidget,
  commonMomentFormats,
  DatesFromProfileType,
  getDateMask,
  isDateWidget,
  isNonEmptyArray,
  isNonEmptyString,
  isNumberWidget,
  isObject,
  isRealValue,
  isWidgetHasDateField,
  LeftValueFormat,
  LeftValueFormatType,
  OptionAddressV2Type,
  RightValueSource,
  RightValueSourceType,
  RowA1c,
  StoredAnswersType,
  StudyOptionValidatorActionType,
  StudyOptionValidatorOperatorsType,
  StudyUuid,
  WidgetsToValidateType,
  widgetToLValueFormatMap,
} from '@tr-common';

import { compare2Moments, parseDate } from './date-helper';
import { compare2Values } from './rules';
import { StudyOptionCalcDate } from './study-option-calc-date';
import { AllValueTypesToValidate, DateRangeType, SimpleValueType } from './study-option-types';
import { StudyOptionValidatorType } from './study-validator-schema';
import { isDateInComboType, WidgetFieldsType } from './widget-fields';

declare const moment: (x?: MomentInput, f?: string | string[]) => Moment;

export class StudyOptionValidator extends StudyUuid implements StudyOptionValidatorType, OptionAddressV2Type {
  stored_study_id = undefined;
  stored_question_id = undefined;
  stored_option_id = undefined;
  option: string = undefined;
  read_only = false;
  label: string = null;
  message: string = null;
  action: StudyOptionValidatorActionType = 'warning';
  l_option_widget: WidgetsToValidateType = null;
  l_widget_fields: WidgetFieldsType = undefined;
  l_value_format: LeftValueFormatType = LeftValueFormat.string;
  operator: StudyOptionValidatorOperatorsType = 'ne';
  skip_if_no_stored_answer = true;
  profile_field = null;
  dynamicValue: StudyOptionCalcDate = null;
  _rValueSource: RightValueSourceType = 'static';
  private _rValueStatic: string | number;
  private _rDateValue: Moment = null;

  constructor(data?: Partial<StudyOptionValidatorType>, public setOfDates?: Partial<DatesFromProfileType>) {
    super();
    if (isObject(data)) {
      Object.assign(this, data);
      this.l_value_format = widgetToLValueFormatMap[this.l_option_widget];
    }
  }

  get isWidgetAsComboType(): boolean {
    return [answerWidget.a1cDynamic, answerWidget.dateRange].some(w => w === this.l_option_widget);
  }

  get isNumberType(): boolean {
    return isNumberWidget(this.l_option_widget) || (this.isWidgetAsComboType && !isDateInComboType(this.l_widget_fields));
  }

  get isDateType(): boolean {
    return isDateWidget(this.l_option_widget) || (this.isWidgetAsComboType && isDateInComboType(this.l_widget_fields));
  }

  set r_value_source(newSource: RightValueSourceType) {
    switch (newSource) {
      case 'static':
        this.initStoredAnswer();
        [this.dynamicValue, this.profile_field] = new Array(2).fill(null);
        break;
      case 'dynamic':
        this.initStoredAnswer();
        [this.dynamicValue, this.profile_field] = [new StudyOptionCalcDate(), null];
        break;
      case 'profile_field':
        this.initStoredAnswer();
        [this.dynamicValue, this.profile_field] = [null, 'created_at'];
        break;
      case 'stored_answer':
        this.dynamicValue = null;
        break;
    }
    this._rValueSource = newSource;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get r_value_source(): RightValueSourceType {
    return this._rValueSource;
  }

  get isRightDateValueValid(): boolean {
    return isMoment(this._rDateValue) && this._rDateValue.isValid();
  }

  set r_value_static(newValue: string | number) {
    this._rValueStatic = (this.isNumberType && isRealValue(newValue)) ? +newValue : newValue;
    if (this.isDateType) {
      this._rDateValue = isRealValue(this._rValueStatic) ? moment(this._rValueStatic, commonMomentFormats).clone() : null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get r_value_static(): string | number {
    return this._rValueStatic;
  }

  set r_value_dynamic(newDynamicValue: string) {
    const isBirthday = newDynamicValue?.match(/birthday/);
    const sourceDate = isBirthday ? this.setOfDates?.birthday : undefined;
    const momentToCompare = () => moment(sourceDate);

    this.dynamicValue = isNonEmptyString(newDynamicValue)
      ? new StudyOptionCalcDate(newDynamicValue, getDateMask(this.l_option_widget), momentToCompare)
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get r_value_dynamic(): string {
    return this.dynamicValue instanceof StudyOptionCalcDate ? this.dynamicValue.toString() : null;
  }

  serialize(): StudyOptionValidatorType {
    return pick(this, [
      'id', 'option', 'read_only', 'stored_study_id', 'stored_question_id', 'stored_option_id',
      'label', 'message', 'action', 'l_option_widget', 'l_widget_fields', 'operator',
      'r_value_source', 'r_value_dynamic', 'r_value_static', 'profile_field'
    ]);
  }

  initStoredAnswer(): void {
    [this.stored_study_id, this.stored_question_id, this.stored_option_id] = new Array(3).fill(null);
  }

  makeComparison(value: SimpleValueType | SimpleValueType[]): boolean {
    return Array.isArray(value) ? value.some(v => this.makeSingleComparison(v)) : this.makeSingleComparison(value);
  };

  getLeftValueToCompare(value: AllValueTypesToValidate): SimpleValueType | SimpleValueType[] {
    let lValue: SimpleValueType | SimpleValueType[];

    switch (this.l_value_format) {
      case LeftValueFormat.jsonA1C:
        const [field] = isNonEmptyArray(this.l_widget_fields) ? this.l_widget_fields : [];
        lValue = field === 'date' ? value as Moment : (value as RowA1c).a1c as number;
        break;
      case LeftValueFormat.jsonDate:
        lValue = typeof value === 'string' ? parseDate(value) : (value as Moment).clone();
        break;
      case LeftValueFormat.jsonStart:
        lValue = (value as DateRangeType).beginDate as Moment;
        break;
      case LeftValueFormat.jsonEnd:
        lValue = (value as DateRangeType).endDate as Moment;
        break;
      case LeftValueFormat.jsonStartEnd:
        lValue = [(value as DateRangeType).beginDate as Moment, (value as DateRangeType).endDate as Moment];
        break;
      default:
        lValue = value as SimpleValueType;
    }

    return lValue;
  }

  ifValueMatchesCondition(value: AllValueTypesToValidate, answers?: Answers): boolean {
    const lValue = this.getLeftValueToCompare(value);
    let result = false;

    switch (RightValueSource[this.r_value_source]) {
      case RightValueSource.static:
        result = this.makeComparison(lValue);
        break;
      case RightValueSource.stored_answer:
        if (isRealValue(answers)) {
          this.lookupAndSetStaticValue(answers);
        }
        result = isRealValue(this.r_value_static) ? this.makeComparison(lValue) : !this.skip_if_no_stored_answer;
        break;
      case RightValueSource.profile_field:
        this.r_value_static = get(this.setOfDates, this.profile_field);
        result = this.makeComparison(lValue);
        break;
      case RightValueSource.dynamic:
        this.r_value_static = this.dynamicValue.value;
        result = this.makeComparison(lValue);
        break;
      default:
        throw new Error(this.r_value_source + ' is not implemented yet');
    }

    return result;
  }

  lookupAndSetStaticValue(answers: Answers): void {
    const foundAnswer: StoredAnswersType = answers[this.stored_question_id] ? answers[this.stored_question_id] : null;

    if (isRealValue(foundAnswer)) {
      const option = foundAnswer.find(a => a.question === this.stored_question_id && a.question_option === this.stored_option_id);

      if (isRealValue(option)) {
        this.r_value_static = option.value as string;
      }
    }
  }

  private ifValueMatchesConditionWhenDate(value: string | Moment) {
    const lValue = typeof value === 'string' ? parseDate(value) : value;
    const rValue = this._rDateValue;

    return compare2Moments(lValue, this.operator, rValue);
  }

  private makeSingleComparison(value: SimpleValueType): boolean {
    return isWidgetHasDateField(this.l_option_widget)
      ? this.ifValueMatchesConditionWhenDate(value as Moment)
      : compare2Values(value as string | number, this.operator, this.r_value_static);
  }
}
