import { Component } from '@angular/core';

@Component({
  selector: 'app-back-button',
  template: `
    <div class="wrapper">
      <a mat-flat-button color="secondary" class="is-with-icon is-squared">
        <mat-icon svgIcon="close"></mat-icon>
      </a>
      <p><ng-content></ng-content></p>
    </div>
  `,
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {}
