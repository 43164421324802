import { FormGroup } from '@angular/forms';
import * as t from 'io-ts';

import { getSmsEmailToggleForm, UserNotificationForm, userNotificationSchema, UserNotificationType } from 'tr-lib';

export const participantNotificationSchema = t.intersection([
  userNotificationSchema,
  t.type({
    sms_opted_out: t.boolean
  })
], 'participantNotificationSchema');
export type ParticipantNotificationType = t.TypeOf<typeof participantNotificationSchema>;
export type ParticipantNotificationUpdateType = UserNotificationType;

export interface ParticipantNotificationState extends ParticipantNotificationType {
  smsDisabled: boolean
}

export const getUserNotificationForm = (): FormGroup<UserNotificationForm> => new FormGroup<UserNotificationForm>({
  reminders_new_ro_and_studies: getSmsEmailToggleForm(),
  reminders_ongoing_studies: getSmsEmailToggleForm(),
  newsletter: getSmsEmailToggleForm(),
  management_resources: getSmsEmailToggleForm(),
});
