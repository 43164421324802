import { keys } from 'lodash';

import { isNonEmptyString, ValueOf } from '@tr-common';

export interface ParamsIDs {
  userID: string;
  studyID: string;
  userStudyID: string;
}

export const paramsIDs = {
  studyID: 'studyID' as 'studyID',
  userStudyID: 'userStudyID' as 'userStudyID',
  userID: 'userID' as 'userID',
} as const;
export type paramsIDsType = typeof paramsIDs;
export const paramsIDsArray = keys(paramsIDs) as ValueOf<paramsIDsType>[];

export interface AllIDs extends ParamsIDs {
  procedure?: string;
  questionID?: string;
  preview?: string;
}

export interface QueryParamsIDs {
  procedure?: string;
  questionId?: string;
  preview?: string;
}

export const mergeAllIDs = (params: ParamsIDs, queryParams: QueryParamsIDs): AllIDs => ({
  userID: params.userID as string,
  studyID: params.studyID as string,
  userStudyID: params.userStudyID as string,
  procedure: queryParams.procedure as string,
  questionID: queryParams.questionId as string,
  preview: queryParams.preview as string,
});

export const hasAllParamIDs = (paramsIDs: ParamsIDs): boolean => isNonEmptyString(paramsIDs?.studyID)
  && isNonEmptyString(paramsIDs?.userStudyID)
  && isNonEmptyString(paramsIDs?.userID);

export const getSurveyRootPath = (
  rootUrl: string, {studyID, userID, userStudyID}: ParamsIDs
): string[] => [rootUrl, 'study', studyID, 'user-study', userStudyID, 'user', userID];

export const getProcedureSubmitPath = (
  rootUrl: string, paramsIDs: ParamsIDs
): string[] => [...getSurveyRootPath(rootUrl, paramsIDs), 'procedureSubmit'];

export const makeSurveyPath = (rootUrl: string, paramIDs: ParamsIDs, isStatus = false): string[] => [
  ...getSurveyRootPath(rootUrl, paramIDs), ...(isStatus ? ['procedureStatus'] : [])
];

export const getSurveyRootUrl = (
  rootUrl: string, {studyID, userStudyID, userID}: AllIDs
): string => `${rootUrl}/study/${studyID}/user-study/${userStudyID}/user/${userID}`;

export const makeSurveyUrl = (
  rootUrl: string, paramsIDs: ParamsIDs, isStatus = false
): string => getSurveyRootUrl(rootUrl, paramsIDs) + (isStatus ? '/procedureStatus' : '');
