import { isEmptyValue, navigationKeys } from '@tr-common';

export const filterPhoneNumber = (phoneNumber: string): string => {
  const dirtyRegexp = /[^+\d]/g;
  const isPhoneNumberHasExtraSymbols = dirtyRegexp.test(phoneNumber);

  return isPhoneNumberHasExtraSymbols ? phoneNumber?.replace(dirtyRegexp, '') : phoneNumber;
};

export const isKeyNumberOrPlus = (key: string): boolean => /[0-9+]/.test(key);

export const isKeyAllowedForPhoneNumber = (
  e: KeyboardEvent
): boolean => isKeyNumberOrPlus(e.key) ||
  navigationKeys.indexOf(e.key) > -1 || // Allow: Delete, Backspace, Tab, Escape, Enter, etc
  (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
  (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
  (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
  (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
  (e.key === 'Insert' && (e.shiftKey === true || e.ctrlKey === true)) || // Shift+Insert or Ctrl+Insert
  (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
  (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
  (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
  (e.key === 'x' && e.metaKey === true);   // Cmd+X (Mac)
