import * as t from 'io-ts';

export const studyEditSaveSchema = t.intersection([
  t.type({
    disabled: t.boolean,
    invalid: t.boolean
  }),
  t.partial({
    collapsed: t.boolean,
    modifiedField: t.string
  })
], 'studyEditSaveSchema');
export type StudyEditSaveType = t.TypeOf<typeof studyEditSaveSchema>;
