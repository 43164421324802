import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDateRangePicker } from '@angular/material/datepicker';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';

import {
  FormControlErrorHandler,
  FormGroupProtoType,
  fullDateFormats,
  isEmptyValue,
  isNullOrUndefined,
  isRealValue,
  ValidatorType,
} from '@tr-common';

import {
  asyncErrors,
  checkAsyncWarnings,
  getDateRangeValue,
  isRangeFilled,
  matchOtherValidator,
  MatDateRange,
  rangeCheck,
  serverDateRangeToDateRange,
  SourceChange,
  SourceChangeType,
  WidgetModify,
  WidgetPrototype,
} from '../../../models';
import { StudyOption } from '../../../models/study-option';

// According to https://material.angular.io/components/datepicker/overview#date-range-selection
@Component({
  selector: 'lib-date-range-widget',
  template: `
    <div class="hint">Select dates</div>

    <mat-form-field floatLabel="always" appearance="outline" class="no-errors">
      <mat-label *ngIf="option?.title">{{ option.title }}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="dateRangePicker">
        <input #startDate matStartDate formControlName="start" placeholder="MM/DD/YYYY" />
        <input #endDate matEndDate formControlName="end" placeholder="MM/DD/YYYY" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker (closed)="afterCloseEmit()"></mat-date-range-picker>
    </mat-form-field>

    <div *ngIf="range.dirty && hasErrors" class="field-error">
      <lib-form-control-error [control]="range" [checkTouched]="true" [errorHandlers]="errorHandlers"></lib-form-control-error>
    </div>
  `,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: fullDateFormats},
  ],
  styleUrls: ['./date-range-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangeWidgetComponent implements WidgetPrototype<string>, OnChanges, AfterViewInit, OnDestroy {
  static nextId = 0;
  @ViewChild('dateRangePicker') dateRangePicker: MatDateRangePicker<unknown>;
  @ViewChild('startDate', {static: true}) startDateInput: ElementRef<HTMLInputElement>;
  @ViewChild('endDate', {static: true}) endDateInput: ElementRef<HTMLInputElement>;
  @Input() option: StudyOption;
  @Input() value: string;
  @Input() asyncValidators: AsyncValidatorFn[];
  @Output() modify = new EventEmitter<WidgetModify<string>>();
  id = `drw-${++DateRangeWidgetComponent.nextId}`;
  range = new FormGroup<FormGroupProtoType<MatDateRange>>({
    start: new FormControl<Moment>(null, {validators: Validators.required, updateOn: 'blur'}),
    end: new FormControl<Moment>(null, {
      validators: Validators.compose([Validators.required, matchOtherValidator('start', rangeCheck)]),
      updateOn: 'blur'
    })
  });
  valid: ValidatorType = 'default';
  subscription = new Subscription();
  readonly errorHandlers: FormControlErrorHandler[] = [{
    mustBeShowed: (f: FormControl<unknown> | FormGroup<FormGroupProtoType<MatDateRange>>): boolean => {
      const isDateRangePickerNotOpened = !this.dateRangePicker?.opened;
      let outcome = false;

      if (isDateRangePickerNotOpened) {
        const {start, end} = (f as FormGroup<FormGroupProtoType<MatDateRange>>).controls;
        const hasErrorInStartDate = isRealValue(start?.errors);
        const hasErrorInEndDate = isRealValue(end?.errors);
        let hasAsyncErrors = false;

        if (isRealValue(f.errors)) {
          hasAsyncErrors = Object.values(f.errors).some(asyncError => asyncErrors.includes(asyncError));
        }
        outcome = (isRealValue(f.errors) && !hasAsyncErrors) || hasErrorInStartDate || hasErrorInEndDate;
      }

      return outcome;
    }, message: 'Invalid range.'
  }];

  constructor() {
    this.subscription.add(
      this.range.statusChanges.pipe(
        map(x => checkAsyncWarnings(x as ValidatorType, this.range.errors))
      ).subscribe((status: ValidatorType) => {
        this.valid = status;
        if (!this.dateRangePicker?.opened) {
          this.emitState();
        }
      })
    );
  }

  @HostListener('click') widgetClick = () => this.emitState(SourceChange.click);
  @HostListener('window:scroll', ['$event']) windowScroll() {
    if (isRealValue(this.dateRangePicker)) this.dateRangePicker.close();
  }
  @HostBinding('class.invalid') get isValid() { return this.valid === 'INVALID'; }

  get hasErrors(): boolean {
    const {start, end} = this.range.controls;

    return isRealValue(this.range.errors) || isRealValue(start.errors) || isRealValue(end.errors);
  }

  ngOnChanges({option, value, asyncValidators}: SimpleChanges): void {
    const noEmit = {emitEvent: false};

    if (isRealValue(asyncValidators)) {
      this.range.setAsyncValidators(this.asyncValidators);
    }
    if (isRealValue(value)) {
      const isValueEmpty = isEmptyValue(this.value);
      const isPreviousTheSame = !isValueEmpty && value.currentValue === value.previousValue;
      const dateRange = isValueEmpty ? null : serverDateRangeToDateRange(this.value);
      const ifRangeFilled = isRangeFilled(dateRange);

      if (!isPreviousTheSame) {
        this.range.patchValue(dateRange, noEmit);
      }
      if (!isValueEmpty) {
        this.range.markAllAsTouched();
        this.range.markAsDirty();
        this.range.updateValueAndValidity(noEmit);
      }
    }
  }

  ngAfterViewInit() {
    const {start, end} = this.range.value;

    if (isNullOrUndefined(start) || !start.isValid()) {
      this.startDateInput.nativeElement.focus();
    } else if (isNullOrUndefined(end) || !end.isValid()) {
      this.endDateInput.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  afterCloseEmit(): void {
    this.emitState();
  }

  emitState(source: SourceChangeType = SourceChange.user): void {
    const value = getDateRangeValue(this.range.value);

    if (this.valid === 'VALID' && !this.range.valid) {
      this.valid = 'INVALID';
    }
    this.modify.emit({value, valid: this.valid, source, id: this.id});
  }
}
