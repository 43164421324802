import { uniq } from 'lodash';

import { IStudyIDs } from './survey';

export interface UserStudiesOptions {
  userStudies: number[];
  options: string[];
}

export const transform2UserStudiesAndOptions = (allUserStudies: IStudyIDs[]): UserStudiesOptions => {
  const filtered = allUserStudies.filter(
    ({user_study_id}) => typeof user_study_id === 'number'
  );
  const userStudies: number[] = uniq(filtered.map(({user_study_id}: IStudyIDs) => user_study_id as number));
  const options: string[] = filtered.map(({optionId}: IStudyIDs) => optionId);

  return {userStudies, options};
};
