import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';

import { DialogContentType } from '../../models';

@Component({
  selector: 'lib-welcome-dialog',
  template: `
    <img src="/assets/modal-welcome.svg" alt="welcome">
    <h3 [innerHTML]="title"></h3>
    <div class="inner-text" [innerHTML]="body"></div>
    <div>
      <button class="dialog-button is-shadowed" mat-flat-button color="primary" [mat-dialog-close]="true">Begin</button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {
  title = `<h3>Let's start</h3>`;
  body = `<p>Please answer the following screening questions so that we can confirm you are eligible to take part in the study.</p>`;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogContentType) {}

  ngOnInit(): void {
    this.title = get(this.data, 'title', this.title);
    this.body = get(this.data, 'body', this.body);
  }
}
