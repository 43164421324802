import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-wrapper-with-back-button',
  encapsulation: ViewEncapsulation.None,
  template: `
    <header>
      <app-back-button (click)="router.navigate(['/dashboard'])">Close</app-back-button>
      <lib-contact-info></lib-contact-info>
    </header>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./content-wrapper-with-back-button.component.scss']
})
export class ContentWrapperWithBackButtonComponent {
  constructor(public router: Router) {}
}
