import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { isRealValue } from '@tr-common';

@Component({
  selector: 'lib-tag',
  standalone: true,
  template: `
    <div class="flex align-center tag-wrapper" [ngClass]="{flat, hold}">
      <span class="button-title">{{ displayType }}</span>
      <span class="cancel-circle" (click)="cancel.emit()"><mat-icon svgIcon="cancel-ic-msg"></mat-icon></span>
    </div>
  `,
  imports: [ CommonModule, MatIconModule ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnChanges {
  @Input() text: string;
  @Input() flat = false;
  @Input() hold = false;
  @Output() cancel = new EventEmitter<void>();
  displayType: string;

  ngOnChanges({text, flat}: SimpleChanges): void {
    if (isRealValue(text)) {
      this.displayType = this.text.split('_').join(' ');
    }
    if (isRealValue(flat)) {
      this.flat = coerceBooleanProperty(this.flat);
    }
  }
}
