import * as t from 'io-ts';

import { StoredAnswerType } from './answers';
import { ValueOf } from './helpers';

/**
 * sync with {@link answerWidgetsSchema}
 */
export const answerWidget = {
  a1cDynamic: 'a1c_dynamic',
  autocompleteItem: 'autocomplete_item',
  autocompleteText: 'autocomplete_text',
  multiselectItem: 'multiselect_item',
  multiselectText: 'multiselect_text',
  date: 'date',
  dateRange: 'date_range',
  dropdown: 'drop_down',
  float: 'numerical_float',
  label: 'label',
  likertScale: 'likert_scale',
  matrixScale: 'matrix_scale',
  numerical: 'numerical',
  option: 'option',
  optionWithText: 'option_with_text',
  radio: 'radio',
  radioWithText: 'radio_with_text',
  shortDate: 'date_short',
  text: 'text',
  zip: 'zip_code',
} as const;
export type AnswerWidgetType = ValueOf<typeof answerWidget>;
export type WidgetsArrayType = AnswerWidgetType[];

export interface AnswerWidgetsInterface {
  id: AnswerWidgetType;
  label: string;
  icon: string
}
/**
 * sync with {@link answerWidget}
 */
export const answerWidgetsSchema = t.union([
  t.literal(answerWidget.a1cDynamic),
  t.literal(answerWidget.autocompleteItem),
  t.literal(answerWidget.autocompleteText),
  t.literal(answerWidget.multiselectItem),
  t.literal(answerWidget.multiselectText),
  t.literal(answerWidget.date),
  t.literal(answerWidget.dateRange),
  t.literal(answerWidget.dropdown),
  t.literal(answerWidget.float),
  t.literal(answerWidget.label),
  t.literal(answerWidget.likertScale),
  t.literal(answerWidget.matrixScale),
  t.literal(answerWidget.numerical),
  t.literal(answerWidget.option),
  t.literal(answerWidget.optionWithText),
  t.literal(answerWidget.radio),
  t.literal(answerWidget.radioWithText),
  t.literal(answerWidget.shortDate),
  t.literal(answerWidget.text),
  t.literal(answerWidget.zip),
], 'answerWidgetsSchema');
export type AnswerWithWidgetType = StoredAnswerType & {widget: AnswerWidgetType};

const dateWidgetsArray: WidgetsArrayType = [answerWidget.shortDate, answerWidget.date, answerWidget.dateRange];

export const isWidgetCanHaveSubQuestion = (widget: AnswerWidgetType): boolean => {
  const selectWidgets: WidgetsArrayType = [answerWidget.radio, answerWidget.option];

  return selectWidgets.includes(widget);
};

export const isSelectWidgetsPresented = (widget: AnswerWidgetType): boolean => [
  answerWidget.dropdown,
  answerWidget.autocompleteItem, answerWidget.autocompleteText,
  answerWidget.multiselectItem, answerWidget.multiselectText,
  answerWidget.matrixScale
].some(w => w === widget);

export const isTextWidget = (widget: AnswerWidgetType): boolean => [
  answerWidget.text, answerWidget.radioWithText, answerWidget.optionWithText
].some(w => w === widget);

export const isNumberWidget = (
  widget: AnswerWidgetType
): boolean => ([answerWidget.numerical, answerWidget.float] as WidgetsArrayType).includes(widget);
export const isDateWidget = (widget: AnswerWidgetType): boolean => dateWidgetsArray.includes(widget);
export const getDateMask = (
  widget: AnswerWidgetType = answerWidget.shortDate
): string => widget === 'date_short' ? 'YYYY-MM' : 'YYYY-MM-DD';

export const isWidgetHasDateField = (
  widget: AnswerWidgetType
): boolean => [...dateWidgetsArray, answerWidget.a1cDynamic].includes(widget);
