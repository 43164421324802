<ng-container [formGroup]="parentForm">
  <mat-form-field appearance="outline" [floatLabel]="floatLabel">
    <mat-label>Child First Name</mat-label>
    <input matInput formControlName="child_first_name" aria-label="Child First Name" autocomplete="off">
    <mat-error>This field is required</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" [floatLabel]="floatLabel">
    <mat-label>Child Last Name</mat-label>
    <input matInput formControlName="child_last_name" aria-label="Child Last Name" autocomplete="family-name" />
    <mat-error>This field is required</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" [floatLabel]="floatLabel">
    <mat-label>Child's Email</mat-label>
    <input matInput formControlName="child_email" aria-label="Child Email" autocomplete="off"
      (copy)="$event.preventDefault()" (paste)="$event.preventDefault()" />
    <mat-error *ngIf="childEmail.invalid && (childEmail.dirty || childEmail.touched)">
      <span *ngFor="let e of childEmail.errors.server">{{ e }}</span>
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" [floatLabel]="floatLabel" class="phone-input-wrapper">
    <mat-label id="child-phone-label">Child Mobile Phone Number</mat-label>
    <lib-phone-input class="full-width" formControlName="child_phone" aria-describedby="child-phone-label" [codes]="countryCodes"
      [validity]="!!childPhone.errors">
    </lib-phone-input>

    <mat-error>
      <span *ngFor="let e of childPhone.errors?.server">{{ e }}</span>
      <span *ngIf="childPhone.errors?.countryError">{{ childPhone.errors.countryError }}</span>
    </mat-error>
  </mat-form-field>
  <ng-content></ng-content>
</ng-container>
