<div *ngIf="{progress: progress$ | async} as s" class="progress">
  <div class="progress-info">{{ s.progress }}%</div>
  <div class="progress-bar-wrapper"><div class="progress-bar" [style.width.%]="s.progress"></div></div>
</div>

<div class="question-list-wrapper">
  <ol class="question-list">
    <li *ngFor="let question of procedureQuestions$ | async" [ngClass]="getItemCssFormQuestion(question)"
      [innerHTML]="question.titleForList" (click)="questionSelect(question)">
    </li>
  </ol>
</div>
