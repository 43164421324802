import { MinimalStudyType, studyState } from '../../models';
import { UserStudyType } from './user-study';

export interface ClosedStudyStatus {
  userStudy: UserStudyType;
  study: MinimalStudyType;
  isResearchStudy: boolean;
  isROStudyClosedForParticipant: boolean;
}

export const isStudyClosedForParticipant = (
  {study_status, status}: UserStudyType
): boolean => status === 100 || study_status === studyState.closed && status === 0

