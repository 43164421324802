<section class="flex">
  <div class="banner-block">
    <p>Welcome to the T1D Exchange Registry!</p>
    <h1 class="mbs-10">Help change the future of type 1 diabetes research and care.</h1>
    <h5>The T1D Exchange Registry is a research study, designed to harness the power of individuals with type 1 diabetes.</h5>
    <button mat-flat-button color="primary" class="is-shadowed" routerLink="/auth/register">Join Now</button>
    <div class="spot l-desktop">
      <img src="assets/img/landing_bg_element.png" alt="#">
    </div>
    <div class="landing-button-down l-medium" aria-label="Scroll to next page">
      <ng-container [ngTemplateOutlet]="scrollBtn"></ng-container>
    </div>
  </div>

  <div class="banner-block">
    <div class="man"><img src="assets/img/man_img.png" alt="#"></div>
    <div class="group"><img src="assets/img/group_img.png" alt="#"></div>
    <div class="child"><img src="assets/img/child_img.png" alt="#"></div>
    <div class="landing-button-down">
      <ng-container [ngTemplateOutlet]="scrollBtn"></ng-container>
    </div>
  </div>
</section>

<section class="questionnaire-banner" id="questionnaire-section">
  <div class="questionnaire-banner-block">
    <img src="assets/img/people_with_phone.png" alt="#">
  </div>

  <div class="questionnaire-banner-block">
    <h1>You complete a questionnaire once a year.</h1>
    <div class="flex align-center pr-50 list-style">
      <mat-icon svgIcon="bullet-point"></mat-icon>
      <p>Add your voice, experiences, and data to a body of evidence that grows over time.</p>
    </div>

    <div class="flex align-center pr-50 list-style">
      <mat-icon svgIcon="bullet-point"></mat-icon>
      <p class="pl-30">
        Help accelerate the discovery and development of new treatments and potentially inform policy and insurance decisions .
      </p>
    </div>

    <div class="flex align-center pr-50 list-style">
      <mat-icon svgIcon="bullet-point"></mat-icon>
      <p class="pl-30">Once you enroll, you can participate in more studies on specific topics related to type 1 diabetes.</p>
    </div>
    <div class="flex align-center pr-50 list-style">
      <mat-icon svgIcon="bullet-point"></mat-icon>
      <p class="pl-30">The Registry is open to both adults and children with type 1 diabetes living in the United States.</p>
    </div>
  </div>
</section>

<ng-template #scrollBtn>
  <button mat-flat-button class="is-rounded is-with-icon" (click)="scroll()">
    <mat-icon svgIcon="two-arrows"></mat-icon>
  </button>
</ng-template>
