import * as t from 'io-ts';
import { date } from 'io-ts-types/date';
import { UUID } from 'io-ts-types/UUID';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { nullable, procedureLookupSchema } from '@tr-common';

import { studyStateSchema } from './study-state';
import { surveyTypeSchema } from './survey-type';
import { criteriaSchema } from './criteria-types';

export const studyRewardSchema = t.partial({
  reward_on: t.boolean,
  reward_text: nullable(t.string)
}, 'studyRewardSchema');

export const minimalStudySchema = t.intersection([
  t.type({
    id: UUID,
    study_type: surveyTypeSchema,
    title: nullable(t.string),
    status: studyStateSchema,
    closed_at: t.union([date, t.string, t.null]),
    approximate_complete_time: nullable(t.string),
    launch_on: t.union([date, t.string, t.null]),
    number_of_users_goal: nullable(t.number),
  }),
  studyRewardSchema,
], 'minimalStudySchema');

export const studySchema = t.intersection([
  minimalStudySchema,
  t.partial({
    sponsor: nullable(t.string),
    principle_investigator: nullable(t.string),
    created_at: t.union([date, t.string, t.null]),
    isEligible: t.boolean,
    available_statuses: t.array(studyStateSchema),
    assignment_by_criteria: t.number,
    procedures_lookup: t.array(procedureLookupSchema),
    expires_in: nullable(t.number),
    assigned_to_user: nullable(t.boolean),
    initially_assigned_and_notified: t.number,
    actually_assigned_and_notified: t.number,
    number_of_users_completed_a_study: nullable(t.number),
    total_actually_assigned: t.number,
    total_possible_to_assign: t.number,
    filter_criterias: nullable(t.array(criteriaSchema)),
    clicked_the_link_count: nullable(t.Int),
    created_by: nullable(t.string),
    updated_at: t.union([date, t.string, t.null]),
    last_activated_at: nullable(DateFromISOString),
  })], 'studySchema');
