import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Memoize } from 'lodash-decorators';
import { Subscription } from 'rxjs';

import { participantSource, ProcedureTemplateTypes, SessionStorage, storageKeys } from '@tr-common'

import { REGISTRY_DOCUMENT, STORAGE } from '../../../global/tokens';
import { IProcedure } from '../../../models';
import { SurveyUrlFactoryProxy } from '../../services/survey-url-factory.service';
import { StudyFacade } from '../../store';

@Component({
  selector: 'lib-not-eligible',
  template: `
    <ng-container *ngIf="procedureStatus$ | async as procedureStatus; else loadingProfile">
      <ng-container *ngIf="isDefault(procedureStatus.activeProcedure); else isIntro">
        <div class="flex shortcuts" [ngClass]="{'is-public': isPublic}">
          <lib-goto-back-link [buttonTitle]="'Back to Dashboard'" (redirectTo)="redirectTo()">
          </lib-goto-back-link>
        </div>

        <lib-not-eligible-wrapper [email]="procedureStatus.setting?.content?.contact_email" [source]="procedureStatus.source"
          [phone]="procedureStatus.setting?.content?.contact_phone" [procedureStatus]="procedureStatus"
          [socialLinks]="procedureStatus.setting?.content?.social_links">
          <ng-container ngProjectAs="source-content">
            <ng-container *ngIf="!isJaebParticipant(procedureStatus.source); else jaebContent" >
              <h4>{{ procedureStatus.setting?.content?.title }}</h4>
              <div [innerHTML]="procedureStatus.setting?.content?.body_text | safeHtml"></div>
            </ng-container>

            <ng-template #jaebContent>
              <lib-not-eligible-jaeb-content></lib-not-eligible-jaeb-content>
            </ng-template>
          </ng-container>

          <ng-container ngProjectAs="source-buttons">
            <lib-submitted-procedure-actions [procedure]="procedureStatus"></lib-submitted-procedure-actions>
          </ng-container>
        </lib-not-eligible-wrapper>
      </ng-container>
    </ng-container>

    <ng-template #isIntro>
      <lib-survey-intro-wrapper></lib-survey-intro-wrapper>
    </ng-template>

    <ng-template #loadingProfile>
      <div class="loading-profile">Loading profile...</div>
    </ng-template>
  `,
  providers: [{provide: REGISTRY_DOCUMENT, useValue: window.document}],
  styleUrls: ['./not-eligible.component.scss'],
})
export class NotEligibleComponent implements OnDestroy {
  @HostBinding('class.dashboard') dashboard = false;
  isPublic: boolean = this.storage.getItem(storageKeys.application_part) === 'public';
  procedureStatus$ = this.study.procedureStatus$;
  subscriptions = new Subscription();

  constructor(
    @Inject(STORAGE) private storage: SessionStorage<typeof storageKeys.application_part>,
    public study: StudyFacade,
    private urls: SurveyUrlFactoryProxy,
    private router: Router
  ) {
    this.subscriptions.add(
      study.study$.subscribe(({id}) => this.study.loadModuleSettings(id, ProcedureTemplateTypes.screening))
    );
  }

  @Memoize() isDefault(procedure: IProcedure): boolean {
    return procedure?.id === ProcedureTemplateTypes.screening;
  }

  @Memoize() isJaebParticipant(source: string): boolean {
    return source === participantSource.registry_jaeb || source === participantSource.jaeb;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  redirectTo(): void {
    this.router.navigateByUrl(this.urls.proxyFactory.getDashboardUrl()).then();
  }
}
