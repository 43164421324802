import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SurveyUrlFactoryProxy } from '../../services/survey-url-factory.service';
import { StudyConsentFacade } from '../../store';

@Component({
  selector: 'lib-unavailable-consent',
  template: `
    <div class="wrapper" [class.hide-shortcuts]="hideShortcuts">
      <div class="flex shortcuts">
        <lib-goto-back-link buttonTitle="Back to Dashboard" (redirectTo)="onBack()"></lib-goto-back-link>
        <lib-contact-info [flatBtn]="true"></lib-contact-info>
      </div>

      <div class="jump-next-procedure">
        <img class="mbs-50" src="assets/consent-is-not-required.svg" alt="consent is not required">
        <h4>Consent is not required.</h4>
        <p class="mbe-40">
          There is no need for you to sign a consent form for this study, to proceed to the questionnaire please click the button below.
        </p>
        <button class="survey-button is-shadowed" mat-flat-button color="primary" (click)="consent.forceSubmitConsentProcedure()">
          Go to Questionnaire
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./unavailable-consent.component.scss']
})
export class UnavailableConsentComponent implements OnInit {
  hideShortcuts = false;

  constructor(
    public consent: StudyConsentFacade,
    private urls: SurveyUrlFactoryProxy,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.hideShortcuts = !this.route.routeConfig.data?.showHeader;
  }

  onBack(): void {
    this.router.navigateByUrl(this.urls.proxyFactory.getDashboardUrl()).then();
  }
}
