import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';

import { nullable, questionAnswerLogic, QuestionTypeEnum, questionTypeSchema } from '@tr-common';

import { studyOptionSchema } from './study-option';

export const studyQuestionSchema = t.intersection([
  t.type({
    id: t.union([t.string, UUID]),
    title: t.union([t.string, t.null]),
    question_type: questionTypeSchema,
    order: t.number,
    options: nullable(t.array(studyOptionSchema)),
    procedure: t.string,
    show_hint: nullable(t.boolean),
    confirmation_warning: nullable(t.string),
    visible_by_default: t.boolean
  }),
  t.partial({
    studyId: nullable(t.string),
    shortTitleMode: nullable(t.boolean),
    created_at: t.string,
    parent_option_id: nullable(t.string),
    updated_at: nullable(t.string),
  })
], 'studyQuestionSchema');
export type StudyQuestionType = t.TypeOf<typeof studyQuestionSchema>;

const singleRadioAnswerLogics: readonly string[] = [
  questionAnswerLogic.multiple_or_single_radio, questionAnswerLogic.all_or_single_radio, questionAnswerLogic.all_mixed_or_single_radio
] as const;
export const hasToHaveSingleRadio = (questionType: QuestionTypeEnum): boolean => singleRadioAnswerLogics.includes(questionType);
