import { Action, createReducer, on } from '@ngrx/store';

import {
  BaseChildProfileType,
  ChangePassPayload,
  isNullOrUndefined,
  isRealValue,
  ParticipantProfile,
  ParticipantProfileType,
  ServerValidationErrors,
  UserHouseholdType,
} from 'tr-lib';

import { SmartConfirmation, smartConfirmationInitialState } from '@app/auth/models/smart-check';
import {
  cleanPhoneErrorFromState,
  DetailsError,
  receiveErrors,
  RegisterError,
  ServerSuspendedType,
} from '@app/model';
import {
  changePassSuccess,
  changePasswordReconsentFail,
  changePasswordReconsentSuccess,
  checkTokenSuccess,
  cleanPhoneErrorState,
  resetDualFactorError,
  resetErrorState,
  setChangePassError,
  setLoginError,
  setNewPassError,
  setNewPassSuccess,
  setNotificationsOnError,
  setRegisterError,
  setResetPassError,
  setTokenError,
  setUserDeactivateError,
  setValidationError,
  smartEmailCheckCoincident,
  smartEmailCheckFail,
  smartEmailCheckSuccess,
  submitDualFactorCodeFail,
  updateChildInfoError,
  updateHouseholdInfoError,
  updateProfileInfoError,
  updateProfileReconsentFail,
  updateProfileReconsentSuccess,
} from '@store/actions';
import { getNotificationsOnSuccess, updateChildInfoSuccess, updateHouseholdInfoSuccess } from '@app/user-profile/store/profile.actions';

export interface ErrorState {
  changePassError: ServerValidationErrors<ChangePassPayload>;
  checkEmail: SmartConfirmation;
  loginError: ServerSuspendedType;
  newPassError: string;
  notificationsOnError: string;
  profileError: ServerValidationErrors<ParticipantProfileType>;
  profileChildError: ServerValidationErrors<BaseChildProfileType>;
  householdError: ServerValidationErrors<UserHouseholdType>;
  reconsentPasswordError: ServerValidationErrors<ChangePassPayload>;
  reconsentProfileError: ServerValidationErrors<ParticipantProfile>;
  registerError: {[k: string]: string[]};
  resetPassError: ServerValidationErrors<DetailsError>;
  tokenError: string;
  userDeactivateError: string;
  validationError: unknown; // TODO refactor this when dual FA will be enabled again
}

export const initialErrorState: ErrorState = {
  changePassError: null,
  checkEmail: smartConfirmationInitialState,
  loginError: null,
  newPassError: null,
  notificationsOnError: null,
  profileError: null,
  profileChildError: null,
  householdError: null,
  reconsentPasswordError: null,
  reconsentProfileError: null,
  registerError: null,
  resetPassError: null,
  tokenError: null,
  userDeactivateError: null,
  validationError: null,
};

const reducer = createReducer(
  initialErrorState,
  on(smartEmailCheckSuccess,  (state, {message}) => ({
    ...state, checkEmail: {...state.checkEmail, isDoubtful: message !== 'OK', message}
  })),
  on(smartEmailCheckFail, (state, {message}) => ({...state, checkEmail: {...state.checkEmail, isDoubtful: false, message}})),
  on(smartEmailCheckCoincident, (state, {payload}) => ({
    ...state,
    checkEmail: {isCoincident: payload, isDoubtful: false, message: payload ? 'OK' : state.checkEmail.message}
  })),
  on(changePassSuccess, (state) => ({...state, changePassError: null})),
  on(changePasswordReconsentFail, (state, {error}) => ({
    ...state, reconsentPasswordError: error as ServerValidationErrors<ChangePassPayload>
  })),
  on(changePasswordReconsentSuccess, (state) => ({...state, reconsentPasswordError: null})),
  on(checkTokenSuccess, (state) => ({...state, tokenError: null})),
  on(updateProfileInfoError, (state, {error}) => ({...state, profileError: {...state.profileError, ...error}})),
  on(updateHouseholdInfoSuccess, state => ({...state, householdError: null})),
  on(updateHouseholdInfoError, (state, {error}) => ({...state, householdError: isNullOrUndefined(error) ? error : {...error}})),
  on(updateChildInfoSuccess, state => ({...state, profileChildError: null})),
  on(updateChildInfoError, (state, {error}) => ({...state, profileChildError: isNullOrUndefined(error) ? error : {...error}})),
  on(cleanPhoneErrorState, state => cleanPhoneErrorFromState(state)),
  on(resetErrorState, () => ({...initialErrorState})),
  on(setChangePassError, (state, {error}) => ({...state, changePassError: error})),
  on(setLoginError, (state, {error}) => ({...state, loginError: isRealValue(error) ? error as ServerSuspendedType : null})),
  on(setNewPassError, (state, {error}) => ({...state, newPassError: receiveErrors(error)})),
  on(setNewPassSuccess, (state) => ({...state, newPassError: null})),
  on(getNotificationsOnSuccess, state => ({...state, notificationsOnError: null})),
  on(setNotificationsOnError, (state, {error}) => ({...state, notificationsOnError: receiveErrors(error)})),
  on(setRegisterError, (state, {error}) => ({...state, registerError: error as RegisterError})),
  on(setResetPassError, (state, {error}) => ({...state, resetPassError: error as ServerValidationErrors<DetailsError>})),
  on(setTokenError, (state, {error}) => ({...state, tokenError: receiveErrors(error)})),
  on(setUserDeactivateError, (state, {error}) => ({...state, userDeactivateError: error as string})),
  on(setValidationError, (state, {error}) => ({...state, validationError: error})),
  on(submitDualFactorCodeFail, (state, {error}) => ({
    ...state,
    validationError: (typeof error === 'string' ? error : 'You entered the wrong code')
  })),
  on(resetDualFactorError, (state) => ({...state, validationError: null})),
  on(updateProfileReconsentFail, (state, {error}) => ({
    ...state, reconsentProfileError: error as ServerValidationErrors<ParticipantProfile>
  })),
  on(updateProfileReconsentSuccess, (state) => ({...state, reconsentProfileError: null})),
);

export const errorReducer = (state: ErrorState, action: Action) => reducer(state, action);
