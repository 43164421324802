<div *ngFor="let consent of data" class="tr-consent-signed-links">
  <div *ngIf="isNonUniversalType(consent)" class="tr-consent-title">{{ consent.consent_type | uppercase }}</div>
  <div *ngIf="isTimeStamp && consent.signature" class="tr-consent-signed-date">
    {{ consent.signature['created_at'] | date: 'medium'}}
  </div>

  <div class="flex link-actions">
    <button mat-flat-button class="survey-button is-with-icon" color="secondary" *ngIf="isReviewBtn"
      (click)="onReviewConsent(consent.signature['user_consent_id'].toString(10))">
      <mat-icon svgIcon="view-open"></mat-icon><span>View consent</span>
    </button>

    <button mat-flat-button class="survey-button is-with-icon" color="secondary" *ngIf="isDownloadBtn"
      (click)="onDownloadConsent(consent)" >
      <mat-icon svgIcon="download"></mat-icon><span>Download</span>
    </button>

    <button mat-flat-button class="survey-button is-with-icon" color="secondary" *ngIf="isPrintBtn"
      (click)="onPrintConsent(consent.signature['user_consent_id'].toString(10))">
      <mat-icon svgIcon="print"></mat-icon><span>Print</span>
    </button>
  </div>
</div>
