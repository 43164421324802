import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { LayoutService } from 'tr-lib';

import { getReasons, withdrawalValidator } from '@app/user-profile/model/user-withdraw';

@Component({
  selector: 'app-withdrawal-reasons-dialog',
  templateUrl: './withdrawal-reasons-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./withdrawal-reasons-dialog.component.scss']
})
export class WithdrawalReasonsDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-gadget') isGadget = this.layoutService.isGadget;
  withdrawalForm: UntypedFormGroup = this.fb.group({
    receiving_too_many_emails: null,
    have_data_privacy_concerns: null,
    takes_too_much_time_to_complete_qs: null,
    not_wish_be_presented_with_pro: null,
    have_difficult_accessing_account: null,
    was_signed_up_by_parent: null,
    was_signed_up_for_my_child: null,
    not_wish_to_disclose_my_reason: null,
    other: null,
    reason: {value: '', disabled: true}
  });
  subscription = new Subscription();

  constructor(
    private layoutService: LayoutService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<WithdrawalReasonsDialogComponent>
  ) {}

  get other(): AbstractControl { return this.withdrawalForm.get('other'); }
  get reason(): AbstractControl { return this.withdrawalForm.get('reason'); }

  ngOnInit(): void {
    this.subscription.add(
      this.other.valueChanges.subscribe(value => {
        if (value) {
          this.reason.enable({emitEvent: false});
        } else {
          this.reason.disable({emitEvent: false});
          this.reason.setValue('');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    this.withdrawalForm.setErrors(withdrawalValidator(this.withdrawalForm));

    if (this.withdrawalForm.valid) {
      this.dialogRef.close(getReasons(this.withdrawalForm));
    }
  }
}
