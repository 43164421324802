const host = window.location.hostname;
const registryHost = {
  alliance: '',
  crowbar: '',
  fe: '',
  be: ''
};
const dexcom = {
  test: 'https://sandbox-api.dexcom.com',
  prod: 'https://api.dexcom.com',
  clientId: 'kK5hXyQHCJ9aegLIzzDHd5MKWJaSdAuQ'
};
const alchemerDexcom = {
  test: 'https://sandbox-api.dexcom.com',
  prod: 'https://api.dexcom.com',
  clientId: '5VBs8ORmMSI6BjKIfxiBywWLNsWBCywi',
  mode: 'prod' as const,
};
const regGuard = {
  clientID: null,
  scriptName: 'Registration Guard v1.0',
  scriptID: 'registration-guard',
  scriptSrc: 'https://d3agx2rif8aadl.cloudfront.net/registrationguard1.0.js'
};

export const environment = {
  production: true,
  dexcom,
  alchemerDexcom,
  apiUrl: registryHost.be + '/registry',
  apiPublicUrl: '/registry/public',
  apiParticipantUrl: '/registry/participant',
  apiAdminUrl: '/registry/admin',
  recaptchaSiteToken: null,
  registerUrl: '/registry/participant',
  registerJAEBUrl: '/registry/jaeb_signup',
  t1dOAuthUrl: '',
  regGuard,
  t1dClientId: '',
  crowbarHost: '',
  admin: `//${host}:8083`,
  registry: window.location.origin,
  versionsUrls: {
    be: registryHost.be + '/registry/version',
    fe: registryHost.fe + '/meta/version',
    alliance: registryHost.alliance + '/alliance/meta/version',
    crowbar: registryHost.crowbar + '/crowbar/meta/version'
  },
  questionsUrl: '/registry/questions',
  answerUrl: '/registry/user-answer',
  subAnswerUrl: '/registry/user-sub-question-answer',
  isEligibleUrl: '/registry/is-eligible',
  loginURL: '/registry/login',
  profileURL: '/registry/me',
  otpURL: '/registry/otp',
  createConsentUrl: '/registry/create-consent',
  notificationsUrl: '/registry/public/user/notifications',
  studyAnswersUrl: '/registry/public/user/study_answers',
  getUserFiles: '/crowbar/vault13/list-simple-files',
  downloadUserFile: '/crowbar/vault13/download-file/',
  resetPassUrl: '/registry/reset_password',
  checkResetPassTokenUrl: '/registry/check-reset-password-token',
  setNewPassUrl: '/registry/create_new_password',
  termOfUseUrl: 'https://static.registry.t1dplatform.org/docs/T1DRegistry_Terms_of_Use.pdf',
  privacyPolicyUrl: 'https://static.registry.t1dplatform.org/docs/T1DRegistry_Privacy_Policy.pdf',
};
