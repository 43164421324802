import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

import { PositionType } from '@tr-common';

@Component({
  selector: 'lib-info-tooltip',
  template: `
    <div #tooltipMessage class="tooltip-message site-{{ position }}" [ngStyle]="styles">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent {
  @Input() position: PositionType = 'center';
  @Input() styles = {};
  @ViewChild('tooltipMessage', { static: true }) tooltipMessage: ElementRef;
  @HostListener('click') onComponentClick = (): void => { this.tooltipMessage.nativeElement.style.display = 'block'; };
  @HostListener('document:click') onClick = (): void  => { this.tooltipMessage.nativeElement.style.display = null; };
}
