import { Action, createReducer, on } from '@ngrx/store';

import { AllStates, BaseChildProfileType, TimeZone, UserHouseholdType } from 'tr-lib';

import {
  getChildInfoSuccess,
  getHouseholdInfoSuccess,
  getListOfUSASuccess,
  getTimezonesSuccess,
  updateChildInfoSuccess,
  updateHouseholdInfo,
} from './profile.actions';

export interface ProfileState {
  statesOfUSA: AllStates;
  timeZones: TimeZone;
  childInfo: BaseChildProfileType;
  householdInfo: UserHouseholdType;
}

export const profileProperty = 'profile';

export const initialProfileState: ProfileState = {
  statesOfUSA: [],
  timeZones: null,
  childInfo: null,
  householdInfo: null,
}

const reducer = createReducer(
  initialProfileState,
  on(getListOfUSASuccess, (state, {payload}) => ({...state, statesOfUSA: payload})),
  on(getTimezonesSuccess, (state, {timeZones}) => ({...state, timeZones})),
  on(getChildInfoSuccess, updateChildInfoSuccess, (state, {payload}) => ({...state, childInfo: payload})),
  on(getHouseholdInfoSuccess, updateHouseholdInfo, (state, {payload}) => ({...state, householdInfo: payload}))
);

export const profileReducer = (state = initialProfileState, action: Action): ProfileState => reducer(state, action);
